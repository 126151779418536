import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { withStyles } from 'material-ui/styles';
// import moment from 'moment';
// import Avatar from 'material-ui/Avatar';
// import Card, { CardContent, CardHeader } from 'material-ui/Card';
import IconButton from 'material-ui/IconButton';
import DeleteIcon from 'material-ui-icons/Delete';
import FilterListIcon from 'material-ui-icons/FilterList';
import EmailReadIcon from 'material-ui-icons/Drafts';
import EmailUnreadIcon from 'material-ui-icons/Email';
import TimelineIcon from 'material-ui-icons/Timeline';
import ReactTooltip from 'react-tooltip';
import Button from 'material-ui/Button';
import Dialog, {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from 'material-ui/Dialog';

// Translation
import i18n from '../../i18n';

// import Button from 'material-ui/Button';
import Table from 'material-ui/Table';
import {TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel} from 'material-ui/Table';
import Checkbox from 'material-ui/Checkbox';
import List, { ListItem } from 'material-ui/List';
import Popover from 'material-ui/Popover/Popover';

import { API } from '../../components/api';
import SystemSnackbar from '../../elements/material-ui/SystemSnackbar'
import {Loading} from '../../elements/loading';
import { granularitiesContextPT_BR } from './constants';

const filterPopover = {
    anchorOrigin: {
        horizontal: 'left',
        vertical: 'bottom',
    },
    transformOrigin: {
        horizontal: 'left',
        vertical: 'top',
    },
}

const CustomTableCell = withStyles(theme => ({
    body: {
        fontSize: '13px',
        fontWeight: 'normal',
        color: '#757575',
        '& span': {
            fontSize: '13px',
        }
    },
}))(TableCell);

const CustomLightCheckbox = withStyles(theme => ({
    root:{
        '& svg': {
            color: '#FFF'
        }
    },
}))(Checkbox);

const CustomTablePagination = withStyles(theme => ({
    root: {
        display: 'inline-flex',
    },
    caption: {
        color: '#FFFFFF',
        fontSize: '13px'
    },
    actions: {
        '& span': { color: 'inherit' },
        '& button' : {
            color: '#FFFFFF',
        },
        '& button:disabled' : {
            color: '#6E6F73',
        },
        fontSize: '13px',
    },
}))(TablePagination);

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
    },
    table: {
        minWidth: 1020,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    tableHeadFirstRow: {
        backgroundColor: '#333645',
    },
    tableHeaderButtonWrapper: {
        height: '24px',
        width: '30px',
        display: 'inline-flex',
        alignItems: 'center',
        flex: '0 0 auto',
        verticalAlign: 'middle',
        justifyContent: 'center',
    },
    readRow:{
        backgroundColor: '#F5F5F5',
    },
    unreadCell:{
        fontWeight: 'bold',
    },
    cardButton: {
        height: '30px',
        width: '30px',
    },
    cardButtonLightIcon: {
        color: '#FFF',
        fontSize: '24px',
    },
    cardButtonLightDisabledIcon: {
        color: '#6E6F73',
        fontSize: '24px',
    },
    cardButtonIcon: {
        color: '#353645',
        fontSize: '20px',
    },
    typographyTitle: {
        fontSize: '1rem',
        fontWeight: 600
    },
    snackbarClose: {
        width: theme.spacing.unit * 4,
        height: theme.spacing.unit * 4,
    },
    filterButton: {
        color: '#FFFFFF',
        '& span': { color: 'inherit' },
    },
    filterMenu: {
        flexGrow: 1,
        backgroundColor: '#333645',
    },
    filterListItem: {
        color: '#FFF',
        fontSize: '16px',
    },
    filterSelectedItem: {
        color: '#FFF',
        fontSize: '16px',
        backgroundColor: '#444448',
    },
    tbodyRow: {
        '& td:last-child button': {
            opacity: '0',
            transition: 'opacity 0.6s'
        },
        '&:hover td:last-child button': {
            opacity: '1'
        }
    },
    deleteConfirm: {
        color: '#6ab42d',
    },
    deleteCancel: {
        color: '#c62828',
    },
});

let pageTimestampMap = new Map();
const rowsPerPage = 20;
let deleting;

const getBreadcrumbsTrailFromHierarchy = (event) => {
    const {alert, systemAlert} = event;
    const errorMessage = i18n.t("Unknown group/sensor");
    let error = false;

    let breadcrumbsText = '';
    if (alert && alert.breadCrumbs) {
        if (alert.breadCrumbs.parentGroupName) {
            breadcrumbsText = alert.breadCrumbs.parentGroupName;
        }

        if (alert.breadCrumbs.groupName) {
            if (alert.breadCrumbs.parentGroupName) {
                breadcrumbsText += '/'
            }
            breadcrumbsText += alert.breadCrumbs.groupName;
        }

        if (alert.breadCrumbs.sensorId && alert.breadCrumbs.channel) {
            breadcrumbsText = breadcrumbsText ? breadcrumbsText + '/' : '';
            breadcrumbsText += alert.breadCrumbs.sensorId + '-' + alert.breadCrumbs.channel;
            if (alert.breadCrumbs.sensorChannelName) {
                breadcrumbsText += ' - ' + alert.breadCrumbs.sensorChannelName;
            }
        }else if(alert.breadCrumbs.sensorId && !alert.breadCrumbs.channel) {
            //sensor with all phases case
            breadcrumbsText = alert.breadCrumbs.sensorId;
            if (alert.breadCrumbs.sensorChannelName) {
                breadcrumbsText += ' - ' + alert.breadCrumbs.sensorChannelName;
            }
            breadcrumbsText += ' ' + i18n.t('(all phases)');
        }

        if (breadcrumbsText === '') {
            error = true;
        }
    } else if (systemAlert && systemAlert.breadCrumbs) {
        if (systemAlert.breadCrumbs.parentGroupName) {
            breadcrumbsText = systemAlert.breadCrumbs.parentGroupName;
        }

        if (systemAlert.breadCrumbs.groupName) {
            if (systemAlert.breadCrumbs.parentGroupName) {
                breadcrumbsText += '/'
            }
            breadcrumbsText += systemAlert.breadCrumbs.groupName;
        }

        if (systemAlert.breadCrumbs.sensorId && systemAlert.breadCrumbs.channel) {
            breadcrumbsText = breadcrumbsText ? breadcrumbsText + '/' : '';
            breadcrumbsText += systemAlert.breadCrumbs.sensorId + '-' + systemAlert.breadCrumbs.channel;
            if (systemAlert.breadCrumbs.sensorChannelName) {
                breadcrumbsText += ' - ' + systemAlert.breadCrumbs.sensorChannelName;
            }
        }
    } else if (systemAlert) {
        breadcrumbsText = systemAlert.sensorId + '-' + systemAlert.channel;
    } else {
        error = true;
    }

    if (error) {
        breadcrumbsText = errorMessage;
        console.warn("A wrong breadcrumb format was received and no breadcrumb text could be created for Alerts Events list");
    }

    return breadcrumbsText;
}

const getMessageContext = (event, granularitiesContext) => {
    if (!event)
        return {};

    const categoriesWithContext = ['ABSOLUTE_COST', 'ABSOLUTE_CONSUMPTION', 'RELATIVE_CONSUMPTION'];

    const message = JSON.parse(event.message)
    const eventAlertCategory = event.alert ? event.alert.category : '';

    let granularityProp = 'granularity';

    if (eventAlertCategory === 'RELATIVE_CONSUMPTION')
        granularityProp = 'avgGranularity';

    if (moment.locale() === 'pt-br' && message.values && message.values.granularity && categoriesWithContext.includes(eventAlertCategory)){
        const rawGranularity = message.values[granularityProp].slice(3).slice(0, -1).toUpperCase();
        return granularitiesContext()[rawGranularity];
    }
    return {};
}

class EventsList extends React.PureComponent {
    constructor(props, context) {
        super(props, context);

        this.state = {
            loading: true,
            selected: [],
            page: 0,
            rowsCount: 0,
            anchorEl: null,
            openFilter: false,
            selectedFilter: 'all',
            alertEvents: [],
            nextTimestamp: null,
            previousTimestamp: null,
            currentTimestamp: null,
            deleteDialogOpen: false,
            showSnackBar: false,
            snackbar: {
                type: 'warning',
                message: ''
            }
        }
    }

    componentWillMount() {
        let searchParams = new URLSearchParams(window.location.search);

        this.getEvents(null, this.state.selectedFilter, 0, parseFloat(searchParams.get('id')));
    }

    componentWillReceiveProps(nextProps){
        if (nextProps.reloadEvents === true)
            this.reloadEvents();
    }

    columnData = [{
        id: 'group-or-sensor',
        numeric: false,
        disablePadding: false,
        label: i18n.t('Group / Subgroup / Sensor')
    }, {
        id: 'triggered',
        numeric: false,
        disablePadding: false,
        label: i18n.t('Triggered')
    }, {
        id: 'alert',
        numeric: false,
        disablePadding: false,
        label: i18n.t('Alert')
    }];

    filterOptions = [
        {value: 'all', label: i18n.t("All Alerts")},
        {value: 'unread', label: i18n.t("Unread Alerts")},
        {value: 'read', label: i18n.t("Read Alerts")},
    ];

    getEvents = (timestamp, filter, page, id) => {
        this.setState({ loading: true })
        filter = (filter === 'read') ? true : (filter === 'unread') ? false : null;
        pageTimestampMap.set(page, timestamp);

        API.ALERTS.EVENTS.GET(timestamp, filter).then(({ data }) => {
            deleting = false;
            const events = data && data.events && data.events.length ? data.events : [];
            const found = data && data.events && data.events.length ? data.events.find(event => event.id === id) : false;

            if (found) {
                this.props.handleEditClick({
                    stopPropagation: () => {},
                    preventDefault: () => {}
                }, found);
            }

            this.setState({
                alertEvents: events,
                rowsCount: data.numberOfEvents,
                loading: false,
                page: page,
                currentTimestamp: timestamp,
                nextTimestamp: events && events.length ? events[events.length-1].timestamp : null,
                previousTimestamp: pageTimestampMap.get(page-1),
            })

            if (id && !isNaN(id) && !found) {
                API.ALERTS.EVENTS.DETAILS(id).then(({ data }) => {
                    if (data) {
                        this.props.handleEditClick({
                            stopPropagation: () => {},
                            preventDefault: () => {}
                        }, data);
                    }
                }).catch((error) => {
                    console.error(error)
                })
            }
        }).catch((error) => {
            this.setState({ loading: false })
            console.error(error)
        });
    }

    reloadEvents = () => {
        this.getEvents(this.state.currentTimestamp, this.state.selectedFilter, this.state.page);
    }

    handleSelectAllClick = (event, checked) => {
        if (checked) {
            this.setState({ selected: this.state.alertEvents.map(n => n.id) });
            return;
        }
        this.setState({ selected: [] });
    }

    handleClick = (event, id) => {
        event.stopPropagation();
        const { selected } = this.state;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        this.setState({ selected: newSelected });
    }

    toggleSelectedRead = event => {
        event.stopPropagation();
        const { selected, alertEvents } = this.state
        if (!selected.length) {
            return
        }

        const read = selected.every(id => alertEvents.find(alert => alert.id === id && alert.read))

        API.ALERTS.EVENTS.EDIT(selected.map(id => {
            const found = alertEvents.find(alert => alert.id === id)
            const type = found.alert
                ? 'USER'
                : 'SYSTEM';

            return ({id, type, read: !read})
        })).then(() => {
            this.openSnackBar(i18n.t('Events updated successfully'), 'success');

            this.setState({ selected: [] })
            window.dispatchEvent(new CustomEvent('updateAlertEventsCount'))
            this.reloadEvents();
        }).catch((error) => {
            console.error(error)
            this.openSnackBar(i18n.t('An error occurred while updating your events.'), 'error');
            this.reloadEvents();
        })
    }

    toggleRead = (e, id, read) => {
        e.stopPropagation();

        const event = this.state.alertEvents.find(alert => alert.id === id);
        const type = event.alert && event.alert.id
            ? 'USER'
            : 'SYSTEM';

        API.ALERTS.EVENTS.EDIT([{id, type, read}]).then(() => {
            this.openSnackBar(i18n.t('Event updated successfully'), 'success');

            window.dispatchEvent(new CustomEvent('updateAlertEventsCount'))
            this.reloadEvents();
        }).catch(error => {
            console.error(error)
            this.openSnackBar(i18n.t('An error occurred while updating your event.'), 'error');
            this.reloadEvents();
        })
    }

    deleteEvent = (event) => {
        deleting = true;
        if (!event && event === 'selected') {
            return
        }

        const type = !!event.alert
            ? 'USER'
            : 'SYSTEM';

        API.ALERTS.EVENTS.DELETE([{ id: event.id, type }]).then(() => {
            this.openSnackBar(i18n.t('Event deleted successfully'), 'success');

            this.setState({ deleteDialogOpen: false })
            window.dispatchEvent(new CustomEvent('updateAlertEventsCount'))
            this.reloadEvents();
            deleting = false;
        }).catch(error => {
            console.error(error)
            this.setState({ deleteDialogOpen: false })
            this.openSnackBar(i18n.t('An error occurred while deleting your event.'), 'error');
            this.reloadEvents();
        })
    }

    deleteSelectedEvents = () => {
        const { selected, alertEvents } = this.state;
        if (!selected.length || deleting) {
            return
        }

        deleting = true;
        API.ALERTS.EVENTS.DELETE(selected.map((id, index) => {
            const found = alertEvents.find(alert => alert.id === id)
            const type = found.alert
                ? 'USER'
                : 'SYSTEM';

            return ({ id, type })
        })).then(() => {
            this.openSnackBar(i18n.t('Events deleted successfully'), 'success');

            this.setState({
                selected: [],
                deleteDialogOpen: false,
            })

            window.dispatchEvent(new CustomEvent('updateAlertEventsCount'))
            this.reloadEvents();
        }).catch((error) => {
            console.error(error)
            this.setState({ deleteDialogOpen: false })
            this.openSnackBar(i18n.t('An error occurred while deleting your events.'), 'error');
            this.reloadEvents();
        })
    }

    handleChangePage = (event, page) => {
        this.setState({loading: true});
        const { nextTimestamp, previousTimestamp, selectedFilter } = this.state;
        const timestamp = page > this.state.page ? nextTimestamp : previousTimestamp;
        this.getEvents( timestamp, selectedFilter, page);
    }

    handleFilterIconClick = event => {
        this.setState({ anchorEl: event.currentTarget, openFilter: true });
    };

    handleFilterPopoverClose = () => {
        this.setState({ anchorEl: null, openFilter: false });
    };

    changeFilter = (value) => {
        if (value !== this.state.selectedFilter){
            this.setState({ selectedFilter: value, loading: true });
            pageTimestampMap = new Map();
            this.getEvents(null, value, 0);
            this.handleFilterPopoverClose();
        }
    };

    isSelected = id => (this.state.selected.indexOf(id) !== -1)

    openSnackBar = (message, type) => this.setState({
        showSnackBar: true,
        snackbar: {
            message,
            type
        }
    })

    closeSnackBar = () => this.setState({
        showSnackBar: false
    })

    handleDeleteDialogClose = flag => {
        if (!flag) {
            return this.setState({ deleteDialogOpen: false })
        }

        if (this.state.deleteDialogOpen !== 'selected') {
            this.deleteEvent(this.state.deleteDialogOpen)
        } else {
            this.deleteSelectedEvents()
        }
    }

    handleDeleteDialogOpen = (event, alert) => {
        event.stopPropagation();

        this.setState({ deleteDialogOpen: alert });
    }

    handleGoToGraph = (e, event) => {
        e.stopPropagation();

        if (!event.alert) {
            return
        }

        let url = `?groupId=${event.alert.groupId}`
        const alertDay = moment(event.timestamp);

        if (event.alert.alertGranularity === 'MINUTE' || event.alert.alertGranularity === 'HOUR') {
            url += `&date=${alertDay.format('YYYY-MM-DD')}&period=day`;
        } else if (event.alert.alertGranularity === 'DAY') {
            url += `&date=${alertDay.format('YYYY-MM-DD')}&period=week`;
        } else if (event.alert.alertGranularity === 'WEEK') {
            url += `&date=${alertDay.format('YYYY-MM-DD')}&period=month`;
        } else {
            url += `&startDate=${alertDay.subtract(3, 'month').format('YYYY-MM-DD')}&endDate=${alertDay.format('YYYY-MM-DD')}&period=custom`;
        }

        if (event.alert.sensorId && event.alert.channel) {
            url += `&sensorId=${event.alert.sensorId}&channel=${event.alert.channel}`;
        }

        window.open(event.alert.category === 'ABSOLUTE_COST' ? `/analysis/cost${url}` : `/analysis/consumption${url}`, '_blank');
    }

    render() {
        const { classes } = this.props;
        const { alertEvents, order, orderBy, selected, page, showSnackBar, snackbar } = this.state;
        const emptyRows = rowsPerPage - alertEvents.length;
        const numSelected = selected.length;
        const rowCount = alertEvents.length;

        return (
            <div className={classes.tableWrapper}>
                <Table className={classes.table} aria-labelledby="tableTitle">
                    <TableHead>
                        <TableRow className={classes.tableHeadFirstRow}>
                            <TableCell padding="checkbox" colSpan={2}>
                                <CustomLightCheckbox
                                    indeterminate={numSelected > 0 && numSelected < rowCount}
                                    checked={numSelected === rowCount}
                                    onChange={this.handleSelectAllClick}
                                    disabled={this.state.loading}
                                />
                                {localStorage.getItem('authority') === 'ADMIN' ?
                                    <IconButton className={`${classes.tableHeaderButtonWrapper} delete-btn`}
                                        onClick={(event => this.handleDeleteDialogOpen(event, 'selected'))}
                                        disabled={this.state.loading || numSelected === 0}>
                                        <DeleteIcon className={numSelected > 0 ? classes.cardButtonLightIcon : classes.cardButtonLightDisabledIcon} />
                                    </IconButton>
                                : null}
                                <IconButton className={`${classes.tableHeaderButtonWrapper} unread-btn`}
                                    onClick={numSelected ? this.toggleSelectedRead : null}
                                    disabled={this.state.loading || numSelected === 0}>
                                    {numSelected && selected.every(id => alertEvents.find(alert => alert.id === id && alert.read))
                                        ? (
                                            <div data-tip={i18n.t("Set all as unread")}>
                                                <EmailReadIcon className={numSelected > 0 ? classes.cardButtonLightIcon : classes.cardButtonLightDisabledIcon} />
                                                <ReactTooltip />
                                            </div>
                                        ) : (
                                            <div data-tip={numSelected ? i18n.t('Set all as read') : ''}>
                                                <EmailUnreadIcon className={numSelected > 0 ? classes.cardButtonLightIcon : classes.cardButtonLightDisabledIcon} />
                                                <ReactTooltip />
                                            </div>
                                        )
                                    }
                                </IconButton>
                            </TableCell>
                            <TableCell colSpan={4} style={{textAlign: "right"}}>
                                <CustomTablePagination
                                    component="div"
                                    count={this.state.rowsCount}
                                    rowsPerPage={rowsPerPage}
                                    rowsPerPageOptions={[rowsPerPage]}
                                    page={page}
                                    backIconButtonProps={{
                                        'aria-label': i18n.t('Previous Page'),
                                    }}
                                    nextIconButtonProps={{
                                        'aria-label': i18n.t('Next Page'),
                                    }}
                                    onChangePage={this.handleChangePage}
                                />
                                <IconButton onClick={this.handleFilterIconClick}
                                    aria-label={i18n.t("Filter list")}
                                    aria-owns={this.state.anchorEl ? 'filter-menu' : null}
                                    aria-haspopup="true"
                                    className={`${classes.filterButton} filter-btn`}>
                                    <FilterListIcon />
                                </IconButton>
                                <Popover
                                    open={this.state.openFilter}
                                    anchorEl={this.state.anchorEl}
                                    anchorOrigin={filterPopover.anchorOrigin}
                                    transformOrigin={filterPopover.transformOrigin}
                                    onClose={this.handleFilterPopoverClose}>
                                    <List component="div" disablePadding className={classes.filterMenu}>
                                        {this.filterOptions.map( filterOption => {
                                            const listItemClass = (filterOption.value === this.state.selectedFilter)
                                                ? classNames(classes.filterListItem, classes.filterSelectedItem)
                                                : classes.filterListItem;
                                            return (
                                                <ListItem button className={listItemClass}
                                                    key={filterOption.value}
                                                    onClick={() => this.changeFilter(filterOption.value)}>
                                                    <div className={classes.filterListItem}>{filterOption.label}</div>
                                                </ListItem>
                                            )
                                        })}
                                    </List>
                                </Popover>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell padding="checkbox">{this.state.loading && <div style={{minWidth: 48, width: 48}}> <Loading /></div>}</TableCell>
                            {this.columnData.map(column => {
                                return (
                                    <TableCell
                                        key={column.id}
                                        numeric={column.numeric}
                                        padding={column.disablePadding ? 'none' : 'default'}
                                        sortDirection={orderBy === column.id ? order : false}
                                        >
                                        <TableSortLabel
                                            active={orderBy === column.id}
                                            direction={order}
                                            >
                                            {column.label}
                                        </TableSortLabel>
                                    </TableCell>
                                );
                            }, this)}
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {alertEvents.map(alertEvent => {
                            const isSelected = this.isSelected(alertEvent.id);
                            return (
                                <TableRow
                                    hover
                                    onClick={e => !this.state.loading && this.props.handleEditClick(e, alertEvent)}
                                    role="checkbox"
                                    aria-checked={isSelected}
                                    tabIndex={-1}
                                    key={alertEvent.id}
                                    selected={isSelected}
                                    className={classNames(classes.tbodyRow, alertEvent.read ? classes.readRow : null)}
                                >
                                    <CustomTableCell padding="checkbox">
                                        <Checkbox checked={isSelected} onClick={(e) => this.handleClick(e, alertEvent.id)} />
                                    </CustomTableCell>
                                    <CustomTableCell
                                        className={!alertEvent.read ? classes.unreadCell : null}
                                    >
                                        {getBreadcrumbsTrailFromHierarchy(alertEvent)}
                                    </CustomTableCell>
                                    <CustomTableCell className={!alertEvent.read ? classes.unreadCell : null}>{moment(alertEvent.timestamp).format('lll')}</CustomTableCell>
                                    <CustomTableCell className={!alertEvent.read ? classes.unreadCell : null}>
                                        {i18n.t(JSON.parse(alertEvent.message).key,
                                            {
                                                ...JSON.parse(alertEvent.message).values,
                                                context: getMessageContext(alertEvent, granularitiesContextPT_BR)
                                            }
                                        )}
                                    </CustomTableCell>
                                    <CustomTableCell padding="none">
                                        {localStorage.getItem('authority') === 'ADMIN' ?
                                            <IconButton
                                                aria-label={i18n.t("Delete event")}
                                                color="inherit"
                                                key="delete-event"
                                                disabled={this.state.loading}
                                                className={`${classes.cardButton} delete-table-btn`}
                                                onClick={e => this.handleDeleteDialogOpen(e, alertEvent)}
                                                data-tip={i18n.t("Delete event")}
                                            >
                                                <DeleteIcon className={classes.cardButtonIcon} />
                                                <ReactTooltip />
                                            </IconButton>
                                        : null}
                                        <IconButton
                                            aria-label={i18n.t("Open event")}
                                            color="inherit"
                                            key="toggleEvent"
                                            disabled={this.state.loading}
                                            className={`${classes.cardButton} unread-table-btn`}
                                            onClick={e => this.toggleRead(e, alertEvent.id, !alertEvent.read)}
                                            data-tip={alertEvent.read ? i18n.t('Set as unread') : i18n.t('Set as read')}
                                        >
                                            {alertEvent.read ? (
                                                <EmailReadIcon className={classes.cardButtonIcon} />
                                            ) : (
                                                <EmailUnreadIcon className={classes.cardButtonIcon} />
                                            )}
                                            <ReactTooltip />
                                        </IconButton>
                                        {alertEvent.alert && alertEvent.alert.category !== 'ABSOLUTE_ACTIVE_POWER' && (
                                            <IconButton
                                                aria-label="Go to chart"
                                                color="inherit"
                                                key="link-event"
                                                disabled={this.state.loading}
                                                className={`${classes.cardButton} go-to-chart-table-btn`}
                                                onClick={e => this.handleGoToGraph(e, alertEvent)}
                                                data-tip={i18n.t("Go to chart")}
                                            >
                                                <TimelineIcon className={classes.cardButtonIcon} />
                                                <ReactTooltip />
                                            </IconButton>
                                        )}
                                    </CustomTableCell>
                                </TableRow>
                            );
                        })}
                        {!this.state.loading && alertEvents.length === 0 && (
                            <TableRow>
                                <CustomTableCell colSpan={6}>{i18n.t('No alert events yet.')}</CustomTableCell>
                            </TableRow>
                        )}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 49 * emptyRows }}>
                                <CustomTableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <Dialog aria-labelledby="dialog-text"
                    fullWidth={true}
                    maxWidth="xs"
                    open={!!this.state.deleteDialogOpen}
                    onClose={() => this.handleDeleteDialogClose()}>
                    <DialogTitle>
                        {i18n.t('Are you sure?')}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="dialog-text">
                            {i18n.t("If you delete you won't see this event anymore.")}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button classes={{label: classes.deleteConfirm}}
                            onClick={() => !deleting && this.handleDeleteDialogClose(true)}>
                            {i18n.t('Yes')}
                        </Button>
                        <Button classes={{label: classes.deleteCancel}} onClick={() => this.handleDeleteDialogClose()}>
                            {i18n.t('Cancel')}
                        </Button>
                    </DialogActions>
                </Dialog>
                <SystemSnackbar
                    open={showSnackBar}
                    message={snackbar.message}
                    onClose={this.closeSnackBar}
                    variant={snackbar.type}
                />
            </div>
        );
    }
}

EventsList.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EventsList);
export {getBreadcrumbsTrailFromHierarchy, getMessageContext}