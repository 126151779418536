import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import {Title} from '../../elements/title';
import {Input} from '../../elements/forms/input';
import { SensorsPhasesAdd } from './add-sensors-phases';
import Info from 'material-ui-icons/PriorityHigh';

import i18n from '../../i18n';

import './group-form.css';

class GroupForm extends React.Component {

    constructor (props){
        super (props);
        this.noSensorsPhasesLeftMsg = i18n.t('There are no sensors-phases left to add.');

        this.state = {
            name: '',
            tagId: null,
            tariffId: null,
            currentAvailableSensors: [],
            fieldsWithError: [],
            groupMainSensorsPhases: [],
            receivedAvailableSensors: false,
        }
    }


    componentWillMount() {
        if (this.props.group && this.props.group.name) {
            const { tagsIds, tagId, name, tariffId, groupMainSensorsPhases } = this.props.group;
            return this.setState({
                name: name,
                tagId: tagsIds && tagsIds.length ? tagsIds[0] : tagId ? tagId : null,
                tariffId: tariffId,
                groupMainSensorsPhases: groupMainSensorsPhases,
                currentAvailableSensors: this.selectedAvailableDiff(this.props.group.groupMainSensorsPhases, this.props.group.subgroups),
            })
        }else if(this.props.availableSensors){
            this.setState({
                currentAvailableSensors: this.props.availableSensors,
            })
        }

        const sessionData = JSON.parse(window.sessionStorage.getItem(`groupEdit_${this.props.group.id ? this.props.group.id : 0}`));

        if (sessionData) {
            this.setState({
                id: sessionData.id,
                name: sessionData.name,
                tagId: sessionData.tagId,
                tariffid: sessionData.tariffId,
                groupMainSensorsPhases: sessionData.groupMainSensorsPhases,
                currentAvailableSensors: this.selectedAvailableDiff(sessionData.groupMainSensorsPhases, this.props.group.subgroups),
            })
        }
    }

    componentWillReceiveProps(nextProps){
        if (nextProps.availableSensors){
            this.setState({
                receivedAvailableSensors: true,
            });
        }
    }

    componentDidUpdate(prevProps, prevState){
        if (!prevProps.group.name && this.props.group.name) {
            const { tagId, name, tariffId, groupMainSensorsPhases } = this.props.group;
            this.setState({
                name: name,
                tariffId: tariffId,
                tagId: tagId ? tagId : null,
                groupMainSensorsPhases: groupMainSensorsPhases,
            });
        }

        if (this.state.receivedAvailableSensors){
            this.setState({
                currentAvailableSensors: this.selectedAvailableDiff(this.state.groupMainSensorsPhases, this.props.group.subgroups),
                receivedAvailableSensors: false,
            });
        }

        if (
            prevState.name !== this.state.name ||
            prevState.tariffId !== this.state.tariffId ||
            prevState.tagId !== this.state.tagId ||
            JSON.stringify(this.state.groupMainSensorsPhases) !== JSON.stringify(prevState.groupMainSensorsPhases)
        ) {
            window.sessionStorage.setItem(`groupEdit_${this.props.group.id ? this.props.group.id : 0}`, JSON.stringify({
                name: this.state.name,
                tagId: this.state.tagId,
                tariffId: this.state.tariffId,
                groupMainSensorsPhases: this.state.groupMainSensorsPhases,
            }))
        }

        if (this.props.checkForm) {
            const isValid = this.validate();
            this.props.goToNext(this.validate(), this.getGroupToSave());
            if (isValid) {
                window.sessionStorage.setItem(`groupEdit_${this.props.group.id ? this.props.group.id : 0}`, null)
            }
        }
    }

    validate = () => {
        let fieldsWithError = [];

        if (!this.state.name) {
            fieldsWithError.push('name');
        }

        let groupMainSensorsPhases = this.state.groupMainSensorsPhases.map((sensorPhase) => {
            if (!sensorPhase.key && !sensorPhase.last){
                sensorPhase.keyError = true;
                fieldsWithError.push("main");
            }else if (sensorPhase.last && sensorPhase.name.trim() !== ""){
                sensorPhase.keyError = true;
                fieldsWithError.push("main");
            }else{
                sensorPhase.keyError = false;
            }
            return sensorPhase;
        });

        this.setState({
            fieldsWithError: fieldsWithError,
            groupMainSensorsPhases: groupMainSensorsPhases,
        });

        if (fieldsWithError.length) {
            this.props.openSnackBar('Some inputs are invalid or incomplete. Please correct them and try again.', 'warning')
        }

        return !fieldsWithError.length;
    }

    getGroupToSave = () => {
        const groupToSave = {
            ...this.props.group,
            name: this.state.name,
            tagId: this.state.tagId ? this.state.tagId : null,
            tariffId: this.state.tariffId,
            groupMainSensorsPhases: this.state.groupMainSensorsPhases,
        }

        if (this.state.id)
            groupToSave.id = this.state.id;

        return groupToSave;
    }

    changeName = ({ target: { name, value }}) => this.setState({
        [name]: value
    })

    changeTag = tag => {
        const found = !!tag && tag.value === tag.label && this.props.tags.find(ele => ele.label.toLowerCase() === tag.label.toLowerCase())

        this.setState({
            tagId: tag ? found ? found.value : tag.value : null,
        })

        if (tag && !found && tag.value === tag.label) {
            this.props.addTag(tag)
        }
    }

    changeTariff = tariff => this.setState({
        tariffId: tariff && tariff.value ? tariff.value : null
    })

    updateGroupMainSensorsPhases = (sensorsPhases) => {
        const available = this.selectedAvailableDiff(sensorsPhases, this.props.group.subgroups);
        this.setState({
            groupMainSensorsPhases: sensorsPhases,
            currentAvailableSensors: available,
        });
    }

    // returns the available to be assigned sensors, in comparison to the initial sensors available
    // and, for editing, to the unselected sensors
    selectedAvailableDiff = (groupMainSensorsPhases, subgroups) => {
        let availableSensors = this.props.availableSensors.filter((available) => {
            // the sensor was previoulsy selected as group main, then it is not available
            let selectedInGroupMain = false;
            if (groupMainSensorsPhases){
                groupMainSensorsPhases.forEach((sensorPhase) => {
                    if (sensorPhase && sensorPhase.key && sensorPhase.key === available.key){
                        selectedInGroupMain = true;
                    }
                });
            }

            let selectedInSubgroups = false;
            subgroups.forEach((subgroup) => {
                // the sensor was previoulsy selected in the subgroup main, then it is not available
                if (subgroup.mainSensorsPhases){
                    subgroup.mainSensorsPhases.forEach((sensorPhase) => {
                        if (sensorPhase && sensorPhase.key && sensorPhase.key === available.key)
                            selectedInSubgroups = true;
                    });
                }

                // the sensor was previoulsy selected in the subgroup subload sensors, then it is not available
                if (subgroup.subLoadSensorsPhases){
                    subgroup.subLoadSensorsPhases.forEach((sensorPhase) => {
                        if (sensorPhase && sensorPhase.key && sensorPhase.key === available.key)
                            selectedInSubgroups = true;
                    });
                }
            });

            if (selectedInGroupMain || selectedInSubgroups)
                return false;

            // the sensor was not selected, then it is available
            return true;
        });

        if (this.props.group.id){
            // sensors that came selected from the original group and subgroups on edit screen, but are not selected anymore,
            // are also set as available
            if (this.state.originalFlatKeyList && this.state.originalFlatKeyList.length > 0){
                const currentSensorsList = this.getFlatKeyList({groupMainSensorsPhases, subgroups}, true);
                const unselectedSensors = this.state.originalFlatKeyList.filter(sensor => {
                    return currentSensorsList.findIndex((s) => s.key === sensor.key) === -1
                });
                availableSensors = availableSensors.concat(unselectedSensors);
            }
        }

        return availableSensors.sort((a,b) => a.id - b.id);
    }

    getFlatKeyList = (state, asObjects) => {
        let flatSensorsList = [];

        if (state.groupMainSensorsPhases && state.groupMainSensorsPhases.length > 0){
            flatSensorsList = flatSensorsList.concat(state.groupMainSensorsPhases);
        }

        const subgroupsSensorsPhases = state.subgroups.map((subgroup) => {
            let sensors = [];
            if (subgroup.mainSensorsPhases && subgroup.mainSensorsPhases.length > 0)
                sensors = sensors.concat(subgroup.mainSensorsPhases);

            if (subgroup.subLoadSensorsPhases && subgroup.subLoadSensorsPhases.length > 0){
                sensors = sensors.concat(subgroup.subLoadSensorsPhases);
            }
            return sensors;
        });

        flatSensorsList = flatSensorsList.concat(...subgroupsSensorsPhases);
        flatSensorsList = flatSensorsList.filter(s => s.key !== '');//removes undefined elements

        if (asObjects === true)
            return flatSensorsList;

        return flatSensorsList.map(sensor => sensor.key);
    }

    render() {
        const { fieldsWithError, name, tagId, tariffId, currentAvailableSensors} = this.state;
        const { tags, tariffs, loadingTags, loadingTariffs, loadingSensors } = this.props;

        return (
            <section id="group-form">
                <Title element="h4" text={i18n.t('Group Configurations')} />
                <form action="#" onSubmit={this.submit}>
                    <div className="content">
                        <div>
                            <Input
                                change={this.changeName}
                                hasError={fieldsWithError.indexOf('name') >= 0}
                                name="name"
                                placeholder={i18n.t('Group Name')}
                                type="text"
                                value={name}
                            />
                        </div>

                        <div className="info-control">
                            <Select.Creatable
                                name="tag"
                                value={tagId}
                                onChange={this.changeTag}
                                disabled={loadingTags}
                                isLoading={loadingTags}
                                valueKey="value"
                                labelKey="label"
                                options={[...tags]}
                                placeholder={i18n.t('Tag (Optional)')}
                                noResultsText={i18n.t('No results found')}
                            />
                            <small>
                                <Info />
                                {i18n.t('The selected tags at any subgroup will not be listed here. Please remove the tag from its respective subgroup to be able to assign it for this group.')}
                            </small>
                        </div>

                        <div>

                            <Select
                                name="tariff"
                                value={tariffId}
                                onChange={this.changeTariff}
                                disabled={loadingTariffs}
                                isLoading={loadingTariffs}
                                valueKey="value"
                                labelKey="label"
                                options={tariffs}
                                placeholder={i18n.t('Tariff (Optional)')}
                                noResultsText={i18n.t('No results found')}
                            />
                        </div>
                        <div>
                            <SensorsPhasesAdd
                                mode="totalLoad"
                                availableSensors={this.state.currentAvailableSensors}
                                sensorsPhases={this.state.groupMainSensorsPhases}
                                changeSensorsPhases={this.updateGroupMainSensorsPhases}
                                loadingSensors={loadingSensors}
                                editMode={!!this.props.group.id}
                                tariffs={tariffs}
                            />
                            {(!loadingSensors && (!currentAvailableSensors || (currentAvailableSensors && !currentAvailableSensors.length))) &&
                                <div className="info-control no-sensor-left-warning">
                                    <small>
                                        <Info />
                                        {this.noSensorsPhasesLeftMsg}
                                    </small>
                                </div>
                            }
                        </div>
                    </div>
                </form>
            </section>
        );
    }
}

GroupForm.propTypes = {
    classes: PropTypes.object,
};

export default GroupForm;
