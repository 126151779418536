import React, {PureComponent} from 'react';
import {TariffComposition} from './tariff-composition';
import {max} from 'd3-array';

import i18n from '../../i18n';

import './add-tariff-composition.css';

const baseTariffComposition = {
    seq: 0,
    // id: 0,
    weekday: null,
    startTime: null,
    endTime: null,
    rate: '',
    limit: null,
    limitValue: '',
    standingCharge: '',
    fieldsWithError: [],
    last: true,
}

class TariffCompositionsAdd extends PureComponent{
    state = {
        id: null,
        fieldsWithError: [],
    }

    componentDidMount() {
        if (this.props.tariffCompositions.length) {
            return this.props.changeTariffCompositions(
                this.props.tariffCompositions.map(composition => ({
                    ...composition,
                    weekday: composition.dayOfWeek ? this.weekdays.find(({ value }) => value === composition.dayOfWeek) : null,
                    limit: composition.limitType ? this.limits.find(({ value }) => value === composition.limitType) : null,
                    limitValue: composition.limitValue ? composition.limitValue : '',
                    standingCharge: composition.standingCharge ? composition.standingCharge : '',
                })),
                this.createNewRowIfNeeded
            )
        }
        this.createNewRowIfNeeded();
    }

    limits = [
        {
            label: i18n.t('More than or equal to'),
            value: 'MORE_THAN_OR_EQUAL'
        },
        {
            label: i18n.t('Less than or equal to'),
            value: 'LESS_THAN_OR_EQUAL'
        },
    ];

    weekdays = [
        {
            label: i18n.t('Monday'),
            value: 'MONDAY',
        },
        {
            label: i18n.t('Tuesday'),
            value: 'TUESDAY',
        },
        {
            label: i18n.t('Wednesday'),
            value: 'WEDNESDAY',
        },
        {
            label: i18n.t('Thursday'),
            value: 'THURSDAY',
        },
        {
            label: i18n.t('Friday'),
            value: 'FRIDAY',
        },
        {
            label: i18n.t('Saturday'),
            value: 'SATURDAY',
        },
        {
            label: i18n.t('Sunday'),
            value: 'SUNDAY',
        },
    ];

    changeField = (field, value, seq) => {
        const { tariffCompositions }  = this.props;
        const i = tariffCompositions.findIndex(composition => composition.seq === seq);
        const composition = {
            ...tariffCompositions[i],
            [field]: value,
        }

        const updatedCompositions = [].concat(tariffCompositions);
        updatedCompositions[i] = composition;

        this.props.changeTariffCompositions(updatedCompositions, this.createNewRowIfNeeded)
    }

    changeWeekday = (weekday, seq) => {
        this.changeField('weekday', weekday, seq);
    }

    changeStartTime = (startTime, seq) => {
        this.changeField('startTime', startTime, seq);
    }

    changeEndTime  = (endTime, seq) => {
        this.changeField('endTime', endTime, seq);
    }

    changeRate  = (rate, seq) => {
        this.changeField('rate', rate, seq);
    }

    changeLimit = (limit, seq) => {
        this.changeField('limit', limit, seq);
    }

    changeLimitValue = (limitValue, seq) => {
        this.changeField('limitValue', limitValue, seq);
    }

    changeStandingCharge = (standingCharge, seq) => {
        this.changeField('standingCharge', standingCharge, seq);
    }

    hasAnyFilledInput = (composition) => {
        const { weekday, startTime, endTime, rate, limit, limitValue, standingCharge } = composition;
        if (weekday !== null
            || startTime !== null || endTime !== null
            || rate !== ''
            || limit !== null || limitValue !== ''
            || standingCharge !== ''){
            return true;
        }
        return false;
    }

    shouldCreateNewRow = () => {
        const { tariffCompositions } = this.props;

        if (!tariffCompositions || (tariffCompositions && tariffCompositions.length === 0)){
            return true;
        }

        if (tariffCompositions && tariffCompositions.length > 0){
            const lastComposition = tariffCompositions[tariffCompositions.length - 1];
            if (this.hasAnyFilledInput(lastComposition)){
                return true;
            }
        }
        return false;
    }

    addTariffComposition = () => {
        let compositions = [].concat(this.props.tariffCompositions);
        let lastSeq = 0;

        if (compositions && compositions.length > 0){
            lastSeq = max(compositions, (d) => d.seq);
            lastSeq++;
        }

        const composition = Object.assign({}, baseTariffComposition, { seq: lastSeq });
        compositions.push(composition);

        this.props.changeTariffCompositions(this.checkLast(compositions))
    }

    removeTariffComposition = (seq) => {
        let compositions = [].concat(this.props.tariffCompositions);
        compositions.splice(compositions.findIndex((composition) => composition.seq === seq), 1);

        this.props.changeTariffCompositions(this.checkLast(compositions))
    }

    createNewRowIfNeeded = () => {
        if (this.shouldCreateNewRow())
            this.addTariffComposition();
    }

    checkLast = (compositions) => {
        if (compositions && compositions.length > 0){
            const lastSeq = max(compositions, (d) => d.seq);
            compositions = compositions.map(composition => ({
                ...composition,
                last: composition.seq === lastSeq
            }));
        }
        return compositions;
    }

    renderCompositions = () => {
        const {tariffCompositions} = this.props;

        return (!!tariffCompositions && !!tariffCompositions.length && tariffCompositions.map(composition =>
            (<TariffComposition
                key={composition.seq}
                seq={composition.seq}
                changeWeekday={this.changeWeekday}
                changeStartTime={this.changeStartTime}
                changeEndTime={this.changeEndTime}
                changeRate={this.changeRate}
                changeLimit={this.changeLimit}
                changeLimitValue={this.changeLimitValue}
                changeStandingCharge={this.changeStandingCharge}
                removeTariffComposition={this.removeTariffComposition}
                weekdays={this.weekdays}
                weekday={composition.weekday}
                startTime={composition.startTime}
                endTime={composition.endTime}
                rate={composition.rate}
                limits={this.limits}
                limit={composition.limit}
                limitValue={composition.limitValue}
                standingCharge={composition.standingCharge}
                fieldsWithError={composition.fieldsWithError}
                isLast={composition.last}
                createNewRowIfNeeded={this.createNewRowIfNeeded}
            />)
        ));
    }

    render () {
        return (
            <table id="tariff-composition-table">
                <thead>
                    <tr>
                        <td className="weekday-column">{i18n.t('Weekday')}</td>
                        <td className="time-column">{i18n.t('Start Time')}</td>
                        <td className="time-column">{i18n.t('End Time')}</td>
                        <td className="number-column">{i18n.t('Rate ({{currencySymbol}}/kWh)', { currencySymbol: this.props.currencySymbol})}</td>
                        <td className="limit-column">{i18n.t('Limit (kWh/month)')}</td>
                        <td className="number-column" />
                        <td className="standing-charge-column">{i18n.t('Standing Charge ({{currencySymbol}}/day)', {currencySymbol: this.props.currencySymbol})}</td>
                        <td className="delete-column" />
                    </tr>
                </thead>
                <tbody>{this.renderCompositions()}</tbody>
            </table>
        )
    }

}

export {TariffCompositionsAdd}
