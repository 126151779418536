import React from 'react';
import moment from 'moment';
import {injectIntl, intlShape} from 'react-intl';
import Select from 'react-select';
import Button from 'material-ui/Button';

import IconButton from 'material-ui/IconButton';
import ExpandMoreIcon from 'material-ui-icons/ArrowDropDown';

// import { injectIntl, intlShape } from 'react-intl';
import i18n from '../../../i18n';
import {API} from '../../../components/api';
import {getSensorStructured, getMultiphaseSensorStructured} from '../../../components/utils';
import ChartLoading from '../chart-loading';
import DatePicker from '../filter-date-picker';

import './power-peak-big-number-chart.css';
import 'react-select/dist/react-select.css';

class PowerPeakBigNumberChart extends React.Component{
    constructor (props){
        super(props);

        let today = moment().subtract(1, 'day');

        this.state = {
            datePicker: {
                open: false,
                endDate: today,
                startDate: today,
                data: {
                    endDate: today,
                    focusedInput: 'startDate',
                    startDate: today,
                },
            },
            dateRangePicker: {
                open: false,
                endDate: today,
                startDate: today.clone().subtract(1, 'week'),
                data: {
                    endDate: today,
                    focusedInput: 'endDate',
                    startDate: today.clone().subtract(1, 'week'),
                },
            },
            loadingSensors: true,
            noSensorsToLoad: false,
            sensors: [],
            peakTimestamp: null,
            peakActivePower: null,
            filterSensor: null,
            loadingData: true,
            errorLoadingData: false,
            periodFilter: 'day',
        }
    }

    componentWillMount = () => {
        API.SENSORS.GET().then(({data}) => {
            if (data && data.length > 0){
                this.setState({
                    sensors: this.getSensorsList(data),
                    loadingSensors: false,
                }, this.checkGeneralChangesOnComponent);
            }else if (data && data.length === 0){
                this.setState({
                    loadingSensors: false,
                    noSensorsToLoad: true,
                });
            }else{
                this.handleLoadError({loadingSensors: false, errorLoadingData: true}, i18n.t("Could not load sensors data"))
            }
        }).catch((error) => {
            this.handleLoadError({loadingSensors: false, errorLoadingData: true}, i18n.t("Could not load sensors data"), error)
        });
    }

    componentWillReceiveProps (nextProps) {
        this.checkGeneralChangesOnComponent(nextProps);
    }

    periodOptions = [
        {label: i18n.t('Day'), value: 'day'},
        {label: i18n.t('Custom'), value: 'custom'},
    ];

    loadErrorMessage = i18n.t("An error occurred while retrieving data");
    noDataChartMessage = i18n.t("There is no data available for the selected criteria");
    noSensorChartMessage = i18n.t("There is no data available as no sensor has been set up");

    getSensorsList = (sensors) => {
        let sensorsList = [];

        if (sensors && sensors.length){
            // if the sensor has only one channel (phase), won't be displayed as multiphase,
            // only as sensor-phase, on sensorPhasesList below
            sensorsList = sensors.filter(sensor => sensor.channels.length > 1).map(sensor => ({
                ...getMultiphaseSensorStructured(sensor),
            }));

            const sensorPhasesList = sensors.reduce((list, sensor) => {
                if (sensor.channels && sensor.channels.length){
                    const sensorPhases = sensor.channels.map(sensor => ({
                        ...sensor,
                        ...getSensorStructured(sensor),
                        label: this.getBreadcrumbsTrailFromHierarchy(sensor)
                    }));

                    list = [...list, ...sensorPhases];
                }
                return list;
            }, []);

            sensorsList = [...sensorsList, ...sensorPhasesList];
        }

        return sensorsList;
    }

    getDateURL = (period=this.state.periodFilter, date) => {
        date = date ? date : period === 'custom' ? this.state.dateRangePicker : this.state.datePicker;

        if (period === 'custom'){
            let url = "";
            url += date.startDate ?
                `?startDate=${date.startDate.format('YYYY-MM-DD')}` : '';
            url += date.endDate ?
                `&endDate=${date.endDate.format('YYYY-MM-DD')}` : '';

            return url;
        }else{
            return date.startDate ?
                `?date=${date.startDate.format('YYYY-MM-DD')}` : '';
        }
    }

    getSearchURL = () => {
        return this.getPeriodFilterURL(false, this.getDateURL());
    }

    getPeriodFilterURL = (fromSelect, url, period=this.state.periodFilter, date) => {
        if (fromSelect){
            url = this.props.location.pathname + this.getDateURL(period, date);
        }

        url.indexOf('period') >= 0 ? url = url.replace(/day|custom/, period) : url += `&period=${period}`;

        return url;
    }

    getBreadcrumbsTrailFromHierarchy = (sensor) => {
        const errorMessage = i18n.t("Unknown group/sensor");
        let error = false;

        let breadcrumbsText = '';
        if (sensor) {
            if (sensor.parentGroupName)
                breadcrumbsText = sensor.parentGroupName;
            if (sensor.groupName)
                if (sensor.parentGroupName)
                    breadcrumbsText += '/'
                breadcrumbsText += sensor.groupName ? sensor.groupName : '';
            if (sensor.sensorId && sensor.channel){
                breadcrumbsText += (breadcrumbsText ? '/' : '') + sensor.sensorId + '-' + sensor.channel;
                if (sensor.sensorChannelName)
                    breadcrumbsText += ' - ' + sensor.sensorChannelName;
            }
            if (breadcrumbsText === '')
                error = true;
        } else {
            error = true;
        }

        if (error){
            breadcrumbsText = errorMessage;
            console.warn("A wrong breadcrumb format was received and no breadcrumb text could be created for Alerts Events list");
        }

        return breadcrumbsText;
    }

    changePeriodFilter = (period) => {
        let url = this.getPeriodFilterURL(true, '', period ? period.value : 'day');
        this.props.history.push(url);
    }

    changeSensor = (selectedSensor) => {
        let url = `/analysis/power/peak`;
        if (!selectedSensor) {
            return this.props.history.push(url + this.getSearchURL())
        }

        url += `/sensor/${selectedSensor.id}`;

        if (selectedSensor.channel)
            url += `/${selectedSensor.channel}`;

        url += this.getSearchURL();

        this.props.history.push(url);
    }

    getSensorSelectedById = (id, channel, state) => {
        let sensor = state.sensors.find(sensor => sensor.id === id && sensor.channel === channel);

        if (id && !sensor) {
            let url = `/analysis/power/peak/sensor/${state.sensors[0].id}`;

            if (state.sensors[0].channel)
                url += `/${this.state.sensors[0].channel}`;

            this.props.history.push(url);
            return;
        }

        return {
            filterSensor: sensor,
        };
    }

    checkGeneralChangesOnComponent = (nextProps) => {
        const props = nextProps || this.props;
        if (!props.match.params.sensorId && this.state.sensors && this.state.sensors.length) {
            let url = `${this.props.history.location.pathname}/sensor/${this.state.sensors[0].id}`

            if (this.state.sensors[0].channel)
                url += `/${this.state.sensors[0].channel}`;

            this.props.history.push(url);
            return;
        }

        let sensorData, startDate, endDate, URLToSearchParams;
        let state = {
            datePicker: this.state.datePicker,
            dateRangePicker: this.state.dateRangePicker,
            sensors: this.state.sensors
        };

        URLToSearchParams = props.location.search ?
            props.location.search : props.location.pathname.indexOf('?') >= 0 ?
                props.location.pathname.slice(props.location.pathname.indexOf('?')) :
                props.location.pathname;
        const searchParams = new URLSearchParams(URLToSearchParams);

        if (props.match.params.sensorId) {
            const channel = props.match.params.channel ? parseInt(props.match.params.channel, 0) : null;
            sensorData = this.getSensorSelectedById(parseInt(props.match.params.sensorId, 0), channel, state);
            if (!sensorData) return;
            Object.assign(state, sensorData);
        }

        state.periodFilter = searchParams.get('period') ?
            searchParams.get('period') : props.location.pathname.indexOf('period=custom') >= 0 ? 'custom' : 'day';
        if (searchParams.get('date')) {
            startDate = moment(searchParams.get('date'));

            if (state.periodFilter === 'week') {
                startDate = startDate.startOf('week');
                endDate = startDate.clone().endOf('week');
                endDate = endDate.isAfter(this.maximumDate) ? this.maximumDate : endDate;
            } else endDate = startDate;

            state.datePicker.open = false;
            state.datePicker.startDate = startDate;
            state.datePicker.endDate = endDate;
            state.datePicker.data.startDate = startDate;
            state.datePicker.data.endDate = endDate;
        }

        if (searchParams.get('startDate') && searchParams.get('endDate')) {
            startDate = moment(searchParams.get('startDate'));
            endDate = moment(searchParams.get('endDate'));

            state.dateRangePicker.open = false;
            state.dateRangePicker.startDate = startDate;
            state.dateRangePicker.endDate = endDate;
            state.dateRangePicker.data.startDate = startDate;
            state.dateRangePicker.data.endDate = endDate;
        }

        this.setState(state);
        this.loadPeakData(state);
    }

    loadPeakData = (state) => {
        this.setState({ loadingData: true, errorLoadingData: false });

        let startDate, endDate;

        if (state.periodFilter === 'day'){
            startDate = state.datePicker.startDate.format('YYYY-MM-DD');
            endDate = state.datePicker.endDate.format('YYYY-MM-DD');
        }else{
            startDate = state.dateRangePicker.startDate.format('YYYY-MM-DD');
            endDate = state.dateRangePicker.endDate.format('YYYY-MM-DD');
        }

        const sensorId = state.filterSensor ? state.filterSensor.id : null;
        const channel = state.filterSensor ? state.filterSensor.channel : null;

        API.VISUALIZATIONS.POWER.PEAK.SENSOR(sensorId, channel, startDate, endDate).then((response) => {
            if (response.data) {
                const data = response.data;
                this.setState({
                    loadingData: false,
                    peakActivePower: data.activePower,
                    peakTimestamp: data.timestampTz,
                });
            }else{
                this.handleLoadError({loadingData: false, errorLoadingData: true}, i18n.t("Could not load chart data"))
            }
        }).catch((error) => {
            this.handleLoadError({loadingData: false, errorLoadingData: true}, i18n.t("Could not load chart data"), error)
        });
    }

    updateDatePicker = (datePicker) => {
        this.setState({datePicker});
    }

    updateDateRangePicker = (dateRangePicker) => {
        this.setState({dateRangePicker});
    }

    goToPreviousPeriod = () => {
        let {datePicker} = this.state;
        datePicker.startDate.subtract(1, this.state.periodFilter);
        this.props.history.push(this.getSearchURL());
    }

    goToNextPeriod = () => {
        let { datePicker } = this.state;
        datePicker.startDate.add(1, this.state.periodFilter);
        this.props.history.push(this.getSearchURL());
    }

    handleLoadError = (state, message, error) => {
        state.loadErrorMessage = this.loadErrorMessage;
        this.setState(state);

        console.error(this.loadErrorMessage + " (" + message + ")");
        if (error)
            console.error(error);
    }

    isOutsideRange = date => date.isSameOrAfter(moment().startOf('day'))

    render (){
        const {peakTimestamp, peakActivePower, loadingData, loadingSensors, sensors, periodFilter, errorLoadingData, noSensorsToLoad} = this.state;

        return (
            <div id="dashboard" className="power-peak-chart">
                <div id="power-peak-chart">
                    <div id="top-bar">
                        <div className={`top top-period-filter-${periodFilter}`}>
                            <div>
                                {this.periodOptions.map((period, key) => (
                                    <Button className={
                                        `btn-period-filter ${period.value} ${periodFilter === period.value ? ' active' : ''}`}
                                        disabled={
                                            loadingData ||
                                            periodFilter === period.value
                                        }
                                        key={key}
                                        onClick={() => this.changePeriodFilter(period)}>
                                        {period.label}
                                    </Button>
                                ))}
                            </div>

                            <div>

                                {periodFilter === 'day' ?
                                    <IconButton
                                        className="btn-previous btn-previous-date"
                                        disabled={loadingData}
                                        onClick={this.goToPreviousPeriod}
                                    >
                                        <ExpandMoreIcon />
                                    </IconButton>
                                : null}

                                <DatePicker
                                    hideTodayButton
                                    chartType="bar-chart"
                                    datePicker={this.state.datePicker}
                                    dateRangePicker={this.state.dateRangePicker}
                                    updateDatePicker={this.updateDatePicker}
                                    updateDateRangePicker={this.updateDateRangePicker}
                                    periodFilter={periodFilter}
                                    isLinear={false}
                                    showParent={true}
                                    loadingGroups={loadingSensors}
                                    history={this.props.history}
                                    disabled={!loadingSensors && sensors.length === 0}
                                    showLabel={false}
                                    dataGranularity={''}
                                    isOutsideRange={this.isOutsideRange}
                                />

                                {periodFilter === 'day' ?
                                    <IconButton className="btn-next btn-next-date"
                                        disabled={
                                            loadingData ||
                                            this.state.datePicker.startDate.isSameOrAfter(
                                                moment().startOf(periodFilter).subtract(1, 'day'))
                                        }
                                        onClick={this.goToNextPeriod}>
                                        <ExpandMoreIcon />
                                    </IconButton>
                                : null}
                            </div>
                            <div />
                        </div>
                        {!loadingSensors && (
                            <div id="group-filters">
                                <div id="compare-card" className="bottom">
                                    <Select
                                        name="sensorPhaseFilter"
                                        value={this.state.filterSensor}
                                        onChange={sensor => this.changeSensor(sensor)}
                                        disabled={!sensors || !sensors.length}
                                        valueKey="key"
                                        labelKey="label"
                                        options={sensors}
                                        placeholder={i18n.t('Select any sensor-phase (type to search)')}
                                        noResultsText={i18n.t('No results found')}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div id="graph">
                        {noSensorsToLoad ?
                            <div className="nv-chart no-chart-msg">
                                <p className="no-data">{this.noSensorChartMessage}</p>
                            </div>
                        :
                            loadingSensors ?
                                <ChartLoading loading={loadingData} />
                            :
                                loadingData ?
                                    <ChartLoading loading={loadingData} />
                                :
                                    peakActivePower && peakTimestamp ?
                                        <div id="big-number-chart">
                                            <h3 id="chart-title">
                                                {i18n.t('The peak demand was of')}
                                            </h3>
                                            <h1 id="chart-peak-value">
                                                {peakActivePower + ' '}
                                                <small>{i18n.t('kW')}</small>
                                            </h1>
                                            <h3 id="chart-peak-timestamp">
                                                {peakTimestamp
                                                    ? i18n.t('on {{peak_time}}', { peak_time: moment(peakTimestamp).format('LLLL') })
                                                    : ''}
                                            </h3>
                                        </div>
                                    :
                                        <div className="nv-chart no-chart-msg">
                                            <p className="no-data">{errorLoadingData ? this.loadErrorMessage : this.noDataChartMessage}</p>
                                        </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

PowerPeakBigNumberChart.propTypes = {
    intl: intlShape.isRequired,
}

export default injectIntl(PowerPeakBigNumberChart);
