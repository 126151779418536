import React from 'react';
import d3 from 'd3';
import NVD3Chart from 'react-nvd3';
import moment from 'moment-timezone';
import { injectIntl, intlShape } from 'react-intl'

import {nest} from 'd3-collection';
import {utcDays} from 'd3-time';
import {format} from 'd3-format';
import {scaleTime} from 'd3-scale';
import ChartLoading from '../chart-loading';
import {colors} from './cost-simulation';

import i18n from '../../../i18n';

import 'nvd3/build/nv.d3.min.css';
import './cost-simulation-line-chart.css';

const yTickFormat = (d) => format(".2f")(d);
const costNumberFormat = (d) => format(".2f")(d);

class CostSimulationLineChart extends React.Component {
    constructor (props) {
        super(props);

        this.loadErrorMessage = i18n.t('An error occurred while retrieving data.');
        this.loadingChartMessage = i18n.t('Loading data...');
        this.noDataChartMessage = i18n.t("There is no consumption data available for the selected criteria. Cost simulation can't be calculated in this case.");
        this.noGroupChartMessage = i18n.t('There is no data available as no group has been set up.');
        this.noTariffChartMessage = i18n.t("There is no data available as no tariff has been set up. Cost simulation can't be calculated in this case.");

        this.state = {
            noDataChartMsg: this.loadingChartMessage,
            xTicks: [],
            xDomain: [],
            data:[],
            currency: "",
            screenWidth: 0,
            screenHeight: 0,
        };

        this.costFormat = (currency, value) => {
            return currency && value > 0 ? `${this.props.intl.formatNumber(value, {style: 'currency', currency: currency})}` : ' - '
        }
    }

    componentWillMount = async () => {
        this.updateDimensions();
    }

    componentDidMount () {
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillReceiveProps (nextProps) {
        if (this.shouldUpdateChart(nextProps)){
            const rawData = nextProps.rawData;
            const data = (rawData && rawData.length)
                ? rawData.reduce((acc, rawDatum, index) => acc.concat(this.createCostData(rawDatum, index)), [])
                : [];

            const state = {
                data: data,
                xDomain: this.getXDomain(),
                xTicks: this.getXTicks(),
            }

            if (nextProps.loadError)
                state.noDataChartMsg = this.loadErrorMessage;

            this.setState({...state});
        }
    }

    shouldComponentUpdate(nextProps){
        return (nextProps && nextProps.loadingData) || this.shouldUpdateChart(nextProps) || !!this.props.noTariffSetupError;
    }

    componentWillUpdate(nextProps, nextState){
        // screen was resized
        if (nextState.screenWidth !== this.state.screenWidth || nextState.screenHeight !== this.state.screenHeight){
            // remove existing highlights to force redraw
            d3.selectAll('.nv-chart svg .weekend-highlight').remove();
            d3.selectAll('.nv-chart svg .after-work-highlight').remove();
        }
    }

    componentDidUpdate () {
        // fixes tooltip mark that stayed on the chart when changing data
        d3.selectAll('.nvtooltip').remove();

        if (this.props.periodFilter === 'day'){
            this.addAfterWorkHighlight();
        }

        if (this.props.periodFilter === 'week' || this.props.periodFilter === 'month' || this.props.periodFilter === 'custom'){
            // when in 'custom' period, it avoids executing when selecting start date of the range:
            if (this.props.shouldAddWeekendhighlight)
                this.addWeekEndsHighlight();
        }
    }

    componentWillUnmount () {
        window.removeEventListener("resize", this.updateDimensions);
    }

    shouldUpdateChart = (nextProps) => {
        return !nextProps.loadingData && nextProps.simulationIndex !== this.props.simulationIndex && nextProps.totalData === nextProps.rawData.length;
    }

    // Based on https://stackoverflow.com/a/34475071
    updateDimensions = () => {
        let w = window,
        d = document,
        documentElement = d.documentElement,
        body = d.getElementsByTagName('body')[0],
        width = w.innerWidth || documentElement.clientWidth || body.clientWidth,
        height = w.innerHeight|| documentElement.clientHeight|| body.clientHeight;

        this.setState({
            screenWidth: width,
            screenHeight: height,
        });
    }

    xTickFormat = (d) => {
        let format = '';
        switch (this.props.periodFilter){
            case 'month':
                format = '[LM]';
                break;
            case 'week':
                format = 'ddd DD';
                break;
            case 'day':
                format = 'LT';
                break;
            case 'custom':
                if (this.props.diffOfSelectedDays <= 1){
                    format = '[LDDMMHHmm]';
                }else{
                    format = '[LM]';
                }
                break;
            default:
                // day format
                format = 'LT';
                console.warn("An invalid x tick format was found. Assuming day x tick format.");
        }
        return moment(d).format(format);
    }

    tooltipHeaderFormatter = (strDate) => {
        let format = '';
        switch (this.props.periodFilter) {
            case 'month':
                format = '[llll-b]';
                break;
            case 'week':
                format = '[llll-b]';
                break;
            case 'day':
                format = 'LT';
                break;
            case 'custom':
                format = (this.props.diffOfSelectedDays <= 1) ? 'LLLL' : '[llll-b]'
                break;
            default:
                format = 'LT';// day format
                console.warn("An invalid period filter was found. Assuming day format.");
        }
        return moment(strDate).format(format);
    }

    getXDomain = () => {
        // this forces the x axis to go from 12AM to 12PM, when period is day, sunday to saturday, when week, each month day, when month,
        // even if the data is partial for the period
        // NVD3 forceX function only understands it if we get the numeric value of the date object
        let domain = [], date;

        switch (this.props.periodFilter) {
            case 'month':
                const monthStart = this.props.datePicker.startDate.clone().startOf('month');
                const monthEnd = this.props.datePicker.startDate.clone().endOf('month');
                domain = [monthStart.valueOf(), monthEnd.valueOf()];
                break;
            case 'week':
                date = this.props.datePicker.startDate.clone().startOf('week');
                domain = [date.valueOf(), date.add(7, 'days').valueOf()];
                break;
            case 'day':
                date = this.props.datePicker.startDate.clone().startOf('day');
                domain = [date.valueOf(), date.add('1', 'days').valueOf()];
                break;
            case 'custom':
                const periodStart = this.props.dateRangePicker.startDate.clone().startOf('day');
                const periodEnd = this.props.dateRangePicker.endDate.clone().endOf('day');
                domain = [periodStart.valueOf(), periodEnd.valueOf()];
                break;
            default:
                // day domain
                date = this.props.datePicker.startDate.clone().startOf('day');
                domain = [date.valueOf(), date.add('1', 'days').valueOf()];
                console.warn("An invalid x domain format was found. Assuming day x domain.");
        }
        return domain;
    }

    getXTicks = () => {
        let xTicks = [];
        switch (this.props.periodFilter) {
            case 'month':
                const monthStart = this.props.datePicker.startDate.clone().startOf('month');
                const monthEnd = this.props.datePicker.startDate.clone().endOf('month');
                xTicks = utcDays(
                    monthStart,
                    monthEnd,
                ).map((value) => moment(value).valueOf());
                break;
            case 'week':
                let momentDate = this.props.datePicker.startDate.clone();
                xTicks = utcDays(
                    momentDate.startOf('week'),
                    momentDate.clone().add(7, 'days')
                ).map((value) => moment(value).valueOf());
                break;
            case 'day':
                xTicks = [];
                break;
            case 'custom':
                xTicks = [];
                if (this.props.diffOfSelectedDays > 1){
                    const periodStart = this.props.dateRangePicker.startDate.clone().startOf('day');
                    const periodEnd = this.props.dateRangePicker.endDate.clone().endOf('day');
                    xTicks = utcDays(
                        periodStart,
                        periodEnd
                    ).map((value) => moment(value).valueOf());
                }
                break;
            default:
                xTicks = [];
                console.warn("An invalid period filter was found for x ticks. Returning no ticks.");
        }
        return  xTicks;
    }

    createDatetime = (data) => data.map((d) => ({
        ...d,
        time: moment(this.state.periodFilter !== 'month' ? d.dayTz + "T" + d.hour : d.dayTz + "T00:00:00").toDate(),
    }));

    /* Creates chart data for tariffs */
    createCostData = (costData, i) => {
        let tariffEntries = [];

        if (costData.data && costData.data.length >= 1){
            const timeSortedData = this.createDatetime(costData.data).sort((a, b) => moment(a.time).diff(moment(b.time)));
            const dataWithTariff = timeSortedData.map((d) => ({
                ...d,
                id: costData.tariffs[0].id+'_'+i,
                tariff: costData.tariffs[0],
            }));
            tariffEntries = nest().key((d) => d.id).entries(dataWithTariff);
        }

        // check if single point and add class to show circle on point
        if (tariffEntries.length && tariffEntries[0].values && tariffEntries[0].values.length === 1)
            tariffEntries[0] = {...tariffEntries[0], classed: 'nv-single-point-line'};

        return tariffEntries;
    }

    addWeekEndsHighlight = () => {
        const renderedInnerChartG = d3.select(".nv-chart svg g.nv-background");

        // the chart was already created'
        if (renderedInnerChartG && renderedInnerChartG.node()){
            const existingHighlights = d3.select(".nv-chart svg g g .weekend-highlight")[0][0];

            // no highlight rects were added yet
            if (existingHighlights === null){
                let weekEndData = [];
                const chartDimensions = renderedInnerChartG.node().getBBox();
                const xScale = scaleTime().domain(this.getXDomain()).range([0, chartDimensions.width]);

                if (this.props.periodFilter === 'week'){
                    let sunday = {};
                    let left = xScale(moment(this.props.datePicker.startDate.clone().startOf('week').valueOf()).toDate());
                    let right = xScale(moment(this.props.datePicker.startDate.clone().startOf('week').add(1, 'days').valueOf()).toDate());
                    let width = right - left;
                    sunday.xStart = left;
                    sunday.width = width;

                    let saturday = {};
                    let left2 = xScale(moment(this.props.datePicker.startDate.clone().endOf('week').subtract(1, 'days').valueOf()).toDate());
                    let right2 = xScale(moment(this.props.datePicker.startDate.clone().endOf('week').valueOf()).toDate());
                    let width2 = right2 - left2;
                    saturday.xStart = left2;
                    saturday.width = width2;

                    weekEndData.push(sunday);
                    weekEndData.push(saturday);
                }

                if (this.props.periodFilter === 'month'){
                    const lastMonthDay = this.props.datePicker.startDate.clone().endOf('month');
                    let currentDay = this.props.datePicker.startDate.clone().startOf('month');

                    const weekEndDays = [];
                    while (currentDay.isSameOrBefore(lastMonthDay, 'day')){
                        // if (currentDay.day() === 6)
                        if (currentDay.day() === 0 || currentDay.day() === 6)
                            weekEndDays.push(currentDay.clone());
                        currentDay.add(1, 'days');
                    }

                    weekEndDays.forEach(weekEndDay => {
                        let weekEndDayData = {};
                        //if the first day of month, doesn't apply the 12 hours left margin
                        const start = (weekEndDay.format('DD') === "01") ? weekEndDay.clone().startOf('day') : weekEndDay.clone().startOf('day');
                        let left = xScale(moment(start.valueOf()).toDate());
                        let right = xScale(moment(weekEndDay.clone().startOf('day').add(1, 'days').valueOf()).toDate());
                        let width = right - left;
                        weekEndDayData.xStart = left;
                        weekEndDayData.width = width;
                        weekEndData.push(weekEndDayData);
                    });
                }

                if (this.props.periodFilter === 'custom'){
                    const firstPeriodDay = this.props.dateRangePicker.startDate.clone().startOf('day');
                    const lastPeriodDay = this.props.dateRangePicker.endDate.clone().endOf('day');
                    let currentDay = this.props.dateRangePicker.startDate.clone().startOf('day');

                    const weekEndDays = [];
                    while (currentDay.isSameOrAfter(firstPeriodDay, 'day') && currentDay.isSameOrBefore(lastPeriodDay, 'day')){
                        if (currentDay.day() === 0 || currentDay.day() === 6)
                            weekEndDays.push(currentDay.clone());
                        currentDay.add(1, 'days');
                    }

                    weekEndDays.forEach((weekEndDay, i) => {
                        let weekEndDayData = {};
                        let left, right;

                        //for periods greater than 31 days, uses month highlight logic (one day granularity)
                        if (this.props.diffOfSelectedDays > 30){//diff of 30 === 31 days selected
                            //if the first day of period is weekend day, doesn't apply the 12 hours left margin
                            const start = (i === 0 && firstPeriodDay.isSame(weekEndDay)) ? weekEndDay.clone().startOf('day') : weekEndDay.clone().startOf('day');
                            left = xScale(moment(start.valueOf()).toDate());
                            right = xScale(moment(weekEndDay.clone().startOf('day').add(1, 'day').valueOf()).toDate());
                        } else {
                            left = xScale(moment(weekEndDay.clone().startOf('day').valueOf()).toDate());
                            right = xScale(moment(weekEndDay.clone().endOf('day').valueOf()).toDate());
                        }

                        let width = right - left;
                        weekEndDayData.xStart = left;
                        weekEndDayData.width = width;
                        weekEndData.push(weekEndDayData);
                    });
                }

                if (weekEndData.length > 0){
                    const group = d3.select(".nv-chart svg g g");

                    group.selectAll("rect.weekend-highlight")
                        .data(weekEndData)
                        .enter()
                        .append("rect")
                        .classed("weekend-highlight", true)
                        .attr("x", (d) => d.xStart)
                        .attr("y", 0)
                        .attr("width", (d) => d.width )
                        .attr("height", chartDimensions.height )
                        .attr("fill", "#d8d8d8")
                        .attr("opacity", 0.2);
                }
            }
        }
    }

    addAfterWorkHighlight = () => {
        const renderedInnerChartG = d3.select(".nv-chart svg g.nv-background");

        // the chart was already created'
        if (renderedInnerChartG && renderedInnerChartG.node()){
            const existingHighlights = d3.select(".nv-chart svg g g .after-work-highlight")[0][0];

            // no highlight rects were added yet
            if (existingHighlights === null){
                let afterWork = [];
                const chartDimensions = renderedInnerChartG.node().getBBox();
                const xScale = scaleTime().domain(this.getXDomain()).range([0, chartDimensions.width]);
                let currentDay = this.props.datePicker.startDate.clone();

                if (this.props.periodFilter === 'day' && currentDay.day() !== 0 && currentDay.day() !== 6){
                    let morning = {};
                    let left = xScale(moment(this.props.datePicker.startDate.clone().startOf('day').valueOf()).toDate());
                    let right = xScale(moment(this.props.datePicker.startDate.clone().startOf('day').add(8, 'hours').valueOf()).toDate());
                    let width = right - left;
                    morning.xStart = left;
                    morning.width = width;

                    let evening = {};
                    let left2 = xScale(moment(this.props.datePicker.startDate.clone().endOf('day').subtract(6, 'hours').valueOf()).toDate());
                    let right2 = xScale(moment(this.props.datePicker.startDate.clone().endOf('day').valueOf()).toDate());
                    let width2 = right2 - left2;
                    evening.xStart = left2;
                    evening.width = width2;

                    afterWork.push(morning);
                    afterWork.push(evening);
                }

                if (afterWork.length > 0){
                    const group = d3.select(".nv-chart svg g g");

                    group.selectAll("rect.after-work-highlight")
                        .data(afterWork)
                        .enter()
                        .append("rect")
                        .classed("after-work-highlight", true)
                        .attr("x", (d) => d.xStart)
                        .attr("y", 0)
                        .attr("width", (d) => d.width )
                        .attr("height", chartDimensions.height )
                        .attr("fill", "#d8d8d8")
                        .attr("opacity", 0.2);
                }
            }
        }
    }

    createTooltip = data => {
        if (!data) {
            return ''
        }

        return `
            <div id="custom-tooltip">
                <div class="tooltip-header">
                    ${this.tooltipHeaderFormatter(data.value)}
                </div>
                <div class="tooltip-content">
                    ${data.series && data.series.reduce((prev, serie, index) => prev += `
                        <div class="tooltip-serie">
                            <div class="serie-info">
                                <div class="serie-color" style="background-color: ${serie.color}"></div>
                                <div class="name">
                                    ${serie.data.tariff.name}
                                </div>
                            </div>
                            <div class="serie-value">
                                ${serie.data.aggregationType === 'ESTIMATED'
                                    ? `${i18n.t('(estimated)')} ${this.costFormat(serie.data.tariff.currency, serie.value)}`
                                    : serie.data.aggregationType === 'HYBRID'
                                        ? `${i18n.t('(partially estimated)')} ${this.costFormat(serie.data.tariff.currency, serie.value)}`
                                        : `${this.costFormat(serie.data.tariff.currency, serie.value)}`
                                }
                            </div>
                        </div>
                    `, '')}
                </div>
            </div>
        `
    }

    render () {
        let xAxis = {
            tickFormat: this.xTickFormat,
            showMaxMin: false,
        };

        if (this.props.periodFilter === 'month' || this.props.periodFilter === 'day'){
            xAxis.rotateLabels = -50;
            xAxis.tickPadding = 0;
        }

        if (this.props.periodFilter === 'day')
            xAxis.ticks = 24;

        if (this.props.periodFilter === 'week' || this.props.periodFilter === 'month')
            xAxis.tickValues = this.state.xTicks;

        if (this.props.periodFilter === 'custom'){
            if (this.props.diffOfSelectedDays > 1 && this.props.diffOfSelectedDays < 20){
                xAxis.tickValues = this.state.xTicks;
            }else{
                xAxis.ticks = 8;
            }
        }

        let yAxis = { axisLabel: this.props.currency || i18n.t('Cost'), tickFormat: yTickFormat };

        if (this.props.max && this.props.max.cost <= 0.1)
            yAxis.ticks = costNumberFormat(this.props.max.cost) * 100;

        return (
            <div id="cost-simulation-line-chart-wrapper">
                {!this.props.loadingGroups && !this.props.loadingData && !this.state.data.length && !this.props.loadError ? (
                    <div className="nv-chart no-chart-msg">
                        <p className="no-data"> {(this.props.noGroupSetupError) ? this.noGroupChartMessage : this.noDataChartMessage} </p>
                    </div>
                ) :
                !this.props.loadingData && this.props.noTariffSetupError ? (
                    <div className="nv-chart no-chart-msg">
                        <p className="no-data">{this.noTariffChartMessage}</p>
                    </div>
                ) : (
                    <div id="graph">
                        <ChartLoading
                            loading={this.props.loadingData}
                            loaded={this.props.loadedData}
                            total={this.props.totalData}
                            hidePercentage={!!this.state.data.length}
                            position={!!this.state.data.length ? 'top' : 'center'}
                        />
                        <NVD3Chart id="cost-simulation-line-chart"
                            type="lineChart"
                            datum={this.state.data}
                            x="time"
                            y="cost"
                            options={{
                                color: colors,
                                interpolate: this.props.isLinear ? 'linear' : 'step-before',
                                forceX: this.state.xDomain,
                                forceY: this.props.max ?
                                    [0, (this.props.max.cost <= 0.1) ? costNumberFormat(this.props.max.cost) : this.props.max.cost] : [0],
                                interactiveLayer: {
                                    tooltip: {
                                        contentGenerator: this.createTooltip
                                    },
                                },
                                noData: this.state.noDataChartMsg,
                                useInteractiveGuideline: true,
                                xAxis: xAxis,
                                xScale: scaleTime(),
                                defined: (d) => d.defined,
                                yAxis: yAxis,
                                legend: {
                                    keyFormatter: (key) => this.props.getTariffName(key.split('_')[0]) || key
                                },
                            }}
                            // legend={{
                            //     legendClick: (chartState) => this.resetLines(true),
                            //     legendDblclick: (chartState) => this.resetLines(true),
                            // }}
                        />
                    </div>
                )}
            </div>
        )
    }
}

CostSimulationLineChart.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(CostSimulationLineChart);
