import React from 'react';

import loadingImg from '../general/img/loading.svg';


class Loading extends React.Component {
    render () {
        return <img src={loadingImg} className="loading-img" alt="voltaware loading" style={{maxWidth: '100%'}} />;
    }
}

export {Loading};
