import React from 'react';
import d3 from 'd3';
import NVD3Chart from 'react-nvd3';
import moment from 'moment-timezone';
import { injectIntl, intlShape } from 'react-intl';
import { withStyles } from 'material-ui/styles';

import {API} from '../../../components/api';
import {getSensorStructured, isEstimatedData} from '../../../components/utils';

import Button from 'material-ui/Button';
import Table, {TableBody, TableCell, TableHead, TableRow} from 'material-ui/Table';
import {Text} from '../../../elements/text';
import {nest} from 'd3-collection';
import {utcHours, utcDays, utcMonths} from 'd3-time';
import {format} from 'd3-format';
import {scaleBand, scaleLinear} from 'd3-scale';
import {min, max, range, ascending} from 'd3-array';
import DatePicker from '../filter-date-picker';
import ChartLoading from '../chart-loading';
import SystemSnackbar from '../../../elements/material-ui/SystemSnackbar';
import CompareCard from '../../../elements/material-ui/CompareCard';
import Switch from 'material-ui/Switch';

import IconButton from 'material-ui/IconButton';
import ExpandMoreIcon from 'material-ui-icons/ArrowDropDown';

import i18n from '../../../i18n';

import 'nvd3/build/nv.d3.min.css';
import './bar-chart.css';
import 'react-select/dist/react-select.css';
import 'react-dates/lib/css/_datepicker.css';

const yTickFormat = (d) => format(".2f")(d);
const costNumberFormat = (d) => format(".2f")(d);

const colors = { default: '#2ca02c', estimated: '#CFCFCF', fallback: '#fafafa'}
const tariffRatesColors = ["#2ca02c", "#FBD55A", "#E5C01C", "#E0A138", "#F10421"];

const styles = {
    switchBase: {
        height: '34px',
        '& + $bar': {
            backgroundColor: 'green',
        },
        '&$checked': {
            '& + $bar': {
                backgroundColor: 'green',
            },
        },
    },
    switchBaseOnOff: {
        height: '34px',
        '& + $bar': {
            backgroundColor: '#b5b5b5',
        },
        '&$checked': {
            '& + $bar': {
                backgroundColor: 'green',
            },
        },
    },
    disabled: {
        '&$switchBase': {
            '& + $bar': {
                backgroundColor: 'gray',
            },
            '&$checked': {
                '& + $bar': {
                    backgroundColor: 'gray',
                },
            },
        },
    },
    bar: {},
    checked: {},
};

const daysToMonthGranularity = 31;

class BarChart extends React.Component {
    constructor (props) {
        super(props);

        this.loadErrorMessage = i18n.t("An error occurred while retrieving data.");
        this.loadingChartMessage = i18n.t("Loading data...");
        this.noDataChartMessage = i18n.t("There is no data available for the selected criteria");
        this.noGroupChartMessage = i18n.t("There is no data available as no group has been set up.");
        this.multipleCurrenciesChartMessage = i18n.t("Data cannot be displayed due to the tariffs for the tag elements being set in different currencies.");

        this.periodOptions = [
            {label: i18n.t('Day'), value: 'day'},
            {label: i18n.t('Week'), value: 'week'},
            {label: i18n.t('Month'), value: 'month'},
            {label: i18n.t('Year'), value: 'year'},
            {label: i18n.t('Custom'), value: 'custom'},
        ];

        this.periodsText = {
            day: i18n.t('24 Hours'),
            week: i18n.t('1 Week'),
            month: i18n.t('1 Month'),
            custom: i18n.t('Custom'),
        }

        let today = moment();

        this.state = {
            calcBySubLoad: false,
            loadingGroups: true,
            loadingData: false,
            periodFilter: 'day',
            filterGroup: null,
            filterSubgroup: null,
            filterGroupOptions: [],
            filterSubgroupOptions: [],
            filterSensor: null,
            filterSensorOptions: [],
            breadcrumbsTrail: '',
            noDataChartMsg: this.loadingChartMessage,
            groups: [],
            data:[],
            min: {
                consumption: 0,
                cost: 0,
                hour: '00:00:00',
            },
            max: {
                consumption: 0,
                cost: 0,
                hour: '00:00:00',
            },
            averageCost: 0,
            datePicker: {
                open: false,
                startDate: today,
                data: {
                    endDate: today,
                    focusedInput: 'startDate',
                    startDate: today,
                },
            },
            dateRangePicker: {
                open: false,
                endDate: today,
                startDate: today.clone().subtract(2, 'days'),
                data: {
                    endDate: today,
                    focusedInput: 'endDate',
                    startDate: today.clone().subtract(2, 'days'),
                },
            },
            screenWidth: 0,
            screenHeight: 0,
            hadScreenResize: false,
            totalCost: 0,
            tariffs: [],
            currentTariff: null,
            currency: "",
            diffOfSelectedDays: 0,
            compare: [{
                filterGroupOptions: [],
                filterSubgroupOptions: [],
                filterSensorOptions: [],
            }],
            rateColorScale: null,
            showAlertEvents: true,
            loadingEvents: true,
        };

        this.maximumDate = today.clone().add(1, 'day').endOf('day');
        this.monthsToAdd = -1;
        this.costFormat = (currency, value) => {
            return currency && value >= 0 ? `${this.props.intl.formatNumber(value, {style: 'currency', currency: currency})}` : ' - '
        }
        this.tariffFormat = (currency, value) => {
            return currency && value >= 0 ? `${this.props.intl.formatNumber(value, {style: 'currency', currency: currency, maximumFractionDigits: 5})}` : ' - '
        }
        this.rateFormat = (currency, cost) => {
            return (currency && cost >= 0) ? this.costFormat(currency, cost) + '/' + i18n.t('kWh') : ' - ';
        }
        this.tariffIdCurrencyMap = new Map();
        this.rateCurrencyMap = new Map();

        this.unlistenHistory = props.history.listen((location) => {
            this.removeAlertEvents();
        });
    }

    componentWillMount = async () => {
        try {
            const { data } = await API.LABELS.GET()

            if (data) {
                this.setState({
                    tagsMap: data.reduce((prev, cur) => ({ ...prev, [cur.id]: cur.name }), {}),
                    tags: data,
                    loadingTags: false,
                });
            }else{
                this.handleLoadError({loadingTags: false}, "Could not load the tags.")
            }
        } catch (error) {
            this.handleLoadError({loadingTags: false}, "Could not load the tags.", error)
        }

        API.GROUPS.GET().then((response) => {
            if (response.data && response.data.length > 0){
                this.groupsNamesMap = this.createGroupsNamesMap(response.data);
                this.sensorsPhasesNamesMap = this.createSensorsPhasesNamesMap(response.data);
                this.setState({
                    groups: response.data,
                    loadingGroups: false,
                    filterGroupOptions: response.data.map(group => { return {name: group.name, id: group.id} }),
                }, () => this.checkGeneralChangesOnComponent());
            }else if (response.data && response.data.length === 0){
                this.setState({
                    loadingGroups: false,
                    noDataChartMsg: this.noGroupChartMessage,
                });
            }else{
                this.handleLoadError({loadingGroups: false}, "Could not load groups data.")
            }
        }).catch((error) => {
            this.handleLoadError({loadingGroups: false}, "Could not load groups data.", error)
        });

        this.updateDimensions();
    }

    componentDidMount () {
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillReceiveProps (nextProps) {
        this.checkGeneralChangesOnComponent(nextProps);
    }

    componentDidUpdate () {
        const breadcrumbsTrail = this.getBreadcrumbsTrail();
        if (this.state.breadcrumbsTrail !== breadcrumbsTrail){
            this.setState({
                breadcrumbsTrail: breadcrumbsTrail
            });
        }

        // The remove/create weekend highlight logic for bar chart is different from
        // the line chart and streamgraph's implementation. Some timeouts were needed
        // to assure that all chart's elements were drawn to create highlights properly
        if (this.state.periodFilter === 'week' || this.state.periodFilter === 'month' || (this.state.periodFilter === 'custom' && this.state.diffOfSelectedDays < daysToMonthGranularity)){
            clearTimeout(this.wkndHighlightAddTimeOut);
            this.wkndHighlightAddTimeOut = setTimeout(() => this.addWeekEndsHighlight(), 1250);
        }

        if (this.state.hadScreenResize){
            if (this.state.periodFilter === 'week' || this.state.periodFilter === 'month' || this.state.periodFilter === 'custom'){
                clearTimeout(this.wkndHighlightRemoveTimeOut);
                this.wkndHighlightRemoveTimeOut = setTimeout(() => {
                    this.removeWeekEndsHighlight();
                    clearTimeout(this.wkndHighlightAddTimeOut);
                    // schedules highlights creation after old ones were removed (for screen resize recreation)
                    this.wkndHighlightAddTimeOut = setTimeout(() => this.addWeekEndsHighlight(), 2250);
                }, 1000);
            }

            // forces state update and then force the chart redraw of x axis, background lines, etc
            clearTimeout(this.forceUpdateTimeOut);
            this.forceUpdateTimeOut = setTimeout(() => {
                this.setState({hadScreenResize: false});
            }, 1250); // after 1 second to execute it after chart transitions (animation lasts 250 ms)

            this.removeAlertEvents();
        }

        if (this.state.showAlertEvents &&
            !this.state.loadingData && this.state.data.length &&
            !this.state.loadingEvents  &&
            this.state.dateRangePicker.open === false && this.state.datePicker.open === false &&
            (this.state.periodFilter !== 'year' && !(this.state.periodFilter === 'custom' && this.state.diffOfSelectedDays >= daysToMonthGranularity))){

            clearTimeout(this.addAlertTimeout);
            this.addAlertTimeout = setTimeout(this.addAlertEvents, 1250);
        }

        if (!this.state.showAlertEvents)
            this.removeAlertEvents();

        if (!this.state.loadingGroups && !this.state.loadingData && !this.state.data.length){
            let tooltips = d3.selectAll('.nvtooltip');
            if (tooltips[0].length === 1)
                tooltips.attr('opacity', 0);
        }

        if (this.state.periodFilter !== 'custom')
            this.showAllXAxisLabels();

        if (this.state.periodFilter === 'custom'){
            if (this.state.dateRangePicker.startDate && this.state.dateRangePicker.endDate)
                if (this.state.diffOfSelectedDays > 1 && this.state.diffOfSelectedDays <= 30)//diff of 1 === 2 days selected
                    this.showAllXAxisLabels();

            if (this.state.diffOfSelectedDays === 1){//diff of 1 === 2 days selected
                clearTimeout(this.hourLabelsTimeOut);
                this.hourLabelsTimeOut = setTimeout(() => this.hideSomeXAxisHourLabels(), 1250);
            }
        }
    }

    componentWillUnmount () {
        window.removeEventListener("resize", this.updateDimensions);
        document.body.removeEventListener('click', this.onOutsideCompareClick);
        clearTimeout(this.forceUpdateTimeOut);
        clearTimeout(this.wkndHighlightAddTimeOut);
        clearTimeout(this.wkndHighlightRemoveTimeOut);
        clearTimeout(this.dayLabelsTimeOut);
        clearTimeout(this.hourLabelsTimeOut);
        this.unlistenHistory();
    }

    // Based on https://stackoverflow.com/a/34475071
    updateDimensions = () => {
        let w = window,
        d = document,
        documentElement = d.documentElement,
        body = d.getElementsByTagName('body')[0],
        width = w.innerWidth || documentElement.clientWidth || body.clientWidth,
        height = w.innerHeight|| documentElement.clientHeight|| body.clientHeight;

        this.setState({
            screenWidth: width,
            screenHeight: height,
            hadScreenResize: true,
        });
    }

    checkGroupFromSearchParams = () => {
        const URLToSearchParams = this.props.location.search ?
            this.props.location.search : this.props.location.pathname.indexOf('?') >= 0 ?
                this.props.location.pathname.slice(this.props.location.pathname.indexOf('?')) :
                this.props.location.pathname;

        const searchParams = new URLSearchParams(URLToSearchParams);

        const groupId = parseFloat(searchParams.get('groupId'))

        const foundGroup = this.state.groups.find(group =>
            group.id === groupId || (
            !!group.subGroups.some(subgroup => searchParams.has('sensorId')
                ? subgroup.subGroups.some(({ id }) => id === groupId)
                : subgroup.id === groupId
            )
        ))

        if (!foundGroup) {
            return this.props.history.push(`${this.props.history.location.pathname}/group/${this.state.groups[0].id}`);
        }

        const foundSubgroup = foundGroup && foundGroup.subGroups.find(subgroup => searchParams.has('sensorId')
            ? subgroup.subGroups.some(({ id }) => id === groupId)
            : subgroup.id === groupId
        )

        const sensor = searchParams.has('sensorId') &&searchParams.has('channel') ? `/sensor/${searchParams.get('sensorId')}/${searchParams.get('channel')}` : ''

        searchParams.delete('groupId');
        searchParams.delete('sensorId');
        searchParams.delete('channel');

        this.props.history.push(`${this.props.history.location.pathname}/group/${foundGroup.id}${foundSubgroup ? `/subgroup/${foundSubgroup.id}` : ''}${sensor}?${searchParams.toString()}`);
    }

    xTickFormat = (d, i) => {
        let format = '';
        switch (this.state.periodFilter){
            case 'year':
                format = 'MMM';
                break;
            case 'month':
                format = '[LM]';
                break;
            case 'week':
                format = 'ddd DD';
                break;
            case 'day':
                format = 'LT';
                break;
            case 'custom':
                if (this.state.diffOfSelectedDays === 1){
                    format = '[LDDMMHHmm]';
                }else if (this.state.diffOfSelectedDays >= daysToMonthGranularity){
                    format = 'MMM';
                }else{
                    format = '[LM]';
                }
                break;
            default:
                // day format
                format = 'LT';
                console.warn("An invalid x tick format was found. Assuming day x tick format.");
        }
        return moment(d).format(format);
    }

    tooltipHeaderFormatter = (strDate) => {
        let format = '';
        switch (this.state.periodFilter) {
            case 'year':
                format = '[LMMMMYYYY]';
                break;
            case 'month':
                format = '[llll-b]';
                break;
            case 'week':
                format = '[llll-b]';
                break;
            case 'day':
                format = 'LT';
                break;
            case 'custom':
                format = (this.state.diffOfSelectedDays === 1) ? 'LLLL' : (this.state.diffOfSelectedDays >= daysToMonthGranularity) ? '[LMMMMYYYY]' : '[llll-b]'
                break;
            default:
                format = 'LT';// day format
                console.warn("An invalid period filter was found. Assuming day format.");
        }
        return moment(strDate).format(format);
    }

    getGroupSelectedById = (id) => {
        const group = id ? this.state.groups.find((group) => group.id === id) : this.state.groups[0];
        if (id && !group) {
            this.props.history.push(`/analysis/cost/group/${this.state.groups[0].id}`);
            return;
        }

        return {
            filterGroup: group,
            filterSubgroup: null,
            filterSubgroupOptions: this.getGroupSubgroupsOptions(group),
            filterSensor: null,
            filterSensorOptions: [],
            noDataChartMsg: this.loadingChartMessage
        };
    }

    getTagSelectedById = (id) => {
        const tag = id ? this.state.tags.find((tag) => tag.id === id) : this.state.tags[0];
        if (id && !tag) {
            if (!this.state.tags[0]) {
                return this.props.history.push(`/analysis/cost/tag/${this.state.groups[0].id}`);
            }

            this.props.history.push(`/analysis/cost/tag/${this.state.tags[0].id}`);
            return;
        }

        return {
            filterTag: tag,
            noDataChartMsg: this.loadingChartMessage
        };
    }

    getSubgroupSelectedById = (id, group) => {
        const subgroup = group.subGroups.find((subgroup) => subgroup.id === id);
        if (id && !subgroup) {
            this.props.history.push(`/analysis/cost/group/${group.id}/subgroup/${group.subGroups[0].id}`);
            return;
        }

        return {
            filterSubgroup: subgroup,
            filterSensor: null,
            filterSensorOptions: this.getSubgroupSensorsOptions(subgroup),
            noDataChartMsg: this.loadingChartMessage
        };
    }

    getSensorSelectedById = (id, channel, state) => {
        let sensor = state.filterSensorOptions.find(sensor => sensor.id === id && sensor.channel === channel);
        if (id && !sensor) {
            this.props.history.push(`/analysis/cost/group/${state.filterGroup.id}/subgroup/${state.filterSubgroup.id}`);
            return;
        }

        return {
            filterSensor: sensor,
            noDataChartMsg: this.loadingChartMessage,
        };
    }

    checkGeneralChangesOnComponent = (nextProps) => {
        const props = nextProps || this.props;
        const URLToSearchParams = props.location.search ?
            props.location.search : props.location.pathname.indexOf('?') >= 0 ?
                props.location.pathname.slice(props.location.pathname.indexOf('?')) :
                props.location.pathname;

        const searchParams = new URLSearchParams(URLToSearchParams);

        if (!this.state.groups || !this.state.groups.length) {
            return;
        }

        if (!props.match.params.groupId && !props.match.params.tagId) {
            if (searchParams.has('groupId')) {
                return this.checkGroupFromSearchParams()
            }

            this.props.history.push(`${this.props.history.location.pathname}/group/${this.state.groups[0].id}`);
            return;
        }

        let groupData, subgroupData, sensorData, tagData, startDate, endDate;
        let state = {
            datePicker: this.state.datePicker,
            dateRangePicker: this.state.dateRangePicker
        };

        if (props.match.params.groupId) {
            groupData = this.getGroupSelectedById(parseInt(props.match.params.groupId, 0));
            if (!groupData) return;
            Object.assign(state, groupData);
        }
        if (props.match.params.tagId) {
            tagData = this.getTagSelectedById(parseInt(props.match.params.tagId, 0));
            if (!tagData) return;
            Object.assign(state, tagData);
        }
        if (props.match.params.subgroupId) {
            subgroupData = this.getSubgroupSelectedById(parseInt(props.match.params.subgroupId, 0), state.filterGroup);
            if (!subgroupData) return;
            Object.assign(state, subgroupData);
        }
        if (props.match.params.sensorId && props.match.params.channel) {
            sensorData = this.getSensorSelectedById(parseInt(props.match.params.sensorId, 0), parseInt(props.match.params.channel, 0), state);
            if (!sensorData) return;
            Object.assign(state, sensorData);
        }

        state.calcBySubLoad = !!searchParams.has('calcBySubLoad');
        state.showAlertEvents = !!searchParams.has('alerts');
        state.periodFilter = searchParams.get('period') ?
            searchParams.get('period') : props.location.pathname.indexOf('period=week') >= 0 ? 'week' : 'day';
        if (searchParams.get('date')) {
            startDate = moment(searchParams.get('date'));

            if (state.periodFilter === 'week') {
                startDate = startDate.startOf('week');
                endDate = startDate.clone().endOf('week');
                endDate = endDate.isAfter(this.maximumDate) ? this.maximumDate : endDate;
            } else endDate = startDate;

            state.datePicker.open = false;
            state.datePicker.startDate = startDate;
            state.datePicker.endDate = endDate;
            state.datePicker.data.startDate = startDate;
            state.datePicker.data.endDate = endDate;
        }

        if (searchParams.get('startDate') && searchParams.get('endDate')) {
            startDate = moment(searchParams.get('startDate'));
            endDate = moment(searchParams.get('endDate'));

            state.dateRangePicker.open = false;
            state.dateRangePicker.startDate = startDate;
            state.dateRangePicker.endDate = endDate;
            state.dateRangePicker.data.startDate = startDate;
            state.dateRangePicker.data.endDate = endDate;
            state.diffOfSelectedDays = (startDate && endDate) ? endDate.diff(startDate, 'days') : 0;
        }

        this.setState(state);
        sensorData ? this.loadSensorChart(state) : this.loadGroupChart(state);
        if (!tagData)
            sensorData ? this.loadSensorAlertEvents(state) : this.loadGroupAlertEvents(state);
    }

    createGroupsNamesMap = (groups) => {
        const idNamesPairsArray = groups.reduce((acc, group) => {
            acc.push([group.id, group.name]);
            group.subGroups.forEach(sg => {
                sg.subGroups.forEach(ssg => {
                    acc.push([ssg.id, sg.name])
                });
                acc.push([sg.id, sg.name])
            });
            return acc;
        }, [])
        return new Map(idNamesPairsArray);
    }

    // Create a Map that lets get the sensor name from sensor-phase
    createSensorsPhasesNamesMap = (groups) => {
        const sensorPhasesMap = new Map();
        groups.forEach((group) => {
            group.sensors.forEach((sensorPhase) => sensorPhasesMap.set(sensorPhase.id + "-" + sensorPhase.channel, sensorPhase.name));
            group.subGroups.forEach((subgroup) => {
                if (subgroup.sensors && subgroup.sensors.length > 0)
                    subgroup.sensors.forEach((sensorPhase) => sensorPhasesMap.set(sensorPhase.id + "-" + sensorPhase.channel, sensorPhase.name));

                if (subgroup.subGroups && subgroup.subGroups.length > 0){
                    subgroup.subGroups.forEach(innerSubgroup => {
                        if (innerSubgroup.sensors && innerSubgroup.sensors.length > 0)
                            innerSubgroup.sensors.forEach((sensorPhase) => sensorPhasesMap.set(sensorPhase.id + "-" + sensorPhase.channel, sensorPhase.name))
                    });
                }
            })
        });

        return sensorPhasesMap;
    }

    getGroupName = (groupId) => {
        return this.groupsNamesMap.get(+groupId);
    }

    getTagName = (tagId) => {
        return '# ' + this.state.tagsMap[tagId];
    }

    getSensorPhaseName = (sensor, phase) => {
        return this.sensorsPhasesNamesMap.get(sensor + '-' + phase);
    }

    getXTicks = () => {
        let xTicks = [];
        switch (this.state.periodFilter) {
            case 'month':
                const monthStart = this.state.datePicker.startDate.clone().startOf('month');
                const monthEnd = this.state.datePicker.startDate.clone().endOf('month');
                xTicks = utcDays(
                    monthStart,
                    monthEnd,
                ).map((value) => moment(value).valueOf());
                break;
            case 'week':
                let momentDateTz = this.state.datePicker.startDate.clone();
                xTicks = utcDays(
                    momentDateTz.startOf('week'),
                    momentDateTz.clone().add(7, 'days')
                ).map((value) => moment(value).valueOf());
                break;
            case 'day':
                let momentDayTz = this.state.datePicker.startDate.clone();
                xTicks = utcHours(
                    momentDayTz.startOf('day'),
                    momentDayTz.clone().add(24, 'hours')
                ).map((value) => moment(value).valueOf());
                break;
            case 'custom':
                xTicks = [];
                if (this.state.diffOfSelectedDays > 1){//diff of 1 === 2 days selected
                    const dateRangePicker = this.state.dateRangePicker;
                    if (dateRangePicker.startDate && dateRangePicker.endDate && dateRangePicker.open === false){
                        const periodStart = this.state.dateRangePicker.startDate.clone().startOf('day');
                        const periodEnd = this.state.dateRangePicker.endDate.clone().endOf('day');
                        xTicks = utcDays(
                            periodStart,
                            periodEnd,
                        ).map((value) => moment(value).valueOf());
                    }
                }else{
                    const dateRangePicker = this.state.dateRangePicker;
                    if (dateRangePicker.startDate && dateRangePicker.endDate && dateRangePicker.open === false){
                        const periodStart = this.state.dateRangePicker.startDate.clone().startOf('day');
                        const periodEnd = this.state.dateRangePicker.endDate.clone().endOf('day');
                        xTicks = utcHours(
                            periodStart,
                            periodEnd,
                        ).map((value) => moment(value).valueOf());
                    }
                }
                break;
            default:
                xTicks = [];
                console.warn("An invalid period filter was found for x ticks. Returning no ticks.");
        }
        return  xTicks;
    }

    isValidChartData = (data) => {
        if (data.length) {
            const targetLength = data[0].values.length
            return data.reduce((acc, current) => {
                return targetLength === current.values.length ? acc && true : acc && false
            });
        } else {
            //it means data is empty, but still valid
            return true;
        }
    }

    shouldShowTariffTable = () => {
        const {filterTag, tariffs} = this.state;
        if (filterTag && tariffs && tariffs.length > 1){
            return false;
        }
        return true;
    }

    isTagWithMultipleCurrencies = () => {
        const {filterTag, tariffs} = this.state;
        if (filterTag && tariffs && tariffs.length > 1){
            const currencies = tariffs.map(tariff => tariff.currency).filter((v, i, a) => a.indexOf(v) === i);//unique currencies list
            return (currencies.length > 1);
        }
        return false;
    }

    loadGroupChart = (state) => {
        d3.selectAll('.nvtooltip').style('opacity', '0');

        this.setState({ loadingData: true });

        const groupId = state.filterSubgroup
            ? state.filterSubgroup.id
            : state.filterTag
                ? state.filterTag.id
                : state.filterGroup.id;
        let date = state.datePicker.startDate.format('YYYY-MM-DD');
        let params = [groupId, date];
        let apiFn;
        const type = state.filterTag ? 'LABEL' : 'GROUP';

        switch (state.periodFilter){
            case 'day':
                apiFn = API.VISUALIZATIONS.COST.REAL.DAY[type];
                break;
            case 'week':
                apiFn = API.VISUALIZATIONS.COST.REAL.WEEK[type];
                break;
            case 'month':
                apiFn = API.VISUALIZATIONS.COST.REAL.MONTH[type];
                date = state.datePicker.startDate.format('YYYYMM');
                params = [groupId, date];
                break;
            case 'year':
                apiFn = API.VISUALIZATIONS.COST.REAL.YEAR[type];
                date = state.datePicker.startDate.format('YYYY');
                params = [groupId, date];
                break;
            case 'custom':
                apiFn = API.VISUALIZATIONS.COST.REAL.CUSTOM[type];
                const startDate = state.dateRangePicker.startDate.format('YYYY-MM-DD');
                const endDate = state.dateRangePicker.endDate.format('YYYY-MM-DD');
                params = [groupId, startDate, endDate];
                break;
            default:
                apiFn = API.VISUALIZATIONS.COST.REAL.DAY[type];
                console.warn("An invalid period filter was found. Assuming day period for group load.");
        }

        apiFn(...params, state.calcBySubLoad ? 'SUB_LOAD' : 'MAIN').then((response) => {
            if (response.data) {
                response.data.data = response.data.data ? this.ensurePositiveValues(response.data.data) : [];

                let calculatedData = this.createGroupData(response.data);
                const currentTariff = response.data.tariffs && response.data.tariffs[0];

                let min = response.data.minDayAndHourCost || response.data.minHourCost || response.data.minDayCost;
                if (min && min.cost < 0){
                    const minFromData = this.getMinimumFromData(calculatedData);
                    if (minFromData){
                        min = minFromData;
                    }else{
                        min.cost = 0;
                    }
                }

                let dataValues = null;
                if (calculatedData.length && calculatedData[0].values){
                    dataValues = calculatedData[0].values;
                }

                if (response.data.tariffs && response.data.tariffs.length){
                    this.tariffIdCurrencyMap = new Map(response.data.tariffs.map(tariff => [tariff.id, tariff.currency]));
                    this.rateCurrencyMap = this.createRatesCurrenciesMap(dataValues, this.tariffIdCurrencyMap);
                }

                d3.selectAll('.nvtooltip').style('opacity', '0');
                this.setState({
                    loadingData: false,
                    data: calculatedData,
                    breadcrumbsTrail: this.getBreadcrumbsTrail(),
                    totalCost: response.data.totalCost,
                    currency: (currentTariff && currentTariff.currency),
                    currentTariff: currentTariff,
                    tariffs: response.data.tariffs,
                    averageCost: response.data.avgCost,
                    noDataChartMsg: this.noDataChartMessage,
                    max: response.data.maxDayAndHourCost || response.data.maxHourCost || response.data.maxDayCost,
                    min: min,
                    rateColorScale: this.createRateColorScale(dataValues),
                });
            }else{
                this.handleLoadError({loadingData: false}, "Could not load chart data.")
            }
        }).catch((error) => {
            this.handleLoadError({loadingData: false}, "Could not load chart data.", error)
        });
    }

    loadSensorChart = (state) => {
        d3.selectAll('.nvtooltip').style('opacity', '0');

        this.setState({ loadingData: true, noDataChartMsg: this.loadingChartMessage });

        let date = state.datePicker.startDate.format('YYYY-MM-DD');
        const subgroupId = state.filterSensor.subgroupId;
        const sensorId = state.filterSensor.id;
        const channelId = state.filterSensor.channel;
        let params = [subgroupId, sensorId, channelId, date];
        let apiFn;
        switch (state.periodFilter){
            case 'day':
                apiFn = API.VISUALIZATIONS.COST.REAL.DAY.SENSOR;
                break;
            case 'week':
                apiFn = API.VISUALIZATIONS.COST.REAL.WEEK.SENSOR;
                break;
            case 'month':
                apiFn = API.VISUALIZATIONS.COST.REAL.MONTH.SENSOR;
                date = state.datePicker.startDate.format('YYYYMM');
                params = [subgroupId, sensorId, channelId, date];
                break;
            case 'year':
                apiFn = API.VISUALIZATIONS.COST.REAL.YEAR.SENSOR;
                date = state.datePicker.startDate.format('YYYY');
                params = [subgroupId, sensorId, channelId, date];
                break;
            case 'custom':
                apiFn = API.VISUALIZATIONS.COST.REAL.CUSTOM.SENSOR;
                const startDate = state.dateRangePicker.startDate.format('YYYY-MM-DD');
                const endDate = state.dateRangePicker.endDate.format('YYYY-MM-DD');
                params = [subgroupId, sensorId, channelId, startDate, endDate];
                break;
            default:
                apiFn = API.VISUALIZATIONS.COST.REAL.DAY.SENSOR;
                console.warn("An invalid period filter was found. Assuming day period for sensor load.");
        }

        apiFn(...params, state.calcBySubLoad ? 'SUB_LOAD' : 'MAIN').then((response) => {
            if (response.data) {
                response.data.data = response.data.data ? this.ensurePositiveValues(response.data.data) : [];

                let calculatedData = this.createSensorData(response.data);
                const currentTariff = response.data.tariffs && response.data.tariffs[0];

                let min = response.data.minDayAndHourCost || response.data.minHourCost || response.data.minDayCost;
                if (min && min.cost < 0){
                    const minFromData = this.getMinimumFromData(calculatedData);
                    if (minFromData){
                        min = minFromData;
                    }else{
                        min.cost = 0;
                    }
                }

                let dataValues = null;
                if (calculatedData.length && calculatedData[0].values){
                    dataValues = calculatedData[0].values;
                }

                if (response.data.tariffs && response.data.tariffs.length){
                    this.tariffIdCurrencyMap = new Map(response.data.tariffs.map(tariff => [tariff.id, tariff.currency]));
                    this.rateCurrencyMap = this.createRatesCurrenciesMap(dataValues, this.tariffIdCurrencyMap);
                }

                d3.selectAll('.nvtooltip').style('opacity', '0');
                this.setState({
                    loadingData: false,
                    data: calculatedData,
                    breadcrumbsTrail: this.getBreadcrumbsTrail(),
                    totalCost: response.data.totalCost,
                    currency: (currentTariff && currentTariff.currency),
                    currentTariff: currentTariff,
                    tariffs: response.data.tariffs,
                    averageCost: response.data.avgCost,
                    noDataChartMsg: this.noDataChartMessage,
                    max: response.data.maxDayAndHourCost || response.data.maxHourCost || response.data.maxDayCost,
                    min: min,
                    rateColorScale: this.createRateColorScale(dataValues),
                });
            }else{
                this.handleLoadError({loadingData: false}, "Could not load chart data.")
            }
        }).catch((error) => {
            this.handleLoadError({loadingData: false}, "Could not load chart data.", error)
        });
    }

    getAlertEventsPeriod = (state) => {
        let startDate, endDate;
        switch (state.periodFilter) {
            case 'year':
                startDate = state.datePicker.startDate.clone().startOf('year').format('YYYY-MM-DD');
                endDate = state.datePicker.startDate.clone().endOf('year').format('YYYY-MM-DD');
                break;
            case 'month':
                startDate = state.datePicker.startDate.clone().startOf('month').format('YYYY-MM-DD');
                endDate = state.datePicker.startDate.clone().endOf('month').format('YYYY-MM-DD');
                break;
            case 'week':
                startDate = state.datePicker.startDate.clone().startOf('week').format('YYYY-MM-DD');
                endDate = state.datePicker.startDate.clone().endOf('week').format('YYYY-MM-DD');
                break;
            case 'day':
                startDate = state.datePicker.startDate.clone().startOf('day').format('YYYY-MM-DD');
                endDate = state.datePicker.startDate.clone().endOf('day').format('YYYY-MM-DD');
                break;
            case 'custom':
                startDate = state.dateRangePicker.startDate.clone().startOf('day').format('YYYY-MM-DD');
                endDate = state.dateRangePicker.endDate.clone().endOf('day').format('YYYY-MM-DD');
                break;
            default:
                startDate = state.datePicker.startDate.clone().startOf('day').format('YYYY-MM-DD');
                endDate = state.datePicker.startDate.clone().endOf('day').format('YYYY-MM-DD');
                console.warn("An invalid period filter was found. Assuming day period for alert events.");
        }

        return {startDate, endDate};
    }

    getEventsFromResponse = (response) => {
        if (!response || !response.data || !response.data.length)
            return [];

        //creates events key with same pattern of this.state.data for sensor and group
        //(related to legend interaction and tooltip implementations)
        return response.data.map(event => {
            let key;
            if (event.sensorId && event.channel){
                const sensorData = getSensorStructured({
                    name: this.getSensorPhaseName(event.sensorId, event.channel),
                    id: event.sensorId,
                    channel: event.channel,
                });
                key = sensorData.label;
            }else{
                key = this.getGroupName(event.groupId);
            }

            return {
                ...event,
                timestamp: moment(event.eventStartPeriod).toDate().valueOf(),
                key: key,
            }
        });
    }

    loadGroupAlertEvents = (state) => {
        this.setState({ loadingEvents: true });
        const groupId = state.filterSubgroup ? state.filterSubgroup.id : state.filterGroup.id;

        const period = this.getAlertEventsPeriod(state);

        API.ALERTS.EVENTS.COST.GROUP(groupId, period.startDate, period.endDate).then((response) => {
            state.events = [];
            if (response.data && response.data.length){
                const events = this.getEventsFromResponse(response);
                Object.assign(state, {
                    events: (state.events) ? state.events.concat(events) : [].concat(events),
                    loadingEvents: false,
                });
            }
            this.setState(state);
        }).catch((error) => {
            this.handleLoadError({loadingEvents: false}, "Could not load alert events data.", error)
        });;
    }

    loadSensorAlertEvents = (state) => {
        this.setState({ loadingEvents: true });
        const sensorId = state.filterSensor.id;
        const channel = state.filterSensor.channel;

        const period = this.getAlertEventsPeriod(state);

        API.ALERTS.EVENTS.COST.SENSOR(sensorId, channel, period.startDate, period.endDate).then((response) => {
            state.events = [];
            if (response.data && response.data.length){
                const events = this.getEventsFromResponse(response);
                Object.assign(state, {
                    events: (state.events) ? state.events.concat(events) : [].concat(events),
                    loadingEvents: false,
                });
            }
            this.setState(state);
        }).catch((error) => {
            this.handleLoadError({loadingEvents: false}, "Could not load alert events data.", error)
        });
    }

    getMinimumFromData = (data) => {
        let min = null;
        if (data && data[0] && data[0].values && data[0].values.length){
            const dataWithoutNegativeValues = data[0].values.filter(d => d.defined);
            const dataWithoutArtificialValues = dataWithoutNegativeValues.filter(d => !d.artificial);
            min = dataWithoutArtificialValues.reduce((min, d) => {
                return (!d.cost || d.cost < min.cost) ? d : min;
            }, dataWithoutArtificialValues[0]);
        }
        return min;
    }

    getGroupSubgroupsOptions = (group) => {
        if (group.subGroups && group.subGroups.length > 0){
            return group.subGroups.map(subgroup => {
                return {
                    groupId: group.id,
                    name: subgroup.name,
                    id: subgroup.id
                }
            });
        }else{
            return [];
        }
    }

    getSubgroupSensorsOptions = (subgroup) => {
        // non main subgroups sensors are stored in a "artificial" sub-subgroup
        if (subgroup.subGroups && subgroup.subGroups[0]
            && subgroup.subGroups[0].sensors
            && subgroup.subGroups[0].sensors.length){

            return subgroup.subGroups[0].sensors.map(sensor => {
                return {...getSensorStructured(sensor), subgroupId: subgroup.subGroups[0].id} });
        }else{
            return [];
        }
    }

    getSearchURL = () => {
        let url = this.getDateURL();
        return this.getPeriodFilterURL(false, url);
    }

    getPeriodFilterURL = (fromSelect, url, period=this.state.periodFilter) => {
        if (fromSelect){
            url = this.props.location.pathname + this.getDateURL(period) + `&period=${period}`;
        }

        if (url.indexOf('?') < 0) url += `?period=${period}`;
        else {
            url.indexOf('period') >= 0 ? url = url.replace(/day|week|month|custom/, period) : url += `&period=${period}`;
        }

        if ((fromSelect || url.indexOf('alerts') < 0) && this.state.showAlertEvents)
            url += '&alerts';

        if ((fromSelect || url.indexOf('calcBySubLoad') < 0) && this.state.calcBySubLoad)
            url += '&calcBySubLoad';

        return url;
    }

    getDateURL = (period=this.state.periodFilter) => {
        if (period === 'custom'){
            let url = "";
            url += this.state.dateRangePicker.startDate ?
                `?startDate=${this.state.dateRangePicker.startDate.format('YYYY-MM-DD')}` : '';
            url += this.state.dateRangePicker.endDate ?
                `&endDate=${this.state.dateRangePicker.endDate.format('YYYY-MM-DD')}` : '';
            return url;
        }else{
            return this.state.datePicker.startDate ?
                `?date=${this.state.datePicker.startDate.format('YYYY-MM-DD')}` : '';
        }
    }

    removeTooltips = () => {
        d3.selectAll('.nvtooltip').remove();
    }

    changeGroup = (selectedGroup) => {
        this.setState({ filterTag: null });
        this.removeTooltips();

        if (selectedGroup && selectedGroup.id && (!this.state.filterGroup || this.state.filterGroup.id !== selectedGroup.id)) {

            const path = this.state.filterTag
                ? this.props.location.pathname.replace(`tag/${this.state.filterTag.id}`, `group/${selectedGroup.id}`)
                : this.props.location.pathname.replace(this.state.filterGroup.id, selectedGroup.id)
                    .split('subgroup')
                    .shift()

            this.props.history.push(`${path}${this.getSearchURL()}`);
        }
    }

    changeTag = (selectedTag) => {
        this.setState({ filterGroup: null, filterSubgroup: null });
        this.removeTooltips();

        if (selectedTag && selectedTag.id && (!this.state.filterTag || this.state.filterTag.id !== selectedTag.id)) {
            const path = !!this.state.filterTag
                ? this.props.location.pathname.replace(this.state.filterTag.id, selectedTag.id)
                : !!this.state.filterGroup
                    ? this.props.location.pathname.replace(`group/${this.state.filterGroup.id}`, `tag/${selectedTag.id}`)
                    : this.props.location.pathname

            this.props.history.push(`${path.split('subgroup').shift()}${this.getSearchURL()}`);
        }
    }

    changeSubgroup = (selectedSubgroup) => {
        let url = `/analysis/cost/group/${this.state.filterGroup.id}`;
        if (!selectedSubgroup) {
            this.props.history.push(url + this.getSearchURL());
        } else if (
            selectedSubgroup.id &&
            (!this.state.filterSubgroup || this.state.filterSubgroup.id !== selectedSubgroup.id)
        ) {
          this.props.history.push(url + `/subgroup/${selectedSubgroup.id}${this.getSearchURL()}`);
        }
    }

    changeSensor = (selectedSensor) => {
        let url = `/analysis/cost/group/${this.state.filterGroup.id}/subgroup/${this.state.filterSubgroup.id}`;
        if (!selectedSensor) {
            this.props.history.push(url + this.getSearchURL())
        } else if (
            selectedSensor.id &&
            (!this.state.filterSensor || !this.state.filterSensor.id !== selectedSensor.key)
        ) {
            this.props.history.push(url + `/sensor/${selectedSensor.id}/${selectedSensor.channel}/${this.getSearchURL()}`);
        }
    }

    toggleCalculation = () => {
        let searchParams = new URLSearchParams(window.location.search);
        let url = this.getSearchURL();
        url = searchParams.has('calcBySubLoad') ? url.replace('&calcBySubLoad', '') : `${url}&calcBySubLoad`;
        this.props.history.push(url);
    }

    toggleShowAlertEvents = () => {
        let searchParams = new URLSearchParams(window.location.search);
        let url = this.getSearchURL();
        url = searchParams.has('alerts') ? url.replace('&alerts', '') : `${url}&alerts`;
        this.props.history.push(url);
    }

    getBreadcrumbsTrail = () => {
        const filterGroup = this.state.filterGroup;
        const filterSubgroup = this.state.filterSubgroup;
        const filterSensor = this.state.filterSensor;
        const filterTag = this.state.filterTag;

        let trail = (filterGroup && filterGroup.name) ? filterGroup.name : null;
        if (filterTag && filterTag.name) trail = '# ' + filterTag.name;
        if (filterSubgroup && filterSubgroup.name) trail += ' / ' + filterSubgroup.name;
        if (filterSensor && filterSensor.label) trail += ' / ' + filterSensor.label;

        return trail;
    }

    // This is a workaround for having the bar chart with x axis with complete period ticks,
    // once we couldn't make xDomain + forceX work using nvd3's discrete bar chart (because
    // it uses ordinal scale, not time scale)
    createDatetime = (data) => {

        // Creates an array of real group/subgroup/sensor data with Date object
        const dataWithDateTime = data.map((d) => ({
            ...d,
            time: this.state.periodFilter === 'year' ? moment(d.month, 'YYYYMM').toDate() : moment(this.state.periodFilter !== 'month' ? d.dayTz + "T" + d.hour : d.dayTz + "T00:00:00").toDate(),
            cost: costNumberFormat(d.cost),
        }));

        const baseProperties = {
            cost: 0,
            artificial: true,
            //reset some not used data
            dayTz: null,
            hour: null,
            consumption: 0
        };

        let allPeriodRange = [], allPeriodRangeArray = [];
        if (this.state.periodFilter === 'day'){
            // Creates an array of objects that has all possible times for this day,
            // with a 1 hour step (12AM, 1AM, ..., 11PM, but with Date objects)
            allPeriodRangeArray = utcHours(moment(data[0].dayTz + "T00:00:00").toDate(), moment(data[0].dayTz + "T23:59:59").toDate(), 1);
        }

        if (this.state.periodFilter === 'week'){
            // Creates an array of objects that has all possible days for this week,
            // with a 1 day step
            allPeriodRangeArray = utcDays(moment(data[0].dayTz + "T00:00:00").startOf('week'), moment(data[0].dayTz + "T00:00:00").endOf('week'), 1);
        }

        if (this.state.periodFilter === 'month'){
            // Creates an array of objects that has all possible days for this month,
            // with a 1 day step
            allPeriodRangeArray = utcDays(moment(data[0].dayTz + "T00:00:00").startOf('month').toDate(), moment(data[0].dayTz + "T00:00:00").endOf('month').toDate(), 1);
        }

        if (this.state.periodFilter === 'custom'){
            if (this.state.diffOfSelectedDays === 1){ //diff of 1 === 2 days selected
                // Creates an array of objects that has all possible hours for this two days period,
                // with a 1 hour step
                allPeriodRangeArray = utcHours(this.state.dateRangePicker.startDate.startOf('day'), this.state.dateRangePicker.endDate.endOf('day'), 1);
            }else if (this.state.diffOfSelectedDays < daysToMonthGranularity){
                // Creates an array of objects that has all possible days for this period,
                // with a 1 day step
                allPeriodRangeArray = utcDays(this.state.dateRangePicker.startDate.startOf('day'), this.state.dateRangePicker.endDate.endOf('day'), 1);
            }else{
                // Creates an array of objects that has all possible months for this period,
                // with a 1 month step
                allPeriodRangeArray = utcMonths(this.state.dateRangePicker.startDate.startOf('month'), this.state.dateRangePicker.endDate.clone().add(1, 'day'), 1);
            }
        }

        allPeriodRange = allPeriodRangeArray.map(time => Object.assign({}, data[0], { time }, baseProperties));

        // Merges both arrays, for filling the real data (consumption/cost/etc) in its place
        // Got an array with all possible hours or days values for the period with empty data where there's no real data
        const mergedData = allPeriodRange.map(period => {
            const index = dataWithDateTime.findIndex(realData => moment(realData.time).isSame(period.time));
            return (index >= 0) ? dataWithDateTime[index] : period;
        });

        return (mergedData.length > 0) ? mergedData : dataWithDateTime;
    }

    createSensorPhase = (data) => data.map((d) => {
        d.name = this.getSensorPhaseName(d.sensorId, d.channel);
        return {
            ...d,
            ...getSensorStructured(d),
        }
    })

    /* Creates chart data for groups and subgroups */
    createGroupData = (groupData) => {
        let groupEntries = [];

        // Creates group data for the chart to draw
        if (groupData.data && groupData.data.length > 0){
            const groupPre = this.createDatetime(groupData.data);
            // Gets the object root groupId, which is of the group requested, because
            // the ids inside data property may come with the first subgroup id
            // Thus we can get the right group name below.
            const groupId = groupData.groupId || groupData.labelId;

            let group = groupPre.map(d => {
                return {
                    ...d,
                    name: this.getGroupName(groupId) || this.getTagName(groupId),
                    tariffCompositions: d.tariffCompositions && d.tariffCompositions.filter(tariff => !!tariff)
                }
            });
            groupEntries = nest().key(function(d) { return d.name; }).entries(group);
        }
        return groupEntries;
    }

    createSensorData = (sensorData) => {
        if (sensorData.data && sensorData.data.length > 0) {
            let sensorsDataPre = this.createDatetime(sensorData.data);
            sensorsDataPre = this.createSensorPhase(sensorsDataPre); // creates sensor-phase, e.g.: 3031-1, 3031-2, 3031-3
            const entries = nest().key(function(d) { return d.label; }).entries(sensorsDataPre);
            return entries;
        }
        return [];
    }

    // Ensure we have just positive values for the chart
    ensurePositiveValues = (data) => data.map((d) => {
        if (d.cost < 0) {
            d.cost = 0;
            d.defined = false;
        } else d.defined = true;
        return d;
    })


    changePeriodFilter = (period) => {
        this.removeWeekEndsHighlight();

        let url = this.getPeriodFilterURL(true, '', period ? period.value : 'day');
        this.props.history.push(url);
    }

    goToPreviousPeriod = () => {
        let {datePicker} = this.state;
        datePicker.startDate.subtract(1, this.state.periodFilter);
        this.props.history.push(this.getSearchURL());
    }

    goToNextPeriod = () => {
        let { datePicker } = this.state;
        datePicker.startDate.add(1, this.state.periodFilter);
        this.props.history.push(this.getSearchURL());
    }

    goToCurrentDay = () => {
        let { datePicker } = this.state;
        datePicker.startDate = moment();
        this.props.history.push(this.getSearchURL());
    }

    handleLoadError = (state, message, error) => {
        state.loadErrorMessage = this.loadErrorMessage;
        this.setState(state);

        console.error(this.loadErrorMessage + " (" + message + ")");
        if (error)
            console.error(error);
    }

    // Date period select functions
    updateDateRangePicker = (dateRangePicker, keepWeekendHighlight) => {
        if (dateRangePicker.startDate && dateRangePicker.endDate && dateRangePicker.open === false && !keepWeekendHighlight)
            this.removeWeekEndsHighlight();

        this.setState({dateRangePicker});
    }

    updateDatePicker = (datePicker) => {
        this.setState({datePicker});
    }
    // /Date period select functions

    showAllXAxisLabels = () => {
        d3.selectAll('.nv-chart svg .nv-x.nv-axis .tick text').attr('opacity', 1);
    }

    hideSomeXAxisDayLabels = () => {
        const hidingFactor = Math.floor(this.state.diffOfSelectedDays/30) + 1;
        d3.selectAll('.nv-chart svg .nv-x.nv-axis .tick text').attr('opacity', function (d,i) { return (i % hidingFactor === 0) ? '1' : '0'});
    }

    hideSomeXAxisHourLabels = () => {
        d3.selectAll('.nv-chart svg .nv-x.nv-axis .tick text').attr('opacity', function (d,i) { return (i % 3 === 0) ? '1' : '0'});
    }

    removeWeekEndsHighlight = () => {
        d3.selectAll('.nv-chart svg g g .weekend-highlight').remove();
    }

    addWeekEndsHighlight = () => {
        this.removeWeekEndsHighlight();
        const renderedInnerChartG = d3.select(".nv-chart svg g.nv-series-0");

        // the chart was already created
        if (renderedInnerChartG && renderedInnerChartG.node()){
            const existingHighlights = d3.select(".nv-chart svg g g .weekend-highlight")[0][0];

            // no highlight rects were added yet
            if (existingHighlights === null){
                const positionFactor = 0.2;
                let weekEndData = [];
                const chartDimensions = renderedInnerChartG.node().getBBox();
                if (chartDimensions.width && chartDimensions.width > 0){
                    const xScale = scaleBand()
                        .domain(this.getXTicks().map(d => moment(d).format('YYYYMMDD')))
                        .range([0, chartDimensions.width + chartDimensions.x * 2])
                        .paddingInner(0.2)
                        .paddingOuter(0.15);

                    const width = xScale.bandwidth() + xScale.bandwidth() * positionFactor;

                    if (this.state.periodFilter === 'week'){
                        let sunday = {};
                        sunday.xStart = xScale(this.state.datePicker.startDate.clone().startOf('week').format('YYYYMMDD')) - (xScale.bandwidth() * positionFactor/2);
                        sunday.width = width;

                        let saturday = {};
                        saturday.xStart = xScale(this.state.datePicker.startDate.clone().endOf('week').format('YYYYMMDD')) - (xScale.bandwidth() * positionFactor/2);
                        saturday.width = width;

                        weekEndData.push(sunday);
                        weekEndData.push(saturday);
                    }

                    if (this.state.periodFilter === 'month'){
                        const lastMonthDay = this.state.datePicker.startDate.clone().endOf('month');
                        let currentDay = this.state.datePicker.startDate.clone().startOf('month');

                        const weekEndDays = [];
                        while (currentDay.isSameOrBefore(lastMonthDay, 'day')){
                            if (currentDay.day() === 0 || currentDay.day() === 6)
                                weekEndDays.push(currentDay.clone());
                            currentDay.add(1, 'days');
                        }

                        weekEndDays.forEach(weekEndDay => {
                            let weekEndDayData = {};
                            let left = xScale(weekEndDay.clone().format('YYYYMMDD')) - (xScale.bandwidth() * positionFactor/2);
                            weekEndDayData.xStart = left;
                            weekEndDayData.width = width;
                            weekEndData.push(weekEndDayData);
                        });
                    }

                    const dateRangePicker = this.state.dateRangePicker;
                    if (this.state.periodFilter === 'custom'
                            && this.state.diffOfSelectedDays > 1 //diff of 1 === 2 days selected
                            && this.state.diffOfSelectedDays < daysToMonthGranularity
                            && dateRangePicker.startDate && dateRangePicker.endDate && dateRangePicker.open === false){
                        const firstPeriodDay = dateRangePicker.startDate.clone().startOf('day');
                        const lastPeriodDay = dateRangePicker.endDate.clone().endOf('day');
                        let currentDay = dateRangePicker.startDate.clone().startOf('day');

                        const weekEndDays = [];
                        while (currentDay.isSameOrAfter(firstPeriodDay, 'day') && currentDay.isSameOrBefore(lastPeriodDay, 'day')){
                            if (currentDay.day() === 0 || currentDay.day() === 6)
                                weekEndDays.push(currentDay.clone());
                            currentDay.add(1, 'days');
                        }

                        weekEndDays.forEach(weekEndDay => {
                            let weekEndDayData = {};
                            let left = xScale(weekEndDay.clone().startOf('day').format('YYYYMMDD')) - (xScale.bandwidth() * positionFactor/2);
                            weekEndDayData.xStart = left;
                            weekEndDayData.width = width;
                            weekEndData.push(weekEndDayData);
                        });
                    }
                }

                if (weekEndData.length > 0){
                    const group = d3.select(".nv-chart svg g g");

                    group.selectAll("rect.weekend-highlight")
                        .data(weekEndData)
                        .enter()
                        .insert('rect', 'g.nv-y + *')
                        .classed("weekend-highlight", true)
                        .attr("x", (d) => d.xStart)
                        .attr("y", 0)
                        .attr("width", (d) => d.width )
                        .attr("height", chartDimensions.height )
                        .attr("fill", "#d8d8d8")
                        .attr("opacity", 0.2);
                }
            }
        }
    }

    getRoundedTimestamp = (timestamp) =>{
        const { periodFilter, dateRangePicker: { startDate, endDate } } = this.state;
        const diffDays = endDate.endOf('day').diff(startDate.startOf('day'), 'days', true);
        let roundedTimestamp = null;

        if (periodFilter === 'day' || (periodFilter === 'custom' && diffDays <= 2)) {
            roundedTimestamp = moment(timestamp).startOf('hour');
        }else{
            roundedTimestamp = moment(timestamp).startOf('day');
        }
        return roundedTimestamp;
    }

    resetAlertEventsLines = (withDelay) => {
        if (withDelay){
            // 1 ms timeout needed to get correctly the current data disabled state on addAlertEvents
            setTimeout(() => {
                this.removeAlertEvents();
                setTimeout(this.addAlertEvents, 1250)
            }, 1);
        }else{
            this.removeAlertEvents();
            setTimeout(this.addAlertEvents, 1250)
        }
    }

    removeAlertEvents = () => {
        d3.selectAll('.nv-chart svg .alert-event-line').remove();
    }

    addAlertEvents = () => {
        if (this.state.showAlertEvents){
            const renderedInnerChartG = d3.select(".nv-chart svg g.nv-series-0");

            // the chart was already created'
            if (renderedInnerChartG && renderedInnerChartG.node()){
                const existingEvents = d3.select(".nv-chart svg g g .alert-event-line")[0][0];

                // no alert event lines were added yet
                if (existingEvents === null){
                    const chartDimensions = renderedInnerChartG.node().getBBox();
                    const xScale = scaleBand()
                        .domain(this.getXTicks())
                        .range([0, chartDimensions.width + chartDimensions.x * 2])
                        .paddingInner(0.2)
                        .paddingOuter(0.15)

                    const {data, events} = this.state;

                    let filteredEvents = [];
                    if (data && data.length && events && events.length ){
                        filteredEvents = events.filter(event => {
                            const found = data.find(datum => datum.key === event.key);
                            return (found) ? !found.disabled : false;
                        });
                        filteredEvents = filteredEvents.map(event => ({ ...event, timestamp: this.getRoundedTimestamp(event.eventStartPeriod) }));
                    }

                    if (filteredEvents.length > 0){
                        const group = d3.select(".nv-chart svg g g");
                        group.selectAll("path.alert-event-line")
                            .data(filteredEvents)
                            .enter()
                            .append("line")
                            .classed("alert-event-line", true)
                            .attr("x1", (d) => xScale(d.timestamp) + xScale.bandwidth()/2)
                            .attr("y1", 0)
                            .attr("x2", (d) => xScale(d.timestamp) + xScale.bandwidth()/2)
                            .attr("y2", chartDimensions.height)
                            .style("stroke-width", 2)
                            .style("stroke", "red")
                            .style("stroke-dasharray", ("10, 4"));
                    }
                }
            }
        }
    }

    isButtonNowDisabled = () => moment().isSame(this.state.datePicker.startDate, this.state.periodFilter)

    getGranularity = () => {
        const { periodFilter, dateRangePicker: { startDate, endDate }} = this.state
        const diffDays = endDate.diff(startDate, 'days', true)

        if (periodFilter === 'day' || (periodFilter === 'custom' && diffDays <= 2)) {
            return i18n.t('1 Hour');
        }

        if (periodFilter === 'year' || (periodFilter === 'custom' && diffDays >= daysToMonthGranularity)) {
            return i18n.t('1 Month')
        }

        return i18n.t('1 Day');
    }

    detailsDateFormatter = day => {
        if (this.state.diffOfSelectedDays <= 1) {
            return moment(day).format('llll')
        }

        return moment(day).format('LL')
    }

    onOutsideCompareClick = (e) => {
        this.onOutsideCompareClickTimer = setTimeout(() => {
            const groupsEle = document.getElementById('compare-card')
            if (!groupsEle) {
                return
            }

            const rootElem = groupsEle.children[0];
            const isDescendantOfRoot = rootElem.contains(e.target);

            if (!isDescendantOfRoot) {
                this.setState({
                    isOpened: false
                })
                document.body.removeEventListener('click', this.onOutsideCompareClick);
            }
        }, 50);
    }

    toggleCompareCollapse = (index, toClose) => {
        clearTimeout(this.onOutsideCompareClickTimer);

        if (this.state.isOpened) {
            document.body.removeEventListener('click', this.onOutsideCompareClick);
        } else {
            document.body.addEventListener('click', this.onOutsideCompareClick);
        }

        this.setState({
            isOpened: !this.state.isOpened
        });
    }

    getEventNames(events){
        return events.reduce((acc, event) => acc.concat([...new Set(event.values.map(v => v.name))]), []);
    }

    // Based on contentGenerator original function, in nvd3/src/tooltip.js
    createTooltip = (d) => {
        d3.selectAll('.nvtooltip:not(:last-child)').style('opacity', '0');
        if (!d) {
            return '';
        }

        const tariffs = d.data.tariffCompositions && d.data.tariffCompositions.reduce((prev, curr) => {
            if (curr && curr.tariffId){
                return (
                    {
                        ...prev,
                        [curr.tariffId]: [
                            ...prev[curr.tariffId] ? prev[curr.tariffId] : [],
                            {
                                ...curr,
                                tariff: this.state.tariffs.find(tariff => tariff.id === curr.tariffId)
                            }
                        ]
                    }
                )
            }else{
                return prev;
            }
        }, {});

        let currency = this.state.currency;
        if (d.data.tariffCompositions && d.data.tariffCompositions.length) {
            const tariffId = d.data.tariffCompositions[0] && d.data.tariffCompositions[0].tariffId;
            const firstCompositionCurrency = tariffId && this.tariffIdCurrencyMap.get(tariffId);
            if (firstCompositionCurrency)
                currency = firstCompositionCurrency;
        }

        const {events, showAlertEvents} = this.state;
        let foundEvents;
        if (showAlertEvents){
            foundEvents = events && events.length && events.filter(({ timestamp }) => {
                if (!timestamp)
                    return false;
                return this.getRoundedTimestamp(timestamp).isSame(moment(d.data.time));
            });
            foundEvents = nest().key(function(d) { return d.key; }).entries(foundEvents);
        }

        return `
            <div id="custom-tooltip">
                ${showAlertEvents && !!foundEvents && foundEvents.length ? `
                    <div class="alert-title">
                        ${this.getEventNames(foundEvents).map((event, i) => `<div key=${i}>` + i18n.t('Check alert:') + ` ${event}</div>`).join('')}
                    </div>
                ` : ''}
                <div class="tooltip-header">
                    <b>${this.tooltipHeaderFormatter(d.data.time)}</b>
                </div>
                <div class="tooltip-header total-cost">
                    <span>${i18n.t('Total Cost:')}</span>
                    ${this.costFormat(currency, d.data.cost)}
                    ${(d.data.aggregationType === 'ESTIMATED')
                        ? i18n.t('(estimated)')
                        : (d.data.aggregationType === 'HYBRID')
                            ? i18n.t('(partially estimated)')
                            : ''
                    }
                </div>
                <div class="tooltip-content">
                    ${!!tariffs && !!Object.keys(tariffs) ? Object.keys(tariffs).map(tariffId => (
                        `<div class="tariffs">
                            <div class="tariff-title">
                                ${!!tariffs[tariffId][0].tariff
                                    ? tariffs[tariffId][0].tariff.name
                                    : ''
                                }
                            </div>
                            <table class="tariff-compositions">
                                <tbody>
                                    ${tariffs[tariffId].map(composition => (
                                        `<tr>
                                            <td>${i18n.t('Rate:')} ${this.tariffFormat(this.rateCurrencyMap.get(composition.rate), composition.rate)}/${i18n.t('kWh')}</td>
                                            ${(composition.standingCharge)
                                                ? `<td>${i18n.t('Standing Charge:')} ${this.tariffFormat(this.rateCurrencyMap.get(composition.rate), composition.standingCharge)}/${i18n.t('Day')}</td>`
                                                : ''
                                            }
                                        </tr>`
                                    )).join('')}
                                </tbody>
                            </table>
                        </div>`
                    )).join('') : ''}
                </div>
            </div>
        `;
    }

    createRatesCurrenciesMap = (barsData, tariffIdCurrencyMap) => {
        const rateCurrencyMap = new Map();

        if (!barsData && this.state.data && this.state.data.length)
            barsData = this.state.data[0].values;

        if (!barsData || !barsData.length)
            return rateCurrencyMap;

        barsData.forEach(d => {
            if (d.tariffCompositions && d.tariffCompositions.length){
                d.tariffCompositions.forEach(composition => {
                    if (composition && composition.rate)
                        rateCurrencyMap.set(composition.rate, tariffIdCurrencyMap.get(composition.tariffId));
                });
            }
        });
        return rateCurrencyMap;
    }

    getRates = (barsData) => {
        if (!barsData && this.state.data && this.state.data.length)
            barsData = this.state.data[0].values;

        if (!barsData || !barsData.length)
            return [];

        const allRates = barsData.map(d => {
            if (d.tariffCompositions && d.tariffCompositions.length &&
                d.tariffCompositions[0] && d.tariffCompositions[0].rate){
                return d.tariffCompositions[0].rate;
            }else{
                return null;
            }
        }).filter(d => d);
        const uniqueRates = [...new Set(allRates)].sort(ascending);
        return uniqueRates;
    }

    createRateColorScale = (barsData) => {
        const rates = this.getRates(barsData);

        if (!rates.length)
            return null;

        const minRate = min(rates);
        const maxRate = max(rates);

        let rateColorScale;

        // if the min and max are equal, there's only one rate to create the scale.
        // So, this 'scale' is simply a function that always returns the first colour of the list
        if (minRate === maxRate){
            rateColorScale = () => tariffRatesColors[0];
        }else{
            rateColorScale = scaleLinear().domain(range(minRate, maxRate + maxRate/100, (maxRate - minRate)/(tariffRatesColors.length - 1))).range(tariffRatesColors);
        }

        return rateColorScale;
    }

    getBarColor = (d) => {
        if (d === null || typeof d === 'undefined'){
            console.warn("Returnig a fallback colour due to null datum in bar chart.")
            return colors.fallback;
        }

        if (!this.state.rateColorScale || !d.tariffCompositions || !d.tariffCompositions.length){
            //No tariff compositions found, returning old color scheme
            return isEstimatedData(d) ? colors.estimated : colors.default
        }

        const datumRate = d.tariffCompositions[0] ? d.tariffCompositions[0].rate : null;

        if (!datumRate || Number.isNaN(datumRate)){
            console.warn("Returnig a default colour due to non numeric rate in bar chart.");
            return colors.default;
        }

        return this.state.rateColorScale(datumRate);
    }

    render () {
        const { periodFilter, loadingData, diffOfSelectedDays } = this.state;
        const { classes } = this.props;

        const isButtonNowDisabled = this.isButtonNowDisabled();

        let xAxis = {
            tickFormat: this.xTickFormat,
            showMaxMin: false,
        };

        if (this.state.periodFilter === 'custom'){
            if (diffOfSelectedDays === 1){
                xAxis.rotateLabels = -25;
                xAxis.tickPadding = 0;
            }else if (diffOfSelectedDays >= 20 && diffOfSelectedDays < daysToMonthGranularity){
                xAxis.rotateLabels = -50;
                xAxis.tickPadding = 0;
            }else{
                xAxis.tickPadding = 10;
            }
        }

        if (this.state.periodFilter === 'month' || this.state.periodFilter === 'day'){
            xAxis.rotateLabels = -50;
            xAxis.tickPadding = 0;
        }

        if (this.state.periodFilter === 'week' || this.state.periodFilter === 'year')
            xAxis.tickPadding = 10;

        if (this.state.periodFilter === 'day')
            xAxis.ticks = 24;

        let yAxis = { axisLabel: this.state.currency || i18n.t('Cost'), tickFormat: yTickFormat };

        if (this.state.max && this.state.max.cost <= 0.1)
            yAxis.ticks = costNumberFormat(this.state.max.cost) * 100;

        const rates = this.getRates();

        return (
            <div id="dashboard">
                <div id="bar-chart">
                    <div id="top-bar">
                        <div className={`top top-period-filter-${periodFilter}`}>
                            <div>
                                {this.periodOptions.map((period, key) => (
                                    <Button className={
                                        `btn-period-filter${periodFilter === period.value ? ' active' : ''}`}
                                        disabled={
                                            loadingData ||
                                            periodFilter === period.value ||
                                            (!this.state.loadingGroups && this.state.groups.length === 0)
                                        }
                                        key={key}
                                        onClick={() => this.changePeriodFilter(period)}>
                                        {period.label}
                                    </Button>
                                ))}
                            </div>

                            <div>
                                {periodFilter !== 'custom' ?
                                    <IconButton className="btn-previous btn-previous-date"
                                        disabled={loadingData}
                                        onClick={this.goToPreviousPeriod}>
                                        <ExpandMoreIcon />
                                    </IconButton> : null}

                                <DatePicker
                                    chartType={'line-chart'}
                                    datePicker={this.state.datePicker}
                                    dateRangePicker={this.state.dateRangePicker}
                                    updateDatePicker={this.updateDatePicker}
                                    updateDateRangePicker={this.updateDateRangePicker}
                                    periodFilter={periodFilter}
                                    isLinear={this.state.isLinear}
                                    loadingGroups={this.state.loadingGroups}
                                    history={this.props.history}
                                    disabled={!this.state.loadingGroups && this.state.groups.length === 0}
                                    showLabel={false}
                                    showAlertEvents={this.state.showAlertEvents} />

                                {periodFilter !== 'custom' ?
                                    <IconButton className="btn-next btn-next-date"
                                        disabled={
                                            loadingData ||
                                            this.state.datePicker.startDate.isSameOrAfter(
                                                moment().startOf(periodFilter))
                                        }
                                        onClick={this.goToNextPeriod}>
                                        <ExpandMoreIcon />
                                    </IconButton> : null}

                                {periodFilter !== 'custom' ?
                                    <Button className={`btn-period${isButtonNowDisabled ? ' active' : ''}`}
                                        disabled={
                                            loadingData ||
                                            isButtonNowDisabled ||
                                            (!this.state.loadingGroups && this.state.groups.length === 0)
                                        }
                                        onClick={() => this.goToCurrentDay()}>
                                        {i18n.t('NOW')}
                                    </Button> : null}
                            </div>

                            <div className="granularity">
                                <div>
                                    <Text text={this.getGranularity()} />
                                </div>
                            </div>
                        </div>
                        {!this.state.loadingGroups && (
                            <div id="group-filters">
                                <div id="compare-card" className="bottom">
                                    <CompareCard
                                        changeGroup={this.changeGroup}
                                        changeSensor={this.changeSensor}
                                        changeSubgroup={this.changeSubgroup}
                                        changeTag={this.changeTag}
                                        compare={this.state.compare}
                                        filterGroupOptions={this.state.filterGroupOptions}
                                        tags={this.state.tags}
                                        index={0}
                                        obj={{
                                            color: '#FFF',
                                            filterTag: this.state.filterTag,
                                            filterGroup: this.state.filterGroup,
                                            filterSubgroup: this.state.filterSubgroup,
                                            filterSensor: this.state.filterSensor,
                                            open: this.state.isOpened,
                                            filterSensorOptions: this.state.filterSensorOptions,
                                            filterSubgroupOptions: this.state.filterSubgroupOptions
                                        }}
                                        toggleCompareCollapse={this.toggleCompareCollapse}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div id="chart">
                        <div className="switch">
                            <p>{i18n.t('Tariff Calculation')}</p>
                            <span className={`label ${!!this.state.calcBySubLoad ? 'active' : ''}`}>
                                {i18n.t('Sum of Parts')}
                            </span>
                            <Switch
                                checked={!this.state.calcBySubLoad}
                                onChange={this.toggleCalculation}
                                value="linear"
                                classes={{
                                    switchBase: classes.switchBase,
                                    checked: classes.checked,
                                    bar: classes.bar,
                                }}
                            />
                            <span className={`label ${!this.state.calcBySubLoad ? 'active' : ''}`}>
                                {i18n.t('Total')}
                            </span>
                        </div>

                        {periodFilter !== 'year' && !(periodFilter === 'custom' && diffOfSelectedDays >= daysToMonthGranularity) ?
                            <div className={`switch show-alerts ${this.state.loadingEvents || !this.state.events.length ? 'disabled' : ''}`}>
                                <p>{i18n.t('Show alerts')}</p>
                                <span className={`label ${!this.state.showAlertEvents ? 'active' : ''}`}>
                                    {i18n.t('Off')}
                                </span>
                                <Switch
                                    checked={(this.state.loadingEvents || !this.state.events.length) ? false : this.state.showAlertEvents}
                                    onChange={this.toggleShowAlertEvents}
                                    disabled={this.state.loadingEvents || !this.state.events.length}
                                    value="showAlerts"
                                    classes={{
                                        switchBase: classes.switchBaseOnOff,
                                        checked: classes.checked,
                                        bar: classes.bar,
                                        disabled: classes.disabled,
                                    }}
                                />
                                <span className={`label ${this.state.showAlertEvents ? 'active' : ''}`}>
                                    {i18n.t('On')}
                                </span>
                            </div>
                        : null}

                        {(this.isTagWithMultipleCurrencies()) ? (
                            <div className="nv-chart multiple-currencies-msg">
                                <p className="no-data">{this.multipleCurrenciesChartMessage}</p>
                            </div>
                        ) : (!this.state.loadingGroups &&
                            !this.state.loadingData &&
                            !this.state.data.length) ? (
                                <div className="nv-chart no-chart-msg">
                                    <p className="no-data"> {this.noDataChartMessage} </p>
                                </div>
                        ) : (
                            <div id="graph">
                                <ChartLoading loading={this.state.loadingData} />
                                <div id="bar-chart-legend">
                                    {rates.length && this.state.rateColorScale ? rates.map((rate, i) => (
                                        <div key={i}>
                                            <span className="legend-circle" style={{backgroundColor: this.state.rateColorScale(rate)}} /> {this.tariffFormat(this.rateCurrencyMap.get(rate), rate)}/{i18n.t('kWh')}
                                        </div>
                                    )) : null}
                                </div>
                                <NVD3Chart
                                    type="discreteBarChart"
                                    datum={this.state.data}
                                    x="time"
                                    y="cost"
                                    options={{
                                        color: (d) => this.getBarColor(d),
                                        forceY: this.state.max ?
                                            [0, (this.state.max.cost <= 0.1) ? costNumberFormat(this.state.max.cost) : this.state.max.cost] : [0],
                                        noData: this.state.noDataChartMsg,
                                        useInteractiveGuideline: true,
                                        xAxis: xAxis,
                                        rectClass: (d) => isEstimatedData(d) && d.cost !== 0 ? 'estimated' : null,
                                        defined: (d) => d.defined,
                                        yAxis: yAxis,
                                        showControls: false,
                                        tooltip: {
                                            headerFormatter: this.tooltipHeaderFormatter,
                                            contentGenerator: (d) =>  this.createTooltip(d),
                                        },

                                    }} />
                            </div>
                        )}
                        {!this.isTagWithMultipleCurrencies() && (
                            <div className="tables">
                                <Table className="chart-table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{i18n.t('Group / Subgroup / Sensor')}</TableCell>
                                            <TableCell>{i18n.t('Total Cost')}</TableCell>
                                            <TableCell>{this.periodsText[this.state.periodFilter]}</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                {this.state.breadcrumbsTrail}
                                            </TableCell>
                                            <TableCell>
                                                {this.costFormat(this.state.currency, this.state.totalCost)}
                                            </TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableBody>
                                </Table>
                                <Table className="details-table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{i18n.t('Max')}</TableCell>
                                            <TableCell>{i18n.t('Min')}</TableCell>
                                            <TableCell>{i18n.t('Avg')}</TableCell>
                                            <TableCell>{this.getGranularity()}</TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <div>
                                                    {this.state.max && (this.state.currency || this.state.filterTag) ? this.costFormat(this.state.currency, this.state.max.cost) : '-'}
                                                    <small>
                                                        {this.state.max && this.state.max.hour
                                                            ? `${moment(this.state.max.hour, 'HH:mm:ss').format('LT')}`
                                                            : this.state.max && this.state.max.dayAndHour
                                                                ? this.detailsDateFormatter(this.state.max.dayAndHour)
                                                                : this.state.max && this.state.max.day
                                                                    ? moment(this.state.max.day, 'YYYY-MM-DD').format('LL')
                                                                    : '-'
                                                        }
                                                    </small>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div>
                                                    {this.state.min && (this.state.currency || this.state.filterTag) ? this.costFormat(this.state.currency, this.state.min.cost) : '-'}
                                                    <small>
                                                        {this.state.min && this.state.min.hour
                                                            ? `${moment(this.state.min.hour, 'HH:mm:ss').format('LT')}`
                                                            : this.state.min && this.state.min.dayAndHour
                                                                ? this.detailsDateFormatter(this.state.min.dayAndHour)
                                                                : this.state.min && this.state.min.day
                                                                    ? moment(this.state.min.day, 'YYYY-MM-DD').format('LL')
                                                                    : '-'
                                                        }
                                                    </small>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                {this.state.averageCost && this.state.currency ? this.costFormat(this.state.currency, this.state.averageCost) : '-'}
                                            </TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </div>
                        )}
                        <div id="tariff-info">
                            {(this.shouldShowTariffTable() && this.state.tariffs && this.state.tariffs.length) ?
                                <Table className="chart-table tariff-table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{i18n.t('Tariff')}</TableCell>
                                            <TableCell>{i18n.t('Rate')}</TableCell>
                                            <TableCell>{i18n.t('Standing Charge')}</TableCell>
                                            <TableCell>{i18n.t('Start Date')}</TableCell>
                                            <TableCell>{i18n.t('End Date')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.state.tariffs.map((tariff, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{tariff.name}</TableCell>
                                                <TableCell>{this.tariffFormat(tariff.currency, tariff.rate)}</TableCell>
                                                <TableCell>{(tariff.standingCharge && tariff.standingCharge > 0) ? this.tariffFormat(tariff.currency,  tariff.standingCharge) : ' - '}</TableCell>
                                                <TableCell>{moment(tariff.initDate).format('LLL')}</TableCell>
                                                <TableCell>{(tariff.endDate) ? moment(tariff.endDate).format('LLL') : ' - '}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table> : null}
                        </div>
                    </div>
                </div>
                <SystemSnackbar
                    open={!this.state.loadingGroups && this.state.groups.length === 0}
                    message={i18n.t('No group setup. Please setup a group in "Configurations > Groups > Create Group".')}
                    variant='warning' />
            </div>
        )
    }
}

BarChart.propTypes = {
    intl: intlShape.isRequired,
};

export default withStyles(styles)(injectIntl(BarChart));
