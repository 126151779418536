import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import Stepper, {Step, StepLabel, StepContent} from 'material-ui/Stepper';
import Check from 'material-ui-icons/Check'
import { Button as VButton } from '../../elements/button';
// import Typography from 'material-ui/Typography';
import {API} from '../../components/api';
import './add-group.css';

import GroupForm from './group-form';
import SubgroupForm from './subgroup-form';
import GroupReview from './group-review';
import Dialog, {
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from 'material-ui/Dialog';
import Button from 'material-ui/Button';
import SystemSnackbar from '../../elements/material-ui/SystemSnackbar';

import i18n from '../../i18n';

const styles = theme => ({
    root: {
        display: 'flex',
        height: '100%'
    },
    resetContainer: {
        padding: theme.spacing.unit * 3,
    },
    stepperRoot: {
        backgroundColor: '#ECECEC'
    },
    stepperContainer:{
        flex: '0.25 0 0',
        backgroundColor: '#ECECEC',
    },
    formContainer:{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: '0.75 0 0',
        height: '100%',
        overflow: 'hidden'
    },
    form: {
        padding: theme.spacing.unit * 4,
        height: 'calc(100% - 142px)',
        overflow: 'auto'
    },
    stepButtons:{
        borderTop: '2px solid #CECECE',
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '20px 10px',
    },
    stepSubgroupListItem: {
        display: 'flex',
        alignItems: 'center',
        color: 'rgba(0, 0, 0, 0.87)',
        '& div:hover': {
            textDecoration: 'underline',
            cursor: 'pointer',
        }
    },
    actionConfirm: {
        color: '#6ab42d',
    },
    actionCancel: {
        color: '#c62828',
    },
});

const baseSubgroup = {
    seq: 0,
    mainSensorsPhases: [],
    subLoadSensorsPhases: [],
    name: '',
}

const getTag = (tags, value) => {
    const found = tags.find(tag => tag.label === value || tag.value === value)

    return found ? found.value : value
}

class GroupAddStepper extends React.Component {
    constructor(props) {
        super(props);

        this.loadErrorMessage = i18n.t('An error occurred while retrieving data.');

        this.steps = [
            {label: i18n.t('Group Configuration'), value: 'group'},
            {label: i18n.t('Subgroups Configuration'), value: 'subgroups'},
            {label: i18n.t('Review Details'), value: 'details'},
        ];

        this.state = {
            activeStep: 0,
            activeSubgroupStep: null,//when null, it's creating a new subgroup
            loadingTariffs: true,
            tariffs: [],
            loadingTags: true,
            tags: [],
            availableSensors: [],
            loadingSensors: true,
            group: {
                subgroups: [],
                groupMainSensorsPhases: []
            },
            dataLossModalDialogOpen: false,
            showSnackBar: false,
            snackbar: {
                type: 'warning',
                message: ''
            },
        };
    }


    componentDidMount() {
        const id = this.props.editGroupId ? this.props.editGroupId : 0;
        const sessionGroup = JSON.parse(window.sessionStorage.getItem(`unsavedGroup_${id}`));
        const activeStep = parseFloat(window.sessionStorage.getItem(`activeStep_${id}`));
        const activeSubgroupStep = parseFloat(window.sessionStorage.getItem(`activeSubgroupStep_${id}`));
        const editingSubGroup = JSON.parse(window.sessionStorage.getItem(`subgroupEdit_${id}_${activeSubgroupStep}`));

        if (sessionGroup) {
            this.setState({
                group: sessionGroup,
                activeStep: isNaN(activeStep) ? 0 : activeStep,
                activeSubgroupStep: isNaN(activeSubgroupStep) ? 0 : activeSubgroupStep,
            })

            this.setState({
                tags: [...this.state.tags, ...this.tagsToAddInSession(sessionGroup, editingSubGroup)]
            });
        }

        if (id && !sessionGroup)
            this.getGroup(id)

        API.TARIFFS.GET().then(({ data }) => {
            if (data && data.length){
                const tariffs = data.map(t => ({
                    label: t.name,
                    value: t.id,
                    currency: t.currency,
                    rate: t.offPeakRate
                }));

                this.setState({loadingTariffs: false, tariffs: tariffs});
            } else {
                this.setState({loadingTariffs: false});
            }
        }).catch((error) => {
            this.handleLoadError({loadingTariffs: false}, "Could not load tariffs.", error);
        });

        API.LABELS.GET().then(({ data }) => {
            if (data && data.length){
                const tags = data.map(t => ({
                    label: t.name,
                    value: t.id
                }));

                this.setState({
                    tags:[...this.state.tags, ...tags],
                    loadingTags: false,
                });
            } else{
                this.setState({ loadingTags: false });
            }
        }).catch((error) => {
            this.handleLoadError({ loadingTags: false }, 'Could not load tags.', error);
        });

        API.SENSORS.WITHOUT_GROUP.GET().then((response) => {
            if (response.data && response.data.length > 0){
                let sensors = this.prepareSensorPhases(response.data, false);
                this.setState({
                    availableSensors: sensors,
                    loadingSensors: false
                });
            }else if (response.data && response.data.length === 0){
                this.setState({
                    loadingSensors: false
                });
            }else{
                this.handleLoadError({loadingSensors: false}, "Could not load sensors.")
            }
        }).catch((error) => {
            this.handleLoadError({loadingSensors: false}, "Could not load sensors.", error)
        });     
    }

    tagsToAddInSession = (sessionGroup, editingSubGroup) => {
        let tagsToAddInSession = [];

        if(sessionGroup.tagId && typeof(sessionGroup.tagId) === 'string')
            tagsToAddInSession = tagsToAddInSession.concat(sessionGroup.tagId);

        if(sessionGroup.subgroups && sessionGroup.subgroups.length)
            sessionGroup.subgroups.forEach((subgroup) => {
                tagsToAddInSession = tagsToAddInSession.concat(this.getSubgroupTagsToAddInSession(subgroup))
            });

        if(editingSubGroup)
            tagsToAddInSession = tagsToAddInSession.concat(this.getSubgroupTagsToAddInSession(editingSubGroup));

        //remove duplicated itens on array
        tagsToAddInSession = [...new Set(tagsToAddInSession)];

        tagsToAddInSession = tagsToAddInSession.map(tag => ({
            label: tag,
            value: tag,
        }));

        return tagsToAddInSession;
    }

    getSubgroupTagsToAddInSession = (subgroup) => {
        let tagsToAddInSession = [];
        
        tagsToAddInSession = tagsToAddInSession.concat(!subgroup.tagsIds ? [] : subgroup.tagsIds.filter(tag => typeof(tag) === 'string'))

        if (subgroup.subLoadSensorsPhases && subgroup.subLoadSensorsPhases.length > 0)
            subgroup.subLoadSensorsPhases.forEach(sensor => {
                tagsToAddInSession = tagsToAddInSession.concat(!sensor.tags ? [] : sensor.tags.filter(tag => typeof(tag) === 'string'))
        });

        return tagsToAddInSession;
    }

    getGroup = id => {
        API.GROUP.GET(id).then((response) => {
            if (response.data){
                let subgroupsSeq = 0;

                // group/subgroups data model transform (back-end model => frontend back-end model)
                const editGroupState = {
                    id: response.data.id,
                    groupMainSensorsPhases: this.prepareSensorPhases(response.data.sensors, true),
                    originalGroupMains: this.prepareSensorPhases(response.data.sensors, true),
                    originalSubgroupsIds: response.data.subGroups.map(subgroup => subgroup.id),
                    name: response.data.name,
                    tagId: response.data.labels && response.data.labels.length
                        ? response.data.labels[0].id
                        : response.data.sensors && response.data.sensors.length && response.data.sensors[0].labels && response.data.sensors[0].labels.length
                            ? response.data.sensors[0].labels[0].id
                            : null,
                    tariffId: response.data.tariffId ? response.data.tariffId : null,
                    subgroups: response.data.subGroups.map((subgroup) => {
                        const sub = Object.assign({}, baseSubgroup, { seq: subgroupsSeq });

                        sub.tagsIds = subgroup.sensors && subgroup.sensors.length && subgroup.sensors[0].labels && subgroup.sensors[0].labels.length
                            ? subgroup.sensors[0].labels.map(({ id }) => id)
                            : subgroup.labels && subgroup.labels.length
                                ? subgroup.labels.map(({ id }) => id)
                                : [];

                        sub.tariffId = subgroup.sensors && subgroup.sensors.length && subgroup.sensors[0].tariffId
                            ? subgroup.sensors[0].tariffId
                            : subgroup.tariffId ? subgroup.tariffId : null;

                        sub.mainSensorsPhases = this.prepareSensorPhases(subgroup.sensors, true);
                        sub.originalMains = this.prepareSensorPhases(subgroup.sensors, true);
                        sub.id = subgroup.id;
                        sub.name = subgroup.name;
                        sub.subLoadSensorsPhases = [].concat(...subgroup.subGroups.map(sub => sub.sensors)); //flattens it
                        sub.originalSubLoads = this.prepareSensorPhases(sub.subLoadSensorsPhases, true);
                        sub.subLoadSensorsPhases = this.prepareSensorPhases(sub.subLoadSensorsPhases, true);

                        sub.subLoadSensorsPhases = sub.subLoadSensorsPhases && sub.subLoadSensorsPhases.length && sub.subLoadSensorsPhases.map(sensor => ({
                        ...sensor,
                        tags: sensor.labels && sensor.labels.length
                            ? sensor.labels.map(({ id }) => id)
                            : null
                        }));
                        // Internal subgroup id. In the backend the subload sensors are stored in a sub-subgroup:
                        sub.subLoadsInternalSubgroupId = subgroup.subGroups && subgroup.subGroups.length ? subgroup.subGroups[0].id : null;

                        subgroupsSeq++;
                        return sub;
                     })
                };

                if (response.data.tariffId) {
                    editGroupState.selectedTariff = response.data.tariffId
                }
                editGroupState.originalFlatKeyList = this.getFlatKeyList(editGroupState, true);
                editGroupState.subgroupsSeq = subgroupsSeq;

                this.setState({ group: editGroupState });
                window.sessionStorage.setItem(`unsavedGroup_${this.props.editGroupId}`, JSON.stringify(editGroupState))
            }
        });
    }

    getFlatKeyList = (state, asObjects) => {
        let flatSensorsList = [];

        if (state.groupMainSensorsPhases && state.groupMainSensorsPhases.length > 0){
            flatSensorsList = flatSensorsList.concat(state.groupMainSensorsPhases);
        }

        const subgroupsSensorsPhases = state.subgroups.map((subgroup) => {
            let sensors = [];
            if (subgroup.mainSensorsPhases && subgroup.mainSensorsPhases.length > 0)
                sensors = sensors.concat(subgroup.mainSensorsPhases);

            if (subgroup.subLoadSensorsPhases && subgroup.subLoadSensorsPhases.length > 0){
                sensors = sensors.concat(subgroup.subLoadSensorsPhases);
            }
            return sensors;
        });

        flatSensorsList = flatSensorsList.concat(...subgroupsSensorsPhases);
        flatSensorsList = flatSensorsList.filter(s => s.key !== '');//removes undefined elements

        if (asObjects === true)
            return flatSensorsList;

        return flatSensorsList.map(sensor => sensor.key);
    }

    prepareSensorPhases = (sensorPhases, withSeq) => {
        if (!sensorPhases) {
            return null;
        }

        let prepared = [].concat(sensorPhases.map(sensorPhase => this.getSensorWithLabel(sensorPhase)));
        //removes empty elements
        prepared = prepared.filter(sensor => !!sensor);

        if (withSeq === true) {
            prepared = prepared.map((sensorPhase, seq) => ({
                ...sensorPhase,
                seq
            }));
        }

        return prepared;
    }

    getSensorWithLabel = (sensor) => {
        if (sensor && sensor.id && sensor.channel){
            return {
                ...sensor,
                name: sensor.name ? sensor.name : '',
                key: sensor.id + "-" + sensor.channel,
            };
        }

        return '';
    }

    // returns the available to be assigned sensors, in comparison to the initial sensors available
    // and, for editing, to the unselected sensors
    selectedAvailableDiff = ({ groupMainSensorsPhases, subgroups }) => {
        const availableSensors = this.state.availableSensors.filter((available) => {
            // the sensor was previoulsy selected as group main, then it is not available
            const selectedInGroupMain = groupMainSensorsPhases && groupMainSensorsPhases.some(s =>
                s && s.key && s.key === available.key
            );

            const selectedInSubgroups = subgroups.filter((subgroup, i) => i !== this.state.activeSubgroupStep).some(subgroup => (
                // the sensor was previoulsy selected in the subgroup main, then it is not available
                subgroup.mainSensorsPhases && subgroup.mainSensorsPhases.some((sensor) =>
                    sensor && sensor.key && sensor.key === available.key
                )) || (
                // the sensor was previoulsy selected in the subgroup subload sensors, then it is not available
                (subgroup.subLoadSensorsPhases && subgroup.subLoadSensorsPhases.some(sensor =>
                    sensor && sensor.key && sensor.key === available.key
                ))
            ));

            if (selectedInGroupMain || selectedInSubgroups)
                return false;

            // the sensor was not selected, then it is available
            return true;
        });

        return availableSensors.sort((a,b) => a.id - b.id);
    }

    checkSensorPhaseSelection = () => {
        let {groupMainSensorsPhases} = this.state.group;
        groupMainSensorsPhases = groupMainSensorsPhases ? groupMainSensorsPhases.filter(s => s.key !== '') : [];//removes undefined elements

        if (groupMainSensorsPhases.length)
            return true;

        const {group} = this.state;
        const sensorPhaseInSubgroups = group.subgroups.some((subgroup) => {
            subgroup.mainSensorsPhases = (subgroup.mainSensorsPhases) ? subgroup.mainSensorsPhases.filter(s => s.key !== '') : [];

            if (subgroup.mainSensorsPhases.length)
                return true;

            subgroup.subLoadSensorsPhases = (subgroup.subLoadSensorsPhases) ? subgroup.subLoadSensorsPhases.filter(s => s.key !== ''): [];

            if (subgroup.subLoadSensorsPhases.length)
                return true;

            return false;
        });
        return sensorPhaseInSubgroups;
    }

    handleLoadError = (state, message, error) => {
        state.loadErrorMessage = this.loadErrorMessage;
        this.setState(state);

        console.error(this.loadErrorMessage + " (" + message + ")");
        if (error)
            console.error(error);
    }

    handleNextClick = () => {
        if (this.state.activeStep === this.steps.length - 1) {
            if (this.checkSensorPhaseSelection()){
                return this.saveGroup()
            }else{
                this.openSnackBar(i18n.t('No sensors were selected for group nor for any subgroup. Please select at least one sensor and try again.'), 'warning')
            }
        }else{
            // `checkForm = true` will inform child form components that they need to
            // inform whether form is valid by using goToNext function
            this.setState({checkForm: true});
        }
    };

    handleSkipClick = () => {
        const lastStep = 2;
        this.handleDataLossModalDialogOpen({fn: 'goToGroup', param: lastStep});
        window.sessionStorage.setItem(`activeStep_${this.props.editGroupId}`, lastStep)
    };

    handleBackClick = () => {
        if (this.state.activeStep === 2){
            return this.goOneStepBehind();
        }else{
            return this.handleDataLossModalDialogOpen({fn: 'goOneStepBehind', param: null});
        }
    };

    handleReset = () => {
        this.setState({
            activeStep: 0,
        });
    };

    goToNext = (isFormValid, group) => {
        if (isFormValid) {
            window.sessionStorage.setItem(`unsavedGroup_${this.props.editGroupId ? this.props.editGroupId : 0}`, JSON.stringify(group))
            this.setState({
                group: group,
            });
            this.goOneStepAhead();
        }

        this.setState({
            checkForm: false,
        });
    }

    addTag = tag => this.setState({
        tags: [...this.state.tags, tag]
    })

    goToGroup = (i) => {
        const { activeSubgroupStep, group } = this.state;
        window.sessionStorage.setItem(`subgroupEdit_${group.id ? group.id : 0}_${activeSubgroupStep ? activeSubgroupStep : 0 }`, null);

        window.sessionStorage.setItem(`activeSubgroupStep_${this.props.editGroupId}`, 0)
        window.sessionStorage.setItem(`activeStep_${this.props.editGroupId}`, i)
        this.setState({
            activeStep: i,
            activeSubgroupStep: 0,
        });
    }

    goToSubgroup = (i) => {
        const { activeSubgroupStep, group } = this.state;
        window.sessionStorage.setItem(`subgroupEdit_${group.id ? group.id : 0}_${activeSubgroupStep ? activeSubgroupStep : 0 }`, null);

        window.sessionStorage.setItem(`activeStep_${this.props.editGroupId ? this.props.editGroupId : 0}`, 1)
        window.sessionStorage.setItem(`activeSubgroupStep_${this.props.editGroupId ? this.props.editGroupId : 0}`, i)
        this.setState({
            activeStep: 1,
            activeSubgroupStep: i,
        });
    }

    goOneStepAhead = () => {
        const { activeStep, activeSubgroupStep, group } = this.state;

        if (activeStep === 1){
            if (activeSubgroupStep !== null && activeSubgroupStep < group.subgroups.length - 1){
                window.sessionStorage.setItem(`activeSubgroupStep_${this.props.editGroupId ? this.props.editGroupId : 0}`, activeSubgroupStep + 1)
                this.setState({
                    activeSubgroupStep: activeSubgroupStep + 1,
                });
            }else{
                window.sessionStorage.setItem(`activeStep_${this.props.editGroupId ? this.props.editGroupId : 0}`, activeStep + 1)
                this.setState({
                    activeStep: activeStep + 1,
                });
            }
        }else{
            if (activeStep === 0){
                window.sessionStorage.setItem(`activeSubgroupStep_${this.props.editGroupId ? this.props.editGroupId : 0}`, (group.subgroups && group.subgroups.length) ? 0 : null)
                this.setState({
                    activeSubgroupStep: (group.subgroups && group.subgroups.length) ? 0 : null,
                });
            }

            window.sessionStorage.setItem(`activeStep_${this.props.editGroupId ? this.props.editGroupId : 0}`, activeStep + 1)
            this.setState({
                activeStep: activeStep + 1,
            });
        }
    }

    goOneStepBehind = () => {
        const { activeStep, activeSubgroupStep, group } = this.state;

        if (activeStep === 1){
            window.sessionStorage.setItem(`subgroupEdit_${group.id ? group.id : 0}_${activeSubgroupStep ? activeSubgroupStep : 0 }`, null);

            if (activeSubgroupStep === null && group.subgroups.length > 0){
                window.sessionStorage.setItem(`activeSubgroupStep_${this.props.editGroupId ? this.props.editGroupId : 0}`, group.subgroups.length - 1)
                this.setState({
                    activeSubgroupStep: group.subgroups.length - 1,
                });
            }else if (activeSubgroupStep > 0){
                window.sessionStorage.setItem(`activeSubgroupStep_${this.props.editGroupId ? this.props.editGroupId : 0}`, activeSubgroupStep - 1)
                this.setState({
                    activeSubgroupStep: activeSubgroupStep - 1,
                });
            }else{
                window.sessionStorage.setItem(`activeStep_${this.props.editGroupId ? this.props.editGroupId : 0}`, activeStep - 1)
                this.setState({
                    activeStep: activeStep - 1,
                });
            }
        }else{
            window.sessionStorage.setItem(`activeSubgroupStep_${this.props.editGroupId ? this.props.editGroupId : 0}`, (group.subgroups && group.subgroups.length) ? group.subgroups.length - 1 : null)
            window.sessionStorage.setItem(`activeStep_${this.props.editGroupId ? this.props.editGroupId : 0}`, activeStep - 1)
            this.setState({
                activeStep: activeStep - 1,
                activeSubgroupStep: (group.subgroups && group.subgroups.length) ? group.subgroups.length - 1 : null,
            });
        }
    }

    saveTags = tags => Promise.all(tags.filter(tag => tag.value === tag.label).map(tag =>
        API.LABELS.ADD(tag.label)
    )).then(responses => 
            responses.map(response => ({
            label: response.data.name,
            value: response.data.id
    }))).catch((error) => {
        this.handleError(i18n.t("An error occurred while adding the Tag's."));
        throw error;
    });

    saveGroup = async () => {
        if (this.state.loading) {
            return
        }

        this.setState({loading: true});

        const selectedTags = this.state.tags.filter(tag =>
            tag.value === this.state.group.tagId ||
            !!this.state.group.subgroups.find(subgroup => (
                (subgroup.tagsIds && subgroup.tagsIds.includes(tag.value)) ||
                (subgroup.subLoadSensorsPhases && subgroup.subLoadSensorsPhases.length && subgroup.subLoadSensorsPhases.find(s => (s.tags && s.tags.includes(tag.value))))
            ))
        );

        const resTags = await this.saveTags(selectedTags);
        const tags = selectedTags.map(tag => {
            const found = resTags.find(resT => resT.label === tag.label)
            return found ? found : tag
        });

        if (!this.props.editGroupId) {
            const group = {
                name: this.state.group.name,
                groupMainSensorsPhases: this.state.group.groupMainSensorsPhases,
                subGroups: this.state.group.subgroups && this.state.group.subgroups.length
                    ? this.state.group.subgroups.map(subgroup => ({
                        ...subgroup,
                        tagsIds: subgroup.tagsIds && subgroup.tagsIds.length ? [getTag(tags, subgroup.tagsIds[0])] : [],
                        subLoadSensorsPhases: subgroup.subLoadSensorsPhases && subgroup.subLoadSensorsPhases.length
                            ? subgroup.subLoadSensorsPhases.map(subload => ({
                                ...subload,
                                tags: subload.tags && subload.tags.length ? [getTag(tags, subload.tags[0])] : []
                            })) : subgroup.subLoadSensorsPhases
                    })) : this.state.group.subgroups,
                tariffId: this.state.group.tariffId,
                labelIds: this.state.group.tagId
                    ? [getTag(tags, this.state.group.tagId)]
                    : null
            }

            return API.GROUPS.ADD(group).then((response) => {
                //TODO review feedbackMessage, which is not being used (may be used with Snackbar)
                this.setState({ feedbackMessage: i18n.t('Group added successfully.'), loading: false });
                window.sessionStorage.setItem(`unsavedGroup_${this.props.editGroupId ? this.props.editGroupId : 0}`, null)
                this.props.handleCloseModal(true);
            }).catch((error) => {
                this.handleError(i18n.t("An error occurred while adding the group."));
                throw error;
            });
        }

        // sensors that were selected for this group but are not selected anymore
        // are explicitly set to be removed on the request objects ('removeSensors' properties)
        const currentKeyList = this.getFlatKeyList(this.state.group);

        //const groupRemoveMains = (originalGroupMains && originalGroupMains.id && currentSensorsList.indexOf(originalGroupMains.id) === -1) ? [originalGroupMains.id] : [];
        let groupMainsToRemove = this.state.group.originalGroupMains.filter(sensorPhase => currentKeyList.indexOf(sensorPhase.key) === -1);
        groupMainsToRemove = groupMainsToRemove.map(sensorPhase => ({
            id: sensorPhase.id,
            channel: sensorPhase.channel
        }));

        let groupMainSensorsPhases = this.state.group.groupMainSensorsPhases.filter(s => s.key !== '');//removes undefined elements
        // get only the properties that the back-end needs
        groupMainSensorsPhases = groupMainSensorsPhases.map(s => ({
            id: s.id,
            channel: s.channel,
            name: s.name,
            labelIds: s.tagId ? [getTag(tags, s.tagId)] : null,
            tariffId: s.tariffId,
        }));

        const subgroups = this.state.group.subgroups.map(sub => {
            sub.removeSensors = [];

            // a subgroup may not have an "original" main sensor-phase when it has just been created on editing the whole group,
            // or simply didn't have a original main sensor-phase
            if (sub.originalMains && sub.originalMains.length > 0)
                sub.removeSensors = sub.originalMains.filter(sensorPhase => currentKeyList.indexOf(sensorPhase.key) === -1);

            // a subgroup may not have an "original" subload sensor-phase (same case above, a new subgroup on editing the group)
            // or simply didn't have a original main sensor-phase
            if (sub.originalSubLoads && sub.originalSubLoads.length > 0) {
                sub.removeSensors = sub.removeSensors.concat(sub.originalSubLoads.filter(sensorPhase => currentKeyList.indexOf(sensorPhase.key) === -1));

                sub.removeSensors = sub.removeSensors.map(s => ({
                    id: s.id,
                    channel: s.channel,
                }));
            }

            //removes undefined elements
            sub.mainSensorsPhases = (sub.mainSensorsPhases) ? sub.mainSensorsPhases.filter(s => s.key !== '') : [];
            sub.subLoadSensorsPhases = (sub.subLoadSensorsPhases) ? sub.subLoadSensorsPhases.filter(s => s.key !== '') : [];

            // get only the properties that the back-end needs
            sub.mainSensorsPhases = sub.mainSensorsPhases.map(s => ({
                id: s.id,
                channel: s.channel,
                name: s.name,
                key: s.key
            }));
            sub.subLoadSensorsPhases = sub.subLoadSensorsPhases.map(s => ({
                id: s.id,
                channel: s.channel,
                name: s.name,
                labelIds: s.tags && s.tags.length ? [getTag(tags, s.tags[0])] : null,
                tags:  s.tags,
                tariffId: s.tariffId,
                key: s.key
            }));

            sub.labelIds = sub.tagsIds && sub.tagsIds.length ? [getTag(tags, sub.tagsIds[0])] : null

            return sub;
        });

        // check for subgroups deleted
        const currentSubgroupsIds = this.state.group.subgroups.map(s => s.id).filter(s => s);//only subgroups with id (already added before editing)
        let subgroupsToRemove = this.state.group.originalSubgroupsIds.filter(subgroupId => currentSubgroupsIds.indexOf(subgroupId) === -1);

        let subgroupsError = false;
        subgroupsToRemove.forEach(subgroupToRemove => {
                API.GROUP.DELETE(subgroupToRemove).catch((error) => {
                    subgroupsError = true;
                });
        });

        if (!subgroupsError) {
            const group = {
                id: this.state.group.id,
                name: this.state.group.name,
                sensors:groupMainSensorsPhases,
                removeSensors: groupMainsToRemove,
                subGroups: subgroups,
                tariffId: this.state.group.tariffId,
                labelIds: this.state.group.tagId
                    ? [getTag(tags, this.state.group.tagId)]
                    : null
            }

            API.GROUPS.EDIT(group).then((response) => {
                //TODO review feedbackMessage, which is not being used (may be used with Snackbar)
                this.setState({ feedbackMessage: i18n.t('Group edited successfully.'), loading: false });
                this.props.handleCloseModal(true);
            }).catch((error) => {
                this.handleError(i18n.t("An error occurred while editing the group."));
                throw error;
            });
        }else{
            console.error("An error occurred while editing the group (subgroup deletion).")
        }
    }

    saveSubgroup = (group) => {
        window.sessionStorage.setItem(`unsavedGroup_${this.props.editGroupId ? this.props.editGroupId : 0}`, JSON.stringify(group))
        window.sessionStorage.setItem(`activeSubgroupStep_${this.props.editGroupId ? this.props.editGroupId : 0}`, null)

        document.getElementById('step_container').scrollTop = 0;
        this.setState({
            checkForm: false,
            group: group,
            activeSubgroupStep: null,
        });
    };

    handleDataLossModalDialogOpen = (action) => {
        this.setState({
            dataLossModalDialogOpen: true,
            modalAction: action,
        });
    }

    handleDataLossModalDialogClose = (flag) => {
        this.setState({
            dataLossModalDialogOpen: false
        });

        if (flag === true) {
            const action = this.state.modalAction;
            this[action.fn](action.param);
        }
    }
    openSnackBar = (message, type) => this.setState({
        showSnackBar: true,
        snackbar: {
            message,
            type
        }
    })

    closeSnackBar = () => this.setState({
        showSnackBar: false
    })

    deleteSubgroup = i => {
        const { group: { subgroups, ...group }} = this.state;
        const newGroup = {
            ...group,
            subgroups: subgroups.filter((group, index) => index !== i)
        };

        const activeSubgroupStep = subgroups.length === 1 || i === null
            ? null
            : i === 0
                ? 0
                : i - 1;

        this.setState({
            activeSubgroupStep,
            group: newGroup
        });

        window.sessionStorage.setItem(`unsavedGroup_${this.props.editGroupId ? this.props.editGroupId : 0}`, JSON.stringify(newGroup))
        window.sessionStorage.setItem(`activeSubgroupStep_${this.props.editGroupId ? this.props.editGroupId : 0}`, activeSubgroupStep)
        document.getElementById('step_container').scrollTop = 0;
    }

    handleError = message => {
        this.setState({
            errorMessage: message,
            loading: false
        });
        this.showErrorMessage();
    }

    showErrorMessage(){
        this.openSnackBar(this.state.errorMessage,'error');
    }

    render() {
        const { classes } = this.props;
        const { activeStep, activeSubgroupStep, showSnackBar, snackbar } = this.state;
        const { subgroups } = this.state.group;

        return (
            <div id="add-group" className={classes.root}>
                <div id="stepper" className={classes.stepperContainer}>
                    <Stepper activeStep={activeStep} orientation="vertical" className={classes.stepperRoot}>
                        {this.steps.map((step, index) => {
                            return (
                                <Step key={step.label}>
                                    <StepLabel
                                        className={index === activeStep ? 'mui-active-step' : index < activeStep ? 'mui-done-step' : 'mui-non-active-step'}
                                        onClick={() => {
                                            if (this.state.activeStep === 2){
                                                return this.goToGroup(index);
                                            }else if (index < this.state.activeStep){
                                                return this.handleDataLossModalDialogOpen({fn: 'goToGroup', param: index})
                                            }else{
                                                return null;
                                            }
                                        }}
                                    >
                                        {step.label}
                                    </StepLabel>
                                    <StepContent TransitionProps={{in: true}}>
                                        {step.value === 'subgroups' && subgroups && subgroups.length ?
                                            <ul>
                                                {subgroups.map((subgroup, i) => (
                                                    <li key={i} className={classes.stepSubgroupListItem}>
                                                        <Check className={classes.stepSubgroupCheckIcon} />
                                                        <div onClick={() => {
                                                                if (this.state.activeStep === 2){
                                                                    return this.goToSubgroup(i);
                                                                }else{
                                                                    return this.handleDataLossModalDialogOpen({fn: 'goToSubgroup', param: i})
                                                                }
                                                            }}
                                                            className={activeStep === 1 && i === activeSubgroupStep ? 'active-subgroup-step' : null}
                                                        >
                                                            {subgroup.name}
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        : null}
                                    </StepContent>
                                </Step>
                            );
                        })}
                    </Stepper>
                </div>
                <div id="form" className={classes.formContainer}>
                    <div className={classes.form} id="step_container">
                        {activeStep === 0 && (
                            <GroupForm
                                addTag={this.addTag}
                                availableSensors={this.state.availableSensors}
                                checkForm={this.state.checkForm}
                                goToNext={this.goToNext}
                                group={this.state.group}
                                loadingSensors={this.state.loadingSensors}
                                loadingTags={this.state.loadingTags}
                                loadingTariffs={this.state.loadingTariffs}
                                openSnackBar={this.openSnackBar}
                                tags={this.state.tags.map(tag =>
                                    !subgroups || !subgroups.length || !subgroups.some(subgroup =>
                                        (subgroup.tagsIds && subgroup.tagsIds[0] === tag.value) ||
                                        (subgroup.subLoadSensorsPhases && subgroup.subLoadSensorsPhases.length && subgroup.subLoadSensorsPhases.some(sub =>
                                            sub.tags && sub.tags.length &&sub.tags[0] === tag.value
                                        ))
                                    ) ? tag : {...tag, disabled: true}
                                )}
                                tariffs={this.state.tariffs}
                            />
                        )}
                        {activeStep === 1 && (
                            <SubgroupForm
                                addTag={this.addTag}
                                saveSubgroup={this.saveSubgroup}
                                availableSensors={this.selectedAvailableDiff(this.state.group)}
                                checkForm={this.state.checkForm}
                                goToNext={this.goToNext}
                                group={this.state.group}
                                openSnackBar={this.openSnackBar}
                                tags={this.state.tags.map(tag => tag.value !== this.state.group.tagId ? tag : {...tag, disabled: true})}
                                tariffs={this.state.tariffs}
                                subgroup={this.state.activeSubgroupStep !== null ? this.state.group.subgroups[this.state.activeSubgroupStep] : null}
                                subgroupToEdit={this.state.activeSubgroupStep}
                                deleteSubgroup={this.deleteSubgroup}
                            />)
                        }
                        {activeStep === 2 && (
                            <GroupReview
                                group={this.state.group}
                                tags={this.state.tags}
                                tariffs={this.state.tariffs}
                            />
                        )}
                    </div>
                    <div id="step-buttons" className={classes.stepButtons}>

                        {activeStep > 0 &&
                            <VButton
                                action="back"
                                // disabled={loadingPreview}
                                label={i18n.t('Back')}
                                click={this.handleBackClick}
                            />
                        }

                        {activeStep === 1 &&
                            <VButton
                                type="submit"
                                action="submit"
                                click={this.handleSkipClick}
                                // disabled={submitting}
                                label={i18n.t('Skip')}
                            />
                        }

                        <VButton
                            type="submit"
                            className="next"
                            action="submit"
                            click={this.handleNextClick}
                            // disabled={submitting}
                            label={activeStep === this.steps.length - 1 ? i18n.t('Save and create group') : i18n.t('Next')}
                        />
                    </div>
                </div>
                <SystemSnackbar
                    open={showSnackBar}
                    message={snackbar.message}
                    onClose={this.closeSnackBar}
                    variant={snackbar.type}
                />
                <div className="leave-section-wrapper">
                    <Dialog aria-labelledby="dialog-text"
                        fullWidth={true}
                        maxWidth="xs"
                        open={this.state.dataLossModalDialogOpen}
                        onClose={this.handleDataLossModalDialogClose}>
                        <DialogTitle>
                            {i18n.t('Leave this section?')}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="dialog-text">
                                {i18n.t('If you leave this section, inputs you may have entered will not be saved.')}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button id="confirm-leave-dialog" classes={{label: classes.actionConfirm}}
                                onClick={() => this.handleDataLossModalDialogClose(true)}>
                                {i18n.t('Yes, leave')}
                            </Button>
                            <Button id="cancel-leave-dialog" classes={{label: classes.actionCancel}} onClick={this.handleDataLossModalDialogClose}>
                                {i18n.t('No, stay here')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        );
    }
}

GroupAddStepper.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(styles)(GroupAddStepper);
