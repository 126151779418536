import axios from 'axios';
import Cookies from 'universal-cookie';
import LogRocket from 'logrocket';
const cookies = new Cookies();


const config = () => {
    return process.env.NODE_ENV === 'development' ?
        axios.defaults.baseURL = process.env.REACT_APP_HOST :
        axios.get('/config').then(response => {
            axios.defaults.baseURL = response.data.REACT_APP_HOST
            LogRocket.init(response.data.REACT_APP_HOST.includes('test')
                ? '3259wf/dev-server'
                : 'tpmqj3/prod-server'
            );
        });
}


axios.interceptors.request.use(function (config) {
    config.headers['X-Requested-With'] = 'XMLHttpRequest';
    return config;
}, function (error) {
    return Promise.reject(error);
});


axios.interceptors.response.use(function (response) {
    let token = cookies.get('x-auth-token');
    if (token) cookies.set('x-auth-token', token, { path: '/', maxAge: 30*60 });
    return response;
}, function (error) {
    if (
        window.location.pathname !== '/login' &&
        (!error.response || !error.response.status || error.response.status === 401) &&
        !axios.isCancel(error)
    ) window.location.href = '/logout';
    return Promise.reject(error);
});


const URLS = {
    AUTH: {
        LOGIN: '/login',
        LOGOUT: '/logout',
    },
    USERS: {
        RESET_PASSWORD: '/users/reset_password',
        LOCALE: 'users/locale',
        LAST_URL: {
            GET: '/users/last_url',
            PUT: '/users/last_url',
        },
        ADD: '/users',
        EDIT: '/users',
        GET: '/users',
        DELETE: '/users',
    },
    VISUALIZATIONS: {
        LIVE: '/visualizations/live',
        MAP: '/visualizations/map',
        INFO:{
            GENERAL: '/visualizations/general_information'
        },
        CONSUMPTION: {
            LINE: {
                DAY: {
                    GROUP: '/visualizations/line/group/:id/day',
                    SENSOR: '/visualizations/line/group/:groupId/sensor/:sensorId/:channel/day',
                    LABEL: '/visualizations/line/label/:id/day',
                },
                WEEK: {
                    GROUP: '/visualizations/line/group/:id/week',
                    SENSOR: '/visualizations/line/group/:groupId/sensor/:sensorId/:channel/week',
                    LABEL: '/visualizations/line/label/:id/week',
                },
                MONTH: {
                    GROUP: '/visualizations/line/group/:id/month',
                    SENSOR: '/visualizations/line/group/:groupId/sensor/:sensorId/:channel/month',
                    LABEL: '/visualizations/line/label/:id/month',
                },
                YEAR: {
                    GROUP: '/visualizations/line/group/:id/year',
                    SENSOR: '/visualizations/line/group/:groupId/sensor/:sensorId/:channel/year',
                    LABEL: '/visualizations/line/label/:id/year',
                },
                CUSTOM: {
                    GROUP: '/visualizations/line/group/:id/custom',
                    SENSOR: '/visualizations/line/group/:groupId/sensor/:sensorId/:channel/custom',
                    LABEL: '/visualizations/line/label/:id/custom',
                },
            },
            STREAM: {
                DAY: {
                    GROUP: '/visualizations/stream/group/:id/day',
                    LABEL: '/visualizations/stream/label/:id/day',
                },
                WEEK: {
                    GROUP: '/visualizations/stream/group/:id/week',
                    LABEL: '/visualizations/stream/label/:id/week',
                },
                MONTH: {
                    GROUP: '/visualizations/stream/group/:id/month',
                    LABEL: '/visualizations/stream/label/:id/month',
                },
                YEAR: {
                    GROUP: '/visualizations/stream/group/:id/year',
                    LABEL: '/visualizations/stream/label/:id/year',
                },
                CUSTOM: {
                    GROUP: '/visualizations/stream/group/:id/custom',
                    LABEL: '/visualizations/stream/label/:id/custom',
                },
            },
            HEAT: {
                DAY: {
                    GROUP: '/visualizations/heat/group/:id/level/:level/day',
                    SENSOR: '/visualizations/heat/group/:groupId/sensor/:sensorId/:channel/level/:level/day',
                    LABEL: '/visualizations/heat/label/:id/level/:level/day',
                },
                WEEK: {
                    GROUP: '/visualizations/heat/group/:id/level/:level/week',
                    SENSOR: '/visualizations/heat/group/:groupId/sensor/:sensorId/:channel/level/:level/week',
                    LABEL: '/visualizations/heat/label/:id/level/:level/week',
                },
                MONTH: {
                    GROUP: '/visualizations/heat/group/:id/level/:level/month',
                    SENSOR: '/visualizations/heat/group/:groupId/sensor/:sensorId/:channel/level/:level/month',
                    LABEL: '/visualizations/heat/label/:id/level/:level/month',
                },
                CUSTOM: {
                    GROUP: '/visualizations/heat/group/:id/level/:level/custom',
                    SENSOR: '/visualizations/heat/group/:groupId/sensor/:sensorId/:channel/level/:level/custom',
                    LABEL: '/visualizations/heat/label/:id/level/:level/custom',
                },
            },
        },
        COST: {
            REAL: {
                DAY: {
                    GROUP: 'visualizations/bar/group/:id/day',
                    LABEL: 'visualizations/bar/label/:id/day',
                    SENSOR: 'visualizations/bar/group/:groupId/sensor/:sensorId/:channel/day'
                },
                WEEK: {
                    GROUP: 'visualizations/bar/group/:id/week',
                    LABEL: 'visualizations/bar/label/:id/week',
                    SENSOR: 'visualizations/bar/group/:groupId/sensor/:sensorId/:channel/week'
                },
                MONTH: {
                    GROUP: 'visualizations/bar/group/:id/month',
                    LABEL: 'visualizations/bar/label/:id/month',
                    SENSOR: 'visualizations/bar/group/:groupId/sensor/:sensorId/:channel/month'
                },
                YEAR: {
                    GROUP: 'visualizations/bar/group/:id/year',
                    LABEL: 'visualizations/bar/label/:id/year',
                    SENSOR: 'visualizations/bar/group/:groupId/sensor/:sensorId/:channel/year'
                },
                CUSTOM: {
                    GROUP: '/visualizations/bar/group/:id/custom',
                    LABEL: '/visualizations/bar/label/:id/custom',
                    SENSOR: '/visualizations/bar/group/:groupId/sensor/:sensorId/:channel/custom'
                },
            },
            SIMULATION: {
                CUSTOM: {
                    GROUP: '/visualizations/line/group/:id/tariff/:tariffId',
                    SENSOR: '/visualizations/line/group/:groupId/sensor/:sensorId/:channel/tariff/:tariffId'
                }
            },
        },
        POWER: {
            LINE: {
                SENSOR: '/visualizations/line/sensor/:id/:channel/power',
            },
            PEAK: {
                SENSOR: '/visualizations/sensor/:id/:channel/power/peak',
                MULTIPHASE_SENSOR: '/visualizations/sensor/:id/power/peak',
            }
        }
    },
    GROUPS: {
        GET: '/groups',
        ADD: '/groups',
        EDIT: '/groups',
    },
    GROUP: {
        GET: '/groups/:id',
        DELETE: '/groups',
    },
    SENSORS: {
        GET: '/sensors',
        DETAILS: {
            SET: '/sensors/localisation'
        },
        WITHOUT_GROUP: {
            GET: '/sensors/without_group',
        },
        STATUS: {
            GET: '/sensors/status',
        },
    },
    TARIFFS: {
        GET:'/tariffs',
        ADD: '/tariffs',
        EDIT: '/tariffs',
        DELETE: '/tariffs',
        COMPOSITION: {
            ADD: '/tariffs/compositions',
            EDIT: '/tariffs/compositions',
            DELETE: '/tariffs/compositions',
        },
        CURRENCY: {
            GROUP: '/tariffs/group/:id/currency',
            SENSOR: '/tariffs/sensor/:id/:channel/currency',
        }
    },
    COUNTRIES: {
        GET:'/countries',
    },
    LOCALES: {
        GET: '/locales'
    },
    TIMEZONES: {
        GET: '/time_zones'
    },
    REPORTS: {
        GET: '/reports',
        ADD: '/reports',
        EDIT: '/reports',
        DELETE: '/reports',
        PREVIEW: '/reports/preview/:id',
        LIVE_PREVIEW: '/reports/preview',
    },
    ALERTS: {
        GET: '/alerts',
        ADD: '/alerts',
        EDIT: '/alerts',
        DELETE: '/alerts',
        EVENTS: {
            GET: '/alerts/events',
            DETAILS: '/alerts/events/:id',
            UNREAD: '/alerts/events/unread',
            EDIT: 'alerts/events',
            DELETE: '/alerts/events',
            CONSUMPTION: {
                GROUP: '/alerts/group/:id/events/consumption',
                SENSOR: '/alerts/sensor/:id/:channel/events/consumption',
            },
            COST: {
                GROUP: '/alerts/group/:id/events/cost',
                SENSOR: '/alerts/sensor/:id/:channel/events/cost',
            },
            SYSTEM: {
                DETAILS: '/alerts/events/system/:id',
                UNREAD: '/alerts/events/system/unread'
            }
        }
    },
    LABELS: {
        GET:'/labels',
        ADD: '/labels',
        EDIT: '/labels',
        DELETE: '/labels'
    },
    HOME: {
        ALERTS: {
            EVENTS: '/home/alerts/events',
            UNREAD: {
                RELATIVE_CONSUMPTION: '/home/alerts/events/relative/consumption/unread',
                ABSOLUTE_CONSUMPTION: '/home/alerts/events/absolute/consumption/unread',
                ABSOLUTE_COST: '/home/alerts/events/absolute/cost/unread',
                ABSOLUTE_ACTIVE_POWER: '/home/alerts/events/absolute/power/unread',
                ENTITY_STATUS: '/home/alerts/events/entity/status/unread'
            }
        },
        CONSUMPTION: {
            GROUPS: {
                DAY: '/home/consumption/groups/day',
                WEEK: '/home/consumption/groups/week',
                MONTH: '/home/consumption/groups/month',
            },
            SENSORS: {
                DAY: '/home/consumption/sensors/day',
                WEEK: '/home/consumption/sensors/week',
                MONTH: '/home/consumption/sensors/month',
            },
        },
        COST: {
            GROUPS: {
                DAY: '/home/cost/groups/day',
                WEEK: '/home/cost/groups/week',
                MONTH: '/home/cost/groups/month',
            },
            SENSORS: {
                DAY: '/home/cost/sensors/day',
                WEEK: '/home/cost/sensors/week',
                MONTH: '/home/cost/sensors/month',
            },
        }
    },
    ROOT: {
        TENANTS: {
            GET:'/tenants',
            ADD: '/tenants',
            EDIT: '/tenants',
            LOGIN: '/tenants/login',
            CONFIGURATION: 'tenants/configuration',
            USERS: {
                GET: '/tenants/:tenantId/users',
                ADD: '/tenants/users',
                DELETE: '/tenants/users',
                ENABLE_ALL: '/tenants/users/enable',
                DISABLE_ALL: '/tenants/users/disable',
            },
            SENSORS: {
                DELETE: '/tenants/sensors',
                EDIT: '/tenants/sensors'
            },
        },
    }
};


// Auth
const login = (user, pass) => {
    let token = btoa(`${user}:${pass}`);
    return axios({
        method: 'post',
        url: URLS.AUTH.LOGIN,
        headers: {
            'Authorization': 'Basic ' + token,
        },
    });
}

const logout = () => {
    return axios.delete(URLS.AUTH.LOGOUT);
}

const resetPassword = (username) => axios.put(URLS.USERS.RESET_PASSWORD, {
    email: username,
});

const updateLocale = (locale) => axios.put(URLS.USERS.LOCALE, {
    'localeCode': locale,
});

const lastUrlGet = () => axios.get(URLS.USERS.LAST_URL.GET);

const lastUrlPut = (url) => axios.put(URLS.USERS.LAST_URL.PUT, {url: url});

const usersGet = () => axios.get(URLS.USERS.GET);

const usersAdd = (user) => {
    return axios.post(URLS.USERS.ADD, {
        email: user.email,
        authority: user.authority,
        localeCode: user.localeCode,
    });
}

const usersEdit = (user) => {
    return axios.put(URLS.USERS.EDIT, {
        email: user.email,
        enabled: user.enabled === 'true' ? true : false,
        authority: user.authority,
        localeCode: user.localeCode,
    });
}

const usersDelete = (email) => {
    return axios.delete(URLS.USERS.DELETE, {data: {email: email}});
}

const liveViz = () => {
    return axios.get(URLS.VISUALIZATIONS.LIVE);
}
const lineDayVizGroup = (id, date, dataGranularity) => {
    return axios.get(URLS.VISUALIZATIONS.CONSUMPTION.LINE.DAY.GROUP.replace(':id',id), {params: {
        date,
        dataGranularity,
    }});
}
const lineDayVizLabel = (id, date, dataGranularity) => {
    return axios.get(URLS.VISUALIZATIONS.CONSUMPTION.LINE.DAY.LABEL.replace(':id',id), {params: {
        date,
        dataGranularity,
    }});
}
const lineDayVizSensor = (groupId, sensorId, channel, date, dataGranularity) => {
    return axios.get(URLS.VISUALIZATIONS.CONSUMPTION.LINE.DAY.SENSOR
        .replace(':groupId', groupId)
        .replace(':sensorId', sensorId)
        .replace(':channel', channel),
        {params: { date, dataGranularity }});
}
const lineWeekVizGroup = (id, date) => {
    return axios.get(URLS.VISUALIZATIONS.CONSUMPTION.LINE.WEEK.GROUP.replace(':id',id), {params: {date: date}});
}
const lineWeekVizLabel = (id, date) => {
    return axios.get(URLS.VISUALIZATIONS.CONSUMPTION.LINE.WEEK.LABEL.replace(':id',id), {params: {date: date}});
}
const lineWeekVizSensor = (groupId, sensorId, channel, date) => {
    return axios.get(URLS.VISUALIZATIONS.CONSUMPTION.LINE.WEEK.SENSOR
        .replace(':groupId', groupId)
        .replace(':sensorId', sensorId)
        .replace(':channel', channel),
        {params: {date: date}});
}
const lineMonthVizGroup = (id, month) => {
    return axios.get(URLS.VISUALIZATIONS.CONSUMPTION.LINE.MONTH.GROUP.replace(':id',id), {params: {month: month}});
}
const lineMonthVizLabel = (id, month) => {
    return axios.get(URLS.VISUALIZATIONS.CONSUMPTION.LINE.MONTH.LABEL.replace(':id',id), {params: {month: month}});
}
const lineMonthVizSensor = (groupId, sensorId, channel, month) => {
    return axios.get(URLS.VISUALIZATIONS.CONSUMPTION.LINE.MONTH.SENSOR
        .replace(':groupId', groupId)
        .replace(':sensorId', sensorId)
        .replace(':channel', channel),
        {params: {month: month}});
}

const lineYearVizGroup = (id, year) => {
    return axios.get(URLS.VISUALIZATIONS.CONSUMPTION.LINE.YEAR.GROUP.replace(':id',id), {params: {year: year}});
}

const lineYearVizLabel = (id, year) => {
    return axios.get(URLS.VISUALIZATIONS.CONSUMPTION.LINE.YEAR.LABEL.replace(':id',id), {params: {year: year}});
}

const lineYearVizSensor = (groupId, sensorId, channel, year) => {
    return axios.get(URLS.VISUALIZATIONS.CONSUMPTION.LINE.YEAR.SENSOR
        .replace(':groupId', groupId)
        .replace(':sensorId', sensorId)
        .replace(':channel', channel),
        {params: {year: year}});
}

const lineCustomVizGroup = (id, startDate, endDate) => {
    return axios.get(URLS.VISUALIZATIONS.CONSUMPTION.LINE.CUSTOM.GROUP.replace(':id',id), {params: {startDate: startDate, endDate: endDate}});
}

const lineCustomVizLabel = (id, startDate, endDate) => {
    return axios.get(URLS.VISUALIZATIONS.CONSUMPTION.LINE.CUSTOM.LABEL.replace(':id',id), {params: {startDate: startDate, endDate: endDate}});
}

const lineCustomVizSensor = (groupId, sensorId, channel, startDate, endDate) => {
    return axios.get(URLS.VISUALIZATIONS.CONSUMPTION.LINE.CUSTOM.SENSOR
        .replace(':groupId', groupId)
        .replace(':sensorId', sensorId)
        .replace(':channel', channel),
        {params: {startDate: startDate, endDate: endDate}})
}

const streamDayVizGroup = (id, date, dataGranularity) => {
    return axios.get(URLS.VISUALIZATIONS.CONSUMPTION.STREAM.DAY.GROUP.replace(':id', id), {params: {date, dataGranularity}})
}

const streamWeekVizGroup = (id, date) => {
    return axios.get(URLS.VISUALIZATIONS.CONSUMPTION.STREAM.WEEK.GROUP.replace(':id', id), {params: {date: date}})
}

const streamMonthVizGroup = (id, date) => {
    return axios.get(URLS.VISUALIZATIONS.CONSUMPTION.STREAM.MONTH.GROUP.replace(':id', id), {params: {month: date}})
}

const streamYearVizGroup = (id, date) => {
    return axios.get(URLS.VISUALIZATIONS.CONSUMPTION.STREAM.YEAR.GROUP.replace(':id', id), {params: {year: date}})
}

const streamCustomVizGroup = (id, startDate, endDate) => {
    return axios.get(URLS.VISUALIZATIONS.CONSUMPTION.STREAM.CUSTOM.GROUP.replace(':id', id), {params: {startDate: startDate, endDate: endDate}})
}

const streamDayVizLabel = (id, date, dataGranularity) => {
    return axios.get(URLS.VISUALIZATIONS.CONSUMPTION.STREAM.DAY.LABEL.replace(':id', id), {params: {date, dataGranularity}})
}

const streamWeekVizLabel = (id, date) => {
    return axios.get(URLS.VISUALIZATIONS.CONSUMPTION.STREAM.WEEK.LABEL.replace(':id', id), {params: {date: date}})
}

const streamMonthVizLabel = (id, date) => {
    return axios.get(URLS.VISUALIZATIONS.CONSUMPTION.STREAM.MONTH.LABEL.replace(':id', id), {params: {month: date}})
}

const streamYearVizLabel = (id, date) => {
    return axios.get(URLS.VISUALIZATIONS.CONSUMPTION.STREAM.YEAR.LABEL.replace(':id', id), {params: {year: date}})
}

const streamCustomVizLabel = (id, startDate, endDate) => {
    return axios.get(URLS.VISUALIZATIONS.CONSUMPTION.STREAM.CUSTOM.LABEL.replace(':id', id), {params: {startDate: startDate, endDate: endDate}})
}

const heatDayVizGroup = (id, date, level) => {
    return axios.get(URLS.VISUALIZATIONS.CONSUMPTION.HEAT.DAY.GROUP.replace(':id',id).replace(':level',level), {params: {date: date}});
}

const heatDayVizSensor = (groupId, sensorId, channel, date, level) => {
    return axios.get(URLS.VISUALIZATIONS.CONSUMPTION.HEAT.DAY.SENSOR
        .replace(':groupId', groupId)
        .replace(':sensorId', sensorId)
        .replace(':channel', channel)
        .replace(':level',level),
        {params: {date: date}});
}

const heatDayVizLabel = (id, date, level) => {
    return axios.get(URLS.VISUALIZATIONS.CONSUMPTION.HEAT.DAY.LABEL.replace(':id',id).replace(':level',level), {params: {date: date}});
}

const costDayVizGroup = (id, date, calculationLevel) => {
    return axios.get(URLS.VISUALIZATIONS.COST.REAL.DAY.GROUP.replace(':id', id), {params: {date: date, calculationLevel: calculationLevel}})
}

const costDayVizLabel = (id, date, calculationLevel) => {
    return axios.get(URLS.VISUALIZATIONS.COST.REAL.DAY.LABEL.replace(':id', id), {params: {date: date, calculationLevel: calculationLevel}})
}

const costDayVizSensor = (groupId, sensorId, channel, date, calculationLevel) => {
    return axios.get(URLS.VISUALIZATIONS.COST.REAL.DAY.SENSOR
        .replace(':groupId', groupId)
        .replace(':sensorId', sensorId)
        .replace(':channel', channel),
        {params: {date: date, calculationLevel: calculationLevel}})
}

const costWeekVizGroup = (id, date, calculationLevel) => {
    return axios.get(URLS.VISUALIZATIONS.COST.REAL.WEEK.GROUP.replace(':id', id), {params: {date: date, calculationLevel: calculationLevel}})
}

const costWeekVizLabel = (id, date, calculationLevel) => {
    return axios.get(URLS.VISUALIZATIONS.COST.REAL.WEEK.LABEL.replace(':id', id), {params: {date: date, calculationLevel: calculationLevel}})
}

const costWeekVizSensor = (groupId, sensorId, channel, date, calculationLevel) => {
    return axios.get(URLS.VISUALIZATIONS.COST.REAL.WEEK.SENSOR
        .replace(':groupId', groupId)
        .replace(':sensorId', sensorId)
        .replace(':channel', channel),
        {params: {date: date, calculationLevel: calculationLevel}})
}

const costMonthVizGroup = (id, date, calculationLevel) => {
    return axios.get(URLS.VISUALIZATIONS.COST.REAL.MONTH.GROUP.replace(':id', id), {params: {month: date, calculationLevel: calculationLevel}})
}

const costMonthVizLabel = (id, date, calculationLevel) => {
    return axios.get(URLS.VISUALIZATIONS.COST.REAL.MONTH.LABEL.replace(':id', id), {params: {month: date, calculationLevel: calculationLevel}})
}

const costMonthVizSensor = (groupId, sensorId, channel, date,  calculationLevel) => {
    return axios.get(URLS.VISUALIZATIONS.COST.REAL.MONTH.SENSOR
        .replace(':groupId', groupId)
        .replace(':sensorId', sensorId)
        .replace(':channel', channel),
        {params: {month: date, calculationLevel: calculationLevel}})
}

const costYearVizGroup = (id, date, calculationLevel) => {
    return axios.get(URLS.VISUALIZATIONS.COST.REAL.YEAR.GROUP.replace(':id', id), {params: {year: date, calculationLevel: calculationLevel}})
}

const costYearVizLabel = (id, date, calculationLevel) => {
    return axios.get(URLS.VISUALIZATIONS.COST.REAL.YEAR.LABEL.replace(':id', id), {params: {year: date, calculationLevel: calculationLevel}})
}

const costYearVizSensor = (groupId, sensorId, channel, date,  calculationLevel) => {
    return axios.get(URLS.VISUALIZATIONS.COST.REAL.YEAR.SENSOR
        .replace(':groupId', groupId)
        .replace(':sensorId', sensorId)
        .replace(':channel', channel),
        {params: {year: date, calculationLevel: calculationLevel}})
}

const costCustomVizGroup = (id, startDate, endDate, calculationLevel) => {
    return axios.get(URLS.VISUALIZATIONS.COST.REAL.CUSTOM.GROUP.replace(':id', id), {params: {startDate: startDate, endDate: endDate, calculationLevel: calculationLevel}})
}

const costCustomVizLabel = (id, startDate, endDate, calculationLevel) => {
    return axios.get(URLS.VISUALIZATIONS.COST.REAL.CUSTOM.LABEL.replace(':id', id), {params: {startDate: startDate, endDate: endDate, calculationLevel: calculationLevel}})
}

const costCustomVizSensor = (groupId, sensorId, channel, startDate, endDate, calculationLevel) => {
    return axios.get(URLS.VISUALIZATIONS.COST.REAL.CUSTOM.SENSOR
        .replace(':groupId', groupId)
        .replace(':sensorId', sensorId)
        .replace(':channel', channel),
        {params: {startDate: startDate, endDate: endDate, calculationLevel: calculationLevel}})
}

const costSimulationCustomVizGroup = (id, startDate, endDate, tariffId, dataGranularity) => {
    return axios.get(URLS.VISUALIZATIONS.COST.SIMULATION.CUSTOM.GROUP
        .replace(':id', id)
        .replace(':tariffId', tariffId),
        {params: {startDate: startDate, endDate: endDate, dataGranularity: dataGranularity}})
}

const costSimulationCustomVizSensor = (groupId, sensorId, channel, startDate, endDate, tariffId, dataGranularity) => {
    return axios.get(URLS.VISUALIZATIONS.COST.SIMULATION.CUSTOM.SENSOR
        .replace(':groupId', groupId)
        .replace(':sensorId', sensorId)
        .replace(':channel', channel)
        .replace(':tariffId', tariffId),
        {params: {startDate: startDate, endDate: endDate, dataGranularity: dataGranularity}})
}

const getVizMap = () => {
    return axios.get(URLS.VISUALIZATIONS.MAP)
}

const powerVizSensor = (sensorId, channel, startDate, endDate) => {
    return axios.get(URLS.VISUALIZATIONS.POWER.LINE.SENSOR
        .replace(':id', sensorId)
        .replace(':channel', channel),
        { params: {startDate: startDate, endDate: endDate} })
}

const powerPeakVizSensor = (sensorId, channel, startDate, endDate) => {
    let url = '';
    if (channel === null){
        url = URLS.VISUALIZATIONS.POWER.PEAK.MULTIPHASE_SENSOR.replace(':id', sensorId)
    }else{
        url = URLS.VISUALIZATIONS.POWER.PEAK.SENSOR.replace(':id', sensorId).replace(':channel', channel);
    }
    return axios.get(url, { params: {startDate: startDate, endDate: endDate} })
}

const groupsGet = () => {
    return axios.get(URLS.GROUPS.GET);
}

const groupGet = (id) => {
    return axios.get(URLS.GROUP.GET.replace(':id',id));
}

const groupsAdd = (group) => {

    group.groupMainSensorsPhases = group.groupMainSensorsPhases
        .filter(sensorPhase => sensorPhase.id && sensorPhase.id > 0)//removes empty sensors-phases
        .map(sensorPhase => ({
            id: sensorPhase.id,
            channel: sensorPhase.channel,
            name: sensorPhase.name
        }));

    group.subGroups = group.subGroups.map((subgroup) => {

        return {
            name: subgroup.name,
            labelIds: subgroup.tagsIds,
            tariffId: subgroup.tariffId,
            sensors: subgroup.mainSensorsPhases
                .filter(sensorPhase => sensorPhase)//removes empty sensors-phases
                .map(sensorPhase => ({
                    id: sensorPhase.id,
                    channel: sensorPhase.channel,
                    name: sensorPhase.name
                })),
            subGroups: [{
                name: subgroup.name + " subload sensors",
                sensors: subgroup.subLoadSensorsPhases
                    .filter(sensorPhase => sensorPhase)//removes empty sensors-phases
                    .map(sensorPhase => ({
                        id: sensorPhase.id,
                        channel: sensorPhase.channel,
                        name: sensorPhase.name,
                        labelIds: sensorPhase.tags,
                        tariffId: sensorPhase.tariffId,
                    }))
            }]
        }
    });

    return axios.post(URLS.GROUPS.ADD, {
        name: group.name,
        labelIds: group.labelIds,
        tariffId: group.tariffId,
        sensors: group.groupMainSensorsPhases,
        subGroups: group.subGroups,
    });
}

const groupsEdit = (group) => {
    // group/subgroups data model transform (frontend model => backend model)
    group.subGroups = group.subGroups.map((subgroup) => {
        return {
            name: subgroup.name,
            labelIds: subgroup.labelIds,
            tariffId: subgroup.tariffId,
            sensors: subgroup.mainSensorsPhases,
            id: subgroup.id,
            subGroups: [{
                name: subgroup.name + " subload sensors",
                id: subgroup.subLoadsInternalSubgroupId,
                removeSensors: subgroup.removeSensors,
                sensors: subgroup.subLoadSensorsPhases,
            }]
        }
    });

    return axios.put(URLS.GROUPS.EDIT, group);
}

const groupDelete = (id) => axios.delete(URLS.GROUP.DELETE, {data: {groupId: id}});

const sensorWithoutGroupGet = () => {
    return axios.get(URLS.SENSORS.WITHOUT_GROUP.GET);
}

const sensorStatus = () => {
    return axios.get(URLS.SENSORS.STATUS.GET);
}

const sensorsGet = () => {
    return axios.get(URLS.SENSORS.GET);
}

const tariffsGet = (search) => {
    if (search){
        return axios.get(URLS.TARIFFS.GET, {
            params: {query: search}
        });
    }else{
        return axios.get(URLS.TARIFFS.GET);
    }
}

const countriesGet = () => {
    return axios.get(URLS.COUNTRIES.GET);
}

const localesGet = (countryIsoCode) => {
    return axios.get(URLS.LOCALES.GET, {
        params: {countryIsoCode}
    });
}

const timeZonesGet = (countryIsoCode) => {
    return axios.get(URLS.TIMEZONES.GET, {
        params: {countryIsoCode}
    });
}

const sensorsDetailsSet = (sensorIds, tariffId, countryIsoCode, localeCode, timeZoneId) => {
    return axios.put(URLS.SENSORS.DETAILS.SET, {
        sensorIds: sensorIds,
        tariffId: tariffId,
        countryIsoCode: countryIsoCode,
        localeCode: localeCode,
        timeZoneId: timeZoneId
    });
}

const tariffAdd = (tariff) => {
    return axios.post(URLS.TARIFFS.ADD, {
        name: tariff.name,
        rate: tariff.rate,
        standingCharge: tariff.standingCharge,
        providerName: tariff.providerName,
        isoCountryCode: tariff.isoCountryCode,
    });
}

const tariffEdit = (id, tariff) => {
    return axios.put(URLS.TARIFFS.EDIT, {
        id: id,
        name: tariff.name,
        rate: tariff.rate,
        standingCharge: tariff.standingCharge,
        providerName: tariff.providerName,
        isoCountryCode: tariff.isoCountryCode,
    });
}

const tariffDelete = id => {
    return axios.delete(URLS.TARIFFS.DELETE, {data:{id: id}});
}

const tariffCompositionAdd = async composition => {
    try{
        return await axios.post(URLS.TARIFFS.COMPOSITION.ADD, {
            tariffId: composition.tariffId,
            dayOfWeek: composition.dayOfWeek,
            startTime: composition.startTime,
            endTime: composition.endTime,
            rate: composition.rate,
            limitType: composition.limitType,
            limitValue: composition.limitValue,
            standingCharge: composition.standingCharge
        });
    } catch (error) {
        throw error;
    }
}

const tariffCompositionUpdate = composition => axios.put(URLS.TARIFFS.COMPOSITION.EDIT, {
    id: composition.id,
    tariffId: composition.tariffId,
    dayOfWeek: composition.dayOfWeek,
    startTime: composition.startTime,
    endTime: composition.endTime,
    rate: composition.rate,
    limitType: composition.limitType,
    limitValue: composition.limitValue,
    standingCharge: composition.standingCharge
})

const tariffCompositionDelete = (id) => axios.delete(URLS.TARIFFS.COMPOSITION.DELETE, {data: {id: id}});

const groupCurrencyGet = (id, channel) => {
    return axios.get(URLS.TARIFFS.CURRENCY.GROUP.replace(':id', id));
}

const sensorCurrencyGet = (id, channel) => {
    return axios.get(URLS.TARIFFS.CURRENCY.SENSOR.replace(':id', id).replace(':channel', channel));
}

const generalInfo = (cancelToken) => {
    return axios.get(URLS.VISUALIZATIONS.INFO.GENERAL, {cancelToken: cancelToken});
}

const reportsGet = () => axios.get(URLS.REPORTS.GET);

// Preview for reports with relative period (already saved scheduled reports preview)
const reportsPreview = id => {
    return axios.get(URLS.REPORTS.PREVIEW.replace(':id', id), {
        responseType: 'blob'
    });
}

const reportAdd = (report) => {
    return axios.post(URLS.REPORTS.ADD, {
        name: report.name,
        type: report.type,
        groupId: report.groupId,
        sensorId: report.sensorId,
        channel: report.channel,
        periodGranularity: report.periodGranularity,
        periodQuantity: report.periodQuantity,
        triggerGranularity: report.triggerGranularity,
        triggerQuantity: report.triggerQuantity,
        recipients: report.recipients
    });
}

const reportEdit = (report) => {
    return axios.put(URLS.REPORTS.EDIT, {
        id: report.id,
        name: report.name,
        type: report.type,
        groupId: report.groupId,
        sensorId: report.sensorId,
        channel: report.channel,
        periodGranularity: report.periodGranularity,
        periodQuantity: report.periodQuantity,
        triggerGranularity: report.triggerGranularity,
        triggerQuantity: report.triggerQuantity,
        recipients: report.recipients
    });
}

const reportDelete = (id) => axios.delete(URLS.REPORTS.DELETE, {data: {id: id}});

// Preview for reports with relative period (not yet saved scheduled reports preview)
const reportsLivePreview = (report) => {
    return axios.put(URLS.REPORTS.LIVE_PREVIEW, {
            type: report.type,
            groupId: report.groupId,
            sensorId: report.sensorId,
            channel: report.channel,
            periodGranularity: report.periodGranularity,
            periodQuantity: report.periodQuantity
        },
        {
            responseType: 'blob'
        }
    );
}

// Custom, fixed date reports (not scheduled)
const reportsGenerate = (report) => {
    return axios.put(URLS.REPORTS.LIVE_PREVIEW, {
            type: report.type,
            groupId: report.groupId,
            sensorId: report.sensorId,
            channel: report.channel,
            startDate: report.startDate,
            endDate: report.endDate,
        },
        {
            responseType: 'blob'
        }
    );
}

const labelsGet = () => axios.get(URLS.LABELS.GET);

const labelAdd = name => axios.post(URLS.LABELS.ADD, {
    name,
});

const alertsGet = () => axios.get(URLS.ALERTS.GET);

const alertsAdd = (alert) => {
    return axios.post(URLS.ALERTS.ADD, {
        name: alert.name,
        groupId: alert.groupId,
        category: alert.category,
        sensorId: alert.sensorId,
        subgroup: alert.subgroup,
        channel: alert.channel,
        executionStartTime: alert.executionStartTime,
        executionEndTime: alert.executionEndTime,
        notificationType: alert.notificationType,
        alertType: alert.alertType,
        alertGranularity: alert.alertGranularity,
        alertQuantity: alert.alertQuantity,
        alertValue: alert.alertValue,
        alertOperation: alert.alertOperation,
        alertPeriodGranularity: alert.alertPeriodGranularity,
        alertPeriodQuantity: alert.alertPeriodQuantity,
        triggerQuantity: alert.triggerQuantity,
        triggerGranularity: alert.triggerGranularity,
        recipients: alert.recipients
    });
}

const alertsEdit = (alert) => {
    return axios.put(URLS.ALERTS.EDIT, {
        id: alert.id,
        name: alert.name,
        groupId: alert.groupId,
        sensorId: alert.sensorId,
        subgroup: alert.subgroup,
        channel: alert.channel,
        executionStartTime: alert.executionStartTime,
        executionEndTime: alert.executionEndTime,
        category: alert.category,
        notificationType: alert.notificationType,
        alertType: alert.alertType,
        alertGranularity: alert.alertGranularity,
        alertQuantity: alert.alertQuantity,
        alertValue: alert.alertValue,
        alertOperation: alert.alertOperation,
        alertPeriodGranularity: alert.alertPeriodGranularity,
        alertPeriodQuantity: alert.alertPeriodQuantity,
        triggerQuantity: alert.triggerQuantity,
        triggerGranularity: alert.triggerGranularity,
        recipients: alert.recipients
    });
}

const alertsDelete = (id) => {
    return axios.delete(URLS.ALERTS.DELETE, {
        data: {
            id: id,
        }
    });
}

const alertsEventsGet = (lastTimestamp, read) => axios.get(URLS.ALERTS.EVENTS.GET, {
    params: lastTimestamp ? { lastTimestamp, read } : { read }
});

const alertsEventsDetails = id => axios.get(URLS.ALERTS.EVENTS.DETAILS.replace(':id', id));

const alertsEventsUnread = () => axios.get(URLS.ALERTS.EVENTS.UNREAD);

const alertsEventsEdit = (alerts) => axios.put(URLS.ALERTS.EVENTS.EDIT, {
    alerts: alerts
});

const alertsEventsDelete = alerts => axios.delete(URLS.ALERTS.EVENTS.DELETE, {
    data: {
        alerts: alerts
    }
});

const alertsEventsSensorConsumption = (sensorId, channel, startDate, endDate) => {
    return axios.get(URLS.ALERTS.EVENTS.CONSUMPTION.SENSOR
        .replace(':id', sensorId)
        .replace(':channel', channel),
        {params: { startDate, endDate }});
}

const alertsEventsGroupConsumption = (id, startDate, endDate) => {
    return axios.get(URLS.ALERTS.EVENTS.CONSUMPTION.GROUP.replace(':id',id), {params: { startDate, endDate }});
}

const alertsEventsSensorCost = (sensorId, channel, startDate, endDate) => {
    return axios.get(URLS.ALERTS.EVENTS.COST.SENSOR
        .replace(':id', sensorId)
        .replace(':channel', channel),
        {params: { startDate, endDate }});
}

const alertsEventsGroupCost = (id, startDate, endDate) => {
    return axios.get(URLS.ALERTS.EVENTS.COST.GROUP.replace(':id',id), {params: { startDate, endDate }});
}

const systemEventsDetails = id => axios.get(URLS.ALERTS.EVENTS.SYSTEM.DETAILS.replace(':id', id));

const systemEventsUnread = () => axios.get(URLS.ALERTS.EVENTS.SYSTEM.UNREAD);

const homeEvents = () => axios.get(URLS.HOME.ALERTS.EVENTS);

const homeUnreadRelativeConsumptionEvents = () => axios.get(URLS.HOME.ALERTS.UNREAD.RELATIVE_CONSUMPTION);

const homeUnreadAbsoluteConsumptionEvents = () => axios.get(URLS.HOME.ALERTS.UNREAD.ABSOLUTE_CONSUMPTION);

const homeUnreadCostEvents = () => axios.get(URLS.HOME.ALERTS.UNREAD.ABSOLUTE_COST);

const homeUnreadStatusEvents = () => axios.get(URLS.HOME.ALERTS.UNREAD.ENTITY_STATUS);

const homeUnreadPowerEvents = () => axios.get(URLS.HOME.ALERTS.UNREAD.ABSOLUTE_ACTIVE_POWER);

const homeConsumptionGroupsDay = (date) => {
    if (date){
        return axios.get(URLS.HOME.CONSUMPTION.GROUPS.DAY, {params: { date }});
    }else{
        return axios.get(URLS.HOME.CONSUMPTION.GROUPS.DAY);
    }

}

const homeConsumptionGroupsWeek = (date) => {
    if (date){
        return axios.get(URLS.HOME.CONSUMPTION.GROUPS.WEEK, {params: { date }});
    }else{
        return axios.get(URLS.HOME.CONSUMPTION.GROUPS.WEEK);
    }
}

const homeConsumptionGroupsMonth = (date) => {
    if (date){
        return axios.get(URLS.HOME.CONSUMPTION.GROUPS.MONTH, {params: { date }});
    }else{
        return axios.get(URLS.HOME.CONSUMPTION.GROUPS.MONTH);
    }

}

const homeConsumptionSensorsDay = () => axios.get(URLS.HOME.CONSUMPTION.SENSORS.DAY);

const homeConsumptionSensorsWeek = () => axios.get(URLS.HOME.CONSUMPTION.SENSORS.WEEK);

const homeConsumptionSensorsMonth = () => axios.get(URLS.HOME.CONSUMPTION.SENSORS.MONTH);

const homeCostGroupsDay = (date) => {
    if (date){
        return axios.get(URLS.HOME.COST.GROUPS.DAY, {params: {date}});
    }else{
        return axios.get(URLS.HOME.COST.GROUPS.DAY);
    }
}

const homeCostGroupsWeek = (date) => {
    if (date){
        return axios.get(URLS.HOME.COST.GROUPS.WEEK, {params: {date}});
    }else{
        return axios.get(URLS.HOME.COST.GROUPS.WEEK);
    }
}

const homeCostGroupsMonth = (date) => {
    if (date){
        return axios.get(URLS.HOME.COST.GROUPS.MONTH, {params: {date}});
    }else{
        return axios.get(URLS.HOME.COST.GROUPS.MONTH);
    }
}

const homeCostSensorsDay = () => axios.get(URLS.HOME.COST.SENSORS.DAY);

const homeCostSensorsWeek = () => axios.get(URLS.HOME.COST.SENSORS.WEEK);

const homeCostSensorsMonth = () => axios.get(URLS.HOME.COST.SENSORS.MONTH);

const tenantGet = () => axios.get(URLS.ROOT.TENANTS.GET);

const tenantAdd = ({name})=> axios.post(URLS.ROOT.TENANTS.ADD, {
    name,
});

const tenantEdit = ({ id, name }) => axios.put(URLS.ROOT.TENANTS.EDIT, {
    id,
    name
});

const tenantConfiguration = ({ id, logo, primaryColor, secondaryColor, showPoweredByVoltaware }) => axios.put(URLS.ROOT.TENANTS.CONFIGURATION, {
    tenantId: id,
    logo,
    primaryColor,
    secondaryColor,
    poweredByVoltaware: showPoweredByVoltaware
});

const tenantUsersGet = ({ id }) => {
    return axios.get(URLS.ROOT.TENANTS.USERS.GET.replace(':tenantId', id))
};

const tenantUsersAdd = (user, tenant)=> axios.post(URLS.ROOT.TENANTS.USERS.ADD, {
    email: user.email,
    localeCode: user.localeCode,
    tenantId: tenant.id,
});

const tenantUsersDelete = (email, tenant) => axios.delete(URLS.ROOT.TENANTS.USERS.DELETE,
    {
        data: {
            email: email,
            tenantId: tenant.id,
        }
    }
);

const tenantUsersEnableAll = (tenant) => axios.put(URLS.ROOT.TENANTS.USERS.ENABLE_ALL, {
    id: tenant.id
});

const tenantUsersDisableAll = (tenant) => axios.put(URLS.ROOT.TENANTS.USERS.DISABLE_ALL, {
    id: tenant.id
});

const tenantSensorsEdit = ({ tenantId, sensors }) => axios.put(URLS.ROOT.TENANTS.SENSORS.EDIT, {
    tenantId,
    sensors,
});

const tenantSensorsDelete = sensors => axios.delete(URLS.ROOT.TENANTS.SENSORS.DELETE, {
    data: { sensors }
});

const tenantLogin = ({ tenantId, email }) => axios.post(URLS.ROOT.TENANTS.LOGIN, {
    tenantId,
    email,
});

const API = {
    CONFIG: config,
    AUTH: {
        LOGIN: login,
        LOGOUT: logout,
    },
    USERS: {
        GET: usersGet,
        ADD: usersAdd,
        EDIT: usersEdit,
        DELETE: usersDelete,
        RESET_PASSWORD: resetPassword,
        LOCALE: updateLocale,
        LAST_URL: {
            GET: lastUrlGet,
            PUT: lastUrlPut,
        },
    },
    VISUALIZATIONS: {
        LIVE: liveViz,
        MAP: getVizMap,
        INFO: {
            GENERAL: generalInfo,
        },
        CONSUMPTION: {
            LINE: {
                DAY: {
                    GROUP: lineDayVizGroup,
                    SENSOR: lineDayVizSensor,
                    LABEL: lineDayVizLabel,
                },
                WEEK: {
                    GROUP: lineWeekVizGroup,
                    SENSOR: lineWeekVizSensor,
                    LABEL: lineWeekVizLabel,
                },
                MONTH: {
                    GROUP: lineMonthVizGroup,
                    SENSOR: lineMonthVizSensor,
                    LABEL: lineMonthVizLabel,
                },
                YEAR: {
                    GROUP: lineYearVizGroup,
                    SENSOR: lineYearVizSensor,
                    LABEL: lineYearVizLabel,
                },
                CUSTOM: {
                    GROUP: lineCustomVizGroup,
                    SENSOR: lineCustomVizSensor,
                    LABEL: lineCustomVizLabel,
                },
            },
            STREAM: {
                DAY: {
                    GROUP: streamDayVizGroup,
                    LABEL: streamDayVizLabel,
                },
                WEEK: {
                    GROUP: streamWeekVizGroup,
                    LABEL: streamWeekVizLabel,
                },
                MONTH: {
                    GROUP: streamMonthVizGroup,
                    LABEL: streamMonthVizLabel,
                },
                YEAR: {
                    GROUP: streamYearVizGroup,
                    LABEL: streamYearVizLabel,
                },
                CUSTOM: {
                    GROUP: streamCustomVizGroup,
                    LABEL: streamCustomVizLabel,
                },
            },
            HEAT: {
                DAY: {
                    GROUP: heatDayVizGroup,
                    SENSOR: heatDayVizSensor,
                    LABEL: heatDayVizLabel,
                },
            }
        },
        COST:{
            REAL: {
                DAY: {
                    GROUP: costDayVizGroup,
                    LABEL: costDayVizLabel,
                    SENSOR: costDayVizSensor,
                },
                WEEK: {
                    GROUP: costWeekVizGroup,
                    LABEL: costWeekVizLabel,
                    SENSOR: costWeekVizSensor,
                },
                MONTH: {
                    GROUP: costMonthVizGroup,
                    LABEL: costMonthVizLabel,
                    SENSOR: costMonthVizSensor,
                },
                YEAR: {
                    GROUP: costYearVizGroup,
                    LABEL: costYearVizLabel,
                    SENSOR: costYearVizSensor,
                },
                CUSTOM: {
                    GROUP: costCustomVizGroup,
                    LABEL: costCustomVizLabel,
                    SENSOR: costCustomVizSensor,
                }
            },
            SIMULATION: {
                CUSTOM: {
                    GROUP: costSimulationCustomVizGroup,
                    SENSOR: costSimulationCustomVizSensor,
                }
            }
        },
        POWER: {
            LINE: {
                SENSOR: powerVizSensor,
            },
            PEAK: {
                SENSOR: powerPeakVizSensor,
            }
        }
    },
    GROUPS: {
        GET: groupsGet,
        ADD: groupsAdd,
        EDIT: groupsEdit,
    },
    GROUP: {
        GET: groupGet,
        DELETE: groupDelete,
    },
    SENSORS: {
        GET: sensorsGet,
        DETAILS: {
            SET: sensorsDetailsSet
        },
        WITHOUT_GROUP: {
            GET: sensorWithoutGroupGet
        },
        STATUS: {
            GET: sensorStatus
        },
    },
    TARIFFS: {
        GET: tariffsGet,
        SEARCH: tariffsGet,
        ADD: tariffAdd,
        EDIT: tariffEdit,
        DELETE: tariffDelete,
        COMPOSITION: {
            ADD: tariffCompositionAdd,
            EDIT: tariffCompositionUpdate,
            DELETE: tariffCompositionDelete,
        },
        CURRENCY: {
            GROUP: groupCurrencyGet,
            SENSOR: sensorCurrencyGet,
        }
    },
    COUNTRIES: {
        GET: countriesGet
    },
    LOCALES: {
        GET: localesGet
    },
    TIMEZONES: {
        GET: timeZonesGet
    },
    REPORTS: {
        GET: reportsGet,
        ADD: reportAdd,
        EDIT: reportEdit,
        DELETE: reportDelete,
        PREVIEW: reportsPreview,
        LIVE_PREVIEW: reportsLivePreview,
        GENERATE: reportsGenerate,
    },
    LABELS: {
        GET: labelsGet,
        ADD: labelAdd
    },
    ALERTS: {
        GET: alertsGet,
        ADD: alertsAdd,
        EDIT: alertsEdit,
        DELETE: alertsDelete,
        EVENTS:{
            GET: alertsEventsGet,
            DETAILS: alertsEventsDetails,
            UNREAD: alertsEventsUnread,
            EDIT: alertsEventsEdit,
            DELETE: alertsEventsDelete,
            CONSUMPTION: {
                GROUP: alertsEventsGroupConsumption,
                SENSOR: alertsEventsSensorConsumption,
            },
            COST: {
                GROUP: alertsEventsGroupCost,
                SENSOR: alertsEventsSensorCost,
            },
            SYSTEM: {
                DETAILS: systemEventsDetails,
                UNREAD: systemEventsUnread
            }
        }
    },
    HOME: {
        ALERTS: {
            EVENTS: homeEvents,
            UNREAD: {
                RELATIVE_CONSUMPTION: homeUnreadRelativeConsumptionEvents,
                ABSOLUTE_CONSUMPTION: homeUnreadAbsoluteConsumptionEvents,
                ABSOLUTE_COST: homeUnreadCostEvents,
                ABSOLUTE_ACTIVE_POWER: homeUnreadPowerEvents,
                ENTITY_STATUS: homeUnreadStatusEvents
            }
        },
        CONSUMPTION: {
            GROUPS: {
                DAY: homeConsumptionGroupsDay,
                WEEK: homeConsumptionGroupsWeek,
                MONTH: homeConsumptionGroupsMonth,
            },
            SENSORS: {
                DAY: homeConsumptionSensorsDay,
                WEEK: homeConsumptionSensorsWeek,
                MONTH: homeConsumptionSensorsMonth,
            }
        },
        COST: {
            GROUPS: {
                DAY: homeCostGroupsDay,
                WEEK: homeCostGroupsWeek,
                MONTH: homeCostGroupsMonth,
            },
            SENSORS: {
                DAY: homeCostSensorsDay,
                WEEK: homeCostSensorsWeek,
                MONTH: homeCostSensorsMonth,
            }
        }
    },
    ROOT: {
        TENANTS: {
            GET: tenantGet,
            ADD: tenantAdd,
            EDIT: tenantEdit,
            LOGIN: tenantLogin,
            CONFIGURATION: tenantConfiguration,
            USERS: {
                GET: tenantUsersGet,
                ADD: tenantUsersAdd,
                DELETE: tenantUsersDelete,
                ENABLE_ALL: tenantUsersEnableAll,
                DISABLE_ALL: tenantUsersDisableAll,
            },
            SENSORS: {
                EDIT: tenantSensorsEdit,
                DELETE: tenantSensorsDelete
            }
        },
    }
}

export {API};
