import React from 'react';

import {Field} from './field';


class InputFile extends Field {
    render () {
        let spanClass = this.getSpanClass();

        return (
            <label className="input-file">
                <span className={spanClass}>{this.props.placeholder}</span>
                <input onBlur={this.blur}
                    onFocus={this.focus}
                    accept={this.props.accept}
                    className={this.props.hasError ? 'error' : ''}
                    disabled={this.props.disabled}
                    name={this.props.name}
                    onChange={this.props.change}
                    ref={(ref) => this.file = ref}
                    value={this.props.value}
                    type="file" />
                <span className="border" />
            </label>
        );
    }
}

export {InputFile};
