import React from 'react';
import {withStyles} from 'material-ui/styles';
import Dialog, {
  DialogActions,
  DialogContent,
  DialogContentText,
  withMobileDialog,
} from 'material-ui/Dialog';
import PropTypes from 'prop-types';
import Snackbar from 'material-ui/Snackbar';
import MaterialButton from 'material-ui/Button';
import IconButton from 'material-ui/IconButton';
import CloseIcon from 'material-ui-icons/Close';

import {ErrorMessage} from '../../elements/error-message';

import i18n from '../../i18n';

import brazilLogo from '../../general/img/brazil.svg';
import unitedKingdomLogo from '../../general/img/united-kingdom.svg';
import russiaLogo from '../../general/img/russia.svg';

import logoEM from './logo_electricity_monitoring.svg';

import './login.css';


const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const greenColor = '#6ab42d';
const redColor = '#c62828';
const styles = theme => ({
    close: {
        width: theme.spacing.unit * 4,
        height: theme.spacing.unit * 4,
    },
    snackbarRoot: {background: '#fff'},
    snackbarMessage: {color: '#757575'},
});

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dialogOpen: false,
            snackMessage: '',
            snackOpen: false,
        };
    }

    componentWillReceiveProps (nextProps) {
        if (nextProps.forgotState.tried) {
            nextProps.forgotState.sent ?
                this.setState({snackMessage: i18n.t('A new password was sent to your email'), snackOpen: true}) :
                nextProps.forgotState.apiResponse.status === 500 &&
                nextProps.forgotState.apiResponse.data.message.indexOf('Sorry') >= 0 ?
                    this.setState({snackMessage: nextProps.forgotState.apiResponse.data.message, snackOpen: true}) :
                    this.setState({snackMessage: i18n.t('There was an error resetting the password'), snackOpen: true});
            this.props.forgotReset();
        }
    }

    setLanguageEN = () => {
        if (this.props.language === 'en-GB') {
            return
        }

        i18n.changeLanguage('en-GB');
        this.props.changeLanguage('en-GB');
    }

    setLanguageRU = () => {
        if (this.props.language === 'ru-RU') {
            return
        }

        i18n.changeLanguage('ru-RU');
        this.props.changeLanguage('ru-RU');
    }

    setLanguageBR = () => {
        if (this.props.language === 'pt-BR') {
            return
        }

        i18n.changeLanguage('pt-BR');
        this.props.changeLanguage('pt-BR');
    }

    handleClickForgot = (e) => {
        e.preventDefault();

        !emailRegex.test(this.props.user) ?
            this.setState({snackOpen: true, snackMessage: i18n.t('Please fill the username correctly')}) :
            this.setState({dialogOpen: true});
    }

    handleCloseForgot = (event, reason) => {
        this.setState({snackOpen: false});
    }

    handleCloseForgotDialog = (flag) => {
        this.setState({dialogOpen: false});

        if (flag === true) {
            this.setState({snackOpen: true, snackMessage: i18n.t('Sending the email')});
            this.props.forgot();
        }
    }

    browserSupport = () => ({
        __html: i18n.t('For a better experience on Electricity Monitoring Dashboard use {{firefox}} or {{chrome}}', {
            firefox: `<a href="https://www.mozilla.org" target="_blank" rel="noopener noreferrer">${i18n.t("Firefox")}</a>`,
            chrome: `<a href="https://www.google.com/chrome" target="_blank" rel="noopener noreferrer">${i18n.t("Chrome")}</a>`,
        })
    })

    render () {
        const {classes, language} = this.props;

        return (
            <section id="page-login">
                <div className="topbar">
                    <div>
                        <div className="topbar-container">
                            <img alt={i18n.t("voltaware building electricity monitoring logo")} src={logoEM} />
                        </div>
                    </div>
                </div>

                <div className="wrapper">
                    <div className="container">
                        <h1>{i18n.t('Login')}</h1>
                        <div className="language-container">
                            <button onClick={this.setLanguageEN} className={language === null || language === 'en-GB' ? 'active' : ''}>
                                <img src={unitedKingdomLogo} alt={i18n.t('Change language to english')} />
                            </button>
                            <button onClick={this.setLanguageRU} className={language === 'ru-RU' ? 'active' : ''}>
                                <img src={russiaLogo} alt={i18n.t('Change language to russian')} />
                            </button>
                            <button onClick={this.setLanguageBR} className={language === null || language === 'pt-BR' ? 'active' : ''}>
                                <img src={brazilLogo} alt={i18n.t('Change language to brazilian portuguese')} />
                            </button>
                        </div>

                        {this.props.errors.indexOf('credentials') >= 0 ?
                            <ErrorMessage message="Unauthorized." /> : null}

                        <form action="#" onSubmit={this.props.submit}>
                            <div>
                                <input autoFocus={true}
                                    className={this.props.errors.indexOf('user') >= 0 ? 'has-error' : ''}
                                    disabled={this.props.loading}
                                    name="user"
                                    onChange={this.props.change}
                                    placeholder={i18n.t("Username")}
                                    type="text"
                                    value={this.props.user} />
                            </div>

                            <div>
                                <input className={this.props.errors.indexOf('pass') >= 0 ? 'has-error' : ''}
                                    disabled={this.props.loading}
                                    name="pass"
                                    onChange={this.props.change}
                                    placeholder={i18n.t("Password")}
                                    type="password"
                                    value={this.props.pass} />
                            </div>

                            <div>
                                <button disabled={this.props.loading} type="submit">{i18n.t('Sign in')}</button>
                                <button className="forgot-link" onClick={this.handleClickForgot}>
                                    {i18n.t('Forgot your password?')}
                                </button>
                            </div>
                        </form>
                        <div className="browser-support" dangerouslySetInnerHTML={this.browserSupport()} />
                    </div>
                </div>

                <Snackbar action={[
                    <IconButton aria-label="Close"
                        className={classes.close}
                        color="inherit"
                        key="close"
                        onClick={this.handleCloseForgot}>
                        <CloseIcon />
                    </IconButton>,]}
                    anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
                    autoHideDuration={50000}
                    ContentProps={{
                        'aria-describedby': 'snackbar-message-id',
                        classes: {
                            root: classes.snackbarRoot,
                            message: classes.snackbarMessage,
                        },
                    }}
                    message={<span id="snackbar-message-id">{this.state.snackMessage}</span>}
                    onClose={this.handleCloseForgot}
                    open={this.state.snackOpen} />

                <Dialog aria-labelledby="dialog-text"
                    fullWidth={true}
                    maxWidth="xs"
                    open={this.state.dialogOpen}
                    onClose={this.handleCloseForgotDialog}>
                    <DialogContent>
                        <DialogContentText id="dialog-text">Confirm password reset?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <MaterialButton autoFocus onClick={() => this.handleCloseForgotDialog(true)}>
                            <span style={{color: greenColor}}>Yes</span>
                        </MaterialButton>
                        <MaterialButton onClick={this.handleCloseForgotDialog} color="inherit">
                            <span style={{color: redColor}}>Cancel</span>
                        </MaterialButton>
                    </DialogActions>
                </Dialog>
            </section>
        );
    }
}

LoginPage.propTypes = {
    forgotState: PropTypes.object.isRequired,
    forgotReset: PropTypes.func.isRequired,
    language: PropTypes.string,
    changeLanguage: PropTypes.func.isRequired,
    user: PropTypes.string,
    forgot: PropTypes.func.isRequired,
    errors: PropTypes.array,
    submit: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    change: PropTypes.func.isRequired,
    pass: PropTypes.string
}

export default withStyles(styles)(withMobileDialog()(LoginPage));
