import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import classnames from 'classnames';
import moment from 'moment';
import Avatar from 'material-ui/Avatar';
import Card, { CardActions, CardContent, CardHeader } from 'material-ui/Card';
import Collapse from 'material-ui/transitions/Collapse';
import IconButton from 'material-ui/IconButton';
import ExpandMoreIcon from 'material-ui-icons/ExpandMore';
import DeleteIcon from 'material-ui-icons/Delete';
import DescriptionIcon from 'material-ui-icons/Description';
import EditIcon from 'material-ui-icons/Edit';
import Dialog, {
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from 'material-ui/Dialog';
import Button from 'material-ui/Button';

import i18n from '../../i18n';

import { Text } from '../../elements/text';
import { Button as VButton } from '../../elements/button';
import SystemSnackbar from '../../elements/material-ui/SystemSnackbar';

import './report-card.css';

import { periods, triggers } from './report-add';

const styles = theme => ({
    recipients: {
        display: 'flex',
        padding: '0 15px 10px'
    },
    subloadContentGroup: {
        padding: '0 15px 10px'
    },
    card: {
        marginBottom: '20px',
        width: '320px',
    },
    cardButton: {
        height: '30px',
        width: '30px',
    },
    cardButtonIcon: {
        color: '#fff',
        fontSize: '20px',
    },
    headerRoot: {
        background: '#3a3d4e',
        padding: '4px 9px 5px',
    },
    headerAvatar: {
        marginRight: '8px',
    },
    avatar: {
        background: 'none',
        height: '60px',
    },
    avatarIcon: {
        fontSize: '50px',
    },
    headerTitle: {
        color: '#fff',
        fontSize: '1.1rem',
        lineHeight: '1.4',
        paddingTop: '5px',
    },
    headerSubheader: {
        color: '#fff',
        fontSize: '.9rem',
        lineHeight: '1.4',
    },
    headerAction: {
        marginRight: '-2px',
        marginTop: '3px',
    },
    cardActions: {
        borderTop: '1px solid #bbb',
        justifyContent: 'flex-end',
        padding: '12px 15px',
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
        marginLeft: 'auto',
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    expandMoreButtonRoot: {
        width: '26px',
        height: '26px',
    },
    contentRoot: {
        minHeight: '155px',
        padding: '0',
        '&:last-child': {
            paddingBottom: '10px',
        },
    },
    groupContent: {
        height: '72px',
        padding: '10px 15px',
    },
    deleteConfirm: {
        color: '#6ab42d',
    },
    deleteCancel: {
        color: '#c62828',
    },
    periodAndSendEvery: {
        background: '#F0F0F0',
        fontSize: '16px',
        display: 'flex',
        padding: '10px 15px',
        justifyContent: 'space-between',
        margin: '0',
    },
});

class ReportCard extends PureComponent {
    state = {
        expanded: false,
        loadingPreview: false,
        showSnackBar: false,
        snackbar: {
            type: 'warning',
            message: ''
        },
        deleteDialogOpen: false,
    }

    getPeriodLabel = ({ periodQuantity, periodGranularity }) => {
        const foundPeriod = periods().find(period =>
            period.periodQuantity === periodQuantity &&
            period.periodGranularity === periodGranularity
        )

        return foundPeriod && foundPeriod.label ? foundPeriod.label : '';
    }

    getTriggerLabel = ({ triggerQuantity, triggerGranularity }) => {
        const foundTrigger = triggers().find(trigger =>
            trigger.triggerQuantity === triggerQuantity &&
            trigger.triggerGranularity === triggerGranularity
        )

        return foundTrigger && foundTrigger.label ? foundTrigger.label : '';
    }

    handleExpandClick = () => {
        setTimeout(this.props.masonryLayout, 150);
        this.setState({ expanded: !this.state.expanded });
    }

    reportPreview = (reportId) => window.open(`/reports/preview/${reportId}`, '_blank', 'toolbar=no')

    reportDateFormatted = (date) => moment(date).format('LL')

    openSnackBar = (message, type) => this.setState({
        showSnackBar: true,
        snackbar: {
            message,
            type
        }
    })

    closeSnackBar = () => this.setState({
        showSnackBar: false
    })

    handleEditClick = () => this.props.openEditReport(this.props.data)

    handleDeleteClick = () => {
        this.setState({deleteDialogOpen: true});
    }

    handleDeleteDialogClose = (confirmDelete) => {
        if (confirmDelete === true)
            this.props.handleReportDelete(this.props.data);
        this.setState({deleteDialogOpen: false});
    }

    render() {
        const { expanded, showSnackBar, snackbar } = this.state;
        const { classes, data } = this.props;

        return (
            <Card className={classes.card}>
                <CardHeader classes={{
                        action: `${classes.headerAction} headerAction`,
                        avatar: classes.headerAvatar,
                        root: `${classes.headerRoot} header`,
                        subheader: classes.headerSubheader,
                        title: `${classes.headerTitle} headerTitle`,
                    }}
                    action={localStorage.getItem('authority') === 'ADMIN' && (
                        <div>
                            <IconButton aria-label="Delete"
                                color="inherit"
                                key="delete"
                                className={`${classes.cardButton} delete-button`}
                                onClick={this.handleDeleteClick}>
                                <DeleteIcon className={classes.cardButtonIcon} />
                            </IconButton>
                            <IconButton aria-label="Edit"
                                color="inherit"
                                key="edit"
                                className={`${classes.cardButton} edit-button`}
                                onClick={this.handleEditClick}>
                                <EditIcon className={classes.cardButtonIcon} />
                            </IconButton>
                        </div>
                    )}
                    avatar={
                        <Avatar className={classes.avatar}><DescriptionIcon className={classes.avatarIcon} /></Avatar>
                    }
                    title={data.name}
                    subheader={this.reportDateFormatted(data.createdAt)} />

                <CardContent classes={{root: classes.contentRoot}}>

                    <div className={classes.periodAndSendEvery}>
                        <div>
                            <Text inline medium text={i18n.t("Period:")} />
                            <Text bold
                                inline
                                medium
                                text={this.getPeriodLabel(data)} />
                        </div>
                        <div>
                            <Text inline medium text={i18n.t("Send every:")} />
                            <Text bold
                                inline
                                medium
                                text={this.getTriggerLabel(data)} />
                        </div>
                    </div>
                    <div className={classes.groupContent}>
                        <div>
                            <Text inline text={i18n.t("Group:")} />
                            <Text bold
                                inline
                                text={(data.breadCrumbs && data.breadCrumbs.parentGroupName)
                                    ? data.breadCrumbs.parentGroupName
                                    : data.breadCrumbs.groupName} />
                        </div>

                        {data.breadCrumbs && data.breadCrumbs.parentGroupName && data.breadCrumbs.groupName ? (
                            <div>
                                <Text inline text={i18n.t("Subgroup:")} />
                                <Text bold inline text={data.breadCrumbs.groupName} />
                            </div>
                        ) : null}

                        {data.breadCrumbs && data.breadCrumbs.sensorId ? (
                            <div>
                                <Text inline text={i18n.t("Sensor:")} />
                                <Text bold
                                    inline
                                    text={`
                                        ${data.breadCrumbs.sensorId}
                                        -${data.breadCrumbs.channel}
                                         ${data.breadCrumbs.sensorChannelName}
                                    `} />
                            </div>
                        ) : null}
                    </div>

                    <div className={classes.recipients}>
                        <Text text={i18n.t("Recipients")} />
                        <IconButton
                            className={classnames(classes.expand, {
                                [classes.expandOpen]: expanded,
                            })}
                            classes={{root: classes.expandMoreButtonRoot}}
                            onClick={this.handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="Show more">
                            <ExpandMoreIcon />
                        </IconButton>
                    </div>

                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <div className={classnames(classes.subloadContentGroup)}>
                            <div>
                                <Text bold
                                    text={data.recipients && data.recipients.length ? data.recipients.join(', ') : i18n.t('There is no recipients added')} />
                            </div>
                        </div>
                    </Collapse>
                </CardContent>

                <CardActions className={classes.cardActions}>
                    <VButton action={"submit"}
                        click={() => this.reportPreview(data.id)}
                        disabled={this.state.loadingPreview}
                        label={i18n.t("Preview")} />
                </CardActions>

                <Dialog aria-labelledby="dialog-text"
                    fullWidth={true}
                    maxWidth="xs"
                    open={this.state.deleteDialogOpen}
                    onClose={this.handleDeleteDialogClose}>
                    <DialogTitle>
                        {i18n.t('Are you sure?')}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="dialog-text">
                            {i18n.t('This report will be permanently removed.')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button classes={{label: classes.deleteConfirm}}
                            onClick={() => this.handleDeleteDialogClose(true)}>
                            {i18n.t('Yes')}
                        </Button>
                        <Button classes={{label: classes.deleteCancel}} onClick={() => this.handleDeleteDialogClose(false)}>
                            {i18n.t('Cancel')}
                        </Button>
                    </DialogActions>
                </Dialog>

                <SystemSnackbar
                    open={showSnackBar}
                    message={snackbar.message}
                    onClose={this.closeSnackBar}
                    variant={snackbar.type}
                />
            </Card>
        );
    }
}

ReportCard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ReportCard);
