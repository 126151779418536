import React from 'react';
import Select from 'react-select';
import { Collapse } from 'material-ui';

import Card, { CardHeader, CardContent } from 'material-ui/Card';
import IconButton from 'material-ui/IconButton';
import ExpandMoreIcon from 'material-ui-icons/ArrowDropDown';
import DeleteIcon from 'material-ui-icons/Delete';

import { Text } from '../text';

// Translation
import i18n from '../../i18n';

const CompareCard = ({
    changeGroup,
    changeSensor,
    changeSubgroup,
    changeTag,
    compare,
    filterGroupOptions,
    hideSensors,
    hideTags,
    index,
    obj,
    removeCompare,
    tags,
    toggleCompareCollapse,
}) => (
    <Card className={`compare-card${obj.open ? ' active' : ''}`} style={{ borderColor: obj.color }}>
        <div className="compare-card-wrapper">
            <CardHeader
                action={
                    <div>
                        {compare.length > 1 && (
                            <IconButton
                                onClick={() => removeCompare(index)}
                                style={{height: 24, width: 24}}
                            >
                                <DeleteIcon />
                            </IconButton>
                        )}
                        <IconButton
                            onClick={() => toggleCompareCollapse(index)}
                            style={{height: 24, width: 24}}
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    </div>
                }
                className="compare-card-title"
                title={
                    obj.filterSensor
                        ? obj.filterSensor.name
                        : obj.filterSubgroup
                            ? obj.filterSubgroup.name
                            : obj.filterGroup
                                ? obj.filterGroup.name
                                : obj.filterTag
                                    ? `# ${obj.filterTag.name}`
                                    : ''
                    }
            />
            <Collapse
                in={obj.open}
                timeout={300}
                className="compare-card-collapse"
            >
                <CardContent style={{padding: 0}}>
                    <Text text={i18n.t("Group")} />
                    <Select
                        name="groupFilter"
                        value={obj.filterGroup}
                        onChange={group => changeGroup(group, index)}
                        disabled={filterGroupOptions.length === 0}
                        valueKey="id"
                        labelKey="name"
                        options={filterGroupOptions}
                        placeholder={i18n.t("Select any group")}
                        noResultsText={i18n.t('No results found')}
                    />
                    <Text text={i18n.t("Subgroup")} />
                    <Select
                        name="subgroupFilter"
                        value={obj.filterSubgroup}
                        onChange={subgroup => changeSubgroup(subgroup, index)}
                        disabled={
                            !!obj.filterTag ||
                            !obj.filterSubgroupOptions ||
                            obj.filterSubgroupOptions.length === 0}
                        valueKey="id"
                        labelKey="name"
                        options={obj.filterSubgroupOptions}
                        placeholder={i18n.t("Select any subgroup")}
                        noResultsText={i18n.t('No results found')}
                    />
                    {!hideSensors && (
                        <Text text={i18n.t("Sensor")} />
                    )}
                    {!hideSensors && (
                        <Select
                            name="sensorFilter"
                            value={obj.filterSensor}
                            onChange={sensor => changeSensor(sensor, index)}
                            disabled={!!obj.filterTag || obj.filterSensorOptions.length === 0}
                            valueKey="key"
                            labelKey="label"
                            options={obj.filterSensorOptions}
                            placeholder={i18n.t("Select any sensor")}
                            noResultsText={i18n.t('No results found')}
                        />
                    )}
                    {!hideTags && (
                        <div className="tag-divider" />
                    )}
                    {!hideTags && (
                        <Text text={i18n.t("Tag")} />
                    )}
                    {!hideTags && (
                        <Select
                            name="tag"
                            value={obj.filterTag && obj.filterTag.id}
                            onChange={tag => changeTag(tag, index)}
                            valueKey="id"
                            labelKey="name"
                            options={tags}
                            placeholder={i18n.t("Select any tag")}
                            noResultsText={i18n.t('No results found')}
                        />
                    )}
                </CardContent>
            </Collapse>
        </div>
    </Card>
)

export default CompareCard
