import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';

import { Text } from '../../elements/text';
import Card, { CardContent } from 'material-ui/Card';
import { scaleLinear } from 'd3-scale';
import { max } from 'd3-array';
import PeriodSelect from './period-select';
import { Loading } from '../../elements/loading';

// Translation
import i18n from '../../i18n';

const styles = theme => ({
    card: {
        marginTop: '16px',
        marginRight: '0px',
        flexBasis: '55%',
    },
    contentRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0',
    },
    header: {
        display: 'flex'
    },
    title: {
        flex: 1,
        margin: '10px',
        display: 'flex',
        alignItems: 'center'
    },
    barChart: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    row: {
        width: '100%',
        display: 'flex',
        margin: '10px',
    },
    sensorLabel: {
        flex: '1 0 20%',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        fontWeight: 'bold',
    },
    barBackground: {
        width: '100%',
        backgroundColor: "#ECECEC",
        flex: '1 0 60%',
    },
    amountBar: {
        height: '100%',
        transition: 'width 1s',
    },
    amountValue: {
        flex: '0 0 18%',
        fontSize: '13px',
        paddingLeft: '10px',
        fontWeight: 'bold',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    noData: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '17px',
        color: '#757575',
    }
});

const consumptionColors = ["#343645", "#27477E", "#256EA1", "#478AD8", "#87CFF8"];
const costColors = ["#253C10", "#3A6B12", "#569123", "#6AB32D", "#B4C940"];

class SensorBarChart extends PureComponent {
    state = {
        data: []
    }

    componentWillMount(){
        this.colors = this.props.type === "consumption" ? consumptionColors : costColors;
        this.scale = null;
    }

    componentWillReceiveProps(nextProps){
        if (this.props.type === 'consumption'){
            if (nextProps.data && nextProps.data.length){
                this.setState({
                    data: nextProps.data.map(d => this.createSensorLabel(d)).sort((a, b) => b.consumption - a.consumption)
                });
                this.scale = scaleLinear().domain([0, max(nextProps.data.map(d => d.consumption))]).range([0, 100]);
            }else{
                this.setState({
                    data: []
                });
            }
        }else{
            if (nextProps.data && nextProps.data.length && nextProps.data[0].data && nextProps.data[0].data.length){
                this.setState({
                    data: nextProps.data[0].data.map(d => this.createSensorLabel(d)).sort((a, b) => b.cost - a.cost),
                    currency: nextProps.data[0].currency,
                });
                this.scale = scaleLinear().domain([0, max(nextProps.data[0].data.map(d => d.cost))]).range([0, 100]);
            }else{
                this.setState({
                    data: []
                });
            }
        }
    }

    createSensorLabel = (sensor) => {
        return {
            ...sensor,
            label: `${sensor.sensorId}-${sensor.channel}${sensor.sensorChannelName ? ' - ' + sensor.sensorChannelName : ''}`,
        }
    }

    render() {
        const { classes, valueFormat, selectedPeriod, loading, type } = this.props;
        const { data, currency } = this.state;

        let format = () => ' - ';
        if (data && data.length){
            format = (type === 'consumption') ? valueFormat : value => valueFormat(currency, value);
        }

        const sectionTitle = type === 'consumption' ? i18n.t("Most Consuming - Sensors") : i18n.t("Most Costly - Sensors");

        return (
            <Card className={classes.card}>
                <div className={classes.header}>
                    <Text className={`bar-chart-title ${classes.title}`} bold medium text={sectionTitle} />
                    <PeriodSelect
                        active={selectedPeriod}
                        onChange={this.props.changePeriod}
                    />
                </div>
                <CardContent classes={{root: classes.contentRoot}}>
                    {loading ?
                        <Loading />
                    :
                        <div className={`bar-chart ${classes.barChart}`}>
                            {data && data.length ? data.map((sensor, i) => (
                                <div className={`row ${classes.row}`} key={i}>
                                    <div className={`sensor-label ${classes.sensorLabel}`}>{sensor.label}</div>
                                    <div className={`bar-background ${classes.barBackground}`}>
                                        <div className={`amount-bar ${classes.amountBar}`} style={{width: this.scale(sensor[type]) + '%', backgroundColor: this.colors[i]}} />
                                    </div>
                                    <div className={`amount-value ${classes.amountValue}`}>
                                        {format(sensor[type])}{(type === 'consumption') ? ' ' + i18n.t('kWh') : null}
                                    </div>
                                </div>
                            )): <div className={`no-data ${classes.noData}`}>{i18n.t('There is no data available')}</div>}
                        </div>
                    }
                </CardContent>
            </Card>
        )
    }
}

SensorBarChart.propTypes = {
    type: PropTypes.oneOf(['consumption', 'cost']).isRequired,
    data: PropTypes.array.isRequired,
    valueFormat: PropTypes.func.isRequired,
    changePeriod: PropTypes.func.isRequired,
    selectedPeriod: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
}

export default withStyles(styles)(SensorBarChart);
