import React, {Component} from 'react';

import PropTypes from 'prop-types'

import i18n from '../../../i18n';

import { API } from '../../../components/api';
import { Title } from '../../../elements/title';
import { Button } from '../../../elements/button';
import { Input as VInput } from '../../../elements/forms/input';
import { InputSimpleSelect } from '../../../elements/forms/input-simple-select';
import { InputFile } from '../../../elements/forms/input-file';
import SystemSnackbar from '../../../elements/material-ui/SystemSnackbar';

import './tenant-add-edit.css';

const modes = { add: 'add', edit: 'edit' };

const initialState = {
    tenant: {
        id: null,
        name: "",
        fileToSave: null,
        configuration: {
            primaryColor: '',
            secondaryColor: '',
            showPoweredByVoltaware: true,
        }
    },
    loading: false,
    showSnackBar: false,
    snackbar: {
        type: 'warning',
        message: ''
    },
    fieldsWithError: {},
    markForDeletion: false,
}

class TenantAddEdit extends Component {
    constructor(props) {
        super(props);

        const tenant = {
            ...props.editTenant,
        };

        if (tenant && tenant.id && tenant.configuration){
            tenant.configuration = {
                ...tenant.configuration,
                primaryColor: (tenant.configuration.primaryColor) || '',
                secondaryColor: (tenant.configuration.secondaryColor) || '',
            }
        }

        this.state = {
            ...initialState,
            mode: tenant && tenant.id ? modes.edit : modes.add,
            tenant: tenant && tenant.id ? {...tenant} : {...initialState.tenant}
        }
    }

    changeLogo = async (event) => {
        if (!event.target.files[0]) {
            return this.setState({
                loadingLogo: false,
                tenant: {
                    ...this.state.tenant,
                    fileToSave: null,
                }
            })
        }

        this.setState({ loadingLogo: true })

        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
            this.setState({
                loadingLogo: false,
                tenant: {
                    ...this.state.tenant,
                    fileToSave: (reader && reader.result && reader.result.split(',')[1]) || null,
                }
            })
        };
        reader.onerror = error => {
            console.error(error);
            this.setState({
                loadingLogo: false,
                tenant: {
                    ...this.state.tenant,
                    fileToSave: null,
                },
            });
            this.openSnackBar(i18n.t('An error occurred while selecting the logo image.'), 'error');
        }
    }

    change = ({ target }) => this.setState({
        tenant: {
            ...this.state.tenant,
            [target.name]: target.value,
        }
    });

    changeConfiguration = ({ target }) => this.setState({
        tenant: {
            ...this.state.tenant,
            configuration: {
                ...this.state.tenant.configuration,
                [target.name]: target.name.includes('Color') && target.value
                ? target.value.toUpperCase()
                : target.value,
            }
        }
    });

    changeShowVoltawareLogo = () => {
        const {configuration} = this.state.tenant;

        return this.setState({
            tenant: {
                ...this.state.tenant,
                configuration: {
                    ...configuration,
                    showPoweredByVoltaware: !configuration.showPoweredByVoltaware
                }
            }
        });
    }

    changeDeleteCurrentLogo = () => {
        return this.setState({
            markForDeletion: !this.state.markForDeletion
        });
    }

    isValid = () => {
        const fieldsWithError = {};

        const { name } = this.state.tenant;

        if (!name){
            fieldsWithError.name = i18n.t('Is required');
        }

        this.setState({
            fieldsWithError: fieldsWithError,
        });

        if (Object.keys(fieldsWithError).length){
            this.setState({
                loading: false,
            });
        }

        return !Object.keys(fieldsWithError).length;
    }

    send = async () => {

        try {

            const newTenant = await API.ROOT.TENANTS[this.state.mode.toUpperCase()](this.state.tenant);

            const configuration = {
                ...this.state.tenant.configuration,
                id: (this.state.mode === 'edit') ? this.props.editTenant.id : newTenant.data && newTenant.data.id,
                logo: this.state.tenant.fileToSave ? this.state.tenant.fileToSave : this.props.editTenant.configuration.logo,
            }

            if (this.state.markForDeletion){
                configuration.logo = null;
            }

            await API.ROOT.TENANTS.CONFIGURATION(configuration);

            this.props.openParentSnackBar(this.state.mode === 'edit'
                ? i18n.t('Client saved successfully')
                : i18n.t('Client created successfully')
            , 'success');

            this.setState(initialState);
            this.props.handleCloseModal(true);
        } catch(error) {
            this.setState({ loading: false });
            this.openSnackBar(i18n.t('An error occurred while creating the client.'), 'error');
        };
    }

    submit = (e) => {
        e.preventDefault();
        e.target.querySelector('button[type="submit"]').blur();

        this.setState({
            loading: true,
        });

        if (this.isValid()) {
            this.send()
        }
    }

    openSnackBar = (message, type) => this.setState({
        showSnackBar: true,
        snackbar: {
            message,
            type
        }
    })

    closeSnackBar = () => this.setState({
        showSnackBar: false
    })

    render () {
        const { fieldsWithError, tenant, loading, mode, showSnackBar, snackbar, markForDeletion } = this.state;
        const { configuration } = tenant;

        return (
            <section id="tenant-add-edit">
                <Title
                    element="h4"
                    text={(mode === modes.edit) ? i18n.t('Edit Client') : i18n.t('New Client')}
                />

                <form action="#" onSubmit={this.submit}>
                    <div ref={element => this.formDiv = element} className="form-content">
                        <div className="form-sections">
                            <VInput
                                className="input-name"
                                change={this.change}
                                disabled={loading}
                                hasError={fieldsWithError.name}
                                value={tenant.name}
                                name="name"
                                placeholder={i18n.t("Client name")}
                                type="text"
                                required
                            />
                        </div>
                        <div className="color-section">
                            <div className="form-sections">
                                <div className="description-input">
                                    <VInput
                                        className="input-primary-color"
                                        change={this.changeConfiguration}
                                        disabled={loading}
                                        hasError={fieldsWithError.primaryColor}
                                        value={configuration.primaryColor || ''}
                                        name="primaryColor"
                                        placeholder={i18n.t("Primary color")}
                                        type="text"
                                        required
                                    />
                                </div>
                                <input name="primaryColor" type="color" value={configuration.primaryColor} onChange={this.changeConfiguration} />
                            </div>
                            <div className="form-sections">
                                <div className="description-input">
                                    <VInput
                                        className="input-secondary-color"
                                        change={this.changeConfiguration}
                                        disabled={loading}
                                        hasError={fieldsWithError.secondaryColor}
                                        value={configuration.secondaryColor || ''}
                                        name="secondaryColor"
                                        placeholder={i18n.t("Secondary color")}
                                        type="text"
                                        required
                                    />
                                </div>
                                <input name="secondaryColor" type="color" value={configuration.secondaryColor} onChange={this.changeConfiguration} />
                            </div>
                        </div>
                        <div className="form-sections row">
                            <InputFile
                                className="input-logo"
                                change={this.changeLogo}
                                disabled={loading || markForDeletion}
                                hasError={fieldsWithError.logo}
                                name="logo"
                                placeholder={mode === modes.edit && configuration && configuration.logo ? i18n.t("Logo image to replace") : i18n.t("Logo image")}
                                accept="image/png, image/jpeg"
                                required
                            />

                            {mode === modes.edit && configuration && configuration.logo ?
                                <InputSimpleSelect
                                    className="input-logo-deletion"
                                    change={this.changeDeleteCurrentLogo}
                                    disabled={loading}
                                    name="markForDeletion"
                                    checked={markForDeletion}
                                    label={i18n.t("Remove current logo image")}
                                    type='checkbox'
                                />
                            : null}
                        </div>
                        <div className="form-sections">
                            <InputSimpleSelect
                                className="input-show-voltaware-logo"
                                change={this.changeShowVoltawareLogo}
                                disabled={loading}
                                name="showPoweredByVoltaware"
                                checked={configuration.showPoweredByVoltaware}
                                label={i18n.t('Show Powered by Voltaware logo')}
                                type='checkbox'
                            />
                        </div>
                    </div>
                    <div className="buttons-content">
                        <Button
                            action="preview"
                            label={i18n.t("CANCEL")}
                            click={this.props.handleCloseModal}
                        />

                        <Button
                            type="submit"
                            action="submit"
                            disabled={loading || this.state.loadingLogo}
                            label={(mode === modes.edit) ? i18n.t('Save Client') : i18n.t('Add Client')}
                        />
                    </div>
                </form>

                <SystemSnackbar
                    open={showSnackBar}
                    message={snackbar.message}
                    onClose={this.closeSnackBar}
                    variant={snackbar.type}
                    useFadeTransition={true}
                />
            </section>

        );
    }
}

TenantAddEdit.protoTypes = {
    editTenant: PropTypes.object,
    handleCloseModal: PropTypes.func.isRequired,
    handleOpenModal: PropTypes.func.isRequired,
    openParentSnackBar: PropTypes.func.isRequired,
}

export default TenantAddEdit;
