import React from 'react';

import './input-search-inline.css';


class InputSearchInline extends React.Component {
    render () {
        return (
            this.props.showing && !this.props.loading ?
                <div className="input-search-inline-wrapper">
                    {this.props.data ?
                        this.props.data.map((obj, index) => (
                            <button data-key={obj[this.props.dataKey]} key={index} onMouseDown={this.props.select}>
                                {obj[this.props.dataLabel]}
                            </button>
                        )) : null
                    }
                </div> : null
        );
    }
}

export {InputSearchInline};
