import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import MonthPickerGrid from './MonthPickerGrid';
import './MonthPicker.css';


class MonthPicker extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            transitioning: {
                flag: false,
            },
        };
        this.startDate = props.startDate ? props.startDate : moment();
        this.activeDate = this.startDate.clone();
        this.transitionTime = .2;
        this.calculateDates();
    }

    componentDidMount () {
        document.body.addEventListener('click', this.onOutsideClick);
    }

    componentWillUnmount () {
        document.body.removeEventListener('click', this.onOutsideClick);
        clearTimeout(this.transitionTimer);
    }

    setChildNodeRef = (ref) => {
        this.childNode = ref;
    }

    // Dates Functions
    isMonthDisabled = (month) => month.startOf('day').isAfter(this.maximumDate);

    calculateDates = () => {
        this.maximumDate = moment().add(1, 'day');
        this.firstDayOfYear = this.activeDate.clone().startOf('year');

        this.currentMonths = this.createMonths(this.firstDayOfYear);
        this.previousMonths = this.createMonths(this.firstDayOfYear.clone().subtract(1, 'year'));
        this.futureMonths = this.createMonths(this.firstDayOfYear.clone().add(1, 'year'));
    }

    createMonths = (firstDay) => {
        let month;

        return Array(12).fill().map((_, i) => {
            month = firstDay.clone().add(i, 'months');
            return {
                date: month,
                disabled: this.isMonthDisabled(month),
            };
        });
    }
    // /Dates Functions

    // Handle Events Functions
    handleTransitionEnd = (to) => {
        this.transitionTimer = setTimeout(() => {
            to === 'prev' ? this.activeDate.subtract(1, 'year') : this.activeDate.add(1, 'year');
            this.calculateDates();
            this.setState({
                transitioning: {
                    flag: true,
                    to: '',
                },
            });

            setTimeout(() => {
                this.setState({
                    flag: false,
                });
            }, 10);
        }, this.transitionTime * 1000);
    }

    handleNavClick = (to) => {
        this.setState({
            transitioning: {
                flag: true,
                to: to,
            },
        });
        this.handleTransitionEnd(to);
    }

    onOutsideClick = (e) => {
        const isDescendantOfRoot = this.childNode && this.childNode.contains(e.target);
        if (!isDescendantOfRoot) this.props.onOutsideClick(e);
    }

    handleMonthClick = (month, e) => {
        if (this.props.onDatesChange) this.props.onDatesChange({startDate: month.date});
    }
    // Handle Events Functions

    render () {
        let {startDate} = this.props;
        if (startDate) startDate = startDate.clone().startOf('month');

        return (
            <div className={this.state.transitioning.flag ? `transitioning ${this.state.transitioning.to}` : ''}
                id="MonthPicker"
                ref={this.setChildNodeRef}>
                <div className="header">
                    <button onClick={() => this.handleNavClick('prev')}>
                        <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                            <path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z" />
                        </svg>
                    </button>
                    <button onClick={() => this.handleNavClick('next')}>
                        <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                            <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z" />
                        </svg>
                    </button>
                </div>

                <div className="months-wrapper" style={
                        this.state.transitioning.to ? {transition: `all ${this.transitionTime}s ease-in-out`} : null
                    }>
                    <MonthPickerGrid format={this.props.format}
                        handleMonthClick={this.handleMonthClick}
                        months={this.previousMonths}
                        startDate={startDate} />
                    <MonthPickerGrid format={this.props.format}
                        handleMonthClick={this.handleMonthClick}
                        months={this.currentMonths}
                        startDate={startDate} />
                    <MonthPickerGrid format={this.props.format}
                        handleMonthClick={this.handleMonthClick}
                        months={this.futureMonths}
                        startDate={startDate} />
                </div>
            </div>
        );
    }
}

MonthPicker.propTypes = {
    format: PropTypes.string,
    onDatesChange: PropTypes.func.isRequired,
    onOutsideClick: PropTypes.func,
    startDate: PropTypes.object.isRequired,
};

export default MonthPicker;
