import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import moment from 'moment';

import i18n from '../../i18n';

import './input-time-select.css';

const startHours = ['00:00','00:30','01:00','01:30','02:00','02:30','03:00','03:30','04:00','04:30','05:00','05:30','06:00','06:30','07:00','07:30','08:00','08:30','09:00','09:30','10:00','10:30','11:00','11:30','12:00','12:30','13:00','13:30','14:00','14:30','15:00','15:30','16:00','16:30','17:00','17:30','18:00','18:30','19:00','19:30','20:00','20:30','21:00','21:30','22:00','22:30','23:00','23:30'];
const endHours = ['00:29','00:59','01:29','01:59','02:29','02:59','03:29','03:59','04:29','04:59','05:29','05:59','06:29','06:59','07:29','07:59','08:29','08:59','09:29','09:59','10:29','10:59','11:29','11:59','12:29','12:59','13:29','13:59','14:29','14:59','15:29','15:59','16:29','16:59','17:29','17:59','18:29','18:59','19:29','19:59','20:29','20:59','21:29','21:59','22:29','22:59','23:29','23:59'];

const startHoursOptions = ({ minHour }) => startHours.map(hour => (
    {
        label:  moment(hour, 'HH:mm').format('LT'),
        value: hour,
        disabled: moment(hour, 'HH:mm').isBefore(moment(minHour, 'HH:mm'))
    }
));

const endHoursOptions = ({ minHour }) => endHours.map(hour => (
    {
        label: moment(hour, 'HH:mm').format('LT'),
        value: hour,
        disabled: moment(hour, 'HH:mm').isBefore(moment(minHour, 'HH:mm'))
    }
));

class InputTimeSelect extends React.Component {
    state = {
        options: [],
        label: ''
    }

    componentDidMount = () => {
        let options = [], label = "";
        switch(this.props.type){
            case 'startTime':
                options = startHoursOptions(this.props);
                label = i18n.t('Select start time');
                break;
            case 'endTime':
                options = endHoursOptions(this.props);
                label = i18n.t('Select end time');
                break;
            default:
                options = startHoursOptions(this.props);
                label = i18n.t('Select start time');
        }

        this.setState({
            options,
            label
        });
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.maxHour !== this.props.maxHour || prevProps.minHour !== this.props.minHour) {
            this.setState({
                options: this.props.type === 'startTime'
                    ? startHoursOptions(this.props)
                    : endHoursOptions(this.props)
            })
        }
    }

    render () {
        return (
            <div className="input-time-select">
                <Select
                    name={this.props.name}
                    className={this.props.hasError ? "has-error" : null}
                    value={this.props.time}
                    onChange={this.props.change}
                    valueKey="value"
                    labelKey="label"
                    disabled={this.props.disabled}
                    options={this.state.options}
                    noResultsText={i18n.t('No results found')}
                    placeholder={this.state.label} />
            </div>
        );
    }
}

InputTimeSelect.propTypes = {
    type: PropTypes.oneOf(['startTime', 'endTime']).isRequired,
    name: PropTypes.string.isRequired,
    change: PropTypes.func.isRequired,
    hasError: PropTypes.bool,
    time: PropTypes.object,
    minHour: PropTypes.string,
};

export {InputTimeSelect, startHoursOptions, endHoursOptions};
