import React, {Component} from 'react';

import PropTypes from 'prop-types'

import i18n from '../../i18n';

import { API } from '../../components/api';
import { Title } from '../../elements/title';
import { Button } from '../../elements/button';
import { Input as VInput } from '../../elements/forms/input';
import SystemSnackbar from '../../elements/material-ui/SystemSnackbar';
import { CustomSelect } from '../../elements/forms/custom-react-select';

import './user-add.css';

const lang = JSON.parse(localStorage.getItem('language'));

const modes = { add: 'add', edit: 'edit' };

const authoritiesOptions = () => [
    {
        value: 'ADMIN',
        label: i18n.t('Administrator'),
    },
    {
        value: 'READ_ONLY',
        label: i18n.t('Read-only user'),
    }
];

const enablementOptions = () => [
    {
        value: 'true',
        label: i18n.t('Enabled'),
    },
    {
        value: 'false',
        label: i18n.t('Disabled'),
    },
];

const initialState = {
    user: {
        email: '',
        authority: 'ADMIN',
        enabled: 'true',
        localeCode: lang ? lang : 'en-GB',
    },
    loading: false,
    showSnackBar: false,
    snackbar: {
        type: 'warning',
        message: ''
    },
    fieldsWithError: {},
}

class UserAdd extends Component {
    constructor (props){
        super(props);

        const user = props.editUser;

        this.state = {
            ...initialState,
            mode: user && user.email ? modes.edit : modes.add,
            ...user && user.email ? {
                user: {
                    ...user,
                    email: user.email,
                    enabled: user.enabled + '',
                    localeCode: initialState.user.localeCode,
                }
            } : {}
        }
    }

    changeEmail = ({ target }) => this.setState({
        user: {
            ...this.state.user,
            email: target.value,
        }
    });

    changeAuthority = (option) => {
        if (option && option.value){
            this.setState({
                user: {
                    ...this.state.user,
                    authority: option.value,
                }
            });
        }
    }

    changeEnabled = (option) => {
        if (option && option.value){
            this.setState({
                user: {
                    ...this.state.user,
                    enabled: option.value,
                }
            });
        }
    };

    isValid = () => {
        const fieldsWithError = {};

        const emailReg = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

        const { email, enabled } = this.state.user;

        if (!email){
            fieldsWithError.email = i18n.t('Is required');
        } else if (!emailReg.test(email)){
            fieldsWithError.email = i18n.t('Invalid');
        } else if (enabled !== 'true' && enabled !== 'false'){
            fieldsWithError.enabled = i18n.t('Invalid');
        }

        this.setState({
            fieldsWithError: fieldsWithError,
        });

        if (Object.keys(fieldsWithError).length){
            this.setState({
                loading: false,
            });
        }

        return !Object.keys(fieldsWithError).length;
    }

    send = () => {
        if (this.props.tenantUsersMode){
            API.ROOT.TENANTS.USERS.ADD(this.state.user, this.props.tenant).then(response => {
                this.props.openParentSnackBar(i18n.t('User saved successfully'), 'success');
                this.setState(initialState);
                this.props.handleCloseModal(true);
            }).catch(error => {
                this.setState({ loading: false });
                this.openSnackBar(i18n.t('An error occurred while creating the user.'), 'error');
            });
        }else{
            API.USERS[this.state.mode.toUpperCase()](this.state.user).then(response => {
                this.props.openParentSnackBar(this.state.mode === 'edit'
                    ? i18n.t('User saved successfully')
                    : i18n.t('User created successfully')
                , 'success');

                this.setState(initialState);
                this.props.handleCloseModal(true);
            }).catch(error => {
                this.setState({ loading: false });
                this.openSnackBar(i18n.t('An error occurred while creating the user.'), 'error');
            });
        }
    }

    submit = (e) => {
        e.preventDefault();
        e.target.querySelector('button[type="submit"]').blur();

        this.setState({
            loading: true,
        });

        if (this.isValid()) {
            this.send()
        }
    }

    openSnackBar = (message, type) => this.setState({
        showSnackBar: true,
        snackbar: {
            message,
            type
        }
    })

    closeSnackBar = () => this.setState({
        showSnackBar: false
    })

    render () {
        // const { classes, category } = this.props
        const { fieldsWithError, user, loading, mode, showSnackBar, snackbar } = this.state;

        return (
            <section id="user-add">
                <Title
                    element="h4"
                    text={(mode === modes.edit) ? i18n.t('Edit User') : i18n.t('New User')}
                />

                <form action="#" onSubmit={this.submit}>
                    <div ref={element => this.formDiv = element} className="form-content">
                        <div className="form-sections">
                            <VInput
                                change={this.changeEmail}
                                hasError={fieldsWithError.email}
                                value={user.email}
                                name="email"
                                placeholder={i18n.t("User login e-mail")}
                                type="email"
                                disabled={this.state.mode === 'edit' ? true : false}
                            />

                            {this.state.mode === 'edit' ?
                                <CustomSelect
                                    clearable={false}
                                    name="enabled"
                                    key="enabled"
                                    value={user.enabled}
                                    onChange={this.changeEnabled}
                                    className={fieldsWithError.enabled ? 'error enabled-select' : 'enabled-select'}
                                    hasError={fieldsWithError.enabled}
                                    noLeftMargin={true}
                                    valueKey="value"
                                    labelKey="label"
                                    options={enablementOptions()}
                                />
                            : null}

                            <CustomSelect
                                clearable={false}
                                name="authority"
                                key="authority"
                                value={user.authority}
                                onChange={this.changeAuthority}
                                className={fieldsWithError.authority ? 'error authority-select' : 'authority-select'}
                                hasError={fieldsWithError.authority}
                                disabled={this.props.tenantUsersMode}
                                noLeftMargin={true}
                                valueKey="value"
                                labelKey="label"
                                options={authoritiesOptions()}
                            />
                        </div>
                    </div>
                    <div className="buttons-content">
                        <Button
                            action="preview"
                            label={i18n.t("CANCEL")}
                            click={this.props.handleCloseModal}
                        />

                        <Button
                            type="submit"
                            action="submit"
                            disabled={loading}
                            label={(mode === modes.edit) ? i18n.t('Save User') : i18n.t('Add User')}
                        />
                    </div>
                </form>

                <SystemSnackbar
                    open={showSnackBar}
                    message={snackbar.message}
                    onClose={this.closeSnackBar}
                    variant={snackbar.type}
                />
            </section>

        );
    }
}

UserAdd.protoTypes = {
    editUser: PropTypes.object,
    handleCloseModal: PropTypes.func.isRequired,
    handleOpenModal: PropTypes.func.isRequired,
    openParentSnackBar: PropTypes.func.isRequired,
    tenantUsersMode: PropTypes.bool,
}

export default UserAdd;
