import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import classnames from 'classnames';
import Collapse from 'material-ui/transitions/Collapse';
import ExpandMoreIcon from 'material-ui-icons/ExpandMore';
import IconButton from 'material-ui/IconButton';

import { Title } from '../../elements/title';
import { getSensorStructured } from '../../components/utils';

import i18n from '../../i18n';

import './group-review.css';

const styles = theme => ({
    expand: {
        height: 25,
        width: 25,
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
        marginLeft: 'auto',
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
})

class GroupReview extends PureComponent {
    state = {
        openedSubroup: 0,
    }

    getTariffName = tariffId => {
        if (!tariffId) {
            return ''
        }

        const found = this.props.tariffs.find(({ value }) => value === tariffId)
        if (found) {
            return found.label
        }

        return ''
    }

    getTagName = tag => {
        if (!tag) {
            return ''
        }

        const found = this.props.tags.find(({ value }) => value === tag);
        if (found) {
            return found.label
        }

        return ''
    }

    handleExpandClick = index => this.setState({
        openedSubroup: this.state.openedSubroup === index ? null : index
    })

    render() {
        const { openedSubroup } = this.state;
        const { classes } = this.props
        let { group } = this.props

        group = {
            ...group,
            groupMainSensorsPhases: group && group.groupMainSensorsPhases && group.groupMainSensorsPhases.length
                ? group.groupMainSensorsPhases.filter(s => s.id && s.id > 0)
                    : group.groupMainSensorsPhases,
        }

        return (
            <section id="group-review">
                <Title element="h4" text={i18n.t('Review Details')} />
                <div className="content">
                    <div className="group-info">
                        <Title element="h5" text={group.name} />
                        {!!group.tariffId && (
                            <p>{i18n.t('Tariff:')} <strong>{this.getTariffName(group.tariffId)}</strong></p>
                        )}
                        {!!group.tagId && (
                            <p>{i18n.t('Tag:')} <strong>{this.getTagName(group.tagId)}</strong></p>
                        )}
                        <div className="total-load">
                            <p>{i18n.t('Total Load Sensors:')}</p>
                            <div className="sensors">
                                {!!group.groupMainSensorsPhases && !!group.groupMainSensorsPhases.length ? group.groupMainSensorsPhases.map(sensorPhase => (
                                    <strong key={getSensorStructured(sensorPhase).key}>
                                        {getSensorStructured(sensorPhase).label}
                                    </strong>
                                ))
                                :
                                <p>{i18n.t('No sensors were selected.')}</p>
                                }
                            </div>
                        </div>
                    </div>

                    <Title element="h6" text={i18n.t('Subgroups')} />
                    {!!group.subgroups && group.subgroups.map((subgroup, index) => (
                        <div className="subgroup-container" key={subgroup.name}>
                            <div className="header">
                                <Title element="h5" text={subgroup.name} />
                                <IconButton
                                    className={classnames(classes.expand, {
                                        [classes.expandOpen]: openedSubroup === index,
                                    })}
                                    classes={{root: classes.expandMoreButtonRoot}}
                                    onClick={() => this.handleExpandClick(index)}
                                    aria-expanded={openedSubroup === index}
                                    aria-label="Show more"
                                >
                                    <ExpandMoreIcon />
                                </IconButton>
                            </div>
                            <Collapse in={openedSubroup === index} timeout="auto" unmountOnExit>
                                <div className="subgroup-content">
                                    {!!subgroup.tariffId && (
                                        <p>{i18n.t('Tariff:')} <strong>{this.getTariffName(subgroup.tariffId)}</strong></p>
                                    )}

                                    {!!subgroup.tagsIds && !!subgroup.tagsIds.length && (
                                        <p>{i18n.t('Tag:')} <strong>{this.getTagName(subgroup.tagsIds[0])}</strong></p>
                                    )}

                                    <p className="total-load-title">
                                        {i18n.t('Total Load Sensors')}
                                    </p>
                                    <table className="total-load-table">
                                        <thead>
                                            <tr>
                                                <td>{i18n.t('Total Load Sensor')}</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!!subgroup.mainSensorsPhases && !!subgroup.mainSensorsPhases.length ? subgroup.mainSensorsPhases.map(sensor => (
                                                <tr key={getSensorStructured(sensor).key}>
                                                    <td>
                                                        {getSensorStructured(sensor).key}
                                                    </td>
                                                </tr>
                                            )) : (
                                                <tr>
                                                    <td>{i18n.t('No sensors were selected.')}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>

                                    <p className="subload-title">
                                        {i18n.t('Sub Load Sensors')}
                                    </p>
                                    <table className="subload-table">
                                        <thead>
                                            <tr>
                                                <td>{i18n.t('Sub Load Sensor')}</td>
                                                <td>{i18n.t('Label')}</td>
                                                <td>{i18n.t('Tag')}</td>
                                                <td>{i18n.t('Tariff')}</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!!subgroup.subLoadSensorsPhases &&
                                            !!subgroup.subLoadSensorsPhases.length
                                                ? subgroup.subLoadSensorsPhases.map( sensor => (
                                                    <tr key={getSensorStructured(sensor).key}>
                                                        <td>
                                                            {getSensorStructured(sensor).key}
                                                        </td>
                                                        <td>{sensor.name}</td>
                                                        <td>{!!sensor.tags && !!sensor.tags.length && this.getTagName(sensor.tags[0])}</td>
                                                        <td>{this.getTariffName(sensor.tariffId)}</td>
                                                    </tr>
                                                )) : (
                                                    <tr>
                                                        <td colSpan="4">{i18n.t('No sensors were selected.')}</td>
                                                    </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </Collapse>
                        </div>
                    ))}
                    {(!group.subgroups || (group.subgroups && !group.subgroups.length)) && (
                        <p className="no-subgroups">{i18n.t('No subgroups were created.')}</p>
                    )}
                </div>
            </section>
        );
    }
}

GroupReview.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(styles)(GroupReview);
