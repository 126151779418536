import React from 'react';
import Select from 'react-select';
import { Collapse } from 'material-ui';

import Card, { CardHeader, CardContent } from 'material-ui/Card';
import IconButton from 'material-ui/IconButton';
import ExpandMoreIcon from 'material-ui-icons/ArrowDropDown';
import DeleteIcon from 'material-ui-icons/Delete';
import DatePicker from '../filter-date-picker';

import { Text } from '../../../elements/text';

import i18n from '../../../i18n';

const TariffCompareCard = ({
    changeTariff,
    checkDateAvailability,
    compare,
    dataGranularity,
    history,
    index,
    isLinear,
    obj,
    periodFilter,
    removeCompare,
    tariffs,
    toggleCompareCollapse,
    updateDatePicker,
}) => (
    <Card className={`compare-card ${obj.open ? ' active' : ''}`} style={{ borderColor: obj.color }}>
        <div className="compare-card-wrapper">
            <CardHeader
                action={
                    <div>
                        {compare.length > 1 && (
                            <IconButton
                                onClick={() => removeCompare(index)}
                                style={{height: 24, width: 24}}
                            >
                                <DeleteIcon />
                            </IconButton>
                        )}
                        <IconButton
                            onClick={() => toggleCompareCollapse(index)}
                            style={{height: 24, width: 24}}
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    </div>
                }
                className="compare-card-title"
                title={obj.filterTariff ? obj.filterTariff.name : ''}
            />
            <Collapse
                in={obj.open}
                timeout={300}
                className="compare-card-collapse"
            >
                <CardContent style={{padding: 0, marginBottom: '-13px'}}>
                    <Text text={i18n.t('Tariff')} />
                    <Select
                        clearable={compare.length !== 1}
                        disabled={tariffs.length === 0}
                        labelKey="name"
                        name="tariffFilter"
                        onChange={tariff => changeTariff(tariff, index)}
                        options={tariffs}
                        placeholder="Select any tariff"
                        value={obj.filterTariff}
                        valueKey="id"
                        noResultsText={i18n.t('No results found')}
                    />
                    <div className="tag-divider" />
                    <h5>{i18n.t('Period Range')} <small>{i18n.t('(optional)')}</small></h5>
                    <Text text={i18n.t('Start date')} />
                    <DatePicker
                        chartType="line-chart"
                        dataGranularity={dataGranularity}
                        datePicker={obj.startDate}
                        dateRangePicker={{}}
                        disabled={periodFilter === 'day'}
                        hideTodayButton
                        history={{ push: () => {} }}
                        isLinear={isLinear}
                        isOutsideRange={checkDateAvailability('startDate', index)}
                        loadingGroups={false}
                        periodFilter="day"
                        showLabel={false}
                        updateDatePicker={updateDatePicker('startDate', index)}
                        updateDateRangePicker={() => {}}
                    />
                    <Text text={i18n.t('End date')} />
                    <DatePicker
                        chartType="line-chart"
                        dataGranularity={dataGranularity}
                        datePicker={obj.endDate}
                        dateRangePicker={{}}
                        disabled={periodFilter === 'day'}
                        hideTodayButton
                        history={{ push: () => {} }}
                        isLinear={isLinear}
                        isOutsideRange={checkDateAvailability('endDate', index)}
                        loadingGroups={false}
                        periodFilter="day"
                        showLabel={false}
                        updateDatePicker={updateDatePicker('endDate', index)}
                        updateDateRangePicker={() => {}}
                    />
                </CardContent>
            </Collapse>
        </div>
    </Card>
)

export default TariffCompareCard
