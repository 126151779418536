import React, { PureComponent } from 'react';
import { API } from '../../components/api';
import { Loading } from '../../elements/loading';

import i18n from '../../i18n';

class ReportsPreview extends PureComponent {
    componentDidMount () {
        // Hide elements and adjust styles
        const header = document.querySelector('header'),
              pageWrapper = document.getElementById('page-wrapper');
        header.style.paddingLeft = '20px'
        header.querySelector('button').style.display = 'none';
        header.nextElementSibling.style.display = 'none';
        pageWrapper.style.display = 'flex';
        pageWrapper.style.justifyContent = 'center';

        const reportData = JSON.parse(localStorage.getItem('reportData'));
        localStorage.removeItem('reportData');

        if (!reportData && !this.props.match.params.id) return window.close();

        let apiFn = reportData && reportData.endDate ? API.REPORTS.GENERATE : reportData ? API.REPORTS.LIVE_PREVIEW : API.REPORTS.PREVIEW;

        apiFn(reportData || this.props.match.params.id).then(({ data }) => {
            if (data) {
                const file = new Blob([data], {type: 'application/pdf'});
                const fileURL = URL.createObjectURL(file);
                window.location = fileURL;
            } else this.handleError();
        }).catch(this.handleError);
    }

    handleError = (error) => {
        if (window.opener) {
            if (error) {
                window.opener.console.error(error);
            }

            if (window.opener.openModalSnackBar) {
                window.opener.openModalSnackBar(i18n.t('An error occurred while generating you report.'), 'error');
            } else if (window.opener.openSnackBar) {
                window.opener.openSnackBar(i18n.t('An error occurred while generating you report.'), 'error');
            }
        } else alert(i18n.t('An error occurred while generating you report.'));
        window.close();
    }

    render () {
        return <Loading />
    }
}

export default ReportsPreview;
