import React, { PureComponent } from 'react';
import moment from 'moment';
import { adjustJSONToValidJSON } from '../../../components/utils';
import { Button as VButton } from '../../../elements/button';
import Button from 'material-ui/Button';
import AddIcon from 'material-ui-icons/AddCircleOutline';
import CompareCard from '../../../elements/material-ui/CompareCard';
import TariffCompareCard from './tariff-compare-card';

import i18n from '../../../i18n';

import './tariff-compare.css';

const TARIFFS_LIMIT = 10;

class GroupCompare extends PureComponent {
    state = {
        showGroupSelect: false
    }

    componentDidMount() {
        const ids = this.getIdsJSONFromURL();
        if (this.state.showGroupSelect || ids.some(obj => obj.open)) {
            document.body.addEventListener('click', this.onOutsideCompareClick);
        }
    }

    componentWillUnmount() {
        document.body.removeEventListener('click', this.onOutsideCompareClick);
    }

    goToURL = (url) => this.props.history.push(url)
    getFullURL = (ids) => `${this.props.location.pathname}${this.getIdsToURL(ids)}${this.getSearchURL()}`
    getIdsJSONFromURL = () => {
        const searchParams = new URLSearchParams(window.location.search);
        return searchParams.get('ids') ? JSON.parse(adjustJSONToValidJSON(searchParams.get('ids'))) : [];
    }

    getIdsParsedToURL = (ids) => JSON.stringify(ids).replace(/"/g, '')
    getIdsToURL = (ids) => `?ids=${this.getIdsParsedToURL(ids)}`

    changeTariff = (tariff, index) => {
        const stateCompare = this.props.compare[index];
        this.props.updateReloadData(false)

        if (tariff && tariff.id && stateCompare && ((stateCompare.filterTariff && stateCompare.filterTariff.id !== tariff.id))) {
            let ids = this.getIdsJSONFromURL();
            ids[index] = { id: tariff.id };
            this.goToURL(this.getFullURL(ids));
        }

        if (!tariff) {
            this.props.removeCompare(index)
        }
    }

    changeGroup = (selectedGroup) => {
        if (selectedGroup && selectedGroup.id && (!this.props.filterGroup || this.props.filterGroup.id !== selectedGroup.id)) {

            this.props.updateReloadData(false)
            const path = this.props.location.pathname.replace(this.props.filterGroup.id, selectedGroup.id)
                    .split('subgroup')
                    .shift()
            const ids = this.getIdsToURL(this.getIdsJSONFromURL())

            this.props.history.push(`${path}${ids}${this.getSearchURL()}`);
        }
    }

    changeSubgroup = (selectedSubgroup) => {
        let url = `/analysis/cost/simulation/group/${this.props.filterGroup.id}`;
        const ids = this.getIdsToURL(this.getIdsJSONFromURL())
        if (!selectedSubgroup) {
            this.props.updateReloadData(false)
            this.props.history.push(url + ids + this.getSearchURL());
        } else if (
            selectedSubgroup.id &&
            (!this.props.filterSubgroup || this.props.filterSubgroup.id !== selectedSubgroup.id)
        ) {
            this.props.updateReloadData(false)
            this.props.history.push(url + `/subgroup/${selectedSubgroup.id}${ids}${this.getSearchURL()}`);
        }
    }

    changeSensor = (selectedSensor) => {
        let url = `/analysis/cost/simulation/group/${this.props.filterGroup.id}/subgroup/${this.props.filterSubgroup.id}`;
        const ids = this.getIdsToURL(this.getIdsJSONFromURL())
        if (!selectedSensor) {
            this.props.updateReloadData(false)
            this.props.history.push(url + ids + this.getSearchURL())
        } else if (
            selectedSensor.id &&
            (!this.props.filterSensor || !this.props.filterSensor.id !== selectedSensor.key)
        ) {
            this.props.updateReloadData(false)
            this.props.history.push(url + `/sensor/${selectedSensor.id}/${selectedSensor.channel}/${ids}${this.getSearchURL()}`);
        }
    }

    getSearchURL = () => {
        let url = this.getDateURL();
        url = this.getPeriodFilterURL(false, url);
        if (!this.props.isLinear)
            url += '&step';
        if (this.props.showAlertEvents)
            url += '&alerts';
        return url;
    }

    getPeriodFilterURL = (fromSelect, url, period=this.props.periodFilter, date) => {
        if (fromSelect){
            const searchParams = new URLSearchParams(this.props.location.search);
            url = this.props.location.pathname + `?ids=${searchParams.get('ids')}`;
            url += this.getDateURL(period, date);
        }

        url.indexOf('period') >= 0 ? url = url.replace(/day|week|month|custom/, period) : url += `&period=${period}`;

        if (fromSelect && !this.props.isLinear)
            url += '&step';

        if (fromSelect && this.props.showAlertEvents)
            url += '&alerts';

        if (this.props.level) {
            url += `&level=${this.props.level}`
        }

        if (this.props.old_date) {
            url += `&old_date=${this.props.old_date}`
        }

        return url;
    }

    getDateURL = (period=this.props.periodFilter, date) => {
        date = date ? date : period === 'custom' ? this.props.dateRangePicker : this.props.datePicker;

        if (period === 'custom'){
            let url = "";
            url += date.startDate ?
                `&startDate=${date.startDate.format('YYYY-MM-DD')}` : '';
            url += date.endDate ?
                `&endDate=${date.endDate.format('YYYY-MM-DD')}` : '';
            return url;
        }else{
            return date.startDate ?
                `&date=${date.startDate.format('YYYY-MM-DD')}` : '';
        }
    }

    toggleGroupSelect = () => {
        if (!this.props.noTariffSetupError){
            this.props.updateReloadData(false)
            if (!this.state.showGroupSelect) {
                document.body.addEventListener('click', this.onOutsideCompareClick);
            } else {
                document.body.removeEventListener('click', this.onOutsideCompareClick);
            }
            this.setState({
                showGroupSelect: !this.state.showGroupSelect
            });
        }
    }

    closeCompares = (index, toClose) => {
        let ids = this.getIdsJSONFromURL(window.location.search);
        return ids.map((id, i) => {
            if (i === index && !id.open && !toClose) {
                id.open = true;
            } else delete(id.open);
            return id;
        });
    }

    toggleCompareCollapse = (index, toClose) => {
        this.props.updateReloadData(false)
        let ids = this.closeCompares(index, toClose);
        this.goToURL(this.getFullURL(ids));

        if (!toClose) {
            document.body.addEventListener('click', this.onOutsideCompareClick);
        } else {
            document.body.removeEventListener('click', this.onOutsideCompareClick);
        }
    }

    onOutsideCompareClick = (e) => {
        const groupsEle = document.getElementById('groups')
        this.props.updateReloadData(false)
        if (!groupsEle) {
            return
        }

        const rootElem = groupsEle.children[0];
        const isDescendantOfRoot = rootElem.contains(e.target);
        if (!isDescendantOfRoot) {
            this.setState({
                showGroupSelect: false
            });

            const compareElements = document.getElementsByClassName('one-tariff');
            const selectIndex = this.props.compare.findIndex(obj => obj.open);
            const isDescendant = compareElements[selectIndex] && compareElements[selectIndex].contains(e.target);
            if (!isDescendant) {
                this.toggleCompareCollapse(selectIndex, true)
            }
        }
    }

    updateDatePicker = (type, index) => datePicker => {
        const stateCompare = this.props.compare[index];

        if (
            datePicker &&
            datePicker[type] &&
            stateCompare &&
            (
                !stateCompare[type][type] ||
                !moment(stateCompare[type][type]).isSame(datePicker[type], 'day')
            )
        ) {
            this.props.updateReloadData(false)

            let ids = this.getIdsJSONFromURL();
            ids[index] = {
                id: stateCompare.filterTariff.id,
                open: true,
                ...stateCompare.startDate.startDate
                    ? {startDate: moment(stateCompare.startDate.startDate).format('YYYYMMDD')}
                    : {},
                ...stateCompare.endDate.endDate
                    ? {endDate: moment(stateCompare.endDate.endDate).format('YYYYMMDD')}
                    : {},
                [type]: moment(datePicker[type]).format('YYYYMMDD')
            };

            if (type === 'startDate' && moment(datePicker.startDate).isAfter(moment(stateCompare.endDate.startDate))) {
                delete ids[index].endDate;
            }

            return this.goToURL(this.getFullURL(ids));
        }

        this.props.updateReloadData(false)
        this.props.updateCompare(index, {
            ...this.props.compare[index],
            [type]: datePicker
        });
    }

    checkDateAvailability = (type, index) => date => {
        const compare = this.props.compare[index];
        const range = this.props.dateRangePicker;

        if (this.props.periodFilter === 'custom') {
            return !moment(date).isBetween(
                compare.startDate.startDate.isAfter(range.startDate) && type === 'endDate'
                    ? compare.startDate.startDate
                    : range.startDate,
                compare.endDate.endDate.isBefore(range.endDate) && type === 'startDate'
                    ? compare.endDate.endDate
                    : range.endDate
            );
        }

        const { startDate, endDate } = this.props.datePicker;
        if (this.props.periodFilter === 'month') {
            if (type === 'endDate') {
                return !moment(date).isBetween(
                    compare.startDate && compare.startDate.startDate && moment(compare.startDate.startDate).isAfter(moment(startDate).startOf('month')) && moment(compare.startDate.startDate).isBefore(moment(startDate).endOf('month'))
                        ? compare.startDate.startDate
                        : moment(startDate).startOf('month'),
                    moment(startDate).endOf('month')
                );
            }

            return !moment(date).isBetween(
                moment(startDate).startOf('month'),
                compare.endDate && compare.endDate.endDate && moment(compare.endDate.endDate).isBefore(moment(startDate).endOf('month')) && moment(compare.endDate.endDate).isAfter(moment(startDate).startOf('month'))
                    ? compare.endDate.endDate
                    : moment(startDate).endOf('month')
            );
        }

        if (type === 'startDate') {
            return !moment(date).isBetween(
                startDate,
                compare.endDate.endDate && compare.endDate.endDate.isBefore(endDate)
                    ? compare.endDate.endDate
                    : endDate
            );
        }

        return !moment(date).isBetween(
            compare.startDate.startDate && compare.startDate.startDate.isAfter(startDate)
                ? compare.startDate.startDate
                : startDate,
            endDate
        );
    }

    render() {
        const {
            addCompare,
            compare,
            removeCompare,
            filterGroupOptions,
            tariffs,
            periodFilter,
            dataGranularity,
            isLinear,
            history,
            loading,
            noTariffSetupError,
        } = this.props

        return (
            <div id="group-filters">
                <div className="group-scroll">
                    <div id="groups">
                        <div className={`one-group ${this.state.showGroupSelect ? ' active' : ''}`}>
                            <CompareCard
                                changeGroup={this.changeGroup}
                                changeSensor={this.changeSensor}
                                changeSubgroup={this.changeSubgroup}
                                compare={[]}
                                filterGroupOptions={filterGroupOptions}
                                obj={{
                                    filterGroup: this.props.filterGroup,
                                    filterSubgroup: this.props.filterSubgroup,
                                    filterSensor: this.props.filterSensor,
                                    filterSensorOptions: this.props.filterSensorOptions,
                                    filterSubgroupOptions: this.props.filterSubgroupOptions,
                                    open: this.state.showGroupSelect,
                                    color: '#FFF'
                                }}
                                hideTags={true}
                                toggleCompareCollapse={this.toggleGroupSelect}
                            />
                        </div>
                        <div className="tariff-divider" />
                        {compare.map((obj, index) => (
                            <div className={`one-tariff ${obj.open ? 'active' : ''} ${(index === compare.length - 1) ? ' last' : ''}`} key={index}>
                                <TariffCompareCard
                                    changeTariff={this.changeTariff}
                                    compare={compare}
                                    index={index}
                                    obj={obj}
                                    removeCompare={removeCompare}
                                    tariffs={tariffs}
                                    toggleCompareCollapse={this.toggleCompareCollapse}
                                    periodFilter={periodFilter}
                                    updateDatePicker={this.updateDatePicker}
                                    dataGranularity={dataGranularity}
                                    isLinear={isLinear}
                                    history={history}
                                    checkDateAvailability={this.checkDateAvailability}
                                />
                            </div>
                        ))}
                        <Button
                            className={loading || compare.length >= TARIFFS_LIMIT ? 'compare-add-group disabled' : 'compare-add-group'}
                            onClick={addCompare}
                            disabled={loading || compare.length >= TARIFFS_LIMIT || noTariffSetupError}>
                            <AddIcon />
                            {i18n.t('ADD TARIFF')}
                        </Button>
                        <VButton
                            className="compare-simulate"
                            click={() => this.props.simulateCosts()}
                            disabled={loading}
                            nonLoadingDisabled={!this.props.hadChangeOnFilters || noTariffSetupError}
                            label={i18n.t('SIMULATE COSTS')} />
                    </div>
                </div>
            </div>
        )
    }
}

export {TARIFFS_LIMIT}
export default GroupCompare
