import React, { PureComponent } from 'react';
import { withStyles } from 'material-ui/styles';
import { injectIntl, intlShape } from 'react-intl'
import {format} from 'd3-format';
import moment from 'moment'

import { Title } from '../../elements/title';
import GroupsPieChart from './groups-pie-chart';
import CostSensorBarChart from './sensors-bar-chart';
import TotalCard from './total-card';
import SystemSnackbar from '../../elements/material-ui/SystemSnackbar';
import {API} from '../../components/api';

import i18n from '../../i18n';

const styles = theme => ({
    costsContent: {
        display: 'flex',
        marginBottom: '25px'
    },
    card: {
        flex: 1,
        marginRight: '16px',
        '&:last-child': {
            marginRight: '0px'
        }
    },
    costTotals: {
        display: 'flex'
    }
});

const percentageFormat = (d) => {
    if (Math.abs(d) === 0){
        return format(".0%")(d);
    }else if (Math.abs(d) < 0.01){
        return format(".2%")(d);

    }else if (Math.abs(d) < 0.1){
        return format(".1%")(d);

    }else{
        return format(".0%")(d);
    }
}

class CostSection extends PureComponent {
    state = {
        openMultiCurrencyMsg: false,
        groups: {
            activePeriod: 'day',
            dayCost: {
                loading: true,
                currentTotal: undefined,
                diff: undefined,
                data: undefined,
            },
            weekCost: {
                loading: true,
                currentTotal: undefined,
                diff: undefined,
                data: undefined,
            },
            monthCost: {
                loading: true,
                currentTotal: undefined,
                diff: undefined,
                data: undefined,
            },
            lastDayCost: {
                loading: true,
                currentTotal: undefined,
                diff: undefined,
                data: undefined,
            },
            lastWeekCost: {
                loading: true,
                currentTotal: undefined,
                diff: undefined,
                data: undefined,
            },
            lastMonthCost: {
                loading: true,
                currentTotal: undefined,
                diff: undefined,
                data: undefined,
            },
        },
        sensors: {
            activePeriod: 'day',
            dayCost: {
                loading: true,
                data: [],
            },
            weekCost: {
                loading: true,
                data: [],
            },
            monthCost: {
                loading: true,
                data: [],
            },
        }
    }

    componentDidMount() {
        this.getGroupsCostDayData();
        this.getGroupsCostDayData(moment().subtract(1, 'day').format('YYYY-MM-DD'));
        this.getGroupsCostWeekData();
        this.getGroupsCostWeekData(moment().subtract(1, 'week').format('YYYY-MM-DD'));
        this.getGroupsCostMonthData();
        this.getGroupsCostMonthData(moment().subtract(1, 'month').format('YYYY-MM-DD'));
        this.getSensorsCostDayData();
        this.getSensorsCostWeekData();
        this.getSensorsCostMonthData();
    }

    changeGroupsPeriod = period => this.setState({
        groups: {
            ...this.state.groups,
            activePeriod: period
        }
    })

    changeSensorsPeriod = period => this.setState({
        sensors: {
            ...this.state.sensors,
            activePeriod: period
        }
    })

    getGroupsCostDayData = async (date) => {
        const dayProp = date ? 'lastDayCost' : 'dayCost';

        try {
            const { data } = await API.HOME.COST.GROUPS.DAY(date);
            this.setState({
                openMultiCurrencyMsg: (data.data.length > 1),
                groups: {
                    currency: data.data[0].currency,
                    ...this.state.groups,
                    [dayProp]: {
                        loading: false,
                        currentTotal: data.currentTotalCost[0].value,
                        diff: data.diffCost[0].value,
                        data: data.data[0].data,
                    }
                }
            });
        } catch (error) {
            this.setState({
                groups: {
                    ...this.state.groups,
                    [dayProp]: {
                        loading: false,
                        currentTotal: null,
                        diff: null,
                        data: null,
                    }
                }
            });
        }
    }

    getGroupsCostWeekData = async (date) => {
        const weekProp = date ? 'lastWeekCost' : 'weekCost';

        try {
            const { data } = await API.HOME.COST.GROUPS.WEEK(date);
            this.setState({
                groups: {
                    currency: data.data[0].currency,
                    ...this.state.groups,
                    [weekProp]: {
                        loading: false,
                        currentTotal: data.currentTotalCost[0].value,
                        diff: data.diffCost[0].value,
                        data: data.data[0].data,
                    }
                }
            });
        } catch (error) {
            this.setState({
                groups: {
                    ...this.state.groups,
                    [weekProp]: {
                        loading: false,
                        currentTotal: null,
                        diff: null,
                        data: null,
                    }
                }
            })
        }
    }

    getGroupsCostMonthData = async (date) => {
        const monthProp = date ? 'lastMonthCost' : 'monthCost';

        try {
            const { data } = await API.HOME.COST.GROUPS.MONTH(date);
            this.setState({
                groups: {
                    currency: data.data[0].currency,
                    ...this.state.groups,
                    [monthProp]: {
                        loading: false,
                        currentTotal: data.currentTotalCost[0].value,
                        diff: data.diffCost[0].value,
                        data: data.data[0].data,
                    }
                }
            });
        } catch (error) {
            this.setState({
                groups: {
                    ...this.state.groups,
                    [monthProp]: {
                        loading: false,
                        currentTotal: null,
                        diff: null,
                        data: null,
                    }
                }
            })
        }
    }

    getSensorsCostDayData = async () => {
        try {
            const { data } = await API.HOME.COST.SENSORS.DAY();

            this.setState({
                sensors: {
                    ...this.state.sensors,
                    dayCost: {
                        loading: false,
                        data: data.data,
                    }
                }
            });
        } catch (error) {
            this.setState({
                sensors: {
                    ...this.state.sensors,
                    dayCost: {
                        loading: false,
                        data: null,
                    }
                }
            });
        }
    }

    getSensorsCostWeekData = async () => {
        try {
            const { data } = await API.HOME.COST.SENSORS.WEEK();

            this.setState({
                sensors: {
                    ...this.state.sensors,
                    weekCost: {
                        loading: false,
                        data: data.data,
                    }
                }
            });
        } catch (error) {
            this.setState({
                sensors: {
                    ...this.state.sensors,
                    weekCost: {
                        loading: false,
                        data: null,
                    }
                }
            });
        }
    }

    getSensorsCostMonthData = async () => {
        try {
            const { data } = await API.HOME.COST.SENSORS.MONTH();

            this.setState({
                sensors: {
                    ...this.state.sensors,
                    monthCost: {
                        loading: false,
                        data: data.data,
                    }
                }
            });
        } catch (error) {
            this.setState({
                sensors: {
                    ...this.state.sensors,
                    monthCost: {
                        loading: false,
                        data: null,
                    }
                }
            });
        }
    }

    costFormat = (currency, value) => {
        return currency && value >= 0 ? `${this.props.intl.formatNumber(value, {style: 'currency', currency: currency})}` : ' - '
    }

    render() {
        const { classes } = this.props;
        const { groups, sensors } = this.state;

        return (
            <section id="page-home-cost">
                <Title element="h4" text={i18n.t("Cost")} />
                <div className={classes.costsContent}>
                    <GroupsPieChart
                        type="cost"
                        selectedPeriod={groups.activePeriod}
                        changePeriod={this.changeGroupsPeriod}
                        valueFormat={value => this.costFormat(groups.currency, value)}
                        data={groups[`${groups.activePeriod}Cost`].data}
                        loading={groups[`${groups.activePeriod}Cost`].loading}
                    />
                    <div>
                        <div className={classes.costTotals}>
                            <TotalCard
                                title={i18n.t('Total aggregated cost')}
                                period="DAY"
                                type="cost"
                                loading={groups.dayCost.loading}
                                diff={groups.dayCost.diff}
                                value={groups.dayCost.currentTotal}
                                lastPeriod={groups.lastDayCost}
                                valueFormat={value => this.costFormat(groups.currency, value)}
                                percentageFormat={percentageFormat}
                            />
                            <TotalCard
                                title={i18n.t('Total aggregated cost')}
                                period="WEEK"
                                type="cost"
                                loading={groups.weekCost.loading}
                                diff={groups.weekCost.diff}
                                value={groups.weekCost.currentTotal}
                                lastPeriod={groups.lastWeekCost}
                                valueFormat={value => this.costFormat(groups.currency, value)}
                                percentageFormat={percentageFormat}
                            />
                            <TotalCard
                                title={i18n.t('Total aggregated cost')}
                                period="MONTH"
                                type="cost"
                                loading={groups.monthCost.loading}
                                diff={groups.monthCost.diff}
                                value={groups.monthCost.currentTotal}
                                lastPeriod={groups.lastMonthCost}
                                valueFormat={value => this.costFormat(groups.currency, value)}
                                percentageFormat={percentageFormat}
                            />
                        </div>

                        <CostSensorBarChart
                            data={sensors[`${sensors.activePeriod}Cost`].data}
                            type="cost"
                            valueFormat={this.costFormat}
                            changePeriod={this.changeSensorsPeriod}
                            selectedPeriod={sensors.activePeriod}
                            loading={sensors[`${sensors.activePeriod}Cost`].loading}
                        />
                        <SystemSnackbar
                            variant='info2'
                            open={this.state.openMultiCurrencyMsg}
                            message={i18n.t('Cost data has more than one currency. Visualisations are showing only the most consuming currency.')}
                        />
                    </div>
                </div>
            </section>
        )
    }
}

CostSection.propTypes = {
    intl: intlShape.isRequired,
};

export default withStyles(styles)(injectIntl(CostSection));
