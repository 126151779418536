import React from 'react';

import './input-select.css';


class InputSimpleSelect extends React.Component {
    render () {
        let divClass = 'input-simple-select';
        if (this.props.hasError) divClass += ' error';
        if (this.props.className) divClass += ' ' + this.props.className;

        return (
            <div className={divClass}>
                <label>
                    <input checked={this.props.checked}
                        disabled={this.props.disabled}
                        name={this.props.name}
                        onChange={this.props.change}
                        value={this.props.name}
                        type={this.props.type} />
                    {this.props.label}
                    <span className="check" />
                </label>
            </div>
        );
    }
}

export {InputSimpleSelect};
