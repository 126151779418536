import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import DeleteIcon from 'material-ui-icons/Delete';

import {Input} from '../../elements/forms/input';

import i18n from '../../i18n';

import 'react-select/dist/react-select.css';

class SensorPhase extends React.Component {

    changeSensorPhase = (selectedSensorPhase) => {
        this.props.changeSensorPhase(selectedSensorPhase, this.props.seq);
    }

    changeLabel = (e) => {
        this.props.changeLabel(e, this.props.seq);
    }

    remove = () => {
        this.props.removeSensorPhase(this.props.seq);
    }

    handleTag = tag => {
        this.props.changeSensorTag(tag, this.props.seq);
    }

    handleTariff = tariff => {
        this.props.changeSensorTariff(tariff, this.props.seq);
    }

    render () {
        return (
            <div className="sensor-phase-row">
                <div className="sensor-phase-selection">
                    <Select
                        name="sensor-phase"
                        className={this.props.keyError ? 'has-error sensor-phase-select' : 'sensor-phase-select'}
                        value={(this.props.sensorPhase && this.props.sensorPhase.key !== "") ? this.props.sensorPhase : null}
                        onChange={this.changeSensorPhase}
                        disabled={this.props.loading}
                        isLoading={this.props.loadingSensors}
                        valueKey="key"
                        labelKey="key"
                        clearable={false}
                        options={this.props.availableSensors}
                        placeholder={i18n.t('Sensor-phase')}
                        onBlur={this.props.createNewRowIfNeeded}
                        noResultsText={i18n.t('No results found')}
                      />
                </div>
                {this.props.mode !== 'totalLoad' && (
                    <div className="sensor-phase-label">
                        <Input
                            change={this.changeLabel}
                            disabled={this.props.loading}
                            value={this.props.name}
                            name="label"
                            placeholder={i18n.t('Add a label')}
                            type="text"
                        />
                    </div>
                )}
                {this.props.mode !== 'totalLoad' && (
                    <div className="select-tag-sensor">
                        <Select.Creatable
                            name="tag"
                            value={this.props.sensorPhase.tags && this.props.sensorPhase.tags[0]}
                            onChange={this.handleTag}
                            valueKey="value"
                            labelKey="label"
                            options={this.props.tags}
                            placeholder={i18n.t('Select or create a new Tag')}
                            noResultsText={i18n.t('No results found')}
                        />
                    </div>
                )}
                {this.props.mode !== 'totalLoad' && (
                    <div className="select-tag-sensor">
                        <Select
                            name="tariff"
                            value={this.props.sensorPhase.tariffId}
                            onChange={this.handleTariff}
                            valueKey="value"
                            labelKey="label"
                            options={this.props.tariffs}
                            placeholder={i18n.t('Select a Tariff')}
                            noResultsText={i18n.t('No results found')}
                        />
                    </div>
                )}
                {!this.props.isLast && (
                    <DeleteIcon className="sensor-phase-delete" onClick={this.remove} />
                )}
            </div>
        );
    }
}

SensorPhase.propTypes = {
    seq: PropTypes.number.isRequired
};

export {SensorPhase};
