import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import Button from 'material-ui/Button';
import { injectIntl, intlShape } from 'react-intl'
import Card, { CardHeader } from 'material-ui/Card';
import Dialog, {
  DialogActions,
  DialogContent,
  DialogContentText,
  //DialogTitle,
} from 'material-ui/Dialog';
import Typography from 'material-ui/Typography';
import classnames from 'classnames'
import moment from 'moment'
import Collapse from 'material-ui/transitions/Collapse';
import IconButton from 'material-ui/IconButton';
import DeleteIcon from 'material-ui-icons/Delete';
import MonetizationOn from 'material-ui-icons/MonetizationOn';
import ExpandMoreIcon from 'material-ui-icons/ExpandMore';
import EditIcon from 'material-ui-icons/ModeEdit';
import getSymbolFromCurrency from 'currency-symbol-map';
import {Loading} from '../../elements/loading';
import {API} from '../../components/api';
import i18n from '../../i18n';

const styles = theme => ({
    card: {
        width: '320px',
        marginBottom: '20px'
    },
    cardButton: {
        width: '30px',
        height: '30px',
    },
    headerIcon: {
        color: '#FFF',
        height: '45px',
        width: '45px',
    },
    headerRoot: {
        paddingLeft: '10px',
        backgroundColor: '#284A87'
    },
    headerTitle: {
        color: '#FFF', fontSize: '1.2rem'
    },
    headerSubheader: {
        color: '#FFF', fontSize: '1.1rem'
    },
    headerChargeType: {
        color: '#FFF',
        fontSize: '1.1rem',
        position: 'absolute',
        top: '50px',
        right: '16px',
    },
    deleteConfirm: {
        color: '#6ab42d',
    },
    deleteCancel: {
        color: '#c62828',
    },
    tariffContentWrapper: {
        padding: '10px 0'
    },
    tariffContentList: {
        listStyleType: 'none',
    },
    typographyListItem: {
        fontSize: '1rem',
        fontWeight: 200,
        margin: '4px 10px 0 10px',
        '& b': {
            color: '#000',
            fontWeight: 600,
        }
    },
    peakAndOfPeak: {
        display: 'flex',
        alignItems: 'center',
        color: '#000',
        margin: '4px 10px 0 10px',
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
        marginLeft: 'auto',
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    compositiomContent: {
        padding: '0 0 10px',
        borderBottom: '1px solid #666'
    }
});

class TariffCard extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            dialogOpen: false,
            isDeleting: false,
            expanded: false
        }

        this.costFormat = (currency, value) => {
            const strValue = value + "";
            const decimal = strValue.split('.')[1];
            if (decimal && decimal.length > 2){
                return currency && value >= 0 ? getSymbolFromCurrency(currency) + value : ' - ';
            }else{
                return currency && value >= 0 ? `${this.props.intl.formatNumber(value, {style: 'currency', currency: currency})}` : ' - '
            }
        }

        this.rateFormat = cost => {
            return cost >= 0 ? this.props.intl.formatNumber(cost, {currency: 'USD'}) : ' - ';
        }
    }

    limitTypes = {
        MORE_THAN_OR_EQUAL: i18n.t('More than or equal to')
    }

    handleGroupDeleteClick = () => this.setState({dialogOpen: true})

    handleDialogClose = () => this.setState({dialogOpen: false})

    handleExpandClick = () => {
        setTimeout(this.props.masonryLayout, 300);
        this.setState({ expanded: !this.state.expanded })
    }

    handleDeleteTariffConfirm = id => {
        this.setState({isDeleting: true})

        API.TARIFFS.DELETE(id).then(response => {
            this.props.getTariffs();
            this.handleDialogClose();
            this.props.showSnackBar(i18n.t('Tariff successfully removed.'))
            this.setState({isDeleting: false})
        }).catch(error => {
            console.error(error);

            let message = i18n.t('There was an error removing this tariff');
            const tariffGroupNames = error.response.data.tariffGroupNames;
            if (error.response.status === 400 && tariffGroupNames && tariffGroupNames.length){
                message = i18n.t("Tariff couldn't be deleted because the group(s) {{tariffGroupNames}} use(s) this tariff", {tariffGroupNames: tariffGroupNames.join(', ')});
            }

            this.handleDialogClose();
            this.props.showSnackBar(message)
            this.setState({isDeleting: false})
        });
    }

    render() {
        const { classes, data } = this.props;

        const countryName = this.props.getCountryName(data.isoCountryCode);
        const tariffInfo = [
            {label: i18n.t("Country:"), value: (countryName) ? data.isoCountryCode + ' - ' + countryName : null},
            {label: i18n.t("Standing charge per day:"), value: (data.standingCharge) ? this.costFormat(data.currency, data.standingCharge) : null},
        ];

        const rate = {label: i18n.t("Rate:"), value: (data.offPeakRate) ? this.rateFormat(data.currency, data.offPeakRate) : null};

        if (data.chargeType === "DUAL"){
            tariffInfo.push(
                {label: i18n.t("Off-peak time:"), value: (data.offPeakStartTime && data.offPeakEndTime) ? data.offPeakStartTime + ' - ' + data.offPeakEndTime : null},
                rate,
                {label: i18n.t("Peak time:"), value: (data.peakStartTime && data.peakEndTime) ? data.peakStartTime + ' - ' + data.peakEndTime : null},
                {label: i18n.t("Peak rate:"), value: (data.peakRate) ? data.peakRate + ' ' + i18n.t("kWh/") + data.currency : null},
            );
        }else if(data.chargeType === "SINGLE"){
            tariffInfo.push(rate);
        }

        return (
            <Card className={classes.card}>
                <CardHeader
                    avatar={
                        <MonetizationOn className={classes.headerIcon} />
                    }
                    classes={{
                        root: `${classes.headerRoot} header`,
                        subheader: classes.headerSubheader,
                        title: `${classes.headerTitle} headerTitle`,
                    }}
                    action={localStorage.getItem('authority') === 'ADMIN' && (
                        <div>
                            <IconButton aria-label="Delete"
                                color="inherit"
                                key="delete"
                                className={`${classes.cardButton} delete-button`}
                                onClick={this.handleGroupDeleteClick}>
                                <DeleteIcon style={{color: '#fff'}} />
                            </IconButton>
                            <IconButton aria-label="Edit"
                                color="inherit"
                                key="edit"
                                className={`${classes.cardButton} edit-button`}
                                onClick={() => this.props.openEditTariff(data.id)}>
                                <EditIcon style={{color: '#fff'}} />
                            </IconButton>
                            <Typography variant="title" color="textSecondary" classes={{title: classes.headerChargeType}}>
                                {(data.chargeType) ? data.chargeType.charAt(0) + data.chargeType.slice(1).toLowerCase() : null}
                            </Typography>
                        </div>
                    )}
                    title={data.name}
                    subheader={data.providerName}
                />

                <div id="tariff-info" className={classes.tariffContentWrapper}>
                    <ul className={classes.tariffContentList}>
                        <li>
                            <p className={classes.typographyListItem}>
                                {i18n.t('Rate:')} <b>{data.rate ? `${this.costFormat(data.currency, data.rate)}/${i18n.t('kWh')}`: '-'}</b>
                            </p>
                            <p className={classes.typographyListItem}>
                                {i18n.t('Currency:')} <b>{data.currency ? data.currency : '-'}</b>
                            </p>
                            <p className={classes.typographyListItem}>
                                {i18n.t('Standing charge:')} <b>{data.standingCharge ? `${this.costFormat(data.currency, data.standingCharge)}/${i18n.t('day')}` : '-'}</b>
                            </p>
                        </li>
                    </ul>
                    {!!data.compositions && !!data.compositions.length && (
                        <p className={classes.peakAndOfPeak}>
                            {i18n.t('Peak and Off-Peak Tariffs')}
                            <IconButton
                                className={classnames(classes.expand, {
                                    [classes.expandOpen]: this.state.expanded,
                                })}
                                classes={{root: classes.expandMoreButtonRoot}}
                                disabled={!data.compositions || !data.compositions.length}
                                onClick={this.handleExpandClick}
                                aria-expanded={this.state.expanded}
                                aria-label="Show more">
                                <ExpandMoreIcon />
                            </IconButton>
                        </p>
                    )}
                    <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                        {!!data.compositions && data.compositions.map(composition => (
                            <div className={classes.compositiomContent} key={composition.id}>
                                <p className={classes.typographyListItem}>
                                    {i18n.t('Week day:')} <b>{composition.dayOfWeek ? i18n.t(`${composition.dayOfWeek.slice(0, 1)}${composition.dayOfWeek.slice(1).toLowerCase()}`) : ' -'}</b>
                                </p>
                                <p className={classes.typographyListItem}>
                                    {i18n.t('Start/End time:')}
                                    <b>
                                        {composition.startTime ? moment(composition.startTime, 'HH:mm:ss').format(' LT') : ' --'} -
                                        {composition.endTime ? moment(composition.endTime, 'HH:mm:ss').format(' LT') : ' --'}
                                    </b>
                                </p>
                                <p className={classes.typographyListItem}>
                                    {i18n.t('Rate:')} <b>{this.costFormat(data.currency, composition.rate)}/{i18n.t('kWh')}</b>
                                </p>
                                <p className={classes.typographyListItem}>
                                    {i18n.t('Limit:')}
                                    <b>
                                        {composition.limitType
                                            ? ` ${this.limitTypes[composition.limitType]} ${this.rateFormat(composition.limitValue)} ${i18n.t('kWh')}`
                                            : ' -'
                                        }
                                    </b>
                                </p>
                                <p className={classes.typographyListItem}>
                                    {i18n.t('Standing charge:')}
                                    <b>
                                        {composition.standingCharge
                                            ? ` ${this.costFormat(data.currency, composition.standingCharge)}/${i18n.t('day')}`
                                            : ' -'
                                        }
                                    </b>
                                </p>
                            </div>
                        ))}
                    </Collapse>
                </div>

                <Dialog aria-labelledby="dialog-text"
                    fullWidth={true}
                    maxWidth="xs"
                    open={this.state.dialogOpen}
                    onClose={this.handleDialogClose}>
                    <DialogContent>
                        <DialogContentText id="dialog-text">
                            {this.state.isDeleting
                                ? i18n.t('We are deleting {{tariffName}} tariff, please wait.', {
                                    tariffName: this.props.data.name
                                })
                                : i18n.t('The Tariff will be permanently deleted.')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {this.props.isDeleting ?
                            <div style={{minWidth: 36, width: 36}}><Loading /></div> :
                            <div>
                                <Button classes={{label: classes.deleteConfirm}}
                                    onClick={() => this.handleDeleteTariffConfirm(this.props.data.id)}>
                                    {i18n.t('Yes')}
                                </Button>
                                <Button classes={{label: classes.deleteCancel}} onClick={this.handleDialogClose}>
                                    {i18n.t('Cancel')}
                                </Button>
                            </div>}
                    </DialogActions>
                </Dialog>
            </Card>
        );
    }
}

TariffCard.propTypes = {
    classes: PropTypes.object.isRequired,
    intl: intlShape.isRequired,
};

export default withStyles(styles)(injectIntl(TariffCard));
