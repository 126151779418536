import React from 'react';
import Select from 'react-select';
import { withStyles } from 'material-ui/styles';
import {Input} from '../../elements/forms/input';
import {SensorsPhasesAdd} from './add-sensors-phases';
import {Title} from '../../elements/title';
import { Button as VButton } from '../../elements/button';
import Button from 'material-ui/Button';
import Info from 'material-ui-icons/PriorityHigh';
import Dialog, {
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from 'material-ui/Dialog';

import i18n from '../../i18n';

import './group-form.css';
import './subgroup-form.css';
import 'react-select/dist/react-select.css';

const initialState = {
    openDeleteDialod: false,
    fieldsWithError: [],
    id: null,
    name: '',
    mainSensorsPhases: [],
    subLoadSensorsPhases: [],
    originalSensorsPhasesList: [],
    currentAvailableSensors: [],
    tagId: null,
    tariffId: null,
    receivedAvailableSensors: false,
    shouldUpdateComponent: false,
};

const styles = () => ({
    actionConfirm: {
        color: '#6ab42d',
    },
    actionCancel: {
        color: '#c62828',
    },
});

class SubgroupForm extends React.Component {
    constructor (props) {
        super(props);
        const sessionData = JSON.parse(window.sessionStorage.getItem(`subgroupEdit_${this.props.group.id ? this.props.group.id : 0}_${this.props.subgroupToEdit ? this.props.subgroupToEdit : 0 }`));

        this.noSensorsPhasesLeftMsg = i18n.t('There are no sensors-phases left to add.')

        if (props.subgroup && (!sessionData || (!sessionData.id && props.subgroup.id)) && ((props.subgroup.id && props.subgroup.id > 0) || this.props.subgroupToEdit !== null)){
            this.state = this.loadDataFromProps(props);
        } else if (sessionData) {
            this.state = ({
                ...initialState,
                id: sessionData.id,
                name: sessionData.name,
                tagId: sessionData.tagId || null,
                tariffId: sessionData.tariffId || null,
                mainSensorsPhases: sessionData.mainSensorsPhases,
                subLoadSensorsPhases: sessionData.subLoadSensorsPhases,
                originalMains: sessionData.originalMains,
                originalSubLoads: sessionData.originalSubLoads,
                subLoadsInternalSubgroupId: sessionData.subLoadsInternalSubgroupId,
                currentAvailableSensors: this.selectedAvailableDiff(sessionData.mainSensorsPhases, sessionData.subLoadSensorsPhases),
                originalSensorsPhasesList: props.subgroup
                    ? [
                        ...(props.subgroup.mainSensorsPhases && props.subgroup.mainSensorsPhases.length)
                            ? props.subgroup.mainSensorsPhases
                            : [],
                        ...(props.subgroup.subLoadSensorsPhases && props.subgroup.subLoadSensorsPhases.length)
                            ? props.subgroup.subLoadSensorsPhases
                            : []
                    ].filter(sub => sub) : [],
            });
        } else {
            this.state = initialState;
        }
    }

    componentWillReceiveProps = (nextProps, nextState) => {
        if ((nextProps.subgroup && nextProps.subgroupToEdit !== null && nextProps.subgroupToEdit !== this.props.subgroupToEdit) || (this.state.shouldUpdateComponent && !nextState.shouldUpdateComponent)) {
            this.setState(this.loadDataFromProps(nextProps));
        }

        if (nextProps.availableSensors){
            this.setState({
                receivedAvailableSensors: true,
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.name !== this.state.name ||
            prevState.tariffId !== this.state.tariffId ||
            prevState.tagId !== this.state.tagId ||
            JSON.stringify(this.state.mainSensorsPhases) !== JSON.stringify(prevState.mainSensorsPhases) ||
            JSON.stringify(this.state.subLoadSensorsPhases) !== JSON.stringify(prevState.subLoadSensorsPhases)
        ) {
            window.sessionStorage.setItem(`subgroupEdit_${this.props.group.id ? this.props.group.id : 0}_${this.props.subgroupToEdit ? this.props.subgroupToEdit : 0 }`, JSON.stringify({
                id: this.state.id,
                name: this.state.name,
                tagId: this.state.tagId,
                tariffId: this.state.tariffId,
                originalMains: this.state.originalMains,
                originalSubLoads: this.state.originalSubLoads,
                mainSensorsPhases: this.state.mainSensorsPhases,
                subLoadSensorsPhases: this.state.subLoadSensorsPhases,
                subLoadsInternalSubgroupId: this.state.subLoadsInternalSubgroupId,
            }))
        }

        if (this.state.receivedAvailableSensors){
            this.setState({
                currentAvailableSensors: this.selectedAvailableDiff(this.state.mainSensorsPhases, this.state.subLoadSensorsPhases),
                receivedAvailableSensors: false,
            });
        }

        if (this.props.checkForm) {
            const isValid = this.validate();
            this.props.goToNext(isValid, this.getGroupToSave());
            if (isValid) {
                window.sessionStorage.setItem(`subgroupEdit_${this.props.group.id ? this.props.group.id : 0}_${this.props.subgroupToEdit ? this.props.subgroupToEdit : 0 }`, null)
            }
        }

        if (this.state.shouldUpdateComponent) {
            this.setState(this.loadDataFromProps(this.props));
        }
    }

    loadDataFromProps = (props) => {
        if (!props.subgroup || !props.subgroup.name) {
            return initialState
        }

        let originalSensorsPhasesList = [].concat(props.subgroup.mainSensorsPhases).concat(props.subgroup.subLoadSensorsPhases);
            originalSensorsPhasesList = originalSensorsPhasesList.filter(s => s); //removes undefined elements

            const mainSensorsPhases = (props.subgroup.mainSensorsPhases) ? props.subgroup.mainSensorsPhases : [];
            const subLoadSensorsPhases = (props.subgroup.subLoadSensorsPhases) ? props.subgroup.subLoadSensorsPhases : [];

            return Object.assign({}, initialState,
                {
                    id: props.subgroup.id,
                    name: props.subgroup.name,
                    tagId: props.subgroup.tagsIds ? props.subgroup.tagsIds[0] : null,
                    originalMains: props.subgroup.originalMains,
                    originalSubLoads: props.subgroup.originalSubLoads,
                    tariffId: props.subgroup.tariffId || null,
                    mainSensorsPhases: mainSensorsPhases,
                    subLoadSensorsPhases: subLoadSensorsPhases,
                    currentAvailableSensors: this.selectedAvailableDiff(mainSensorsPhases, subLoadSensorsPhases),
                    originalSensorsPhasesList: originalSensorsPhasesList,
                    subLoadsInternalSubgroupId: props.subgroup.subLoadsInternalSubgroupId,
                }
            );
    }

    handleTag = tag => {
        const found = !!tag && tag.value === tag.label && this.props.tags.find(ele => ele.label.toLowerCase() === tag.label.toLowerCase())

        this.setState({
            tagId: tag ? found ? found.value : tag.value : null
        })

        if (tag && !found && tag.value === tag.label) {
            this.props.addTag(tag)
        }
    }

    changeTariff = tariff => this.setState({
        tariffId: tariff && tariff.value ? tariff.value : null
    })

    changeName = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    updateMainSensorsPhases = (sensorsPhases) => {
        let available = this.selectedAvailableDiff(sensorsPhases, this.state.subLoadSensorsPhases);
        this.setState({
            mainSensorsPhases: sensorsPhases,
            currentAvailableSensors: available,
        });
    }

    updateSubLoadSensorsPhases = (sensorsPhases) => {
        const available = this.selectedAvailableDiff(this.state.mainSensorsPhases, sensorsPhases);
        this.setState({
            subLoadSensorsPhases: sensorsPhases,
            currentAvailableSensors: available,
        });
    }

    validate = () => {
        let fieldsWithError = [];

        if (!this.state.name) {
            fieldsWithError.push('name');
            // this.props.scrollIntoView(this.formDiv);
        }

        let mainSensorsPhases = this.state.mainSensorsPhases.map((sensorPhase) => {
            if (!sensorPhase.key && !sensorPhase.last){
                sensorPhase.keyError = true;
                fieldsWithError.push("main");
            }else if (sensorPhase.last && sensorPhase.name.trim() !== ""){
                sensorPhase.keyError = true;
                fieldsWithError.push("main");
            }else{
                sensorPhase.keyError = false;
            }
            return sensorPhase;
        });

        let subLoadSensorsPhases = this.state.subLoadSensorsPhases.map((sensorPhase) => {
            if (!sensorPhase.key && !sensorPhase.last){
                sensorPhase.keyError = true;
                fieldsWithError.push("subLoad");
            }else if (sensorPhase.last && sensorPhase.name.trim() !== ""){
                sensorPhase.keyError = true;
                fieldsWithError.push("subLoad");
            }else{
                sensorPhase.keyError = false;
            }
            return sensorPhase;
        });

        if (fieldsWithError.length) {
            this.props.openSnackBar(i18n.t('Some inputs are invalid or incomplete. Please correct them and try again.'), 'warning')
        }

        this.setState({
            fieldsWithError: fieldsWithError,
            mainSensorsPhases: mainSensorsPhases,
            subLoadSensorsPhases: subLoadSensorsPhases,
        });

        return !fieldsWithError.length;
    }

    submit = (e) => {
        e.preventDefault()
        if (this.validate())
            this.save()
        // this.validate()
        //     ? this.save()
        //     : this.props.showSnackbarMessage('Please, fill all the required data.');
    }

    removeEmpty = (sensorsPhases) => {
        if (sensorsPhases && sensorsPhases.length > 0){
            return sensorsPhases.filter((sensorPhase) => {
                return sensorPhase.key
            });
        }else{
            return [];
        }
    }

    // Creates a diff between what was available before adding this subgroup and
    // what was selected during the subgroup creation.
    // (GroupAdd's currentAvailableSensors will be updated when the subgroup is
    // saved, that is, this is a local SubgroupAdd diff.)
    selectedAvailableDiff = (mainSensorsPhases, subLoadSensorsPhases) => {
        let availableSensors = this.props.availableSensors.filter((available) => {

            let selectedInMain = false;
            // the sensor was previoulsy selected in the subgroup main, then it is not available
            if (mainSensorsPhases){
                mainSensorsPhases.forEach((sensorPhase) => {
                    if (sensorPhase && sensorPhase.key && sensorPhase.key === available.key)
                        selectedInMain = true;
                });
            }

            let selectedInSubLoad = false;
            // the sensor was previoulsy selected in the subgroup subload sensors, then it is not available
            if (subLoadSensorsPhases){
                subLoadSensorsPhases.forEach((sensorPhase) => {
                    if (sensorPhase && sensorPhase.key && sensorPhase.key === available.key)
                        selectedInSubLoad = true;
                });
            }

            if (selectedInMain || selectedInSubLoad){
                return false;
            }

            // the sensor was not selected, then it is available
            return true;
        });

        if (this.state && this.state.id) {
            // sensors that came selected from the original subgroup on edit screen, but are not selected anymore,
            // are also set as available
            if (this.state.originalSensorsPhasesList && this.state.originalSensorsPhasesList.length > 0){
                let currentSensorsList = []
                if (mainSensorsPhases && mainSensorsPhases.length > 0)
                    currentSensorsList = currentSensorsList.concat(mainSensorsPhases);

                if (subLoadSensorsPhases && subLoadSensorsPhases.length > 0){
                    currentSensorsList = currentSensorsList.concat(subLoadSensorsPhases);
                }
                currentSensorsList = currentSensorsList.filter(s => s.key !== '');//removes undefined elements

                const unselectedSensors = this.state.originalSensorsPhasesList.filter(sensor => {
                    return currentSensorsList.findIndex((s) => s.key === sensor.key) === -1
                });

                availableSensors = availableSensors.concat(unselectedSensors);
            }
        }

        return availableSensors.sort((a,b) => a.id - b.id);
    }

    getGroupToSave = () => {
        const subgroupToSave = {
            id: this.state.id ? this.state.id : null,
            name: this.state.name,
            tagsIds: this.state.tagId ? [this.state.tagId] : null,
            tariffId: this.state.tariffId,
            originalMains: this.state.originalMains,
            originalSubLoads: this.state.originalSubLoads,
            mainSensorsPhases: this.removeEmpty(this.state.mainSensorsPhases),
            subLoadSensorsPhases: this.removeEmpty(this.state.subLoadSensorsPhases),
            subLoadsInternalSubgroupId: this.state.subLoadsInternalSubgroupId ? this.state.subLoadsInternalSubgroupId : null,
        }

        let subgroups;
        if (this.props.subgroupToEdit !== null){
            subgroups = [].concat(this.props.group.subgroups);
            subgroups[this.props.subgroupToEdit] = subgroupToSave;
        }else{
            subgroups = [
                ...this.props.group.subgroups,
                subgroupToSave
            ];
        }

        return {
            ...this.props.group,
            subgroups,
        };
    }

    save = (e) => {
        this.props.saveSubgroup(this.getGroupToSave());
        this.setState(Object.assign({}, initialState));
    }

    handleDeleteModalDialogClose = toDelete => {
        if (toDelete === true) {
            this.props.deleteSubgroup(this.props.subgroupToEdit)
            window.sessionStorage.setItem(`subgroupEdit_${this.props.group.id ? this.props.group.id : 0}_${this.props.subgroupToEdit ? this.props.subgroupToEdit : 0 }`, null)
        }

        this.setState({
            shouldUpdateComponent: !!toDelete,
            openDeleteDialod: false
        })
    }

    deleteSubgroup = () => this.setState({
        openDeleteDialod: true
    })

    render () {
        const { tags, tariffs, classes } = this.props
        const {
            fieldsWithError,
            name,
            tagId,
            tariffId,
            currentAvailableSensors,
            mainSensorsPhases,
            subLoadSensorsPhases
        } = this.state

        return (
            <section id="subgroup-form">
                <Title element="h4" text={i18n.t('Subgroup Configurations')} />
                <form action="#" onSubmit={this.submit}>
                    <div className="content">
                        <div className="single-field">
                            <Input
                                change={this.changeName}
                                hasError={fieldsWithError.indexOf('name') >= 0}
                                value={name}
                                name="name"
                                placeholder={i18n.t('Subgroup Name')}
                                type="text" />
                        </div>

                        <div className="single-field info-control">
                            <Select.Creatable
                                name="tag"
                                value={tagId}
                                onChange={this.handleTag}
                                valueKey="value"
                                labelKey="label"
                                options={[...tags]}
                                placeholder={i18n.t('Tag (Optional)')}
                                noResultsText={i18n.t('No results found')}
                            />
                            <small>
                                <Info />
                                {i18n.t('The selected tags at any subgroup or group will not be listed here. Please remove the tag from its respective entity to be able to assign it for this subgroup.')}
                            </small>
                        </div>

                        <div className="single-field">
                            <Select
                                name="tariff"
                                value={tariffId}
                                onChange={this.changeTariff}
                                // disabled={loadingTariffs}
                                // isLoading={loadingTariffs}
                                valueKey="value"
                                labelKey="label"
                                options={tariffs}
                                placeholder={i18n.t('Tariff (Optional)')}
                                noResultsText={i18n.t('No results found')}
                            />
                        </div>

                        {/* {currentAvailableSensors.length === 0 ?
                            this.props.noSensorsPhasesLeft(true) : null} */}

                        <div>
                            <SensorsPhasesAdd mode="totalLoad"
                                availableSensors={currentAvailableSensors}
                                sensorsPhases={mainSensorsPhases}
                                changeSensorsPhases={this.updateMainSensorsPhases}
                                tariffs={tariffs}
                            />
                        </div>
                        {(!currentAvailableSensors || (currentAvailableSensors && !currentAvailableSensors.length)) &&
                            <div className="info-control no-sensor-left-warning">
                                <small>
                                    <Info />
                                    {this.noSensorsPhasesLeftMsg}
                                </small>
                            </div>
                        }
                        <div>
                            <SensorsPhasesAdd mode="subLoad"
                                availableSensors={currentAvailableSensors}
                                sensorsPhases={subLoadSensorsPhases}
                                changeSensorsPhases={this.updateSubLoadSensorsPhases}
                                tags={this.props.tags}
                                groupTag={this.props.groupTag}
                                subgroupTag={tagId}
                                addTag={this.props.addTag}
                                tariffs={tariffs}
                            />
                        </div>
                        {(!currentAvailableSensors || (currentAvailableSensors && !currentAvailableSensors.length)) &&
                            <div className="info-control no-sensor-left-warning">
                                <small>
                                    <Info />
                                    {this.noSensorsPhasesLeftMsg}
                                </small>
                            </div>
                        }
                        <div className="subgroup-buttons">
                            <VButton
                                type="button"
                                click={this.deleteSubgroup}
                                className="preview cancel"
                                label={i18n.t('Delete subgroup')}
                            />
                            <div id="subgroup-buttons">
                                <VButton
                                    type="submit"
                                    action="submit"
                                    click={this.submit}
                                    // disabled={submitting}
                                    label={i18n.t('Add new subgroup')}
                                />
                            </div>
                        </div>
                    </div>
                </form>
                <Dialog aria-labelledby="dialog-text"
                    fullWidth={true}
                    maxWidth="xs"
                    open={this.state.openDeleteDialod}
                    onClose={this.handleDeleteModalDialogClose}>
                    <DialogTitle>
                        {i18n.t('Are you sure?')}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="dialog-text">
                            {i18n.t('This Subgroup will be permanently deleted.')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button classes={{label: classes.actionConfirm}}
                            onClick={() => this.handleDeleteModalDialogClose(true)}>
                            {i18n.t('Yes, Delete it.')}
                        </Button>
                        <Button classes={{label: classes.actionCancel}} onClick={this.handleDeleteModalDialogClose}>
                            {i18n.t('Cancel')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </section>
        );
    }
}

export default withStyles(styles)(SubgroupForm);
