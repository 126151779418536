import React from 'react';
import { withStyles } from 'material-ui/styles';
import PropTypes from 'prop-types';

import Button from 'material-ui/Button';

import i18n from '../../i18n';

const styles = theme => ({
    content: {
        margin: '10px'
    },
    btnPeriod: {
        color: '#FFF',
        backgroundColor: '#999BA0',
        padding: '4px 16px',
        fontSize: '0.875rem',
        minHeight: 'initial',
        minWidth: 'initial',
        height: 'initial',
        marginLeft: '4px',
        boxSizing: 'border-box',
        transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        fontWeight: '500',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        borderRadius: '2px',
        textTransform: 'uppercase',
        '& span': {
            color: '#FFF',
        },
        '&:hover': {
            backgroundColor: '#323741',
        }
    },
    'active': {
        backgroundColor: '#323741',
    }
});

const PeriodSelect = ({ classes, active, disabled, onChange }) => (
    <div className={`period-select ${classes.content}`}>
        <Button
            className={
                `${classes.btnPeriod} ${active === 'day' ? classes.active : ''}`}
            disabled={disabled}
            onClick={() => onChange('day')}
        >
            {i18n.t('Day')}
        </Button>
        <Button
            className={
                `${classes.btnPeriod} ${active === 'week' ? classes.active : ''}`}
            disabled={disabled}
            onClick={() => onChange('week')}
        >
            {i18n.t('Week')}
        </Button>
        <Button
            className={
                `${classes.btnPeriod} ${active === 'month' ? classes.active : ''}`}
            disabled={disabled}
            onClick={() => onChange('month')}
        >
            {i18n.t('Month')}
        </Button>
    </div>
);

PeriodSelect.propTypes = {
    active: PropTypes.oneOf(['day', 'week', 'month']).isRequired,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired
};

export default withStyles(styles)(PeriodSelect);
