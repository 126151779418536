
import React from 'react';
import Masonry from 'react-masonry-component';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import IconButton from 'material-ui/IconButton';
import Button from 'material-ui/Button';
import CloseIcon from 'material-ui-icons/Close';
import AddIcon from 'material-ui-icons/AddCircleOutline';
import Typography from 'material-ui/Typography';
import Modal from 'material-ui/Modal';
import Snackbar from 'material-ui/Snackbar';
import Dialog, {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from 'material-ui/Dialog';

import {API} from '../../components/api';

import {Loading} from '../../elements/loading';
import GroupCard from './group-card';
import GroudAddStepper from './add-group';

import i18n from '../../i18n';

const styles = theme => ({
    typographyTitle: {
        fontSize: '1rem',
        fontWeight: 600
    },
    paper: {
        position: 'absolute',
        width: '80%',
        height: '100%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: 0,
        '&:focus': {
            outline: 'none'
        },
        overflow: 'hidden',
    },
    snackbarClose: {
        width: theme.spacing.unit * 4,
        height: theme.spacing.unit * 4,
    },
    createGroupButton: {
        borderRadius: '3px',
        display: 'flex',
        alignItems: 'center',
        background: '#FFF',
        color: '#6ab42d',
        position: 'fixed',
        right: '30px',
        top: '20px',
        zIndex: 2000,
        padding: '4px 10px',
        cursor: 'pointer',
    },
    createGroupButtonText: {
        color: '#6ab42d',
        paddingLeft: '4px',
        fontWeight: 'bold',
    },
    deleteConfirm: {
        color: '#6ab42d',
    },
    deleteCancel: {
        color: '#c62828',
    },
});

const masonryOptions = {
    gutter: 20,
    horizontalOrder: true,
};

const modalStyle = {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    maxHeight: '90%',
}

const closeButtonStyle = {
    cursor: 'pointer',
    padding: '10px',
    position: 'absolute',
    right: 0,
    zIndex: 11,
}

class GroupsOverview extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            loading: true,
            groupDelete: {
                data: {},
                loading: false,
            },
            groups: [],
            openGroupModal: false,
            openSubgroup: false,
            groupModalDialogOpen: false,
            editGroupId: null,
            snackbar: {
                message: '',
                open: false,
            },
        };
    }

    componentWillMount () {
        this.getGroups();
    }

    getGroups () {
        API.GROUPS.GET().then((response) => {
            if (response.data){
                this.setState({
                    groups: response.data,
                    loading: false,
                });
            }
        });
    }

    handleOpenModal = (openSubgroup) => {
        (openSubgroup === true) ? this.setState({ openSubgroup: true }) : this.setState({ openGroupModal: true });
    }

    handleCloseModal = (closeAndUpdateGroups) => {
        //closing after adding a group, no dialog needed
        this.setState({ groupModalDialogOpen: closeAndUpdateGroups !== true });
        if (closeAndUpdateGroups === true) {
            const id = this.state.editGroupId ? this.state.editGroupId : 0;
            window.sessionStorage.setItem('unsavedGroup_' + id, null);
            window.sessionStorage.setItem('activeStep_' + id, null);
            window.sessionStorage.setItem('activeSubgroupStep_' + id, null);
            window.sessionStorage.setItem('groupEdit_' + id, null);
            window.sessionStorage.setItem('subgroupEdit_' + id, null);

            this.getGroups();
            this.setState({
                openGroupModal: false,
                editGroupId: null
            });
        //     this.setState({ openGroupModal: false });
        // }else{
        //     if (this.state.openSubgroup){
        //         this.setState({ openSubgroup: false });
        //     }else{
        //         this.setState({ groupModalDialogOpen: true });
        //     }
        }
    }

    handleGroupModalDialogOpen = () => {
        this.setState({groupModalDialogOpen: true});
    }

    handleGroupModalDialogClose = (flag) => {
        const id = this.state.editGroupId ? this.state.editGroupId : 0;
        this.setState({
            openGroupModal: false,
            groupModalDialogOpen: false,
            editGroupId: null
        });

        if (flag !== true) {
            window.sessionStorage.setItem('unsavedGroup_' + id, null);
            window.sessionStorage.setItem('activeStep_' + id, null);
            window.sessionStorage.setItem('activeSubgroupStep_' + id, null);
            window.sessionStorage.setItem('groupEdit_' + id, null);
            window.sessionStorage.setItem('subgroupEdit_' + id, null);
        }
    }

    handleGroupDelete = (group) => {
        this.setState({
            groupDelete: {
                data: group,
                loading: true,
            },
        });

        API.GROUP.DELETE(group.id).then((response) => {
            let groups = this.state.groups;
            groups = groups.filter((item) => item.id !== group.id);

            this.setState({
                groups: groups,
                snackbar: {
                    message: i18n.t('Group successfully removed.'),
                    open: true,
                },
            });
        }).catch((error) => {
            console.error(error);
            this.setState({
                snackbar: {
                    message: i18n.t('There was an error removing this group. Please, try again or contact support.'),
                    open: true,
                },
            });
        });
    }

    groupDeleteReset = () => {
        this.setState({
            groupDelete: {
                data: {},
                loading: false,
            },
        });
    }

    snackbarReset = () => {
        this.setState({
            snackbar: {
                message: '',
                open: false,
            }
        });
    }

    handleSnackbarClose = () => {
        this.groupDeleteReset();
        this.snackbarReset();
    }

    openEditGroup = (groupId) => {
        this.setState({ editGroupId: groupId });
        this.handleOpenModal();
    }

    setMasonryRef = (ref) => this.masonry = ref
    masonryLayout = () => this.masonry.performLayout()

    render () {
        const {classes} = this.props;

        return (
            <div id="groups">
                {localStorage.getItem('authority') === 'ADMIN' && (
                    <div id="new-group" onClick={this.handleOpenModal} className={classes.createGroupButton} >
                        <AddIcon />
                        <Typography variant="body2" classes={{body2: classes.createGroupButtonText}}>
                            {i18n.t('CREATE GROUP')}
                        </Typography>
                    </div>
                )}
                {this.state.loading ?
                    <Loading /> :
                    <Masonry
                        className={'groups-grid'}
                        elementType={'div'}
                        options={masonryOptions}
                        disableImagesLoaded={false}
                        updateOnEachImageLoad={false}
                        ref={this.setMasonryRef}
                    >
                        {(this.state.groups && this.state.groups.length > 0) ?
                            this.state.groups.map((group, i) => (
                                <GroupCard
                                    groupDelete={this.state.groupDelete}
                                    handleGroupDelete={this.handleGroupDelete}
                                    key={group.id}
                                    data={group}
                                    seq={i}
                                    openEditGroup={this.openEditGroup}
                                    masonryLayout={this.masonryLayout}
                                />
                            )) :
                            <Typography variant="title" color="textSecondary" classes={{title: `${classes.typographyTitle} no-groups-title`}}>
                                {i18n.t('No groups available')}
                            </Typography>
                        }
                    </Masonry>
                }
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.openGroupModal}
                    onClose={this.handleCloseModal}
                    disableBackdropClick={true}>

                    <div style={modalStyle} className={classes.paper}>
                        <CloseIcon onClick={this.handleCloseModal} style={closeButtonStyle} />

                        {/* <GroupAdd
                            handleCloseModal={this.handleCloseModal}
                            handleOpenModal={this.handleOpenModal}
                            openSubgroup={this.state.openSubgroup}
                            editGroupId={this.state.editGroupId} />*/}

                        <Dialog aria-labelledby="dialog-text"
                            fullWidth={true}
                            maxWidth="xs"
                            open={this.state.groupModalDialogOpen}
                            onClose={this.handleGroupModalDialogClose}>
                            <DialogTitle>
                                {i18n.t('Do you want to keep editing your data?')}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="dialog-text">
                                    {i18n.t('You can start editing from where you last stopped for as long as you keep your session alive.')}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button id="confirm-leave-dialog" classes={{label: classes.deleteConfirm}}
                                    onClick={() => this.handleGroupModalDialogClose(true)}>
                                    {i18n.t('Yes, Keep it')}
                                </Button>
                                <Button id="cancel-leave-dialog" classes={{label: classes.deleteCancel}} onClick={this.handleGroupModalDialogClose}>
                                    {i18n.t('No')}
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <GroudAddStepper
                            editGroupId={this.state.editGroupId}
                            handleCloseModal={this.handleCloseModal}
                            handleOpenModal={this.handleOpenModal}
                        />
                    </div>
                </Modal>

                <Snackbar action={[
                    <IconButton aria-label="Close"
                        className={classes.snackbarClose}
                        color="inherit"
                        key="close"
                        onClick={this.handleSnackbarClose}>
                        <CloseIcon />
                    </IconButton>,]}
                    anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
                    autoHideDuration={5000}
                    onClose={this.handleSnackbarClose}
                    open={this.state.snackbar.open}
                    message={<span id="snackbar-message-id">{this.state.snackbar.message}</span>}
                    SnackbarContentProps={{'aria-describedby': 'snackbar-message-id'}}
                />
            </div>
        )
    }

}

GroupsOverview.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GroupsOverview);
