import React from 'react';
import d3 from 'd3';
import NVD3Chart from 'react-nvd3';
import moment from 'moment-timezone';
import { injectIntl, intlShape } from 'react-intl'
import { withStyles } from 'material-ui/styles';

import {API} from '../../../components/api';
import {getSensorStructured, isEstimatedData} from '../../../components/utils';
import CompareCard from '../../../elements/material-ui/CompareCard';

// Translation
import i18n from '../../../i18n';

import Button from 'material-ui/Button';
import {Text} from '../../../elements/text';
import {nest} from 'd3-collection';
import {utcDays, utcMonths} from 'd3-time';
import {format} from 'd3-format';
import {sum, max} from 'd3-array';
import {scaleTime, scaleLinear} from 'd3-scale';
import DatePicker from '../filter-date-picker';
import ChartLoading from '../chart-loading';
import SystemSnackbar from '../../../elements/material-ui/SystemSnackbar';
import Table, {TableBody, TableCell, TableHead, TableRow} from 'material-ui/Table';
import Switch from 'material-ui/Switch';

import IconButton from 'material-ui/IconButton';
import ExpandMoreIcon from 'material-ui-icons/ArrowDropDown';

import 'nvd3/build/nv.d3.min.css';
import './stream-graph.css';
import 'react-select/dist/react-select.css';

const yTickFormat = (d) => {
    if (Number.isInteger(d)){
        return format("d")(d);
    }else if(d < 1){
        return format(".2r")(d);
    }else{
        return format(".2f")(d);
    }
}

const kwhFormat = (d) => {
    if (d < 1){
        return format(".2r")(d);
    }else{
        return format(".2f")(d);
    }
}

// category20 color scheme (from D3v3) rearranged for darker first, then lighter
// (instead of dark blue, light blue, dark green, light green...)
const colors = ["#1f77b4","#ff7f0e","#2ca02c","#d62728","#9467bd","#8c564b","#e377c2","#7f7f7f","#bcbd22","#17becf","#aec7e8","#ffbb78","#98df8a","#ff9896","#c5b0d5","#c49c94","#f7b6d2","#c7c7c7","#dbdb8d","#9edae5"];
const parentGroupColor = "#B1C087";

const styles = {
  switchBase: {
    '& + $bar': {
        backgroundColor: 'green',
    },
    '&$checked': {
      '& + $bar': {
        backgroundColor: 'green',
      },
    },
  },
  bar: {},
  checked: {},
};

const daysToMonthGranularity = 92;

class StreamGraph extends React.Component {
    constructor (props) {
        super(props);
        let today = moment();

        this.state = {
            loadingGroups: true,
            loadingData: false,
            loadingTags: true,
            isLinear: true,
            periodFilter: 'day',
            dataGranularity: 'FIVE_MINUTES',
            filterTag: null,
            filterGroup: null,
            filterSubgroup: null,
            filterGroupOptions: [],
            filterSubgroupOptions: [],
            breadcrumbsTrail: '',
            noDataChartMsg: this.loadingChartMessage,
            tags: [],
            breadcrumbsTrails: [],
            tagsMap: {},
            dataLegends: {},
            groups: [],
            xTicks: [],
            xDomain: [],
            data:[],
            parentGroupData: [],
            parentGroupOnly: false,
            total: 0,
            min: {
                consumption: 0,
                cost: 0,
                hour: '00:00:00',
            },
            max: {
                consumption: 0,
                cost: 0,
                hour: '00:00:00',
            },
            average: 0,
            chartMaxConsumption: 0,
            parentGroupMaxConsumption: 0,
            datePicker: {
                open: false,
                startDate: today,
                data: {
                    endDate: today,
                    focusedInput: 'startDate',
                    startDate: today,
                },
            },
            dateRangePicker: {
                open: false,
                endDate: today,
                startDate: today.clone().subtract(2, 'days'),
                data: {
                    endDate: today,
                    focusedInput: 'endDate',
                    startDate: today.clone().subtract(2, 'days'),
                },
            },
            screenWidth: 0,
            screenHeight: 0,
            hadScreenResize: false,
            tooltip: true,
            totalCost: 0,
            currency: "",
            diffOfSelectedDays: 0,
            compare: [{
                filterGroupOptions: [],
                filterSubgroupOptions: [],
                filterSensorOptions: [],
            }]
        };

        this.maximumDate = today.clone().add(1, 'day').endOf('day');
        this.monthsToAdd = -1;
        this.costFormat = (currency, value) => {
            return currency && value > 0 ? `${this.props.intl.formatNumber(value, {style: 'currency', currency: currency})}` : ' - '
        }
    }

    componentWillMount = async () => {
        try {
            const { data } = await API.LABELS.GET()
            if (data) {
                this.setState({
                    tagsMap: data.reduce((prev, cur) => ({ ...prev, [cur.id]: cur.name }), {}),
                    tags: data,
                    loadingTags: false,
                });

            }else{
                this.handleLoadError({loadingTags: false}, i18n.t("Could not load the tags"))
            }
        } catch (error) {
            this.handleLoadError({loadingTags: false}, i18n.t("Could not load the tags"))
        }

        API.GROUPS.GET().then(({ data }) => {
            if (data && data.length > 0){
                this.groupsNamesMap = this.createGroupsNamesMap(data);
                this.sensorsPhasesNamesMap = this.createSensorsPhasesNamesMap(data);
                this.setState({
                    groups: data,
                    breadcrumbsTrails: [],
                    loadingGroups: false,
                    filterGroupOptions: data.map(group => ({name: group.name, id: group.id})),
                }, this.checkGeneralChangesOnComponent);
            }else if (data && data.length === 0){
                this.setState({
                    loadingGroups: false,
                    breadcrumbsTrails: [],
                    noDataChartMsg: this.noGroupChartMessage,
                });
            }else{
                this.handleLoadError({loadingGroups: false}, i18n.t("Could not load groups data"))
            }
        }).catch((error) => {
            this.handleLoadError({loadingGroups: false}, i18n.t("Could not load groups data"), error)
        });

        this.updateDimensions();
    }

    componentDidMount () {
        //TODO review this implementation. Not sure that this.state.tooltip is useful
        let elem = document.querySelector('#chart');
        if (elem)
            elem.addEventListener('click', this.clearTooltip);

        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillReceiveProps (nextProps) {
        this.checkGeneralChangesOnComponent(nextProps);
    }

    componentWillUpdate(nextProps, nextState){
        // screen was resized
        if (nextState.screenWidth !== this.state.screenWidth || nextState.screenHeight !== this.state.screenHeight){
            // remove existing weekend highlight to force redraw
            d3.selectAll('.nv-chart svg .weekend-highlight').remove();
            this.removeParentGroupLayer()
        }
    }

    componentDidUpdate () {
        const breadcrumbsTrail = this.getBreadcrumbsTrail();
        if (this.state.breadcrumbsTrail !== breadcrumbsTrail){
            this.setState({
                breadcrumbsTrail: breadcrumbsTrail
            });
        }

        // fixes tooltip mark that stayed on the chart when changing data
        d3.selectAll('.nvtooltip').remove();

        if (this.state.periodFilter === 'week' || this.state.periodFilter === 'month' ||  (this.state.periodFilter === 'custom' && this.state.diffOfSelectedDays < daysToMonthGranularity)){
            // when in 'custom' period, it avoids executing when selecting start date of the range:
            if (this.state.dateRangePicker.open === false){
                // when coming from (or going to) a parent group only viz, chart's height changes and
                // this timeout is necessary to draw weekend highlight correctly
                setTimeout(this.addWeekEndsHighlight, 1250);
            }
        }

        if (!this.state.loadingData){
            this.resetEstimatesAreas(false);
            this.addParentGroupLayer();
        }

        // verification to force state update and then force the chart redraw of x axis, background lines, etc
        if (this.state.hadScreenResize){
            this.removeEstimatesAreas();

            setTimeout(() => {
              this.setState({hadScreenResize: false});
            }, 1250); // after 1 second to execute it after chart transitions (animation lasts 250 ms)
        }
    }

    componentWillUnmount () {
        window.removeEventListener("resize", this.updateDimensions);
        document.body.removeEventListener('click', this.onOutsideCompareClick);
        clearTimeout(this.onOutsideCompareClickTimer);
    }

    periodOptions = [
        {label: i18n.t('Day'), value: 'day'},
        {label: i18n.t('Week'), value: 'week'},
        {label: i18n.t('Month'), value: 'month'},
        {label: i18n.t('Year'), value: 'year'},
        {label: i18n.t('Custom'), value: 'custom'},
    ];

    periodsText = {
        day: i18n.t('24 Hours'),
        week: i18n.t('1 Week'),
        month: i18n.t('1 Month'),
        custom: i18n.t('Custom'),
    }

    loadErrorMessage = i18n.t("An error occurred while retrieving data");
    loadingChartMessage = i18n.t("Loading data");
    noDataChartMessage = i18n.t("There is no data available for the selected criteria");
    parentOnlyGroupChartMessage = i18n.t("This group has no subgroups");
    parentOnlySensorChartMessage = i18n.t("This subgroup has no sensors");
    noGroupChartMessage = i18n.t("There is no data available as no group has been set up");
    granularities = [
        { label: i18n.t('5 Min'), value: 'FIVE_MINUTES', minutes: 5 },
        { label: i18n.t('30 Min'), value: 'THIRTY_MINUTES', minutes: 30 },
        { label: i18n.t('1 Hour'), value: 'ONE_HOUR', minutes: 60 },
    ];

    // Based on https://stackoverflow.com/a/34475071
    updateDimensions = () => {
        let w = window,
        d = document,
        documentElement = d.documentElement,
        body = d.getElementsByTagName('body')[0],
        width = w.innerWidth || documentElement.clientWidth || body.clientWidth,
        height = w.innerHeight|| documentElement.clientHeight|| body.clientHeight;

        this.setState({
            screenWidth: width,
            screenHeight: height,
            hadScreenResize: true,
        });
    }

    xTickFormat = (d) => {
        let format = '';
        switch (this.state.periodFilter){
            case 'year':
                format = 'MMM';
                break;
            case 'month':
                format = '[LM]';
                break;
            case 'week':
                format = 'ddd DD';
                break;
            case 'day':
                format = 'LT';
                break;
            case 'custom':
                if (this.state.diffOfSelectedDays < 5){
                    format = '[LDDMMHHmm]';
                }else if (this.state.diffOfSelectedDays >= daysToMonthGranularity){
                    format = 'MMM';
                }else{
                    format = '[LM]';
                }
                break;
            default:
                // day format
                format = 'LT';
                console.warn("An invalid x tick format was found. Assuming day x tick format.");
        }
        return moment(d).format(format);
    }

    tooltipHeaderFormatter = (strDate) => {
        if (this.state.periodFilter === 'day') {
            if (this.state.dataGranularity === 'ONE_HOUR')
                return `${moment(strDate).format('LT')} - ${moment(strDate).add(1, 'hour').format('LT')}`

            if (this.state.dataGranularity === 'THIRTY_MINUTES')
                return `${moment(strDate).format('LT')} - ${moment(strDate).add(30, 'minute').format('LT')}`

            if (this.state.dataGranularity === 'FIVE_MINUTES')
                return `${moment(strDate).format('LT')} - ${moment(strDate).add(5, 'minute').format('LT')}`
        }

        if (this.state.periodFilter === 'week') {
            return `${moment(strDate).format('LLLL')} - ${moment(strDate).add(1, 'hour').format('LT')}`
        }


        let format = '';
        switch (this.state.periodFilter) {
            case 'year':
                format = '[LMMMMYYYY]';
                break;
            case 'month':
                format = 'LL';
                break;
            case 'custom':
                if (this.state.diffOfSelectedDays < 30){
                    format = 'LLLL';
                }else if (this.state.diffOfSelectedDays >= daysToMonthGranularity){
                    format = '[LMMMMYYYY]';
                }else{
                    format = 'LL';
                }
                break;
            default:
                format = 'LT';// day format
                console.warn("An invalid period filter was found for tooltip header. Assuming day format.");
        }
        return moment(strDate).format(format);
    }

    getGroupSelectedById = (id) => {
        const group = id ? this.state.groups.find((group) => group.id === id) : this.state.groups[0];
        if (id && !group) {
            if (!this.state.groups[0]) {
                return
            }

            this.props.history.push(`/analysis/consumption/stream/group/${this.state.groups[0].id}`);
            return;
        }

        return {
            filterGroup: group,
            filterSubgroup: null,
            filterSubgroupOptions: this.getGroupSubgroupsOptions(group),
            noDataChartMsg: this.loadingChartMessage
        };
    }

    getTagSelectedById = (id) => {
        const tag = id ? this.state.tags.find((tag) => tag.id === id) : this.state.tags[0];
        if (id && !tag) {
            if (!this.state.tags[0]) {
                return this.props.history.push(`/analysis/consumption/stream/group/${this.state.groups[0].id}`);
            }

            this.props.history.push(`/analysis/consumption/stream/tag/${this.state.tags[0].id}`);
            return;
        }

        return {
            filterTag: tag,
            noDataChartMsg: this.loadingChartMessage
        };
    }

    getSubgroupSelectedById = (id, group) => {
        const subgroup = group.subGroups.find((subgroup) => subgroup.id === id);
        if (id && !subgroup) {
            this.props.history.push(`/analysis/consumption/stream/group/${group.id}/subgroup/${group.subGroups[0].id}`);
            return;
        }

        return {
            filterSubgroup: subgroup,
            subgroupSensor: this.getSubgroupSensors(subgroup),
            noDataChartMsg: this.loadingChartMessage
        };
    }

    checkGeneralChangesOnComponent = (nextProps) => {
        const props = nextProps || this.props;
        if ((!props.match.params.groupId && !props.match.params.tagId) && this.state.groups && this.state.groups.length) {
            this.props.history.push(`${this.props.history.location.pathname}/group/${this.state.groups[0].id}`);
            return;
        }

        let groupData, tagData, subgroupData, startDate, endDate, URLToSearchParams;
        let state = {datePicker: this.state.datePicker, dateRangePicker: this.state.dateRangePicker};

        URLToSearchParams = props.location.search ?
            props.location.search : props.location.pathname.indexOf('?') >= 0 ?
                props.location.pathname.slice(props.location.pathname.indexOf('?')) :
                props.location.pathname;
        const searchParams = new URLSearchParams(URLToSearchParams);

        if (props.match.params.groupId) {
            groupData = this.getGroupSelectedById(parseInt(props.match.params.groupId, 0));
            if (!groupData) return;
            Object.assign(state, groupData);
        }

        if (props.match.params.tagId) {
            tagData = this.getTagSelectedById(parseInt(props.match.params.tagId, 0));
            if (!tagData) return;
            Object.assign(state, tagData);
        }

        if (props.match.params.subgroupId) {
            subgroupData = this.getSubgroupSelectedById(parseInt(props.match.params.subgroupId, 0), state.filterGroup);
            if (!subgroupData) return;
            Object.assign(state, subgroupData);
        }

        state.periodFilter = searchParams.get('period') ?
            searchParams.get('period') : props.location.pathname.indexOf('period=week') >= 0 ? 'week' : 'day';
        if (searchParams.get('date')) {
            startDate = moment(searchParams.get('date'));

            if (state.periodFilter === 'week') {
                startDate = startDate.startOf('week');
                endDate = startDate.clone().endOf('week');
                endDate = endDate.isAfter(this.maximumDate) ? this.maximumDate : endDate;
            } else endDate = startDate;

            state.datePicker.open = false;
            state.datePicker.startDate = startDate;
            state.datePicker.endDate = endDate;
            state.datePicker.data.startDate = startDate;
            state.datePicker.data.endDate = endDate;
        }

        if (searchParams.get('startDate') && searchParams.get('endDate')) {
            startDate = moment(searchParams.get('startDate'));
            endDate = moment(searchParams.get('endDate'));

            state.dateRangePicker.open = false;
            state.dateRangePicker.startDate = startDate;
            state.dateRangePicker.endDate = endDate;
            state.dateRangePicker.data.startDate = startDate;
            state.dateRangePicker.data.endDate = endDate;
            state.diffOfSelectedDays = (startDate && endDate) ? endDate.diff(startDate, 'days') : 0;
        }

        state.isLinear = !searchParams.has('step');
        state.showParent = !searchParams.has('hideParent');
        state.dataGranularity = searchParams.has('dataGranularity') ? searchParams.get('dataGranularity') : 'FIVE_MINUTES';

        this.setState(state);

        this.loadGroupChart(state);
    }

    createGroupsNamesMap = (groups) => {
        const idNamesPairsArray = groups.reduce((acc, group) => {
            acc.push([group.id, group.name]);
            group.subGroups.forEach(sg => {
                sg.subGroups.forEach(ssg => {
                    acc.push([ssg.id, sg.name])
                });
                acc.push([sg.id, sg.name])
            });
            return acc;
        }, [])
        return new Map(idNamesPairsArray);
    }

    // Create a Map that lets get the sensor name from sensor-phase
    createSensorsPhasesNamesMap = (groups) => {
        const sensorPhasesMap = new Map();
        groups.forEach((group) => {
            group.sensors.forEach((sensorPhase) => sensorPhasesMap.set(sensorPhase.id + "-" + sensorPhase.channel, sensorPhase.name));
            group.subGroups.forEach((subgroup) => {
                if (subgroup.sensors && subgroup.sensors.length > 0)
                    subgroup.sensors.forEach((sensorPhase) => sensorPhasesMap.set(sensorPhase.id + "-" + sensorPhase.channel, sensorPhase.name));

                if (subgroup.subGroups && subgroup.subGroups.length > 0){
                    subgroup.subGroups.forEach(innerSubgroup => {
                        if (innerSubgroup.sensors && innerSubgroup.sensors.length > 0)
                            innerSubgroup.sensors.forEach((sensorPhase) => sensorPhasesMap.set(sensorPhase.id + "-" + sensorPhase.channel, sensorPhase.name))
                    });
                }
            })
        });

        return sensorPhasesMap;
    }

    getGroupName = (groupId) => {
        return this.groupsNamesMap.get(+groupId);
    }

    getTagName = (tagId) => {
        return this.tagsMap[tagId];
    }

    getSensorPhaseName = (sensor, phase) => {
        return this.sensorsPhasesNamesMap.get(sensor + '-' + phase);
    }

    getXDomain = () => {
        // this forces the x axis to go from 12AM to 12PM, when period is day (sunday to saturday, when week. Each month day, when month)
        // even if the data is partial for the period
        // NVD3 forceX function only understands it if we use getTime() to get the numeric value for the day object
        let domain = [], date;

        switch (this.state.periodFilter) {
            case 'year':
                const yearStart = this.state.datePicker.startDate.clone().startOf('year');
                const yearEnd = this.state.datePicker.startDate.clone().endOf('year');
                domain = [yearStart.valueOf(), yearEnd.valueOf()];
                break;
            case 'month':
                const monthStart = this.state.datePicker.startDate.clone().startOf('month');
                const monthEnd = this.state.datePicker.startDate.clone().endOf('month');
                domain = [monthStart.valueOf(), monthEnd.valueOf()];
                break;
            case 'week':
                date = this.state.datePicker.startDate.clone().startOf('week');
                domain = [date.valueOf(), date.add(7, 'days').valueOf()];
                break;
            case 'day':
                date = this.state.datePicker.startDate.clone().startOf('day');
                domain = [date.valueOf(), date.add('1', 'days').valueOf()];
                break;
            case 'custom':
                const periodStart = this.state.dateRangePicker.startDate.clone().startOf('day');
                const periodEnd = this.state.dateRangePicker.endDate.clone().endOf('day');
                domain = [periodStart.valueOf(), periodEnd.valueOf()];
                break;
            default:
                // day domain
                date = this.state.datePicker.startDate.clone().startOf('day');
                domain = [date.valueOf(), date.add('1', 'days').valueOf()];
                console.warn("An invalid x domain format was found. Assuming day x domain.");
        }
        return domain;
    }

    getXTicks = () => {
        let xTicks = [];
        switch (this.state.periodFilter) {
            case 'year':
                const yearStart = this.state.datePicker.startDate.clone().startOf('year');
                const yearEnd = this.state.datePicker.startDate.clone().endOf('year');
                xTicks = utcMonths(
                    yearStart,
                    yearEnd,
                ).map((value) => moment(value).valueOf());
                break;
            case 'month':
                const monthStart = this.state.datePicker.startDate.clone().startOf('month');
                const monthEnd = this.state.datePicker.startDate.clone().endOf('month');
                xTicks = utcDays(
                    monthStart,
                    monthEnd,
                ).map((value) => moment(value).valueOf());
                break;
            case 'week':
                let momentDate = this.state.datePicker.startDate.clone();
                xTicks = utcDays(
                    momentDate.startOf('week'),
                    momentDate.clone().add(7, 'days')
                ).map((value) => moment(value).valueOf());
                break;
            case 'day':
            case 'custom':
                xTicks = [];
                break;
            default:
                xTicks = [];
                console.warn("An invalid period filter was found for x ticks. Returning no ticks.");
        }
        return  xTicks;
    }

    isValidChartData = (data) => {
        if (data.length) {
            const targetLength = data[0].values.length
            return data.reduce((acc, current) => {
                return targetLength === current.values.length ? acc && true : acc && false
            });
        } else {
            //it means data is empty, but still valid
            return true;
        }
    }


    // Ensure we have just positive values for the chart
    // This function is necessary because we're receiving negative values from sensors.
    ensurePositiveValues = (data) => data.map((d) => {
        if (d.consumption < 0) d.consumption = 0;
        return d;
    })

    // Get data peak from data removing negative values
    // It may be used for chart data or parent group data.
    // It arranges all slices of data per time to find the stacked peak.
    // This function is necessary because we're receiving negative values from sensors.
    getStackedDataPeak = (data) => {
        const positiveData = data ? this.ensurePositiveValues(data) : [];
        const dataPre = this.createDatetime(positiveData).sort((a, b) => moment(a.time).diff(moment(b.time)));
        const nestedData = nest().key(function(d) { return d.time }).rollup(leaves => sum(leaves, d => d.consumption)).entries(dataPre);
        return max(nestedData, d => d.value);
    }

    loadGroupChart = (state) => {
        d3.selectAll('.nv-chart svg .weekend-highlight').remove();
        this.removeParentGroupLayer();

        this.setState({ loadingData: true, xTicks: [] });
        const groupId = state.filterSubgroup
            ? state.filterSubgroup.id
            : state.filterTag
                ? state.filterTag.id
                : state.filterGroup.id;

        let date = state.datePicker.startDate.format('YYYY-MM-DD');
        let params = [groupId, date];
        let apiFn;
        let dataGranularity = null;
        const type = state.filterTag ? 'LABEL' : 'GROUP';

        switch (state.periodFilter){
            case 'day':
                apiFn = API.VISUALIZATIONS.CONSUMPTION.STREAM.DAY[type];
                dataGranularity = state.dataGranularity;
                params = [groupId, date, dataGranularity];
                break;
            case 'week':
                apiFn = API.VISUALIZATIONS.CONSUMPTION.STREAM.WEEK[type];
                break;
            case 'month':
                apiFn = API.VISUALIZATIONS.CONSUMPTION.STREAM.MONTH[type];
                date = state.datePicker.startDate.format('YYYYMM');
                params = [groupId, date];
                break;
            case 'year':
                apiFn = API.VISUALIZATIONS.CONSUMPTION.STREAM.YEAR[type];
                date = state.datePicker.startDate.format('YYYY');
                params = [groupId, date];
                break;
            case 'custom':
                apiFn = API.VISUALIZATIONS.CONSUMPTION.STREAM.CUSTOM[type];
                const startDate = state.dateRangePicker.startDate.format('YYYY-MM-DD');
                const endDate = state.dateRangePicker.endDate.format('YYYY-MM-DD');
                params = [groupId, startDate, endDate];
                break;
            default:
                apiFn = API.VISUALIZATIONS.CONSUMPTION.STREAM.DAY[type];
                console.warn("An invalid period filter was found. Assuming day period for group load.");
        }

        let toCalculateData;
        if (state.filterSubgroup) {
            toCalculateData = (data) => {
                return this.createSensorData(data)
            }
        } else if (state.filterTag){
            toCalculateData = (data) => {
                return this.createTagData(data)
            }
        } else {
            toCalculateData = (data) => {
                return this.createGroupData(data)
            }
        }

        apiFn(...params).then((response) => {
            if (response.data) {
                const dataPeak = this.getStackedDataPeak(response.data.data);
                const parentDataPeak = this.getStackedDataPeak(response.data.backgroundData);

                response.data.data = response.data.data ? this.ensurePositiveValues(response.data.data) : [];
                response.data.backgroundData = response.data.backgroundData ? this.ensurePositiveValues(response.data.backgroundData) : [];

                let calculatedData = toCalculateData(response.data);
                let parentGroupOnly = false;
                if (calculatedData.length === 0 && response.data.backgroundData && response.data.backgroundData.length){
                    calculatedData = [{"key" : i18n.t("Empty data"),"values" : [[]]}];
                    parentGroupOnly = true;
                }

                if (this.isValidChartData(calculatedData)) {
                    let min = response.data.minDayAndHourConsumption ||
                        response.data.minHourConsumption || response.data.minDayConsumption;
                    if (min && min.consumption < 0) min.consumption = 0;

                    const state = {
                        loadingData: false,
                        data: calculatedData,
                        breadcrumbsTrail: this.getBreadcrumbsTrail(),
                        total: response.data.totalConsumption,
                        totalCost: response.data.totalCost,
                        currency: (response.data.tariffs && response.data.tariffs.length > 0) ? response.data.tariffs[0].currency : null,
                        average: response.data.avgConsumption,
                        noDataChartMsg: this.noDataChartMessage,
                        max: response.data.maxDayAndHourConsumption || response.data.maxHourConsumption || response.data.maxDayConsumption,
                        min: min,
                        parentGroupData: response.data.backgroundData,
                        parentGroupMaxConsumption: (response.data.backgroundData && response.data.backgroundData.length) ? max(response.data.backgroundData, d => d.consumption) : null,
                        parentGroupOnly: parentGroupOnly,
                        xDomain: this.getXDomain(),
                        xTicks: this.getXTicks(),
                    }
                    this.setState({
                        ...state,
                        chartMaxConsumption: max([dataPeak, parentDataPeak]),
                    });
                } else {
                    this.handleLoadError({loadingData: false, noDataChartMsg: this.loadErrorMessage, data: []}, i18n.t("Entries values length are not equal"))
                }

            }else{
                this.handleLoadError({loadingData: false}, i18n.t("Could not load chart data"))
            }
        }).catch((error) => {
            this.handleLoadError({loadingData: false}, i18n.t("Could not load chart data"), error)
        });
    }

    getGroupSubgroupsOptions = (group) => {
        if (group.subGroups && group.subGroups.length > 0){
            return group.subGroups.map(subgroup => {
                return {
                    groupId: group.id,
                    name: subgroup.name,
                    id: subgroup.id
                }
            });
        }else{
            return [];
        }
    }

    getSubgroupSensors = (subgroup) => {
        // non main subgroups sensors are stored in a "artificial" sub-subgroup
        if (subgroup.subGroups && subgroup.subGroups[0]
            && subgroup.subGroups[0].sensors
            && subgroup.subGroups[0].sensors.length){

            return subgroup.subGroups[0].sensors.map(sensor => {
                return {...getSensorStructured(sensor), subgroupId: subgroup.subGroups[0].id} });
        }else{
            return [];
        }
    }

    getSearchURL = () => {
        let url = this.getDateURL();
        url = this.getPeriodFilterURL(false, url)
        if (!this.state.isLinear)
            url += '&step';
        if (!this.state.showParent)
            url += '&hideParent';
        return url;
    }

    getPeriodFilterURL = (fromSelect, url, period=this.state.periodFilter) => {
        if (fromSelect){
            url = this.props.location.pathname + this.getDateURL(period) + `&period=${period}`;
        }
        if (url.indexOf('?') < 0) url += `?period=${period}`;
        else {
            url.indexOf('period') >= 0 ? url = url.replace(/day|week|month|custom/, period) : url += `&period=${period}`;
        }

        if (!this.state.isLinear) url += '&step';
        if (!this.state.showParent) url += '&hideParent';
        if (period === 'day'){
            url += '&dataGranularity=';
            url += (this.state.dataGranularity) ? this.state.dataGranularity : 'FIVE_MINUTES';
        }

        return url;
    }

    getDateURL = (period=this.state.periodFilter) => {
        if (period === 'custom'){
            let url = "";
            url += this.state.dateRangePicker.startDate ?
                `?startDate=${this.state.dateRangePicker.startDate.format('YYYY-MM-DD')}` : '';
            url += this.state.dateRangePicker.endDate ?
                `&endDate=${this.state.dateRangePicker.endDate.format('YYYY-MM-DD')}` : '';
            return url;
        }else{
            return this.state.datePicker.startDate ?
                `?date=${this.state.datePicker.startDate.format('YYYY-MM-DD')}` : '';
        }
    }

    changeGroup = (selectedGroup) => {
        this.setState({ filterTag: null })
        if (selectedGroup && selectedGroup.id && (!this.state.filterGroup || this.state.filterGroup.id !== selectedGroup.id)) {
            const path = !!this.state.filterGroup
                ? this.props.location.pathname.replace(this.state.filterGroup.id, selectedGroup.id)
                : !!this.state.filterTag
                    ? this.props.location.pathname.replace(`tag/${this.state.filterTag.id}`, `group/${selectedGroup.id}`)
                    : this.props.location.pathname

            this.props.history.push(`${path.split('subgroup').shift()}${this.getSearchURL()}`);
        }
    }

    changeTag = (selectedTag) => {
        this.setState({ filterGroup: null, filterSubgroup: null })

        if (selectedTag && selectedTag.id && (!this.state.filterTag || this.state.filterTag.id !== selectedTag.id)) {
            const path = !!this.state.filterTag
                ? this.props.location.pathname.replace(this.state.filterTag.id, selectedTag.id)
                : !!this.state.filterGroup
                    ? this.props.location.pathname.replace(`group/${this.state.filterGroup.id}`, `tag/${selectedTag.id}`)
                    : this.props.location.pathname

            this.props.history.push(`${path.split('subgroup').shift()}${this.getSearchURL()}`);
        }
    }

    changeSubgroup = (selectedSubgroup) => {
        let url = `/analysis/consumption/stream/group/${this.state.filterGroup.id}`;
        if (!selectedSubgroup) this.props.history.push(url + this.getSearchURL());
        else if (
            selectedSubgroup.id &&
            (!this.state.filterSubgroup || this.state.filterSubgroup.id !== selectedSubgroup.id)
        ) this.props.history.push(url + `/subgroup/${selectedSubgroup.id}${this.getSearchURL()}`);
    }

    getBreadcrumbsTrail = () => {
        const filterGroup = this.state.filterGroup;
        const filterSubgroup = this.state.filterSubgroup;
        const filterTag = this.state.filterTag;

        let trail = (filterGroup && filterGroup.name) ? filterGroup.name : null;
        if (filterSubgroup && filterSubgroup.name) trail += ' / ' + filterSubgroup.name;
        if (filterTag && filterTag.name) trail = '# ' + filterTag.name;

        return trail;
    }

    getLabelName = id => {
        const { groups } = this.state

        const foundGroup = groups.find(
            group => id === `${group.id}` || (group.subGroups && group.subGroups.find(
                subgroup => `${subgroup.id}` === id || (subgroup.subGroups && subgroup.subGroups.find(
                    subsubgroup =>
                        subsubgroup && subsubgroup.sensors && subsubgroup.sensors.find(sensor => `${sensor.id}-${sensor.channel}` === id)
                ))
            ))
        )

        const foundSubGroup = foundGroup && foundGroup.subGroups && foundGroup.subGroups.find(
            subgroup => `${subgroup.id}` === id || (subgroup.subGroups && subgroup.subGroups.find(subsubgroup =>
                subsubgroup.sensors && subsubgroup.sensors.find(sensor => `${sensor.id}-${sensor.channel}` === id)
            ))
        )

        const foundSubSubGroup = foundSubGroup && foundSubGroup.subGroups && foundSubGroup.subGroups.find(subsubgroup =>
            subsubgroup.sensors && subsubgroup.sensors.find(sensor => `${sensor.id}-${sensor.channel}` === id)
        )

        const foundSensor = foundSubSubGroup && foundSubSubGroup.sensors && foundSubSubGroup.sensors.find(
            sensor => `${sensor.id}-${sensor.channel}` === id
        )

        if (!foundGroup) {
            return id
        }

        if (foundGroup && foundSubGroup && foundSensor) {
            return `${foundGroup.name} / ${foundSubGroup.name} / ${getSensorStructured(foundSensor).label}`
        }

        if (foundGroup && foundSubGroup) {
            return `${foundGroup.name} / ${foundSubGroup.name}`
        }

        return foundGroup.name
    }

    createDatetime = (data) => {
        return data.map((d) => {
            return {
                ...d,
                time: this.state.periodFilter === 'year' ? moment(d.month, 'YYYYMM').toDate() : moment(this.state.periodFilter !== 'month' ? d.dayTz + "T" + d.hour : d.dayTz + "T00:00:00").toDate(),
            }
        });
    }

    createSensorPhase = (data) => data.map((d) => {
        d.name = this.getSensorPhaseName(d.sensorId, d.channel);
        return {
            ...d,
            ...getSensorStructured(d),
        }
    })

    /* Creates chart data for groups and subgroups */
    createGroupData = (groupData) => {
        let groupEntries = [];

        // Creates group data for the chart to draw
        if (groupData.data && groupData.data.length > 1){
            const groupPre = this.createDatetime(groupData.data).sort((a, b) => moment(a.time).diff(moment(b.time)));

            // Summarizes (rolls-up) consumption grouping by same time (same date and hour)
            let rollupPerHour;
            rollupPerHour = nest()
                .key(function(d) { return d.time }) // groups by time
                .key(function(d) { return d.groupId }); // groups by groupId

            if (this.state.periodFilter === 'day') {
                rollupPerHour = rollupPerHour.sortKeys(d3.ascending); // orders by time
            }

            rollupPerHour = rollupPerHour.rollup((d) => {
                return {
                    consumption: sum(d, function(d) { return d.consumption }),
                    aggregationType: d.reduce((acc, d) => {
                        if (acc !== 'HYBRID'){
                            if (d.aggregationType === 'ESTIMATED' && acc === '')
                                acc = 'ESTIMATED';
                            else if(d.aggregationType === 'ESTIMATED' && acc !== 'ESTIMATED')
                                acc = 'HYBRID';
                            if (d.aggregationType === 'HYBRID' || d.aggregationType === 'REAL')
                                acc = d.aggregationType;
                        }
                        return acc;
                    }, ''),
                }
            }) // sums consumption
                .entries(groupPre); // get key/values
            let group = [];
            rollupPerHour.forEach(d => {
                d.values.forEach(v => {
                    group.push({
                        time: moment(d.key).toDate(),
                        name: this.getGroupName(v.key),
                        consumption: v.value.consumption,
                        aggregationType: v.value.aggregationType,
                    });
                });
            });
            groupEntries = nest().key(function(d) { return d.name; }).entries(group);
        }
        return groupEntries;
    }

    /* Creates chart data for tags */
    createTagData = (tagData) => {
        let tagEntries = [];

        // Creates tag data for the chart to draw
        if (tagData.data && tagData.data.length > 1){
            const tagPre = this.createDatetime(tagData.data).sort((a, b) => moment(a.time).diff(moment(b.time)));

            // Summarizes (rolls-up) consumption grouping by same time (same date and hour)
            let rollupPerHour;
            rollupPerHour = nest()
                .key(function(d) { return d.time }) // groups by time
                .key(function(d) { return d.sensorId ? `${d.sensorId}-${d.channel}` : d.groupId }); // groups by groupId

            if (this.state.periodFilter === 'day') {
                rollupPerHour = rollupPerHour.sortKeys(d3.ascending); // orders by time
            }

            rollupPerHour = rollupPerHour.rollup((d) => {
                return {
                    consumption: sum(d, function(d) { return d.consumption }),
                    aggregationType: d.reduce((acc, d) => {
                        if (acc !== 'HYBRID'){
                            if (d.aggregationType === 'ESTIMATED' && acc === '')
                                acc = 'ESTIMATED';
                            else if(d.aggregationType === 'ESTIMATED' && acc !== 'ESTIMATED')
                                acc = 'HYBRID';
                            if (d.aggregationType === 'HYBRID' || d.aggregationType === 'REAL')
                                acc = d.aggregationType;
                        }
                        return acc;
                    }, ''),
                }
            }) // sums consumption
                .entries(tagPre); // get key/values

            let tags = [];
            let breadcrumbsTrails = [];
            let dataLegends = {};
            rollupPerHour.forEach(d => {
                d.values.forEach(v => {
                    const sensor = v.key.split('-')
                    breadcrumbsTrails.push(this.getLabelName(v.key))
                    dataLegends[v.key] = this.getGroupName(v.key) || this.getSensorPhaseName(sensor[0], sensor[1])
                    tags.push({
                        time: moment(d.key).toDate(),
                        key: v.key,
                        name: this.getGroupName(v.key) || this.getSensorPhaseName(sensor[0], sensor[1]),
                        consumption: v.value.consumption,
                        aggregationType: v.value.aggregationType,
                    });
                });
            });

            this.setState({ breadcrumbsTrails, dataLegends })
            tagEntries = nest().key((d) => d.key).entries(tags);
        }
        return tagEntries;
    }

    createSensorData = (sensorData) => {
        if (sensorData.data && sensorData.data.length) {
            let sensorsDataPre = this.createDatetime(sensorData.data).sort((a, b) => moment(a.time).diff(moment(b.time)));
            sensorsDataPre = this.createSensorPhase(sensorsDataPre); // creates sensor-phase, e.g.: 3031-1, 3031-2, 3031-3
            const entries = nest().key(function(d) { return d.label; }).entries(sensorsDataPre);
            return entries;
        }
        return [];
    }


    changePeriodFilter = (period) => {
        let url = this.getPeriodFilterURL(true, '', period ? period.value : 'day');
        this.props.history.push(url);
    }

    toggleLinear = () => {
        this.removeParentGroupLayer();
        let searchParams = new URLSearchParams(window.location.search),
            url = this.getPeriodFilterURL(false, this.getDateURL());
        url = searchParams.has('step') ? url.replace('&step', '') : `${url}&step`;
        this.props.history.push(url);
    }

    toggleParent = () => {
        this.removeParentGroupLayer();
        let searchParams = new URLSearchParams(window.location.search),
            url = this.getPeriodFilterURL(false, this.getDateURL());
        url = searchParams.has('hideParent') ? url.replace('&hideParent', '') : `${url}&hideParent`;
        this.props.history.push(url);
    }

    handleLoadError = (state, message, error) => {
        state.loadErrorMessage = this.loadErrorMessage;
        this.setState(state);

        console.error(this.loadErrorMessage + " (" + message + ")");
        if (error)
            console.error(error);
    }


    // Date period select functions
    updateDateRangePicker = (dateRangePicker, removeTooltip) => {
        this.setState({dateRangePicker});
        if (removeTooltip)
            d3.selectAll('.nvtooltip').remove();
    }

    updateDatePicker = (datePicker, removeTooltip) => {
        this.setState({datePicker});
        if (removeTooltip)
            d3.selectAll('.nvtooltip').remove();
    }
    // /Date period select functions

    getStackedLayout = (data) => {
        return d3.layout.stack()
           .offset('zero')
           .values(function(d) { return d.values })
           .x(function(d) { return d.time })
           .y(function(d) { return d.consumption })
           .out(function(d, y0, y) {
               d.display = {
                   y: y,
                   y0: y0
               };
           })(data);
    }

    getAreaGenerator = (chartDimensions, isEstimation) => {
        const xScale = scaleTime().domain(this.getXDomain()).range([chartDimensions.x, chartDimensions.width]);
        const yScale = scaleLinear()
            .domain([0,this.state.chartMaxConsumption])
            .range([chartDimensions.height, 0]);

        var area = d3.svg.area()
            .x(function(d,i)  { return xScale(d.time) })
            .y0(function(d) {
                return yScale(d.display.y0)
            })
            .y1(function(d) {
                return yScale(d.display.y + d.display.y0)
            })
            .interpolate(this.state.isLinear ? 'linear' : 'step-before');

        if (isEstimation)
            area.defined(function(d) { return isEstimatedData(d); })

        return area;
    }

    resetEstimatesAreas = (withDelay) => {
        if (withDelay){
            // 1 ms timeout needed to get correctly the current data disabled state on addEstimatesAreas
            setTimeout(() => {
                this.removeEstimatesAreas();
                this.addEstimatesAreas();
            }, 1);
        }else{
            this.removeEstimatesAreas();
            this.addEstimatesAreas();
        }
    }

    removeEstimatesAreas = () => {
        d3.selectAll('.nv-chart svg .nv-areaWrap .nv-estimate-area').remove();
    }

    addEstimatesAreas = () => {
        const renderedInnerChartG = d3.select(".nv-chart svg g.nv-background");
        if (renderedInnerChartG && renderedInnerChartG.node()){
            const existingAreas = d3.select(".nv-chart svg .nv-areaWrap .nv-estimate-area")[0][0];
            // no estimate areas were added yet
            if (existingAreas === null){
                const filteredData = this.state.data.map((d, i) => {
                    return {
                        ...d,
                        key: d.key + "_estimate-area",
                    }
                }).filter(d => !d.disabled);
                const estimateLayers = this.getStackedLayout(filteredData);

                const area = this.getAreaGenerator(renderedInnerChartG.node().getBBox(), true);

                const g = d3.select(".nv-chart svg .nv-areaWrap");
                g.selectAll(".nv-estimate-area")
                    .data(estimateLayers)
                    .enter().append("path")
                    .attr("class", function(d, i){ return "nv-estimate-area nv-estimate-area-" + i })
                    .attr("d", function(d) { return area(d.values); })
                    .attr("stroke", "#CFCFCF")
                    .attr("stroke-width", 2.5)
                    .style("fill", "#CFCFCF")
                    .style("fill-opacity", 0.7);
            }
        }
    }

    onOutsideCompareClick = (e) => {
        this.onOutsideCompareClickTimer = setTimeout(() => {
            const groupsEle = document.getElementById('compare-card')
            if (!groupsEle) {
                return
            }

            const rootElem = groupsEle.children[0];
            const isDescendantOfRoot = rootElem.contains(e.target);

            if (!isDescendantOfRoot) {
                this.setState({
                    isOpened: false
                })
                document.body.removeEventListener('click', this.onOutsideCompareClick);
            }
        }, 50);
    }

    toggleCompareCollapse = (index, toClose) => {
        clearTimeout(this.onOutsideCompareClickTimer);

        if (this.state.isOpened) {
            document.body.removeEventListener('click', this.onOutsideCompareClick);
        } else {
            document.body.addEventListener('click', this.onOutsideCompareClick);
        }

        this.setState({
            isOpened: !this.state.isOpened
        });
    }

    isButtonNowDisabled = () => moment().isSame(this.state.datePicker.startDate, this.state.periodFilter)

    getGranularity = () => {
        const { periodFilter, dateRangePicker: { startDate, endDate }, dataGranularity} = this.state
        const diffDays = endDate.endOf('day').diff(startDate.startOf('day'), 'days', true)

        if (periodFilter === 'day') {
            const found = this.granularities.find(granularity => granularity.value === dataGranularity)
            return found ? found.label : i18n.t('5 Min');
        }

        if (periodFilter === 'custom' && diffDays <= 2) {
            return i18n.t('5 Min');
        }

        if (periodFilter === 'week' || (periodFilter === 'custom' && diffDays <= 31)) {
            return i18n.t('1 Hour');
        }

        if (periodFilter === 'year' || (periodFilter === 'custom' && diffDays >= daysToMonthGranularity)) {
            return i18n.t('1 Month')
        }

        return i18n.t('1 Day');
    }

    changeGranularity = granularity => {
        const { dataGranularity } = this.state;
        const searchParams = new URLSearchParams(window.location.search);
        let search = window.location.search;

        if (granularity && granularity.value){
            let separator = (search.indexOf('?') < 0) ? '?' : '&';
            if (!searchParams.has('dataGranularity')) {
                search = search + separator + `dataGranularity=${granularity.value}`;
            } else {
                search = search.replace(`dataGranularity=${dataGranularity}`, `dataGranularity=${granularity.value}`);
            }
        }

        this.props.history.push(window.location.pathname + search);
    }

    goToPreviousPeriod = () => {
        let {datePicker} = this.state;
        datePicker.startDate.subtract(1, this.state.periodFilter);
        this.props.history.push(this.getSearchURL());
    }

    goToNextPeriod = () => {
        let { datePicker } = this.state;
        datePicker.startDate.add(1, this.state.periodFilter);
        this.props.history.push(this.getSearchURL());
    }

    goToCurrentDay = () => {
        let { datePicker } = this.state;
        datePicker.startDate = moment();
        this.props.history.push(this.getSearchURL());
    }

    detailsDateFormatter = day => {
        const { dateRangePicker: { startDate, endDate }} = this.state;
        const diffDays = endDate.endOf('day').diff(startDate.startOf('day'), 'days', true);

        if (diffDays <= 31) {
            const rangeStart = moment(day).format('LT');
            let rangeEnd;

            if (diffDays <= 2) {
                rangeEnd = moment(day).add(5, 'minute').format('LT');
            }else{
                rangeEnd = moment(day).add(1, 'hour').format('LT');
            }

            return moment(day).format('LL') + ' ' + rangeStart + ' - ' + rangeEnd;
        }

        return moment(day).format('LL');
    }

    getMinMaxTimeRange = (obj, variable) => {
        if (variable !== 'min' && variable !== 'max') {
            console.warn('Invalid variable informed. No min/max time range will be displayed.');
            return '-';
        }

        if (obj[variable] && obj[variable].hour){
            const granularity = this.granularities.find(granularity => granularity.value === this.state.dataGranularity)
            const rangeStart = moment(obj[variable].hour, 'HH:mm').format('LT');
            const rangeEnd = moment(obj[variable].hour, 'HH:mm').add(granularity.minutes, 'minute').format('LT');
            return rangeStart + ' - ' + rangeEnd;
        }else if (obj[variable] && obj[variable].dayAndHour){
            return this.detailsDateFormatter(obj[variable].dayAndHour);
        }else if (obj[variable] && obj[variable].day){
            return moment(obj[variable].day, 'YYYY-MM-DD').format('LL');
        }else {
            return '-'
        }
    }


    addWeekEndsHighlight = () => {
        const renderedInnerChartG = d3.select(".nv-chart svg g.nv-background");

        // the chart was already created
        if (renderedInnerChartG && renderedInnerChartG.node()){
            const existingHighlights = d3.select(".nv-chart svg g g .weekend-highlight")[0][0];

            // no highlight rects were added yet
            if (existingHighlights === null){
                let weekEndData = [];
                const chartDimensions = renderedInnerChartG.node().getBBox();
                const xScale = scaleTime().domain(this.getXDomain()).range([0, chartDimensions.width]);

                if (this.state.periodFilter === 'week'){
                    let sunday = {};
                    let left = xScale(moment(this.state.datePicker.startDate.clone().startOf('week').valueOf()).toDate());
                    let right = xScale(moment(this.state.datePicker.startDate.clone().startOf('week').add(1, 'days').valueOf()).toDate());
                    let width = right - left;
                    sunday.xStart = left;
                    sunday.width = width;

                    let saturday = {};
                    let left2 = xScale(moment(this.state.datePicker.startDate.clone().endOf('week').subtract(1, 'days').valueOf()).toDate());
                    let right2 = xScale(moment(this.state.datePicker.startDate.clone().endOf('week').valueOf()).toDate());
                    let width2 = right2 - left2;
                    saturday.xStart = left2;
                    saturday.width = width2;

                    weekEndData.push(sunday);
                    weekEndData.push(saturday);
                }

                if (this.state.periodFilter === 'month'){
                    const lastMonthDay = this.state.datePicker.startDate.clone().endOf('month');
                    let currentDay = this.state.datePicker.startDate.clone().startOf('month');

                    const weekEndDays = [];
                    while (currentDay.isSameOrBefore(lastMonthDay, 'day')){
                        // if (currentDay.day() === 6)
                        if (currentDay.day() === 0 || currentDay.day() === 6)
                            weekEndDays.push(currentDay.clone());
                        currentDay.add(1, 'days');
                    }

                    weekEndDays.forEach(weekEndDay => {
                        let weekEndDayData = {};
                        //if the first day of month, doesn't apply the 12 hours left margin
                        const start = (weekEndDay.format('DD') === "01") ? weekEndDay.clone().startOf('day') : weekEndDay.clone().startOf('day');
                        let left = xScale(moment(start.valueOf()).toDate());
                        let right = xScale(moment(weekEndDay.clone().startOf('day').add(1, 'days').valueOf()).toDate());
                        let width = right - left;
                        weekEndDayData.xStart = left;
                        weekEndDayData.width = width;
                        weekEndData.push(weekEndDayData);
                    });
                }

                if (this.state.periodFilter === 'custom' && this.state.diffOfSelectedDays < daysToMonthGranularity){
                    const firstPeriodDay = this.state.dateRangePicker.startDate.clone().startOf('day');
                    const lastPeriodDay = this.state.dateRangePicker.endDate.clone().endOf('day');
                    let currentDay = this.state.dateRangePicker.startDate.clone().startOf('day');

                    const weekEndDays = [];
                    while (currentDay.isSameOrAfter(firstPeriodDay, 'day') && currentDay.isSameOrBefore(lastPeriodDay, 'day')){
                        if (currentDay.day() === 0 || currentDay.day() === 6)
                            weekEndDays.push(currentDay.clone());
                        currentDay.add(1, 'days');
                    }

                    weekEndDays.forEach((weekEndDay, i) => {
                        let weekEndDayData = {};
                        let left, right;

                        //for periods greater than 31 days, uses month highlight logic (one day granularity)
                        if (this.state.diffOfSelectedDays > 30){//diff of 30 === 31 days selected
                            //if the first day of period is weekend day, doesn't apply the 12 hours left margin
                            const start = (i === 0 && firstPeriodDay.isSame(weekEndDay)) ? weekEndDay.clone().startOf('day') : weekEndDay.clone().startOf('day');
                            left = xScale(moment(start.valueOf()).toDate());
                            right = xScale(moment(weekEndDay.clone().startOf('day').add(1, 'day').valueOf()).toDate());
                        } else {
                            left = xScale(moment(weekEndDay.clone().startOf('day').valueOf()).toDate());
                            right = xScale(moment(weekEndDay.clone().endOf('day').valueOf()).toDate());
                        }

                        let width = right - left;
                        weekEndDayData.xStart = left;
                        weekEndDayData.width = width;
                        weekEndData.push(weekEndDayData);
                    });
                }

                if (weekEndData.length > 0){
                    const group = d3.select(".nv-chart svg g g");

                    group.selectAll("rect.weekend-highlight")
                        .data(weekEndData)
                        .enter()
                        .append("rect")
                        .classed("weekend-highlight", true)
                        .attr("x", (d) => d.xStart)
                        .attr("y", 0)
                        .attr("width", (d) => d.width )
                        .attr("height", chartDimensions.height )
                        .attr("fill", "#d8d8d8")
                        .attr("opacity", 0.2);
                }
            }
        }
    }

    removeParentGroupLayer = () =>{
        d3.select(".nv-chart svg .nv-stackedWrap .nv-parent-group-area").remove();
    }

    addParentGroupLayer = () => {
        if (this.state.parentGroupData && this.state.showParent && this.state.parentGroupData.length) {
            const renderedInnerChartG = d3.select(".nv-chart svg g.nv-background");
            if (renderedInnerChartG && renderedInnerChartG.node()){
                const existingAreas = d3.select(".nv-chart svg .nv-areaWrap .nv-parent-group-area")[0][0];
                // no estimate areas were added yet
                if (existingAreas === null){
                    let parentGroupDataPre = this.createDatetime(this.state.parentGroupData).sort((a, b) => moment(a.time).diff(moment(b.time)));
                    const entries = nest().key(function(d) { return d.label; }).entries(parentGroupDataPre);
                    const parentGroupLayer = this.getStackedLayout(entries);

                    const area = this.getAreaGenerator(renderedInnerChartG.node().getBBox());

                    const g = d3.select(".nv-chart svg .nv-stackedWrap");
                    g.selectAll(".nv-parent-group-area")
                       .data(parentGroupLayer)
                       .enter().insert("path", ":first-child")
                       .attr("class", "nv-parent-group-area")
                       .attr("d", function(d) { return area(d.values); })
                       .attr("stroke", parentGroupColor)
                       .attr("stroke-width", 1)
                       .style("fill", parentGroupColor)
                       .style("fill-opacity", 0.1);

                   if (this.state.parentGroupOnly)
                        this.addParentGroupMessage();
               }
           }
        }
    }

    addParentGroupMessage = () => {
        const renderedInnerChartG = d3.select(".nv-chart svg g.nv-background");
        // the chart was already created
        if (renderedInnerChartG && renderedInnerChartG.node()){
            const existingMessage = d3.select(".nv-chart svg nvd3 nv-noData")[0][0];
            // no message was added yet
            if (existingMessage === null){
                const chartDimensions = renderedInnerChartG.node().getBBox();
                const message = (this.state.filterSubgroup) ? this.parentOnlySensorChartMessage : this.parentOnlyGroupChartMessage;

                d3.select('.nv-chart svg').append("text")
                    .attr("x", (chartDimensions.width/2) + 60)// 60 is the NVD3's stackedAreaChart left margin
                    .attr("y", chartDimensions.height/2 + 10)// 10 is the NVD3's stackedAreaChart top margin
                    .attr("fill", "#3a3d4e")
                    .attr("class", "nvd3 nv-noData")
                    .style("text-anchor", "middle")
                    .text(message);
            }
        }
    }

    clearTooltip = () => {
        this.setState({tooltip: false})
        setTimeout(() => { this.setState({tooltip: true})}, 500)
    }

    render () {
        const { periodFilter, loadingData } = this.state
        const { classes } = this.props

        const isButtonNowDisabled = this.isButtonNowDisabled();

        let xAxis = {
            tickFormat: this.xTickFormat,
            showMaxMin: false,
        };

        if (periodFilter === 'month' || periodFilter === 'day'){
            xAxis.rotateLabels = -50;
            xAxis.tickPadding = 0;
        }

        if (periodFilter === 'day')
            xAxis.ticks = 24;

        if (periodFilter === 'week' || periodFilter === 'month' || periodFilter === 'year')
            xAxis.tickValues = this.state.xTicks;

        if (periodFilter === 'custom'){
            if (this.state.diffOfSelectedDays < daysToMonthGranularity){
                xAxis.ticks = 8;
            }else{
                const periodStart = this.state.dateRangePicker.startDate.clone().startOf('month');
                const periodEnd = this.state.dateRangePicker.endDate.clone().endOf('month');
                const months = utcMonths(
                    periodStart,
                    periodEnd,
                );
                xAxis.ticks = months && months.length ? months.length : 4;
            }
        }

        return (
            <div id="dashboard" className="stream-graph">
                <div id="streamgraph-chart">
                    <div id="top-bar">
                        <div className={`top top-period-filter-${periodFilter}`}>
                            <div>
                                {this.periodOptions.map((period, key) => (
                                    <Button className={
                                        `btn-period-filter${periodFilter === period.value ? ' active' : ''}`}
                                        disabled={
                                            loadingData ||
                                            periodFilter === period.value ||
                                            (!this.state.loadingGroups && this.state.groups.length === 0)
                                        }
                                        key={key}
                                        onClick={() => this.changePeriodFilter(period)}>
                                        {period.label}
                                    </Button>
                                ))}
                            </div>

                            <div>
                                {periodFilter !== 'custom' ?
                                    <IconButton className="btn-previous btn-previous-date"
                                        disabled={loadingData}
                                        onClick={this.goToPreviousPeriod}>
                                        <ExpandMoreIcon />
                                    </IconButton> : null}

                                <DatePicker
                                    chartType="streamgraph"
                                    datePicker={this.state.datePicker}
                                    dateRangePicker={this.state.dateRangePicker}
                                    updateDatePicker={this.updateDatePicker}
                                    updateDateRangePicker={this.updateDateRangePicker}
                                    periodFilter={periodFilter}
                                    isLinear={this.state.isLinear}
                                    showParent={this.state.showParent}
                                    loadingGroups={this.state.loadingGroups}
                                    history={this.props.history}
                                    disabled={!this.state.loadingGroups && this.state.groups.length === 0}
                                    showLabel={false}
                                    dataGranularity={this.state.dataGranularity} />

                                {periodFilter !== 'custom' ?
                                    <IconButton className="btn-next btn-next-date"
                                        disabled={
                                            loadingData ||
                                            this.state.datePicker.startDate.isSameOrAfter(
                                                moment().startOf(periodFilter))
                                        }
                                        onClick={this.goToNextPeriod}>
                                        <ExpandMoreIcon />
                                    </IconButton> : null}

                                {periodFilter !== 'custom' ?
                                    <Button className={`btn-period${isButtonNowDisabled ? ' active' : ''}`}
                                        disabled={
                                            loadingData ||
                                            isButtonNowDisabled ||
                                            (!this.state.loadingGroups && this.state.groups.length === 0)
                                        }
                                        onClick={() => this.goToCurrentDay()}>
                                        {i18n.t('NOW')}
                                    </Button> : null}
                            </div>

                            <div className="granularity">
                                {periodFilter === 'day' && (
                                    <div className="granularity-group">
                                        {this.granularities.map(rate => (
                                            <Button
                                                className={
                                                    `btn-period-filter ${this.state.dataGranularity === rate.value ? 'active' : ''}`}
                                                disabled={
                                                    loadingData ||
                                                    (!this.state.loadingGroups && this.state.groups.length === 0)
                                                }
                                                key={rate.value}
                                                onClick={() => this.changeGranularity(rate)}
                                            >
                                                {rate.label}
                                            </Button>
                                        ))}
                                    </div>
                                )}
                                {periodFilter !== 'day' && (
                                    <div>
                                        <Text text={this.getGranularity()} />
                                    </div>
                                )}
                            </div>
                        </div>
                        {!this.state.loadingGroups && (
                            <div id="group-filters">
                                <div id="compare-card" className="bottom">
                                    <CompareCard
                                        changeGroup={this.changeGroup}
                                        changeSensor={this.changeSensor}
                                        changeSubgroup={this.changeSubgroup}
                                        changeTag={this.changeTag}
                                        compare={this.state.compare}
                                        filterGroupOptions={this.state.filterGroupOptions}
                                        tags={this.state.tags}
                                        hideSensors
                                        index={0}
                                        obj={{
                                            color: '#FFF',
                                            filterTag: this.state.filterTag,
                                            filterGroup: this.state.filterGroup,
                                            filterSubgroup: this.state.filterSubgroup,
                                            open: this.state.isOpened,
                                            filterSensorOptions: [],
                                            filterSubgroupOptions: this.state.filterSubgroupOptions
                                        }}
                                        toggleCompareCollapse={this.toggleCompareCollapse}
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                    <div id="chart">
                        {(!this.state.loadingGroups &&
                        !this.state.loadingData &&
                        !this.state.data.length &&
                        !this.state.parentGroupData.length) ? (
                            <div className="nv-chart no-chart-msg">
                                <p className="no-data"> {this.noDataChartMessage} </p>
                            </div>
                        ) : (
                            <div id="graph" className={`${this.state.parentGroupOnly ? 'parent-group-only' : ''}`} >
                                <ChartLoading loading={this.state.loadingData} />
                                <div className="tooltip-killer" />
                                <div className="switch">
                                    <p>{i18n.t('Graph View')}</p>
                                    <span className={`label ${this.state.isLinear ? 'active' : ''}`}>
                                        {i18n.t('Linear')}
                                    </span>
                                    <Switch
                                        checked={!this.state.isLinear}
                                        onClick={this.toggleLinear}
                                        value="linear"
                                        classes={{
                                            switchBase: classes.switchBase,
                                            checked: classes.checked,
                                            bar: classes.bar,
                                        }}
                                    />
                                    <span className={`label ${!this.state.isLinear ? 'active' : ''}`}>
                                        {i18n.t('Step')}
                                    </span>
                                </div>
                                <div className={`switch show-alerts ${this.state.loadingData || !this.state.data.length ? 'disabled' : ''}`}>
                                    <p>{i18n.t('Show Parent')}</p>
                                    <span className={`label ${this.state.showParent ? 'active' : ''}`}>
                                        {i18n.t('On')}
                                    </span>
                                    <Switch
                                        checked={!this.state.showParent}
                                        onClick={this.toggleParent}
                                        value="showAlerts"
                                        classes={{
                                            switchBase: classes.switchBase,
                                            checked: classes.checked,
                                            bar: classes.bar,
                                        }}
                                    />
                                    <span className={`label ${!this.state.showParent ? 'active' : ''}`}>
                                        {i18n.t('Off')}
                                    </span>
                                </div>

                                <NVD3Chart id="streamgraph"
                                    type="stackedAreaChart"
                                    datum={this.state.data}
                                    x="time"
                                    y="consumption"
                                    options={{
                                        color: colors,
                                        duration: 0,
                                        interpolate: this.state.isLinear ? 'linear' : 'step-before',
                                        forceX: this.state.xDomain,
                                        forceY: this.state.chartMaxConsumption ?
                                            [0, this.state.chartMaxConsumption] : [0],
                                        interactiveLayer: {
                                            tooltip: {
                                                keyFormatter: (name, index) => !this.state.filterTag ? name : name === 'TOTAL' ? i18n.t('Total') : this.state.breadcrumbsTrails[index],
                                                headerFormatter: this.tooltipHeaderFormatter,
                                                enabled: this.state.tooltip && !this.state.parentGroupOnly,
                                                valueFormatter: (value, i, d) => {
                                                    let valueLabel = kwhFormat(value);
                                                    if (d && d.point && d.point.aggregationType){
                                                        if (d.point.aggregationType === 'ESTIMATED')
                                                            valueLabel = i18n.t('(estimated)') + ' ' + valueLabel;
                                                        if (d.point.aggregationType === 'HYBRID')
                                                            valueLabel = i18n.t('(partially estimated)') + ' ' + valueLabel;
                                                    }
                                                    return valueLabel;
                                                }
                                            }
                                        },
                                        legend: { keyFormatter:
                                            key => this.state.dataLegends[key] || key
                                        },
                                        noData: this.state.noDataChartMsg,
                                        useInteractiveGuideline: !this.state.parentGroupOnly,
                                        xAxis: xAxis,
                                        xScale: scaleTime(),
                                        yAxis: { axisLabel: i18n.t('kWh'), tickFormat: yTickFormat},
                                        showControls: false,
                                        showLegend: !this.state.parentGroupOnly
                                    }} />
                            </div>
                        )}
                    </div>
                    <div className="tables">
                        <Table className="chart-table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{i18n.t('Group / Subgroup')}</TableCell>
                                    <TableCell>{i18n.t('Total Cost')}</TableCell>
                                    <TableCell>{i18n.t('Total Consumption')}</TableCell>
                                    <TableCell>{this.periodsText[this.state.periodFilter]}</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        {this.state.breadcrumbsTrail}
                                    </TableCell>
                                    <TableCell>
                                        {this.costFormat(this.state.currency, this.state.totalCost)}
                                    </TableCell>
                                    <TableCell>
                                        {this.state.total && this.state.total >= 0 ? `${kwhFormat(this.state.total)} ${i18n.t('kWh')}` : '-'}
                                    </TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableBody>
                        </Table>
                        <Table className="details-table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{i18n.t('Max')}</TableCell>
                                    <TableCell>{i18n.t('Min')}</TableCell>
                                    <TableCell>{i18n.t('Avg')}</TableCell>
                                    <TableCell>{this.getGranularity()}</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <div>
                                            {this.state.max && this.state.max.consumption !== null && this.state.max.consumption >= 0
                                                ? `${kwhFormat(this.state.max.consumption)} ${i18n.t('kWh')}`
                                                : '-'
                                            }
                                            <small>
                                                {this.getMinMaxTimeRange(this.state, 'max')}
                                            </small>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div>
                                            {this.state.min && this.state.min.consumption !== null && this.state.min.consumption >= 0
                                                ? `${kwhFormat(this.state.min.consumption)} ${i18n.t('kWh')}`
                                                : '-'
                                            }
                                            <small>
                                                {this.getMinMaxTimeRange(this.state, 'min')}
                                            </small>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        {this.state.average && this.state.average >= 0 ? `${kwhFormat(this.state.average)} ${i18n.t('kWh')}` : '-'}
                                    </TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </div>
                <SystemSnackbar
                    open={!this.state.loadingGroups && this.state.groups.length === 0}
                    message={i18n.t('No group setup')}
                    variant='warning' />
            </div>
        )
    }
}

StreamGraph.propTypes = {
    intl: intlShape.isRequired,
};

export default withStyles(styles)(injectIntl(StreamGraph));
