import React from 'react';
import {Redirect, Route} from 'react-router';

import axios from 'axios';
import {API} from '../api';
import i18n from '../../i18n';

import {defaultRoutePath} from '../utils'

import {App} from '../../index';
import LoginPage from '../../pages/login/login';

import LogRocket from 'logrocket';
import ReactGa from 'react-ga';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

const setToken = (token) => {
    axios.defaults.headers.common['x-auth-token'] = token;
}

const getUserData = () => {
    let token = cookies.get('x-auth-token');
    if (token) setToken(token);
    return token;
}

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        getUserData() ? (
            <App>
                <Component {...props} />
            </App>
        ) : (
            <Redirect to={{pathname: '/login', state: {from: props.location}}} />
        )
    )} />
);

const RootRoute = ({ component: Component, ...rest }) => {
    const isRoot = localStorage.getItem('isRoot');

    return (
        <Route {...rest} render={props => (
            getUserData() && isRoot === 'true'
            ? (
                <App>
                    <Component {...props} />
                </App>
            ) : (
                <Redirect to={{pathname: defaultRoutePath, state: {from: props.location}}} />
            )
        )} />
    )
}

class Auth extends React.Component {

    constructor (props) {
        super(props);

        const language = JSON.parse(localStorage.getItem('language'));

        this.from = this.props.location.state ? this.props.location.state.from : {pathname: '/'};
        this.state = {
            language: !language || language === 'en-GB' ? 'en-GB' : language === 'ru-RU' ? 'ru-RU' : 'pt-BR',
            redirect: false,
            loading: false,
            user: '',
            pass: '',
            fieldsWithError: [],
            forgot: {
                apiResponse: '',
                sent: false,
                tried: false,
            },
        };
    }

    change = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    changeLanguage = language => {
        localStorage.setItem('language', JSON.stringify(language));
        this.props.changeMomentLanguage(language)
        this.setState({
            changedLang: true,
            language: language
        })
    }

    validate = () => {
        let fieldsWithError = [];

        if (this.state.user === '') fieldsWithError.push('user');
        if (this.state.pass === '') fieldsWithError.push('pass');
        this.setState({fieldsWithError: fieldsWithError, loading: false});

        return !fieldsWithError.length;
    }

    send = () => {
        this.setState({loading: true});

        const user = this.state.user.trim();

        API.AUTH.LOGIN(user, this.state.pass).then((response) => {
            localStorage.setItem('userEmail', user);
            localStorage.setItem('lastUrlFromLogin', response.data.user.lastUrl);
            localStorage.setItem('authority', response.data.user.authority);
            localStorage.setItem('isRoot', response.data.tenant.root);

            if (process.env.NODE_ENV !== 'development') {
                ReactGa.initialize('UA-136314465-1', {
                    gaOptions: {
                        userId: user
                    }
                });
            }

            let token = response.headers['x-auth-token'] || cookies.get('x-auth-token');
            cookies.set('x-auth-token', token, { path: '/', maxAge: 30*60 });
            cookies.set('tenantName', response.data.tenant.name, { path: '/' });
            localStorage.setItem('tenantColors', JSON.stringify(response.data.tenant.colors));
            localStorage.setItem('tenantLogo', response.data.tenant.logo);
            localStorage.setItem('tenantShowPoweredByVoltaware', response.data.tenant.showPoweredByVoltaware);

            setToken(token);

            if (this.state.changedLang) {
                API.USERS.LOCALE(this.state.language);
                if (process.env.NODE_ENV !== 'development') {
                    ReactGa.event({
                        category: 'logedLang',
                        action: this.state.language
                    });
                }
            } else if (this.state.language !== response.data.user.localeCode) {
                localStorage.setItem('language', JSON.stringify(response.data.user.localeCode));
                this.setState({ language: response.data.user.localeCode });
                i18n.changeLanguage(response.data.user.localeCode);
                this.props.changeMomentLanguage(response.data.user.localeCode);
                if (process.env.NODE_ENV !== 'development') {
                    ReactGa.event({
                        category: 'logedLang',
                        action: response.data.user.localeCode
                    });
                }
            } else {
                if (process.env.NODE_ENV !== 'development') {
                    ReactGa.event({
                        category: 'logedLang',
                        action: response.data.user.localeCode
                    });
                }
            }

            LogRocket.identify(user, {
              name: response.data.tenant.name,
              email: user
            });

            if (response.data.tenant.root)
                this.from = {pathname: '/config/clients'}

            if (this.from.pathname && this.from.pathname === '/')
                this.from = {pathname: defaultRoutePath}

            this.setState({redirect: true});
        }).catch((error) => {
            this.setState({fieldsWithError: ['credentials']});
            this.setState({loading: false});
        });
    }

    submit = (e) => {
        e.preventDefault();

        if (this.validate()) this.send();

        return false;
    }

    forgot = () => {
        let forgot = this.state.forgot;
        API.USERS.RESET_PASSWORD(this.state.user).then((response) => {
            forgot = {sent: true, tried: true};
            this.setState({forgot: forgot});
        }).catch((error) => {
            console.error(error);
            forgot = {apiResponse: error.response, sent: false, tried: true};
            this.setState({forgot: forgot});
        });
    }

    forgotReset = () => {
        this.setState({forgot: {sent: false, tried: false}});
    }

    render () {
        const {redirect} = this.state;

        if (redirect) return <Redirect to={this.from.search ? `${this.from.pathname}${this.from.search}` : this.from.pathname} />;

        return (
            <LoginPage errors={this.state.fieldsWithError}
                forgot={this.forgot}
                forgotState={this.state.forgot}
                forgotReset={this.forgotReset}
                loading={this.state.loading}
                pass={this.state.pass}
                user={this.state.user}
                language={this.state.language}
                change={this.change}
                changeLanguage={this.changeLanguage}
                submit={this.submit} />
        );
    }

}


class AuthLogout extends React.Component {
    removeTokens () {
        API.AUTH.LOGOUT().catch((error) => {
            console.error(error);
            console.error('Could not logout!');
        });

        cookies.remove('x-auth-token', { path: '/' });
        window.sessionStorage.clear();
        delete axios.defaults.headers.common['x-auth-token'];
    }

    render () {
        localStorage.setItem('tenantColors', null);
        localStorage.setItem('tenantLogo', null);
        localStorage.setItem('tenantShowPoweredByVoltaware', true);
        localStorage.setItem('authority', null);
        localStorage.setItem('isRoot', null);
        localStorage.setItem('rootTenantId', null);
        this.removeTokens();
        return <Redirect to="/login" />;
    }
}


export {Auth, AuthLogout, getUserData, PrivateRoute, RootRoute};
