import i18next from 'i18next';
import { translations } from './components/i18n/translations';

const lang = JSON.parse(localStorage.getItem('language'));

const removeUnstralatedKeys = object => Object.keys(object).reduce((pre, cur) => ({
  ...pre,
  ...object[cur] ? { [cur]: object[cur] } : {}
}), {})

const keysFrontBack = {
  'en-gb': 'en-GB',
  'pt-br': 'pt-BR',
  ru: 'ru-RU'
}

i18next.init({
    interpolation: {
      // React already does escaping
      escapeValue: false,
    },
    keySeparator: '§',
    nsSeparator: '|',
    lng: lang ? lang : 'en-GB', // 'en' | 'ru'
    // Using simple hardcoded resources for simple example
    resources: translations && Object.keys(translations).length && Object.keys(translations)
      .filter(key => key !== 'options')
      .reduce((prev, curr) => ({
        ...prev,
        [keysFrontBack[curr]]: {
          translation: removeUnstralatedKeys(translations[curr])
        }
      }), {})
  });

export default i18next;
