import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { withStyles } from 'material-ui/styles';

import Table from 'material-ui/Table';
import Paper from 'material-ui/Paper';
import {TableBody, TableCell, TableHead, TableRow, TableSortLabel} from 'material-ui/Table';

import { API } from '../../components/api';
import { getSensorStructured } from '../../components/utils';
// import { granularities, granularitiesCapitalized, alertOperations } from './constants';
// import {Loading} from '../../elements/loading';

import i18n from '../../i18n';

const CustomTableCell = withStyles(theme => ({
    body: {
        fontSize: '13px',
        fontWeight: 'bold',
        color: '#757575',
        '& span': {
            fontSize: '13px',
        }
    },
}))(TableCell);

const CustomTableSortLabel = withStyles(theme => ({
    root: {
        fontSize: '13px',
        fontWeight: 'bold',
        color: '#A1A1A8',
    },
}))(TableSortLabel);

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        width: '100%',
        marginBottom: theme.spacing.unit * 3,
        boxShadow: theme.shadows[5],
        padding: 0,
        '&:focus': {
            outline: 'none'
        }
    },
    table: {
        minWidth: 1020,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    tbodyRow: {
        '& td:last-child button': {
            opacity: '0',
            transition: 'opacity 0.6s'
        },
        '&:hover td:last-child button': {
            opacity: '1'
        }
    },
    statusIcon: {
        borderRadius: '50%',
        width: '20px',
        height: '20px',
        display: 'inline-block',
        marginRight: '20px',
        position: 'relative',
        top: '4px'
    },
    statusInfo: {
        height: '20px',
    },
    offlineStatus: {
        border: '2px solid #CCC',
    },
    onlineStatus: {
        border: '1px solid #5A9429',
        backgroundColor: '#5A9429',
    },
});

class ResourcesList extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.columnData = [
            { id: 'status', numeric: false, disablePadding: false, label: i18n.t('Status') },
            { id: 'id', numeric: false, disablePadding: false, label: i18n.t('ID') },
            { id: 'group-or-sensor', numeric: false, disablePadding: false, label: i18n.t('Allocated to') },
            { id: 'tag', numeric: false, disablePadding: false, label: i18n.t('Tag') },
            { id: 'tariff', numeric: false, disablePadding: false, label: i18n.t('Tariff') },
        ];

        this.state = {
            loading: true,
            selected: [],
            page: 0,
            rowsCount: 0,
            anchorEl: null,
            openFilter: false,
            selectedFilter: 'all',
            systemEvents: [],
        }
    }

    componentWillMount() {
        let searchParams = new URLSearchParams(window.location.search);

        this.getEvents(null, this.state.selectedFilter, 0, parseFloat(searchParams.get('id')));
    }

    componentWillReceiveProps(nextProps){
        if (nextProps.reloadEvents === true)
            this.reloadEvents();
    }

    getEvents = (timestamp) => {

        API.SENSORS.STATUS.GET().then(({ data }) => {

            this.setState({
                systemEvents: data.map(event => ({
                    ...event,
                    key: getSensorStructured(event).key
                })),
                rowsCount: data.numberOfEvents,
                loading: false,
            })

        }).catch((error) => {
            this.setState({ loading: false })
            console.error(error)
        });
    }

    reloadEvents = () => {
        this.getEvents();
    }

    getBreadcrumbsTrailFromHierarchy = (hierarchy) => {
        const errorMessage = i18n.t('Unknown group/sensor');
        let error = false;

        if (hierarchy && !hierarchy.groupName && !hierarchy.sensorChannelName && !hierarchy.parentGroupName) {
            return i18n.t('Not allocated')
        }

        let breadcrumbsText = '';
        if (hierarchy){
            if (hierarchy.parentGroupName)
                breadcrumbsText = hierarchy.parentGroupName;
            if (hierarchy.groupName)
                if (hierarchy.parentGroupName)
                    breadcrumbsText += '/'
                breadcrumbsText += hierarchy.groupName;
            if (hierarchy.sensorId && hierarchy.channel){
                breadcrumbsText += '/' + hierarchy.sensorId + '-' + hierarchy.channel;
                if (hierarchy.sensorChannelName)
                    breadcrumbsText += ' - ' + hierarchy.sensorChannelName;
            }
            if (breadcrumbsText === '')
                error = true;
        }else{
            error = true;
        }

        if (error){
            breadcrumbsText = errorMessage;
            console.warn("A wrong breadcrumb format was received and no breadcrumb text could be created for Alerts Events list");
        }

        return breadcrumbsText;
    }

    render() {
        const { classes } = this.props;
        const { systemEvents: resources, order, orderBy } = this.state;
        const emptyRows = resources.length < 10 ? 10 - resources.length : 0;

        return (
            <Paper className={classes.paper}>
                <div className={classes.tableWrapper}>
                    <Table className={classes.table} aria-labelledby="tableTitle">
                        <TableHead className={classes.head}>
                            <TableRow>
                                {/* <TableCell padding="checkbox">{this.state.loading && <div style={{minWidth: 48, width: 48}}> <Loading /></div>}</TableCell> */}
                                {this.columnData.map(column => {
                                    return (
                                        <TableCell
                                            key={column.id}
                                            numeric={column.numeric}
                                            padding="checkbox"
                                            sortDirection={orderBy === column.id ? order : false}
                                            >
                                            <CustomTableSortLabel
                                                active={orderBy === column.id}
                                                direction={order}
                                                >
                                                {column.label}
                                            </CustomTableSortLabel>
                                        </TableCell>
                                    );
                                }, this)}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {resources.map(resource => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={resource.key}
                                        className={classes.tbodyRow}>

                                        <CustomTableCell padding="checkbox">
                                            <span className={classNames(resource.type === 'STATUS_ONLINE' ? classes.onlineStatus : classes.offlineStatus, classes.statusIcon)} />
                                            <span className={classes.statusInfo}>
                                                {resource.type === null
                                                    ? i18n.t('Never connected')
                                                    : i18n.t('{{status}} since {{dateAndTime}}',
                                                        {
                                                            status: resource.type === 'STATUS_ONLINE' ? i18n.t('Online') : i18n.t('Offline'),
                                                            dateAndTime: moment(resource.timestamp).format('LLL')
                                                        })
                                                }
                                            </span>
                                        </CustomTableCell>
                                        <CustomTableCell padding="checkbox">{resource.key}</CustomTableCell>
                                        <CustomTableCell padding="checkbox">{this.getBreadcrumbsTrailFromHierarchy(resource.groupBreadCrumb)}</CustomTableCell>
                                        <CustomTableCell padding="checkbox">{resource.labelName}</CustomTableCell>
                                        <CustomTableCell padding="checkbox">{resource.tariffName}</CustomTableCell>
                                    </TableRow>
                                );
                            })}
                            {!this.state.loading && resources.length === 0 && (
                                <TableRow>
                                    <CustomTableCell colSpan={6}>{i18n.t('No sensors set up yet.')}</CustomTableCell>
                                </TableRow>
                            )}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                    <CustomTableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
            </Paper>
        );
    }
}

ResourcesList.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ResourcesList);
