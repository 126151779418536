export const translations = {
  'en-gb': {
    'An error occurred while retrieving data': 'An error occurred while retrieving data.',
    'Loading data': 'Loading data...',
    'There is no data available for the selected criteria': 'There is no data available for the selected criteria',
    'There is no data available as no group has been set up': 'There is no data available as no group has been set up.',
    '5 Min': '',
    '30 Min': '',
    '1 Hour': '',
    'Day': '',
    'Week': '',
    'Month': '',
    'Custom': '',
    '24 Hours': '',
    '1 Week': '',
    '1 Month': '',
    'Total Cost': '',
    'There is no data available as no sensor has been set up': 'There is no data available as no sensor has been set up.',
    'Could not load sensors data': 'Could not load sensors data.',
    'Unknown group/sensor': '',
    'Active Power': '',
    'Reactive Power': '',
    'NOW': '',
    'Select any sensor-phase (type to search)': '',
    'Max': '',
    'Min': '',
    'Avg': '',
    'No sensors setup': 'No sensors setup. Please contact support.',
    'A new password was sent to your email': 'A new password was sent to your email.',
    'There was an error resetting the password': 'There was an error resetting the password.',
    'Please fill the username correctly': 'Please fill the username correctly.',
    'Sending the email': 'Sending the email...',
    'voltaware building electricity monitoring logo': '',
    'Login': '',
    'Username': '',
    'Password': '',
    'Sign in': '',
    'Forgot your password?': '',
    'For a better experience on Electricity Monitoring Dashboard use {{firefox}} or {{chrome}}': 'For a better experience on Electricity Monitoring Dashboard use {{firefox}} or {{chrome}}.',
    'Change language to english': '',
    'Change language to russian': '',
    'Firefox': '',
    'Chrome': '',
    'There are intersection between two compositions': 'There is a conflict in Peak and Off-Peak Tariffs table. Please check if there\'s overlapping in time related inputs or if there are limits with identical inputs.',
    'There was an error removing this tariff': 'There was an error removing this tariff.  Please, try again or contact support.',
    'Tariff couldn\'t be deleted because the group(s) {{tariffGroupNames}} use(s) this tariff': 'Tariff couldn\'t be deleted because the group(s) {{tariffGroupNames}} use(s) this tariff.',
    'Benchmark': '',
    'Select any benchmark': '',
    'Fixed Benchmark': '',
    'Below benchmark': '',
    'Above benchmark': '',
    'kWh': '',
    '1 Day': '',
    'View Only - This feature is currently under development': '',
    'Type': '',
    'Total Consumption': '',
    'Total Savings': '',
    'Total Losses': '',
    'Absolute': '',
    'Relative Benchmark': '',
    'Relative': '',
    'Group': '',
    'Select any group': '',
    'Subgroup': '',
    'Select any subgroup': '',
    'Sensor': '',
    'Select any sensor': '',
    'Tag': '',
    'Select any tag': '',
    'Consumption': '',
    'Power': '',
    'Cost': '',
    'Groups': '',
    'Tariffs': '',
    'Benchmarking': '',
    'Analysis': '',
    'Resources': '',
    'Reports': '',
    'Alerts': '',
    'Configuration': '',
    'Tutorials': '',
    'Logout': '',
    'ADD GROUP': '',
    'line graph': '',
    'stream graph': '',
    'heatmap': '',
    'geolocation': '',
    'cumulative': '',
    'benchmark': '',
    'Events': '',
    'Real Cost': '',
    'Cost Simulation': '',
    'Please try again': 'Please, try again or contact support.',
    'Sample Group': '',
    'Graph interpolation': '',
    'Linear': '',
    'Step': '',
    'Show alerts': '',
    'Off': '',
    'On': '',
    'No group setup': 'No group setup. Please setup a group in "Configurations > Groups > Create Group".',
    'Could not load the tags': 'Could not load the tags.',
    'Could not load groups data': 'Could not load groups data.',
    'Could not load alert events data': 'Could not load alert events data.',
    'Could not load chart data': 'Could not load chart data.',
    'Check alert': '',
    '(estimated)': '',
    '(partially estimated)': '',
    'This group has no subgroups': '',
    'This subgroup has no sensors': '',
    'Empty data': '',
    'Entries values length are not equal': '',
    'Graph View': '',
    'Show Parent': '',
    'Total': '',
    'Group / Subgroup': '',
    'Please, try again or contact support.': '',
    'There was an error resetting the password.': '',
    'Sending the email...': '',
    '*': '',
    'An error occurred while retrieving data.': '',
    'Loading data...': '',
    'There is no data available as no group has been set up.': '',
    'Data cannot be displayed due to the tariffs for the tag elements being set in different currencies.': '',
    'Check alert:': '',
    'Total Cost:': '',
    'Rate:': '',
    'Standing Charge:': '',
    'Tariff Calculation': '',
    'Sum of Parts': '',
    'Group / Subgroup / Sensor': '',
    'Tariff': '',
    'Rate': '',
    'Standing Charge': '',
    'Start Date': '',
    'End Date': '',
    'No group setup. Please setup a group in "Configurations > Groups > Create Group".': '',
    'Could not load sensors data.': '',
    'Could not load chart data.': '',
    'No sensors setup. Please contact support.': '',
    'Chart may be outdated. Please press "Simulate Costs" after setting the filters.': '',
    'No tariff setup. Please setup a tariff in "Configurations > Tariff > Create Tariff".': '',
    'Period Range': '',
    '(optional)': '',
    'Start date': '',
    'End date': '',
    'ADD TARIFF': '',
    'SIMULATE COSTS': '',
    'connected': '',
    'disconnected': '',
    'Could not delete the event.': '',
    'Could not load event details.': '',
    'Delete event': '',
    'Edit event': '',
    'Show event': '',
    'Alert:': '',
    'Last Triggered:': '',
    'History': '',
    'Date': '',
    'Hour': '',
    'Alert name:': '',
    'Online Sensor System Alert': '',
    'Offline Sensor System Alert': '',
    'Created at:': '',
    'Alert created by:': '',
    'EDIT ALERT': '',
    'Are you sure?': '',
    'If you delete you won\'t see this event anymore.': '',
    'Yes': '',
    'Cancel': '',
    'Triggered': '',
    'Alert': '',
    'All Alerts': '',
    'Unread Alerts': '',
    'Read Alerts': '',
    'Events updated successfully': '',
    'An error occurred while updating your events.': '',
    'Event updated successfully': '',
    'An error occurred while updating your event.': '',
    'Event deleted successfully': '',
    'An error occurred while deleting your event.': '',
    'Events deleted successfully': '',
    'An error occurred while deleting your events.': '',
    'Set all as unread': '',
    'Set all as read': '',
    'Previous Page': '',
    'Next Page': '',
    'Filter list': '',
    'Open event': '',
    'Set as unread': '',
    'Set as read': '',
    'Go to chart': '',
    'No alert events yet.': '',
    'Last 10 days': '',
    'Consumption:': '',
    'BACK': '',
    'An error occurred while loading the groups.': '',
    'Sensors Count:': '',
    'Total Consumption:': '',
    'Today:': '',
    'Granularity': '',
    'Max:': '',
    'Min:': '',
    'Avg:': '',
    'GO TO ANALYSIS': '',
    'An error occurred while updating the event': '',
    'There is no consumption data available for the selected criteria. Cost simulation can\'t be calculated in this case.': '',
    'Today': '',
    'This {{period}}': '',
    'Select date': '',
    'Date Range': '',
    'Select date range': '',
    'Reset Date Range': '',
    'month': '',
    'day': '',
    'week': '',
    'custom': '',
    'Invalid': '',
    'Already exists': '',
    'New Recipient': '',
    'Recipient e-mail': '',
    'Add Recipient': '',
    'Above': '',
    'Below': '',
    'Above or below': '',
    'I want to receive notifications if {{granularity_select}} of consumption is {{alert_value}} {{alert_type}} {{alert_operation}} than the average of the last {{alert_period_quantity}}.': '',
    '% Consumption': '',
    'kWh Consumption': '',
    'I would like to receive a notification if the accumulated consumption during the period {{alert_period_quantity}} reaches {{alert_value}} $t(kWh).': '',
    'I would like to receive a notification if the consumption measured goes {{entity_status}} at any moment between {{alert_init_period}} and {{alert_end_period}}. The checking frequency is hourly.': '',
    'I would like to receive a notification if the sensor accumulated cost reaches {{currency_code}}{{alert_value}} in the period {{alert_period}}': '',
    '60 minutes': '',
    '24 hours': '',
    '7 days': '',
    '30 days': '',
    'off': '',
    'on': '',
    'hour': '',
    'calendar day': '',
    'calendar week': '',
    'calendar month': '',
    'Could not load tariffs.': '',
    'New Relative Consumption Alert': '',
    'Edit Relative Consumption Alert': '',
    'New Absolute Consumption Alert': '',
    'Edit Absolute Consumption Alert': '',
    'New Equipment Status Alert': '',
    'Edit Equipment Status Alert': '',
    'New Cost Alert': '',
    'Edit Cost Alert': '',
    'Is required': '',
    'Name is too long': '',
    'Alert saved successfully': '',
    'Alert created successfully': '',
    'An error occurred while creating the alert.': '',
    'Edit Alert': '',
    'New Alert': '',
    'Alert Name': '',
    'Subgroup - Optional': '',
    'Sensor - Optional': '',
    'Notifications:': '',
    'By email': '',
    'On the graph': '',
    'By email and on the graph': '',
    'Recipients': '',
    'ADD E-MAIL': '',
    'There are no recipients added': '',
    'CANCEL': '',
    'Save Alert': '',
    'Add Alert': '',
    'The group or sensor selected has no tariff set. Please set a tariff for it on Configuration > Group to create cost alerts.': '',
    'Alert successfully removed.': '',
    'An error occurred while trying to remove this alert.': '',
    'CREATE ALERT': '',
    'No alerts available': '',
    'If you close all unsaved information will be lost.': '',
    'I want to receive notifications if the {{alert_period}} accumulated cost reaches {{alert_value}} under the tariff set on configuration.': '',
    'I want to receive notifications if the {{alert_period}} accumulated consumption reaches {{alert_value}} $t(kWh).': '',
    'I want to receive notifications if the {{granularity_select}} consumption is {{alert_value}}$t(%) {{alert_operation}} the average of the last {{alert_period_quantity}}.': '',
    'I want to receive notifications if the {{granularity_select}} consumption is {{alert_value}} $t(kWh) {{alert_operation}} the average of the last {{alert_period_quantity}}.': '',
    'I want to receive notifications if the sensor goes {{entity_status}} {{alert_period}}.': '',
    'at any moment between {{alert_init_period}} and {{alert_end_period}}': '',
    'at any moment': '',
    'Details': '',
    'Show more': '',
    'Cost Alert': '',
    'Absolute Consumption': '',
    'Relative Consumption - %': '',
    'Relative Consumption - kWh': '',
    'Equipment Status Alert': '',
    'Alert category': '',
    'Group:': '',
    'Subgroup:': '',
    'Sensor:': '',
    'This report will be permanently removed.': '',
    'Choose Alert Type': '',
    'Absolute consumption': '',
    'Equipment status': '',
    'Relative consumption': '',
    'minute': '',
    'minutes': '',
    'hours': '',
    'days': '',
    'weeks': '',
    'months': '',
    'Minute': '',
    'above': '',
    'below': '',
    'either above or below': '',
    'Sensor {{sensor}} was {{status}}.': '',
    'Equipment went {{status}}': '',
    'The accumulated cost has reached {{value}} in the last {{granularity}}': '',
    'Sensor connected.': '',
    'Sensor disconnected.': '',
    '{{granularity}} consumption is {{value}} {{operation}} the average of the last {{avgQuantity}} {{avgGranularity}}': '',
    'The accumulated consumption has reached {{value}} kWh in the last {{granularity}}': '',
    'Could not load countries.': '',
    'New Tariff': '',
    'Please fill the information to add a new tariff.': '',
    'Add Tariff': '',
    'Edit Tariff': '',
    'Please fill the information to edit the tariff.': '',
    'Submit': '',
    'Please set at least one: a weekday, a time or a limit.': '',
    'Please select a start time and an end time.': '',
    'Please select a limit and its value.': '',
    'Please select an end time later than start time.': '',
    'The new tariff was successfully added.': '',
    'An error occurred while adding the tariff.': '',
    'An error occurred while editing the tariff.': '',
    'The tariff was successfully edited.': '',
    'Tariff Name': '',
    'Provider Name': '',
    'Select a country': '',
    'Standing Charge ({{currencySymbol}}/day)': '',
    'Peak and Off-Peak Tariffs': '',
    'Overlapping periods may result into calculation errors. In the case where the overlapping is mathematically consistent, the rate with the lowest granularity defined, among the possible ones, will be used in the calculation.': '',
    'More than or equal to': '',
    'Less than or equal to': '',
    'Monday': '',
    'Tuesday': '',
    'Wednesday': '',
    'Thursday': '',
    'Friday': '',
    'Saturday': '',
    'Sunday': '',
    'Weekday': '',
    'Start Time': '',
    'End Time': '',
    'Limit (kWh/month)': '',
    'Could not load countries data.': '',
    'CREATE TARIFF': '',
    'Reload': '',
    'No tariffs available': '',
    'Leave this section?': '',
    'If you leave this section, inputs you may have entered will not be saved.': '',
    'Yes, leave': '',
    'No, stay here': '',
    'Tariff successfully removed.': '',
    'Country:': '',
    'Standing charge per day:': '',
    'Off-peak time:': '',
    'Peak time:': '',
    'Peak rate:': '',
    'kWh/': '',
    'Currency:': '',
    'Standing charge:': '',
    'Week day:': '',
    'Start/End time:': '',
    'Limit:': '',
    'We are deleting {{tariffName}} tariff, please wait.': '',
    'The Tariff will be permanently deleted.': '',
    'Select weekday': '',
    'Add rate': '',
    'Select limit': '',
    'Add limit value': '',
    'Add standing charge': '',
    'Rate ({{currencySymbol}}/kWh)': '',
    'No sensors were selected for group nor for any subgroup. Please select at least one sensor and try again.': '',
    'Group added successfully.': '',
    'An error occurred while adding the group.': '',
    'Group edited successfully.': '',
    'An error occurred while editing the group.': '',
    'Back': '',
    'Skip': '',
    'Save and create group': '',
    'Next': '',
    'Total Load Sensor': '',
    'Total load sensor': '',
    'Sub Load Sensors': '',
    'Sub load sensors': '',
    '(If any)': '',
    'Label <small>(Optional)</small>': '',
    'Tag <small>(Optional)</small>': '',
    'Tariff <small>(Optional)</small>': '',
    'This groups has no subgroups.': '',
    'We are deleting the group {{groupName}}, please wait.': '',
    'The Group and all related Subgroups will be permanently deleted.': '',
    'Group Name': '',
    'Tag (Optional)': '',
    'The selected tags at any subgroup will not be listed here. Please remove the tag from its respective subgroup to be able to assign it for this group.': '',
    'Tariff (Optional)': '',
    'Review Details': '',
    'Tariff:': '',
    'Tag:': '',
    'No sensors were selected.': '',
    'Total Load Sensors': '',
    'Sub Load Sensor': '',
    'Label': '',
    'No subgroups were created.': '',
    'Group successfully removed.': '',
    'There was an error removing this group. Please, try again or contact support.': '',
    'CREATE GROUP': '',
    'No groups available': '',
    'Do you want to keep editing your data?': '',
    'You can start editing from where you last stopped for as long as you keep your session alive.': '',
    'Yes, Keep it': '',
    'No': '',
    'Sensor-phase': '',
    'Add a label': '',
    'Select or create a new Tag': '',
    'Select a Tariff': '',
    'There are no sensors-phases left to add.': '',
    'Some inputs are invalid or incomplete. Please correct them and try again.': '',
    'Subgroup Configurations': '',
    'Subgroup Name': '',
    'The selected tags at any subgroup or group will not be listed here. Please remove the tag from its respective entity to be able to assign it for this subgroup.': '',
    'Delete subgroup': '',
    'Add new subgroup': '',
    'This Subgroup will be permanently deleted.': '',
    'Yes, Delete it.': '',
    'Group Configuration': '',
    'Subgroups Configuration': '',
    'Level:': '',
    'Periodicity:': '',
    'Average of:': '',
    'Absolute Consumption Level': '',
    'Relative Consumption Level': '',
    'Same 3 Months': '',
    'No benchmarks available': '',
    'Sensor {{status}}': '',
    'Report successfully removed.': '',
    'An error occurred while trying to remove this report.': '',
    'CREATE REPORT': '',
    'No reports available': '',
    'An error occurred while generating you report.': '',
    'Daily': '',
    'Weekly': '',
    'Monthly': '',
    '{{period_quantity}} Months': '',
    '{{period_quantity}} Weeks': '',
    'Report saved successfully': '',
    'Report created successfully': '',
    'An error occurred while creating you report.': '',
    'Edit Report': '',
    'New Report': '',
    'Report Name': '',
    'Filters': '',
    'There is no recipients added': '',
    'Trigger': '',
    'Send a {{period}} report at the end of every {{trigger}}': '',
    'Preview': '',
    'Save Report': '',
    'Add Report': '',
    'Period:': '',
    'Send every:': '',
    'Status': '',
    'ID': '',
    'Allocated to': '',
    'Not allocated': '',
    'Never connected': '',
    '{{status}} since {{dateAndTime}}': '',
    'Online': '',
    'Offline': '',
    'No sensors set up yet.': '',
    'Select start time': '',
    'Select end time': '',
    'No consumption data': '',
    'benchmarking': '',
    'Subgroups': '',
    'Total Load Sensors:': '',
    'Select...': '',
    'Group Configurations': '',
    'Report {0} from {1} to {2}': '',
    'Alert {0} was triggered': '',
    'Dear Partner,': '',
    'Alert {0} was triggered at {1}.': '',
    'Description: ': '',
    'Consumption is {0}{1} {2} the average of the last {3} {4}.': '',
    '{0} was detected to be switched {1} during the check.': '',
    '{0} accumulated consumption surpassed the threshold defined at {1}.': '',
    '{0} accumulated cost surpassed the threshold defined at {1}.': '',
    'Please access ': '',
    'here': '',
    'for more info.': '',
    'You can disable this alert by erasing the email from the list at ': '',
    'Best regards,': '',
    'or': '',
    'Voltaware reset password information': '',
    'Hi,': '',
    'You have requested to reset your Voltaware Building Monitoring Dashboard password. Your new password is:': '',
    'If you have not requested to reset your password please contact our customer service immediately.': '',
    'Kind regards,': '',
    'The Voltaware Team': '',
    'No results found': '',
    'There is no data available as no tariff has been set up. Cost simulation can\'t be calculated in this case.': '',
    'Home': '',
    'Equipment Status': '',
    'Total aggregated consumption': '',
    'Total aggregated cost': '',
    'Others, aggregated': '',
    'Most Consuming - Groups': '',
    'There is no data available': '',
    'Most Consuming - Sensors': '',
    'Cost data has more than one currency. Visualisations are showing only the most consuming currency.': '',
    'Most Costly - Groups': '',
    'Click {{here_link}} to go to the last visited page': '',
    'Most Costly - Sensors': '',
    'Percentage change in relation to last period': '',
    'Percentage change not applicable, as previous period has no data': '',
    'N/A': '',
    'Recent Events': '',
    'Power Peak': '',
    '**': '',
    'kW': '',
    'The peak demand was of': '',
    'on {{peak_time}}': '',
    '{0} power demand went above {1} kW.': '',
    'The power demand went above {{value}} kW in the last {{granularity}}': '',
    'I would like to receive a notification if the active power goes above {{alert_value}} kW in the last {{alert_period}}.': '',
    'New Power Alert': '',
    'Edit Power Alert': '',
    'Power Alert': '',
    'I want to receive notifications if the active power goes above {{alert_value}} $t(kW) in the last {{alert_period}}.': '',
    'Users': '',
    'E-mail': '',
    'Role': '',
    'Created At': '',
    'Enabled': '',
    'Administrator': '',
    'Read-only': '',
    'CREATE USER': '',
    'Edit user': '',
    'Delete user': '',
    'No users yet.': '',
    'If you delete, all the user information will be lost.': '',
    'Read-only user': '',
    'Disabled': '',
    'User saved successfully': '',
    'User created successfully': '',
    'An error occurred while creating the user.': '',
    'Edit User': '',
    'New User': '',
    'User login e-mail': '',
    'Save User': '',
    'Add User': '',
    'User deleted successfully': '',
    'Welcome to Voltaware': '',
    'Greetings,': '',
    'Welcome to Voltaware Electricity Monitoring Dashboard': '',
    'You can access the login page': '',
    'Your credentials are:': '',
    'User:': '',
    'password:': '',
    'Use the same password required for all our services.': '',
    'For support requests, please contact us by going to our support page.': '',
    'Many thanks,': '',
    'You have requested to reset your Voltaware Electricity Monitoring Dashboard password. Your new password is:': '',
    '(all phases)': '',
    'GENERATE REPORT': '',
    'Generate Instant Report': '',
    'Select a group, a subgroup or a sensor': '',
    'Select a period': '',
    'Generate Report': '',
    'I want to receive notifications if the {{alert_period}} accumulated cost reaches {{alert_value}} under the tariff set on configuration._male': '',
    'I want to receive notifications if the {{alert_period}} accumulated cost reaches {{alert_value}} under the tariff set on configuration._female': '',
    'I want to receive notifications if the {{alert_period}} accumulated consumption reaches {{alert_value}} $t(kWh)._male': '',
    'I want to receive notifications if the {{alert_period}} accumulated consumption reaches {{alert_value}} $t(kWh)._female': '',
    'I want to receive notifications if the {{granularity_select}} consumption is {{alert_value}}$t(%) {{alert_operation}} the average of the last {{alert_period_quantity}}._male': '',
    'I want to receive notifications if the {{granularity_select}} consumption is {{alert_value}}$t(%) {{alert_operation}} the average of the last {{alert_period_quantity}}._female': '',
    'I want to receive notifications if the {{granularity_select}} consumption is {{alert_value}} $t(kWh) {{alert_operation}} the average of the last {{alert_period_quantity}}._male': '',
    'I want to receive notifications if the {{granularity_select}} consumption is {{alert_value}} $t(kWh) {{alert_operation}} the average of the last {{alert_period_quantity}}._female': '',
    'The accumulated cost has reached {{value}} in the last {{granularity}}_male': '',
    'The accumulated cost has reached {{value}} in the last {{granularity}}_female': '',
    'The accumulated consumption has reached {{value}} kWh in the last {{granularity}}_male': '',
    'The accumulated consumption has reached {{value}} kWh in the last {{granularity}}_female': '',
    'above or below': '',
    '{{granularity}} consumption is {{value}} {{operation}} the average of the last {{avgQuantity}} {{avgGranularity}}_male': '',
    '{{granularity}} consumption is {{value}} {{operation}} the average of the last {{avgQuantity}} {{avgGranularity}}_female': '',
    'Tenants': '',
    'Login as root': '',
    'Change language to brazilian portuguese': '',
    'There was an error trying to signin as user': '',
    'CREATE CLIENT': '',
    'No clients available': '',
    'Primary color:': '',
    'Default': '',
    'Secondary color:': '',
    'Login as client': '',
    'An error occurred while getting the sensors.': '',
    'Sensor Management': '',
    'Select the sensors': '',
    'Save': '',
    'Client saved successfully': '',
    'Client created successfully': '',
    'An error occurred while creating the client.': '',
    'Edit Client': '',
    'New Client': '',
    'Client name': '',
    'Save Client': '',
    'Add Client': '',
    'Users disabled successfully': '',
    'An error occurred while disabling the users': '',
    'Users enabled successfully': '',
    'An error occurred while enabling the users': '',
    'DISABLE ALL': '',
    'ENABLE ALL': '',
    'Login as user': '',
    'Year': '',
    'Select the year': '',
    'This Week': '',
    'This Month': '',
    'Last Day': '',
    'Last Week': '',
    'Last Month': '',
    'Sensors allocated successfully': '',
    'An error occurred while allocating the sensor.': '',
    'An error occurred while selecting the logo image.': '',
    'Primary color': '',
    'Secondary color': '',
    'Logo image to replace': '',
    'Logo image': '',
    'Remove current logo image': '',
    'Show Powered by Voltaware logo': '',
  },
  'options': {
  },
  'fr': {
    'An error occurred while retrieving data': 'An error occurred while retrieving data.',
    'Loading data': 'Loading data...',
    'There is no data available for the selected criteria': 'There is no data available for the selected criteria',
    'There is no data available as no group has been set up': 'There is no data available as no group has been set up.',
    '5 Min': '',
    '30 Min': '',
    '1 Hour': '',
    'Day': '',
    'Week': '',
    'Month': '',
    'Custom': '',
    '24 Hours': '',
    '1 Week': '',
    '1 Month': '',
    'Total Cost': '',
    'There is no data available as no sensor has been set up': 'There is no data available as no sensor has been set up.',
    'Could not load sensors data': 'Could not load sensors data.',
    'Unknown group/sensor': '',
    'Active Power': '',
    'Reactive Power': '',
    'NOW': '',
    'Select any sensor-phase (type to search)': '',
    'Max': '',
    'Min': '',
    'Avg': '',
    'No sensors setup': 'No sensors setup. Please contact support.',
    'A new password was sent to your email': 'A new password was sent to your email.',
    'There was an error resetting the password': 'There was an error resetting the password.',
    'Please fill the username correctly': 'Please fill the username correctly.',
    'Sending the email': 'Sending the email...',
    'voltaware building electricity monitoring logo': '',
    'Login': '',
    'Username': '',
    'Password': '',
    'Sign in': '',
    'Forgot your password?': '',
    'For a better experience on Electricity Monitoring Dashboard use {{firefox}} or {{chrome}}': 'For a better experience on Electricity Monitoring Dashboard use {{firefox}} or {{chrome}}.',
    'Change language to english': '',
    'Change language to russian': '',
    'Firefox': '',
    'Chrome': '',
    'There are intersection between two compositions': 'There is a conflict in Peak and Off-Peak Tariffs table. Please check if there\'s overlapping in time related inputs or if there are limits with identical inputs.',
    'There was an error removing this tariff': 'There was an error removing this tariff.  Please, try again or contact support.',
    'Tariff couldn\'t be deleted because the group(s) {{tariffGroupNames}} use(s) this tariff': 'Tariff couldn\'t be deleted because the group(s) {{tariffGroupNames}} use(s) this tariff.',
    'Benchmark': '',
    'Select any benchmark': '',
    'Fixed Benchmark': '',
    'Below benchmark': '',
    'Above benchmark': '',
    'kWh': '',
    '1 Day': '',
    'View Only - This feature is currently under development': '',
    'Type': '',
    'Total Consumption': '',
    'Total Savings': '',
    'Total Losses': '',
    'Absolute': '',
    'Relative Benchmark': '',
    'Relative': '',
    'Group': '',
    'Select any group': '',
    'Subgroup': '',
    'Select any subgroup': '',
    'Sensor': '',
    'Select any sensor': '',
    'Tag': '',
    'Select any tag': '',
    'Consumption': '',
    'Power': '',
    'Cost': '',
    'Groups': '',
    'Tariffs': '',
    'Benchmarking': '',
    'Analysis': '',
    'Resources': '',
    'Reports': '',
    'Alerts': '',
    'Configuration': '',
    'Tutorials': '',
    'Logout': '',
    'ADD GROUP': '',
    'line graph': '',
    'stream graph': '',
    'heatmap': '',
    'geolocation': '',
    'cumulative': '',
    'benchmark': '',
    'Events': '',
    'Real Cost': '',
    'Cost Simulation': '',
    'Please try again': 'Please, try again or contact support.',
    'Sample Group': '',
    'Graph interpolation': '',
    'Linear': '',
    'Step': '',
    'Show alerts': '',
    'Off': '',
    'On': '',
    'No group setup': 'No group setup. Please setup a group in "Configurations > Groups > Create Group".',
    'Could not load the tags': 'Could not load the tags.',
    'Could not load groups data': 'Could not load groups data.',
    'Could not load alert events data': 'Could not load alert events data.',
    'Could not load chart data': 'Could not load chart data.',
    'Check alert': '',
    '(estimated)': '',
    '(partially estimated)': '',
    'This group has no subgroups': '',
    'This subgroup has no sensors': '',
    'Empty data': '',
    'Entries values length are not equal': '',
    'Graph View': '',
    'Show Parent': '',
    'Total': '',
    'Group / Subgroup': '',
    'Please, try again or contact support.': '',
    'There was an error resetting the password.': '',
    'Sending the email...': '',
    '*': '',
    'An error occurred while retrieving data.': '',
    'Loading data...': '',
    'There is no data available as no group has been set up.': '',
    'Data cannot be displayed due to the tariffs for the tag elements being set in different currencies.': '',
    'Check alert:': '',
    'Total Cost:': '',
    'Rate:': '',
    'Standing Charge:': '',
    'Tariff Calculation': '',
    'Sum of Parts': '',
    'Group / Subgroup / Sensor': '',
    'Tariff': '',
    'Rate': '',
    'Standing Charge': '',
    'Start Date': '',
    'End Date': '',
    'No group setup. Please setup a group in "Configurations > Groups > Create Group".': '',
    'Could not load sensors data.': '',
    'Could not load chart data.': '',
    'No sensors setup. Please contact support.': '',
    'Chart may be outdated. Please press "Simulate Costs" after setting the filters.': '',
    'No tariff setup. Please setup a tariff in "Configurations > Tariff > Create Tariff".': '',
    'Period Range': '',
    '(optional)': '',
    'Start date': '',
    'End date': '',
    'ADD TARIFF': '',
    'SIMULATE COSTS': '',
    'connected': '',
    'disconnected': '',
    'Could not delete the event.': '',
    'Could not load event details.': '',
    'Delete event': '',
    'Edit event': '',
    'Show event': '',
    'Alert:': '',
    'Last Triggered:': '',
    'History': '',
    'Date': '',
    'Hour': '',
    'Alert name:': '',
    'Online Sensor System Alert': '',
    'Offline Sensor System Alert': '',
    'Created at:': '',
    'Alert created by:': '',
    'EDIT ALERT': '',
    'Are you sure?': '',
    'If you delete you won\'t see this event anymore.': '',
    'Yes': '',
    'Cancel': '',
    'Triggered': '',
    'Alert': '',
    'All Alerts': '',
    'Unread Alerts': '',
    'Read Alerts': '',
    'Events updated successfully': '',
    'An error occurred while updating your events.': '',
    'Event updated successfully': '',
    'An error occurred while updating your event.': '',
    'Event deleted successfully': '',
    'An error occurred while deleting your event.': '',
    'Events deleted successfully': '',
    'An error occurred while deleting your events.': '',
    'Set all as unread': '',
    'Set all as read': '',
    'Previous Page': '',
    'Next Page': '',
    'Filter list': '',
    'Open event': '',
    'Set as unread': '',
    'Set as read': '',
    'Go to chart': '',
    'No alert events yet.': '',
    'Last 10 days': '',
    'Consumption:': '',
    'BACK': '',
    'An error occurred while loading the groups.': '',
    'Sensors Count:': '',
    'Total Consumption:': '',
    'Today:': '',
    'Granularity': '',
    'Max:': '',
    'Min:': '',
    'Avg:': '',
    'GO TO ANALYSIS': '',
    'An error occurred while updating the event': '',
    'There is no consumption data available for the selected criteria. Cost simulation can\'t be calculated in this case.': '',
    'Today': '',
    'This {{period}}': '',
    'Select date': '',
    'Date Range': '',
    'Select date range': '',
    'Reset Date Range': '',
    'month': '',
    'day': '',
    'week': '',
    'custom': '',
    'Invalid': '',
    'Already exists': '',
    'New Recipient': '',
    'Recipient e-mail': '',
    'Add Recipient': '',
    'Above': '',
    'Below': '',
    'Above or below': '',
    'I want to receive notifications if {{granularity_select}} of consumption is {{alert_value}} {{alert_type}} {{alert_operation}} than the average of the last {{alert_period_quantity}}.': '',
    '% Consumption': '',
    'kWh Consumption': '',
    'I would like to receive a notification if the accumulated consumption during the period {{alert_period_quantity}} reaches {{alert_value}} $t(kWh).': '',
    'I would like to receive a notification if the consumption measured goes {{entity_status}} at any moment between {{alert_init_period}} and {{alert_end_period}}. The checking frequency is hourly.': '',
    'I would like to receive a notification if the sensor accumulated cost reaches {{currency_code}}{{alert_value}} in the period {{alert_period}}': '',
    '60 minutes': '',
    '24 hours': '',
    '7 days': '',
    '30 days': '',
    'off': '',
    'on': '',
    'hour': '',
    'calendar day': '',
    'calendar week': '',
    'calendar month': '',
    'Could not load tariffs.': '',
    'New Relative Consumption Alert': '',
    'Edit Relative Consumption Alert': '',
    'New Absolute Consumption Alert': '',
    'Edit Absolute Consumption Alert': '',
    'New Equipment Status Alert': '',
    'Edit Equipment Status Alert': '',
    'New Cost Alert': '',
    'Edit Cost Alert': '',
    'Is required': '',
    'Name is too long': '',
    'Alert saved successfully': '',
    'Alert created successfully': '',
    'An error occurred while creating the alert.': '',
    'Edit Alert': '',
    'New Alert': '',
    'Alert Name': '',
    'Subgroup - Optional': '',
    'Sensor - Optional': '',
    'Notifications:': '',
    'By email': '',
    'On the graph': '',
    'By email and on the graph': '',
    'Recipients': '',
    'ADD E-MAIL': '',
    'There are no recipients added': '',
    'CANCEL': '',
    'Save Alert': '',
    'Add Alert': '',
    'The group or sensor selected has no tariff set. Please set a tariff for it on Configuration > Group to create cost alerts.': '',
    'Alert successfully removed.': '',
    'An error occurred while trying to remove this alert.': '',
    'CREATE ALERT': '',
    'No alerts available': '',
    'If you close all unsaved information will be lost.': '',
    'I want to receive notifications if the {{alert_period}} accumulated cost reaches {{alert_value}} under the tariff set on configuration.': '',
    'I want to receive notifications if the {{alert_period}} accumulated consumption reaches {{alert_value}} $t(kWh).': '',
    'I want to receive notifications if the {{granularity_select}} consumption is {{alert_value}}$t(%) {{alert_operation}} the average of the last {{alert_period_quantity}}.': '',
    'I want to receive notifications if the {{granularity_select}} consumption is {{alert_value}} $t(kWh) {{alert_operation}} the average of the last {{alert_period_quantity}}.': '',
    'I want to receive notifications if the sensor goes {{entity_status}} {{alert_period}}.': '',
    'at any moment between {{alert_init_period}} and {{alert_end_period}}': '',
    'at any moment': '',
    'Details': '',
    'Show more': '',
    'Cost Alert': '',
    'Absolute Consumption': '',
    'Relative Consumption - %': '',
    'Relative Consumption - kWh': '',
    'Equipment Status Alert': '',
    'Alert category': '',
    'Group:': '',
    'Subgroup:': '',
    'Sensor:': '',
    'This report will be permanently removed.': '',
    'Choose Alert Type': '',
    'Absolute consumption': '',
    'Equipment status': '',
    'Relative consumption': '',
    'minute': '',
    'minutes': '',
    'hours': '',
    'days': '',
    'weeks': '',
    'months': '',
    'Minute': '',
    'above': '',
    'below': '',
    'either above or below': '',
    'Sensor {{sensor}} was {{status}}.': '',
    'Equipment went {{status}}': '',
    'The accumulated cost has reached {{value}} in the last {{granularity}}': '',
    'Sensor connected.': '',
    'Sensor disconnected.': '',
    '{{granularity}} consumption is {{value}} {{operation}} the average of the last {{avgQuantity}} {{avgGranularity}}': '',
    'The accumulated consumption has reached {{value}} kWh in the last {{granularity}}': '',
    'Could not load countries.': '',
    'New Tariff': '',
    'Please fill the information to add a new tariff.': '',
    'Add Tariff': '',
    'Edit Tariff': '',
    'Please fill the information to edit the tariff.': '',
    'Submit': '',
    'Please set at least one: a weekday, a time or a limit.': '',
    'Please select a start time and an end time.': '',
    'Please select a limit and its value.': '',
    'Please select an end time later than start time.': '',
    'The new tariff was successfully added.': '',
    'An error occurred while adding the tariff.': '',
    'An error occurred while editing the tariff.': '',
    'The tariff was successfully edited.': '',
    'Tariff Name': '',
    'Provider Name': '',
    'Select a country': '',
    'Standing Charge ({{currencySymbol}}/day)': '',
    'Peak and Off-Peak Tariffs': '',
    'Overlapping periods may result into calculation errors. In the case where the overlapping is mathematically consistent, the rate with the lowest granularity defined, among the possible ones, will be used in the calculation.': '',
    'More than or equal to': '',
    'Less than or equal to': '',
    'Monday': '',
    'Tuesday': '',
    'Wednesday': '',
    'Thursday': '',
    'Friday': '',
    'Saturday': '',
    'Sunday': '',
    'Weekday': '',
    'Start Time': '',
    'End Time': '',
    'Limit (kWh/month)': '',
    'Could not load countries data.': '',
    'CREATE TARIFF': '',
    'Reload': '',
    'No tariffs available': '',
    'Leave this section?': '',
    'If you leave this section, inputs you may have entered will not be saved.': '',
    'Yes, leave': '',
    'No, stay here': '',
    'Tariff successfully removed.': '',
    'Country:': '',
    'Standing charge per day:': '',
    'Off-peak time:': '',
    'Peak time:': '',
    'Peak rate:': '',
    'kWh/': '',
    'Currency:': '',
    'Standing charge:': '',
    'Week day:': '',
    'Start/End time:': '',
    'Limit:': '',
    'We are deleting {{tariffName}} tariff, please wait.': '',
    'The Tariff will be permanently deleted.': '',
    'Select weekday': '',
    'Add rate': '',
    'Select limit': '',
    'Add limit value': '',
    'Add standing charge': '',
    'Rate ({{currencySymbol}}/kWh)': '',
    'No sensors were selected for group nor for any subgroup. Please select at least one sensor and try again.': '',
    'Group added successfully.': '',
    'An error occurred while adding the group.': '',
    'Group edited successfully.': '',
    'An error occurred while editing the group.': '',
    'Back': '',
    'Skip': '',
    'Save and create group': '',
    'Next': '',
    'Total Load Sensor': '',
    'Total load sensor': '',
    'Sub Load Sensors': '',
    'Sub load sensors': '',
    '(If any)': '',
    'Label <small>(Optional)</small>': '',
    'Tag <small>(Optional)</small>': '',
    'Tariff <small>(Optional)</small>': '',
    'This groups has no subgroups.': '',
    'We are deleting the group {{groupName}}, please wait.': '',
    'The Group and all related Subgroups will be permanently deleted.': '',
    'Group Name': '',
    'Tag (Optional)': '',
    'The selected tags at any subgroup will not be listed here. Please remove the tag from its respective subgroup to be able to assign it for this group.': '',
    'Tariff (Optional)': '',
    'Review Details': '',
    'Tariff:': '',
    'Tag:': '',
    'No sensors were selected.': '',
    'Total Load Sensors': '',
    'Sub Load Sensor': '',
    'Label': '',
    'No subgroups were created.': '',
    'Group successfully removed.': '',
    'There was an error removing this group. Please, try again or contact support.': '',
    'CREATE GROUP': '',
    'No groups available': '',
    'Do you want to keep editing your data?': '',
    'You can start editing from where you last stopped for as long as you keep your session alive.': '',
    'Yes, Keep it': '',
    'No': '',
    'Sensor-phase': '',
    'Add a label': '',
    'Select or create a new Tag': '',
    'Select a Tariff': '',
    'There are no sensors-phases left to add.': '',
    'Some inputs are invalid or incomplete. Please correct them and try again.': '',
    'Subgroup Configurations': '',
    'Subgroup Name': '',
    'The selected tags at any subgroup or group will not be listed here. Please remove the tag from its respective entity to be able to assign it for this subgroup.': '',
    'Delete subgroup': '',
    'Add new subgroup': '',
    'This Subgroup will be permanently deleted.': '',
    'Yes, Delete it.': '',
    'Group Configuration': '',
    'Subgroups Configuration': '',
    'Level:': '',
    'Periodicity:': '',
    'Average of:': '',
    'Absolute Consumption Level': '',
    'Relative Consumption Level': '',
    'Same 3 Months': '',
    'No benchmarks available': '',
    'Sensor {{status}}': '',
    'Report successfully removed.': '',
    'An error occurred while trying to remove this report.': '',
    'CREATE REPORT': '',
    'No reports available': '',
    'An error occurred while generating you report.': '',
    'Daily': '',
    'Weekly': '',
    'Monthly': '',
    '{{period_quantity}} Months': '',
    '{{period_quantity}} Weeks': '',
    'Report saved successfully': '',
    'Report created successfully': '',
    'An error occurred while creating you report.': '',
    'Edit Report': '',
    'New Report': '',
    'Report Name': '',
    'Filters': '',
    'There is no recipients added': '',
    'Trigger': '',
    'Send a {{period}} report at the end of every {{trigger}}': '',
    'Preview': '',
    'Save Report': '',
    'Add Report': '',
    'Period:': '',
    'Send every:': '',
    'Status': '',
    'ID': '',
    'Allocated to': '',
    'Not allocated': '',
    'Never connected': '',
    '{{status}} since {{dateAndTime}}': '',
    'Online': '',
    'Offline': '',
    'No sensors set up yet.': '',
    'Select start time': '',
    'Select end time': '',
    'No consumption data': '',
    'benchmarking': '',
    'Subgroups': '',
    'Total Load Sensors:': '',
    'Select...': '',
    'Group Configurations': '',
    'Report {0} from {1} to {2}': '',
    'Alert {0} was triggered': '',
    'Dear Partner,': '',
    'Alert {0} was triggered at {1}.': '',
    'Description: ': '',
    'Consumption is {0}{1} {2} the average of the last {3} {4}.': '',
    '{0} was detected to be switched {1} during the check.': '',
    '{0} accumulated consumption surpassed the threshold defined at {1}.': '',
    '{0} accumulated cost surpassed the threshold defined at {1}.': '',
    'Please access ': '',
    'here': '',
    'for more info.': '',
    'You can disable this alert by erasing the email from the list at ': '',
    'Best regards,': '',
    'or': '',
    'Voltaware reset password information': '',
    'Hi,': '',
    'You have requested to reset your Voltaware Building Monitoring Dashboard password. Your new password is:': '',
    'If you have not requested to reset your password please contact our customer service immediately.': '',
    'Kind regards,': '',
    'The Voltaware Team': '',
    'No results found': '',
    'There is no data available as no tariff has been set up. Cost simulation can\'t be calculated in this case.': '',
    'Home': '',
    'Equipment Status': '',
    'Total aggregated consumption': '',
    'Total aggregated cost': '',
    'Others, aggregated': '',
    'Most Consuming - Groups': '',
    'There is no data available': '',
    'Most Consuming - Sensors': '',
    'Cost data has more than one currency. Visualisations are showing only the most consuming currency.': '',
    'Most Costly - Groups': '',
    'Click {{here_link}} to go to the last visited page': '',
    'Most Costly - Sensors': '',
    'Percentage change in relation to last period': '',
    'Percentage change not applicable, as previous period has no data': '',
    'N/A': '',
    'Recent Events': '',
    'Power Peak': '',
    '**': '',
    'kW': '',
    'The peak demand was of': '',
    'on {{peak_time}}': '',
    '{0} power demand went above {1} kW.': '',
    'The power demand went above {{value}} kW in the last {{granularity}}': '',
    'I would like to receive a notification if the active power goes above {{alert_value}} kW in the last {{alert_period}}.': '',
    'New Power Alert': '',
    'Edit Power Alert': '',
    'Power Alert': '',
    'I want to receive notifications if the active power goes above {{alert_value}} $t(kW) in the last {{alert_period}}.': '',
    'Users': '',
    'E-mail': '',
    'Role': '',
    'Created At': '',
    'Enabled': '',
    'Administrator': '',
    'Read-only': '',
    'CREATE USER': '',
    'Edit user': '',
    'Delete user': '',
    'No users yet.': '',
    'If you delete, all the user information will be lost.': '',
    'Read-only user': '',
    'Disabled': '',
    'User saved successfully': '',
    'User created successfully': '',
    'An error occurred while creating the user.': '',
    'Edit User': '',
    'New User': '',
    'User login e-mail': '',
    'Save User': '',
    'Add User': '',
    'User deleted successfully': '',
    'Welcome to Voltaware': '',
    'Greetings,': '',
    'Welcome to Voltaware Electricity Monitoring Dashboard': '',
    'You can access the login page': '',
    'Your credentials are:': '',
    'User:': '',
    'password:': '',
    'Use the same password required for all our services.': '',
    'For support requests, please contact us by going to our support page.': '',
    'Many thanks,': '',
    'You have requested to reset your Voltaware Electricity Monitoring Dashboard password. Your new password is:': '',
    '(all phases)': '',
    'GENERATE REPORT': '',
    'Generate Instant Report': '',
    'Select a group, a subgroup or a sensor': '',
    'Select a period': '',
    'Generate Report': '',
    'I want to receive notifications if the {{alert_period}} accumulated cost reaches {{alert_value}} under the tariff set on configuration._male': '',
    'I want to receive notifications if the {{alert_period}} accumulated cost reaches {{alert_value}} under the tariff set on configuration._female': '',
    'I want to receive notifications if the {{alert_period}} accumulated consumption reaches {{alert_value}} $t(kWh)._male': '',
    'I want to receive notifications if the {{alert_period}} accumulated consumption reaches {{alert_value}} $t(kWh)._female': '',
    'I want to receive notifications if the {{granularity_select}} consumption is {{alert_value}}$t(%) {{alert_operation}} the average of the last {{alert_period_quantity}}._male': '',
    'I want to receive notifications if the {{granularity_select}} consumption is {{alert_value}}$t(%) {{alert_operation}} the average of the last {{alert_period_quantity}}._female': '',
    'I want to receive notifications if the {{granularity_select}} consumption is {{alert_value}} $t(kWh) {{alert_operation}} the average of the last {{alert_period_quantity}}._male': '',
    'I want to receive notifications if the {{granularity_select}} consumption is {{alert_value}} $t(kWh) {{alert_operation}} the average of the last {{alert_period_quantity}}._female': '',
    'The accumulated cost has reached {{value}} in the last {{granularity}}_male': '',
    'The accumulated cost has reached {{value}} in the last {{granularity}}_female': '',
    'The accumulated consumption has reached {{value}} kWh in the last {{granularity}}_male': '',
    'The accumulated consumption has reached {{value}} kWh in the last {{granularity}}_female': '',
    'above or below': '',
    '{{granularity}} consumption is {{value}} {{operation}} the average of the last {{avgQuantity}} {{avgGranularity}}_male': '',
    '{{granularity}} consumption is {{value}} {{operation}} the average of the last {{avgQuantity}} {{avgGranularity}}_female': '',
    'Tenants': '',
    'Login as root': '',
    'Change language to brazilian portuguese': '',
    'There was an error trying to signin as user': '',
    'CREATE CLIENT': '',
    'No clients available': '',
    'Primary color:': '',
    'Default': '',
    'Secondary color:': '',
    'Login as client': '',
    'An error occurred while getting the sensors.': '',
    'Sensor Management': '',
    'Select the sensors': '',
    'Save': '',
    'Client saved successfully': '',
    'Client created successfully': '',
    'An error occurred while creating the client.': '',
    'Edit Client': '',
    'New Client': '',
    'Client name': '',
    'Save Client': '',
    'Add Client': '',
    'Users disabled successfully': '',
    'An error occurred while disabling the users': '',
    'Users enabled successfully': '',
    'An error occurred while enabling the users': '',
    'DISABLE ALL': '',
    'ENABLE ALL': '',
    'Login as user': '',
    'Year': '',
    'Select the year': '',
    'This Week': '',
    'This Month': '',
    'Last Day': '',
    'Last Week': '',
    'Last Month': '',
    'Sensors allocated successfully': '',
    'An error occurred while allocating the sensor.': '',
    'An error occurred while selecting the logo image.': '',
    'Primary color': '',
    'Secondary color': '',
    'Logo image to replace': '',
    'Logo image': '',
    'Remove current logo image': '',
    'Show Powered by Voltaware logo': '',
  },
  'pt-br': {
    'An error occurred while retrieving data': 'Um erro ocorreu ao carregar os dados.',
    'Loading data': 'Carregando dados...',
    'There is no data available for the selected criteria': 'Não há dados disponíveis para os critérios selecionados.',
    'There is no data available as no group has been set up': 'Não há dados disponíveis pois nenhum grupo foi configurado.',
    '5 Min': '5 Min',
    '30 Min': '30 Min',
    '1 Hour': '1 Hora',
    'Day': 'Dia',
    'Week': 'Semana',
    'Month': 'Mês',
    'Custom': 'Customizável',
    '24 Hours': '24 Horas',
    '1 Week': '1 semana',
    '1 Month': '1 mes',
    'Total Cost': 'Custo Total',
    'There is no data available as no sensor has been set up': 'Não há dados disponíveis pois nenhum sensor foi configurado.',
    'Could not load sensors data': 'Não foi possível carregar os dados dos sensores.',
    'Unknown group/sensor': 'Grupo ou sensor desconhecido.',
    'Active Power': 'Potência Ativa',
    'Reactive Power': 'Potência Reativa',
    'NOW': 'Agora',
    'Select any sensor-phase (type to search)': 'Selecione um sensor-fase (digite para procurar)',
    'Max': 'Máx.',
    'Min': 'Min',
    'Avg': 'Média',
    'No sensors setup': 'Não há sensores configurados. Contate o suporte.',
    'A new password was sent to your email': 'Uma nova senha foi enviada para o seu e-mail.',
    'There was an error resetting the password': 'Houve um erro ao criar sua nova senha.',
    'Please fill the username correctly': 'Preencha o nome de usuário corretamente.',
    'Sending the email': 'Enviando e-mail...',
    'voltaware building electricity monitoring logo': 'Voltaware EMD logo',
    'Login': 'Login',
    'Username': 'Nome de usuário',
    'Password': 'Senha',
    'Sign in': 'Entrar',
    'Forgot your password?': 'Esqueceu a senha?',
    'For a better experience on Electricity Monitoring Dashboard use {{firefox}} or {{chrome}}': 'Para uma melhor experiência no Electricity Monitoring Dashboard use {{firefox}} ou {{chrome}}.',
    'Change language to english': 'Mudar idioma para o inglês',
    'Change language to russian': 'Mudar idioma para o russo',
    'Firefox': 'Firefox',
    'Chrome': 'Chrome',
    'There are intersection between two compositions': 'Houve um conflito no cálculo das tarifas nos períodos de pico e fora de pico. Por favor, verifique se há intersecção dos períodos de tempo ou se há ambiguidades na definição dos mesmos.',
    'There was an error removing this tariff': 'Houve um erro ao remover esta tarifa. Por favor, tente novamente ou contate o suporte.',
    'Tariff couldn\'t be deleted because the group(s) {{tariffGroupNames}} use(s) this tariff': 'A tarifa não pode ser deletada porque está sendo usada no(s) seguintes grupo(s) {{tariffGroupNames}}',
    'Benchmark': 'Benchmark',
    'Select any benchmark': 'Selecione um benchmark',
    'Fixed Benchmark': 'Benchmark Fixo',
    'Below benchmark': 'Abaixo do benchmark',
    'Above benchmark': 'Acima do benchmark',
    'kWh': 'kWh',
    '1 Day': '1 Dia',
    'View Only - This feature is currently under development': 'Modo Visualização - Esta funcionalidade está em desenvolvimento',
    'Type': 'Tipo',
    'Total Consumption': 'Consumo Total',
    'Total Savings': 'Economia Total',
    'Total Losses': 'Perdas Totais',
    'Absolute': 'Absoluto',
    'Relative Benchmark': 'Benchmark Relativo',
    'Relative': 'Relativo',
    'Group': 'Grupo',
    'Select any group': 'Selecione um grupo',
    'Subgroup': 'Subgrupo',
    'Select any subgroup': 'Selecione um subgrupo',
    'Sensor': 'Sensor',
    'Select any sensor': 'Selecione um sensor',
    'Tag': 'Tag',
    'Select any tag': 'Selecione uma tag',
    'Consumption': 'Consumo',
    'Power': 'Potência',
    'Cost': 'Custo',
    'Groups': 'Grupos',
    'Tariffs': 'Tarifas',
    'Benchmarking': 'Benchmarking',
    'Analysis': 'Análise',
    'Resources': 'Recursos',
    'Reports': 'Relatórios',
    'Alerts': 'Alertas',
    'Configuration': 'Configuração',
    'Tutorials': 'Tutoriais',
    'Logout': 'Sair',
    'ADD GROUP': 'ADICIONAR GRUPO',
    'line graph': 'Gráfico de linha',
    'stream graph': 'Gráfico de Fluxo',
    'heatmap': 'Mapas de calor',
    'geolocation': 'geolocalização',
    'cumulative': 'acumulado',
    'benchmark': 'benchmark',
    'Events': 'Eventos',
    'Real Cost': 'Custo real',
    'Cost Simulation': 'Custo simulado',
    'Please try again': 'Tente novamente ou contate o suporte.',
    'Sample Group': 'Grupo Amostral',
    'Graph interpolation': 'Visualização do gráfico',
    'Linear': 'Linear',
    'Step': 'Step',
    'Show alerts': 'Mostrar alertas',
    'Off': 'Desligado',
    'On': 'Ligado',
    'No group setup': 'Não há grupos configurados. Crie um grupo em "Configurações>Grupos>Criar Grupo"',
    'Could not load the tags': 'Não foi possível carregar as tags.',
    'Could not load groups data': 'Nãoo foi possível carregar os dados dos grupos.',
    'Could not load alert events data': 'Não foi possível carregar os eventos de alerta.',
    'Could not load chart data': 'Não foi possível carregar os dados do gráfico.',
    'Check alert': 'Alerta',
    '(estimated)': '(estimado)',
    '(partially estimated)': '(parcialmente estimado)',
    'This group has no subgroups': 'Este grupo não possui subgrupos.',
    'This subgroup has no sensors': 'Este subgrupo não possui sensores.',
    'Empty data': 'Dados vazios',
    'Entries values length are not equal': 'O tamanho dos valores nao é similar.',
    'Graph View': 'Visualização do gráfico',
    'Show Parent': 'Mostrar grupo pai',
    'Total': 'Total',
    'Group / Subgroup': 'Grupo/Subgrupo',
    'Please, try again or contact support.': 'Por favor, tente novamente ou contate o suporte',
    'There was an error resetting the password.': 'Houve um erro ao resetar sua senha.',
    'Sending the email...': 'Enviando e-mail...',
    '*': '*',
    'An error occurred while retrieving data.': 'Um erro ocorreu ao carregar os dados.',
    'Loading data...': 'Carregando dados...',
    'There is no data available as no group has been set up.': 'Não há grupos configurados',
    'Data cannot be displayed due to the tariffs for the tag elements being set in different currencies.': 'Os dados não podem ser mostrados porque os itens possuem tarifas com moedas diferentes',
    'Check alert:': 'Alerta:',
    'Total Cost:': 'Custo total:',
    'Rate:': 'Taxa:',
    'Standing Charge:': 'Tarifa fixa:',
    'Tariff Calculation': 'Cálculo de tarifa',
    'Sum of Parts': 'Soma das partes',
    'Group / Subgroup / Sensor': 'Grupo / Subgrupo / Sensor',
    'Tariff': 'Tarifa',
    'Rate': 'Taxa',
    'Standing Charge': 'Tarifa fixa',
    'Start Date': 'Data inicial',
    'End Date': 'Data final',
    'No group setup. Please setup a group in "Configurations > Groups > Create Group".': 'Nãoo há grupos configurados. Crie um grupo em "Configurações>Grupos>Criar Grupo"',
    'Could not load sensors data.': 'Não foi possível carregar os dados dos sensores.',
    'Could not load chart data.': 'Não foi possível carregar o gráfico.',
    'No sensors setup. Please contact support.': 'Não há sensores configurados. Contate o suporte.',
    'Chart may be outdated. Please press "Simulate Costs" after setting the filters.': 'O gráfico pode estar desatualizado. Clique em "Simular custos" depois de selecionar os filtros desejados.',
    'No tariff setup. Please setup a tariff in "Configurations > Tariff > Create Tariff".': 'Não há configuração de tarifas. Configure as tarifas em "Configurações > Tarifas > Criar Tarifa".',
    'Period Range': 'Período',
    '(optional)': '(opcional)',
    'Start date': 'Data inicial',
    'End date': 'Data final',
    'ADD TARIFF': 'ADICIONAR TARIFA',
    'SIMULATE COSTS': 'Simular custos',
    'connected': 'conectado',
    'disconnected': 'desconectado',
    'Could not delete the event.': 'Não foi possível excluir o evento.',
    'Could not load event details.': 'Não foi possível carregar os detalhes dos eventos.',
    'Delete event': 'Excluir evento',
    'Edit event': 'Editar evento',
    'Show event': 'Mostrar evento',
    'Alert:': 'Alerta:',
    'Last Triggered:': 'Disparado pela última vez em',
    'History': 'Histórico',
    'Date': 'Data',
    'Hour': 'Hora',
    'Alert name:': 'Nome do alerta:',
    'Online Sensor System Alert': 'Alerta de sensores online',
    'Offline Sensor System Alert': 'Alerta de sensores offline',
    'Created at:': 'Criado em:',
    'Alert created by:': 'Alerta criado por:',
    'EDIT ALERT': 'Editar alerta',
    'Are you sure?': 'Você tem certeza?',
    'If you delete you won\'t see this event anymore.': 'Se você deletar este evento não poderá recuperá-lo.',
    'Yes': 'Sim',
    'Cancel': 'Cancelar',
    'Triggered': 'Disparado',
    'Alert': 'Alerta',
    'All Alerts': 'Todos os alertas',
    'Unread Alerts': 'Alertas não lidos',
    'Read Alerts': 'Alertas lidos',
    'Events updated successfully': 'Eventos atualizados com sucesso',
    'An error occurred while updating your events.': 'Um erro ocorreu ao atualizar os eventos.',
    'Event updated successfully': 'Evento atualizados com successo',
    'An error occurred while updating your event.': 'Um erro ocorreu ao atualizar o evento.',
    'Event deleted successfully': 'Evento excluído com sucesso',
    'An error occurred while deleting your event.': 'Um erro ocorreu ao excluir o evento.',
    'Events deleted successfully': 'Eventos excluídos com sucesso',
    'An error occurred while deleting your events.': 'Um erro ocorreu ao excluir os eventos.',
    'Set all as unread': 'Marcar todas como não lidas',
    'Set all as read': 'Marcar todas como lidas',
    'Previous Page': 'Página anterior',
    'Next Page': 'Próxima pagina',
    'Filter list': 'Filtrar lista',
    'Open event': 'Abrir evento',
    'Set as unread': 'Marcar como não lida',
    'Set as read': 'Marcar como lida',
    'Go to chart': 'Ir para o gráfico',
    'No alert events yet.': 'Não há alertas de eventos',
    'Last 10 days': 'Últimos 10 dias',
    'Consumption:': 'Consumo:',
    'BACK': 'Voltar',
    'An error occurred while loading the groups.': 'Um erro ocorreu ao carregar os grupos.',
    'Sensors Count:': 'Número de sensores:',
    'Total Consumption:': 'Consumo Total:',
    'Today:': 'Hoje:',
    'Granularity': 'Granularidade',
    'Max:': 'Máximo:',
    'Min:': 'Mínimo:',
    'Avg:': 'Média:',
    'GO TO ANALYSIS': 'Ir para análise',
    'An error occurred while updating the event': 'Um erro ocorreu ao carregar o evento.',
    'There is no consumption data available for the selected criteria. Cost simulation can\'t be calculated in this case.': 'Não há dados de consumo para os critérios selecionados. Não é possível fazer a simulação de custos.',
    'Today': 'Hoje',
    'This {{period}}': 'Este {{period}}',
    'Select date': 'Selecione a data',
    'Date Range': 'Período',
    'Select date range': 'Selecione o período',
    'Reset Date Range': 'Limpar período',
    'month': 'mês',
    'day': 'dia',
    'week': 'semana',
    'custom': 'Customizável',
    'Invalid': 'Inválido',
    'Already exists': 'Já existe',
    'New Recipient': 'Novo destinatário',
    'Recipient e-mail': 'E-mail do destinatáio',
    'Add Recipient': 'Adicionar destinatário',
    'Above': 'Acima',
    'Below': 'Abaixo',
    'Above or below': 'Acima ou abaixo',
    'I want to receive notifications if {{granularity_select}} of consumption is {{alert_value}} {{alert_type}} {{alert_operation}} than the average of the last {{alert_period_quantity}}.': 'Eu quero receber notificações se {{granularity_select}} de consumo for {{alert_value}} {{alert_type}} {{alert_operation}} maior que a média dos(as) últimos(as) {{alert_period_quantity}}.',
    '% Consumption': '% Consumo',
    'kWh Consumption': 'kWh Consumo',
    'I would like to receive a notification if the accumulated consumption during the period {{alert_period_quantity}} reaches {{alert_value}} $t(kWh).': 'Eu quero receber uma notificação se o consumo acumulado durante o período {{alert_period_quantity}} alcançar {{alert_value}} $t(kWh).',
    'I would like to receive a notification if the consumption measured goes {{entity_status}} at any moment between {{alert_init_period}} and {{alert_end_period}}. The checking frequency is hourly.': 'Eu quero receber uma notificação se o consumo for {{entity_status}} em qualquer momento entre {{alert_init_period}} e {{alert_end_period}}. A checagem é feita a cada hora.',
    'I would like to receive a notification if the sensor accumulated cost reaches {{currency_code}}{{alert_value}} in the period {{alert_period}}': 'Eu quero receber notificação se o custo acumulado do sensor alcançar {{currency_code}}{{alert_value}} no período {{alert_period}}',
    '60 minutes': '60 min',
    '24 hours': '24 horas',
    '7 days': '7 dias',
    '30 days': '30 dias',
    'off': 'Desligado',
    'on': 'Ligado',
    'hour': 'hora',
    'calendar day': 'dia',
    'calendar week': 'semana',
    'calendar month': 'mês',
    'Could not load tariffs.': 'Não foi possível carregar as tarifas.',
    'New Relative Consumption Alert': 'Novo alerta de consumo relativo',
    'Edit Relative Consumption Alert': 'Editar alerta de consumo relativo',
    'New Absolute Consumption Alert': 'Novo alerta de consumo absoluto',
    'Edit Absolute Consumption Alert': 'Editar alerta de consumo absoluto',
    'New Equipment Status Alert': 'Novo alerta de status de equipamento',
    'Edit Equipment Status Alert': 'Editar alerta de status de equipamento',
    'New Cost Alert': 'Novo alerta de custo',
    'Edit Cost Alert': 'Editar alerta de custo',
    'Is required': 'Obrigatório',
    'Name is too long': 'O nome é muito longo',
    'Alert saved successfully': 'Alerta salvo com sucesso',
    'Alert created successfully': 'Alerta criado com sucesso',
    'An error occurred while creating the alert.': 'Um erro ocorreu ao criar o alerta.',
    'Edit Alert': 'Editar alerta',
    'New Alert': 'Novo alerta',
    'Alert Name': 'Nome do alerta',
    'Subgroup - Optional': 'Subgrupo - Opcional',
    'Sensor - Optional': 'Sensor - Opcional',
    'Notifications:': 'Notificações:',
    'By email': 'Por e-mail',
    'On the graph': 'No gráfico',
    'By email and on the graph': 'Por e-mail e no gráfico',
    'Recipients': 'Destinatários',
    'ADD E-MAIL': 'ADICIONAR E-MAIL',
    'There are no recipients added': 'Nenhum destinatário informado',
    'CANCEL': 'CANCELAR',
    'Save Alert': 'Salvar alerta',
    'Add Alert': 'Adicionar alerta',
    'The group or sensor selected has no tariff set. Please set a tariff for it on Configuration > Group to create cost alerts.': 'Não é possível cadastrar um alerta de custo para um grupo ou sensor que não tem tarifa cadastrada. Selecione uma tarifa em Configurações > Grupos',
    'Alert successfully removed.': 'Alerta excluído com sucesso',
    'An error occurred while trying to remove this alert.': 'Um erro ocorreu durante o recebimento deste alerta.',
    'CREATE ALERT': 'CRIAR ALERTA',
    'No alerts available': 'Nao há alertas disponíveis',
    'If you close all unsaved information will be lost.': 'Se sair dessa tela todas as informações não salvas serão perdidas',
    'I want to receive notifications if the {{alert_period}} accumulated cost reaches {{alert_value}} under the tariff set on configuration.': 'Eu quero receber notificações se o custo acumulado no(a) {{alert_period}} atingir {{alert_value}} com base na tarifa configurada.',
    'I want to receive notifications if the {{alert_period}} accumulated consumption reaches {{alert_value}} $t(kWh).': 'Eu quero receber notificações se o consumo acumulado no(a) {{alert_period}} atingir {{alert_value}} $t(kWh).',
    'I want to receive notifications if the {{granularity_select}} consumption is {{alert_value}}$t(%) {{alert_operation}} the average of the last {{alert_period_quantity}}.': 'Eu quero receber notificações se o consumo em {{granularity_select}} for {{alert_value}}$t(%) {{alert_operation}} da média dos(as) últimos(as) {{alert_period_quantity}}.',
    'I want to receive notifications if the {{granularity_select}} consumption is {{alert_value}} $t(kWh) {{alert_operation}} the average of the last {{alert_period_quantity}}.': 'Eu quero receber notificações se o consumo em {{granularity_select}} for {{alert_value}}$t(kWh) {{alert_operation}} da média dos(as) últimos(as) {{alert_period_quantity}}.',
    'I want to receive notifications if the sensor goes {{entity_status}} {{alert_period}}.': 'Eu quero receber notificações se o sensor ficar {{entity_status}} {{alert_period}}.',
    'at any moment between {{alert_init_period}} and {{alert_end_period}}': 'a qualquer momento entre {{alert_init_period}} e {{alert_end_period}}',
    'at any moment': 'a qualquer momento',
    'Details': 'Detalhes',
    'Show more': 'Mostrar mais',
    'Cost Alert': 'Alerta de custo',
    'Absolute Consumption': 'Consumo absoluto',
    'Relative Consumption - %': 'Consumo relativo - %',
    'Relative Consumption - kWh': 'Consumo relativo - kWh',
    'Equipment Status Alert': 'Alerta de status do equipamento',
    'Alert category': 'Categoria de alerta',
    'Group:': 'Grupo:',
    'Subgroup:': 'Subgrupo:',
    'Sensor:': 'Sensor:',
    'This report will be permanently removed.': 'Esse relatório será permanentemente removido.',
    'Choose Alert Type': 'Escolha o tipo de alerta',
    'Absolute consumption': 'Consumo absoluto',
    'Equipment status': 'Status do equipamento',
    'Relative consumption': 'Consumo relativo',
    'minute': 'minuto',
    'minutes': 'minutos',
    'hours': 'horas',
    'days': 'dias',
    'weeks': 'semanas',
    'months': 'meses',
    'Minute': 'Minuto',
    'above': 'acima',
    'below': 'abaixo',
    'either above or below': 'tanto acima quanto abaixo',
    'Sensor {{sensor}} was {{status}}.': 'Sensor {{sensor}} estava {{status}}.',
    'Equipment went {{status}}': 'Equipamento foi {{status}}',
    'The accumulated cost has reached {{value}} in the last {{granularity}}': 'O custo acumulado alcançou {{value}} no(a) último(a) {{granularity}}',
    'Sensor connected.': 'Sensor conectado.',
    'Sensor disconnected.': 'Sensor desconectado.',
    '{{granularity}} consumption is {{value}} {{operation}} the average of the last {{avgQuantity}} {{avgGranularity}}': 'O consumo de {{granularity}} passou {{value}} {{operation}} da média dos(as) últimos(as) {{avgQuantity}} {{avgGranularity}}',
    'The accumulated consumption has reached {{value}} kWh in the last {{granularity}}': 'O consumo acumulado alcançou {{value}} kWh no(a) último(a) {{granularity}}',
    'Could not load countries.': 'Nao foi possível carregar a lista de países.',
    'New Tariff': 'Nova tarifa',
    'Please fill the information to add a new tariff.': 'Preencha todas as informações para adicionar uma nova tarifa.',
    'Add Tariff': 'Adicionar tarifas',
    'Edit Tariff': 'Editar tarifas',
    'Please fill the information to edit the tariff.': 'Preencha as informações para editar a tarifa.',
    'Submit': 'Enviar',
    'Please set at least one: a weekday, a time or a limit.': 'Selecione pelo menos um: dia da semana, horário ou limite.',
    'Please select a start time and an end time.': 'Informe a hora de início e a hora de fim.',
    'Please select a limit and its value.': 'Informe o limite e seu valor.',
    'Please select an end time later than start time.': 'A hora de fim deve ser posterior a hora de início',
    'The new tariff was successfully added.': 'A nova tarifa foi criada com sucesso',
    'An error occurred while adding the tariff.': 'Um erro ocorreu ao criar a tarifa.',
    'An error occurred while editing the tariff.': 'Um erro ocorreu ao editar a tarifa.',
    'The tariff was successfully edited.': 'A tarifa foi editada com sucesso.',
    'Tariff Name': 'Nome da tarifa',
    'Provider Name': 'Nome do fornecedor',
    'Select a country': 'Selecione um país',
    'Standing Charge ({{currencySymbol}}/day)': 'Tarifa fixa ({{currencySymbol}}/dia)',
    'Peak and Off-Peak Tariffs': 'Tarifas de pico e fora-de-pico',
    'Overlapping periods may result into calculation errors. In the case where the overlapping is mathematically consistent, the rate with the lowest granularity defined, among the possible ones, will be used in the calculation.': 'Períodos sobrepostos podem levar a erros no cálculo do custo. Nos casos onde seja possível realizar o cálculo apesar da sobreposição, a menor tarifa dentre essas será usada no cálculo.',
    'More than or equal to': 'Maior ou igual a',
    'Less than or equal to': 'Menor ou igual a',
    'Monday': 'Segunda-feira',
    'Tuesday': 'Terça-feira',
    'Wednesday': 'Quarta-feira',
    'Thursday': 'Quinta-feira',
    'Friday': 'Sexta-feira',
    'Saturday': 'Sábado',
    'Sunday': 'Domingo',
    'Weekday': 'Dia da semana',
    'Start Time': 'Hora de Início',
    'End Time': 'Hora de Fim',
    'Limit (kWh/month)': 'Limite (kWh/mês)',
    'Could not load countries data.': 'Nao foi possível carregar os dados dos países.',
    'CREATE TARIFF': 'CRIAR TARIFA',
    'Reload': 'Recarregar',
    'No tariffs available': 'Não há tarifas disponíveis',
    'Leave this section?': 'Sair desta seção?',
    'If you leave this section, inputs you may have entered will not be saved.': 'Se você sair desta seção os dados fornecidos não serão salvos.',
    'Yes, leave': 'Sim, sair',
    'No, stay here': 'Não, continuar aqui',
    'Tariff successfully removed.': 'Tarifa removida com sucesso',
    'Country:': 'País:',
    'Standing charge per day:': 'Tarifa fixa por dia:',
    'Off-peak time:': 'Hora fora-de-pico:',
    'Peak time:': 'Hora de pico:',
    'Peak rate:': 'Tarifa de pico:',
    'kWh/': 'kWh/',
    'Currency:': 'Moeda:',
    'Standing charge:': 'Tarifa fixa:',
    'Week day:': 'Dia da semana:',
    'Start/End time:': 'Hora de início/fim:',
    'Limit:': 'Limite:',
    'We are deleting {{tariffName}} tariff, please wait.': 'Aguarde enquanto a tarifa {{tariffName}} é excluída.',
    'The Tariff will be permanently deleted.': 'A tarifa será permanentemente excluída.',
    'Select weekday': 'Selecionar o dia da semana',
    'Add rate': 'Adicionar tarifa',
    'Select limit': 'Selecionar limite',
    'Add limit value': 'Adicionar valor do limite',
    'Add standing charge': 'Adicionar tarifa fixa',
    'Rate ({{currencySymbol}}/kWh)': 'Tarifa ({{currencySymbol}}/kWh)',
    'No sensors were selected for group nor for any subgroup. Please select at least one sensor and try again.': 'Nenhum sensor foi incluído no grupo e no subgrupo. Selecione pelo menos um sensor e tente novamente.',
    'Group added successfully.': 'Grupo adicionado com sucesso.',
    'An error occurred while adding the group.': 'Um erro ocorreu ao criar o grupo.',
    'Group edited successfully.': 'Grupo editado com sucesso.',
    'An error occurred while editing the group.': 'Um erro ocorreu ao criar o grupo.',
    'Back': 'Voltar',
    'Skip': 'Pular',
    'Save and create group': 'Salvar e criar grupo',
    'Next': 'Próximo',
    'Total Load Sensor': 'Sensor de carga total',
    'Total load sensor': 'Sensor de carga total',
    'Sub Load Sensors': 'Sensor de subcarga total',
    'Sub load sensors': 'Sensor de subcarga total',
    '(If any)': '(Se houver)',
    'Label <small>(Optional)</small>': 'Rótulo <small>(Opcional)</small>',
    'Tag <small>(Optional)</small>': 'Tag <small>(Opcional)</small>',
    'Tariff <small>(Optional)</small>': 'Tarifa <small>(Opcional)</small>',
    'This groups has no subgroups.': 'Este grupo não possui subgrupos.',
    'We are deleting the group {{groupName}}, please wait.': 'Aguarde enquando o grupo {{groupName}} é excluído.',
    'The Group and all related Subgroups will be permanently deleted.': 'O grupo e os subgrupos relacionados foram permanentemente excluídos.',
    'Group Name': 'Nome do grupo',
    'Tag (Optional)': 'Tag (opcional)',
    'The selected tags at any subgroup will not be listed here. Please remove the tag from its respective subgroup to be able to assign it for this group.': 'Tags que foram utilizadas em subgrupos não são listadas aqui. Remova a tag do subgrupo para poder utilizá-la neste grupo.',
    'Tariff (Optional)': 'Tarifa (opcional)',
    'Review Details': 'Detalhes',
    'Tariff:': 'Tarifa:',
    'Tag:': 'Tag:',
    'No sensors were selected.': 'Nenhum sensor selecionado',
    'Total Load Sensors': 'Sensores de carga total',
    'Sub Load Sensor': 'Sensores de sub carga',
    'Label': 'Rótulo',
    'No subgroups were created.': 'Nenhum subgrupo foi criado.',
    'Group successfully removed.': 'Grupo removido com sucesso',
    'There was an error removing this group. Please, try again or contact support.': 'Ocorreu um erro ao remover este grupo. Por favor tente novamente ou contate o suporte.',
    'CREATE GROUP': 'CRIAR GRUPO',
    'No groups available': 'Nenhum grupo disponível',
    'Do you want to keep editing your data?': 'Deseja continuar editando os dados?',
    'You can start editing from where you last stopped for as long as you keep your session alive.': 'Você pode continuar a editar do ponto onde parou se mantiver sua sessão aberta.',
    'Yes, Keep it': 'Sim, mantenha.',
    'No': 'Não',
    'Sensor-phase': 'Sensor-fase',
    'Add a label': 'Adicionar um rótulo',
    'Select or create a new Tag': 'Selecionar ou criar uma nova tag',
    'Select a Tariff': 'Selecione uma tarifa',
    'There are no sensors-phases left to add.': 'Não há nenhum sensor-fase restante para ser incluído',
    'Some inputs are invalid or incomplete. Please correct them and try again.': 'Algumas informações são inválidas ou incompletas. Por favor, corrija isso e tente de novo.',
    'Subgroup Configurations': 'Configuração do subgrupo',
    'Subgroup Name': 'Nome do subgrupo',
    'The selected tags at any subgroup or group will not be listed here. Please remove the tag from its respective entity to be able to assign it for this subgroup.': 'As tags selecionadas para subgrupos ou grupos não serão listadas aqui. Por favor remova a tag da sua respectiva entidade para poder atribuí-la a este subgrupo.',
    'Delete subgroup': 'Excluir subgrupo',
    'Add new subgroup': 'Adicionar subgrupo',
    'This Subgroup will be permanently deleted.': 'O subgrupo será excluído permanentemente',
    'Yes, Delete it.': 'Sim, exclua.',
    'Group Configuration': 'Configuração do grupo',
    'Subgroups Configuration': 'Configuração do subgrupo',
    'Level:': 'Nível:',
    'Periodicity:': 'Periodicidade:',
    'Average of:': 'Média dos:',
    'Absolute Consumption Level': 'Nível de Consumo Absoluto',
    'Relative Consumption Level': 'Nível de Consumo Relativo',
    'Same 3 Months': 'Últimos 3 Meses',
    'No benchmarks available': 'Não há benchmarks disponíveis',
    'Sensor {{status}}': '\nSensor {{status}}',
    'Report successfully removed.': 'Relatório excluído com sucesso.',
    'An error occurred while trying to remove this report.': 'Um erro ocorreu ao excluir o relatório.',
    'CREATE REPORT': 'CRIAR RELATÓRIO',
    'No reports available': 'Nenhum relatório disponível',
    'An error occurred while generating you report.': 'Um erro ocorreu ao gerar o relatório.',
    'Daily': 'Diário',
    'Weekly': 'Semanal',
    'Monthly': 'Mensal',
    '{{period_quantity}} Months': '{{period_quantity}} Meses',
    '{{period_quantity}} Weeks': '{{period_quantity}} Semanas',
    'Report saved successfully': 'Relatório salvo com sucesso',
    'Report created successfully': 'Relatório criado com sucesso',
    'An error occurred while creating you report.': 'Um erro ocorreu ao criar o relatório.',
    'Edit Report': 'Editar o relatório',
    'New Report': 'Novo relatório',
    'Report Name': 'Nome do relatório',
    'Filters': 'Filtros',
    'There is no recipients added': 'Nenhum destinatário informado',
    'Trigger': 'Gatilho',
    'Send a {{period}} report at the end of every {{trigger}}': 'Enviar um relatório {{period}} ao final de cada {{trigger}}',
    'Preview': 'Pré-visualizar',
    'Save Report': 'Salvar relatório',
    'Add Report': 'Criar Relatório',
    'Period:': 'Período:',
    'Send every:': 'Enviar a cada:',
    'Status': 'Status',
    'ID': 'ID',
    'Allocated to': 'Alocado em',
    'Not allocated': 'Não alocado',
    'Never connected': 'Nunca conectado',
    '{{status}} since {{dateAndTime}}': '{{status}} desde {{dateAndTime}}',
    'Online': 'Online',
    'Offline': 'Offline',
    'No sensors set up yet.': 'Nenhum sensor cadastrado',
    'Select start time': 'Selecionar hora de início',
    'Select end time': 'Selecionar hora de fim',
    'No consumption data': 'Não há dados de consumo',
    'benchmarking': 'benchmarking',
    'Subgroups': 'Subgrupos',
    'Total Load Sensors:': 'Total de sensores de carga:',
    'Select...': 'Selecione...',
    'Group Configurations': 'Configurações de grupo',
    'Report {0} from {1} to {2}': 'Relatorio {0} de {1} a {2}',
    'Alert {0} was triggered': 'Alerta {0} foi gerado',
    'Dear Partner,': 'Caro usuário,',
    'Alert {0} was triggered at {1}.': 'O alerta {0} foi gerado às {1}.',
    'Description: ': 'Descrição:',
    'Consumption is {0}{1} {2} the average of the last {3} {4}.': 'O consumo atingiu {0}{1} {2} da média do(a)s último(a)s {3} {4}.',
    '{0} was detected to be switched {1} during the check.': 'Foi detectada a mudança em {0} para {1} durante a conferência.',
    '{0} accumulated consumption surpassed the threshold defined at {1}.': 'O consumo acumulado em {0} ultrapassou o limite definido em {1}.',
    '{0} accumulated cost surpassed the threshold defined at {1}.': 'O custo acumulado em {0} ultrapassou o limite definido em {1}.',
    'Please access ': 'Acesse',
    'here': 'aqui',
    'for more info.': 'para mais informações.',
    'You can disable this alert by erasing the email from the list at ': 'Você pode desabilitar este alerta apagando seu e-mail',
    'Best regards,': 'Atenciosamente,',
    'or': 'ou',
    'Voltaware reset password information': 'Sua nova senha EMD Voltaware',
    'Hi,': 'Olá,',
    'You have requested to reset your Voltaware Building Monitoring Dashboard password. Your new password is:': 'Você fez um pedido para uma nova senha de acesso ao Electricity Monitoring Dashboard. Sua nova senha é:',
    'If you have not requested to reset your password please contact our customer service immediately.': 'Se você não solicitou uma nova senha, entre em contato com o nosso Serviço de Atendimento ao Cliente imediatamente.',
    'Kind regards,': 'Obrigado,',
    'The Voltaware Team': 'Equipe Voltaware',
    'No results found': 'Nenhum resultado encontrado',
    'There is no data available as no tariff has been set up. Cost simulation can\'t be calculated in this case.': 'Não há dados disponíveis já que nenhuma tarifa foi configurada. A similação de custo não pode ser calculada nesse caso. ',
    'Home': 'Home',
    'Equipment Status': 'Status do Equipamento',
    'Total aggregated consumption': 'Consumo total acumulado',
    'Total aggregated cost': 'Custo total acumulado',
    'Others, aggregated': 'Outros, acumulado',
    'Most Consuming - Groups': 'Maiores Consumos - Grupos',
    'There is no data available': 'Não há dados disponíveis',
    'Most Consuming - Sensors': 'Maiores Consumos - Sensores',
    'Cost data has more than one currency. Visualisations are showing only the most consuming currency.': 'Os dados do custo possuem mais de uma moeda. A visualização mostra somente a moeda de maior consumo.',
    'Most Costly - Groups': 'Maiores Custos - Grupos',
    'Click {{here_link}} to go to the last visited page': 'Clique {{here_link}} para ir para a última página visitada',
    'Most Costly - Sensors': 'Maiores Custos - Sensores',
    'Percentage change in relation to last period': 'Porcentagem da diferença com relação ao último período',
    'Percentage change not applicable, as previous period has no data': 'A porcentagem da diferença não pode ser aplicada já que não há dados para o período anterior',
    'N/A': 'N/A',
    'Recent Events': 'Eventos recentes',
    'Power Peak': 'Pico de Potência',
    '**': '**',
    'kW': 'kW',
    'The peak demand was of': 'O pico de consumo foi',
    'on {{peak_time}}': 'em {{peak_time}}',
    '{0} power demand went above {1} kW.': 'A potência consumida em {0} ultrapassou {1} kW.',
    'The power demand went above {{value}} kW in the last {{granularity}}': 'A potência ultrapassou {{value}} kW no útlimo {{granularity}}',
    'I would like to receive a notification if the active power goes above {{alert_value}} kW in the last {{alert_period}}.': 'Eu gostaria de receber uma notificação se a potência ativa ultrapassar {{alert_value}} kW no(a) último(a) {{alert_period}}.',
    'New Power Alert': 'Novo Alerta de Potência',
    'Edit Power Alert': 'Editar Alerta de Potência',
    'Power Alert': 'Alerta de Potência',
    'I want to receive notifications if the active power goes above {{alert_value}} $t(kW) in the last {{alert_period}}.': 'Eu desejo receber notificações se a potência ativa ultrapassar {{alert_value}} $t(kW) em  {{alert_period}}.',
    'Users': 'Usuários',
    'E-mail': 'E-mail',
    'Role': 'Tipo',
    'Created At': 'Criado em',
    'Enabled': 'Habilitado',
    'Administrator': 'Administrador',
    'Read-only': 'Somente leitura',
    'CREATE USER': 'CRIAR USUÁRIO',
    'Edit user': 'Editar usuário',
    'Delete user': 'Excluir usuário',
    'No users yet.': 'Nenhum usuário ainda',
    'If you delete, all the user information will be lost.': 'Se excluir o usuário, todas as informações relacionadas a ele serão perdidas',
    'Read-only user': 'Usuário somente leitura',
    'Disabled': 'Desabilitado',
    'User saved successfully': 'Usuário salvo com sucesso',
    'User created successfully': 'Usuário cadastrado com sucesso',
    'An error occurred while creating the user.': 'Um erro ocorreu ao cadastrar o usuário.',
    'Edit User': 'Alterar usuário',
    'New User': 'Novo usuário',
    'User login e-mail': 'E-mail do usuário',
    'Save User': 'Salvar usuário',
    'Add User': 'Criar usuário',
    'User deleted successfully': 'Usuário excluído com sucesso',
    'Welcome to Voltaware': 'Bem-vindo a Voltaware',
    'Greetings,': 'Olá,',
    'Welcome to Voltaware Electricity Monitoring Dashboard': 'Bem-vindo ao Voltaware Electricity Monitoring Dashboard',
    'You can access the login page': 'Acesse a página de login',
    'Your credentials are:': 'Suas credenciais são:',
    'User:': 'Usuário',
    'password:': 'senha:',
    'Use the same password required for all our services.': 'Use a mesma senha utilizada em nossos outros serviços',
    'For support requests, please contact us by going to our support page.': 'Em caso de dúvidas, acesse nossa página de suporte.',
    'Many thanks,': 'Obrigado,',
    'You have requested to reset your Voltaware Electricity Monitoring Dashboard password. Your new password is:': 'Você fez um pedido para redefinir sua senha para a Electricity Monitoring Dashboard. Sua nova senha é:',
    '(all phases)': '(todas as fases)',
    'GENERATE REPORT': 'GERAR RELATÓRIO',
    'Generate Instant Report': 'Gerar relatório agora',
    'Select a group, a subgroup or a sensor': 'Selecionar um grupo, subgrupo ou sensor',
    'Select a period': 'Selecione o período',
    'Generate Report': 'Gerar relatório',
    'I want to receive notifications if the {{alert_period}} accumulated cost reaches {{alert_value}} under the tariff set on configuration._male': 'Eu quero receber notificações se o custo acumulado no {{alert_period}} atingir {{alert_value}} com base na tarifa configurada.',
    'I want to receive notifications if the {{alert_period}} accumulated cost reaches {{alert_value}} under the tariff set on configuration._female': 'Eu quero receber notificações se o custo acumulado na {{alert_period}} atingir {{alert_value}} com base na tarifa configurada.',
    'I want to receive notifications if the {{alert_period}} accumulated consumption reaches {{alert_value}} $t(kWh)._male': 'Eu quero receber notificações se o consumo acumulado no {{alert_period}} atingir {{alert_value}} $t(kWh).',
    'I want to receive notifications if the {{alert_period}} accumulated consumption reaches {{alert_value}} $t(kWh)._female': 'Eu quero receber notificações se o consumo acumulado na {{alert_period}} atingir {{alert_value}} $t(kWh).',
    'I want to receive notifications if the {{granularity_select}} consumption is {{alert_value}}$t(%) {{alert_operation}} the average of the last {{alert_period_quantity}}._male': 'Eu quero receber notificações se o consumo em {{granularity_select}} for {{alert_value}}$t(%) {{alert_operation}} da média dos últimos {{alert_period_quantity}}.',
    'I want to receive notifications if the {{granularity_select}} consumption is {{alert_value}}$t(%) {{alert_operation}} the average of the last {{alert_period_quantity}}._female': 'Eu quero receber notificações se o consumo em {{granularity_select}} for {{alert_value}}$t(%) {{alert_operation}} da média das últimas {{alert_period_quantity}}.',
    'I want to receive notifications if the {{granularity_select}} consumption is {{alert_value}} $t(kWh) {{alert_operation}} the average of the last {{alert_period_quantity}}._male': 'Eu quero receber notificações se o consumo em {{granularity_select}} for {{alert_value}}$t(kWh) {{alert_operation}} da média dos últimos {{alert_period_quantity}}.',
    'I want to receive notifications if the {{granularity_select}} consumption is {{alert_value}} $t(kWh) {{alert_operation}} the average of the last {{alert_period_quantity}}._female': 'Eu quero receber notificações se o consumo em {{granularity_select}} for {{alert_value}}$t(kWh) {{alert_operation}} da média das últimas {{alert_period_quantity}}.',
    'The accumulated cost has reached {{value}} in the last {{granularity}}_male': 'O custo acumulado alcançou {{value}} no último {{granularity}}',
    'The accumulated cost has reached {{value}} in the last {{granularity}}_female': 'O custo acumulado alcançou {{value}} na última {{granularity}}',
    'The accumulated consumption has reached {{value}} kWh in the last {{granularity}}_male': 'O consumo acumulado alcançou {{value}} kWh no último {{granularity}}',
    'The accumulated consumption has reached {{value}} kWh in the last {{granularity}}_female': 'O consumo acumulado alcançou {{value}} kWh na última {{granularity}}',
    'above or below': 'acima ou abaixo',
    '{{granularity}} consumption is {{value}} {{operation}} the average of the last {{avgQuantity}} {{avgGranularity}}_male': 'O consumo de {{granularity}} passou {{value}} {{operation}} da média dos últimos {{avgQuantity}} {{avgGranularity}}',
    '{{granularity}} consumption is {{value}} {{operation}} the average of the last {{avgQuantity}} {{avgGranularity}}_female': 'O consumo de {{granularity}} passou {{value}} {{operation}} da média das últimas {{avgQuantity}} {{avgGranularity}}',
    'Tenants': 'Clientes',
    'Login as root': 'Acessar como gestor',
    'Change language to brazilian portuguese': 'Mudar o idioma para português (brasileiro)',
    'There was an error trying to signin as user': 'Houve um erro ao tentar acessar como usuário',
    'CREATE CLIENT': 'CRIAR CLIENTE',
    'No clients available': 'Não há clientes disponíveis',
    'Primary color:': 'Cor primária',
    'Default': 'Padrão',
    'Secondary color:': 'Cor secundária',
    'Login as client': 'Acessar como cliente',
    'An error occurred while getting the sensors.': 'Um erro ocorreu ao carregar os sensores.',
    'Sensor Management': 'Gestão de sensores',
    'Select the sensors': 'Selecionar sensores',
    'Save': 'Salvar',
    'Client saved successfully': 'Cliente salvo com sucesso',
    'Client created successfully': 'Cliente criado com sucesso',
    'An error occurred while creating the client.': 'Um erro ocorreu ao criar o cliente.',
    'Edit Client': 'Editar cliente',
    'New Client': 'Novo cliente',
    'Client name': 'Nome do cliente',
    'Save Client': 'Salvar cliente',
    'Add Client': 'Adicionar cliente',
    'Users disabled successfully': 'Usuário desabilitado com sucesso',
    'An error occurred while disabling the users': 'Um erro ocorreu ao desabilitar os usuários',
    'Users enabled successfully': 'Usuários habilitados com sucesso',
    'An error occurred while enabling the users': 'Um erro ocorreu ao habilitar os usuários',
    'DISABLE ALL': 'Desabilitar todos',
    'ENABLE ALL': 'Habilitar todos',
    'Login as user': 'Acessar como usuário',
    'Year': 'Ano',
    'Select the year': 'Selecionar ano',
    'This Week': 'Esta semana',
    'This Month': 'Este mês',
    'Last Day': 'Dia Anterior',
    'Last Week': 'Semana Anterior',
    'Last Month': 'Mês Anterior',
    'Sensors allocated successfully': 'Sensores alocados com sucesso',
    'An error occurred while allocating the sensor.': 'Um erro ocorreu ao alocar o sensor.',
    'An error occurred while selecting the logo image.': 'Um erro ocorreu ao selecionar o logotipo.',
    'Primary color': 'Primeira cor',
    'Secondary color': 'Segunda cor',
    'Logo image to replace': 'Substituir logotipo',
    'Logo image': 'Logotipo',
    'Remove current logo image': 'Remover o logotipo atual',
    'Show Powered by Voltaware logo': 'Mostar o logotipo Powered by Voltaware',
  },
  'ru': {
    'An error occurred while retrieving data': 'При получении данных произошла ошибка.',
    'Loading data': 'Загрузка данных...',
    'There is no data available for the selected criteria': 'Нет данных по выбранным критериям',
    'There is no data available as no group has been set up': 'Нет доступных данных, так как не создана ни одна группа.',
    '5 Min': '5 минут',
    '30 Min': '30 минут',
    '1 Hour': '1 час',
    'Day': 'День',
    'Week': 'Неделя',
    'Month': 'Месяц',
    'Custom': 'Пользовательский',
    '24 Hours': '24 часа',
    '1 Week': '1 неделя',
    '1 Month': '1 месяц',
    'Total Cost': 'Общая стоимость',
    'There is no data available as no sensor has been set up': 'Нет доступных данных, так как нет настроенных датчиков',
    'Could not load sensors data': 'Не удалось загрузить данные датчиков.',
    'Unknown group/sensor': 'Неизвестная группа / датчик',
    'Active Power': 'Активная мощность',
    'Reactive Power': 'Реактивная мощность',
    'NOW': 'СЕЙЧАС',
    'Select any sensor-phase (type to search)': 'Выберите любую фазу-датчик (введите имя для поиска)',
    'Max': 'Макс',
    'Min': 'Мин',
    'Avg': 'Средн',
    'No sensors setup': 'Нет настроенных датчиков. Пожалуйста, свяжитесь со службой поддержки.',
    'A new password was sent to your email': 'Новый пароль был отправлен на вашу электронную почту.',
    'There was an error resetting the password': 'При сбросе пароля произошла ошибка.',
    'Please fill the username correctly': 'Пожалуйста, укажите верное имя пользователя.',
    'Sending the email': 'Отправка письма ...',
    'voltaware building electricity monitoring logo': 'Логотип Voltaware building electricity monitoring',
    'Login': 'Войти',
    'Username': 'Имя пользователя',
    'Password': 'Пароль',
    'Sign in': 'Войти в систему',
    'Forgot your password?': 'Забыли пароль?',
    'For a better experience on Electricity Monitoring Dashboard use {{firefox}} or {{chrome}}': 'Для работы с Панелью мониторинга электричества используйте {{firefox}} или {{chrome}}.',
    'Change language to english': 'Сменить язык на Английский',
    'Change language to russian': 'Сменить язык на Русский',
    'Firefox': 'FireFox',
    'Chrome': 'Chrome',
    'There are intersection between two compositions': 'Ошибка в таблице Пиковых и Внепиковых тарифов. Пожалуйста, проверьте, не пересекаются ли временные интервалы для тарифов и для лимитов.',
    'There was an error removing this tariff': 'При удалении этого тарифа произошла ошибка. Пожалуйста, попробуйте еще раз или обратитесь в службу поддержки.',
    'Tariff couldn\'t be deleted because the group(s) {{tariffGroupNames}} use(s) this tariff': 'Тариф не может быть удален, потому что он используется группой(ами) {{tariffGroupNames}}.',
    'Benchmark': 'Эталонный тест',
    'Select any benchmark': 'Выберите любой тест',
    'Fixed Benchmark': 'Фиксированный эталонный тест',
    'Below benchmark': 'Ниже эталонного значения',
    'Above benchmark': 'Выше эталонного значения',
    'kWh': 'кВтч',
    '1 Day': '1 день',
    'View Only - This feature is currently under development': 'Только просмотр - эта функция находится в стадии разработки',
    'Type': 'Тип',
    'Total Consumption': 'Общее потребление',
    'Total Savings': 'Общая экономия',
    'Total Losses': 'Общие потери',
    'Absolute': 'Полное',
    'Relative Benchmark': 'Относительный эталон',
    'Relative': 'Относительно',
    'Group': 'Группа',
    'Select any group': 'Выберите любую группу',
    'Subgroup': 'Подгруппа',
    'Select any subgroup': 'Выберите любую подгруппу',
    'Sensor': 'Датчик',
    'Select any sensor': 'Выберите любой датчик',
    'Tag': 'Тег',
    'Select any tag': 'Выберите любой тег',
    'Consumption': 'Потребление',
    'Power': 'Мощность',
    'Cost': 'Стоимость',
    'Groups': 'Группы',
    'Tariffs': 'Тарифы',
    'Benchmarking': 'Сравнение с эталоном',
    'Analysis': 'Анализ',
    'Resources': 'Ресурсы',
    'Reports': 'Отчеты',
    'Alerts': 'Оповещения',
    'Configuration': 'Конфигурация',
    'Tutorials': 'Обучение',
    'Logout': 'Выйти',
    'ADD GROUP': 'ДОБАВИТЬ ГРУППУ',
    'line graph': 'линейный график',
    'stream graph': 'потоковый график',
    'heatmap': 'тепловая карта',
    'geolocation': 'геолокации',
    'cumulative': 'совокупный',
    'benchmark': 'Эталонный тест',
    'Events': 'События',
    'Real Cost': 'Реальная стоимость',
    'Cost Simulation': 'Моделирование стоимости',
    'Please try again': 'Пожалуйста, попробуйте еще раз или обратитесь в службу поддержки.',
    'Sample Group': 'Образец группы',
    'Graph interpolation': 'Интерполяция графика',
    'Linear': 'Линейный',
    'Step': 'Шаг',
    'Show alerts': 'Показать оповещения',
    'Off': 'Выкл',
    'On': 'Вкл',
    'No group setup': 'Нет ни одной группы. Пожалуйста, создайте группу в «Конфигурации > Группы> Создать группу».',
    'Could not load the tags': 'Не удалось загрузить теги.',
    'Could not load groups data': 'Не удалось загрузить данные групп.',
    'Could not load alert events data': 'Не удалось загрузить оповещения.',
    'Could not load chart data': 'Не удалось загрузить данные диаграммы.',
    'Check alert': 'Проверьте оповещение',
    '(estimated)': '(расчетное значение)',
    '(partially estimated)': '(частично рассчитанное значение)',
    'This group has no subgroups': 'В этой группе нет подгрупп',
    'This subgroup has no sensors': 'В этой подгруппе нет датчиков',
    'Empty data': 'Пустые данные',
    'Entries values length are not equal': 'Записи разной длины',
    'Graph View': 'Вид графика',
    'Show Parent': 'Показать верхний уровень',
    'Total': 'Всего',
    'Group / Subgroup': 'Группа / Подгруппа',
    'Please, try again or contact support.': 'Пожалуйста, попробуйте еще раз или обратитесь в службу поддержки.',
    'There was an error resetting the password.': 'При сбросе пароля произошла ошибка.',
    'Sending the email...': 'Отправка письма ...',
    '*': '*',
    'An error occurred while retrieving data.': 'При получении данных произошла ошибка.',
    'Loading data...': 'Загрузка данных...',
    'There is no data available as no group has been set up.': 'Нет доступных данных, так как не создана ни одна группа.',
    'Data cannot be displayed due to the tariffs for the tag elements being set in different currencies.': 'Невозможно показать данные, поскольку тарифы для тегов используют разные валюты.',
    'Check alert:': 'Проверьте оповещение:',
    'Total Cost:': 'Общая стоимость:',
    'Rate:': 'Ставка:',
    'Standing Charge:': 'Фиксированный платеж:',
    'Tariff Calculation': 'Расчет тарифа',
    'Sum of Parts': 'Сумма частей',
    'Group / Subgroup / Sensor': 'Группа / Подгруппа / Датчик',
    'Tariff': 'Тариф',
    'Rate': 'Ставка',
    'Standing Charge': 'Фиксированный платеж',
    'Start Date': 'Дата начала',
    'End Date': 'Дата окончания',
    'No group setup. Please setup a group in "Configurations > Groups > Create Group".': 'Нет ни одной группы. Пожалуйста, создайте группу в «Конфигурации > Группы> Создать группу».',
    'Could not load sensors data.': 'Не удалось загрузить данные датчиков.',
    'Could not load chart data.': 'Не удалось загрузить данные диаграммы.',
    'No sensors setup. Please contact support.': 'Нет настроеннных датчиков. Пожалуйста, свяжитесь со службой поддержки.',
    'Chart may be outdated. Please press "Simulate Costs" after setting the filters.': 'Данные на графике могут быть устаревшим. Пожалуйста, настройте нужные фильтры и нажмите кнопку «Моделировать стоимость».',
    'No tariff setup. Please setup a tariff in "Configurations > Tariff > Create Tariff".': 'Не настроен тариф. Пожалуйста, настройте тариф в разделе «Конфигурации> Тариф> Создать тариф».',
    'Period Range': 'Период',
    '(optional)': '(необязательный)',
    'Start date': 'Дата начала',
    'End date': 'Дата окончания',
    'ADD TARIFF': 'ДОБАВИТЬ ТАРИФ',
    'SIMULATE COSTS': 'МОДЕЛИРОВАТЬ РАСХОДЫ',
    'connected': 'подключен',
    'disconnected': 'отключен',
    'Could not delete the event.': 'Не удалось удалить событие.',
    'Could not load event details.': 'Не удалось загрузить данные событий.',
    'Delete event': 'Удалить событие',
    'Edit event': 'Редактировать событие',
    'Show event': 'Показать событие',
    'Alert:': 'Оповещение:',
    'Last Triggered:': 'Последнее срабатывание:',
    'History': 'История',
    'Date': 'Дата',
    'Hour': 'Час',
    'Alert name:': 'Имя оповещения:',
    'Online Sensor System Alert': 'Оповещение: датчик на связи',
    'Offline Sensor System Alert': 'Оповещение: нет связи с датчиком.',
    'Created at:': 'Создан в:',
    'Alert created by:': 'Оповещение создано:',
    'EDIT ALERT': 'РЕДАКТИРОВАТЬ ОПОВЕЩЕНИЕ',
    'Are you sure?': 'Вы уверены?',
    'If you delete you won\'t see this event anymore.': 'Удалив, вы больше не увидите это событие.',
    'Yes': 'Да',
    'Cancel': 'Отменить',
    'Triggered': 'Сработавшие',
    'Alert': 'Оповещение',
    'All Alerts': 'Все оповещения',
    'Unread Alerts': 'Непрочитанные оповещения',
    'Read Alerts': 'Прочитанные оповещения',
    'Events updated successfully': 'События успешно обновлены',
    'An error occurred while updating your events.': 'При обновлении списка ваших событий произошла ошибка.',
    'Event updated successfully': 'Событие успешно обновлено',
    'An error occurred while updating your event.': 'При обновлении вашего события произошла ошибка.',
    'Event deleted successfully': 'Событие успешно удалено',
    'An error occurred while deleting your event.': 'При удалении вашего события произошла ошибка.',
    'Events deleted successfully': 'События успешно удалены',
    'An error occurred while deleting your events.': 'При удалении ваших событий произошла ошибка.',
    'Set all as unread': 'Пометить все как непрочитанные',
    'Set all as read': 'Пометить все как прочитанные',
    'Previous Page': 'Предыдущая Страница',
    'Next Page': 'Следующая Страница',
    'Filter list': 'Список фильтров',
    'Open event': 'Открыть событие',
    'Set as unread': 'Пометить как непрочитанное',
    'Set as read': 'Пометить как прочитанное',
    'Go to chart': 'Перейти к графику',
    'No alert events yet.': 'Оповещений о событиях пока нет.',
    'Last 10 days': 'Последние 10 дней',
    'Consumption:': 'Потребление:',
    'BACK': 'НАЗАД',
    'An error occurred while loading the groups.': 'При загрузке групп произошла ошибка.',
    'Sensors Count:': 'Количество датчиков:',
    'Total Consumption:': 'Общее потребление:',
    'Today:': 'Сегодня:',
    'Granularity': 'Детализация',
    'Max:': 'Макс:',
    'Min:': 'Мин:',
    'Avg:': 'Средн:',
    'GO TO ANALYSIS': 'ПЕРЕЙТИ К АНАЛИЗУ',
    'An error occurred while updating the event': 'При обновлении события произошла ошибка ',
    'There is no consumption data available for the selected criteria. Cost simulation can\'t be calculated in this case.': 'Для выбранных критериев нет данных о потреблении. Невозможно провести моделирование стоимости.',
    'Today': 'Сегодня',
    'This {{period}}': 'Этот {{period}}',
    'Select date': 'Выберите дату',
    'Date Range': 'Диапазон дат',
    'Select date range': 'Выберите диапазон дат',
    'Reset Date Range': 'Сбросить диапазон дат',
    'month': 'месяц',
    'day': 'день',
    'week': 'неделя',
    'custom': 'пользовательский',
    'Invalid': 'Неверный',
    'Already exists': 'Уже существует',
    'New Recipient': 'Новый получатель',
    'Recipient e-mail': 'Электронная почта получателя',
    'Add Recipient': 'Добавить получателя',
    'Above': 'Выше',
    'Below': 'Ниже',
    'Above or below': 'Выше или ниже',
    'I want to receive notifications if {{granularity_select}} of consumption is {{alert_value}} {{alert_type}} {{alert_operation}} than the average of the last {{alert_period_quantity}}.': 'Я хочу получать уведомления, если {{granularity_select}} потребления {{alert_value}} {{alert_type}} {{alert_operation}}, чем среднее значение за последний {{alert_period_quantity}}.',
    '% Consumption': '% Потребления',
    'kWh Consumption': 'Потребление, кВтч',
    'I would like to receive a notification if the accumulated consumption during the period {{alert_period_quantity}} reaches {{alert_value}} $t(kWh).': 'Я хотел бы получить уведомление, если накопленное потребление за период {{alert_period_quantity}} достигнет {{alert_value}} $t(kWh).',
    'I would like to receive a notification if the consumption measured goes {{entity_status}} at any moment between {{alert_init_period}} and {{alert_end_period}}. The checking frequency is hourly.': 'Я хотел бы получить уведомление, если измеренное потребление переходит {{entity_status}} в любой момент между {{alert_init_period}} и {{alert_end_period}}. Периодичность проверки почасовая.',
    'I would like to receive a notification if the sensor accumulated cost reaches {{currency_code}}{{alert_value}} in the period {{alert_period}}': 'Я хотел бы получить уведомление, если накопленная стоимость датчика достигнет {{currency_code}} {{alert_value}} в период {{alert_period}}',
    '60 minutes': '60 минут',
    '24 hours': '24 часа',
    '7 days': '7 дней',
    '30 days': '30 дней',
    'off': 'откл',
    'on': 'вкл',
    'hour': 'час',
    'calendar day': 'календарный день',
    'calendar week': 'календарная неделя',
    'calendar month': 'календарный месяц',
    'Could not load tariffs.': 'Не удалось загрузить тарифы.',
    'New Relative Consumption Alert': 'Новое оповещение об относительном потреблении',
    'Edit Relative Consumption Alert': 'Редактировать оповещение об относительном потреблении',
    'New Absolute Consumption Alert': 'Новое оповещение об абсолютном потреблении',
    'Edit Absolute Consumption Alert': 'Редактировать оповещение об абсолютном потреблении',
    'New Equipment Status Alert': 'Новое оповещение о состоянии оборудования',
    'Edit Equipment Status Alert': 'Редактировать оповещение о состоянии оборудования',
    'New Cost Alert': 'Новое оповещение о стоимости',
    'Edit Cost Alert': 'Редактировать оповещение о стоимости',
    'Is required': 'Обязательный',
    'Name is too long': 'Имя слишком длинное',
    'Alert saved successfully': 'Оповещение успешно сохранено',
    'Alert created successfully': 'Оповещение создано успешно',
    'An error occurred while creating the alert.': 'При создании оповещения произошла ошибка.',
    'Edit Alert': 'Редактировать оповещение',
    'New Alert': 'Новое оповещение',
    'Alert Name': 'Имя оповещения',
    'Subgroup - Optional': 'Подгруппа - необязательно',
    'Sensor - Optional': 'Датчик - необязательно',
    'Notifications:': 'Уведомления:',
    'By email': 'По электронной почте',
    'On the graph': 'На графике',
    'By email and on the graph': 'По электронной почте и на графике',
    'Recipients': 'Получатели',
    'ADD E-MAIL': 'ДОБАВИТЬ E-MAIL',
    'There are no recipients added': 'Получатели не добавлены',
    'CANCEL': 'ОТМЕНИТЬ',
    'Save Alert': 'Сохранить оповещение',
    'Add Alert': 'Добавить оповещение',
    'The group or sensor selected has no tariff set. Please set a tariff for it on Configuration > Group to create cost alerts.': 'Для выбранной группы или датчика не установлен тариф. Пожалуйста, настройте тариф в меню «Конфигурация»> «Группа», чтобы создать оповещения о стоимости.',
    'Alert successfully removed.': 'Оповещение успешно удалено.',
    'An error occurred while trying to remove this alert.': 'При попытке удалить это предупреждение произошла ошибка.',
    'CREATE ALERT': 'Создать предупреждение',
    'No alerts available': 'Нет доступных предупреждений',
    'If you close all unsaved information will be lost.': 'Если вы закроете все несохраненные данные будут потеряны.',
    'I want to receive notifications if the {{alert_period}} accumulated cost reaches {{alert_value}} under the tariff set on configuration.': 'Я хочу получать уведомления, если {{alert_period}} накопленная стоимость достигнет {{alert_value}} по тарифу, установленному в конфигурации.',
    'I want to receive notifications if the {{alert_period}} accumulated consumption reaches {{alert_value}} $t(kWh).': 'Я хочу получать уведомления, если {{alert_period}} накопленное потребление достигнет {{alert_value}} $t(kWh).',
    'I want to receive notifications if the {{granularity_select}} consumption is {{alert_value}}$t(%) {{alert_operation}} the average of the last {{alert_period_quantity}}.': 'Я хочу получать уведомления, если {{granularity_select}} потребление {{alert_value}} $t(%) {{alert_operation}} составит среднее значение за последние {{alert_period_quantity}}.',
    'I want to receive notifications if the {{granularity_select}} consumption is {{alert_value}} $t(kWh) {{alert_operation}} the average of the last {{alert_period_quantity}}.': 'Я хочу получать уведомления, если {{granularity_select}} потребление {{alert_value}} $t(kWh) {{alert_operation}} составит среднее значение за последние {{alert_period_quantity}}.',
    'I want to receive notifications if the sensor goes {{entity_status}} {{alert_period}}.': 'Я хочу получать уведомления, если статус датчика изменится на {{entity_status}} {{alert_period}}.',
    'at any moment between {{alert_init_period}} and {{alert_end_period}}': 'в любой момент между {{alert_init_period}} и {{alert_end_period}}',
    'at any moment': 'в любой момент',
    'Details': 'Подробности',
    'Show more': 'Подробнее',
    'Cost Alert': 'Оповещение о стоимости',
    'Absolute Consumption': 'Полное потребление',
    'Relative Consumption - %': 'Относительное потребление -%',
    'Relative Consumption - kWh': 'Относительное потребление - кВтч',
    'Equipment Status Alert': 'Оповещение о состоянии оборудования',
    'Alert category': 'Категория оповещения',
    'Group:': 'Группа:',
    'Subgroup:': 'Подгруппа:',
    'Sensor:': 'Датчик:',
    'This report will be permanently removed.': 'Этот отчет будет навсегда удален.',
    'Choose Alert Type': 'Выберите тип оповещения',
    'Absolute consumption': 'Полное потребление',
    'Equipment status': 'Состояние оборудования',
    'Relative consumption': 'Относительное потребление',
    'minute': 'минута',
    'minutes': 'мин',
    'hours': 'час',
    'days': 'дни',
    'weeks': 'недели',
    'months': 'мес',
    'Minute': 'Мин',
    'above': 'выше',
    'below': 'ниже',
    'either above or below': 'или выше или ниже',
    'Sensor {{sensor}} was {{status}}.': 'Датчик {{sensor}} был в {{status}}.',
    'Equipment went {{status}}': 'Оборудование перешло в {{status}}',
    'The accumulated cost has reached {{value}} in the last {{granularity}}': 'Накопленная стоимость достигла {{value}} за последний {{granularity}}',
    'Sensor connected.': 'Датчик подключен.',
    'Sensor disconnected.': 'Датчик отключен.',
    '{{granularity}} consumption is {{value}} {{operation}} the average of the last {{avgQuantity}} {{avgGranularity}}': 'Потребление {{granularity}} равно {{value}} {{operation}} среднему значению за последние {{avgQuantity}} {{avgGranularity}}',
    'The accumulated consumption has reached {{value}} kWh in the last {{granularity}}': 'Накопленное потребление достигло {{value}} кВтч за последний {{granularity}}',
    'Could not load countries.': 'Не удалось загрузить список стран.',
    'New Tariff': 'Новый тариф',
    'Please fill the information to add a new tariff.': 'Пожалуйста, введите необходимую информацию, чтобы добавить новый тариф.',
    'Add Tariff': 'Добавить тариф',
    'Edit Tariff': 'Редактировать тариф',
    'Please fill the information to edit the tariff.': 'Пожалуйста, внесите необходимые изменения в настройки тарифа.',
    'Submit': 'Отправить',
    'Please set at least one: a weekday, a time or a limit.': 'Пожалуйста, выберите хотя бы одно: день недели, время или лимит.',
    'Please select a start time and an end time.': 'Пожалуйста, выберите время начала и время окончания.',
    'Please select a limit and its value.': 'Пожалуйста, выберите лимит и его значение.',
    'Please select an end time later than start time.': 'Пожалуйста, выберите время окончания позже, чем время начала.',
    'The new tariff was successfully added.': 'Новый тариф был успешно добавлен.',
    'An error occurred while adding the tariff.': 'При добавлении тарифа произошла ошибка.',
    'An error occurred while editing the tariff.': 'При редактировании тарифа произошла ошибка.',
    'The tariff was successfully edited.': 'Тариф был успешно отредактирован.',
    'Tariff Name': 'Название тарифа',
    'Provider Name': 'Имя провайдера',
    'Select a country': 'Выберите страну',
    'Standing Charge ({{currencySymbol}}/day)': 'Фиксированный платеж({{currencySymbol}} / день)',
    'Peak and Off-Peak Tariffs': 'Пиковые и внепиковые тарифы',
    'Overlapping periods may result into calculation errors. In the case where the overlapping is mathematically consistent, the rate with the lowest granularity defined, among the possible ones, will be used in the calculation.': 'Перекрывающиеся периоды могут привести к ошибкам в расчетах. В случае, когда перекрытие математически непротиворечиво, в расчете будет использоваться ставка с наименьшей степенью детализации, определенной среди возможных.',
    'More than or equal to': 'Больше или равно',
    'Less than or equal to': 'Меньше или равно',
    'Monday': 'Понедельник',
    'Tuesday': 'Вторник',
    'Wednesday': 'Среда',
    'Thursday': 'Четверг',
    'Friday': 'Пятница',
    'Saturday': 'Суббота',
    'Sunday': 'Воскресенье',
    'Weekday': 'Будний день',
    'Start Time': 'Время начала',
    'End Time': 'Время окончания',
    'Limit (kWh/month)': 'Лимит (кВтч / месяц)',
    'Could not load countries data.': 'Не удалось загрузить данные по странам.',
    'CREATE TARIFF': 'СОЗДАТЬ ТАРИФ',
    'Reload': 'Перезагрузить',
    'No tariffs available': 'Нет доступных тарифов',
    'Leave this section?': 'Покинуть этот раздел?',
    'If you leave this section, inputs you may have entered will not be saved.': 'Если вы покинете этот раздел, введенные вами данные не будут сохранены.',
    'Yes, leave': 'Да, покинуть',
    'No, stay here': 'Нет, остаться',
    'Tariff successfully removed.': 'Тариф успешно удален.',
    'Country:': 'Страна:',
    'Standing charge per day:': 'Фиксированный платеж в день:',
    'Off-peak time:': 'Внепиковое время:',
    'Peak time:': 'Пиковое время',
    'Peak rate:': 'Пиковая скорость:',
    'kWh/': 'кВтч /',
    'Currency:': 'Валюта:',
    'Standing charge:': 'Фиксированный платеж:',
    'Week day:': 'День недели:',
    'Start/End time:': 'Время начала / окончания:',
    'Limit:': 'Лимит:',
    'We are deleting {{tariffName}} tariff, please wait.': 'Мы удаляем тариф {{tariffName}}, пожалуйста, подождите.',
    'The Tariff will be permanently deleted.': 'Тариф будет навсегда удален.',
    'Select weekday': 'Выберите день недели',
    'Add rate': 'Добавить ставку',
    'Select limit': 'Выберите лимит',
    'Add limit value': 'Добавить величину лимита',
    'Add standing charge': 'Добавить фиксированный платеж',
    'Rate ({{currencySymbol}}/kWh)': 'Ставка ({{currencySymbol}} / кВтч)',
    'No sensors were selected for group nor for any subgroup. Please select at least one sensor and try again.': 'Для группы или подгруппы не было выбрано ни одного сенсора. Пожалуйста, выберите хотя бы один сенсор и попробуйте еще раз.',
    'Group added successfully.': 'Группа успешно добавлена',
    'An error occurred while adding the group.': 'При добавлении группы произошла ошибка.',
    'Group edited successfully.': 'Группа успешно отредактирована',
    'An error occurred while editing the group.': 'При редактировании группы произошла ошибка.',
    'Back': 'Назад',
    'Skip': 'Пропустить',
    'Save and create group': 'Сохранить и создать группу',
    'Next': 'Далее',
    'Total Load Sensor': 'Датчик общей нагрузки',
    'Total load sensor': 'Датчик общей нагрузки',
    'Sub Load Sensors': 'Датчики нагрузки нижнего уровня',
    'Sub load sensors': 'Датчики нагрузки нижнего уровня',
    '(If any)': '(если есть)',
    'Label <small>(Optional)</small>': 'Метка <small>(Необязательно)</small>',
    'Tag <small>(Optional)</small>': 'Тег <small>(необязательно)</small>',
    'Tariff <small>(Optional)</small>': 'Тариф <small>(Необязательно)</small>',
    'This groups has no subgroups.': 'У этих групп нет подгрупп',
    'We are deleting the group {{groupName}}, please wait.': 'Мы удаляем группу {{groupName}}, пожалуйста, подождите.',
    'The Group and all related Subgroups will be permanently deleted.': 'Группа и все относящиеся к ней подгруппы будут безвозвратно удалены.',
    'Group Name': 'Имя группы',
    'Tag (Optional)': 'Тег (необязательно)',
    'The selected tags at any subgroup will not be listed here. Please remove the tag from its respective subgroup to be able to assign it for this group.': 'Выбранные теги в любой подгруппе не будут отображены здесь. Пожалуйста, удалите теги из их подгруппы, чтобы переназначить их в эту группу',
    'Tariff (Optional)': 'Тариф (Необязательно)',
    'Review Details': 'Проверьте данные',
    'Tariff:': 'Тариф:',
    'Tag:': 'Тег:',
    'No sensors were selected.': 'Датчики не выбраны',
    'Total Load Sensors': 'Датчики общей нагрузки',
    'Sub Load Sensor': 'Датчик нагрузки нижнего уровня',
    'Label': 'Метка',
    'No subgroups were created.': 'Подгруппы не созданы',
    'Group successfully removed.': 'Группа успешно удалена',
    'There was an error removing this group. Please, try again or contact support.': 'При удалении данной группы произошла ошибка. Пожалуйста, попробуйте еще раз, или обратитесь в службу поддержки.',
    'CREATE GROUP': 'СОЗДАТЬ ГРУППУ',
    'No groups available': 'Нет доступных групп',
    'Do you want to keep editing your data?': 'Хотите продолжить редактировать?',
    'You can start editing from where you last stopped for as long as you keep your session alive.': 'Вы можете продолжить редактирование на том месте, где вы остановились, пока ваша сессия активна.',
    'Yes, Keep it': 'Да, оставить',
    'No': 'Нет',
    'Sensor-phase': 'Датчик-фаза',
    'Add a label': 'Добавить метку',
    'Select or create a new Tag': 'Выбрать или создать новый Тег',
    'Select a Tariff': 'Выберите тариф',
    'There are no sensors-phases left to add.': 'Все датчики-фазы добавлены.',
    'Some inputs are invalid or incomplete. Please correct them and try again.': 'Введенные данные неверны или неполны. Пожалуйста, исправьте и повторите попытку.',
    'Subgroup Configurations': 'Конфигурации подгрупп',
    'Subgroup Name': 'Имя подгруппы',
    'The selected tags at any subgroup or group will not be listed here. Please remove the tag from its respective entity to be able to assign it for this subgroup.': 'Выбранные теги в любой подгруппе не будут отображены здесь. Пожалуйста, удалите тег из его группы, чтобы переназначить его в эту подгруппу',
    'Delete subgroup': 'Удалить подгруппу',
    'Add new subgroup': 'Добавить новую подгруппу',
    'This Subgroup will be permanently deleted.': 'Эта подгруппа будет удалена.',
    'Yes, Delete it.': 'Да, удалить.',
    'Group Configuration': 'Настройка группы',
    'Subgroups Configuration': 'Конфигурация подгрупп',
    'Level:': 'Уровень',
    'Periodicity:': 'Периодичность',
    'Average of:': 'В среднем от:',
    'Absolute Consumption Level': 'Уровень полного потребления',
    'Relative Consumption Level': 'Относительный уровень потребления',
    'Same 3 Months': 'То же за 3 месяца',
    'No benchmarks available': 'Нет ничего для сравнения',
    'Sensor {{status}}': 'Датчик {{status}}',
    'Report successfully removed.': 'Отчет успешно удален',
    'An error occurred while trying to remove this report.': 'При попытке удалить этот отчет произошла ошибка.',
    'CREATE REPORT': 'Создать отчет',
    'No reports available': 'Нет доступных отчетов',
    'An error occurred while generating you report.': 'При создании вашего отчета произошла ошибка.',
    'Daily': 'Ежедневно',
    'Weekly': 'Еженедельно',
    'Monthly': 'Ежемесячно',
    '{{period_quantity}} Months': '{{period_quantity}} месяцев',
    '{{period_quantity}} Weeks': '{{period_quantity}} недель',
    'Report saved successfully': 'Отчет успешно сохранен',
    'Report created successfully': 'Отчет успешно создан',
    'An error occurred while creating you report.': 'При создании вашего отчета произошла ошибка.',
    'Edit Report': 'Редактировать отчет',
    'New Report': 'Новый отчет',
    'Report Name': 'Имя отчета',
    'Filters': 'Фильтры',
    'There is no recipients added': 'Список получателей пуст',
    'Trigger': 'Триггер',
    'Send a {{period}} report at the end of every {{trigger}}': 'Посылать {{period}} отчет в конце каждого {{trigger}}',
    'Preview': 'Предварительный просмотр',
    'Save Report': 'Сохранить отчет',
    'Add Report': 'Добавить отчет',
    'Period:': 'Период:',
    'Send every:': 'Посылать каждую:',
    'Status': 'Статус',
    'ID': 'ID',
    'Allocated to': 'Закреплен за',
    'Not allocated': 'Не закреплен',
    'Never connected': 'Никогда не подключался',
    '{{status}} since {{dateAndTime}}': '{{status}} начиная с {{dateAndTime}}',
    'Online': 'На связи',
    'Offline': 'Не доступен',
    'No sensors set up yet.': 'Ни один датчик не настроен',
    'Select start time': 'Выберите время начала',
    'Select end time': 'Выберите время оканчания',
    'No consumption data': 'Нет данных по потреблению',
    'benchmarking': 'Сравнение с эталоном',
    'Subgroups': 'Подгруппы',
    'Total Load Sensors:': 'Датчики общей нагрузки:',
    'Select...': 'Выбрать...',
    'Group Configurations': 'Настройки групп',
    'Report {0} from {1} to {2}': 'Отчет {0} с {1} по {2}',
    'Alert {0} was triggered': 'Сработало оповещение {0}',
    'Dear Partner,': 'Дорогой Партнер,',
    'Alert {0} was triggered at {1}.': 'Оповещение {0} сработало в {1}.',
    'Description: ': 'Описание:',
    'Consumption is {0}{1} {2} the average of the last {3} {4}.': 'Потребление составляет {0} {1} от среднего потребления за последние {2} {3}.',
    '{0} was detected to be switched {1} during the check.': 'В ходе проверки обнаружено, что {0} в состоянии {1}',
    '{0} accumulated consumption surpassed the threshold defined at {1}.': '{0} накопленное потребление превысило порог, определенный в {1}. Пожалуйста, ознакомьтесь с более подробной информацией.',
    '{0} accumulated cost surpassed the threshold defined at {1}.': '{0} накопленная стоимость превысила порог, определенный в {1}. Пожалуйста, ознакомьтесь с более подробной информацией.',
    'Please access ': 'Пожалуйста, войдите',
    'here': 'здесь',
    'for more info.': 'для получения более подробной информации.',
    'You can disable this alert by erasing the email from the list at ': 'Вы можете выключить это оповещение удалив сообщение из списка',
    'Best regards,': 'С наилучшими пожеланиями,',
    'or': 'или',
    'Voltaware reset password information': 'Информация для смены пароля на сайте Voltaware',
    'Hi,': 'Здравствуйте,',
    'You have requested to reset your Voltaware Building Monitoring Dashboard password. Your new password is:': 'Вы запросили сменить пароль доступа к панели мониторинга электричества Voltaware. Ваш новый пароль:',
    'If you have not requested to reset your password please contact our customer service immediately.': 'Если это не вы запросили смену пароля, пожалуйста немедленно свяжитесь со службой поддержки.',
    'Kind regards,': 'С уважением,',
    'The Voltaware Team': 'Команда Voltaware',
    'No results found': 'Результаты не найдены',
    'There is no data available as no tariff has been set up. Cost simulation can\'t be calculated in this case.': 'Данные отсутствуют, так как тариф не был установлен. Моделирование затрат не может быть рассчитано в этом случае \n',
    'Home': 'Дом',
    'Equipment Status': 'Состояние оборудования',
    'Total aggregated consumption': 'Общее потребление\n',
    'Total aggregated cost': 'Общая стоимость\n \n',
    'Others, aggregated': 'Другие, в совокупности',
    'Most Consuming - Groups': 'Наиболее потребляющие - группы',
    'There is no data available': 'Данные отсутствуют',
    'Most Consuming - Sensors': 'Наиболее потребляющие - датчики',
    'Cost data has more than one currency. Visualisations are showing only the most consuming currency.': 'Данные о затратах имеют более одной валюты. Визуализации показывают только самую потребляющую валюту.',
    'Most Costly - Groups': 'Наиболее дорогостоящие - группы \n',
    'Click {{here_link}} to go to the last visited page': 'Щелкните {{here_link}}, чтобы перейти к последней посещенной странице',
    'Most Costly - Sensors': 'Наиболее дорогостоящие - датчики',
    'Percentage change in relation to last period': 'Процентное изменение по отношению к прошлому периоду\n',
    'Percentage change not applicable, as previous period has no data': 'Процентное изменение не применимо, так как предыдущий период не содержит данных',
    'N/A': 'Н/Д',
    'Recent Events': 'Последние события',
    'Power Peak': 'Пиковая мощность ',
    '**': '**',
    'kW': 'кВт',
    'The peak demand was of': 'Пиковый спрос был\n',
    'on {{peak_time}}': 'Включен {{peak_time}}',
    '{0} power demand went above {1} kW.': '{0} пиковый спрос превысил {1} кВт. Пожалуйста проверьте это.',
    'The power demand went above {{value}} kW in the last {{granularity}}': 'Пиковый спрос превысил {{value}} кВт за последний {{granularity}}',
    'I would like to receive a notification if the active power goes above {{alert_value}} kW in the last {{alert_period}}.': 'Я хотел бы получить уведомление, если активная мощность превышает {{alert_value}} кВт в последний {{alert_period}}.',
    'New Power Alert': 'Оповещение о новом питании',
    'Edit Power Alert': 'Изменить оповещение о мощности',
    'Power Alert': 'Оповещение о мощности',
    'I want to receive notifications if the active power goes above {{alert_value}} $t(kW) in the last {{alert_period}}.': 'Я хочу получать уведомления, если активная мощность превышает {{alert_value}} $t(кВт) в последний {{alert_period}}.',
    'Users': 'Пользователи',
    'E-mail': 'Электронная почта',
    'Role': 'Роль',
    'Created At': 'Создан в ',
    'Enabled': 'Включен ',
    'Administrator': 'Администратор',
    'Read-only': 'Только для чтения',
    'CREATE USER': 'Создать пользователя',
    'Edit user': 'Редактировать пользователя',
    'Delete user': 'Удалить пользователя',
    'No users yet.': 'Пока нет пользователей.',
    'If you delete, all the user information will be lost.': 'Если вы удалите, все данные пользователя будут потеряны.',
    'Read-only user': 'Пользователь только для чтения',
    'Disabled': 'Отключен',
    'User saved successfully': 'Пользователь успешно сохранен',
    'User created successfully': 'Пользователь успешно создан',
    'An error occurred while creating the user.': 'При создании пользователя произошла ошибка. ',
    'Edit User': 'Редактировать пользователя',
    'New User': 'Новый пользователь',
    'User login e-mail': 'Электронная почта для входа пользователя',
    'Save User': 'Сохранить пользователя',
    'Add User': 'Добавить пользователя',
    'User deleted successfully': 'Пользователь успешно удален ',
    'Welcome to Voltaware': 'Добро пожаловать в Voltaware',
    'Greetings,': 'С Уважением, ',
    'Welcome to Voltaware Electricity Monitoring Dashboard': 'Добро пожаловать в панель мониторинга электричества Voltaware',
    'You can access the login page': 'Вы можете получить доступ к панеле входа ',
    'Your credentials are:': 'Ваши учетные данные: ',
    'User:': 'Пользователь:',
    'password:': 'Пароль:',
    'Use the same password required for all our services.': 'Используйте одинаковый пароль, необходимый для всех наших услуг.',
    'For support requests, please contact us by going to our support page.': 'По вопросам поддержки, пожалуйста, свяжитесь с нами, перейдя на нашу страницу поддержки.',
    'Many thanks,': 'Большое спасибо,',
    'You have requested to reset your Voltaware Electricity Monitoring Dashboard password. Your new password is:': 'Вы запросили сброс пароля для доступа к панели мониторинга электричества Voltaware. Ваш новый пароль:',
    '(all phases)': '(все фазы)',
    'GENERATE REPORT': 'СОЗДАТЬ ОТЧЕТ',
    'Generate Instant Report': 'Создать мгновенный отчет',
    'Select a group, a subgroup or a sensor': 'Выберите группу, подгруппу или датчик',
    'Select a period': 'Выберите период',
    'Generate Report': 'Создать отчет',
    'I want to receive notifications if the {{alert_period}} accumulated cost reaches {{alert_value}} under the tariff set on configuration._male': 'Я хочу получать уведомление если {{alert_period}} накопленная стоимость достигнет {{alert_value}} по тарифу, указанному в конфигурации._male',
    'I want to receive notifications if the {{alert_period}} accumulated cost reaches {{alert_value}} under the tariff set on configuration._female': 'Я хочу получать уведомление если {{alert_period}} накопленная стоимость достигнет {{alert_value}} по тарифу, указанному в конфигурации._female',
    'I want to receive notifications if the {{alert_period}} accumulated consumption reaches {{alert_value}} $t(kWh)._male': 'Я хочу получать уведомление если  {{alert_period}} накопленное потребление достигнет  {{alert_value}} $t(kWh)._male',
    'I want to receive notifications if the {{alert_period}} accumulated consumption reaches {{alert_value}} $t(kWh)._female': 'Я хочу получать уведомление если  {{alert_period}} накопленное потребление достигнет  {{alert_value}} $t(kWh)._female',
    'I want to receive notifications if the {{granularity_select}} consumption is {{alert_value}}$t(%) {{alert_operation}} the average of the last {{alert_period_quantity}}._male': 'Я хочу получать уведомление если {{granularity_select}} потребление составит {{alert_value}}$t(%) {{alert_operation}} от среднего за последние {{alert_period_quantity}}._male',
    'I want to receive notifications if the {{granularity_select}} consumption is {{alert_value}}$t(%) {{alert_operation}} the average of the last {{alert_period_quantity}}._female': 'Я хочу получать уведомление если {{granularity_select}} потребление составит {{alert_value}}$t(%) {{alert_operation}} от среднего за последние {{alert_period_quantity}}._female',
    'I want to receive notifications if the {{granularity_select}} consumption is {{alert_value}} $t(kWh) {{alert_operation}} the average of the last {{alert_period_quantity}}._male': 'Я хочу получать уведомление если {{granularity_select}} потребление составит {{alert_value}} $t(kWh) {{alert_operation}} от среднего за последние {{alert_period_quantity}}._male',
    'I want to receive notifications if the {{granularity_select}} consumption is {{alert_value}} $t(kWh) {{alert_operation}} the average of the last {{alert_period_quantity}}._female': 'Я хочу получать уведомление если {{granularity_select}} потребление составит {{alert_value}} $t(kWh) {{alert_operation}} от среднего за последние {{alert_period_quantity}}._female',
    'The accumulated cost has reached {{value}} in the last {{granularity}}_male': 'Накопленная стоимость составила {{value}} в последнем {{granularity}}_male',
    'The accumulated cost has reached {{value}} in the last {{granularity}}_female': 'Накопленная стоимость составила {{value}} в последнем {{granularity}}_female',
    'The accumulated consumption has reached {{value}} kWh in the last {{granularity}}_male': 'Накопленное потребление составило {{value}} kWh в последнем {{granularity}}_male',
    'The accumulated consumption has reached {{value}} kWh in the last {{granularity}}_female': 'Накопленное потребление составило {{value}} kWh в последнем {{granularity}}_female',
    'above or below': 'Выше или ниже',
    '{{granularity}} consumption is {{value}} {{operation}} the average of the last {{avgQuantity}} {{avgGranularity}}_male': '{{granularity}} потребление составляет {{value}} {{operation}} от среднего за последний {{avgQuantity}} {{avgGranularity}}_male',
    '{{granularity}} consumption is {{value}} {{operation}} the average of the last {{avgQuantity}} {{avgGranularity}}_female': '{{granularity}} потребление составляет {{value}} {{operation}} от среднего за последний {{avgQuantity}} {{avgGranularity}}_female',
    'Tenants': 'Арендаторы',
    'Login as root': 'Зайти под учетной записью root',
    'Change language to brazilian portuguese': 'Сменить язык на Португальский',
    'There was an error trying to signin as user': 'При попытке авторизации пользователя произошла ошибка',
    'CREATE CLIENT': 'СОЗДАТЬ КЛИЕНТА',
    'No clients available': 'Клиенты отсутствуют',
    'Primary color:': 'Основной цвет:',
    'Default': 'По умолчанию',
    'Secondary color:': 'Дополнительный цвет:',
    'Login as client': 'Вход для клиентов',
    'An error occurred while getting the sensors.': 'При получении списка датчиков произошла ошибка',
    'Sensor Management': 'Управление датчиками',
    'Select the sensors': 'Выбрать датчики',
    'Save': 'Сохранить',
    'Client saved successfully': 'Настройки клиента успешно сохранены',
    'Client created successfully': 'Клиент успешно создан',
    'An error occurred while creating the client.': 'При создании клиента произошла ошибка',
    'Edit Client': 'Править настройки клиента',
    'New Client': 'Новый клиент',
    'Client name': 'Имя клиента',
    'Save Client': 'Сохранить настройки клиента',
    'Add Client': 'Добавить клиента',
    'Users disabled successfully': 'Пользователи успешно отключены',
    'An error occurred while disabling the users': 'При отключении пользователей произошла ошибка',
    'Users enabled successfully': 'Пользователи успешно включены',
    'An error occurred while enabling the users': 'При включении пользователей произошла ошибка',
    'DISABLE ALL': 'ОТКЛЮЧИТЬ ВСЕХ',
    'ENABLE ALL': 'ВКЛЮЧИТЬ ВСЕХ',
    'Login as user': 'Вход для пользователей',
    'Year': 'Год',
    'Select the year': 'Выбрать год',
    'This Week': 'На этой неделе',
    'This Month': 'Этот месяц',
    'Last Day': 'Последний день',
    'Last Week': 'На прошлой неделе',
    'Last Month': 'Прошлый месяц',
    'Sensors allocated successfully': 'Список датчиков успешно сохранен',
    'An error occurred while allocating the sensor.': 'При сохранении датчика произошла ошибка.',
    'An error occurred while selecting the logo image.': 'Произошла ошибка при выборе изображения логотипа.',
    'Primary color': 'Основной цвет',
    'Secondary color': 'Вторичный цвет',
    'Logo image to replace': 'Изображение логотипа для замены',
    'Logo image': 'Изображение логотипа',
    'Remove current logo image': 'Удалить текущее изображение логотипа',
    'Show Powered by Voltaware logo': 'Шоу Powered by Voltaware логотип',
  },
}
