import React from 'react';

import {Button} from '../button';
import {Field} from './field';
import {InputSearchInline} from './input-search-inline';


class Input extends Field {
    render () {
        let spanClass = this.getSpanClass();

        return (
            <label
                className={this.props.icon ? 'has-icon' : ''}>
                {this.props.icon ? this.props.icon : null}
                <span className={spanClass}>{this.props.placeholder}</span>
                <input autoComplete={this.props.autocomplete !== undefined ? this.props.autocomplete : 'on'}
                    className={this.props.hasError ? 'error' : ''}
                    disabled={this.props.disabled}
                    name={this.props.name}
                    onBlur={this.blur}
                    onChange={this.props.change}
                    onFocus={this.focus}
                    type={this.props.type}
                    min={this.props.min}
                    step={this.props.step}
                    value={this.props.value} />
                <span className="border" />

                {this.props.hasError && typeof this.props.hasError === 'string' && (
                    <small className="input-error">{this.props.hasError}</small>
                )}
                {this.props.type === 'search' ?
                    <Button action="search" disabled={this.props.disabled} loading={this.props.loading} type="submit" />
                    : null}

                {this.props.type === 'search' && this.props.data ?
                    <InputSearchInline data={this.props.data}
                        dataKey={this.props.dataKey}
                        dataLabel={this.props.dataLabel}
                        loading={this.props.loading}
                        select={this.props.select}
                        showing={this.state.focus} /> : null}

            </label>
        );
    }
}

export {Input};
