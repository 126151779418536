import React from 'react';
import Table, { TableBody, TableCell, TableHead, TableRow } from 'material-ui/Table';
import {format} from 'd3-format';

import i18n from '../../../i18n'

const kwhFormat = (d) => {
    if (d < 1){
        return format(".2r")(d);
    }else{
        return format(".2f")(d);
    }
}

const BenchmarkResumeTable = ({
    absoluteBenchmark,
    relativeBenchmark,
}) => (
    <div className="tables">
        <Table className="chart-table">
            <TableHead>
                <TableRow>
                    <TableCell>{i18n.t('Benchmark')}</TableCell>
                    <TableCell>{i18n.t('Type')}</TableCell>
                    <TableCell>{i18n.t('Total Consumption')}</TableCell>
                    <TableCell>{i18n.t('Total Savings')}</TableCell>
                    <TableCell>{i18n.t('Total Losses')}</TableCell>
                </TableRow>
            </TableHead>

            <TableBody>
                <TableRow>
                    <TableCell>{i18n.t('Fixed Benchmark')}</TableCell>
                    <TableCell>{i18n.t('Absolute')}</TableCell>
                    <TableCell>{kwhFormat(absoluteBenchmark.total)} {i18n.t('kWh')}</TableCell>
                    <TableCell>{kwhFormat(absoluteBenchmark.savings)} {i18n.t('kWh')}</TableCell>
                    <TableCell>{kwhFormat(absoluteBenchmark.losses)} {i18n.t('kWh')}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>{i18n.t('Relative Benchmark')}</TableCell>
                    <TableCell>{i18n.t('Relative')}</TableCell>
                    <TableCell>{kwhFormat(relativeBenchmark.total)} {i18n.t('kWh')}</TableCell>
                    <TableCell>{kwhFormat(relativeBenchmark.savings)} {i18n.t('kWh')}</TableCell>
                    <TableCell>{kwhFormat(relativeBenchmark.losses)} {i18n.t('kWh')}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    </div>
)

export default BenchmarkResumeTable;
