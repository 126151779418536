import React, {Component} from 'react';
import Select from 'react-select';

import PropTypes from 'prop-types'

import i18n from '../../../i18n';

import { API } from '../../../components/api';
import { Title } from '../../../elements/title';
import { Button } from '../../../elements/button';
import SystemSnackbar from '../../../elements/material-ui/SystemSnackbar';
import { getSensorStructured } from '../../../components/utils'

import './sensor-management.css';

class SensorManagement extends Component {
    state = {
        sensors: [],
        initialSensors: [],
        selectedSensors: null,
        loading: true,
        saving: false,
        showSnackBar: false,
        snackbar: {
            type: 'warning',
            message: ''
        },
    }

    componentDidMount() {
        this.getSensors()
    }

    getSensors = async () => {
        try {
            const { data } = await API.SENSORS.GET();

            const selectedSensors = this.props.tenant.sensors.map(sensor => ({
                ...sensor,
                label: `${sensor.id}-${sensor.channel}`
            }))

            this.setState({
                loading: false,
                initialSensors: selectedSensors,
                sensors: [...this.getSensorsList(data), ...selectedSensors],
                selectedSensors: selectedSensors.map(({ label }) => label).join(',')
            })
        } catch (error) {
            this.openSnackBar(i18n.t('An error occurred while getting the sensors.'), 'error')
        }
    }

    getSensorsList = (sensors) => {
        if (!sensors || !sensors.length) {
            return []
        }

        return sensors.reduce((list, sensor) => {
            if (sensor.channels && sensor.channels.length){
                const sensorPhases = sensor.channels.map(sensor => ({
                    ...sensor,
                    ...getSensorStructured(sensor)
                }));

                list = [...list, ...sensorPhases];
            }
            return list;
        }, []);
    }

    onSelectChange = selectedSensors => {
        this.setState({ selectedSensors })
    }

    submit = async (e) => {
        e.preventDefault();

        this.setState({ saving: true });
        try {
            const includedSensors = this.state.selectedSensors.split(',').filter(sensorKey => !this.state.initialSensors.map(s => s.label).includes(sensorKey));

            if (includedSensors && includedSensors.length){
                await API.ROOT.TENANTS.SENSORS.EDIT({
                    tenantId: this.props.tenant.id,
                    sensors: includedSensors ? includedSensors.map(sensor => ({
                        id: sensor.split('-')[0],
                        channel: sensor.split('-')[1]
                    })) : []
                })
            }

            const removedSensors = this.state.initialSensors.filter(sensor => !this.state.selectedSensors.includes(sensor.label))
            if (removedSensors && removedSensors.length) {
                await API.ROOT.TENANTS.SENSORS.DELETE(removedSensors)
            }

            this.props.openParentSnackBar(i18n.t('Sensors allocated successfully'), 'success');
            this.props.handleCloseModal(true);
        } catch (error) {
            console.error(error)
            this.setState({ saving: false });
            this.openSnackBar(i18n.t('An error occurred while allocating the sensor.'), 'error');
        }
    }

    openSnackBar = (message, type) => this.setState({
        showSnackBar: true,
        snackbar: {
            message,
            type
        }
    })

    closeSnackBar = () => this.setState({
        showSnackBar: false
    })

    render () {
        const { sensors, selectedSensors, loading, saving, showSnackBar, snackbar } = this.state;

        return (
            <section id="sensor-management">
                <Title
                    element="h4"
                    text={i18n.t('Sensor Management')}
                />
                <form action="#" onSubmit={this.submit}>
                    <div ref={element => this.formDiv = element} className="form-content">
                        <div className="form-sections">
                            <Select
                                removeSelected
                                closeOnSelect
                                multi
                                disabled={loading}
                                onChange={this.onSelectChange}
                                valueKey="label"
                                options={sensors}
                                placeholder={i18n.t('Select the sensors')}
                                simpleValue
                                value={selectedSensors}
                            />
                        </div>
                    </div>
                    <div className="buttons-content">
                        <Button
                            action="preview"
                            label={i18n.t("CANCEL")}
                            disabled={loading || saving}
                            click={this.props.handleCloseModal}
                        />

                        <Button
                            type="submit"
                            action="submit"
                            disabled={loading || saving}
                            label={i18n.t('Save')}
                        />
                    </div>
                </form>

                <SystemSnackbar
                    open={showSnackBar}
                    message={snackbar.message}
                    onClose={this.closeSnackBar}
                    variant={snackbar.type}
                />
            </section>

        );
    }
}

SensorManagement.protoTypes = {
    editTenant: PropTypes.object,
    handleCloseModal: PropTypes.func.isRequired,
    handleOpenModal: PropTypes.func.isRequired,
    openParentSnackBar: PropTypes.func.isRequired,
}

export default SensorManagement;
