import React from 'react';
import {API} from '../../components/api';
import { withStyles } from 'material-ui/styles';

import classNames from 'classnames';
import {Button} from '../../elements/button';
//import {ErrorMessage} from '../../elements/error-message';
import {Input} from '../../elements/forms/input';
import {Text} from '../../elements/text';
import {Title} from '../../elements/title';
import SystemSnackbar from '../../elements/material-ui/SystemSnackbar'
import {Loading} from '../../elements/loading';
import {startHoursOptions, endHoursOptions} from '../../elements/forms/input-time-select';
import {TariffCompositionsAdd} from './add-tariff-composition';
import Select from 'react-select';
import Divider from 'material-ui/Divider';
import moment from 'moment';
import getSymbolFromCurrency from 'currency-symbol-map';

import i18n from '../../i18n';

import 'react-select/dist/react-select.css';
import './add-edit.css';


const MODE = {add: "add", edit: "edit"};

const initialState = {
    loading: false,
    mode: MODE.add,
    tariffName: '',
    providerName: '',
    chargeType: 'SINGLE',
    standingCharge: '',
    rate: '',
    selectedCountry: null ,
    showCountrySelectError: false,
    countries:[],
    loadingCountries: true,
    disabledCountry: true,
    loadingTariff: false,
    fieldsWithError: [],
    tariffCompositions: [],
    showSnackBar: false,
    snackbar: {
        type: 'warning',
        message: ''
    },
    initialCompositions: [],
};

const styles = theme => ({
    chargeTypeContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    formControl: {
        margin: '-15px'
    },
    radioDisabled: {
        '& svg': {
            color: 'rgba(0,0,0,.38)'
        }
    },
    radio: {
        margin: 0,
        padding: 0,
    },
    countrySelect: {
        zIndex: 99,
        '& input': {
            transition: 'none',
        }
    },
    divider: {
        position: 'absolute',
        width: '100%',
        left: 0
    },
    formInstructions: {
        width: '100%'
    },
    hasError: {
        '& > div': {
            borderColor: 'red'
        }
    }
});

const modes = {add: "add", edit: "edit"};

const defaultCurrencySymbol = '$';

class TariffAddEdit extends React.Component {
    constructor (props) {
        super(props);

        const tariffId = props.editTariffId;
        this.state = {
            ...initialState,
            mode: tariffId && tariffId > 0 ? modes.edit : modes.add,
            loadingTariff: !!(tariffId && tariffId > 0),
            tariffId: tariffId,
            currencySymbol: defaultCurrencySymbol,
        };

        API.COUNTRIES.GET().then((response) => {
            if (response.data && response.data.length){
                const countries = response.data.map(c => {
                    return {label: `${c.isoCode} - ${c.name}`, value: c.isoCode, currency: c.currency}
                });
                this.setState(
                    {countries: countries, loadingCountries: false, disabledCountry: false},
                    this.getTariff()
                );
            }else{
                this.handleLoadError({loadingCountries: false}, i18n.t("Could not load countries."))
            }
        }).catch((error) => {
            this.handleLoadError({loadingCountries: false}, i18n.t("Could not load countries."), error)
        });
    }

    loadErrorMessage = i18n.t("An error occurred while retrieving data.")

    MESSAGES = {
        add:{
            title: i18n.t("New Tariff"),
            instruction: i18n.t("Please fill the information to add a new tariff."),
            submit: i18n.t("Add Tariff")
        },
        edit:{
            title: i18n.t("Edit Tariff"),
            instruction: i18n.t("Please fill the information to edit the tariff."),
            submit: i18n.t("Submit")
        }
    };

    getTariff() {
        if (this.state.tariffId) {
            API.TARIFFS.GET(this.state.tariffId).then((response) => {
                if (response.data && response.data.length > 0){
                    const data = response.data[0];
                    this.setState({
                        loadingTariff: false,
                        initialCompositions: data.compositions,
                        tariffName: data.name,
                        rate: data.rate,
                        standingCharge: data.standingCharge || '',
                        providerName: data.providerName,
                        selectedCountry: this.state.countries.find(country => country.value === data.isoCountryCode),
                        currencySymbol: data && data.currency ? getSymbolFromCurrency(data.currency) : defaultCurrencySymbol,
                        tariffCompositions: data.compositions.map((composition, index) => ({
                            ...composition,
                            startTime: composition.startTime
                                ? startHoursOptions({}).find(({ value }) =>
                                    value === moment(composition.startTime, 'HH:mm:ss').format('HH:mm')
                                ) : null,
                            endTime: composition.endTime
                                ? endHoursOptions({}).find(({ value }) =>
                                    value === moment(composition.endTime, 'HH:mm:ss').format('HH:mm')
                                ) : null,
                            fieldsWithError: [],
                            seq: index + 1,
                        })),
                    });
                }
            });
        }
    }

    change = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }

    handleChargeTypeChange = event => {
        this.setState({chargeType: event.target.value});
    };

    handleCountryChange = selectedCountry => {
        this.setState({
            selectedCountry: selectedCountry,
            showCountrySelectError: false,
            currencySymbol: selectedCountry && selectedCountry.currency ? getSymbolFromCurrency(selectedCountry.currency) : defaultCurrencySymbol,
        })
    }

    handleLoadError = (state, message, error) => {
        state.loadErrorMessage = this.loadErrorMessage;
        this.setState(state);

        console.error(this.loadErrorMessage + " (" + message + ")");
        if (error)
            console.error(error);
    }

    changeTariffCompositions = (compositions, checkRowCallback) => {
        if (checkRowCallback && typeof checkRowCallback === 'function'){
            this.setState({
                tariffCompositions: compositions
            }, checkRowCallback);
        }else{
            this.setState({
                tariffCompositions: compositions
            });
        }
    }

    validate = () => {
        let fieldsWithError = [];
        let showCountrySelectError = false;
        if (this.state.tariffName === '') fieldsWithError.push('tariffName');
        if (this.state.rate === '') fieldsWithError.push('rate')
        if (this.state.selectedCountry === null) {
            fieldsWithError.push('selectedCountry')
            showCountrySelectError = true
        }

        let compositionError = false;
        const compositions = this.state.tariffCompositions.map(composition => {
            composition.fieldsWithError = [];

            if (composition.last)
                return { ...composition }

            if (!composition.weekday && !composition.startTime && !composition.endTime && !composition.limit){
                composition.fieldsWithError.push('weekday', 'startTime', 'endTime', 'limit');
                fieldsWithError.push('at-least-one-period-or-limit');
            }

            if (composition.endTime && !composition.startTime){
                composition.fieldsWithError.push('startTime');
                fieldsWithError.push('complete-time');
            }

            if (composition.startTime && !composition.endTime){
                composition.fieldsWithError.push('endTime');
                fieldsWithError.push('complete-time');
            }

            if (composition.startTime && composition.endTime && moment(composition.endTime.value, 'HH:mm').isBefore(moment(composition.startTime.value, 'HH:mm'))) {
                composition.fieldsWithError.push('endTime');
                fieldsWithError.push('invalid-time');
            }

            if (!composition.rate)
                composition.fieldsWithError.push('rate');

            if (composition.limitValue && !composition.limit){
                composition.fieldsWithError.push('limit');
                fieldsWithError.push('complete-limit');
            }

            if (composition.limit && !composition.limitValue){
                composition.fieldsWithError.push('limitValue');
                fieldsWithError.push('complete-limit');
            }

            if (composition.fieldsWithError.length > 0)
                compositionError = true;

            return { ...composition }
        });

        if (compositionError){
            if (fieldsWithError.includes('at-least-one-period-or-limit')){
                this.openSnackBar(i18n.t('Please set at least one: a weekday, a time or a limit.'), 'warning');
            }else if (fieldsWithError.includes('complete-time')){
                this.openSnackBar(i18n.t('Please select a start time and an end time.'), 'warning');
            }else if (fieldsWithError.includes('complete-limit')){
                this.openSnackBar(i18n.t('Please select a limit and its value.'), 'warning');
            }else if (fieldsWithError.includes('invalid-time')){
                this.openSnackBar(i18n.t('Please select an end time later than start time.'), 'warning');
            }
        }

        this.setState({
            fieldsWithError: fieldsWithError,
            loading: false,
            showCountrySelectError: showCountrySelectError,
            tariffCompositions: compositions,
        });

        return !fieldsWithError.length && !compositionError;
    }

    createTariffRequestBody = (state) => ({
        name: state.tariffName,
        rate: parseFloat(this.state.rate),
        standingCharge: parseFloat(state.standingCharge),
        providerName: state.providerName,
        isoCountryCode: state.selectedCountry.value,
    })

    parseComposition = composition => {
        const tariffComposition = {
            dayOfWeek: composition.weekday ? composition.weekday.value : null,
            rate: parseFloat(composition.rate),
            startTime: composition.startTime && composition.startTime.value ? moment(composition.startTime.value, 'HH:mm:ss').format('HH:mm') : null,
            endTime: composition.endTime && composition.endTime.value ?  moment(composition.endTime.value, 'HH:mm:ss').format('HH:mm') : null,
            limitType: composition.limit ? composition.limit.value : null,
            limitValue: composition.limitValue ? parseFloat(composition.limitValue) : null,
            standingCharge: composition.standingCharge ? parseFloat(composition.standingCharge) : null,
            seq: composition.seq,
        }

        if (composition.id)
            tariffComposition.id = composition.id;

        return tariffComposition;
    }

    send = async () => {
        this.setState({loading: true});
        const compositions = this.state.tariffCompositions.filter(composition => !composition.last).map(this.parseComposition);

        if (!this.state.tariffId){
            API.TARIFFS.ADD(
                this.createTariffRequestBody(this.state)
            ).then(async ({ data }) => {
                const savedCompositions = [];
                if (data.tariffId && compositions.length) {
                    try {
                        for (const composition of compositions){
                            const compositionResponse = await this.saveComposition(composition, data.tariffId);
                            // Store saved compositions in case of composition validation error from backend
                            savedCompositions.push({
                                ...composition,
                                id: compositionResponse.data.tariffCompositionId,
                            });
                        }

                        this.closeWindowAndShowSuccess(i18n.t('The new tariff was successfully added.'));
                    } catch (error) {
                        if (error.response.status === 400){
                            console.error("Error while saving compositions. There are intersections between two compositions.");
                            this.openSnackBar(i18n.t('There are intersection between two compositions'), 'warning');

                            // There was a validation error from backend. Some compositions now have ids and will be edited
                            const mergeSavedCompositions = this.getSavedCompositionsIds(savedCompositions);

                            // The tariff being added also now has an id and will be processed as an edition
                            this.setState({
                                loading: false,
                                tariffId: data.tariffId,
                                tariffCompositions: mergeSavedCompositions
                            });
                        }else{
                            this.closeWindowAndShowError(error, i18n.t("An error occurred while adding the tariff."));
                        }
                    }
                }else{
                    this.closeWindowAndShowSuccess(i18n.t('The new tariff was successfully added.'));
                }
            }).catch((error) => {
                this.closeWindowAndShowError(error, i18n.t("An error occurred while editing the tariff."));
            });
        }else{
            API.TARIFFS.EDIT(
                this.state.tariffId,
                this.createTariffRequestBody(this.state)
            ).then(async (response) => {
                if (compositions.length || this.state.initialCompositions.length) {
                    await this.removeCompositions(compositions)
                    const savedCompositions = [];
                    try {
                        for (const composition of compositions){
                            const compositionResponse = await this.saveComposition(composition, this.state.tariffId);
                            // Store added compositions in case of composition validation error from backend
                            savedCompositions.push({
                                ...composition,
                                id: compositionResponse.data.tariffCompositionId,
                            });
                        }

                        this.closeWindowAndShowSuccess(i18n.t('The tariff was successfully edited.'));
                    } catch (error) {
                        if (error.response.status === 400){
                            console.error("Error while saving compositions. There are intersection between two compositions");
                            this.openSnackBar(i18n.t('There are intersection between two compositions'), 'warning');

                            // There was a validation error from backend. Some compositions now have ids and will be edited
                            const mergeSavedCompositions = this.getSavedCompositionsIds(savedCompositions);

                            this.setState({
                                loading: false,
                                tariffCompositions: mergeSavedCompositions
                            });
                        }else{
                            this.closeWindowAndShowError(error, i18n.t("An error occurred while editing the tariff."));
                        }
                    }
                }else{
                    this.closeWindowAndShowSuccess(i18n.t('The tariff was successfully edited.'));
                }
            }).catch((error) => {
                this.closeWindowAndShowError(error, i18n.t("An error occurred while editing the tariff."));
            });
        }
    }

    saveComposition = async (composition, tariffId) => {
        return await API.TARIFFS.COMPOSITION[composition.id ? 'EDIT' : 'ADD']({
            ...composition,
            tariffId: tariffId
        })
    }

    getSavedCompositionsIds = (savedCompositions) => {
        if (savedCompositions && savedCompositions.length){
            const mergedSavedCompositionsIds = this.state.tariffCompositions.map((composition) => {
                const index = savedCompositions.findIndex(saved => saved.seq === composition.seq);
                return (index >= 0) ? { ...composition, id: savedCompositions[index].id } : composition;
            });
            return mergedSavedCompositionsIds;
        }
        return [].concat(this.state.tariffCompositions);
    }

    removeCompositions = compositions => {
        return Promise.all(this.state.initialCompositions
            .filter(composition => !compositions.some(({ id }) => id === composition.id))
            .map(composition => API.TARIFFS.COMPOSITION.DELETE(composition.id)
        ))
    }

    submit = (e) => {
        e.preventDefault();

        e.target.querySelector('button[type="submit"]').blur();

        if (this.validate()) this.send();

        return false;
    }

    openSnackBar = (message, type) => {
        this.setState({
            showSnackBar: true,
            snackbar: {
                message,
                type
            }
        });
    }

    closeSnackBar = () => {
        this.setState({
            showSnackBar: false
        });
    }

    closeWindowAndShowError = (error, message) => {
        console.error(error);
        this.props.handleCloseModal(false);
        this.props.showSnackBar(message, true)
    }

    closeWindowAndShowSuccess = (message) => {
        this.props.handleCloseModal(true);
        this.props.showSnackBar(message)
    }

    render () {
        const {classes} = this.props;
        const {showSnackBar, snackbar} = this.state;

        return (
            <section id="page-tariff-add-edit" className="modal-container">
                <Title element="h4" text={this.MESSAGES[this.state.mode].title} />

                <form action="#" onSubmit={this.submit} autoComplete="off">
                    <div className="form-inner-container">
                        <Text bold={true} text={this.MESSAGES[this.state.mode].instruction} />
                        <Input change={this.change}
                            disabled={this.state.loading}
                            hasError={this.state.fieldsWithError.indexOf('tariffName') >= 0 ? i18n.t('Is required') : false}
                            value={this.state.tariffName}
                            name="tariffName"
                            placeholder={i18n.t("Tariff Name")}
                            type="text" />

                        <div className="inline-fields">
                            <Input change={this.change}
                                disabled={this.state.loading}
                                value={this.state.providerName}
                                name="providerName"
                                placeholder={i18n.t("Provider Name")}
                                type="text" />

                            <Input
                                change={this.change}
                                disabled={this.state.loading}
                                hasError={this.state.fieldsWithError.indexOf('rate') >= 0  ? i18n.t('Is required') : false}
                                value={this.state.rate}
                                type="number"
                                min="0"
                                step="0.00001"
                                name="rate"
                                placeholder={i18n.t('Rate ({{currencySymbol}}/kWh)', {
                                    currencySymbol: this.state.currencySymbol
                                })}
                            />

                            <div className="select-control">
                                <Select
                                    name="country"
                                    className={this.state.showCountrySelectError ?
                                        classNames(classes.countrySelect, classes.hasError) :
                                        classes.countrySelect
                                    }
                                    value={this.state.selectedCountry}
                                    onChange={this.handleCountryChange}
                                    disabled={
                                        this.state.loadingCountries ||
                                        !this.state.countries.length}
                                    isLoading={this.state.loadingCountries}
                                    valueKey="value"
                                    labelKey="label"
                                    options={this.state.countries}
                                    placeholder={i18n.t("Select a country")}
                                    noResultsText={i18n.t('No results found')}
                                />
                                {this.state.showCountrySelectError && (
                                    <p className="select-error">
                                        {i18n.t('Is required')}
                                    </p>
                                )}
                            </div>

                            <Input
                                change={this.change}
                                disabled={this.state.loading}
                                value={this.state.standingCharge}
                                name="standingCharge"
                                type="number"
                                min="0"
                                step="0.00001"
                                placeholder={i18n.t('Standing Charge ({{currencySymbol}}/day)', {
                                    currencySymbol: this.state.currencySymbol
                                })}
                            />
                        </div>

                        <Divider className={classes.divider} />

                        <h3>{i18n.t('Peak and Off-Peak Tariffs')}</h3>

                        <div className="composition-container">
                            {!this.state.loadingTariff && (
                                <TariffCompositionsAdd
                                    tariffCompositions={this.state.tariffCompositions}
                                    changeTariffCompositions={this.changeTariffCompositions}
                                    currencySymbol={this.state.currencySymbol}
                                />
                            )}

                            {!!this.state.loadingTariff &&
                                <Loading />
                            }
                            <p className="composition-info">
                                {i18n.t('Overlapping periods may result into calculation errors. In the case where the overlapping is mathematically consistent, the rate with the lowest granularity defined, among the possible ones, will be used in the calculation.')}
                            </p>
                        </div>

                    </div>
                    <div className="actions-container">
                        <Button
                            action="back"
                            label={i18n.t("Cancel")}
                            click={this.props.handleDataLossModalDialogOpen}
                        />
                        <Button
                            disabled={this.state.loading}
                            label={this.MESSAGES[this.state.mode].submit}
                            type="submit"
                        />
                    </div>
                </form>
                <SystemSnackbar
                    open={showSnackBar}
                    message={snackbar.message}
                    onClose={this.closeSnackBar}
                    variant={snackbar.type}
                />
            </section>
        );
    }
}

export default withStyles(styles)(TariffAddEdit);
