import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import CloseIcon from 'material-ui-icons/Close';
import Modal from 'material-ui/Modal';

// import Button from 'material-ui/Button';
import Paper from 'material-ui/Paper';

// import { Text } from '../../elements/text';
import EventDetails from './event-details';
import EventsList from './events-list';

import './alert-card.css';

const styles = theme => ({
    root: {
        width: '100%',
        marginBottom: theme.spacing.unit * 3,
    },
    paper: {
        position: 'absolute',
        width: 600,
        minHeight: 500,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: 0,
        '&:focus': {
            outline: 'none'
        }
    },
    snackbarClose: {
        width: theme.spacing.unit * 4,
        height: theme.spacing.unit * 4,
    },
});

const modalStyle = {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '90%',
    overflowY: 'auto',
}

const closeButtonStyle = {
    cursor: 'pointer',
    padding: '10px',
    position: 'absolute',
    right: 0,
    zIndex: 1,
}

class EventsOverview extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            eventDetail: null,
            reloadEvents: false,
        };
    }

    componentDidUpdate() {
        if (this.state.reloadEvents)
            this.setState({ reloadEvents: false });
    }

    handleCloseModal = () => {
        this.setState({
            eventDetail: null,
            reloadEvents: true,
        })
    }

    handleEditClick = (e, event) => {
        e.stopPropagation();
        e.preventDefault();

        this.setState({ eventDetail: event });
    }

    render() {
        const { classes } = this.props;

        return (
            <Paper className={classes.root}>
                <EventsList
                    handleEditClick={this.handleEditClick}
                    reloadEvents={this.state.reloadEvents}
                />
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={!!this.state.eventDetail}
                    onClose={this.handleCloseModal}
                    disableBackdropClick={true}>

                    <div style={modalStyle} className={classes.paper}>
                        <CloseIcon onClick={this.handleCloseModal} style={closeButtonStyle} />

                        <EventDetails
                            handleCloseModal={this.handleCloseModal}
                            event={this.state.eventDetail}
                        />
                    </div>
                </Modal>
            </Paper>
        );
    }
}

EventsOverview.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EventsOverview);
