import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Cookies from 'universal-cookie';
import List, { ListItem, ListItemIcon, ListItemText } from 'material-ui/List';
import { withStyles } from 'material-ui/styles';
import Typography from 'material-ui/Typography';
import VizIcon from 'material-ui-icons/RemoveRedEye';
import DescriptionIcon from 'material-ui-icons/Description';
import WarningIcon from 'material-ui-icons/Warning';
import SettingsApplicationsIcon from 'material-ui-icons/SettingsApplications';
import HelpIcon from 'material-ui-icons/Help';
import SensorIcon from 'material-ui-icons/Memory';
import {NavLink, withRouter} from 'react-router-dom';
import Collapse from 'material-ui/transitions/Collapse';
import ExpandLess from 'material-ui-icons/ExpandLess';
import ExpandMore from 'material-ui-icons/ExpandMore';
import Popover from 'material-ui/Popover/Popover';
import LogoutIcon from 'material-ui-icons/ExitToApp';
import LogintIcon from 'material-ui-icons/Loop';
import HomeIcon from 'material-ui-icons/Home';

import logoPowered from '../../general/img/powered_voltaware.svg';
import logoIcon from '../../general/img/icon_voltaware.svg';
import { API } from '../../components/api';

// Translation
import i18n from '../../i18n';

let alertsTimeout;

const cookies = new Cookies();

const styles = theme => ({
    root: {
        width: '100%',
        height: '80%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    generalInfo: {
        display: 'flex',
        justifyContent: 'space-around',
        margin: '16px 0 2px 0'
    },
    generalInfoText: {
        fontFamily: 'robotobold',
        color: '#FFF',
        fontSize: '13px',
        padding: '0 10px',
    },
    menu: {
        flexGrow: 1,
    },
    selected: {
        backgroundColor: '#535663',
    },
    opened: {
        backgroundColor: '#242428',
    },
    listItem: {
        color: '#FFF',
        fontSize: '16px',
        width: '24px',
        height: '24px',
        position: 'relative',
    },
    listItemText: {
        color: '#FFF',
        fontSize: '16px',
        whiteSpace: 'nowrap'
    },
    disabled: {
        color: '#808080',
        fontSize: '16px',
        fontStyle: 'italic',
    },
    selectedItem: {
        color: '#FFF',
        fontSize: '16px',
        backgroundColor: '#444448',
    },
    nested: {
        paddingLeft: theme.spacing.unit * 10,
    },
    popover: {
        backgroundColor: '#333645',
    },
    logo: {
        marginBottom: '10px',
        alignSelf: 'center'
    },
    logoutLink: {
        backgroundColor: '#6ab42d',
        color: '#fff',
        display: 'block',
        lineHeight: '2px',
        padding: '10px 0 6px',
        textAlign: 'center',
    },
    logoutIcon: {
        height: 30,
        paddingBottom: 4,
        verticalAlign: 'middle',
        width: 30,
    },
    menuAlertsCount: {
        alignItems: 'center',
        background: '#D0021B',
        borderRadius: '50%',
        color: '#FFF',
        display: 'flex',
        fontSize: '11px',
        height: '15px',
        justifyContent: 'center',
        position: 'absolute',
        right: '-8px',
        textAlign: 'center',
        top: '-8px',
        width: '16px',
    },
    menuOpenAlertsCount: {
        alignItems: 'center',
        background: '#D0021B',
        borderRadius: '50%',
        color: '#FFF',
        display: 'flex',
        fontSize: '11px',
        height: '15px',
        justifyContent: 'center',
        position: 'absolute',
        right: '-18px',
        textAlign: 'center',
        top: '-4px',
        width: '16px',
    },
    openedAlert: {
        color: 'inherit',
        fontSize: 'inherit',
        position: 'relative',
        display: 'inline-block',
    }
});

const path = {
    home: '/home',
    consumption: '/analysis/consumption',
    power: '/analysis/power',
    powerPeak: '/analysis/power/peak',
    cost: '/analysis/cost',
    groupsConfig: '/config/groups',
    tariffsConfig: '/config/tariffs',
    userConfig: '/config/users',
    benchmarkConfig: '/config/benchmarking',
    tenantsConfig: '/config/clients',
    logout: '/logout',
    reports: '/reports',
    alertsEvents: '/alerts/events',
    alerts: '/alerts',
    resources: '/resources/status',
}

const popover = {
    anchorOrigin: {
        horizontal: 'right',
        vertical: 'top',
    },
    transformOrigin: {
        horizontal: 'left',
        vertical: 'top',
    },
}

class ExpandedMenu extends React.Component {
    constructor (props) {
        super(props);

        const isAnalysisSubitemSelected = props.location.pathname.includes(path.consumption) || props.location.pathname.includes(path.cost) || props.location.pathname.includes(path.powerPeak);
        const isConfigurationsSubitemSelected = props.location.pathname === path.groupsConfig || props.location.pathname === path.tariffsConfig || props.location.pathname === path.userConfig || props.location.pathname === path.tenantsConfig;

        this.state = {
            openAnalyses: (props.drawerOpened && isAnalysisSubitemSelected) ? true : false,
            openConfigurations: (props.drawerOpened && isConfigurationsSubitemSelected) ? true : false,
            openConfigPopover: false,
            openAnalysesPopover: false,
            anchorEl: null,
            alertsCount: 0,
        };
    }

    componentDidMount() {
        this.getAlertsCount()

        window.addEventListener('updateAlertEventsCount', this.getAlertsCount)
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.drawerOpened){
            this.setState({
                openAnalyses: false,
                openConfigurations: false,
            });
        }

        if (nextProps.drawerOpened){
            this.setState({
                openConfigPopover: false,
                openAnalysesPopover: false,
            });
        }
    }

    handleAnalysesClick = () => {
        if (this.props.drawerOpened){
            this.setState({
                openConfigurations: false,
                openAnalyses: !this.state.openAnalyses
            });
        }
    }

    handleConfigurationsClick = () => {
        if (this.props.drawerOpened){
            this.setState({
                openAnalyses: false,
                openConfigurations: !this.state.openConfigurations
            });
        }
    };

    handleSubmenuClick = (e, path) => {
        if (this.props.location.pathname.indexOf(path) === 0)
            e.preventDefault()
    }

    handleConfigPopoverClick = (event) => {
        this.setState({
            openConfigPopover: true,
            anchorEl: event.currentTarget,
        });
    };

    handleAnalysesPopoverClick = (event) => {
        this.setState({
            openAnalysesPopover: true,
            anchorEl: event.currentTarget,
        });
    };

    handleAnalysesPopoverClose = () => {
        this.setState({
            openAnalysesPopover: false,
        });
    }

    handleConfigPopoverClose = () => {
        this.setState({
            openConfigPopover: false,
        });
    }

    handleLogout = () => {
        this.props.history.push('/logout');
    }

    logAsRoot = async () => {
        this.setState({ loading: true });
        const tenantId = parseFloat(localStorage.getItem('rootTenantId'));
        const email = localStorage.getItem('userEmail');

        try {
            const { data } = await API.ROOT.TENANTS.LOGIN({ tenantId, email});

            localStorage.setItem('rootTenantId', null);
            localStorage.setItem('authority', data.user.authority);
            localStorage.setItem('isRoot', true);
            cookies.set('tenantName', data.tenant.name, { path: '/' });
            localStorage.setItem('tenantColors', JSON.stringify(data.tenant.colors));
            localStorage.setItem('tenantLogo', data.tenant.logo);
            localStorage.setItem('tenantShowPoweredByVoltaware', data.tenant.showPoweredByVoltaware);
            window.location = '/config/clients';
        } catch (error) {
            console.error(error);
        }
        this.setState({ loading: false })
    }

    getAlertsCount = () => {
        clearTimeout(alertsTimeout)

        API.ALERTS.EVENTS.UNREAD().then(({ data }) => {
            if (data && data.unread) {
                this.setState({
                    alertsCount: data.unread
                })
            }

            alertsTimeout = setTimeout(this.getAlertsCount, 60000)
        }).catch(error => {
            console.error(error)
            // alertsTimeout = setTimeout(this.getAlertsCount, 100)
        })
    }

    renderAnalysesSubitems = (classes, popover) => {
        const { location } = this.props;

        //TODO create items with a array.map(), generic structures!
        const listClassName = (popover) ? classes.popover : ((this.state.openAnalyses) ? classes.opened : null)
        const listItemClassName = (!popover) ? classes.nested : null;
        const consumptionClassName = (location.pathname.indexOf(path.consumption) === 0) ? classNames(listItemClassName, classes.selectedItem) : listItemClassName;
        const costClassName = (location.pathname.indexOf(path.cost) === 0) ? classNames(listItemClassName, classes.selectedItem) : listItemClassName;
        const powerPeakClassName = (location.pathname.indexOf(path.powerPeak) === 0) ? classNames(listItemClassName, classes.selectedItem) : listItemClassName;

        return (
            <List component="div" disablePadding
                className={listClassName}>
                <NavLink exact={true} to={path.consumption} className={classes.listItem} onClick={(e) => this.handleSubmenuClick(e, path.consumption)}>
                    <ListItem button className={consumptionClassName}>{i18n.t('Consumption')}</ListItem>
                </NavLink>
                <NavLink exact={true} to={path.powerPeak} className={classes.listItem} onClick={(e) => this.handleSubmenuClick(e, path.powerPeak)}>
                    <ListItem button className={powerPeakClassName}>{i18n.t('Power Peak')}</ListItem>
                </NavLink>
                {/* <ListItem button className={listItemClassName}>
                    <span className={classes.disabled}>{i18n.t('Power')}</span>
                </ListItem> */}
                <NavLink exact={true} to={path.cost} className={classes.listItem} onClick={(e) => this.handleSubmenuClick(e, path.cost)}>
                    <ListItem button className={costClassName}>{i18n.t('Cost')}</ListItem>
                </NavLink>
            </List>
        );
    }

    renderConfigSubitems = (classes, popover) => {
        const { location } = this.props;

        //TODO create items with a array.map(), generic structures!
        const listClassName = (popover) ? classes.popover : ((this.state.openConfigurations) ? classes.opened : null)
        const listItemClassName = (!popover) ? classes.nested : null;
        const groupClassName = (location.pathname === path.groupsConfig) ? classNames(listItemClassName, classes.selectedItem) : listItemClassName;
        const tariffClassName = (location.pathname === path.tariffsConfig) ? classNames(listItemClassName, classes.selectedItem) : listItemClassName;
        const benchmarkClassName = (location.pathname === path.benchmarkConfig) ? classNames(listItemClassName, classes.selectedItem) : listItemClassName;
        const userClassName = (location.pathname === path.userConfig) ? classNames(listItemClassName, classes.selectedItem) : listItemClassName;
        const tenantsClassName = (location.pathname === path.tenantsConfig) ? classNames(listItemClassName, classes.selectedItem) : listItemClassName;

        return (
            <List component="div" disablePadding
                className={listClassName}>
                <NavLink exact={true} to={path.groupsConfig} className={classes.listItem} onClick={(e) => this.handleSubmenuClick(e, path.groupsConfig)}>
                    <ListItem button className={groupClassName}>{i18n.t('Groups')}</ListItem>
                </NavLink>
                <NavLink exact={true} to={path.tariffsConfig} className={classes.listItem} onClick={(e) => this.handleSubmenuClick(e, path.tariffsConfig)}>
                    <ListItem button className={tariffClassName}>{i18n.t('Tariffs')}</ListItem>
                </NavLink>
                <NavLink exact={true} to={path.userConfig} className={classes.listItem} onClick={(e) => this.handleSubmenuClick(e, path.userConfig)}>
                    <ListItem button className={userClassName}>{i18n.t('Users')}</ListItem>
                </NavLink>
                {localStorage.getItem('isRoot') === 'true' && (
                    <NavLink exact={true} to={path.tenantsConfig} className={classes.listItem} onClick={(e) => this.handleSubmenuClick(e, path.tenantsConfig)}>
                        <ListItem button className={tenantsClassName}>{i18n.t('Tenants')}</ListItem>
                    </NavLink>
                )}
                <NavLink exact={true} to={path.benchmarkConfig} className={classes.listItem} onClick={(e) => this.handleSubmenuClick(e, path.benchmarkConfig)}>
                    <ListItem button className={benchmarkClassName}>{i18n.t('Benchmarking')}</ListItem>
                </NavLink>
            </List>
        );
    }

    render() {
        const { classes, location } = this.props;
        const { alertsCount } = this.state;
        const primaryColor = JSON.parse(localStorage.getItem('tenantColors')) ? JSON.parse(localStorage.getItem('tenantColors')).primary : null;
        const showPoweredByVolta = JSON.parse(localStorage.getItem('tenantShowPoweredByVoltaware'));

        return (
            <div className={classes.root}>

                {/* MENU */}

                <List component="nav" className={classes.menu}>

                    {/* HOME MENU ITEM */}

                    <NavLink exact={true} to={path.home}>
                        <ListItem
                            button
                            className={path.home === location.pathname ? classes.selected : ''}>
                            <ListItemIcon className={classes.listItem}>
                                <HomeIcon />
                            </ListItemIcon>
                            <ListItemText
                                disableTypography
                                primary={(
                                    <Typography type="body2" className={classes.listItem}>
                                        {i18n.t('Home')}
                                    </Typography>
                                )} />
                        </ListItem>
                    </NavLink>

                    {/* ANALYSES MENU ITEM */}

                    <ListItem button onClick={(this.props.drawerOpened) ? this.handleAnalysesClick : this.handleAnalysesPopoverClick}
                        className={(this.state.openAnalyses) ? classes.opened : null}>

                        <ListItemIcon className={classes.listItem}>
                            <VizIcon />
                        </ListItemIcon>

                        <ListItemText
                            inset
                            disableTypography
                            primary={(
                                <Typography type="body2" className={classes.listItem}>
                                    {i18n.t('Analysis')}
                                </Typography>
                            )}
                        />
                        {this.state.openAnalyses ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>

                    <Collapse in={this.state.openAnalyses} timeout="auto" unmountOnExit>

                        {this.renderAnalysesSubitems(classes, false)}

                    </Collapse>

                    <Popover
                        open={this.state.openAnalysesPopover}
                        anchorEl={this.state.anchorEl}
                        anchorOrigin={popover.anchorOrigin}
                        transformOrigin={popover.transformOrigin}
                        onClose={this.handleAnalysesPopoverClose}>

                        {this.renderAnalysesSubitems(classes, true)}

                    </Popover>

                    {/* RESOURCES MENU ITEM */}

                    <NavLink exact={true} to={path.resources}>
                        <ListItem
                            button
                            className={path.resources === location.pathname ? classes.selected : ''}>
                            <ListItemIcon className={classes.listItem}>
                                <SensorIcon />
                            </ListItemIcon>
                            <ListItemText
                                disableTypography
                                primary={(
                                    <Typography type="body2" className={classes.listItem}>
                                        {i18n.t('Resources')}
                                    </Typography>
                                )} />
                        </ListItem>
                    </NavLink>

                    {/* REPORTS MENU ITEM */}

                    <NavLink exact={true} to={path.reports}>
                        <ListItem
                            button
                            className={path.reports === location.pathname ? classes.selected : ''}>
                            <ListItemIcon className={classes.listItem}>
                                <DescriptionIcon />
                            </ListItemIcon>
                            <ListItemText
                                disableTypography
                                primary={(
                                    <Typography type="body2" className={classes.listItem}>
                                        {i18n.t('Reports')}
                                    </Typography>
                                )} />
                        </ListItem>
                    </NavLink>

                    {/* ALERTS MENU ITEM */}
                    <NavLink exact={true} to={path.alerts}>
                        <ListItem
                            button
                            className={(path.alerts === location.pathname || path.alertsEvents === location.pathname) ? classes.selected : ''}>
                            <ListItemIcon className={classes.listItem}>
                                <div>
                                    <WarningIcon />
                                    {!!alertsCount && !this.props.drawerOpened && (
                                        <p className={classes.menuAlertsCount}>
                                            {alertsCount > 99 ? '+99' : alertsCount}
                                        </p>
                                    )}
                                </div>
                            </ListItemIcon>
                            <ListItemText
                                disableTypography
                                primary={
                                    <Typography type="body2" className={classes.listItem}>
                                        <span className={classes.openedAlert}>
                                            {i18n.t('Alerts')}
                                            {!!alertsCount && this.props.drawerOpened && (
                                                <span className={classes.menuOpenAlertsCount}>
                                                    {alertsCount > 99 ? '+99' : alertsCount}
                                                </span>
                                            )}
                                        </span>
                                    </Typography>
                                } />
                        </ListItem>
                    </NavLink>

                    {/* CONFIGURATIONS MENU ITEM */}

                    <ListItem button onClick={(this.props.drawerOpened) ? this.handleConfigurationsClick : this.handleConfigPopoverClick}
                        className={(this.state.openConfigurations) ? classes.opened : null}>

                        <ListItemIcon className={classes.listItem}>
                            <SettingsApplicationsIcon />
                        </ListItemIcon>
                        <ListItemText
                            disableTypography
                            primary={<Typography type="body2" className={classes.listItem}>{i18n.t('Configuration')}</Typography>} />
                        {this.state.openConfigurations ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={this.state.openConfigurations} timeout="auto" unmountOnExit>

                        {this.renderConfigSubitems(classes, false)}

                    </Collapse>

                    <Popover
                        open={this.state.openConfigPopover}
                        anchorEl={this.state.anchorEl}
                        anchorOrigin={popover.anchorOrigin}
                        transformOrigin={popover.transformOrigin}
                        onClose={this.handleConfigPopoverClose}>

                        {this.renderConfigSubitems(classes, true)}

                    </Popover>

                    {/* TUTORIALS MENU ITEM */}

                    <ListItem button>
                        <ListItemIcon className={classes.listItem}>
                            <HelpIcon />
                        </ListItemIcon>
                        <ListItemText
                            disableTypography
                            primary={<Typography type="body2" className={classes.listItem}><span className={classes.disabled}>{i18n.t('Tutorials')}</span></Typography>} />
                    </ListItem>

                </List>
                {showPoweredByVolta && (
                    <div className={`poweredByVoltaLogo ${classes.logo}`}>
                        {(this.props.drawerOpened) ?
                            <img className="poweredByVoltaLogoImg" src={logoPowered} alt={i18n.t("voltaware building electricity monitoring logo")} /> :
                            <img className="poweredByVoltaLogoIcon" src={logoIcon} alt={i18n.t("voltaware building electricity monitoring logo")} />}
                    </div>
                )}

                <List
                    className={`logoutNav ${classes.menu}`}
                    component="nav"
                    disablePadding
                    style={{backgroundColor: primaryColor ? primaryColor : '#6ab42d', flexGrow: 0}}
                >
                    {localStorage.getItem('rootTenantId') && localStorage.getItem('rootTenantId') !== 'null' && (
                        <ListItem button disabled={this.state.loading} onClick={this.logAsRoot}>
                            <ListItemIcon className={classes.listItem}><LogintIcon /></ListItemIcon>
                            <ListItemText disableTypography
                                inset
                                primary={
                                    <Typography type="body2" className={classes.listItemText}>{i18n.t('Login as root')}</Typography>
                                } />
                        </ListItem>
                    )}
                    <ListItem button onClick={this.handleLogout}>
                        <ListItemIcon className={classes.listItem}><LogoutIcon /></ListItemIcon>
                        <ListItemText disableTypography
                            inset
                            primary={
                                <Typography type="body2" className={classes.listItem}>{i18n.t('Logout')}</Typography>
                            } />
                    </ListItem>
                </List>
            </div>
        );
    }
}

ExpandedMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles, { withTheme: true })(ExpandedMenu));
