import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import Button from 'material-ui/Button';
import Card, { CardHeader, CardContent } from 'material-ui/Card';
import Dialog, {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from 'material-ui/Dialog';
import Typography from 'material-ui/Typography';
import classNames from 'classnames'
import IconButton from 'material-ui/IconButton';
import DeleteIcon from 'material-ui-icons/Delete';
import EditIcon from 'material-ui-icons/ModeEdit';
import MainSensorIcon from 'material-ui-icons/Memory';
import {scaleOrdinal} from 'd3-scale';
import {Loading} from '../../elements/loading';

import Subgroup from './group-card-subgroup';

import i18n from '../../i18n';

const styles = theme => ({
    card: {
        width: '320px',
        marginBottom: '20px'
    },
    cardExpanded: {
        zIndex: 2,
    },
    cardButton: {
        width: '30px',
        height: '30px',
    },
    headerRoot: {
        paddingLeft: '10px',
    },
    headerGreen: {
        backgroundColor: '#569124'
    },
    headerYellow: {
        backgroundColor: '#F6A623'
    },
    headerBlue: {
        backgroundColor: '#4A90E2'
    },
    headerRed: {
        backgroundColor: '#D0021B'
    },
    headerTitle: {
        color: '#FFF', fontSize: '1.2rem'
    },
    headerSubheader: {
        color: '#FFF'
    },
    deleteConfirm: {
        color: '#6ab42d',
    },
    deleteCancel: {
        color: '#c62828',
    },
    mainSensor: {
        display: 'flex', alignItems: 'center'
    },
    mainSensorIcon: {
        marginRight: '6px'
    },
    subgroupsWrapper: {
        padding: '10px 0'
    },
    typographyTitle: {
        fontSize: '1rem',
        fontWeight: 600
    },
});

const color = scaleOrdinal(['headerGreen', 'headerYellow','headerBlue','headerRed']);

class GroupCard extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            dialogOpen: false,
            expandedSubgroups: 0,
        }
    }

    renderMainSensors = (mainSensors) => {
        const { classes } = this.props;

        let sensorsLabels = '';
        if (mainSensors && mainSensors.length > 0){
            sensorsLabels = mainSensors.map(main => {
                return main.id + "-" + main.channel;
            }).join(' ');
        }

        return <div className={classes.mainSensor}><MainSensorIcon className={classes.mainSensorIcon} /> {sensorsLabels}</div>;
    }

    setExpanded = (expanded) => {
        setTimeout(this.props.masonryLayout, 300);
        const expandedSubgroups = this.state.expandedSubgroups;
        this.setState({
            expandedSubgroups: (expanded) ? expandedSubgroups+1 : expandedSubgroups-1
        });
    }

    handleGroupDeleteClick = () => {
        this.setState({dialogOpen: true});
    }

    handleDialogClose = (flag) => {
        flag === true ?
            this.props.handleGroupDelete(this.props.data) :
            this.setState({dialogOpen: false});
    }

    render() {
        const { classes, data, seq } = this.props;

        const cardClass = this.state.expandedSubgroups > 0 ? classNames(classes.card, classes.cardExpanded) : classes.card;
        const colorClass = color(seq);

        return (
            <Card className={cardClass}>
                <CardHeader
                    classes={{
                        root: classNames(classes.headerRoot, classes[colorClass], 'header'),
                        subheader: classes.headerSubheader,
                        title: `${classes.headerTitle} headerTitle`,
                    }}
                    action={localStorage.getItem('authority') === 'ADMIN' && (
                        <div>
                            <IconButton aria-label="Edit"
                                color="inherit"
                                key="edit"
                                className={`${classes.cardButton} edit-button`}
                                onClick={() => this.props.openEditGroup(data.id)}>
                                <EditIcon style={{color: '#fff'}} />
                            </IconButton>
                            <IconButton aria-label="Delete"
                                color="inherit"
                                key="delete"
                                className={`${classes.cardButton} delete-button`}
                                onClick={this.handleGroupDeleteClick}>
                                <DeleteIcon style={{color: '#fff'}} />
                            </IconButton>
                        </div>
                    )}
                    title={data.name}
                    subheader={this.renderMainSensors(data.sensors)}
                />
                <div className={classes.subgroupsWrapper}>
                    {(data.subGroups && data.subGroups.length > 0) ?
                        data.subGroups.map(subgroup => {
                            return <Subgroup key={subgroup.id} data={subgroup} setCardExpanded={this.setExpanded} />
                        }) :
                        <CardContent>
                            <Typography variant="title" color="textSecondary" classes={{title: classes.typographyTitle}}>
                                {i18n.t('This groups has no subgroups.')}
                            </Typography>
                        </CardContent>
                    }
                </div>

                <Dialog aria-labelledby="dialog-text"
                    fullWidth={true}
                    maxWidth="xs"
                    open={this.state.dialogOpen}
                    onClose={this.handleDialogClose}>
                    {!this.props.groupDelete.loading ?
                        <DialogTitle>
                            {i18n.t('Are you sure?')}
                        </DialogTitle> : null}
                    <DialogContent>
                        <DialogContentText id="dialog-text">
                            {this.props.groupDelete.loading ?
                                i18n.t(`We are deleting the group {{groupName}}, please wait.`, {groupName: this.props.groupDelete.data.name}) :
                                i18n.t(`The Group and all related Subgroups will be permanently deleted.`)
                            }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {this.props.groupDelete.loading ?
                            <div style={{minWidth: 36, width: 36}}><Loading /></div> :
                            <div>
                                <Button id="confirm-leave-dialog" classes={{label: classes.deleteConfirm}}
                                    onClick={() => this.handleDialogClose(true)}>
                                    {i18n.t('Yes')}
                                </Button>
                                <Button id="cancel-leave-dialog" classes={{label: classes.deleteCancel}} onClick={this.handleDialogClose}>
                                    {i18n.t('Cancel')}
                                </Button>
                            </div>}
                    </DialogActions>
                </Dialog>
            </Card>
        );
    }
}

GroupCard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GroupCard);
