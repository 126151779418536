import React from 'react';

import './button.css';


class Button extends React.Component {
    render () {
        let loadingClass = '';
        if (
            (this.props.loading !== undefined && this.props.loading) ||
            (this.props.loading === undefined && this.props.disabled)
        ) loadingClass = 'loading ';

        let classes = '';
        if (this.props.className !== undefined)
            classes = this.props.className + ' ';

        if (this.props.nonLoadingDisabled)
            classes += 'non-loading-disabled ';

        let props = {
            className: loadingClass + classes + (this.props.action || ''),
            disabled: this.props.disabled || this.props.nonLoadingDisabled,
            type: this.props.type || 'button',
        };

        return this.props.click ?
            <button {...props} onClick={this.props.click}>{this.props.label}</button> :
            <button {...props}>{this.props.label}</button>;
    }
}

export {Button};
