import React from 'react';

import './text.css';


const Text = ({ className = '', bold, highlight, warning, inline, black, medium, text, color }) => {
    if (bold) className += ' bolder';
    if (highlight) className += ' highlight';
    if (warning) className += ' warning';
    if (inline) className += ' inline';
    if (black) className += ' black';
    if (medium) className += ' medium';

    return <p style={{ color }} className={className}>{text}</p>;
}

export {Text};
