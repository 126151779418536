import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from 'material-ui/Snackbar';
import IconButton from 'material-ui/IconButton';
import {Fade, Slide} from 'material-ui';

// Translation
import i18n from '../../i18n';

const styles = theme => ({
    closeButton: {
        width: theme.spacing.unit * 4,
        height: theme.spacing.unit * 4,
    },
    info: {
        background: "#FFF",
        flexWrap: 'unset',
    },
    info2: {
        background: "#C5D8FF",
        flexWrap: 'unset',
    },
    success: {
        background: "#C5FFB7",
        flexWrap: 'unset',
    },
    warning: {
        background: "#FFEFB6",
        flexWrap: 'unset',
    },
    error: {
        background: "#FFCCCC",
        flexWrap: 'unset',
    },
    message: {
        color: '#4A4A4A',
        fontSize: '14px',
    },
});


class SystemSnackbar extends React.Component {

    constructor (props) {
        super(props);

        this.state = {
            open: false,
            background: props.classes.info,
            autoHideDuration: null,
            variant: 'info',
            message: '',
        };
    }

    componentWillReceiveProps(nextProps){
        let {message, open, classes, variant} = nextProps;

        let background, autoHideDuration;
        switch (variant) {
            case 'info':
                background = classes.info;
                autoHideDuration = 5000;
                break;
            case 'info2':
                background = classes.info2;
                autoHideDuration = 5000;
                break;
            case 'success':
                background = classes.success;
                autoHideDuration = 5000;
                break;
            case 'warning':
                background = classes.warning;
                autoHideDuration = null;
                break;
            case 'error':
                background = classes.error;
                autoHideDuration = 10000;
                message = message + ' ' + i18n.t("Please try again");
                break;
            default:
                background = classes.info;
        }

        this.setState({message, open, background, variant})

        if (!this.props.noAutoHide)
            this.setState({autoHideDuration})
    }

    handleClose = (e, reason) => {
        if (reason !== 'clickaway'){
            this.setState({
                open: false,
            })
        }

        if (this.props.onClose && typeof this.props.onClose === 'function') {
            this.props.onClose()
        }
    }

    render() {
        const { classes, useFadeTransition } = this.props;

        return (
            <Snackbar action={[
                <IconButton aria-label="Close"
                    className={classes.closeButton}
                    color="inherit"
                    key="close"
                    onClick={this.handleClose}>
                    <CloseIcon />
                </IconButton>,]}
                anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
                autoHideDuration={this.state.autoHideDuration}
                onClose={this.handleClose}
                open={this.state.open}
                message={this.state.message}
                TransitionComponent={useFadeTransition ? Fade : Slide}
                ContentProps={{
                    classes: {
                        root: this.state.background,
                        message: `${classes.message} system-snackbar`,
                    }
                }}
            />
        );
    }
}

SystemSnackbar.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['info', 'info2', 'success', 'warning', 'error']).isRequired,
  noAutohide: PropTypes.bool,
  useFadeTransition: PropTypes.bool,
};

export default withStyles(styles)(SystemSnackbar);
