import React from 'react';
import propTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import ReactTooltip from 'react-tooltip';

import Card, { CardContent } from 'material-ui/Card';
import Up from '@material-ui/icons/ArrowUpward';
import Down from '@material-ui/icons/ArrowDownward';
import { Text } from '../../elements/text';
import {Loading} from '../../elements/loading';


// Translation
import i18n from '../../i18n';

const styles = theme => ({
    card: {
        flex: 1,
        marginRight: '16px',
        '&:last-child': {
            marginRight: '0px'
        },
        overflow: 'visible',
    },
    contentRoot: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0',
        '&:last-child': {
            padding: '10px'
        }
    },
    totalName: {
        flex: 1,
        marginBottom: '16px'
    },
    totalValue: {
        fontSize: 'calc(12px + 1.5vw)',
        margin: '0px',
    },
    totalPeriod: {
        fontSize: '20px',
        marginBottom: 0
    },
    valueContent: {
        display: 'flex',
        alignItems: 'flex-end'
    },
    totalValueUnit: {
        fontSize: 'calc(10px + 0.5vw)',
        marginLeft: '4px',
    },
    totalPercentageUp: {
        display: 'flex',
        marginBottom: '0px',
        marginLeft: '4px',
        color: '#A73F49',
        '& svg': {
            fill: '#A73F49'
        }
    },
    totalPercentageDown: {
        display: 'flex',
        marginBottom: '0px',
        marginLeft: '4px',
        color: '#69AA78',
        '& svg': {
            fill: '#69AA78'
        }
    },
    totalPercentageNotApplicable: {
        display: 'flex',
        marginBottom: '0px',
        marginLeft: '10px',
        color: '#AEAEAE',
    },
    totalValuePercentage: {
        fontSize: '18px',
        marginBottom: '9px',
        color: 'inherit'
    },
    totalLastPeriod: {
        marginTop: '10px',
    },
});

const periods = () => ({
    DAY: i18n.t('Today'),
    WEEK: i18n.t('This Week'),
    MONTH: i18n.t('This Month'),
});

const lastPeriods = () => ({
    DAY: i18n.t('Last Day'),
    WEEK: i18n.t('Last Week'),
    MONTH: i18n.t('Last Month'),
});

const percentageChangeTooltip = i18n.t("Percentage change in relation to last period");
const notApplicableTooltip = i18n.t("Percentage change not applicable, as previous period has no data");

const TotalCard = ({ title, period, classes, diff, value, loading, valueFormat, percentageFormat, type, lastPeriod }) => (
    <Card className={classes.card}>
        <CardContent className={`total-card-${period} ${classes.contentRoot}`}>
            <Text className={`total-title ${classes.totalName}`} bold medium text={title} />
            <Text className={`total-period ${classes.totalPeriod}`} text={periods()[period]} />
            {loading
                ? <Loading />
                : (
                    <div className={classes.valueContent}>
                        <Text className={`total-value ${classes.totalValue}`} bold text={valueFormat(value || 0)} />
                        {type === 'consumption' && (
                            <Text className={`total-value-unit ${classes.totalValueUnit}`} bold text={i18n.t('kWh')} />
                        )}
                    </div>
                )
            }

            {lastPeriod && lastPeriod.loading ? <Loading /> : null}

            {lastPeriod && lastPeriod.loading === false && typeof lastPeriod.currentTotal === 'number' ?
                <div className={`last-period ${classes.totalLastPeriod}`}>
                    <Text className={`total-period ${classes.totalPeriod}`} text={lastPeriods()[period]} />
                    <div className={classes.valueContent}>
                        <Text className={`total-value ${classes.totalValue}`} bold text={valueFormat(lastPeriod.currentTotal || 0)} />
                        {type === 'consumption' && (
                            <Text className={`total-value-unit ${classes.totalValueUnit}`} bold text={i18n.t('kWh')} />
                        )}
                        <div
                            className={`total-percentage ` + (lastPeriod.diff === null ? `not-applicable ${classes.totalPercentageNotApplicable}` : lastPeriod.diff > 0 ? classes.totalPercentageUp : classes.totalPercentageDown)}
                            data-tip={lastPeriod.diff === null ? notApplicableTooltip : percentageChangeTooltip}>

                            {lastPeriod.diff !== null ? (lastPeriod.diff > 0 ? <Up className="total-above" /> : <Down className="total-below" />) : ''}
                            <Text
                                className={`total-relative-value ${classes.totalValuePercentage}`}
                                bold
                                text={lastPeriod.diff === null ? i18n.t('N/A'): percentageFormat(lastPeriod.diff || 0)}
                            />
                            <ReactTooltip />
                        </div>
                    </div>
                </div>
            : null}
        </CardContent>
    </Card>
);

TotalCard.propTypes = {
    title: propTypes.string.isRequired,
    period: propTypes.oneOf(['DAY', 'WEEK', 'MONTH']).isRequired,
    diff: propTypes.number,
    value: propTypes.number,
    loading: propTypes.bool,
    valueFormat: propTypes.func.isRequired,
    type: propTypes.oneOf(['consumption', 'cost']).isRequired,
    percentageFormat: propTypes.func.isRequired
};

export default withStyles(styles)(TotalCard);
