import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
// import Button from 'material-ui/Button';
import Card, { CardHeader, CardContent } from 'material-ui/Card';
// import Dialog, {
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
// } from 'material-ui/Dialog';
import classNames from 'classnames'
import { Text } from '../../elements/text';
// import IconButton from 'material-ui/IconButton';
// import {Loading} from '../../elements/loading';
import TimelineIcon from 'material-ui-icons/Timeline';

import i18n from '../../i18n';

const styles = theme => ({
    card: {
        width: '320px',
        marginBottom: '20px'
    },
    cardExpanded: {
        zIndex: 2,
    },
    cardButton: {
        width: '30px',
        height: '30px',
    },
    headerRoot: {
        paddingLeft: '10px',
    },
    headerIcon: {
        color: '#FFF',
        height: '45px',
        width: '45px',
    },
    headerBlue: {
        backgroundColor: '#4A90E2'
    },
    headerTitle: {
        color: '#FFF', fontSize: '1.2rem'
    },
    headerSubheader: {
        color: '#FFF'
    },
    deleteConfirm: {
        color: '#6ab42d',
    },
    deleteCancel: {
        color: '#c62828',
    },
    typographyTitle: {
        fontSize: '1rem',
        fontWeight: 600
    },
    contentRoot: {
        minHeight: '80px',
        padding: '10px 15px',
        '&:last-child': {
            paddingBottom: '10px',
        },
    },
    typeLabel:{
        marginBottom: '16px'
    },
});


class BenchmarkCard extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            dialogOpen: false,
        }
    }

    render() {
        const { classes, data } = this.props;

        return (
            <Card className={classes.card}>
                <CardHeader
                    classes={{
                        root: classNames(classes.headerRoot, classes.headerBlue),
                        subheader: classes.headerSubheader,
                        title: classes.headerTitle,
                    }}
                    avatar={<TimelineIcon className={classes.headerIcon} />}
                    // action={
                        // <div>
                        //     <IconButton aria-label="Edit"
                        //         color="inherit"
                        //         key="edit"
                        //         className={classes.cardButton}
                        //         onClick={() => this.props.openEditGroup(data.id)}>
                        //         <EditIcon style={{color: '#fff'}} />
                        //     </IconButton>
                        //     <IconButton aria-label="Delete"
                        //         color="inherit"
                        //         key="delete"
                        //         className={classes.cardButton}
                        //         onClick={this.handleGroupDeleteClick}>
                        //         <DeleteIcon style={{color: '#fff'}} />
                        //     </IconButton>
                        // </div>
                    // }
                    title={data.name}
                    subheader={data.date}
                />
                <CardContent classes={{root: classes.contentRoot}}>
                    <div>
                        <div className={classes.typeLabel}>
                            <Text bold text={data.typeLabel} />
                        </div>
                        {data.type === 'absolute' ?
                            <div>
                                <div>
                                    <Text inline text={i18n.t('Level:')} />
                                    <Text inline bold text={data.consumption + ' ' + i18n.t('kWh')} />
                                </div>
                                <div>
                                    <Text inline text={i18n.t('Periodicity:')} />
                                    <Text inline bold text={data.periodicity} />
                                </div>
                            </div>
                        : null}
                        {data.type === 'relative' ?
                            <div>
                                <Text inline text={i18n.t('Average of:')} />
                                <Text inline bold text={data.referenceTime} />
                            </div>
                        : null}
                    </div>
                </CardContent>
            </Card>
        );
    }
}

BenchmarkCard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BenchmarkCard);
