import React, { PureComponent } from 'react'
import {Input} from '../../elements/forms/input';
import {InputTimeSelect} from '../../elements/forms/input-time-select';
import Select from 'react-select';
import DeleteIcon from 'material-ui-icons/Delete';

import i18n from '../../i18n';

import './tariff-composition.css'

class TariffComposition extends PureComponent{

    changeWeekday = (weekday) => {
        this.props.changeWeekday(weekday, this.props.seq);
    }

    changeStartTime = (startTime) => {
        this.props.changeStartTime(startTime, this.props.seq);
    }

    changeEndTime = (endTime) => {
        this.props.changeEndTime(endTime, this.props.seq);
    }

    changeRate = (event) => {
        this.props.changeRate(event.target.value, this.props.seq);
    }

    changeLimit = (limit) => {
        this.props.changeLimit(limit, this.props.seq);
    }

    changeLimitValue = (event) => {
        this.props.changeLimitValue(event.target.value, this.props.seq);
    }

    changeStandingCharge = (event) => {
        this.props.changeStandingCharge(event.target.value, this.props.seq);
    }

    remove = () => {
        this.props.removeTariffComposition(this.props.seq);
    }

    render () {
        return (
            <tr>
                <td>
                    <Select
                        name="weekday"
                        className={this.props.fieldsWithError.indexOf("weekday") >= 0 ? 'weekday has-error' : 'weekday'}
                        value={this.props.weekday}
                        onChange={this.changeWeekday}
                        valueKey="value"
                        labelKey="label"
                        options={this.props.weekdays}
                        disabled={!!this.props.limit || !!this.props.limitValue}
                        placeholder={i18n.t("Select weekday")}
                        noResultsText={i18n.t('No results found')}
                    />
                </td>
                <td>
                    <InputTimeSelect
                        name="startTime"
                        type="startTime"
                        hasError={this.props.fieldsWithError.indexOf("startTime") >= 0}
                        disabled={!!this.props.limit || !!this.props.limitValue}
                        time={this.props.startTime}
                        change={this.changeStartTime}
                    />
                </td>
                <td>
                    <InputTimeSelect
                        name="endTime"
                        type="endTime"
                        hasError={this.props.fieldsWithError.indexOf("endTime") >= 0}
                        time={this.props.endTime}
                        disabled={!!this.props.limit || !!this.props.limitValue}
                        change={this.changeEndTime}
                        minHour={this.props.startTime ? this.props.startTime.value : null}
                    />
                </td>
                <td>
                    <Input change={this.changeRate}
                        hasError={this.props.fieldsWithError.indexOf('rate') >= 0 ? i18n.t('Is required') : false}
                        value={this.props.rate}
                        type="number"
                        min="0"
                        step="0.00001"
                        name="rate"
                        placeholder={i18n.t("Add rate")} />
                </td>
                <td>
                    <Select
                        name="limit"
                        className={this.props.fieldsWithError.indexOf("limit") >= 0 ? 'has-error' : null}
                        value={this.props.limit}
                        onChange={this.changeLimit}
                        valueKey="value"
                        labelKey="label"
                        disabled={!!this.props.weekday || !!this.props.startTime || !!this.props.endTime}
                        options={this.props.limits}
                        placeholder={i18n.t("Select limit")}
                        noResultsText={i18n.t('No results found')}
                    />
                </td>
                <td>
                    <Input change={this.changeLimitValue}
                        hasError={this.props.fieldsWithError.indexOf('limitValue') >= 0}
                        value={this.props.limitValue}
                        type="number"
                        min="0"
                        step="0.01"
                        name="limitValue"
                        disabled={!!this.props.weekday || !!this.props.startTime || !!this.props.endTime}
                        placeholder={i18n.t("Add limit value")} />
                </td>
                <td>
                    <Input change={this.changeStandingCharge}
                        value={this.props.standingCharge}
                        type="number"
                        min="0"
                        step="0.00001"
                        name="standingCharge"
                        placeholder={i18n.t("Add standing charge")} />
                </td>
                <td>
                    {!this.props.isLast && (
                        <DeleteIcon className="tariff-composition-delete" onClick={this.remove} />
                    )}
                </td>
            </tr>
        )
    }
}

export {TariffComposition}
