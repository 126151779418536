import React from 'react';

import './error-message.css';


class ErrorMessage extends React.Component {
    render () {
        let className = 'error-message';
        if (this.props.align) className += ` ${this.props.align}`;
        return <p className={className}>{this.props.message}</p>;
    }
}

export {ErrorMessage};
