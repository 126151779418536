
import React from 'react';
import Masonry from 'react-masonry-component';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
// import AddIcon from 'material-ui-icons/AddCircleOutline';
import Typography from 'material-ui/Typography';

import SystemSnackbar from '../../elements/material-ui/SystemSnackbar'
import {Loading} from '../../elements/loading';
import BenchmarkCard from './benchmark-card';

import i18n from '../../i18n';
import moment from 'moment';

const styles = theme => ({
    typographyTitle: {
        fontSize: '1rem',
        fontWeight: 600
    },
    paper: {
        position: 'absolute',
        width: '80%',
        height: '100%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: 0,
        '&:focus': {
            outline: 'none'
        },
        overflow: 'hidden',
    },
    deleteConfirm: {
        color: '#6ab42d',
    },
    deleteCancel: {
        color: '#c62828',
    },
});

const masonryOptions = {
    gutter: 20,
    horizontalOrder: true,
};

class BenchmarksOverview extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            loading: true,
            snackbar: {
                message: '',
                open: false,
            },
        };
    }

    componentDidMount () {
        this.getBenchmarks();
    }

    getBenchmarks () {
        this.setState({
            benchmarks: [
                {
                    id: 0,
                    name: i18n.t('Fixed Benchmark'),
                    date: moment().format('LL'),
                    type: 'absolute',
                    typeLabel: i18n.t('Absolute Consumption Level'),
                    consumption: 4,
                    periodicity:  i18n.t('Month')
                },
                {
                    id: 1,
                    name: i18n.t('Relative Benchmark'),
                    date: moment().format('LL'),
                    type: 'relative',
                    typeLabel: i18n.t('Relative Consumption Level'),
                    referenceTime: i18n.t('Same 3 Months')
                },
            ],
            loading: false,
        });
    }


    setMasonryRef = (ref) => this.masonry = ref
    masonryLayout = () => this.masonry.performLayout()

    render () {
        const {classes} = this.props;

        return (
            <div>
                {this.state.loading ?
                    <Loading /> :
                    <Masonry
                        className={'benchmarks-grid'}
                        elementType={'div'}
                        options={masonryOptions}
                        disableImagesLoaded={false}
                        updateOnEachImageLoad={false}
                        ref={this.setMasonryRef}
                    >
                        {(this.state.benchmarks && this.state.benchmarks.length > 0) ?
                            this.state.benchmarks.map((benchmark, i) => (
                                <BenchmarkCard
                                    key={benchmark.id}
                                    data={benchmark}
                                    seq={i}
                                    masonryLayout={this.masonryLayout}
                                />
                            )) :
                            <Typography variant="title" color="textSecondary" classes={{title: classes.typographyTitle}}>
                                {i18n.t('No benchmarks available')}
                            </Typography>
                        }
                    </Masonry>
                }
                <SystemSnackbar
                    open={true}
                    message={i18n.t('View Only - This feature is currently under development')}
                    variant='info2'
                    noAutoHide
                />
            </div>
        )
    }

}

BenchmarksOverview.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BenchmarksOverview);
