import React, { PureComponent } from 'react';

import { adjustJSONToValidJSON } from '../../components/utils';
import Button from 'material-ui/Button';
import AddIcon from 'material-ui-icons/AddCircleOutline';
import CompareCard from './CompareCard';

// Translation
import i18n from '../../i18n';

class GroupCompare extends PureComponent {

    goToURL = (url) => this.props.history.push(url)
    getFullURL = (ids) => `${this.props.baseURLDefault}${this.getIdsToURL(ids)}${this.getSearchURL()}`
    getIdsJSONFromURL = () => {
        const searchParams = new URLSearchParams(window.location.search);
        return searchParams.get('ids') ? JSON.parse(adjustJSONToValidJSON(searchParams.get('ids'))) : [];
    }

    getIdsParsedToURL = (ids) => JSON.stringify(ids).replace(/"/g, '')
    getIdsToURL = (ids) => `?ids=${this.getIdsParsedToURL(ids)}`

    changeGroup = (group, index) => {
        const stateCompare = this.props.compare[index];
        if (group && group.id && stateCompare && ((stateCompare.filterGroup && stateCompare.filterGroup.id !== group.id) || stateCompare.filterTag)) {
            let ids = this.getIdsJSONFromURL();
            ids[index] = { gid: group.id, open: true };
            this.goToURL(this.getFullURL(ids));
        }
    }

    changeTag = (tag, index) => {
        const stateCompare = this.props.compare[index];
        if (tag && tag.id && stateCompare) {
            let ids = this.getIdsJSONFromURL();
            ids[index] = { tag: tag.id, open: true };
            this.goToURL(this.getFullURL(ids));
        }
    }

    getSearchURL = () => {
        let url = this.getDateURL();
        url = this.getPeriodFilterURL(false, url);
        if (!this.props.isLinear)
            url += '&step';
        if (this.props.showAlertEvents)
            url += '&alerts';
        return url;
    }

    getPeriodFilterURL = (fromSelect, url, period=this.props.periodFilter, date) => {
        if (fromSelect){
            const searchParams = new URLSearchParams(this.props.location.search);
            url = this.props.location.pathname + `?ids=${searchParams.get('ids')}`;
            url += this.getDateURL(period, date);
        }

        url.indexOf('period') >= 0 ? url = url.replace(/day|week|month|custom/, period) : url += `&period=${period}`;

        if (fromSelect && !this.props.isLinear)
            url += '&step';

        if (fromSelect && this.props.showAlertEvents)
            url += '&alerts';

        if (this.props.level) {
            url += `&level=${this.props.level}`
        }

        if (this.props.old_date) {
            url += `&old_date=${this.props.old_date}`
        }

        return url;
    }

    getDateURL = (period=this.props.periodFilter, date) => {
        date = date ? date : period === 'custom' ? this.props.dateRangePicker : this.props.datePicker;

        if (period === 'custom'){
            let url = "";
            url += date.startDate ?
                `&startDate=${date.startDate.format('YYYY-MM-DD')}` : '';
            url += date.endDate ?
                `&endDate=${date.endDate.format('YYYY-MM-DD')}` : '';
            return url;
        }else{
            return date.startDate ?
                `&date=${date.startDate.format('YYYY-MM-DD')}` : '';
        }
    }

    changeSubgroup = (subgroup, index) => {
        const stateCompare = this.props.compare[index];
        let ids = this.getIdsJSONFromURL();
        if (!subgroup) {
            delete(ids[index].sgid);
            delete(ids[index].sid);
            delete(ids[index].sph);
            this.goToURL(this.getFullURL(ids));
        } else if (
            subgroup.id && (
                !stateCompare || !stateCompare.filterSubgroup || stateCompare.filterSubgroup.id !== subgroup.id
            )
        ) {
            delete(ids[index].sid);
            delete(ids[index].sph);
            ids[index].sgid = subgroup.id;
            this.goToURL(this.getFullURL(ids));
        }
    }

    changeSensor = (sensor, index) => {
        const stateCompare = this.props.compare[index];
        let ids = this.getIdsJSONFromURL();
        if (!sensor) {
            delete(ids[index].sid);
            delete(ids[index].sph);
            this.goToURL(this.getFullURL(ids));
        } else if (
            sensor.id && (!stateCompare || !stateCompare.filterSensor || stateCompare.filterSensor.key !== sensor.key)
        ) {
            ids[index].sid = sensor.id;
            ids[index].sph = sensor.channel;
            this.goToURL(this.getFullURL(ids));
        }
    }

    render() {
        const {
            addCompare,
            compare,
            filterGroupOptions,
            hideTags,
            removeCompare,
            tags,
            toggleCompareCollapse,
        } = this.props

        return (
            <div id="group-filters">
                <div className="group-scroll">
                    <div id="groups">
                        {compare.map((obj, index) => (
                            <div className={`one-group${obj.open ? ' active' : ''} ${(index === compare.length - 1) ? ' last' : ''}`} key={index}>
                                <CompareCard
                                    changeGroup={this.changeGroup}
                                    changeSensor={this.changeSensor}
                                    changeSubgroup={this.changeSubgroup}
                                    changeTag={this.changeTag}
                                    compare={compare}
                                    filterGroupOptions={filterGroupOptions}
                                    hideTags={hideTags}
                                    index={index}
                                    obj={obj}
                                    removeCompare={removeCompare}
                                    tags={tags}
                                    toggleCompareCollapse={toggleCompareCollapse}
                                />

                                {(index < (compare.length - 1)) && (
                                    <div className="group-divider">+</div>
                                )}
                            </div>
                        ))}
                        <Button className="compare-add-group" onClick={addCompare}>
                            <AddIcon />
                            {i18n.t('ADD GROUP')}
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}

export default GroupCompare
