import React, { PureComponent } from 'react';
import { withStyles } from 'material-ui/styles';

import { Title } from '../../elements/title';
import { Text } from '../../elements/text';
import Card, { CardContent } from 'material-ui/Card';
import { API } from '../../components/api';
import {Loading} from '../../elements/loading';

// Translation
import i18n from '../../i18n';

import ABSOLUTE_CONSUMPTION from './absolute.svg';
import ABSOLUTE_COST from './cost.svg';
import RELATIVE from './relative.svg';
import ENTITY_STATUS from './sequipament.svg';
import ABSOLUTE_ACTIVE_POWER from './absolute_power.svg';

const styles = theme => ({
    alertsContent: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '25px'
    },
    card: {
        flex: 1,
        overflow: 'visible',
        marginRight: '16px',
        '&:last-child': {
            marginRight: '0px'
        }
    },
    contentRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0',
        '&:last-child': {
            padding: '10px'
        }
    },
    alertName: {
        flex: 1,
        marginLeft: '10px'
    },
    alertValue: {
        fontSize: '45px',
        margin: '0px',
    }
});

class AlertSection extends PureComponent {
    state = {
        relative: {
            loading: true,
            value: 0
        },
        absolute: {
            loading: true,
            value: 0
        },
        cost: {
            loading: true,
            value: 0
        },
        status: {
            loading: true,
            value: 0
        },
        activePower: {
            loading: true,
            value: 0
        }
    }

    componentDidMount() {
        this.getRelativeConsumptionEvents();
        this.getAbsoluteConsumptionEvents();
        this.getCostEvents();
        this.getStatusEvents();
        this.getActivePowerEvents();
    }

    getRelativeConsumptionEvents = async () => {

        try {
            const { data } = await API.HOME.ALERTS.UNREAD.RELATIVE_CONSUMPTION();

            this.setState({
                relative: {
                    loading: false,
                    value: data.unread
                }
            });
        } catch (error) {
            this.setState({
                relative: {
                    loading: false,
                    value: 0
                }
            })
        }
    }

    getAbsoluteConsumptionEvents = async () => {

        try {
            const { data } = await API.HOME.ALERTS.UNREAD.ABSOLUTE_CONSUMPTION();

            this.setState({
                absolute: {
                    loading: false,
                    value: data.unread
                }
            });
        } catch (error) {
            this.setState({
                absolute: {
                    loading: false,
                    value: 0
                }
            })
        }
    }

    getCostEvents = async () => {

        try {
            const { data } = await API.HOME.ALERTS.UNREAD.ABSOLUTE_COST();

            this.setState({
                cost: {
                    loading: false,
                    value: data.unread
                }
            });
        } catch (error) {
            this.setState({
                cost: {
                    loading: false,
                    value: 0
                }
            })
        }
    }

    getStatusEvents = async () => {

        try {
            const { data } = await API.HOME.ALERTS.UNREAD.ENTITY_STATUS();

            this.setState({
                status: {
                    loading: false,
                    value: data.unread
                }
            });
        } catch (error) {
            this.setState({
                status: {
                    loading: false,
                    value: 0
                }
            })
        }
    }

    getActivePowerEvents = async () => {
        try {
            const { data } = await API.HOME.ALERTS.UNREAD.ABSOLUTE_ACTIVE_POWER();

            this.setState({
                activePower: {
                    loading: false,
                    value: data.unread
                }
            });
        } catch (error) {
            this.setState({
                activePower: {
                    loading: false,
                    value: 0
                }
            })
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <section id="page-home-alert">
                <Title element="h4" text={i18n.t("Alerts")} />
                <div className={classes.alertsContent}>
                    <Card className={`home_absolute_consumption ${classes.card}`}>
                        <CardContent classes={{root: classes.contentRoot}}>
                            <img src={ABSOLUTE_CONSUMPTION} alt={i18n.t("Absolute consumption")} />
                            <Text className={classes.alertName} bold medium text={i18n.t("Absolute consumption")} />
                            {this.state.absolute.loading
                                ? <Loading />
                                : <Text className={classes.alertValue} bold text={this.state.absolute.value} />
                            }
                        </CardContent>
                    </Card>
                    <Card className={`home_absolute_cost ${classes.card}`}>
                        <CardContent classes={{root: classes.contentRoot}}>
                            <img src={ABSOLUTE_COST} alt={i18n.t("Cost")} />
                            <Text className={classes.alertName} bold medium text={i18n.t("Cost")} />
                            {this.state.cost.loading
                                ? <Loading />
                                : <Text className={classes.alertValue} bold text={this.state.cost.value} />
                            }
                        </CardContent>
                    </Card>
                    <Card className={`home_entity_status ${classes.card}`}>
                        <CardContent classes={{root: classes.contentRoot}}>
                            <img src={ENTITY_STATUS} alt={i18n.t("Equipment Status")} />
                            <Text className={classes.alertName} bold medium text={i18n.t("Equipment Status")} />
                            {this.state.status.loading
                                ? <Loading />
                                : <Text className={classes.alertValue} bold text={this.state.status.value} />
                            }
                        </CardContent>
                    </Card>
                    <Card className={`home_relative ${classes.card}`}>
                        <CardContent classes={{root: classes.contentRoot}}>
                            <img src={RELATIVE} alt={i18n.t("Relative consumption")} />
                            <Text className={classes.alertName} bold medium text={i18n.t("Relative consumption")} />
                            {this.state.relative.loading
                                ? <Loading />
                                : <Text className={classes.alertValue} bold text={this.state.relative.value} />
                            }
                        </CardContent>
                    </Card>

                    <Card className={`home_absolute_active_power ${classes.card}`}>
                        <CardContent classes={{root: classes.contentRoot}}>
                            <img src={ABSOLUTE_ACTIVE_POWER} alt={i18n.t("Power")} />
                            <Text className={classes.alertName} bold medium text={i18n.t("Power")} />
                            {this.state.activePower.loading
                                ? <Loading />
                                : <Text className={classes.alertValue} bold text={this.state.activePower.value} />
                            }
                        </CardContent>
                    </Card>
                </div>
            </section>
        )
    }
}

export default withStyles(styles)(AlertSection);
