import React, { PureComponent } from 'react';
import Masonry from 'react-masonry-component';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import Button from 'material-ui/Button';
import CloseIcon from 'material-ui-icons/Close';
import Typography from 'material-ui/Typography';
import AddIcon from 'material-ui-icons/AddCircleOutline';
import Modal from 'material-ui/Modal';
import Dialog, {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from 'material-ui/Dialog';

import { API } from '../../components/api';

// Translation
import i18n from '../../i18n';

import AlertCard from './alert-card';
import AlertAdd from './alert-add';
import AlertCategory from './alert-category';
import { Loading } from '../../elements/loading';
import SystemSnackbar from '../../elements/material-ui/SystemSnackbar';

const masonryOptions = {
    gutter: 20,
    horizontalOrder: true,
};

const styles = theme => ({
    typographyTitle: {
        fontSize: '1rem',
        fontWeight: 600
    },
    paper: {
        position: 'absolute',
        width: 600,
        minHeight: 500,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: 0,
        '&:focus': {
            outline: 'none'
        }
    },
    paperType: {
        position: 'absolute',
        width: 680,
        minHeight: 320,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: 0,
        '&:focus': {
            outline: 'none'
        }
    },
    snackbarClose: {
        width: theme.spacing.unit * 4,
        height: theme.spacing.unit * 4,
    },
    createAlertButton: {
        borderRadius: '3px',
        display: 'flex',
        alignItems: 'center',
        background: '#FFF',
        color: '#6ab42d',
        position: 'fixed',
        right: '30px',
        top: '20px',
        zIndex: 2000,
        padding: '4px 10px',
        cursor: 'pointer',
    },
    createAlertButtonText: {
        color: '#6ab42d',
        paddingLeft: '4px',
        fontWeight: 'bold',
    },
    deleteConfirm: {
        color: '#6ab42d',
    },
    deleteCancel: {
        color: '#c62828',
    },
});

const modalStyle = {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '90%',
    overflowY: 'auto',
}

const closeButtonStyle = {
    cursor: 'pointer',
    padding: '10px',
    position: 'absolute',
    right: 0,
    zIndex: 1,
}

class AlertsOverview extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            alerts: [],
            openAlertModal: false,
            openCategoryModal: false,
            alertModalDialogOpen: false,
            showSnackBar: false,
            selectCategory: null,
            snackbar: {
                type: 'warning',
                message: ''
            },
        }

        window.openSnackBar = this.openSnackBar;
    }

    componentWillMount() {
        let searchParams = new URLSearchParams(this.props.history.location.search);

        this.getAlerts(parseFloat(searchParams.get('id')))
    }

    handleOpenModal = () => this.setState({
        openCategoryModal: true
    })

    openEditAlert = alert => this.setState({
        alertCategory: alert.category,
        openAlertModal: true,
        editAlert: alert,
    })

    handleCloseCategoryModal = () => this.setState({
        openCategoryModal: false
    })

    handleCloseModal = closeAndUpdateAlerts => {
        if (closeAndUpdateAlerts === true){
            this.getAlerts();
            this.setState({ openAlertModal: false, editAlert: null });
        } else {
            this.setState({ alertModalDialogOpen: true });
        }
    }

    selectCategory = category => this.setState({
        openCategoryModal: false,
        openAlertModal: true,
        alertCategory: category
    })

    handleGroupModalDialogOpen = () => {
        this.setState({alertModalDialogOpen: true});
    }

    handleAlertModalDialogClose = flag => (flag === true
        ? this.setState({
            alertModalDialogOpen: false,
            alertCategory: null,
            editAlert: null,
            openAlertModal: false
        }) : this.setState({
            alertModalDialogOpen: false,
        })
    )

    getAlerts = (id) => {
        API.ALERTS.GET().then(({ data }) => {
            const found = !!data && data.find(alert => alert.id === id)

            this.setState({
                alerts: data ? data : [],
                loading: false,
                alertCategory: !!found ? found.category : null,
                openAlertModal: !!found,
                editAlert: !!found ? found : null,
            })
        }).catch((error) => {
            this.setState({ loading: false })
            console.error(error)
        })
    }

    handleAlertDelete = (alert) => {
        API.ALERTS.DELETE(alert.id).then((response) => {
            this.openSnackBar(i18n.t('Alert successfully removed.'), 'success');
            this.getAlerts();
        }).catch((error) => {
            console.error(error);
            this.openSnackBar(i18n.t('An error occurred while trying to remove this alert.'), 'error');
        });
    }

    openSnackBar = (message, type) => this.setState({
        showSnackBar: true,
        snackbar: {
            message,
            type
        }
    })

    closeSnackBar = () => this.setState({
        showSnackBar: false
    })

    setMasonryRef = (ref) => this.masonry = ref
    masonryLayout = () => this.masonry.performLayout()

    render () {
        const { classes } = this.props;
        const { loading, alerts, showSnackBar, snackbar, editAlert} = this.state;

        return (
            <div>
                {localStorage.getItem('authority') === 'ADMIN' ?
                    <div onClick={this.handleOpenModal} className={classes.createAlertButton} id="new-alert">
                        <AddIcon />
                        <Typography variant="body2" classes={{body2: classes.createAlertButtonText}}>
                            {i18n.t('CREATE ALERT')}
                        </Typography>
                    </div>
                : null}
                {loading ?
                    <Loading /> :
                    <Masonry disableImagesLoaded={false}
                        className="alerts-grid"
                        elementType={'div'}
                        options={masonryOptions}
                        updateOnEachImageLoad={false}
                        ref={this.setMasonryRef}>
                        {alerts && alerts.length > 0 ? alerts.map((alert, i) => (
                            <AlertCard
                                handleAlertDelete={this.handleAlertDelete}
                                key={alert.id}
                                data={alert}
                                seq={i}
                                openEditAlert={this.openEditAlert}
                                masonryLayout={this.masonryLayout}
                            />
                        )) : (
                            <Typography variant="title" color="textSecondary" classes={{title: classes.typographyTitle}}>
                                {i18n.t('No alerts available')}
                            </Typography>
                        )}
                    </Masonry>
                }
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.openCategoryModal}
                    onClose={this.handleCloseCategoryModal}
                >

                    <div style={modalStyle} className={classes.paperType}>
                        <CloseIcon onClick={this.handleCloseCategoryModal} style={closeButtonStyle} />
                        <AlertCategory onSelectCategory={this.selectCategory} />
                    </div>
                </Modal>
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.openAlertModal}
                    onClose={this.handleCloseModal}
                    disableBackdropClick={true}>

                    <div style={modalStyle} className={classes.paper}>
                        <CloseIcon onClick={this.handleCloseModal} style={closeButtonStyle} />

                        <AlertAdd
                            handleCloseModal={this.handleCloseModal}
                            handleOpenModal={this.handleOpenModal}
                            editAlert={editAlert}
                            category={this.state.alertCategory}
                        />

                        <Dialog aria-labelledby="dialog-text"
                            fullWidth={true}
                            maxWidth="xs"
                            open={this.state.alertModalDialogOpen}
                            onClose={this.handleAlertModalDialogClose}>
                            <DialogTitle>
                                {i18n.t('Are you sure?')}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="dialog-text">
                                    {i18n.t('If you close all unsaved information will be lost.')}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button classes={{label: classes.deleteConfirm}}
                                    onClick={() => this.handleAlertModalDialogClose(true)}>
                                    {i18n.t('Yes')}
                                </Button>
                                <Button classes={{label: classes.deleteCancel}} onClick={this.handleAlertModalDialogClose}>
                                    {i18n.t('Cancel')}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </Modal>

                <SystemSnackbar
                    open={showSnackBar}
                    message={snackbar.message}
                    onClose={this.closeSnackBar}
                    variant={snackbar.type} />
            </div>
        )
    }

}

AlertsOverview.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AlertsOverview);
