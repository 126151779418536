import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import moment from 'moment';
import Avatar from 'material-ui/Avatar';
import Card, { CardContent, CardHeader } from 'material-ui/Card';
import IconButton from 'material-ui/IconButton';
import DeleteIcon from 'material-ui-icons/Delete';
import EditIcon from 'material-ui-icons/Edit';
import Dialog, {
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from 'material-ui/Dialog';
import Button from 'material-ui/Button';
import classNames from 'classnames';

// Translation
import i18n from '../../i18n';

import { Text } from '../../elements/text';
import SystemSnackbar from '../../elements/material-ui/SystemSnackbar';
import AlertCardText from './alert-card-text';

import ABSOLUTE_CONSUMPTION from './absolute_white.svg';
import ABSOLUTE_COST from './cost_white.svg';
import RELATIVE from './relative_white.svg';
import ENTITY_STATUS from './sequipament_white.svg';
import ABSOLUTE_ACTIVE_POWER from './absolute_power_white.svg';

import './alert-card.css';


const styles = theme => ({
    recipients: {
        display: 'flex',
        padding: '0 15px 10px'
    },
    subloadContentGroup: {
        padding: '0 15px 10px'
    },
    card: {
        marginBottom: '20px',
        width: '320px',
    },
    cardButton: {
        height: '30px',
        width: '30px',
    },
    cardButtonIcon: {
        color: '#fff',
        fontSize: '20px',
    },
    headerRoot: {
        background: '#D0021B',
        padding: '4px 9px 5px',
    },
    headerAvatar: {
        marginRight: '8px',
    },
    avatar: {
        background: 'none',
        height: '60px',
        width: '54px',
    },
    avatarIcon: {
        height: '48px',
        width: '48px',
    },
    headerTitle: {
        color: '#fff',
        fontSize: '1.1rem',
        lineHeight: '1.4',
        paddingTop: '5px',
        wordBreak: 'break-all',
    },
    headerSubheader: {
        color: '#fff',
        fontSize: '.9rem',
        lineHeight: '1.4',
    },
    headerAction: {
        marginRight: '-2px',
        marginTop: '3px',
    },
    cardActions: {
        borderTop: '1px solid #bbb',
        justifyContent: 'flex-end',
        padding: '12px 15px',
    },
    contentRoot: {
        padding: '0',
        '&:last-child': {
            paddingBottom: '10px',
        },
    },
    groupContent: {
        padding: '10px 15px',
    },
    deleteConfirm: {
        color: '#6ab42d',
    },
    deleteCancel: {
        color: '#c62828',
    },
    alertCategoryTitle: {
        color: '#000',
        fontSize: '14px',
        fontWeight: 'bold',
        margin: '8px 0'
    },
});

class AlertCard extends PureComponent {
    state = {
        showSnackBar: false,
        snackbar: {
            type: 'warning',
            message: ''
        },
        deleteDialogOpen: false,
    }

    alertDateFormatted = (date) => moment(date).format('LL')

    openSnackBar = (message, type) => this.setState({
        showSnackBar: true,
        snackbar: {
            message,
            type
        }
    })

    closeSnackBar = () => this.setState({
        showSnackBar: false
    })

    handleEditClick = () => this.props.openEditAlert(this.props.data)

    handleDeleteClick = () => {
        this.setState({deleteDialogOpen: true});
    }

    handleDeleteDialogClose = (confirmDelete) => {
        if (confirmDelete === true)
            this.props.handleAlertDelete(this.props.data);
        this.setState({deleteDialogOpen: false});
    }

    getCategoryImg = alert => {
        switch (alert.category) {
            case 'ABSOLUTE_COST':
                return ABSOLUTE_COST;
            case 'ABSOLUTE_CONSUMPTION':
                return ABSOLUTE_CONSUMPTION;
            case 'RELATIVE_CONSUMPTION':
                return RELATIVE;
            case 'ENTITY_STATUS':
                return ENTITY_STATUS;
            case 'ABSOLUTE_ACTIVE_POWER':
                return ABSOLUTE_ACTIVE_POWER;
            default:
                return RELATIVE;
        }
    }

    getCategoryTitle = alert => {
        switch (alert.category) {
            case 'ABSOLUTE_COST':
                return i18n.t('Cost Alert');
            case 'ABSOLUTE_CONSUMPTION':
                return i18n.t('Absolute Consumption');
            case 'RELATIVE_CONSUMPTION':
                if (alert.alertType === 'PERCENTAGE') {
                    return i18n.t('Relative Consumption - %');
                }
                return i18n.t('Relative Consumption - kWh');
            case 'ENTITY_STATUS':
                return i18n.t('Equipment Status Alert');
            case 'ABSOLUTE_ACTIVE_POWER':
                return i18n.t('Power Alert');
            default:
                if (alert.alertType === 'PERCENTAGE') {
                    return i18n.t('Relative Consumption - %');
                }
                return i18n.t('Relative Consumption - kWh');
        }
    }

    render() {
        const { showSnackBar, snackbar } = this.state;
        const { classes, data } = this.props;

        return (
            <Card className={classes.card}>
                <CardHeader classes={{
                        action: classes.headerAction,
                        avatar: classes.headerAvatar,
                        root: classes.headerRoot,
                        subheader: classes.headerSubheader,
                        title: classes.headerTitle,
                    }}
                    action={localStorage.getItem('authority') === 'ADMIN' ?
                        <div>
                            <IconButton aria-label="Delete"
                                color="inherit"
                                key="delete"
                                className={classNames(classes.cardButton, "btn-delete")}
                                onClick={this.handleDeleteClick}>
                                <DeleteIcon className={classes.cardButtonIcon} />
                            </IconButton>
                            <IconButton aria-label="Edit"
                                color="inherit"
                                key="edit"
                                className={classNames(classes.cardButton, "btn-edit")}
                                onClick={this.handleEditClick}>
                                <EditIcon className={classes.cardButtonIcon} />
                            </IconButton>
                        </div> : null
                    }
                    avatar={
                        <Avatar className={classes.avatar}>
                            <img className={classes.avatarIcon} src={this.getCategoryImg(data)} alt={i18n.t("Alert category")} />
                        </Avatar>
                    }
                    title={data.name}
                    subheader={this.alertDateFormatted(data.createdAt)} />

                <CardContent classes={{root: classes.contentRoot}}>
                    <div className={classes.groupContent}>
                        <div className={`alert-category-title ${classes.alertCategoryTitle}`}>
                            {this.getCategoryTitle(data)}
                        </div>
                        {data.breadCrumbs && data.breadCrumbs.groupName && ((data.breadCrumbs.channel && data.breadCrumbs.sensorId) || (!data.breadCrumbs.channel && !data.breadCrumbs.sensorId)) && (
                            <div className="group-wrapper">
                                <Text inline text={i18n.t("Group:")} />
                                <Text bold
                                    inline
                                    text={(data.breadCrumbs && data.breadCrumbs.parentGroupName)
                                        ? data.breadCrumbs.parentGroupName
                                        : data.breadCrumbs.groupName} />
                            </div>
                        )}

                        {data.breadCrumbs && data.breadCrumbs.parentGroupName && data.breadCrumbs.groupName ? (
                            <div className="subgroup-wrapper">
                                <Text inline text={i18n.t("Subgroup:")} />
                                <Text bold inline text={data.breadCrumbs.groupName} />
                            </div>
                        ) : null}

                        {data.breadCrumbs && data.breadCrumbs.sensorId ? (
                            <div className="sensor-wrapper">
                                <Text inline text={i18n.t("Sensor:")} />
                                <Text bold
                                    inline
                                    text={data.breadCrumbs.channel
                                        ? `${data.breadCrumbs.sensorId}-${data.breadCrumbs.channel} ${data.breadCrumbs.sensorChannelName ? '- ' + data.breadCrumbs.sensorChannelName : ''}`
                                        : `${data.breadCrumbs.sensorId} ${i18n.t('(all phases)')}`
                                    }
                                />
                            </div>
                        ) : null}
                        <AlertCardText
                            data={data}
                            masonryLayout={this.props.masonryLayout}
                        />
                    </div>
                </CardContent>

                <Dialog aria-labelledby="dialog-text"
                    fullWidth={true}
                    maxWidth="xs"
                    open={this.state.deleteDialogOpen}
                    onClose={this.handleDeleteDialogClose}>
                    <DialogTitle>
                        {i18n.t('Are you sure?')}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="dialog-text">
                            {i18n.t('This report will be permanently removed.')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button classes={{label: classes.deleteConfirm}}
                            onClick={() => this.handleDeleteDialogClose(true)}>
                            {i18n.t('Yes')}
                        </Button>
                        <Button classes={{label: classes.deleteCancel}} onClick={() => this.handleDeleteDialogClose(false)}>
                            {i18n.t('Cancel')}
                        </Button>
                    </DialogActions>
                </Dialog>

                <SystemSnackbar
                    open={showSnackBar}
                    message={snackbar.message}
                    onClose={this.closeSnackBar}
                    variant={snackbar.type}
                />
            </Card>
        );
    }
}

AlertCard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AlertCard);
