import React from 'react';

import './field.css';


class Field extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            focus: false,
        };
    }

    componentWillReceiveProps (nextProps) {
        if (nextProps.disabled) {
            this.setState({focus: false});
        }
    }

    blur = () => {
        setTimeout(() => {
            this.setState({focus: false});
        }, 25);
    }

    focus = () => {
        this.setState({focus: true});
    }

    getSpanClass = () => {
        let spanClass = 'placeholder';

        if (this.state.focus) {
            spanClass += ' active';
        } else {
            if (this.props.value || this.props.selected) spanClass += ' deactive';
        }

        return spanClass;
    }

    render () {
        return null;
    }
}

export {Field};
