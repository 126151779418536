import React from 'react';
import Masonry from 'react-masonry-component';

import {API} from '../../components/api';
//import {TARIFF_CHARGE_TYPES} from '../../components/constants';

import {ErrorMessage} from '../../elements/error-message';
import {Loading} from '../../elements/loading';
//import {Text} from '../../elements/text';
import { Button as VButton } from '../../elements/button';
import { withStyles } from 'material-ui/styles';
import Modal from 'material-ui/Modal';
import CloseIcon from 'material-ui-icons/Close';
import AddIcon from 'material-ui-icons/AddCircleOutline';
import Typography from 'material-ui/Typography';
import PropTypes from 'prop-types';
import TariffAddEdit from  './add-edit';
import TariffCard from './tariff-card';
import Snackbar from 'material-ui/Snackbar';
import IconButton from 'material-ui/IconButton';
import Dialog, {
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from 'material-ui/Dialog';
import Button from 'material-ui/Button';

import i18n from '../../i18n';

import './overview.css';

const styles = theme => ({
    paper: {
        position: 'absolute',
        width: '80%',
        minHeight: 500,
        maxHeight: '90%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: 0,
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
        overflow: 'hidden',
        overflowY: 'auto',
        '&:focus': {
            outline: 'none'
        },
    },
    createTariffButton: {
        background: '#FFF',
        borderRadius: '3px',
        display: 'flex',
        alignItems: 'center',
        color: '#6ab42d',
        padding: '4px 10px',
        position: 'fixed',
        right: '30px',
        top: '20px',
        zIndex: 2000,
        cursor: 'pointer',
    },
    createTariffButtonText: {
        color: '#6ab42d',
        paddingLeft: '4px',
        fontWeight: 'bold',
    },
    snackbarClose: {
        width: theme.spacing.unit * 4,
        height: theme.spacing.unit * 4,
    },
    snackbarError: {
        background: "#FFCCCC",
    },
    snackbarInfo: {
        background: "#FFF",
    },
    snackbarMessage: {
        color: '#4A4A4A',
        fontSize: '14px',
    },
    actionConfirm: {
        color: '#6ab42d',
    },
    actionCancel: {
        color: '#c62828',
    },
})

const closeButtonStyle = {
    cursor: 'pointer',
    padding: '10px',
    position: 'absolute',
    right: 0,
    zIndex: 1,
}

const initialState =  {
    loading: true,
    isModalOpened: false,
    tariffs: [],
    loadErrorMessage: '',
    dataLossModalDialogOpen: false,
    groupDelete: {
        data: {},
        loading: false,
    },
    snackbar: {
        message: '',
        open: false,
        error: false,
    }
};

const masonryOptions = {
    gutter: 20,
    horizontalOrder: true,
};

class TariffsOverview extends React.Component {
    constructor (props) {
        super(props);

        this.state = initialState;
    }

    componentWillMount () {
        this.getCountriesAndTariffs();
    }

    errorMessageEnd = i18n.t("Please, try again or contact support.");
    loadErrorMessage = i18n.t("An error occurred while retrieving data.");

    getCountriesAndTariffs = () => {
        this.setState({loading: true});

        API.COUNTRIES.GET().then((response) => {
            if (response.data && response.data.length > 0){
                this.countriesNamesMap = this.createCountriesNamesMap(response.data);
                this.setState({
                    countries: response.data,
                }, () => { this.getTariffs() });
            }else if (response.data && response.data.length === 0){
                this.setState({
                    loading: false,
                });
            }else{
                this.handleLoadError({loadingGroups: false}, "Could not load countries data.")
            }
        }).catch((error) => {
            this.handleLoadError({loadingGroups: false}, "Could not load countries data.", error)
        });
    }

    getTariffs = () => {
        API.TARIFFS.GET().then((response) => {
            this.setState({tariffs: response.data});

            if (this.state.loading)
                this.setState({loading: false});
        }).catch((error) => {
            this.handleLoadError({loading: false}, "Could not load tariffs.", error);
        });;
    }

    createCountriesNamesMap = (countries) => {
        const isoCodeNamePairsArray = countries.map((country) => [country.isoCode, country.name]);
        return new Map(isoCodeNamePairsArray);
    }

    getCountryName = (isoCode) => {
        return this.countriesNamesMap.get(isoCode);
    }

    submit = (e) => {
        e.preventDefault();
        e.target.querySelector('button[type="submit"]').blur();

        this.table.fireFetchData();

        return false;
    }

    handleOpenModal = () => {
        this.setState({ isModalOpened: true })
    }

    handleCloseModal = (isTariffAdded) => {
        if (isTariffAdded){
            this.getTariffs();
        }
        this.setState({ isModalOpened: false, editTariffId: null})
    }

    handleDataLossModalDialogOpen = () => this.setState({
        dataLossModalDialogOpen: true
    })

    handleLoadError = (state, message, error) => {
        state.loadErrorMessage = this.loadErrorMessage;
        this.setState(state);

        console.error(this.loadErrorMessage + " (" + message + ")");
        if (error)
            console.error(error);
    }

    handleSnackbarClose = () => {
        this.setState({
            snackbar: {
                ...this.state.snackbar,
                open: false,
            }
        })
    }

    showSnackBar = (message, error) => {
        if (error)
            message = message + ' ' + this.errorMessageEnd;

        this.setState({
            snackbar: {
                message: message,
                open: true,
                error: error === true,
            },
        });
    }

    reset = () => {
        this.setState(initialState);
        this.getTariffs();
    }

    openEditTariff = (tariffId) => {
        this.setState({ editTariffId: tariffId });
        this.handleOpenModal();
    }

    handleDataLossModalDialogClose = (flag) => {
        this.setState({
            dataLossModalDialogOpen: false
        });

        if (flag === true) {
            this.setState({
                isModalOpened: false,
                editTariffId: null
            })
        }
    }

    setMasonryRef = (ref) => this.masonry = ref
    masonryLayout = () => this.masonry.performLayout()

    render () {
        const {classes} = this.props;

        return (
            <section id="page-tariff-browse">
                {localStorage.getItem('authority') === 'ADMIN' && (
                    <div onClick={this.handleOpenModal} className={`${classes.createTariffButton} new-tariff`} >
                        <AddIcon />
                        <Typography variant="body2" classes={{body2: classes.createTariffButtonText}}>
                            {i18n.t('CREATE TARIFF')}
                        </Typography>
                    </div>
                )}
                {this.state.loadErrorMessage ?
                    <div>
                        <ErrorMessage message={this.state.loadErrorMessage + " " + this.errorMessageEnd} />
                        <VButton click={this.reset} action="reset" label={i18n.t("Reload")} />
                    </div> : null}

                {this.state.loading ?
                    <Loading /> :
                    <Masonry
                        className={'groups-grid'}
                        elementType={'div'}
                        options={masonryOptions}
                        disableImagesLoaded={false}
                        updateOnEachImageLoad={false}
                        ref={this.setMasonryRef}
                    >
                        {(this.state.tariffs && this.state.tariffs.length > 0) ?
                            this.state.tariffs.map((tariff, i) => (
                                <TariffCard
                                    key={i}
                                    data={tariff}
                                    seq={i}
                                    openEditTariff={this.openEditTariff}
                                    getCountryName={this.getCountryName}
                                    getTariffs={this.getTariffs}
                                    showSnackBar={this.showSnackBar}
                                    masonryLayout={this.masonryLayout}
                                />
                            )) :
                            <Typography variant="title" color="textSecondary" classes={{title: `${classes.typographyTitle} no-tariffs-title`}}>
                                {i18n.t('No tariffs available')}
                            </Typography>
                        }
                    </Masonry>
                }
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.isModalOpened}
                    onClose={this.handleDataLossModalDialogOpen}
                    disableBackdropClick={true}
                >

                    <div className={classes.paper}>
                        <CloseIcon onClick={this.handleDataLossModalDialogOpen} style={closeButtonStyle} />
                        <TariffAddEdit
                            handleCloseModal={this.handleCloseModal}
                            handleDataLossModalDialogOpen={this.handleDataLossModalDialogOpen}
                            editTariffId={this.state.editTariffId}
                            showSnackBar={this.showSnackBar}
                        />
                    </div>
                </Modal>

                <Snackbar
                    action={[
                        <IconButton
                            aria-label="Close"
                            className={classes.snackbarClose}
                            color="inherit"
                            key="close"
                            onClick={this.handleSnackbarClose}>
                            <CloseIcon />
                        </IconButton>,
                    ]}
                    anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
                    autoHideDuration={(!this.state.snackbar.error) ? 5000 : 10000}
                    onClose={this.handleSnackbarClose}
                    open={this.state.snackbar.open}
                    message={this.state.snackbar.message}
                    ContentProps={{
                        classes: {
                            root: (this.state.snackbar.error) ? classes.snackbarError : classes.snackbarInfo,
                            message: classes.snackbarMessage,
                        }
                    }}
                />
                <div className="leave-section-wrapper">
                    <Dialog
                        aria-labelledby="dialog-text"
                        fullWidth={true}
                        maxWidth="xs"
                        open={this.state.dataLossModalDialogOpen}
                        onClose={this.handleDataLossModalDialogClose}
                    >
                        <DialogTitle>
                            {i18n.t('Leave this section?')}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="dialog-text">
                                {i18n.t('If you leave this section, inputs you may have entered will not be saved.')}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                id="confirm-leave-dialog"
                                classes={{label: classes.actionConfirm}}
                                onClick={() => this.handleDataLossModalDialogClose(true)}
                            >
                                {i18n.t('Yes, leave')}
                            </Button>
                            <Button id="cancel-leave-dialog" classes={{label: classes.actionCancel}} onClick={this.handleDataLossModalDialogClose}>
                                {i18n.t('No, stay here')}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </section>
        );
    }
}

TariffsOverview.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TariffsOverview);
