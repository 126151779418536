import React from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import axios from 'axios';
import { withStyles } from 'material-ui/styles';
import classNames from 'classnames';
import Button from 'material-ui/Button';
import Drawer from 'material-ui/Drawer';
import AppBar from 'material-ui/AppBar';
import Toolbar from 'material-ui/Toolbar';
import IconButton from 'material-ui/IconButton';
import MenuIcon from 'material-ui-icons/Menu';
import ChevronLeftIcon from 'material-ui-icons/ChevronLeft';
import ChevronRightIcon from 'material-ui-icons/ChevronRight';
import LogoutIcon from 'material-ui-icons/ExitToApp';
import {NavLink} from 'react-router-dom';

import ExpandedMenu from './ExpandedMenu';
import Cookies from 'universal-cookie';
import {API} from '../../components/api';

import logo from './logo_electricity_monitoring.svg';

// Translation
import i18n from '../../i18n';

const CancelToken = axios.CancelToken;
let source;
const cookies = new Cookies();
const drawerWidth = 240;

const styles = theme => ({
    root: {
        flexGrow: 1,
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        height: '100vh'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: '#5A9429'
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawerPaper: {
        position: 'relative',
        height: '100%',
        width: drawerWidth,
        backgroundColor: '#333645',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing.unit * 7,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing.unit * 9,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        backgroundColor: '#6AB42D',
        flexDirection: 'column',
        height: '196px',
        position: 'relative',
        ...theme.mixins.toolbar,
    },
    menuIcon: {
        color: '#FFF'
    },
    navBar: {
        flex: 1
    },
    navBarList: {
        display: 'flex',
        listStyle: 'none',
        margin: '0 40px',
        '& li': {
            margin: '0 20px'
        },
        '& span': {
            color: '#FFF'
        }
    },
    navButton: {
        opacity: .7
    },
    navButtonActive: {
        opacity: 1
    },
    chevronIcon: {
        color: '#FFF'
    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing.unit * 3,
        marginTop: '60px',
        overflowY: 'auto',
    },
    logo: {
        maxWidth: 180,
    },
    logoWithPadding: {
        maxWidth: 180,
        paddingLeft: 16
    },
    rootMenu: {
        justifyContent: 'space-between'
    },
    closeMenu: {
        position: 'absolute',
        zIndex: 110,
        right: 0,
        top: 0,
    },
    tenantLogoWrapper: {
        flexGrow: 1,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 0,
    },
    tenantLogo: {
        maxHeight: '140px',
        maxWidth: '140px',
        marginTop: '8px',
    },
    tenantName: {
        color: '#fff',
        margin: 0,
        maxHeight: 95,
        overflow: 'hidden',
        padding: '6px',
        fontSize: 19,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
});


const actionBar = (path, classes) => {
    const consumptionNavLinks = [
        {
            path: '/analysis/consumption',
            tab: 'LineGraph',
            text: i18n.t('line graph')
        },
        {
            path: '/analysis/consumption/stream',
            tab: 'StreamGraph',
            text: i18n.t('stream graph')
        },
        {
            path: '/analysis/consumption/heatmap',
            tab: 'Heatmap',
            text: i18n.t('heatmap')
        },
        {
            path: '/analysis/geolocation',
            tab: 'Geolocation',
            text: i18n.t('geolocation')
        },
        {
            path: '/analysis/consumption/cumulative',
            tab: 'CumulativeDashboard',
            text: i18n.t('cumulative')
        },
        {
            path: '/analysis/consumption/benchmarking',
            tab: 'ConsumptionBenchmarking',
            text: i18n.t('benchmarking')
        },
    ];

    const alertsNavLinks = [
        {
            path: '/alerts/events',
            tab: 'Events',
            text: i18n.t('Events')
        },
        {
            path: '/alerts',
            tab: 'Alerts',
            text: i18n.t('Alerts')
        },
    ];

    const costNavLinks = [
        {
            path: '/analysis/cost',
            tab: 'BarGraph',
            text: i18n.t('Real Cost')
        },
        {
            path: '/analysis/cost/simulation',
            tab: 'LineGraph',
            text: i18n.t('Cost Simulation')
        },
    ];

    if (path.includes('/analysis/consumption') || path.includes('analysis/geolocation')) {
        const activeTab = path.includes("stream")
            ? 'StreamGraph'
            : path.includes('heatmap')
                ? 'Heatmap'
                : path.includes('cumulative')
                    ? 'CumulativeDashboard'
                    : path.includes('geolocation')
                        ? 'Geolocation'
                        : path.includes('benchmarking')
                            ? 'ConsumptionBenchmarking'
                            : 'LineGraph'
        return (
            <nav className={classes.navBar}>
                <ul className={classes.navBarList}>
                    {consumptionNavLinks.map((l,index) => {
                        return (
                            <li key={index}>
                                <NavLink to={l.path} >
                                    <Button
                                        className={
                                            activeTab === l.tab ?
                                                classes.navButtonActive :
                                                classes.navButton
                                            }
                                        color="inherit">{l.text}
                                    </Button>
                                </NavLink>
                            </li>
                       )
                    })}
                </ul>
            </nav>
        )
    } else if (path.includes('/alerts')) {
        const activeTab = path.includes("events") ? 'Events' : 'Alerts';
        return (
            <nav className={classes.navBar}>
                <ul className={classes.navBarList}>
                    {alertsNavLinks.map((l,index) => {
                        return (
                            <li key={index}>
                                <NavLink to={l.path} >
                                    <Button
                                        className={
                                            activeTab === l.tab ?
                                                classes.navButtonActive :
                                                classes.navButton
                                            }
                                        color="inherit">{l.text}
                                    </Button>
                                </NavLink>
                            </li>
                       )
                    })}
                </ul>
            </nav>
        )
    } else if (path.includes('/analysis/cost')) {
        const activeTab = path.includes("simulation") ? 'LineGraph' : 'BarGraph';
        return (
            <nav className={classes.navBar}>
                <ul className={classes.navBarList}>
                    {costNavLinks.map((l,index) => {
                        return (
                            <li key={index}>
                                <NavLink to={l.path} >
                                    <Button
                                        className={
                                            activeTab === l.tab ?
                                                classes.navButtonActive :
                                                classes.navButton
                                            }
                                        color="inherit">{l.text}
                                    </Button>
                                </NavLink>
                            </li>
                       )
                    })}
                </ul>
            </nav>
        )
    } else {
        return ""
    }
}

class MiniDrawer extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            open: cookies.get('menu-expanded') === 'true' && localStorage.getItem('isRoot') !== 'true' ? true : false,
        }
    }

    getGeneralInfo = () => {
        source = CancelToken.source();

        API.VISUALIZATIONS.INFO.GENERAL(source.token).then((response) => {
            if (response.data){
                this.setState({
                    numberOfSensors: response.data.numberOfSensors,
                    totalConsumption: response.data.totalConsumption,
                });
            }
        }).catch((error) => {
            if (!axios.isCancel(error)) console.error('Could not load sensors general info.')
        });
    }

    handleDrawerOpen = () => {
        cookies.set('menu-expanded', true, {path: '/'});
        this.setState({ open: true });
    }

    handleDrawerClose = () => {
        cookies.set('menu-expanded', false, {path: '/'});
        this.setState({ open: false });
    }

    handleLogout = () => {
        window.location = '/logout'
    }

    render() {
        const { classes, theme } = this.props;
        const tenantName = cookies.get('tenantName');
        const colors = JSON.parse(localStorage.getItem('tenantColors')) ? JSON.parse(localStorage.getItem('tenantColors')) : {};
        const tenantLogo = localStorage.getItem('tenantLogo');

        return (
            <div className={classes.root}>
                <AppBar
                    position="absolute"
                    className={classNames(classes.appBar, this.state.open && classes.appBarShift)}
                    style={{ backgroundColor: colors.secondary ? colors.secondary : null }}
                >
                    <Toolbar
                        className={localStorage.getItem('isRoot') === 'true' ? classes.rootMenu : null}
                        disableGutters={!this.state.open}
                    >
                        {localStorage.getItem('isRoot') !== 'true' && (
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={this.handleDrawerOpen}
                                className={classNames(classes.menuButton, this.state.open && classes.hide)}
                                >
                                <MenuIcon className={classes.menuIcon} />
                            </IconButton>
                        )}
                        <img src={logo} alt={i18n.t("voltaware building electricity monitoring logo")} className={localStorage.getItem('isRoot') !== 'true' ? classes.logo : classes.logoWithPadding} />
                        {actionBar(this.props.location.pathname, classes)}
                        {localStorage.getItem('isRoot') === 'true' && (
                            <IconButton
                                color="inherit"
                                aria-label="Logout"
                                onClick={this.handleLogout}
                                className={classes.menuButton}
                                >
                                <LogoutIcon className={classes.menuIcon} />
                            </IconButton>
                        )}
                    </Toolbar>
                </AppBar>
                {localStorage.getItem('isRoot') !== 'true' && (
                    <Drawer
                        variant="permanent"
                        classes={{
                            paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
                        }}
                        open={this.state.open}
                    >

                        <div className={classes.toolbar} style={{ backgroundColor: colors.primary ? colors.primary : null }}>
                            <IconButton className={classes.closeMenu} onClick={this.handleDrawerClose} >
                                {theme.direction === 'rtl' ? <ChevronRightIcon className={classes.chevronIcon} /> : <ChevronLeftIcon className={classes.chevronIcon} />}
                            </IconButton>
                            <div className={classes.tenantLogoWrapper}>
                                {this.state.open && tenantLogo !== 'null' && (
                                    <img src={`data:image/png;base64,${tenantLogo}`} alt="logo" className={classes.tenantLogo} />
                                )}
                            </div>
                            {this.state.open && (
                                <h4 className={`user-name ${classes.tenantName}`} data-tip={tenantName && tenantName.length > 12 ? tenantName : null}>
                                    {tenantName}
                                    <ReactTooltip />
                                </h4>
                            )}
                        </div>
                        <ExpandedMenu location={this.props.location}
                            drawerOpened={this.state.open}
                            totalConsumption={this.state.totalConsumption}
                            numberOfSensors={this.state.numberOfSensors} />
                    </Drawer>
                )}
                <main className={classes.content}>
                    {this.props.children}
                </main>
            </div>
        );
    }
}

MiniDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(MiniDrawer);
