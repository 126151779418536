import React from 'react';
import Select from 'react-select';

import './custom-react-select.css';

import i18n from '../../i18n';

class CustomSelect extends React.Component {
    render () {
        const {name, value, onChange, disabled, isLoading, valueKey, labelKey, className, options, placeholder} = this.props;

        let wrapperClasses = 'custom-react-select';

        if (this.props.noExtremitiesMargins)
            wrapperClasses += " no-extremities-margins"

        if (this.props.noLeftMargin)
            wrapperClasses += " no-left-margin"

        if (this.props.withinText)
            wrapperClasses += " within-text"

        return (
            <div className={wrapperClasses}>
                <Select
                    name={name}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    isLoading={isLoading}
                    className={className}
                    valueKey={valueKey}
                    labelKey={labelKey}
                    options={options}
                    placeholder={placeholder ? placeholder : i18n.t('Select...')}
                    noResultsText={i18n.t('No results found')}
                />
                {this.props.hasError && typeof this.props.hasError === 'string' && (
                    <small className="input-error">{this.props.hasError}</small>
                )}
            </div>
        );
    }
}

export {CustomSelect};
