import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { withStyles } from 'material-ui/styles';

import Table from 'material-ui/Table';
import Paper from 'material-ui/Paper';
import {TableBody, TableCell, TableHead, TableRow, TableSortLabel} from 'material-ui/Table';
import Button from 'material-ui/Button';
import CloseIcon from 'material-ui-icons/Close';
import AddIcon from 'material-ui-icons/AddCircleOutline';
import Modal from 'material-ui/Modal';
import ReactTooltip from 'react-tooltip';
import IconButton from 'material-ui/IconButton';
import CheckIcon from 'material-ui-icons/CheckCircle';
import EditIcon from 'material-ui-icons/Edit';
import DeleteIcon from 'material-ui-icons/Delete';

import Dialog, {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from 'material-ui/Dialog';
import Typography from 'material-ui/Typography';
import {Loading} from '../../elements/loading';
import UserAdd from './user-add.js'
import SystemSnackbar from '../../elements/material-ui/SystemSnackbar';

import { API } from '../../components/api';

import i18n from '../../i18n';

const CustomTableCell = withStyles(theme => ({
    body: {
        fontSize: '13px',
        fontWeight: 'bold',
        color: '#757575',
        '& span': {
            fontSize: '13px',
        }
    },
}))(TableCell);

const CustomTableSortLabel = withStyles(theme => ({
    root: {
        fontSize: '13px',
        fontWeight: 'bold',
        color: '#A1A1A8',
    },
}))(TableSortLabel);

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        width: '100%',
        marginBottom: theme.spacing.unit * 3,
        boxShadow: theme.shadows[5],
        padding: 0,
        '&:focus': {
            outline: 'none'
        }
    },
    tenantUsersPaper: {
        backgroundColor: theme.palette.background.paper,
        width: '100%',
        marginTop: theme.spacing.unit * 6,
        boxShadow: theme.shadows[5],
        padding: 0,
        '&:focus': {
            outline: 'none'
        }
    },
    modalPaper: {
        position: 'absolute',
        width: '550px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: 0,
        '&:focus': {
            outline: 'none'
        },
        overflow: 'hidden',
    },
    table: {
        minWidth: 1020,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    tbodyRow: {
        '& td:last-child button': {
            opacity: '0',
            transition: 'opacity 0.6s'
        },
        '&:hover td:last-child button': {
            opacity: '1'
        }
    },
    statusIcon: {
        borderRadius: '50%',
        width: '20px',
        height: '20px',
        display: 'inline-block',
        marginRight: '20px',
        position: 'relative',
        top: '4px'
    },
    statusInfo: {
        height: '20px',
    },
    disableStatus: {
        color: '#CCC',
    },
    enableStatus: {
        color: '#64b42d',
    },
    createUserButton: {
        borderRadius: '3px',
        display: 'flex',
        alignItems: 'center',
        background: '#FFF',
        color: '#6ab42d',
        position: 'fixed',
        right: '30px',
        top: '20px',
        zIndex: 2000,
        padding: '4px 10px',
        cursor: 'pointer',
    },
    tentantHeader: {
        left: 0,
        top: '7px',
        display: 'flex',
        position: 'fixed',
        right: '44px',
        justifyContent: 'space-between'
    },
    tenantActionsButtons: {
        display: 'flex'
    },
    tenantUsersCreateUserButton: {
        borderRadius: '3px',
        alignItems: 'center',
        background: '#FFF',
        display: 'flex',
        color: '#6ab42d',
        zIndex: 2000,
        padding: '4px 10px',
        cursor: 'pointer',
    },

    createUserButtonText: {
        color: '#6ab42d',
        paddingLeft: '4px',
        fontWeight: 'bold',
    },
    deleteConfirm: {
        color: '#6ab42d',
    },
    deleteCancel: {
        color: '#c62828',
    },
});

const modalStyle = {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
}

const closeButtonStyle = {
    cursor: 'pointer',
    padding: '10px',
    position: 'absolute',
    right: 0,
    zIndex: 11,
}

class Overview extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.columnData = [
            { id: 'email', numeric: false, disablePadding: false, label: i18n.t('E-mail') },
            { id: 'authority', numeric: false, disablePadding: false, label: i18n.t('Role') },
            { id: 'createdAt', numeric: false, disablePadding: false, label: i18n.t('Created At') },
            { id: 'enabled', numeric: false, disablePadding: false, label: i18n.t('Enabled') },
            { id: 'actions', numeric: false, disablePadding: false, label: '' }
        ];

        this.state = {
            loading: true,
            selected: [],
            page: 0,
            rowsCount: 0,
            anchorEl: null,
            openFilter: false,
            selectedFilter: 'all',
            users: [],
            openUserModal: false,
            openUserModalDialog: false,
            showSnackBar: false,
            snackbar: {
                type: 'warning',
                message: ''
            },
            openUserDeleteModalDialog: false,
            editUser: null
        }
    }

    componentWillMount() {
        this.getUsers();
    }

    componentWillReceiveProps(nextProps){
        if (nextProps.reloadUsers === true)
            this.reloadUsers();
    }

    authorities = {
        ADMIN: i18n.t('Administrator'),
        READ_ONLY: i18n.t('Read-only')
    }

    getUsers = () => {
        if (this.props.tenantUsersMode){
            API.ROOT.TENANTS.USERS.GET(this.props.tenant).then(({ data }) => {
                this.setState({
                    users: data,
                    rowsCount: data.numberOfEvents,
                    loading: false,
                })
            }).catch((error) => {
                this.setState({ loading: false })
                console.error(error)
            });
        }else{
            API.USERS.GET().then(({ data }) => {
                this.setState({
                    users: data,
                    rowsCount: data.numberOfEvents,
                    loading: false,
                })
            }).catch((error) => {
                this.setState({ loading: false })
                console.error(error)
            });
        }
    }

    reloadUsers = () => {
        this.getUsers();
    }

    handleOpenModal = () => this.setState({
        openUserModal: true
    })

    handleOpenUserDeleteDialog = user => this.setState({
        openUserDeleteModalDialog: user
    })

    handleCloseUserDeleteDialog = async flag => {
        if (this.state.deleting) {
            return
        }

        if (flag === true) {
            this.setState({ deleting: true });
            const { email }  = this.state.openUserDeleteModalDialog;

            if (this.props.tenantUsersMode){
                try {
                    await API.ROOT.TENANTS.USERS.DELETE(email, this.props.tenant);

                    this.openSnackBar(i18n.t('User deleted successfully'), 'success');
                    this.setState({
                        deleting: false,
                        openUserDeleteModalDialog: false
                    });
                } catch (error) {
                    this.setState({ deleting: false });
                    console.error(error);
                }
            }else{
                try {
                    await API.USERS.DELETE(email)

                    this.openSnackBar(i18n.t('User deleted successfully'), 'success')
                    this.setState({
                        deleting: false,
                        openUserDeleteModalDialog: false
                    })
                } catch (error) {
                    this.setState({ deleting: false })
                    console.error(error)
                }
            }
            return this.getUsers()
        }

        this.setState({
            openUserDeleteModalDialog: false
        })
    }

    handleDisableAll = async () => {
        this.setState({ loading: true })

        try {
            await API.ROOT.TENANTS.USERS.DISABLE_ALL(this.props.tenant)

            this.getUsers()
            this.openSnackBar(i18n.t('Users disabled successfully'), 'success')
        } catch (error) {
            this.openSnackBar(i18n.t('An error occurred while disabling the users'), 'error')
            this.setState({ loading: false })
        }
    }

    handleEnableAll = async () => {
        this.setState({ loading: true })

        try {
            await API.ROOT.TENANTS.USERS.ENABLE_ALL(this.props.tenant)

            this.getUsers()
            this.openSnackBar(i18n.t('Users enabled successfully'), 'success')
        } catch (error) {
            this.openSnackBar(i18n.t('An error occurred while enabling the users'), 'error')
            this.setState({ loading: false })
        }
    }

    handleCloseModal = closeAndUpdateAlerts => {
        if (closeAndUpdateAlerts === true){
            this.getUsers();
            this.setState({ openUserModal: false, editUser: null });
        } else {
            this.setState({ openUserModalDialog: true });
        }
    }

    handleUserModalDialogClose = flag => (flag === true
        ? this.setState({
            openUserModalDialog: false,
            editUser: null,
            openUserModal: false
        }) : this.setState({
            openUserModalDialog: false,
        })
    )

    openSnackBar = (message, type) => this.setState({
        showSnackBar: true,
        snackbar: {
            message,
            type
        }
    })

    closeSnackBar = () => this.setState({
        showSnackBar: false
    })

    handleEditUser = user => this.setState({ openUserModal: true, editUser: user })

    render() {
        const { classes, tenantUsersMode, tenant } = this.props;
        const { users: resources, order, orderBy, showSnackBar, snackbar } = this.state;
        const emptyRows = resources.length < 10 ? 10 - resources.length : 0;

        return (
            <Paper className={tenantUsersMode ? classes.tenantUsersPaper : classes.paper}>
                {localStorage.getItem('authority') === 'ADMIN' ?
                    <div className={tenantUsersMode ? classes.tentantHeader : ''}>
                        {tenantUsersMode && (
                            <div className={classes.tenantActionsButtons}>
                                <div onClick={this.handleDisableAll} className={classes.tenantUsersCreateUserButton}>
                                    <Typography variant="body2" classes={{body2: classes.createUserButtonText}}>
                                        {i18n.t('DISABLE ALL')}
                                    </Typography>
                                </div>
                                <div onClick={this.handleEnableAll} className={classes.tenantUsersCreateUserButton}>
                                    <Typography variant="body2" classes={{body2: classes.createUserButtonText}}>
                                        {i18n.t('ENABLE ALL')}
                                    </Typography>
                                </div>
                            </div>
                        )}
                        <div onClick={this.handleOpenModal} className={tenantUsersMode ? classes.tenantUsersCreateUserButton : classes.createUserButton} id="create-user-btn">
                            <AddIcon />
                            <Typography variant="body2" classes={{body2: classes.createUserButtonText}}>
                                {i18n.t('CREATE USER')}
                            </Typography>
                        </div>
                    </div>
                : null}
                <div className={`${classes.tableWrapper} users-list`}>
                    <Table className={classes.table} aria-labelledby="tableTitle">
                        <TableHead className={classes.head}>
                            <TableRow>
                                {this.columnData.map(column => {
                                    return (
                                        <TableCell
                                            key={column.id}
                                            numeric={column.numeric}
                                            padding="checkbox"
                                            sortDirection={orderBy === column.id ? order : false}
                                            >
                                            <CustomTableSortLabel
                                                active={orderBy === column.id}
                                                direction={order}
                                                >
                                                {column.label}
                                            </CustomTableSortLabel>
                                        </TableCell>
                                    );
                                }, this)}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.loading && (
                                <TableRow>
                                    <TableCell padding="checkbox" colSpan="4">
                                        <div style={{minWidth: 48, width: 48}}> <Loading /></div>
                                    </TableCell>
                                </TableRow>
                            )}
                            {resources.map(resource => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={resource.email}
                                        className={classes.tbodyRow}
                                    >
                                        <CustomTableCell padding="checkbox" className="email_column">{resource.email}</CustomTableCell>
                                        <CustomTableCell padding="checkbox" className="authority_column">{this.authorities[resource.authority]}</CustomTableCell>
                                        <CustomTableCell padding="checkbox"className="created_column">{moment(resource.createdAt).format('LLL')}</CustomTableCell>
                                        <CustomTableCell padding="checkbox" className="status_column">
                                            <CheckIcon className={classNames(resource.enabled ? classes.enableStatus : classes.disableStatus, classes.statusIcon)} />
                                        </CustomTableCell>
                                        {localStorage.getItem('authority') === 'ADMIN' && localStorage.getItem('userEmail') !== resource.email ?
                                            <CustomTableCell className="action_column" padding="none">
                                                {!tenantUsersMode && (
                                                    <IconButton
                                                        aria-label={i18n.t("Edit user")}
                                                        color="inherit"
                                                        key="edit-user"
                                                        disabled={this.state.loading}
                                                        className={classes.cardButton}
                                                        onClick={() => this.handleEditUser(resource)}
                                                        data-tip={i18n.t("Edit user")}
                                                    >
                                                        <EditIcon className={classes.cardButtonIcon} />
                                                        <ReactTooltip />
                                                    </IconButton>
                                                )}

                                                {tenantUsersMode && (
                                                    <IconButton
                                                        aria-label={i18n.t("Login as user")}
                                                        color="inherit"
                                                        key="login-as-user"
                                                        disabled={this.state.loading}
                                                        className={classes.cardButton}
                                                        onClick={() => this.handleLoginUser(resource)}
                                                        data-tip={i18n.t("Login as user")}
                                                    >
                                                        <EditIcon className={classes.cardButtonIcon} />
                                                        <ReactTooltip />
                                                    </IconButton>
                                                )}

                                                <IconButton
                                                    aria-label={i18n.t("Delete user")}
                                                    color="inherit"
                                                    key="delete-user"
                                                    disabled={this.state.loading}
                                                    className={classes.cardButton}
                                                    onClick={() => this.handleOpenUserDeleteDialog(resource)}
                                                    data-tip={i18n.t("Delete user")}
                                                >
                                                    <DeleteIcon className={classes.cardButtonIcon} />
                                                    <ReactTooltip />
                                                </IconButton>
                                            </CustomTableCell>
                                        :
                                            <CustomTableCell className="action_column" padding="none" />
                                        }
                                    </TableRow>
                                );
                            })}
                            {!this.state.loading && resources.length === 0 && (
                                <TableRow>
                                    <CustomTableCell colSpan={6}>{i18n.t('No users yet.')}</CustomTableCell>
                                </TableRow>
                            )}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                    <CustomTableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>

                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.openUserModal}
                    onClose={this.handleCloseModal}
                    disableBackdropClick={true}>

                    <div style={modalStyle} className={classes.modalPaper}>
                        <CloseIcon onClick={this.handleCloseModal} style={closeButtonStyle} />

                        <UserAdd
                            editUser={this.state.editUser}
                            handleCloseModal={this.handleCloseModal}
                            handleOpenModal={this.handleOpenModal}
                            openParentSnackBar={this.openSnackBar}
                            tenantUsersMode={tenantUsersMode || false}
                            tenant={tenant}
                        />

                        <Dialog aria-labelledby="dialog-text"
                            fullWidth={true}
                            maxWidth="xs"
                            open={this.state.openUserModalDialog}
                            onClose={this.handleUserModalDialogClose}>
                            <DialogTitle>
                                {i18n.t('Are you sure?')}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="dialog-text">
                                    {i18n.t('If you close all unsaved information will be lost.')}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button classes={{label: classes.deleteConfirm}}
                                    onClick={() => this.handleUserModalDialogClose(true)}>
                                    {i18n.t('Yes')}
                                </Button>
                                <Button classes={{label: classes.deleteCancel}} onClick={this.handleUserModalDialogClose}>
                                    {i18n.t('Cancel')}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </Modal>
                <SystemSnackbar
                    open={showSnackBar}
                    message={snackbar.message}
                    onClose={this.closeSnackBar}
                    variant={snackbar.type}
                    useFadeTransition={tenantUsersMode ? true : false}
                />
                <Dialog aria-labelledby="dialog-text"
                    fullWidth={true}
                    maxWidth="xs"
                    open={this.state.openUserDeleteModalDialog}
                    onClose={this.handleCloseUserDeleteDialog}>
                    <DialogTitle>
                        {i18n.t('Are you sure?')}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="dialog-text">
                            {i18n.t('If you delete, all the user information will be lost.')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={this.state.deleting || this.state.loading}
                            classes={{label: classes.deleteConfirm}}
                            onClick={() => this.handleCloseUserDeleteDialog(true)}
                        >
                            {i18n.t('Yes')}
                        </Button>
                        <Button
                            disabled={this.state.deleting || this.state.loading}
                            classes={{label: classes.deleteCancel}}
                            onClick={this.handleCloseUserDeleteDialog}
                        >
                            {i18n.t('Cancel')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Paper>
        );
    }
}

Overview.propTypes = {
    classes: PropTypes.object.isRequired,
    tenantUsersMode: PropTypes.bool,
};

export default withStyles(styles)(Overview);
