import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import moment from 'moment';

import IconButton from 'material-ui/IconButton';
import EditIcon from 'material-ui-icons/Edit';
import EmailIcon from 'material-ui-icons/Email';
import DeleteIcon from 'material-ui-icons/Delete';
import GraphIcon from 'material-ui-icons/Timeline';
import MaterialButton from 'material-ui/Button';
import Dialog, {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from 'material-ui/Dialog';

// Translation
import i18n from '../../i18n';

import { API } from '../../components/api';

import { Button } from '../../elements/button';
import { Loading } from '../../elements/loading';
import {getBreadcrumbsTrailFromHierarchy, getMessageContext} from './events-list';
import { granularitiesContextPT_BR } from './constants';

import './event-details.css';

const styles = theme => ({
    cardButton: {
        height: '30px',
        width: '30px',
    },
    cardButtonIcon: {
        color: '#353645',
        fontSize: '20px',
    },
    deleteConfirm: {
        color: '#6ab42d',
    },
    deleteCancel: {
        color: '#c62828',
    },
});

const getAlertDescription = event => {
    if (!event.alert) {
        return i18n.t('Sensor {{sensor}} was {{status}}.', {
            sensor: `${event.systemAlert.sensorId}-${event.systemAlert.channel}`,
            status: event.systemAlert.type === 'STATUS_ONLINE' ? i18n.t('connected') : i18n.t('disconnected')
        })
    }

    const message = JSON.parse(event.message);
    if (event.alert.name && event.message) {
        const eventMessageText = i18n.t(message.key,
            {
                ...message.values,
                context: getMessageContext(event, granularitiesContextPT_BR)
            }
        );
        return ` ${event.alert.name} -  ${eventMessageText}`;
    }

    return '';
}

class EventDetails extends PureComponent {
    state = {
        loadingEvent: true,
        error: false,
        event: {},
        deleteDialogOpen: false,
    }

    componentDidMount() {
        const { event } = this.props

        if (event && event.id) {
            this.getAlertEvents(event)

            if (!event.read) {
                this.changeEventRead(true)
            }
        }
    }

    changeEventRead = (read) => {
        this.setState({
            loadingEvent: true
        })

        const type = this.props.event.alert
            ? 'USER'
            : 'SYSTEM';

        API.ALERTS.EVENTS.EDIT([{id: this.props.event.id, type, read}]).then(() => {
            window.dispatchEvent(new CustomEvent('updateAlertEventsCount'))
            this.getAlertEvents(this.props.event)
        }).catch(error => this.setState({
            loadingEvent: false,
            error: i18n.t("An error occurred while updating the event")
        }));
    }

    deleteEvent = () => {
        this.setState({
            loadingEvent: true
        })

        const type = this.props.event.alert
            ? 'USER'
            : 'SYSTEM';

        API.ALERTS.EVENTS.DELETE([{ id: this.props.event.id, type }]).then(() => {
            this.props.handleCloseModal()
        }).catch(error => this.setState({
            loadingEvent: false,
            error: i18n.t('Could not delete the event.')
        }));
    }

    handleDeleteDialogClose = flag => {
        if (!flag) {
            return this.setState({ deleteDialogOpen: false })
        }

        this.deleteEvent()
    }

    handleDeleteDialogOpen = () => {
        this.setState({ deleteDialogOpen: true });
    }

    getAlertEvents = event => {
        this.setState({
            loadingEvent: true,
            error: false
        })

        const requestUrl = event.alert
            ? API.ALERTS.EVENTS.DETAILS
            : API.ALERTS.EVENTS.SYSTEM.DETAILS;

        requestUrl(event.id).then(({ data }) => {
            if (data && data.id) {
                return this.setState({
                    event: data,
                    loadingEvent: false,
                })
            }

            this.setState({
                loadingEvent: false
            })
        }).catch(error => {
            this.setState({
                loadingEvent: false,
                error: i18n.t('Could not load event details.')
            })
        });
    }

    goToAlert = () => window.open(`/alerts?id=${this.props.event.alert.id}`, '_blank')

    handleGoToGraph = e => {
        e.stopPropagation();
        const { event } = this.props

        if (!event.alert) {
            return
        }

        let url = `?groupId=${event.alert.groupId}`
        const alertDay = moment(event.timestamp);

        if (event.alert.alertGranularity === 'MINUTE' || event.alert.alertGranularity === 'HOUR') {
            url += `&date=${alertDay.format('YYYY-MM-DD')}&period=day`;
        } else if (event.alert.alertGranularity === 'DAY') {
            url += `&date=${alertDay.format('YYYY-MM-DD')}&period=week`;
        } else if (event.alert.alertGranularity === 'WEEK') {
            url += `&date=${alertDay.format('YYYY-MM-DD')}&period=month`;
        } else {
            url += `&startDate=${alertDay.subtract(3, 'month').format('YYYY-MM-DD')}&endDate=${alertDay.format('YYYY-MM-DD')}&period=custom`;
        }

        if (event.alert.sensorId && event.alert.channel) {
            url += `&sensorId=${event.alert.sensorId}&channel=${event.alert.channel}`;
        }

        window.open(event.alert.category === 'ABSOLUTE_COST' ? `/analysis/cost${url}` : `/analysis/consumption${url}`, '_blank');
    }


    render () {
        const { classes } = this.props;
        const { event, loadingEvent, error } = this.state;

        if (loadingEvent) {
            return <Loading />
        }

        if (!event.id || error) {
            return (
                <div className="error">
                    {error}
                </div>
            )
        }

        const last = moment(event.timestamp);
        return (
            <section id="event-details">
                <div className="top-bar">
                    {localStorage.getItem('authority') === 'ADMIN' && (
                        <IconButton
                            aria-label={i18n.t("Delete event")}
                            color="inherit"
                            key="event"
                            className={`${classes.cardButton} delete-button`}
                            onClick={this.handleDeleteDialogOpen}
                        >
                            <DeleteIcon className={classes.cardButtonIcon} />
                        </IconButton>
                    )}
                    <IconButton
                        aria-label={i18n.t("Edit event")}
                        color="inherit"
                        key="edit"
                        className={`${classes.cardButton} status-button`}
                        onClick={() => this.changeEventRead(!event.read)}
                    >
                        <EmailIcon className={classes.cardButtonIcon} />
                    </IconButton>
                    {event.alert && event.alert.category !== 'ABSOLUTE_ACTIVE_POWER' && (
                        <IconButton
                            aria-label={i18n.t("Show event")}
                            color="inherit"
                            key="graphh"
                            className={`${classes.cardButton} show-button`}
                            onClick={this.handleGoToGraph}
                        >
                            <GraphIcon className={classes.cardButtonIcon} />
                        </IconButton>
                    )}
                </div>
                <div className="header">
                    {getBreadcrumbsTrailFromHierarchy(event)}
                </div>
                <p>
                    {i18n.t('Alert:')}
                    <strong>
                        {getAlertDescription(event)}
                    </strong>
                </p>
                <p>
                    {i18n.t('Last Triggered:')}
                    <strong> {last.format('LLL')}</strong>
                </p>

                <p>{i18n.t('History')}</p>
                <div className="table">
                    <div className="thead">
                        <div className="row">
                            <div className="column">
                                {i18n.t('Date')}
                            </div>
                            <div className="column">
                                {i18n.t('Hour')}
                            </div>
                        </div>
                    </div>
                    <div className="tbody">
                        {event.alertHistory.map((time, i) => (
                            <div className="row" key={`${time}_${i}`}>
                                <div className="column">
                                    {moment(time).format('LL')}
                                </div>
                                <div className="column">
                                    {moment(time).format('LT')}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <p>
                    {i18n.t('Alert name:')}
                    <strong> {event.alert
                            ? event.alert.name
                            : event.systemAlert.type === 'STATUS_ONLINE'
                                ? i18n.t('Online Sensor System Alert')
                                : i18n.t('Offline Sensor System Alert')
                        }
                    </strong>
                </p>
                {event.alert && (
                    <p>
                        {i18n.t('Created at:')}
                        <strong> {moment(event.alert.createdAt).format('LL')}</strong>
                    </p>
                )}
                {event.alert && (
                    <p>
                        {i18n.t('Alert created by:')}
                        <strong> {event.alert.createdByUser}</strong>
                    </p>
                )}
                {event.alert && localStorage.getItem('authority') === 'ADMIN' && (
                    <div className="footer">
                        <Button
                            action="submit"
                            click={this.goToAlert}
                            label={(
                                <div>
                                    <EditIcon /> {i18n.t('EDIT ALERT')}
                                </div>
                            )}
                        />
                    </div>
                )}
                <Dialog aria-labelledby="dialog-text"
                    fullWidth={true}
                    maxWidth="xs"
                    open={this.state.deleteDialogOpen}
                    onClose={() => this.handleDeleteDialogClose()}>
                    <DialogTitle>
                        {i18n.t('Are you sure?')}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="dialog-text">
                            {i18n.t("If you delete you won't see this event anymore.")}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <MaterialButton classes={{label: classes.deleteConfirm}}
                            onClick={() => this.handleDeleteDialogClose(true)}>
                            {i18n.t('Yes')}
                        </MaterialButton>
                        <MaterialButton classes={{label: classes.deleteCancel}} onClick={() => this.handleDeleteDialogClose()}>
                            {i18n.t('Cancel')}
                        </MaterialButton>
                    </DialogActions>
                </Dialog>
            </section>
        );
    }
}

EventDetails.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EventDetails);
