import React from 'react';
import Table, { TableBody, TableCell, TableHead, TableRow } from 'material-ui/Table';
import moment from 'moment';

import { kwhFormat } from './cost-simulation';

import i18n from '../../../i18n';

const detailsDateFormatter = (day, { startDate, endDate }, periodFilter) => {
    if (periodFilter === 'day'){
        return moment(day).format('LT');
    }else if (periodFilter === 'week' || periodFilter === 'month'){
        return moment(day).format('LL');
    }else if (periodFilter === 'custom'){
        const diffDays = endDate.endOf('day').diff(startDate.startOf('day'), 'days', true)

        if (diffDays <= 2 ) {
            return moment(day).format('llll');
        }

        return moment(day).format('LL');
    }
}

const CostSimulationResumeTable = ({
    compare,
    costFormat,
    currency,
    dateRangePicker,
    getColor,
    getGranularity,
    periodFilter,
    tariffsMap
}) => {

    const periodsText = {
        day: i18n.t('24 Hours'),
        week: i18n.t('1 Week'),
        month: i18n.t('1 Month'),
        custom: i18n.t('Custom'),
    }

    return (
        <div className="tables">
            <Table className="chart-table">
                <TableHead>
                    <TableRow>
                        <TableCell>{i18n.t('Tariff')}</TableCell>
                        <TableCell>{i18n.t('Total Cost')}</TableCell>
                        <TableCell>{i18n.t('Total Consumption')}</TableCell>
                        <TableCell>{periodsText[periodFilter]}</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {compare.map((obj, index) => (
                        <TableRow key={index}>
                            <TableCell>
                                <span className="compare-colored-circle"
                                    style={{background: getColor(index)}} />
                                {obj.filterTariff && obj.filterTariff.id &&  tariffsMap[obj.filterTariff.id]}
                            </TableCell>
                            <TableCell>
                                {costFormat(obj.filterTariff ? obj.filterTariff.currency : currency, obj.totalCost)}
                            </TableCell>
                            <TableCell>
                                {obj.total && obj.total >= 0 ? `${kwhFormat(obj.total)} ${i18n.t('kWh')}` : '-'}
                            </TableCell>
                            <TableCell />
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Table className="details-table">
                <TableHead>
                    <TableRow>
                        <TableCell>{i18n.t('Max')}</TableCell>
                        <TableCell>{i18n.t('Min')}</TableCell>
                        <TableCell>{i18n.t('Avg')}</TableCell>
                        <TableCell>{getGranularity()}</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {compare.map((obj, index) => (
                        <TableRow key={index}>
                            <TableCell>
                                <div>
                                    {obj.max && obj.max.cost !== null && obj.max.cost >= 0
                                        ? costFormat(obj.filterTariff ? obj.filterTariff.currency : currency , obj.max.cost)
                                        : '-'
                                    }
                                    <small>
                                        {obj.max && obj.max.dayAndHour
                                            ? detailsDateFormatter(obj.max.dayAndHour, dateRangePicker, periodFilter)
                                            : '-'
                                        }
                                    </small>
                                </div>
                            </TableCell>
                            <TableCell>
                                <div>
                                    {obj.min && obj.min.cost !== null && obj.min.cost >= 0
                                        ? costFormat(obj.filterTariff ? obj.filterTariff.currency : currency , obj.min.cost)
                                        : '-'
                                    }
                                    <small>
                                        {obj.min && obj.min.dayAndHour
                                            ? detailsDateFormatter(obj.min.dayAndHour, dateRangePicker, periodFilter)
                                            : '-'
                                        }
                                    </small>
                                </div>
                            </TableCell>
                            <TableCell>
                                {obj.average && obj.average >= 0 ? costFormat(obj.filterTariff ? obj.filterTariff.currency : currency , obj.average) : '-'}
                            </TableCell>
                            <TableCell />
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    )
}

export default CostSimulationResumeTable;
