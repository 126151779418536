import React, { PureComponent } from 'react';
import Select from 'react-select';
import { withStyles } from 'material-ui/styles';
import { injectIntl, intlShape } from 'react-intl';
import Typography from 'material-ui/Typography';
import AddIcon from 'material-ui-icons/AddCircleOutline';
import CloseIcon from 'material-ui-icons/Close';
import Modal from 'material-ui/Modal';
import reactStrReplace from 'react-string-replace';

import i18n from '../../i18n';

import { API } from '../../components/api';
import { Title } from '../../elements/title';
import { Button } from '../../elements/button';
import { Input as VInput } from '../../elements/forms/input';
import SystemSnackbar from '../../elements/material-ui/SystemSnackbar'
import { getSensorStructured } from '../../components/utils'
import ReportAddRecipient from './report-add-recipient';

import 'react-select/dist/react-select.css';
import './report-add.css';

const modes = { add: 'add', edit: 'edit' };

const initialState = {
    addingEmail: false,
    fieldsWithError: {},
    groupId: null,
    loading: false,
    loadingGroups: true,
    loadingPreview: false,
    mode: modes.add,
    groups: [],
    recipient: '',
    showSnackBar: false,
    snackbar: {
        type: 'warning',
        message: ''
    },
    report: {
        type: 'DEFAULT',
        name: '',
        trigger: 5,
        period: 4,
        recipients: [],
    }
};

const modalStyle = {
    backgroundColor: '#FFF',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    maxHeight: '90%',
    overflowY: 'auto',
}

const closeModalButtonStyle = {
    cursor: 'pointer',
    padding: '10px',
    position: 'absolute',
    right: 0,
    zIndex: 1,
}

const closeButtonStyle = {
    cursor: 'pointer',
}

const styles = theme => ({
    paper: {
        '&:focus': {
            outline: 'none'
        }
    },
    typographyNoRecipients: {
        fontSize: '13px'
    }
})

let submitting = false;
const periods = () => [{
    value: 1,
    label: i18n.t('Daily'),
    periodQuantity: 1,
    periodGranularity: 'DAY'
}, {
    value: 2,
    label: i18n.t('Weekly'),
    periodQuantity: 1,
    periodGranularity: 'WEEK'
}, {
    value: 3,
    label: i18n.t('Monthly'),
    periodQuantity: 1,
    periodGranularity: 'MONTH'
}, {
    value: 4,
    label: i18n.t('{{period_quantity}} Months', { period_quantity: 3}),
    periodQuantity: 3,
    periodGranularity: 'MONTH'
}]

const triggers = () => [{
    value: 1,
    label: i18n.t('Day'),
    triggerQuantity: 1,
    triggerGranularity: 'DAY'
}, {
    value: 2,
    label: i18n.t('Week'),
    triggerQuantity: 1,
    triggerGranularity: 'WEEK'
}, {
    value: 3,
    label: i18n.t('{{period_quantity}} Weeks', {period_quantity: 2 }),
    triggerQuantity: 2,
    triggerGranularity: 'WEEK'
}, {
    value: 4,
    label: i18n.t('Month'),
    triggerQuantity: 1,
    triggerGranularity: 'MONTH'
}, {
    value: 5,
    label: i18n.t('{{period_quantity}} Months', {period_quantity: 2 }),
    triggerQuantity: 2,
    triggerGranularity: 'MONTH'
}, {
    value: 6,
    label: i18n.t('{{period_quantity}} Months', {period_quantity: 3 }),
    triggerQuantity: 3,
    triggerGranularity: 'MONTH'
}]

class ReportAdd extends PureComponent {
    constructor (props) {
        super(props);

        const report = props.editReport;

        this.state = {
            ...initialState,
            mode: report && report.id && report.id > 0 ? modes.edit : modes.add,
        };
        window.openModalSnackBar = this.openSnackBar;
    }

    componentWillMount = () => {
        API.GROUPS.GET().then(({ data }) => {
            if (data && data.length) {
                if (this.props.editReport && this.props.editReport.id) {
                    this.setEditableReport(data)
                }

                return this.setState({
                    loadingGroups: false,
                    groups: data.map(group => ({
                        ...group,
                        label: group.name,
                        value: group.id
                    }))
                })
            }

            this.setState({
                loadingGroups: false
            })
        }).catch(error => this.handleLoadError(
            { loadingGroups: false },
            'Could not load tariffs.',
            error
        ));
    }

    loadErrorMessage = i18n.t("An error occurred while retrieving data.");

    getSaveData = ({ period, trigger, subgroup, groupId, sensorKey, ...report }, groups) => {
        const foundPeriod = periods().find(data => data.value === period)
        const foundTrigger = triggers().find(data => data.value === trigger)

        const foundGroup = groups.find(({ id }) => id === groupId)
        const foundSubgroup = foundGroup.subGroups.find(({ id }) => id === subgroup)
        const subSubGroup = subgroup && foundSubgroup.subGroups[0]

        return {
            ...report,
            groupId: report.sensorId ? subSubGroup.id : subgroup ? subgroup : groupId,
            ...foundPeriod ? {
                periodQuantity: foundPeriod.periodQuantity,
                periodGranularity: foundPeriod.periodGranularity
            } : {},
            ...foundTrigger ? {
                triggerQuantity: foundTrigger.triggerQuantity,
                triggerGranularity: foundTrigger.triggerGranularity
            } : {}
        }
    }

    setEditableReport = groups => {
        const { editReport } = this.props
        if (!editReport || !editReport.id) {
            return
        }
        const foundTrigger = triggers().find(trigger =>
            trigger.triggerQuantity === editReport.triggerQuantity &&
            trigger.triggerGranularity === editReport.triggerGranularity
        )
        const foundPeriod = periods().find(period =>
            period.periodQuantity === editReport.periodQuantity &&
            period.periodGranularity === editReport.periodGranularity
        )

        const foundGroup = groups.find(group => (
            !!editReport.breadCrumbs.parentGroupName &&
            !!group.subGroups.some(subgroup => editReport.sensorId
                ? subgroup.subGroups.some(({ id }) => id === editReport.groupId)
                : subgroup.id === editReport.groupId
            )
        ) || group.id === editReport.groupId)

        const foundSubgroup = foundGroup && !!editReport.breadCrumbs.parentGroupName &&
            foundGroup.subGroups.find(subgroup => editReport.sensorId
                ? subgroup.subGroups.some(({ id }) => id === editReport.groupId)
                : subgroup.id === editReport.groupId
            )

        this.setState({
            report: {
                ...this.state.report,
                ...editReport,
                recipients: editReport.recipients && editReport.recipients.length ? editReport.recipients : [],
                trigger: foundTrigger ? foundTrigger.value : null,
                period: foundPeriod ? foundPeriod.value : null,
                groupId: foundGroup.id,
                subgroup: foundSubgroup.id,
                sensorId: editReport.sensorId,
                sensorKey: editReport.sensorId && editReport.channel
                    ? `${editReport.sensorId}-${editReport.channel}`
                    : null
            }
        })
    }

    handleLoadError = (state, message, error) => {
        state.loadErrorMessage = this.loadErrorMessage;
        this.setState(state);

        console.error(this.loadErrorMessage + " (" + message + ")");
        if (error)
            console.error(error);
    }

    reset = () => this.setState(initialState)

    back = () => this.setState({
        errorMessage: ''
    })

    toggleAddEmail = () => this.setState({
        recipient: '',
        fieldsWithError: {},
        addingEmail: !this.state.addingEmail
    })

    changeRecipient = ({ target }) => this.setState({
        recipient: target.value
    })

    change = ({ target }) => this.setState({
        report: {
            ...this.state.report,
            [target.name]: target.value,
        }
    })

    changeGroup = group => this.setState({
        report: {
            ...this.state.report,
            groupId: group ? group.value : null,
            subgroup: null,
            channel: null,
            sensorId: null,
            sensorKey: null
        }
    })

    changeSubgroup = subgroup => this.setState({
        report: {
            ...this.state.report,
            subgroup: subgroup ? subgroup.id : null,
            sensorId: null,
            sensorKey: null,
            channel: null
        }
    })

    changeSensor = sensor => this.setState({
        report: {
            ...this.state.report,
            sensorId: sensor ? sensor.id : null,
            sensorKey: sensor ? sensor.key : null,
            channel: sensor ? sensor.channel : null
        }
    })

    changePeriod = period => period && period.value && this.setState({
        report: {
            ...this.state.report,
            period: period.value
        }
    })

    changeTrigger = trigger => trigger && trigger.value && this.setState({
        report: {
            ...this.state.report,
            trigger: trigger.value
        }
    })

    scrollIntoView = (elem) => {
        elem.scrollIntoView ?
            elem.scrollIntoView({behavior: 'smooth'}) :
            document.getElementById('add-group').parentElement.scrollTop = 0;
    }

    removeRecipient = index => this.setState({
        report: {
            ...this.state.report,
            recipients: this.state.report.recipients.filter((email, i) => i !== index)
        }
    })

    addRecipient = recipient => this.setState({
        addingEmail: false,
        report: {
            ...this.state.report,
            recipients: [
                ...this.state.report.recipients,
                recipient
            ]
        }
    })

    isValid = () => {
        const fieldsWithError = {};
        const { report } = this.state

        if (!report.name) {
            fieldsWithError.name = i18n.t('Is required');
        }

        if (!report.groupId) {
            fieldsWithError.groupId = i18n.t('Is required');
        }

        this.setState({
            fieldsWithError: fieldsWithError,
            loading: false
        });

        return !Object.keys(fieldsWithError).length;
    }

    send = () => {
        const { report, groups } = this.state
        const action = report.id ? 'EDIT' : 'ADD'

        API.REPORTS[action](this.getSaveData(report, groups)).then(response => {
            this.openSnackBar(report.id
                ? i18n.t('Report saved successfully')
                : i18n.t('Report created successfully')
            , 'success');

            this.props.handleCloseModal(true);
            this.setState(initialState);
            submitting = false
        }).catch(error => {
            this.setState({ loading: false });
            this.openSnackBar(i18n.t('An error occurred while creating you report.'), 'error');
            submitting = false
        })
    }

    submit = (e) => {
        if (submitting) {
            return
        }

        submitting = true;
        e.preventDefault();
        e.target.querySelector('button[type="submit"]').blur();

        this.setState({
            loading: true,
            feedbackMessage: '',
            errorMessage: '',
            loadErrorMessage: ''
        });

        if (this.isValid()) {
            this.send()
        } else {
            submitting = false
        }
    }

    reportLivePreview = () => {
        const { report, groups } = this.state
        if (!report.groupId) {
            return this.setState({
                fieldsWithError: {
                    groupId: i18n.t('Is required')
                }
            });
        }

        localStorage.setItem('reportData', JSON.stringify(this.getSaveData(report, groups)));
        window.open(`/reports/preview`, '_blank', 'toolbar=no');
    }

    openSnackBar = (message, type) => this.setState({
        showSnackBar: true,
        snackbar: {
            message,
            type
        }
    })

    closeSnackBar = () => this.setState({
        showSnackBar: false
    })

    render () {
        const { classes } = this.props
        const {
            fieldsWithError,
            groups,
            loading,
            loadingGroups,
            loadingPreview,
            mode,
            report,
            showSnackBar,
            snackbar,
            submitting,
        } = this.state

        const foundGroup = groups.find(group => group.id === report.groupId)
        const subgroups = foundGroup ? foundGroup.subGroups : []

        const foundSubgroup = foundGroup && foundGroup.subGroups.find(group => group.id === report.subgroup)
        const sensors = foundSubgroup ? foundSubgroup.subGroups.reduce((prev, curr) => [
            ...prev,
            ...curr.sensors ? curr.sensors : [],
        ], []) : []

        return (
            <section id="add-report">
                <Title
                    element="h4"
                    text={(mode === modes.edit) ? i18n.t('Edit Report') : i18n.t('New Report')}
                />

                <form action="#" onSubmit={this.submit}>
                    <div ref={element => this.formDiv = element} className="form-content">
                        <div className="form-sections">
                            <VInput
                                className="input-name"
                                change={this.change}
                                disabled={loading}
                                hasError={fieldsWithError.name}
                                value={report.name}
                                name="name"
                                placeholder={i18n.t("Report Name")}
                                type="text"
                                required
                            />
                            <div className="filters">
                                <Title
                                    element="h6"
                                    text={i18n.t("Filters")}
                                />

                                <Select
                                    name="groupId"
                                    value={report.groupId}
                                    onChange={this.changeGroup}
                                    disabled={loadingGroups || !groups.length}
                                    isLoading={loadingGroups}
                                    className={fieldsWithError.groupId ? 'error' : null}
                                    valueKey="value"
                                    labelKey="label"
                                    options={groups}
                                    placeholder={i18n.t("Group")}
                                    noResultsText={i18n.t('No results found')}
                                />

                                <Select
                                    name="subgroup"
                                    value={report.subgroup}
                                    onChange={this.changeSubgroup}
                                    disabled={!report.groupId || !subgroups.length}
                                    isLoading={loadingGroups}
                                    valueKey="id"
                                    labelKey="name"
                                    options={subgroups}
                                    placeholder={i18n.t("Subgroup - Optional")}
                                    noResultsText={i18n.t('No results found')}
                                />

                                <Select
                                    name="sensorKey"
                                    value={report.sensorKey}
                                    onChange={this.changeSensor}
                                    disabled={!report.subgroup || !sensors.length}
                                    isLoading={loadingGroups}
                                    valueKey="key"
                                    labelKey="label"
                                    options={sensors.map(getSensorStructured)}
                                    placeholder={i18n.t("Sensor - Optional")}
                                    noResultsText={i18n.t('No results found')}
                                />
                            </div>
                        </div>
                        <div className="form-sections">
                            <div className="recipient-header">
                                <Title
                                    element="h6"
                                    text={i18n.t("Recipients")}
                                />
                                <div onClick={this.toggleAddEmail}>
                                    <AddIcon />
                                    <Typography variant="body2" classes={{body2: classes.createReportButtonText}}>
                                        {i18n.t('ADD E-MAIL')}
                                    </Typography>
                                </div>
                            </div>

                            {!report.recipients.length && (
                                <Typography
                                    variant="title"
                                    color="textSecondary"
                                    classes={{title: classes.typographyNoRecipients}}
                                >
                                    {i18n.t('There is no recipients added')}
                                </Typography>
                            )}

                            {!!report.recipients.length && (
                                <div className="recipients-content">
                                    {report.recipients.map((email, index) => (
                                        <div key={email} className="recipient">
                                            {email}
                                            <CloseIcon
                                                onClick={() => this.removeRecipient(index)}
                                                style={closeButtonStyle}
                                            />
                                        </div>
                                    ))}
                                </div>
                            )}

                        </div>
                    </div>
                    <div className="trigger-section">
                        <Title
                            element="h6"
                            text={i18n.t("Trigger")}
                        />
                        <div className="text">
                            {reactStrReplace(i18n.t('Send a {{period}} report at the end of every {{trigger}}', { period: 'period', trigger: 'trigger'}), /(period|trigger)/g, match => {
                                switch(match) {
                                    case 'period':
                                        return (
                                            <Select
                                                clearable={false}
                                                name="period"
                                                value={report.period}
                                                onChange={this.changePeriod}
                                                valueKey="value"
                                                labelKey="label"
                                                options={periods()}
                                                noResultsText={i18n.t('No results found')}
                                            />
                                        )
                                    case 'trigger':
                                        return (
                                            <Select
                                                clearable={false}
                                                name="trigger"
                                                value={report.trigger}
                                                onChange={this.changeTrigger}
                                                valueKey="value"
                                                labelKey="label"
                                                options={triggers()}
                                                noResultsText={i18n.t('No results found')}
                                            />
                                        )
                                    default:
                                        return '';
                                }
                            })}
                        </div>
                    </div>
                    <div className="buttons-content">
                        <Button
                            action="preview"
                            disabled={loadingPreview}
                            label={i18n.t("Preview")}
                            click={this.reportLivePreview}
                        />

                        <Button
                            type="submit"
                            action="submit"
                            disabled={submitting}
                            label={(mode === modes.edit) ? i18n.t('Save Report') : i18n.t('Add Report')}
                        />
                    </div>
                </form>
                <SystemSnackbar
                    open={showSnackBar}
                    message={snackbar.message}
                    onClose={this.closeSnackBar}
                    variant={snackbar.type}
                />
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.addingEmail}
                    onClose={this.toggleAddEmail}
                    disableBackdropClick={true}>

                    <div style={modalStyle} className={classes.paper}>
                        <CloseIcon onClick={this.toggleAddEmail} style={closeModalButtonStyle} />
                        <ReportAddRecipient
                            recipients={report.recipients}
                            cancel={this.toggleAddEmail}
                            addRecipient={this.addRecipient}
                        />
                    </div>
                </Modal>
            </section>
        );
    }
}

ReportAdd.propTypes = {
    intl: intlShape.isRequired,
};

export {
    periods,
    triggers
}
export default withStyles(styles)(injectIntl(ReportAdd));
