import React from 'react';
import axios from 'axios';
import d3 from 'd3';
import moment from 'moment-timezone';
import { injectIntl, intlShape } from 'react-intl'
import { withStyles } from 'material-ui/styles';
// import classNames from 'classnames';

import {API} from '../../../components/api';
import {adjustJSONToValidJSON, getSensorStructured} from '../../../components/utils';
// import {adjustJSONToValidJSON, getSensorStructured, isEstimatedData} from '../../components/utils';

// Translation
import i18n from '../../../i18n';

import Button from 'material-ui/Button';
import Table, {TableBody, TableCell, TableHead, TableRow} from 'material-ui/Table';
import {Text} from '../../../elements/text';
import {nest} from 'd3-collection'
import {utcDays} from 'd3-time'
import {format} from 'd3-format'
import {sum, mean, range} from 'd3-array'
import {scaleLinear} from 'd3-scale'
import DatePicker from '../filter-date-picker'
import SystemSnackbar from '../../../elements/material-ui/SystemSnackbar'
import GroupCompare from '../../../elements/material-ui/GroupCompare'
import { Button as VButton } from '../../../elements/button';
import { Loading } from '../../../elements/loading';

import IconButton from 'material-ui/IconButton';
import ExpandMoreIcon from 'material-ui-icons/ArrowDropDown';
import ArrowLeftIcon from 'material-ui-icons/KeyboardBackspace';

import 'nvd3/build/nv.d3.min.css'
import './dashboard.css'
import './heatmap.css'
import 'react-select/dist/react-select.css';

const styles = {
  switchBase: {
    '& + $bar': {
        backgroundColor: 'green',
    },
    '&$checked': {
      '& + $bar': {
        backgroundColor: 'green',
      },
    },
  },
  bar: {},
  checked: {},
};

const kwhFormat = (d) => {
    if (d < 1){
        return format(".2r")(d);
    }else{
        return format(".2f")(d);
    }
}

const baseURLDefault = '/analysis/consumption/heatmap';

const colors = ["#5B8D3A", "#FBD55A", "#E5C01C", "#E0A138", "#F10421"];

class Heatmap extends React.Component {
    constructor (props) {
        super(props);
        let today = moment();

        this.state = {
            compare: [{
                filterGroupOptions: [],
                filterSubgroupOptions: [],
                filterSensorOptions: [],
            }],
            loadingGroups: true,
            loadingData: false,
            loadingTags: true,
            periodFilter: 'day',
            filterGroup: null,
            filterSubgroup: null,
            filterSensor: null,
            noDataChartMsg: this.loadingChartMessage,
            tags: [],
            tagsMap: {},
            groups: [],
            xTicks: [],
            xDomain: [],
            data:[],
            total: 0,
            totals: [],
            min: null,
            mins: [],
            max: null,
            maxes: [],
            average: 0,
            averages: [],
            datePicker: {
                open: false,
                endDate: today,
                startDate: today,
                data: {
                    endDate: today,
                    focusedInput: 'startDate',
                    startDate: today,
                },
            },
            dateRangePicker: {
                open: false,
                endDate: today,
                startDate: today.clone().subtract(2, 'days'),
                data: {
                    endDate: today,
                    focusedInput: 'endDate',
                    startDate: today.clone().subtract(2, 'days'),
                },
            },
            level: 1,
            allPeriods: [],
            loadingLevel: true,
        };

        this.maximumDate = today.clone().add(1, 'day').endOf('day');
        this.willLoadData = true;
        this.compareIndexOpen = undefined;
    }

    componentWillMount = async () => {
        try {
            const { data } = await API.LABELS.GET()
            if (data) {
                this.setState({
                    tagsMap: data.reduce((prev, cur) => ({ ...prev, [cur.id]: cur.name }), {}),
                    tags: data,
                    loadingTags: false,
                });

            }else{
                this.handleLoadError({loadingTags: false}, i18n.t("Could not load the tags"))
            }
        } catch (error) {
            this.handleLoadError({loadingTags: false}, i18n.t("Could not load the tags"))
        }

        API.GROUPS.GET().then((response) => {
            if (response.data && response.data.length > 0){
                this.groupsNamesMap = this.createGroupsNamesMap(response.data);
                this.sensorsPhasesNamesMap = this.createSensorsPhasesNamesMap(response.data);
                this.setState({
                    groups: response.data,
                    loadingGroups: false,
                    filterGroupOptions: response.data.map(group => { return {name: group.name, id: group.id} }),
                }, () => {this.checkGeneralChangesOnComponent()});
            }else if (response.data && response.data.length === 0){
                this.setState({
                    loadingGroups: false,
                    noDataChartMsg: this.noGroupChartMessage,
                });
            }else{
                this.handleLoadError({loadingGroups: false}, i18n.t("Could not load groups data"))
            }
        }).catch((error) => {
            this.handleLoadError({loadingGroups: false}, i18n.t("Could not load groups data"), error)
        });
    }

    componentWillReceiveProps (nextProps) {
        this.checkGeneralChangesOnComponent(nextProps);
    }

    componentWillUnmount () {
        document.body.removeEventListener('click', this.onOutsideCompareClick);
        clearTimeout(this.onOutsideCompareClickTimer);
    }

    loadErrorMessage = i18n.t("An error occurred while retrieving data");
    loadingChartMessage = i18n.t("Loading data");
    noDataChartMessage = i18n.t("There is no data available for the selected criteria");
    noGroupChartMessage = i18n.t("There is no data available as no group has been set up");

    periodOptions = [
        {label: i18n.t('Day'), value: 'day'},
        // {label: 'Week', value: 'week'},
        // {label: 'Month', value: 'month'},
        // {label: 'Custom', value: 'custom'},
    ];

    awaitChartData = (state, promises) => {
        axios.all(promises).then((responses) => {
            state.data = [];
            state.totals = [];
            state.totalCosts = [];
            state.mins = [];
            state.maxes = [];
            state.averages = [];
            responses.forEach((response, index) => {
                if (response.data) {
                    response.data.data = response.data.data ? this.ensurePositiveValues(response.data.data) : [];
                    response.data.data = response.data.data ? this.createDatetime(response.data.data) : [];

                    let data = response.data;

                    let averages = state.averages ? state.averages.concat(response.data.avgConsumption) : [].concat(response.data.avgConsumption);
                    averages = averages.filter(s => s);
                    let totals = state.totals ? state.totals.concat(response.data.totalConsumption) : [].concat(response.data.totalConsumption);
                    totals = totals.filter(s => s);

                    const max = response.data.data.reduce((max, d) => {
                        return (d.consumption && d.consumption > max) ? d.consumption : max;
                    }, response.data.data[0] ? response.data.data[0].consumption : 0);

                    let min = response.data.data.reduce((min, d) => {
                        return (d.consumption && d.consumption < min) ? d.consumption : min;
                    }, response.data.data[0] ? response.data.data[0].consumption : 0);

                    let mins;
                    if (!min || min < 0){
                        mins = state.mins && state.mins.length ? state.mins : [0];
                    }else{
                        mins = state.mins && state.mins.length ? state.mins.concat(min) : [min];
                    }
                    const maxes = state.maxes ? state.maxes.concat(max) : [].concat(max);

                    //order by consumption desc
                    const orderedData = (state.data)
                        ? state.data.concat(data).sort((a, b) => b.totalConsumption - a.totalConsumption)
                        : [].concat(data);

                    const allPeriods = this.getAllPeriods();

                    orderedData.forEach(groupOrSensor => {
                        // if group/sensor hasn't data for all periods, merge with all periods
                        // Get an array with all possible periods (hours, days, weeks, etc) values for the period with empty data where there's no real data
                        if (!groupOrSensor.data || (groupOrSensor.data.length < allPeriods.length)){
                            const mergedData = allPeriods.map(period => {
                                const index = groupOrSensor.data.findIndex(realData => moment(realData.time).isSame(period.time));
                                return (index >= 0) ? groupOrSensor.data[index] : period;
                            });
                            groupOrSensor.data = mergedData;
                        }
                    });

                    let overallMin = mins.reduce((min, cur) => (min < cur) ? min : cur, mins[0]);
                    let overallMax = maxes.reduce((max, cur) => (max > cur) ? max : cur, 0);

                    if (overallMin === overallMax)
                        overallMin = 0;

                    Object.assign(state, {
                        loadingData: false,
                        loadingLevel: false,
                        data: orderedData,
                        allPeriods: allPeriods,
                        total: sum(totals),
                        totals: totals,
                        average: averages && averages.length > 0 ? mean(averages) : 0,
                        averages: averages,
                        noDataChartMsg: this.noDataChartMessage,
                        min: overallMin,
                        mins: mins,
                        max: overallMax,
                        maxes: maxes,
                    });
                    Object.assign(state.compare[index], {
                        average: response.data.avgConsumption,
                        max: max,
                        min: min,
                        total: response.data.totalConsumption,
                        groupId: response.data.groupId || null,
                        sensorId: response.data.sensorId || null,
                        sensorPhase: response.data.channel || null,
                    });
                    this.setState(state);
                }else{
                    this.handleLoadError({loadingData: false, loadingLevel: false}, i18n.t("Could not load chart data"))
                }
            });
        }).catch((error) => {
            this.handleLoadError({loadingData: false, loadingLevel: false}, i18n.t("Could not load chart data"), error)
        });
    }

    getCompareAndLoadChartData = (state) => {
        const ids = this.getIdsJSONFromURL();
        let promises = [];

        ids.forEach((obj, index) => {
            let compare = this.willLoadData ? {} : this.state.compare[index], groupData, subgroupData, sensorData, tagData;

            compare.open = obj.open;
            if (obj.open) {
                this.compareIndexOpen = index;
                document.body.addEventListener('click', this.onOutsideCompareClick);
            }

            if (obj.gid) {
                groupData = this.getGroupSelectedById(obj.gid, 0);
                if (groupData) Object.assign(compare, groupData);
            }
            if (obj.sgid) {
                subgroupData = this.getSubgroupSelectedById(obj.sgid, compare.filterGroup);
                if (subgroupData) Object.assign(compare, subgroupData);
            }
            if (obj.sid) {
                sensorData = this.getSensorSelectedById(obj.sid, obj.sph, compare);
                if (sensorData) Object.assign(compare, sensorData);
            }

            if (obj.tag) {
                tagData = this.getTagSelectedById(obj.tag, 0);
                if (tagData) Object.assign(compare, tagData);
            }

            state.compare.push(compare);
            if (this.willLoadData)
                promises.push(sensorData
                    ? this.loadSensorChart(state, index)
                    : tagData
                        ? this.loadTagChart(state, index)
                        : this.loadGroupChart(state, index)
                );
        });

        this.awaitChartData(state, promises);
        return state;
    }

    reloadChartData = () => {
        const promises = this.state.compare.map((obj, index) => obj.filterSensor
            ? this.loadSensorChart(this.state, index)
            : obj.filterTag
                ? this.loadTagChart(this.state, index)
                : this.loadGroupChart(this.state, index)
        );

        this.awaitChartData(this.state, promises);
    }

    getGroupSelectedById = (id) => {
        const group = id ? this.state.groups.find((group) => group.id === id) : this.state.groups[0];
        if (id && !group) {
            this.goToURL(this.getFullURL([{gid: this.state.groups[0].id}]));
            return;
        }

        return {
            filterGroup: group,
            filterSubgroup: null,
            filterSubgroupOptions: this.getGroupSubgroupsOptions(group),
            filterSensor: null,
            filterSensorOptions: [],
            noDataChartMsg: this.loadingChartMessage,
        };
    }

    getSubgroupSelectedById = (id, group) => {
        const subgroup = group.subGroups.find((subgroup) => subgroup.id === id);
        if (id && !subgroup) {
            this.goToURL(this.getFullURL([{gid: group.id, sgid: group.subGroups[0].id}]));
            return;
        }

        return {
            filterSubgroup: subgroup,
            filterSensor: null,
            filterSensorOptions: this.getSubgroupSensorsOptions(subgroup),
            noDataChartMsg: this.loadingChartMessage,
        };
    }

    getSensorSelectedById = (id, channel, state) => {
        let sensor = state.filterSensorOptions.find((sensor) => sensor.id === id && sensor.channel === channel);
        if (id && !sensor) {
            sensor = state.filterSubgroup.subGroups[0].sensors[0];
            this.goToURL(this.getFullURL([{
                gid: state.filterGroup.id,
                sgid: state.filterSubgroup.id,
                sid: sensor.id,
                sph: sensor.channel,
            }]));
            return;
        }

        return {
            filterSensor: sensor,
            noDataChartMsg: this.loadingChartMessage,
        };
    }

    getTagSelectedById = id => {
        const tag = id ? this.state.tags.find(tag => tag.id === id) : this.state.tags[0];
        if (id && !tag) {
            this.goToURL(this.getFullURL([{ tag: this.state.tags[0].id}]));
            return;
        }

        return {
            filterTag: tag,
            noDataChartMsg: this.loadingChartMessage,
        };
    }

    checkGeneralChangesOnComponent = (nextProps) => {
        if (!window.location.search) {
            if (this.state.groups && this.state.groups.length)
                this.props.history.push(`/analysis/consumption/heatmap?ids=[{gid:${this.state.groups[0].id}}]`);
            return;
        }

        const searchParams = new URLSearchParams(window.location.search);
        let state = {
            compare: [],
            datePicker: this.state.datePicker,
            dateRangePicker: this.state.dateRangePicker,
        }, startDate, endDate;


        state.periodFilter = searchParams.get('period') ? searchParams.get('period') : 'day';
        if (searchParams.get('date')) {
            startDate = moment(searchParams.get('date'));

            if (state.periodFilter === 'week') {
                startDate = startDate.startOf('week');
                endDate = startDate.clone().endOf('week');
                endDate = endDate.isAfter(this.maximumDate) ? this.maximumDate : endDate;
            } else endDate = startDate;

            state.datePicker.open = false;
            state.datePicker.startDate = startDate;
            state.datePicker.endDate = endDate;
            state.datePicker.data.startDate = startDate;
            state.datePicker.data.endDate = endDate;
        }

        if (searchParams.get('startDate') && searchParams.get('endDate')) {
            startDate = moment(searchParams.get('startDate'));
            endDate = moment(searchParams.get('endDate'));

            state.dateRangePicker.open = false;
            state.dateRangePicker.startDate = startDate;
            state.dateRangePicker.endDate = endDate;
            state.dateRangePicker.data.startDate = startDate;
            state.dateRangePicker.data.endDate = endDate;
            state.diffOfSelectedDays = (startDate && endDate) ? endDate.diff(startDate, 'days') : 0;
        }

        state.level = searchParams.has('level') ? parseFloat(searchParams.get('level')) : 1;

        state.old_date = searchParams.has('old_date') ? searchParams.get('old_date') : null;
        state = this.getCompareAndLoadChartData(state);

        this.setState(state);

        // Always enforce to load data again
        this.willLoadData = true;
    }

    createGroupsNamesMap = (groups) => {
        const idNamesPairsArray = groups.map((group) => {
            const groupPair = [group.id, group.name];
            const subgroupPair = group.subGroups.map((subgroup) => {
                return [subgroup.id, subgroup.name];
            })
            return subgroupPair.concat([groupPair]);
        }).reduce( (acc,x)=> acc.concat(x));

        return new Map(idNamesPairsArray);
    }

    // Create a Map that lets get the sensor name from sensor-phase
    createSensorsPhasesNamesMap = (groups) => {
        const sensorPhasesMap = new Map();
        groups.forEach((group) => {
            group.sensors.forEach((sensorPhase) => sensorPhasesMap.set(sensorPhase.id + "-" + sensorPhase.channel, sensorPhase.name));
            group.subGroups.forEach((subgroup) => {
                if (subgroup.sensors && subgroup.sensors.length > 0)
                    subgroup.sensors.forEach((sensorPhase) => sensorPhasesMap.set(sensorPhase.id + "-" + sensorPhase.channel, sensorPhase.name));

                if (subgroup.subGroups && subgroup.subGroups.length > 0){
                    subgroup.subGroups.forEach(innerSubgroup => {
                        if (innerSubgroup.sensors && innerSubgroup.sensors.length > 0)
                            innerSubgroup.sensors.forEach((sensorPhase) => sensorPhasesMap.set(sensorPhase.id + "-" + sensorPhase.channel, sensorPhase.name))
                    });
                }
            })
        });

        return sensorPhasesMap;
    }

    getGroupName = (groupId) => {
        return this.groupsNamesMap.get(+groupId);
    }

    getSensorPhaseName = (sensor, phase) => {
        return this.sensorsPhasesNamesMap.get(sensor + '-' + phase);
    }

    getHeatColor = (consumption) => {
        if (consumption === null || typeof consumption === 'undefined' || consumption < 0)
            return "#fafafa";
        const {min, max} = this.state;

        return scaleLinear().domain(range(min, max + max/100, (max - min)/(colors.length - 1))).range(colors)(consumption);
    }

    getBarChartWidth = index => {
        const dataValues = this.state.data.map(({ data }) =>
            data.filter(({ defined}) => defined).reduce((prev, cur) => prev + cur.consumption , 0)
        )

        const max = Math.max(...dataValues)
        const percentage = dataValues[index] / max * 100
        return 100 - percentage
    }

    loadGroupChart = (state, index) => {
        d3.selectAll('.nv-chart svg .weekend-highlight').remove();

        this.setState({ loadingData: true, noDataChartMsg: this.loadingChartMessage, xTicks: [] });
        const compare = state.compare[index],
              groupId = compare.filterSubgroup ? compare.filterSubgroup.id : compare.filterGroup.id;

        let level = state.level;
        let date = state.datePicker.startDate.format('YYYY-MM-DD');
        let apiFn;

        switch (state.periodFilter) {
            case 'month':
                apiFn = API.VISUALIZATIONS.CONSUMPTION.HEAT.MONTH.GROUP;
                break;
            case 'week':
                apiFn = API.VISUALIZATIONS.CONSUMPTION.HEAT.WEEK.GROUP;
                break;
            case 'day':
                apiFn = API.VISUALIZATIONS.CONSUMPTION.HEAT.DAY.GROUP;
                break;
            default:
                apiFn = API.VISUALIZATIONS.CONSUMPTION.HEAT.DAY.GROUP;
                console.warn("An invalid period filter was found. Assuming day period for group load.");
        }

        return apiFn(groupId, date, level);
    }

    loadTagChart = (state, index) => {
        d3.selectAll('.nv-chart svg .weekend-highlight').remove();

        this.setState({ loadingData: true, noDataChartMsg: this.loadingChartMessage, xTicks: [] });
        const compare = state.compare[index],
              tagId = compare.filterTag ? compare.filterTag.id : null;

        if (!tagId) {
            return
        }

        let level = state.level;
        let date = state.datePicker.startDate.format('YYYY-MM-DD');
        let apiFn;

        switch (state.periodFilter) {
            case 'month':
                apiFn = API.VISUALIZATIONS.CONSUMPTION.HEAT.MONTH.LABEL;
                break;
            case 'week':
                apiFn = API.VISUALIZATIONS.CONSUMPTION.HEAT.WEEK.LABEL;
                break;
            case 'day':
                apiFn = API.VISUALIZATIONS.CONSUMPTION.HEAT.DAY.LABEL;
                break;
            default:
                apiFn = API.VISUALIZATIONS.CONSUMPTION.HEAT.DAY.LABEL;
                console.warn("An invalid period filter was found. Assuming day period for group load.");
        }

        return apiFn(tagId, date, level);
    }

    loadSensorChart = (state, index) => {
        d3.selectAll('.nvtooltip').remove();
        d3.selectAll('.nv-chart svg .weekend-highlight').remove();

        this.setState({ loadingData: true, noDataChartMsg: this.loadingChartMessage, xTicks: [] });

        let level = state.level;
        let date = state.datePicker.startDate.format('YYYY-MM-DD');

        let apiFn = null;
        switch (state.periodFilter) {
            case 'month':
                apiFn = API.VISUALIZATIONS.CONSUMPTION.HEAT.MONTH.SENSOR;
                break;
            case 'week':
                apiFn = API.VISUALIZATIONS.CONSUMPTION.HEAT.WEEK.SENSOR;
                break;
            case 'day':
                apiFn = API.VISUALIZATIONS.CONSUMPTION.HEAT.DAY.SENSOR;
                break;
            default:
                apiFn = API.VISUALIZATIONS.CONSUMPTION.HEAT.DAY.SENSOR;
                console.warn("An invalid period filter was found. Assuming day period for sensor load.");
        }

        return apiFn(
            state.compare[index].filterSensor.subgroupId,
            state.compare[index].filterSensor.id,
            state.compare[index].filterSensor.channel,
            date,
            level
        );
    }

    getGroupSubgroupsOptions = (group) => {
        if (group.subGroups && group.subGroups.length > 0){
            return group.subGroups.map(subgroup => {
                return {
                    groupId: group.id,
                    name: subgroup.name,
                    id: subgroup.id
                }
            });
        }else{
            return [];
        }
    }

    getSubgroupSensorsOptions = (subgroup) => {
        // non main subgroups sensors are stored in a "artificial" sub-subgroup
        if (subgroup.subGroups && subgroup.subGroups[0]
            && subgroup.subGroups[0].sensors
            && subgroup.subGroups[0].sensors.length > 0){

            return subgroup.subGroups[0].sensors.map(sensor => {
                return {...getSensorStructured(sensor), subgroupId: subgroup.subGroups[0].id} });
        }else{
            return [];
        }
    }


    // URL Functions
    goToURL = (url) => this.props.history.push(url);
    getFullURL = (ids) => `${baseURLDefault}${this.getIdsToURL(ids)}${this.getSearchURL()}`;
    getIdsJSONFromURL = () => {
        const searchParams = new URLSearchParams(window.location.search);
        return searchParams.get('ids') ? JSON.parse(adjustJSONToValidJSON(searchParams.get('ids'))) : [];
    }
    getIdsParsedToURL = (ids) => JSON.stringify(ids).replace(/"/g, '');
    getIdsToURL = (ids) => `?ids=${this.getIdsParsedToURL(ids)}`;

    getSearchURL = () => {
        let url = this.getDateURL();
        url = this.getPeriodFilterURL(false, url);
        return url;
    }

    getPeriodFilterURL = (fromSelect, url, period=this.state.periodFilter, date) => {
        if (fromSelect){
            const searchParams = new URLSearchParams(this.props.location.search);
            url = this.props.location.pathname + `?ids=${searchParams.get('ids')}`;
            url += this.getDateURL(period, date);
        }

        if (this.state.level) {
            url += `&level=${this.state.level}`
        }

        if (this.state.old_date) {
            url += `&old_date=${this.state.old_date}`
        }

        url.indexOf('period') >= 0 ? url = url.replace(/day|week|month|custom/, period) : url += `&period=${period}`;
        return url;
    }

    getDateURL = (period=this.state.periodFilter, date) => {
        date = date ? date : period === 'custom' ? this.state.dateRangePicker : this.state.datePicker;

        if (period === 'custom'){
            let url = "";
            url += date.startDate ?
                `&startDate=${date.startDate.format('YYYY-MM-DD')}` : '';
            url += date.endDate ?
                `&endDate=${date.endDate.format('YYYY-MM-DD')}` : '';
            return url;
        }else{
            return date.startDate ?
                `&date=${date.startDate.format('YYYY-MM-DD')}` : '';
        }
    }

    onOutsideCompareClick = (e) => {
        this.onOutsideCompareClickTimer = setTimeout(() => {
            const groupsEle = document.getElementById('groups')
            if (!groupsEle) {
                return
            }

            const rootElem = groupsEle.children[this.compareIndexOpen];
            const isDescendantOfRoot = rootElem.contains(e.target);
            if (!isDescendantOfRoot) {
                let ids = this.getIdsJSONFromURL();
                this.willLoadData = false;
                document.body.removeEventListener('click', this.onOutsideCompareClick);
                ids = ids.map((id) => {delete(id.open); return id;});
                this.goToURL(this.getFullURL(ids));
            }
        }, 50);
    }

    // Close (the Collapse element) all compares, except the index, if you pass one
    closeCompares = (index, toClose) => {
        let ids = this.getIdsJSONFromURL();
        return ids.map((id, i) => {
            if (i === index && !id.open && !toClose) {
                document.body.addEventListener('click', this.onOutsideCompareClick);
                this.compareIndexOpen = index;
                id.open = true;
            } else delete(id.open);
            return id;
        });
    }

    toggleCompareCollapse = (index, toClose) => {
        clearTimeout(this.onOutsideCompareClickTimer);

        this.willLoadData = false;
        this.compareIndexOpen = undefined;
        document.body.removeEventListener('click', this.onOutsideCompareClick);

        let ids = this.closeCompares(index, toClose);
        this.goToURL(this.getFullURL(ids));
    }

    addCompare = () => {
        clearTimeout(this.onOutsideCompareClickTimer);

        if (this.state.groups && this.state.groups.length) {
            let ids = this.closeCompares();
            ids.push({ gid: this.state.groups[0].id });
            this.goToURL(this.getFullURL(ids));
        }
    }

    removeCompare = (index) => {
        clearTimeout(this.onOutsideCompareClickTimer);
        let ids = this.getIdsJSONFromURL();
        ids.splice(index, 1);
        this.goToURL(this.getFullURL(ids));
    }

    getBreadcrumbsBySelected = (selected) => {
        const {filterGroup, filterSubgroup, filterSensor, filterTag} = selected;
        const trail = [];
        if (filterTag && filterTag.name)
            trail.push(<div key="tag"><span># {filterTag.name}</span></div>);
        if (filterGroup && filterGroup.name)
            trail.push(<div key="group"><span>{filterGroup.name}</span></div>);
        if (filterSubgroup && filterSubgroup.name)
            trail.push(<div key="subgroup"><span>{filterSubgroup.name}</span></div>);
        if (filterSensor &&  filterSensor.label)
            trail.push(<div key="sensor"><span>{filterSensor.label}</span></div>);

        return <div className="hierarchy-label">{trail}</div>;
    }

    createDatetime = data => data.map((d) => ({
        ...d,
        time: this.state.periodFilter !== 'month'
            ? d.dayTz + "T" + d.hour
            : d.dayTz + "T" + d.hour ? d.hour : "00:00:00",
    }));

    createSensorPhase = (data) => data.map((d) => {
        d.name = this.getSensorPhaseName(d.sensorId, d.channel);
        return {
            ...d,
            ...getSensorStructured(d),
        }
    })

    /* Creates chart data for groups and subgroups */
    createGroupData = (groupData) => {
        let groupEntries = [];

        // Creates group data for the chart to draw
        if (groupData.data && groupData.data.length > 1){
            const groupPre = this.createDatetime(groupData.data);
            let group = groupPre.map((d) => {
                return {
                    ...d,
                    id: groupData.groupId+'',
                }
            });
            groupEntries = nest().key(function(d) { return d.id; }).entries(group);
        }

        return groupEntries;
    }

    /* Creates chart data for sensors-phases */
    createSensorData = (sensorData) => {
        if (sensorData.data && sensorData.data.length) {
            let sensorsDataPre = this.createDatetime(sensorData.data);
            sensorsDataPre = this.createSensorPhase(sensorsDataPre); // creates sensor-phase, e.g.: 3031-1, 3031-2, 3031-3
            const entries = nest().key(function(d) { return d.label; }).entries(sensorsDataPre);
            return entries;
        }
        return [];
    }


    // Ensure we have just positive values for the chart
    ensurePositiveValues = (data) => data.map((d) => {
        if (d.consumption < 0) {
            d.consumption = 0;
            d.defined = false;
        } else d.defined = true;
        return d;
    })


    changePeriodFilter = (period) => {
        let url = this.getPeriodFilterURL(true, '', period ? period.value : 'day');
        this.props.history.push(url);
    }

    goToPreviousPeriod = () => {
        let {datePicker} = this.state;
        datePicker.startDate.subtract(1, this.state.periodFilter);
        this.goToURL(this.getFullURL(this.getIdsJSONFromURL()));
    }

    goToNextPeriod = () => {
        let {datePicker} = this.state;
        datePicker.startDate.add(1, this.state.periodFilter);
        this.goToURL(this.getFullURL(this.getIdsJSONFromURL()));
    }

    goToCurrentDay = () => {
        const url = this.getPeriodFilterURL(true, '', this.state.periodFilter, {startDate: moment()});
        this.goToURL(url);
    }

    goToNextLevel = groupOrSensor => {
        const { level, datePicker } = this.state;
        let searchParams = new URLSearchParams(window.location.search);
        let search = window.location.search;
        search += searchParams.has('date')
            ? `&old_date=${searchParams.get('date')}`
            : `&old_date=${datePicker.startDate.format('YYYY-MM-DD')}`;

        const levelDay = groupOrSensor.dayTz;
        if (level && level > 0){
            if (!searchParams.has('level')) {
                search = search + `&level=${level + 1}`;
            } else {
                search = search.replace(`&level=${level}`, `&level=${level + 1}`).replace(`&date=${searchParams.get('date')}`, `&date=${levelDay}`);
            }

            if (!searchParams.has('date')) {
                search = search + `&date=${levelDay}`;
            } else {
                search = search.replace(`&date=${searchParams.get('date')}`, `&date=${levelDay}`);
            }
        }

        this.setState({loadingLevel: true});
        this.props.history.push(window.location.pathname + search);
    }

    goToPreviousLevel = () => {
        const { level } = this.state
        let searchParams = new URLSearchParams(window.location.search);
        let search = window.location.search
            .replace(`&date=${searchParams.get('date')}`, `&date=${searchParams.get('old_date')}`)
            .replace(`&old_date=${searchParams.get('old_date')}`, '');

        if (level && level > 0){
            if (!searchParams.has('level')) {
                search = search + `&level=${level - 1}`
            } else {
                search = search.replace(`&level=${level}`, `&level=${level - 1}`);
            }
        }

        this.setState({loadingLevel: true});
        this.props.history.push(window.location.pathname + search);
    }

    handleLoadError = (state, message, error) => {
        state.loadErrorMessage = this.loadErrorMessage;
        this.setState(state);

        console.error(this.loadErrorMessage + " (" + message + ")");
        if (error)
            console.error(error);
    }


    // Date period select functions
    updateDateRangePicker = (dateRangePicker, removeTooltip) => {
        this.setState({dateRangePicker});
        if (removeTooltip)
            d3.selectAll('.nvtooltip').remove();
    }

    updateDatePicker = (datePicker, removeTooltip) => {
        this.setState({datePicker});
        if (removeTooltip)
            d3.selectAll('.nvtooltip').remove();
    }
    // /Date period select functions

    getGranularity = () => {
        const { periodFilter, level, dateRangePicker: { startDate, endDate }} = this.state
        const diffDays = endDate.diff(startDate, 'days', true)

        if (periodFilter === 'day' && level === 1) {
            return i18n.t('1 Day')
        }

        if (periodFilter === 'day' && level === 2) {
            return i18n.t('1 Hour')
        }

        if (periodFilter === 'day' || (periodFilter === 'custom' && diffDays <= 2)) {
            return i18n.t('5 Min')
        }

        if (periodFilter === 'week' || (periodFilter === 'custom' && diffDays <= 31)) {
            return i18n.t('1 Hour')
        }

        return i18n.t('1 Day')
    }

    isButtonNowDisabled = () => moment().isSame(this.state.datePicker.startDate, this.state.periodFilter)

    openCalendar = () => {
        let {datePicker, dateRangePicker} = this.state;
        this.state.periodFilter === 'custom' ? dateRangePicker.open = true : datePicker.open = true;
        this.setState({datePicker: datePicker, dateRangePicker: dateRangePicker});
    }

    getHeatmapTitle = () => {
        let chartTitle = "";
        switch (this.state.periodFilter) {
            // case 'month':
            //     break;
            // case 'week':
            //     break;
            case 'day':
                if (this.state.level === 1){
                    chartTitle = i18n.t('Last 10 days');
                }else if (this.state.level === 2) {
                    chartTitle = '';
                }else{
                    chartTitle = i18n.t('Last 10 days');
                }
                break;
            default:
                chartTitle = i18n.t('Day');
                console.warn("An invalid period filter was found. Assuming generic title for heatmap.");
        }
        return chartTitle;
    }

    getHeatmapHeaderTimeFormat = time => {
        let timeFormat = moment(time).format('DD');;
        switch (this.state.periodFilter) {
            // case 'month':
            //     break;
            // case 'week':
            //     break;
            case 'day':
                if(this.state.level === 2){
                    timeFormat = (<span><small>{moment(time).format('LT')}</small></span>)
                }
                break;
            default:
                console.warn("An invalid period filter was found. Assuming day format for heatmap header.");
        }
        return timeFormat;
    }

    getAllPeriods = () => {
        let allPeriods = [];
        switch (this.state.periodFilter) {
            // case 'month':
            //     break;
            // case 'week':
            //     break;
            case 'day':
                if (this.state.level === 1){
                    const selectedDay = this.state.datePicker.startDate.clone().add(12, 'hours'); //utcDay stop is exclusive
                    const lastDayBackward = selectedDay.clone().subtract(10, 'days');
                    allPeriods = utcDays(
                        lastDayBackward,
                        selectedDay
                    );
                }else if(this.state.level === 2){
                    allPeriods = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23].map(hour => moment(this.state.datePicker.startDate).startOf('day').add(hour, 'hour'));
                }else{
                    console.warn("An invalid level was found. No period will be created for heatmap header.");
                }
                break;
            default:
                console.warn("An invalid period filter was found. No period will be created for heatmap header.");
        }

        return allPeriods.map(d => ({
            time: d,
            defined: false
        }));
    }

    render () {
        const { compare, periodFilter, loadingData, max, min, data, allPeriods, level } = this.state;
        // const { classes } = this.props
        const isButtonNowDisabled = this.isButtonNowDisabled();

        let heatmapClasses = 'heatmap-chart';
        let barchartClasses = 'bar-chart';
        const levelClass = ' level-' +this.state.level;
        const periodClass = ' period-' +this.state.periodFilter;
        heatmapClasses += levelClass;
        barchartClasses += levelClass;
        heatmapClasses += periodClass;
        barchartClasses += periodClass;

        return (
            <div id="dashboard">
                <div id="heatmap">
                    <div id="top-bar">
                        <div className={`top top-period-filter-${periodFilter}`}>
                            <div>
                                {this.periodOptions.map((period, key) => (
                                    <Button className={
                                        `btn-period-filter${periodFilter === period.value ? ' active' : ''}`}
                                        disabled={
                                            loadingData ||
                                            periodFilter === period.value ||
                                            (!this.state.loadingGroups && this.state.groups.length === 0)
                                        }
                                        key={key}
                                        onClick={() => this.changePeriodFilter(period)}>
                                        {period.label}
                                    </Button>
                                ))}
                            </div>

                            <div>
                                {periodFilter !== 'custom' ?
                                    <IconButton className="btn-previous btn-previous-date"
                                        disabled={loadingData}
                                        onClick={this.goToPreviousPeriod}>
                                        <ExpandMoreIcon />
                                    </IconButton> : null}

                                <DatePicker
                                    chartType={'line-chart'}
                                    datePicker={this.state.datePicker}
                                    dateRangePicker={this.state.dateRangePicker}
                                    updateDatePicker={this.updateDatePicker}
                                    updateDateRangePicker={this.updateDateRangePicker}
                                    level={this.state.level}
                                    old_date={this.state.old_date}
                                    periodFilter={periodFilter}
                                    loadingGroups={this.state.loadingGroups}
                                    history={this.props.history}
                                    disabled={!this.state.loadingGroups && this.state.groups.length === 0}
                                    showLabel={false} />

                                {periodFilter !== 'custom' ?
                                    <IconButton className="btn-next btn-next-date"
                                        disabled={
                                            loadingData ||
                                            this.state.datePicker.startDate.isSameOrAfter(
                                                moment().startOf(periodFilter))
                                        }
                                        onClick={this.goToNextPeriod}>
                                        <ExpandMoreIcon />
                                    </IconButton> : null}

                                {periodFilter !== 'custom' ?
                                    <Button className={`btn-period${isButtonNowDisabled ? ' active' : ''}`}
                                        disabled={
                                            loadingData ||
                                            isButtonNowDisabled ||
                                            (!this.state.loadingGroups && this.state.groups.length === 0)
                                        }
                                        onClick={() => this.goToCurrentDay()}>
                                        {i18n.t('NOW')}
                                    </Button> : null}
                            </div>

                            <div className="granularity">
                                <div>
                                    <Text text={this.getGranularity()} />
                                </div>
                            </div>
                        </div>

                        {!this.state.loadingGroups && this.state.groups.length > 0 && (
                            <div className="bottom">
                                <GroupCompare
                                    tags={this.state.tags}
                                    baseURLDefault={baseURLDefault}
                                    history={this.props.history}
                                    periodFilter={this.state.periodFilter}
                                    level={this.state.level}
                                    old_date={this.state.old_date}
                                    dateRangePicker={this.state.dateRangePicker}
                                    datePicker={this.state.datePicker}
                                    addCompare={this.addCompare}
                                    compare={compare.map((comp, index) => ({
                                        ...comp,
                                        color: '#FFF'
                                    }))}
                                    filterGroupOptions={this.state.filterGroupOptions}
                                    removeCompare={this.removeCompare}
                                    toggleCompareCollapse={this.toggleCompareCollapse}
                                />
                            </div>
                        )}
                    </div>

                    {(!this.state.loadingGroups &&
                    !this.state.loadingData &&
                    !this.state.data.length) ? (
                        <div className="nv-chart no-chart-msg">
                            <p className="no-data"> {this.noDataChartMessage} </p>
                        </div>
                    ) : (
                        <div>
                            <div className="consumption-info">
                                <div className="colors-info" />
                                <div className="values-info">
                                    <span>{(min >= 0 && min !== max) ? `${kwhFormat(min)} ${i18n.t('kWh')}` : "--"}</span>
                                    <span>{(max) ? `${kwhFormat(max)} ${i18n.t('kWh')}` : "--"}</span>
                                </div>
                            </div>
                            <div id={this.state.loadingLevel ? 'loading-graph' : 'graph'}>
                                <Table className={heatmapClasses}>
                                    <TableHead>
                                        <TableRow>
                                            {!this.state.loadingLevel && <TableCell />}
                                            <TableCell className="chart-title" colSpan={this.state.loadingLevel ? 0 : allPeriods.length}>
                                                {this.getHeatmapTitle()}
                                            </TableCell>

                                        </TableRow>
                                        <TableRow>
                                            <TableCell />
                                            {this.state.loadingGroups ?
                                                <TableCell />:
                                                (allPeriods && allPeriods.length && !this.state.loadingLevel) ?
                                                    allPeriods.map(d => <TableCell key={`${d.time}`} className="square-header">{this.getHeatmapHeaderTimeFormat(d.time)}</TableCell>) :
                                                    <TableCell />
                                            }
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {data && !!data.length && data.map((groupOrSensor, i) => {
                                            const groupOrSensorCompare = this.state.compare.find( compare => compare.groupId === groupOrSensor.groupId
                                                || (compare.sensorId === groupOrSensor.sensorId && compare.sensorPhase === groupOrSensor.channel)
                                                || (compare.filterTag && compare.filterTag.id === groupOrSensor.labelId));
                                            const grourOrSensorLabel = (groupOrSensorCompare) ? this.getBreadcrumbsBySelected(groupOrSensorCompare) : null;

                                            return (
                                                <TableRow key={i}>
                                                    <TableCell colSpan={this.state.loadingLevel ? allPeriods.length + 1 : 1}>
                                                        <div>
                                                            {(this.state.loadingData || this.state.loadingGroups) && !this.state.loadingLevel && i === 0 && <div className="hierarchy-label loading"><Loading /></div>}
                                                            {this.state.loadingLevel && i === 0 && <Loading />}
                                                            {!this.state.loadingData && !this.state.loadingGroups && !this.state.loadingLevel && grourOrSensorLabel}
                                                        </div>
                                                    </TableCell>
                                                    {!this.state.loadingLevel && groupOrSensor && groupOrSensor.data && groupOrSensor.data.map(
                                                        d => (
                                                            <TableCell key={`${d.time}`} className={d.consumption && d.consumption >= 0 && d.defined  ? 'square' : 'square no-data'}>
                                                                <div
                                                                    style={{backgroundColor:`${this.getHeatColor(d.consumption)}`}}
                                                                    className={d.consumption === this.state.max ? 'max-consumption' : null}
                                                                    onClick={level === 1 && d.defined ? () => this.goToNextLevel(d) : null}
                                                                />
                                                                <div className={`square-tooltip level-${level}`}>
                                                                    <span className="text">
                                                                        {level === 2 && (
                                                                            <small>
                                                                                {moment(d.time).format('LT')} - {moment(d.time).add(1, 'hour').format('LT')}
                                                                            </small>
                                                                        )}
                                                                        {d.consumption && d.consumption >= 0 && d.defined  ? `${i18n.t('Consumption:')} ${kwhFormat(d.consumption)} ${i18n.t('kWh')}` : i18n.t('No consumption data')}
                                                                    </span>
                                                                </div>
                                                            </TableCell>
                                                        ))}
                                                </TableRow>
                                                )
                                        })}
                                    </TableBody>
                                </Table>
                                {!this.state.loadingLevel && data && !!data.length && (
                                    <div className={barchartClasses}>
                                        <div className="header">
                                            {i18n.t('Consumption')}
                                        </div>
                                        <div className="data">
                                            {data.map((groupOrSensor, index) => (
                                                <div className="chart" key={index}>
                                                    <p className="group-info">
                                                        {kwhFormat(groupOrSensor.data.filter(({ defined }) => defined).reduce((prev, cur) => prev + cur.consumption , 0))} {i18n.t('kWh')}
                                                    </p>
                                                    <div className="blank" style={{ width: `${this.getBarChartWidth(index)}%`}} />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    {level !== 1 && (
                        <div className="heatmap-footer">
                            <VButton action={"submit"}
                                click={this.goToPreviousLevel}
                                label={<div><ArrowLeftIcon /> {i18n.t('BACK')}</div>}
                            />
                        </div>
                    )}
                </div>
                <SystemSnackbar
                    open={!this.state.loadingGroups && this.state.groups.length === 0}
                    message={i18n.t('No group setup')}
                    variant='warning' />
            </div>
        )
    }
}

Heatmap.propTypes = {
    intl: intlShape.isRequired,
};

export default withStyles(styles)(injectIntl(Heatmap));
