import React, { PureComponent } from 'react';
import CompareCard from '../../../elements/material-ui/CompareCard';
import BenchmarkCompareCard from './benchmark-compare-card';

import './benchmark-compare.css';

class BenchmarkCompare extends PureComponent {
    state = {
        showGroupSelect: false
    }

    changeBenchmark = (benchmark) => {
        this.props.changeBenchmark(benchmark);
    }

    render() {
        const {
            benchmarkCompare,
            removeCompare,
            filterGroupOptions,
            benchmarkOptions,
            periodFilter,
            dataGranularity,
            isLinear,
            history,
            // loading,
        } = this.props

        return (
            <div id="group-filters">
                <div className="group-scroll">
                    <div id="groups">
                        <div className={`one-group ${this.state.showGroupSelect ? ' active' : ''}`}>
                            <CompareCard
                                changeGroup={() => {}}
                                changeSensor={() => {}}
                                changeSubgroup={() => {}}
                                compare={[]}
                                filterGroupOptions={filterGroupOptions}
                                obj={{
                                    filterGroup: this.props.filterGroup,
                                    filterSubgroup: null,
                                    filterSensor: null,
                                    filterSensorOptions: this.props.filterSensorOptions,
                                    filterSubgroupOptions: this.props.filterSubgroupOptions,
                                    open: false,
                                    color: '#FFF'
                                }}
                                hideTags={true}
                                hideSensors={true}
                                toggleCompareCollapse={() => {}}
                            />
                        </div>
                        <div className="tariff-divider" />
                        <div className={`one-tariff ${this.open ? 'active' : ''} last' : ''}`}>
                            <BenchmarkCompareCard
                                changeBenchmark={this.changeBenchmark}
                                compare={[]}
                                index={0}
                                obj={benchmarkCompare}
                                removeCompare={removeCompare}
                                benchmarkOptions={benchmarkOptions}
                                toggleCompareCollapse={this.props.toggleBenchmarkCollapse}
                                periodFilter={periodFilter}
                                updateDatePicker={this.updateDatePicker}
                                dataGranularity={dataGranularity}
                                isLinear={isLinear}
                                history={history}
                                checkDateAvailability={this.checkDateAvailability}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default BenchmarkCompare
