import React, { PureComponent } from 'react';
import { withStyles } from 'material-ui/styles';
import NVD3Chart from 'react-nvd3';
import PropTypes from 'prop-types';

import { Text } from '../../elements/text';
import { Loading } from '../../elements/loading';
import Card, { CardContent } from 'material-ui/Card';
// import {groupMostConsumptionData} from '../../components/__mocks__/visualizations-group-most-consumption-month-get';
import PeriodSelect from './period-select';

// Translation
import i18n from '../../i18n';

const styles = theme => ({
    card: {
        flex: 1,
        marginRight: '16px',
        '&:last-child': {
            marginRight: '0px'
        },
        minWidth: '420px',
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        display: 'flex'
    },
    contentRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1,
        padding: '0',
        '&:last-child': {
            padding: '10px',
            paddingTop: '0'
        },
        '& .nv-chart': {
            border: 'none',
            padding: '0'
        },
        '& .nvd3.nv-pie path': {
            fillOpacity: '1'
        }
    },
    title: {
        flex: 1,
        margin: '10px',
        display: 'flex',
        alignItems: 'center'
    },
    pieChartLegend: {
        flexDirection: 'column',
        fontSize: '14px',
        display: 'flex',
        paddingRight: '20px',
        '&>div': {
            display: 'flex',
            justifyContent: 'space-between',
            margin: '4px 0',
        }
    },

    legendCircle: {
        display: 'inline-block',
        backgroundColor: '#000',
        borderRadius: '50%',
        width: '10px',
        height: '10px',
        marginRight: '8px',
    },
    legendGroupName: {
        maxWidth: '160px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        marginRight: '8px',
    },
    legendValue: {
        minWidth: '40px',
        whiteSpace: 'nowrap',
    },
    noData: {
        fontSize: '17px',
        color: '#757575',
    }
});

class GroupsPieChart extends PureComponent {
    state = {
        data: []
    }

    componentWillReceiveProps(nextProps){
        if (nextProps.data && nextProps.data.length){
            this.setState({
                data: nextProps.data.map(d => {
                    if (d.groupId === null && d.groupName === null){
                        d.groupName = i18n.t('Others, aggregated');
                    }
                    return d;
                })
            });
        }
    }

    colors = this.props.type === 'cost'
        ? ["#253C0F", "#3C6B12", "#579223", "#69B42D", "#B5C940", "#E0E0E0"]
        : ["#343645", "#27477E", "#256EA1", "#478AD8", "#87CFF8", "#E0E0E0"];

    render() {
        const { classes, data, selectedPeriod, valueFormat, type, loading } = this.props;

        const sectionTitle = type === 'consumption' ? i18n.t("Most Consuming - Groups") : i18n.t("Most Costly - Groups");

        return (
            <Card className={classes.card}>
                <div className={classes.header}>
                    <Text className={`pie-chart-title ${classes.title}`} bold medium text={sectionTitle} />
                    <PeriodSelect
                        active={selectedPeriod}
                        onChange={this.props.changePeriod}
                    />
                </div>
                <CardContent classes={{root: classes.contentRoot}}>
                    {loading ?
                        <Loading />
                    :
                        data && data.length ? (
                            <NVD3Chart
                                type="pieChart"
                                datum={data}
                                x="groupName"
                                y={type}
                                height="360px"
                                width="360px"
                                options={{
                                    color: this.colors,
                                    showLabels: false,
                                    showLegend: false,
                                    noData: i18n.t("There is no data available"),
                                    useInteractiveGuideline: true
                                }}
                            />)
                        :
                            <div className={`no-data ${classes.noData}`}>{i18n.t('There is no data available')}</div>}

                    <div className={`pie-chart-legend ${classes.pieChartLegend}`}>
                        {data && data.length ? data.map((group, i) => (
                            <div key={i} className='legend-section'>
                                <div className={`legend-group-name ${classes.legendGroupName}`}>
                                    <span className={`legend-colored-circle ${classes.legendCircle}`} style={{backgroundColor: this.colors[i]}} />
                                    {group.groupName}
                                </div>
                                <div className={`legend-value ${classes.legendValue}`}>{valueFormat(group[type])}{type === 'consumption' ? ' ' + i18n.t('kWh') : ''}</div>
                            </div>
                        )) : null}
                    </div>
                </CardContent>
            </Card>
        )
    }
}

GroupsPieChart.propTypes = {
    data: PropTypes.array,
    selectedPeriod: PropTypes.oneOf(['day', 'week', 'month']).isRequired,
    valueFormat: PropTypes.func.isRequired,
    type: PropTypes.oneOf(['consumption', 'cost']).isRequired,
    loading: PropTypes.bool.isRequired,
};

export default withStyles(styles)(GroupsPieChart);
