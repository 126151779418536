// React
import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import momentTz from 'moment-timezone';

// React Router
import {Route, Switch} from 'react-router';
import {BrowserRouter, Redirect, withRouter} from 'react-router-dom';

// API
import {API} from './components/api';

// Utils
import {unregister} from './registerServiceWorker';
import {defaultRoutePath} from './components/utils'
import md5 from 'md5'

// Third party components
import 'react-dates/initialize';
import {IntlProvider} from 'react-intl';
import Cookies from 'universal-cookie';
import ReactGa from 'react-ga';

// Third party components styles
import 'react-table/react-table.css';

// Style
import './general/sass/index.css';
import './general/sass/components.css';

// Translate
import './i18n';
import { customFormats } from './components/i18n/moment-custom-formats';
import 'moment/locale/en-gb';
import 'moment/locale/ru';
import 'moment/locale/pt-br';

// Components
import {Auth, AuthLogout, getUserData, PrivateRoute, RootRoute} from './components/auth/auth';

// Pages
import TariffsOverview from './pages/tariff/overview';
import BenchmarksOverview from './pages/consumption-benchmark/overview';
import GroupsOverview from './pages/group/overview';
import ConsumptionLineChart from './pages/visualizations/consumption/line-chart';
import StreamGraph from './pages/visualizations/consumption/stream-graph';
import Geolocation from './pages/visualizations/consumption/geolocation';
import BarChart from './pages/visualizations/cost/bar-chart';
import Heatmap from './pages/visualizations/consumption/heatmap';
import CostSimulation from './pages/visualizations/cost/cost-simulation';
import MiniDrawer from './elements/material-ui/MiniDrawer';
import ReportOverview from './pages/report/overview';
import ReportPreview from './pages/report/preview';
import AlertOverview from './pages/alert/overview';
import EventsOverview from './pages/alert/events-overview';
import ResourcesList from './pages/resource/resources-list';
import PowerChart from './pages/visualizations/power/power-chart';
import PowerPeakBigNumberChart from './pages/visualizations/power/power-peak-big-number-chart';
import BenchmarkLineChartMock from './pages/visualizations/consumption-benchmark/benchmark-line-chart-mock';
import HomeOverview from './pages/home/overview';
import UsersOverview from './pages/user/overview';
import Tenants from './pages/root/tenants/overview';

const keysMomentFront = {
  'en-GB': 'en-gb',
  'ru-RU': 'ru',
  'pt-BR': 'pt-br'
}

const menuRoutes = [
    {
        path: '/',
        exact: true,
        name: 'Home',
        component: GroupsOverview,
    },
    {
        path: '/home',
        exact: true,
        name: 'HomeOverview',
        component: HomeOverview,
    },
    {
        path: '/analysis/consumption',
        exact: true,
        name: 'Consumption Line Chart Dashboard',
        component: ConsumptionLineChart,
        key: 'ConsumptionLineChart'
    },
    {
        path: '/analysis/consumption/stream',
        exact: true,
        name: 'Stream Graph Dashboard',
        component: StreamGraph,
        key: 'StreamGraph'
    },
    {
        path: '/analysis/consumption/cumulative',
        exact: true,
        name: 'Cumulative Dashboard',
        component: ConsumptionLineChart,
        key: 'CumulativeDashboard'
    },
    {
        path: '/analysis/consumption/stream/group/:groupId',
        exact: true,
        name: 'Stream Graph Dashboard',
        component: StreamGraph,
        key: 'StreamGraph'
    },
    {
        path: '/analysis/consumption/stream/tag/:tagId',
        exact: true,
        name: 'Stream Graph Dashboard',
        component: StreamGraph,
        key: 'StreamGraph'
    },
    {
        path: '/analysis/consumption/stream/group/:groupId/subgroup/:subgroupId',
        exact: true,
        name: 'Stream Graph Dashboard',
        component: StreamGraph,
        key: 'StreamGraph'
    },
    {
        path: '/analysis/consumption/heatmap',
        exact: true,
        name: 'Heatmap Dashboard',
        component: Heatmap,
        key: 'Heatmap'
    },
    {
        path: '/analysis/consumption/benchmarking',
        exact: true,
        name: 'Consumption Benchmarking Dashboard',
        component: BenchmarkLineChartMock,
        key: 'ConsumptionBenchmarking'
    },
    {
        path: '/analysis/cost',
        exact: true,
        name: 'Cost Dashboard',
        component: BarChart,
        key: 'BarChart'
    },
    {
        path: '/analysis/cost/simulation',
        exact: true,
        name: 'Cost Simulation Dashboard',
        component: CostSimulation,
        key: 'CostSimulation'
    },
    {
        path: '/analysis/cost/simulation/tag/:tagId',
        exact: true,
        name: 'Cost Simulation Dashboard',
        component: CostSimulation,
        key: 'CostSimulation'
    },
    {
        path: '/analysis/cost/simulation/group/:groupId',
        exact: true,
        name: 'Cost Simulation Dashboard',
        component: CostSimulation,
        key: 'CostSimulation'
    },
    {
        path: '/analysis/cost/simulation/group/:groupId/subgroup/:subgroupId',
        exact: true,
        name: 'Cost Simulation Dashboard',
        component: CostSimulation,
        key: 'CostSimulation'
    },
    {
        path: '/analysis/cost/simulation/group/:groupId/subgroup/:subgroupId/sensor/:sensorId/:channel',
        exact: true,
        name: 'Cost Simulation Dashboard',
        component: CostSimulation,
        key: 'CostSimulation'
    },
    {
        path: '/analysis/cost/group/:groupId',
        exact: true,
        name: 'Cost Dashboard',
        component: BarChart,
        key: 'BarChart'
    },
    {
        path: '/analysis/cost/tag/:tagId',
        exact: true,
        name: 'Cost Dashboard',
        component: BarChart,
        key: 'BarChart'
    },
    {
        path: '/analysis/cost/group/:groupId/subgroup/:subgroupId',
        exact: true,
        name: 'Cost Dashboard',
        component: BarChart,
        key: 'BarChart'
    },
    {
        path: '/analysis/cost/group/:groupId/subgroup/:subgroupId/sensor/:sensorId/:channel',
        exact: true,
        name: 'Cost Dashboard',
        component: BarChart,
        key: 'BarChart'
    },
    {
        path: '/config/groups',
        exact: true,
        name: 'Groups Overview',
        component: GroupsOverview
    },
    {
        path: '/config/tariffs',
        exact: true,
        name: 'Tariffs Overview',
        component: TariffsOverview,
    },
    {
        path: '/config/benchmarking',
        exact: true,
        name: 'Benchmarking',
        component: BenchmarksOverview,
    },
    {
        path: '/reports',
        exact: true,
        name: 'Reports',
        component: ReportOverview,
    },
    {
        path: '/analysis/geolocation',
        exact: true,
        name: 'Geolocation',
        component: Geolocation,
    },
    {
        path: '/reports/preview',
        exact: true,
        name: 'Reports Preview',
        component: ReportPreview,
    },
    {
        path: '/reports/preview/:id',
        exact: true,
        name: 'Reports Preview',
        component: ReportPreview,
    },
    {
        path: '/alerts',
        exact: true,
        name: 'Alerts',
        component: AlertOverview,
    },
    {
        path: '/alerts/events',
        exact: true,
        name: 'Alerts Events',
        component: EventsOverview,
    },
    {
        path: '/resources/status',
        exact: true,
        name: 'Resources Status List',
        component: ResourcesList,
    },
    {
        path: '/analysis/power',
        exact: true,
        name: 'Power',
        component: PowerChart,
        key: 'PowerChart'
    },
    {
        path: '/analysis/power/sensor/:sensorId/:channel',
        exact: true,
        name: 'Power',
        component: PowerChart,
        key: 'PowerChart'
    },
    {
        path: '/analysis/power/peak',
        exact: true,
        name: 'PowerPeak',
        component: PowerPeakBigNumberChart,
        key: 'PowerPeakBigNumberChart'
    },
    {
        path: '/analysis/power/peak/sensor/:sensorId',
        exact: true,
        name: 'PowerPeak',
        component: PowerPeakBigNumberChart,
        key: 'PowerPeakBigNumberChart'
    },
    {
        path: '/analysis/power/peak/sensor/:sensorId/:channel',
        exact: true,
        name: 'PowerPeak',
        component: PowerPeakBigNumberChart,
        key: 'PowerPeakBigNumberChart'
    },
    {
        path: '/config/users',
        exact: true,
        name: 'Users Overview',
        component: UsersOverview
    },
];

const rootRoutes = [
    {
        path: '/config/clients',
        exact: true,
        name: 'Clients',
        component: Tenants,
    }
];

const cookies = new Cookies();
const urlsToNotSave = ['reports/preview'];
const getUrl = pathname => pathname.replace(/[^/A-Za-z]/g, '')

const momentWeekConf = {
    week: {
        dow: 0,
        doy: 6,
    }
};

class Root extends React.Component {
    constructor (props) {
        super(props);

        const language = JSON.parse(localStorage.getItem('language'));
        moment.locale(keysMomentFront[!language ? 'en-GB' : language], momentWeekConf);

        if (moment.locale() === 'en-gb')
            this.customiseEnglishLocale();

        if (moment.locale() === 'ru')
            this.customiseRussianLocale();

        if (moment.locale() === 'pt-br')
            this.customiseBrazilLocale();

        this.state = {
            lastUrlLoaded: false,
            urlLoaded: false,
        };
    }

    componentWillMount () {
        let config = API.CONFIG();
        momentTz.tz.setDefault('UTC');

        if (getUserData() && process.env.NODE_ENV !== 'development') {
            ReactGa.initialize('UA-136314465-1', {
                gaOptions: {
                    userId: localStorage.getItem('userEmail')
                }
            });

            ReactGa.pageview(getUrl(window.location.pathname));
        }

        process.env.NODE_ENV === 'development' ?
            this.setState({urlLoaded: true}, () => this.checkLastUrl()) :
            config.then(() => {
                this.setState({urlLoaded: true}, () => this.checkLastUrl());
            });
    }

    componentDidUpdate () {
        if (this.state.redirect) this.setState({redirect: false});
    }

    changeMomentLanguage = (lang) => {
        if (process.env.NODE_ENV !== 'development') {
            ReactGa.event({
                category: 'ChangedLanguage',
                action: lang
            });
        }

        moment.locale(keysMomentFront[lang], momentWeekConf);
        if (moment.locale() === 'en-gb')
            this.customiseEnglishLocale();
        if (moment.locale() === 'ru')
            this.customiseRussianLocale();
        if (moment.locale() === 'pt-br')
            this.customiseBrazilLocale();
    }

    customiseEnglishLocale = () => {
        moment.updateLocale('en-gb', {
            longDateFormat : {
                ...customFormats['en-gb']
            }
        });
    }

    customiseRussianLocale = () => {
        moment.updateLocale('ru', {
            longDateFormat : {
                ...customFormats['ru']
            }
        });
    }

    customiseBrazilLocale = () => {
        moment.updateLocale('pt-br', {
            longDateFormat : {
                ...customFormats['pt-br']
            }
        });
    }

    checkLastUrl = () => {
        if (getUserData() && window.location.pathname === '/') {
            API.USERS.LAST_URL.GET().then((response) => {
                this.from = {pathname: response.data.lastUrl};
                this.setState({lastUrlLoaded: true, redirect: true});
            }).catch((error) => {
                console.error(error);
                this.setState({lastUrlLoaded: true});
            });
        } else this.setState({lastUrlLoaded: true});
    }

    render () {
        // redirects to default route if accessing root/home
        if (this.state.redirect && this.from.pathname === '/')
            this.from.pathname = defaultRoutePath;

        const AuthComponent = (props) => <Auth {...props} changeMomentLanguage={this.changeMomentLanguage} />

        return (
            this.state.urlLoaded && this.state.lastUrlLoaded ?
                <BrowserRouter>
                    <Switch>
                        {this.state.redirect ? <Redirect to={this.from.pathname} /> : null}
                        <Route path="/login"  component={AuthComponent} />
                        <Route path="/logout" component={AuthLogout} />

                        {menuRoutes.map((route, index) => (
                            <PrivateRoute
                                key={route.key ? route.key : index}
                                path={route.path}
                                exact={route.exact}
                                component={route.component}
                            />
                        ))}
                        {rootRoutes.map((route, index) => (
                            <RootRoute
                                key={route.key ? route.key : index}
                                path={route.path}
                                exact={route.exact}
                                component={route.component}
                            />
                        ))}
                        <Redirect to={defaultRoutePath} />
                    </Switch>
                </BrowserRouter> : null
        );
    }
}


class App extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            lastVizUrlLoaded: false,
            redirect: false,
            redirectPath: '',
        };
    }

    componentWillMount () {
        this.lastUrlUpdate();
        this.lastVizUrlLoad();
    }

    componentDidMount () {
        this.lastVizUrlUpdate();
    }

    componentDidUpdate() {
        if (process.env.NODE_ENV !== 'development') {
            ReactGa.pageview(getUrl(window.location.pathname));
        }

        //TODO Solve this strange behavior:
        // The render function executes twice even if setting redirect false here,
        // throwing the warning "You tried to redirect to the same route you're currently on",
        // and then componentDidUpdate is called twice.
        // If implemented using a var out of state, like this.redirect, the chart's state
        // gets lost and an error occurs.
        // Anyway, the feature works fine, it's a React's warning.
        if (this.state.redirect) this.setState({redirect: false, redirectPath: ''});

        this.lastUrlUpdate();
        this.lastVizUrlUpdate();
    }

    getChartHash = (chartName) => {
        const tenantName = cookies.get('tenantName') || 'default';
        return chartName + '-' + md5(tenantName);
    }

    lastVizUrlUpdate () {
        let url = this.props.location.pathname;
        url += url + this.props.location.search ? this.props.location.search : '';
        if (this.props.location.pathname.indexOf('/analysis/consumption/stream') >= 0){
            localStorage.setItem(this.getChartHash('stream-viz-url'), url);
        }else if (this.props.location.pathname.indexOf('/analysis/consumption/heatmap') >= 0){
            localStorage.setItem(this.getChartHash('heatmap-viz-url'), url);
        }else if (this.props.location.pathname.indexOf('/analysis/consumption/cumulative') >= 0){
            localStorage.setItem(this.getChartHash('cumulative-viz-url'), url);
        }else if (this.props.location.pathname.indexOf('/analysis/consumption/benchmarking') >= 0){
            localStorage.setItem(this.getChartHash('benchmark-line-viz-url'), url);
        }else if (this.props.location.pathname.indexOf('/analysis/consumption') >= 0){
            localStorage.setItem(this.getChartHash('line-viz-url'), url);
        }else if (this.props.location.pathname.indexOf('/analysis/power/peak') >= 0){
            localStorage.setItem(this.getChartHash('power-peak-viz-url'), url);
        }else if (this.props.location.pathname.indexOf('/analysis/power') >= 0){
            localStorage.setItem(this.getChartHash('power-line-viz-url'), url);
        }else if (this.props.location.pathname.indexOf('/analysis/cost/simulation') >= 0){
            localStorage.setItem(this.getChartHash('cost-line-viz-url'), url);
        }else if (this.props.location.pathname.indexOf('/analysis/cost') >= 0){
            localStorage.setItem(this.getChartHash('cost-bar-viz-url'), url);
        }
    }

    lastVizUrlLoad () {
        if (!this.state.lastVizUrlLoaded){
            if (this.props.location.pathname === '/analysis/consumption/stream' && this.props.location.search === ''){
                let storedStreamgraphUrl = localStorage.getItem(this.getChartHash('stream-viz-url'));
                if (storedStreamgraphUrl && storedStreamgraphUrl !== this.props.location.pathname){
                    this.setState({
                        redirect: true,
                        lastVizUrlLoaded: true,
                        redirectPath: storedStreamgraphUrl
                    });
                }
            }else if (this.props.location.pathname === '/analysis/consumption/heatmap' && this.props.location.search === ''){
                let storedHeatmapUrl = localStorage.getItem(this.getChartHash('heatmap-viz-url'));
                if (storedHeatmapUrl && storedHeatmapUrl !== this.props.location.pathname){
                    this.setState({
                        redirect: true,
                        lastVizUrlLoaded: true,
                        redirectPath: storedHeatmapUrl
                    });
                }
            }else if (this.props.location.pathname === '/analysis/consumption/cumulative' && this.props.location.search === ''){
                let storedCumulativeUrl = localStorage.getItem(this.getChartHash('cumulative-viz-url'));
                if (storedCumulativeUrl && storedCumulativeUrl !== this.props.location.pathname){
                    this.setState({
                        redirect: true,
                        lastVizUrlLoaded: true,
                        redirectPath: storedCumulativeUrl
                    });
                }
            }else if (this.props.location.pathname === '/analysis/consumption/benchmarking' && this.props.location.search === ''){
                let storedLineBenchmarkUrl = localStorage.getItem(this.getChartHash('benchmark-line-viz-url'));
                if (storedLineBenchmarkUrl && storedLineBenchmarkUrl !== this.props.location.pathname){
                    this.setState({
                        redirect: true,
                        lastVizUrlLoaded: true,
                        redirectPath: storedLineBenchmarkUrl
                    });
                }
            }else if (this.props.location.pathname === '/analysis/consumption' && this.props.location.search === ''){
                let storedLineChartUrl = localStorage.getItem(this.getChartHash('line-viz-url'));
                if (storedLineChartUrl && storedLineChartUrl !== this.props.location.pathname){
                    this.setState({
                        redirect: true,
                        lastVizUrlLoaded: true,
                        redirectPath: storedLineChartUrl
                    });
                }
            }else if (this.props.location.pathname === '/analysis/power/peak' && this.props.location.search === ''){
                let storedPowerPeakChartUrl = localStorage.getItem(this.getChartHash('power-peak-viz-url'));
                if (storedPowerPeakChartUrl && storedPowerPeakChartUrl !== this.props.location.pathname){
                    this.setState({
                        redirect: true,
                        lastVizUrlLoaded: true,
                        redirectPath: storedPowerPeakChartUrl
                    });
                }
            }else if (this.props.location.pathname === '/analysis/power' && this.props.location.search === ''){
                let storedPowerLineChartUrl = localStorage.getItem(this.getChartHash('power-line-viz-url'));
                if (storedPowerLineChartUrl && storedPowerLineChartUrl !== this.props.location.pathname){
                    this.setState({
                        redirect: true,
                        lastVizUrlLoaded: true,
                        redirectPath: storedPowerLineChartUrl
                    });
                }
            }else if (this.props.location.pathname === '/analysis/cost/simulation' && this.props.location.search === ''){
                let storedCostLineChartUrl = localStorage.getItem(this.getChartHash('cost-line-viz-url'));
                if (storedCostLineChartUrl && storedCostLineChartUrl !== this.props.location.pathname){
                    this.setState({
                        redirect: true,
                        lastVizUrlLoaded: true,
                        redirectPath: storedCostLineChartUrl
                    });
                }
            }else if (this.props.location.pathname === '/analysis/cost' && this.props.location.search === ''){
                let storedCostBarChartUrl = localStorage.getItem(this.getChartHash('cost-bar-viz-url'));
                if (storedCostBarChartUrl && storedCostBarChartUrl !== this.props.location.pathname){
                    this.setState({
                        redirect: true,
                        lastVizUrlLoaded: true,
                        redirectPath: storedCostBarChartUrl
                    });
                }
            }
        }
    }

    lastUrlUpdate () {
        let url = this.props.location.pathname;
        url += this.props.location.search ? this.props.location.search : '';
        const filter = urlsToNotSave.filter((val) => url.indexOf(val) >= 0);
        if (!filter.length) API.USERS.LAST_URL.PUT(url).catch((error) => console.error(error));
    }

    render () {
        if (this.state.redirect && this.state.redirectPath !== ''){
            return <Redirect to={this.state.redirectPath} />;
        }

        return (
            <IntlProvider locale="en">
                <MiniDrawer routes={menuRoutes} location={this.props.children.props.location}>
                    <section id="page-wrapper">
                        {this.props.children}
                    </section>
                </MiniDrawer>
            </IntlProvider>
        );
    }
}
App = withRouter(App);


ReactDOM.render(<Root />, document.getElementById('root'));
unregister();

export {App};
