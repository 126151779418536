import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import classnames from 'classnames';
import { CardContent } from 'material-ui/Card';
import Collapse from 'material-ui/transitions/Collapse';
import IconButton from 'material-ui/IconButton';
import Typography from 'material-ui/Typography';
import MainSensorIcon from 'material-ui-icons/Memory';
import ExpandMoreIcon from 'material-ui-icons/ExpandMore';

import {getSensorStructured} from '../../components/utils';

const styles = theme => ({
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
        marginLeft: 'auto',
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    contentRoot:{
        display: 'flex',
        alignItems: 'center',
        paddingTop: '4px',
        paddingRight: '6px',
        paddingBottom: '0px',
        paddingLeft: '10px',
        '&:last-child' : {
            paddingTop: '6px',
            paddingBottom: '0px',
        }
    },
    subloadContentRoot:{
        padding: '0 10px',
        '&:last-child' : {
            paddingTop: '0px',
            paddingBottom: '6px',
        }
    },
    mainSensor: {
        display: 'flex',
        alignItems: 'center',
    },
    mainSensorSimple: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '30px'
    },
    mainSensorIcon: {
        marginRight: '6px'
    },
    sensorsList: {
        paddingLeft: '50px',
        listStyleType: 'none',
    },
    typographyTitle: {
        fontSize: '1rem',
        fontWeight: 600
    },
    typographySubheading: {
        fontSize: '0.9rem',
        //fontWeight: 600
    },
    expandMoreButtonRoot: {
        width: '26px',
        height: '26px',
    },
});

class GroupCardSubgroup extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            expanded: false,
        }
    }

    handleExpandClick = () => {
        this.setState({ expanded: !this.state.expanded });
        this.props.setCardExpanded(!this.state.expanded);
    };

    renderMainSensors = (mainSensors) => {
        const { classes } = this.props;

        let sensorsLabels = null;
        if (mainSensors && mainSensors.length > 0){
            sensorsLabels = mainSensors.map((main) => {
                return main.id + "-" + main.channel;
            });
        }

        let listItems = null;
        if (sensorsLabels){
            listItems = sensorsLabels.map((sensorLabel,i) => {
                let sensor = <Typography variant="title" color="textSecondary" classes={{title: classes.typographyTitle}}>{sensorLabel}</Typography>
                 if (i === 0)
                    return <li key={i} className={classes.mainSensor}><MainSensorIcon className={classes.mainSensorIcon} />{sensor}</li>;

                return <li key={i} className={classes.mainSensorSimple}>{sensor}</li>;
            });
        }

        let mainSensorsList = null;
        if (listItems){
            mainSensorsList = <ul>{listItems}</ul>
        }

        return mainSensorsList;
    }

    renderSubloadSensors = (sensors) =>{
        const { classes } = this.props;

        let sensorsLabels = null;

        if (sensors && sensors.length > 0){
            sensorsLabels = sensors.map((sensor) => {
                return getSensorStructured(sensor).label;
            });
        }

        let listItems = null;
        if (sensorsLabels){
            listItems = sensorsLabels.map((sensorLabel,i) => {
                let sensor = <Typography variant="subheading" color="textSecondary" classes={{subheading: classes.typographySubheading}}>{sensorLabel}</Typography>
                return <li key={i} className={classes.sensor}>{sensor}</li>;
            });
        }

        let sensorsList = null;
        if (listItems){
            sensorsList = <ul className={classes.sensorsList}>{listItems}</ul>
        }

        return sensorsList;
    }


    render() {
        const { classes, data } = this.props;

        return (
            <div key={data.id}>
                <CardContent classes={{root: classes.contentRoot}}>
                    <Typography variant="title" color="textSecondary" classes={{title: classes.typographyTitle}}>{data.name}</Typography>
                    <IconButton
                        className={classnames(classes.expand, {
                            [classes.expandOpen]: this.state.expanded,
                        })}
                        classes={{root: classes.expandMoreButtonRoot}}
                        onClick={this.handleExpandClick}
                        aria-expanded={this.state.expanded}
                        aria-label="Show more">
                        <ExpandMoreIcon />
                    </IconButton>
                </CardContent>
                <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                    <CardContent classes={{root: classes.subloadContentRoot}}>
                        {(data.sensors && data.sensors.length > 0) ?
                            this.renderMainSensors(data.sensors)
                            :null
                        }
                        {(data.subGroups && data.subGroups.length > 0
                            && data.subGroups[0].sensors && data.subGroups[0].sensors.length > 0) ?
                            this.renderSubloadSensors(data.subGroups[0].sensors)
                            :null
                        }
                    </CardContent>
                </Collapse>
            </div>
        );
    }
}

GroupCardSubgroup.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GroupCardSubgroup);
