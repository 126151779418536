import React, { PureComponent } from 'react';
import { injectIntl, intlShape } from 'react-intl';

import { Title } from '../../elements/title';
import { Button } from '../../elements/button';
import { Input as VInput } from '../../elements/forms/input';

import i18n from '../../i18n';

const initialState = {
    recipient: '',
    fieldsWithError: {},
};

class ReportAddRecipient extends PureComponent {
    constructor (props) {
        super(props);

        this.state = initialState;
    }

    changeRecipient = ({ target }) => this.setState({
        recipient: target.value
    })

    submit = (e) => {
        e.preventDefault();
        e.target.querySelector('button[type="submit"]').blur();

        const emailReg = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

        const { recipient } = this.state
        const { recipients } = this.props

        if (!recipient || !emailReg.test(recipient)) {
            return this.setState({
                fieldsWithError: {
                    recipient: i18n.t('Invalid')
                }
            })
        }

        if (recipients.includes(recipient)) {
            return this.setState({
                fieldsWithError: {
                    recipient: i18n.t('Already exists')
                }
            })
        }

        this.props.addRecipient(recipient)
    }

    render () {
        const {
            fieldsWithError,
            recipient,
            submitting,
        } = this.state

        return (
            <section id="add-report">
                <Title element="h4" text={i18n.t("New Recipient")} />

                <form action="#" onSubmit={this.submit}>
                    <div className="form-content">
                        <div className="form-sections">
                            <VInput
                                change={this.changeRecipient}
                                hasError={fieldsWithError.recipient}
                                value={recipient}
                                name="recipient"
                                placeholder={i18n.t("Recipient e-mail")}
                                type="email"
                            />
                        </div>
                    </div>
                    <div className="buttons-content">
                        <Button
                            action="preview"
                            label={i18n.t("Cancel")}
                            click={this.props.cancel}
                        />

                        <Button
                            type="submit"
                            action="submit"
                            disabled={submitting}
                            label={i18n.t("Add Recipient")}
                        />
                    </div>
                </form>
            </section>
        );
    }
}

ReportAddRecipient.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(ReportAddRecipient);
