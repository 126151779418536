import React from 'react';
import axios from 'axios';
import d3 from 'd3';
import moment from 'moment-timezone';
import { injectIntl, intlShape } from 'react-intl'
import { withStyles } from 'material-ui/styles';

import {API} from '../../../components/api';
import {adjustJSONToValidJSON, getSensorStructured} from '../../../components/utils';

import Button from 'material-ui/Button';
import Switch from 'material-ui/Switch';
import {Text} from '../../../elements/text';
import {format} from 'd3-format'
import DatePicker from '../filter-date-picker'
import SystemSnackbar from '../../../elements/material-ui/SystemSnackbar'
import TariffCompare from './tariff-compare'
import CostSimulationLineChart from './cost-simulation-line-chart';
import CostSimulationResumeTable from './cost-simulation-resume-table';

import IconButton from 'material-ui/IconButton';
import ExpandMoreIcon from 'material-ui-icons/ArrowDropDown';

import i18n from '../../../i18n';

import 'nvd3/build/nv.d3.min.css'
import 'react-select/dist/react-select.css';

const styles = {
    switchBase: {
        height: '34px',
        '& + $bar': {
            backgroundColor: 'green',
        },
        '&$checked': {
            '& + $bar': {
                backgroundColor: 'green',
            },
        },
    },
    switchBaseOnOff: {
        height: '34px',
        '& + $bar': {
            backgroundColor: '#b5b5b5',
        },
        '&$checked': {
            '& + $bar': {
                backgroundColor: 'green',
            },
        },
    },
    disabled: {
        '&$switchBase': {
            '& + $bar': {
                backgroundColor: 'gray',
            },
            '&$checked': {
                '& + $bar': {
                    backgroundColor: 'gray',
                },
            },
        },
    },
    bar: {},
    checked: {},
};

// category20 color scheme (from D3v3) rearranged for darker first, then lighter
// (instead of dark blue, light blue, dark green, light green...)
const colors = ["#1f77b4","#ff7f0e","#2ca02c","#d62728","#9467bd","#8c564b","#e377c2","#7f7f7f","#bcbd22","#17becf","#aec7e8","#ffbb78","#98df8a","#ff9896","#c5b0d5","#c49c94","#f7b6d2","#c7c7c7","#dbdb8d","#9edae5"];

const kwhFormat = (d) => {
    if (d < 1){
        return format(".2r")(d);
    }else{
        return format(".2f")(d);
    }
}

const noDataColor = "#000000";

// const collapseJSSstyle = theme => ({
//   entered: {overflow: 'visible'}
// });

class CostSimulation extends React.PureComponent {
    constructor (props) {
        super(props);
        let today = moment();

        this.loadErrorMessage = "An error occurred while retrieving data.";

        this.costGranularities = [
            { label: i18n.t('1 Hour'), value: 'ONE_HOUR', minutes: 60 },
            { label: i18n.t('1 Day'), value: 'ONE_DAY', minutes: 1440 }
        ];

        this.periodOptions = [
            {label: i18n.t('Day'), value: 'day'},
            {label: i18n.t('Week'), value: 'week'},
            {label: i18n.t('Month'), value: 'month'},
            {label: i18n.t('Custom'), value: 'custom'},
        ];

        this.state = {
            compare: [],
            filterGroupOptions: [],
            filterSubgroupOptions: [],
            filterSensorOptions: [],
            dataGranularity: 'FIVE_MINUTES',
            loadingGroups: true,
            loadingTariffs: true,
            loadingData: false,
            periodFilter: 'day',
            filterGroup: null,
            filterSubgroup: null,
            filterSensor: null,
            isLinear: false,
            breadcrumbsTrail: '',
            groups: [],
            tariffs:[],
            events: [],
            loadedData: 0,
            requestsToDraw: 0,
            currency: "",
            min: {
                consumption: null,
                cost: 0,
                hour: '00:00:00',
            },
            max: {
                consumption: null,
                cost: 0,
                hour: '00:00:00',
            },
            maxes: [],
            datePicker: {
                open: false,
                endDate: today,
                startDate: today,
                data: {
                    endDate: today,
                    focusedInput: 'startDate',
                    startDate: today,
                },
            },
            dateRangePicker: {
                open: false,
                endDate: today,
                startDate: today.clone().subtract(2, 'days'),
                data: {
                    endDate: today,
                    focusedInput: 'endDate',
                    startDate: today.clone().subtract(2, 'days'),
                },
            },
            hadScreenResize: false,
            diffOfSelectedDays: 0,
            noGroupSetupError: false,
            loadError: false,
            simulationIndex: 0,
            loadedFiltersURL: '',
        };

        this.maximumDate = today.clone().add(1, 'day').endOf('day');
        this.willLoadData = true;
        this.costFormat = (currency, value) => {
            return currency && value > 0 ? `${this.props.intl.formatNumber(value, {style: 'currency', currency: currency})}` : ' - '
        }
        this.compareIndexOpen = undefined;
        this.keyColorMap = {};
        this.granularitiesMap = new Map(this.costGranularities.map(granularity => [granularity.value, granularity]));
        this.tariffKeyNameMap = new Map();
    }

    componentWillMount = async () => {
        await this.getTariffs()

        API.GROUPS.GET().then((response) => {
            if (response.data && response.data.length > 0){
                this.groupsNamesMap = this.createGroupsNamesMap(response.data);
                this.sensorsPhasesNamesMap = this.createSensorsPhasesNamesMap(response.data);
                this.setState({
                    groups: response.data,
                    loadingGroups: false,
                    filterGroupOptions: response.data.map(group => { return {name: group.name, id: group.id} }),
                }, this.checkGeneralChangesOnComponent);
            }else if (response.data && response.data.length === 0){
                this.setState({
                    loadingGroups: false,
                    noGroupSetupError: true,
                });
            }else{
                this.handleLoadError({loadingGroups: false}, "Could not load groups data.")
            }
        }).catch((error) => {
            this.handleLoadError({loadingGroups: false}, "Could not load groups data.", error)
        });
    }

    componentWillReceiveProps (nextProps) {
        this.checkGeneralChangesOnComponent(nextProps);
    }

    componentDidUpdate () {
        if (this.state.breadcrumbsTrail !== this.getBreadcrumbsTrail()){
            this.setState({
                breadcrumbsTrail: this.getBreadcrumbsTrail()
            });
        }

        // fixes tooltip mark that stayed on the chart when changing data
        d3.selectAll('.nvtooltip').remove();
    }

    getTariffs = async () => {
        try {
            const { data } = await API.TARIFFS.GET()

            if (data) {
                this.setState({
                    tariffsMap: data.reduce((prev, cur) => ({ ...prev, [cur.id]: cur.name }), {}),
                    tariffs: data,
                    loadingTariffs: false,
                });
            }else{
                this.handleLoadError({loadingTariffs: false}, "Could not load the tariffs.")
            }
        } catch (error) {
            this.handleLoadError({loadingTariffs: false}, "Could not load the tariffs.", error)
        }
    }

    awaitLoading = () => new Promise(resolve => setTimeout(resolve, 700))

    awaitChartData = (state, promises) => {
        axios.all(promises).then(async (responses) => {

            await this.awaitLoading()

            state.maxes = [];
            state.rawData = [];
            state.tariffsKey = [];
            state.requestsToDraw = responses.length;

            responses.forEach((response, index) => {
                if (response.data) {
                    response.data.data = response.data.data ? this.ensurePositiveValues(response.data.data) : [];

                    const rawChartData = response.data.data;

                    if (!rawChartData.length) {
                        state.requestsToDraw = state.requestsToDraw - 1;
                    } else {
                        state.tariffsKey.push(response.data.tariffs[0].id);
                        this.setKeysArray(state.tariffsKey);
                    }

                    const lastCurrency = (response.data.tariffs && response.data.tariffs.length > 0) ? response.data.tariffs[0].currency : null;

                    let min = response.data.minDayAndHourCost || response.data.minHourCost || response.data.minDayCost;
                    if (min && min.cost < 0){
                        const minFromData = this.getMinimumFromData(rawChartData);
                        if (minFromData){
                            min = minFromData;
                        }else{
                            min.cost = 0;
                        }
                    }

                    const max = response.data.maxDayAndHourCost ||
                        response.data.maxHourCost ||
                        response.data.maxDayCost ||
                        {
                            consumption: null,
                            cost: 0,
                            hour: '00:00:00',
                        };
                    const maxes = state.maxes ? state.maxes.concat(max) : [].concat(max);

                    if (response.data.tariffs && response.data.tariffs.length){
                        response.data.tariffs.forEach(tariff => this.tariffKeyNameMap.set(tariff.id, tariff.name));
                    }

                    Object.assign(state, {
                        loadingData: false,
                        breadcrumbsTrail: this.getBreadcrumbsTrail(),
                        currency: lastCurrency,
                        max: maxes.reduce((max, cur) => (max.cost > cur.cost) ? max : cur, 0),
                        maxes: maxes,
                        rawData: (state.rawData) ? state.rawData.concat(response.data) : [].concat(response.data),
                        simulationIndex: this.state.simulationIndex + 1,
                        loadedFiltersURL: this.props.location.pathname + this.props.location.search,
                    });
                    Object.assign(state.compare[index], {
                        average: response.data.avgCost,
                        max: max,
                        min: min,
                        total: response.data.totalConsumption,
                        totalCost: response.data.totalCost,
                    });
                    this.setState(state);
                }else{
                    this.handleLoadError({loadingData: false}, "Could not load chart data.")
                }
            });

            this.willLoadData = false;
        }).catch((error) => {
            this.handleLoadError({loadingData: false}, "Could not load chart data.", error)
        });
    }

    getCompareAndLoadChartData = (state) => {
        this.setState({ loadedData: 0 });

        const ids = this.getIdsJSONFromURL(window.location.search);

        ids.forEach((obj, index) => {
            const compare = (this.willLoadData || !this.state.compare[index])
                ? {}
                : this.state.compare[index];
            let tariffData;

            compare.open = obj.open;
            if (obj.id) {
                tariffData = this.getTariffSelectedById(obj.id, 0);
                if (tariffData) {
                    Object.assign(compare, {
                        ...tariffData,
                        startDate: {
                            open: false,
                            startDate: obj.startDate ? moment(obj.startDate, 'YYYYMMDD') : null,
                            endDate: obj.startDate ? moment(obj.startDate, 'YYYYMMDD') : null,
                            data: {
                                focusedInput: 'startDate',
                                startDate: obj.startDate ? moment(obj.startDate, 'YYYYMMDD') : null,
                                endDate: obj.startDate ? moment(obj.startDate, 'YYYYMMDD') : null,
                            }
                        },
                        endDate: {
                            open: false,
                            startDate: obj.endDate ? moment(obj.endDate, 'YYYYMMDD') : null,
                            endDate: obj.endDate ? moment(obj.endDate, 'YYYYMMDD') : null,
                            data: {
                                focusedInput: 'startDate',
                                startDate: obj.endDate ? moment(obj.endDate, 'YYYYMMDD') : null,
                                endDate: obj.endDate ? moment(obj.endDate, 'YYYYMMDD') : null,
                            }
                        }
                    });
                }
            }

            state.compare.push(compare);
        });

        if (this.willLoadData)
            this.simulateCosts(state);

        return state;
    }

    addLoadedData = response => {
        this.setState({
            loadedData: this.state.loadedData + 1
        })
        return response
    }

    getTariffSelectedById = (id) => {
        const tariff = id ? this.state.tariffs.find((tariff) => tariff.id === id) : this.state.tariffs[0];
        if (id && !tariff) {
            this.goToURL(this.getFullURL([{id: this.state.tariffs[0].id}]));
            return;
        }

        return {
            filterTariff: tariff,
        };
    }

    checkGeneralChangesOnComponent = (nextProps) => {
        const props = nextProps || this.props;

        if (!this.state.groups || !this.state.groups.length || !this.state.tariffs || !this.state.tariffs.length) {
            return;
        }

        if (!props.match.params.groupId) {
            this.props.history.push(`${this.props.history.location.pathname}/group/${this.state.groups[0].id}/`);
            return;
        }

        const searchParams = new URLSearchParams(props.location.search);

        if (!props.location.search || !searchParams.get('ids') || searchParams.get('ids') === 'null') {
            if (this.state.tariffs && this.state.tariffs.length)
                this.props.history.push(`/analysis/cost/simulation/${props.match.params.groupId
                    ? `group/${props.match.params.groupId}`
                    : `group/${this.state.groups[0].id}`
                }?ids=[{id:${this.state.tariffs[0].id}}]`);
            return;
        }

        let state = {
            compare: [],
            datePicker: this.state.datePicker,
            dateRangePicker: this.state.dateRangePicker
        }, startDate, endDate, groupData, subgroupData, sensorData;

        if (props.match.params.groupId) {
            groupData = this.getGroupSelectedById(parseInt(props.match.params.groupId, 0));
            if (!groupData) return;
            Object.assign(state, groupData);
        }

        if (props.match.params.subgroupId) {
            subgroupData = this.getSubgroupSelectedById(parseInt(props.match.params.subgroupId, 0), state.filterGroup);
            if (!subgroupData) return;
            Object.assign(state, subgroupData);
        }
        if (props.match.params.sensorId && props.match.params.channel) {
            sensorData = this.getSensorSelectedById(parseInt(props.match.params.sensorId, 0), parseInt(props.match.params.channel, 0), state);
            if (!sensorData) return;
            Object.assign(state, sensorData);
        }

        state.periodFilter = searchParams.get('period') ? searchParams.get('period') : 'day';
        if (searchParams.get('date')) {
            startDate = moment(searchParams.get('date'));

            if (state.periodFilter === 'week') {
                startDate = startDate.startOf('week');
                endDate = startDate.clone().endOf('week');
                endDate = endDate.isAfter(this.maximumDate) ? this.maximumDate : endDate;
            } else endDate = startDate;

            state.datePicker.open = false;
            state.datePicker.startDate = startDate;
            state.datePicker.endDate = endDate;
            state.datePicker.data.startDate = startDate;
            state.datePicker.data.endDate = endDate;
        }

        if (searchParams.get('startDate') && searchParams.get('endDate')) {
            startDate = moment(searchParams.get('startDate'));
            endDate = moment(searchParams.get('endDate'));

            state.dateRangePicker.open = false;
            state.dateRangePicker.startDate = startDate;
            state.dateRangePicker.endDate = endDate;
            state.dateRangePicker.data.startDate = startDate;
            state.dateRangePicker.data.endDate = endDate;
            state.diffOfSelectedDays = (startDate && endDate) ? endDate.diff(startDate, 'days') : 0;
        }

        state.isLinear = !searchParams.has('step');
        state.dataGranularity = searchParams.has('dataGranularity') ? searchParams.get('dataGranularity') : 'FIVE_MINUTES';

        state = this.getCompareAndLoadChartData(state);
        this.setState(state);
    }

    simulateCosts = (state) => {
        state = state || this.state;

        const promises = [];
        state.compare.forEach((compare, index) => {
            promises.push(state.filterSensor
                ? this.loadSensorChart(state, index)
                    : this.loadGroupChart(state, index)
            );
        });

        this.awaitChartData(state, promises);
    }

    createGroupsNamesMap = (groups) => {
        const idNamesPairsArray = groups.map((group) => {
            const groupPair = [group.id, group.name];
            const subgroupPair = group.subGroups.map((subgroup) => {
                return [subgroup.id, subgroup.name];
            })
            return subgroupPair.concat([groupPair]);
        }).reduce( (acc,x)=> acc.concat(x));

        return new Map(idNamesPairsArray);
    }

    // Create a Map that lets get the sensor name from sensor-phase
    createSensorsPhasesNamesMap = (groups) => {
        const sensorPhasesMap = new Map();
        groups.forEach((group) => {
            group.sensors.forEach((sensorPhase) => sensorPhasesMap.set(sensorPhase.id + "-" + sensorPhase.channel, sensorPhase.name));
            group.subGroups.forEach((subgroup) => {
                if (subgroup.sensors && subgroup.sensors.length > 0)
                    subgroup.sensors.forEach((sensorPhase) => sensorPhasesMap.set(sensorPhase.id + "-" + sensorPhase.channel, sensorPhase.name));

                if (subgroup.subGroups && subgroup.subGroups.length > 0){
                    subgroup.subGroups.forEach(innerSubgroup => {
                        if (innerSubgroup.sensors && innerSubgroup.sensors.length > 0)
                            innerSubgroup.sensors.forEach((sensorPhase) => sensorPhasesMap.set(sensorPhase.id + "-" + sensorPhase.channel, sensorPhase.name))
                    });
                }
            })
        });

        return sensorPhasesMap;
    }

    getGroupName = (groupId) => {
        return this.groupsNamesMap.get(+groupId);
    }

    getSensorPhaseName = (sensor, phase) =>
        this.sensorsPhasesNamesMap.get(sensor + '-' + phase)

    setKeysArray = (keysArray) => {
        this.createKeyColorMap(keysArray);
    }

    createKeyColorMap = (keysArray) => {
        const keyColorPairs = keysArray.reduce((pre, cur, i) => ({
            ...pre,
            [i]: colors[i % colors.length]
        }), {});

        this.keyColorMap = keyColorPairs;
    }

    getColor = (compareIndex) => {
        const compare = this.state.compare[compareIndex];

        if (compare && this.keyColorMap) {
            return this.keyColorMap[compareIndex] || noDataColor;
        }else{
            return noDataColor;
        }
    }

    getTariffName = (key) => {
        return this.tariffKeyNameMap.get(+key);
    }

    loadGroupChart = (state, index) => {
        d3.selectAll('.nv-chart svg .weekend-highlight').remove();
        d3.selectAll('.nv-chart svg .after-work-highlight').remove();

        this.setState({ loadingData: true });
        const groupId = state.filterSubgroup ? state.filterSubgroup.id : state.filterGroup.id;

        const dataGranularity = this.getGranularity(true, state);

        let startDate, endDate;
        switch (state.periodFilter) {
            case 'month':
                startDate = state.datePicker.startDate.clone().startOf('month').format('YYYY-MM-DD');
                endDate = state.datePicker.startDate.clone().endOf('month').format('YYYY-MM-DD');
                break;
            case 'week':
                startDate = state.datePicker.startDate.clone().startOf('week').format('YYYY-MM-DD');
                endDate = state.datePicker.startDate.clone().endOf('week').format('YYYY-MM-DD');
                break;
            case 'day':
                startDate = state.datePicker.startDate.format('YYYY-MM-DD');
                endDate = state.datePicker.startDate.format('YYYY-MM-DD');
                break;
            case 'custom':
                startDate = state.dateRangePicker.startDate.format('YYYY-MM-DD');
                endDate = state.dateRangePicker.endDate.format('YYYY-MM-DD');
                break;
            default:
                startDate = state.datePicker.startDate.format('YYYY-MM-DD');
                endDate = state.datePicker.startDate.format('YYYY-MM-DD');
                console.warn("An invalid period filter was found. Assuming day period for group load.");
        }

        return API.VISUALIZATIONS.COST.SIMULATION.CUSTOM.GROUP(
            groupId,
            state.compare[index].startDate && state.compare[index].startDate.startDate
                ? state.compare[index].startDate.startDate.format('YYYY-MM-DD')
                : startDate,
            state.compare[index].endDate && state.compare[index].endDate.endDate
                ? state.compare[index].endDate.endDate.format('YYYY-MM-DD')
                : endDate,
            state.compare[index].filterTariff.id,
            dataGranularity,
        ).then(this.addLoadedData);
    }

    loadSensorChart = (state, index) => {
        d3.selectAll('.nvtooltip').remove();
        d3.selectAll('.nv-chart svg .weekend-highlight').remove();
        d3.selectAll('.nv-chart svg .after-work-highlight').remove();

        this.setState({ loadingData: true });

        const dataGranularity = this.getGranularity(true, state);

        let startDate, endDate;
        switch (state.periodFilter) {
            case 'month':
                startDate = state.datePicker.startDate.clone().startOf('month').format('YYYY-MM-DD');
                endDate = state.datePicker.startDate.clone().endOf('month').format('YYYY-MM-DD');
                break;
            case 'week':
                startDate = state.datePicker.startDate.clone().startOf('week').format('YYYY-MM-DD');
                endDate = state.datePicker.startDate.clone().endOf('week').format('YYYY-MM-DD');
                break;
            case 'day':
                startDate = state.datePicker.startDate.format('YYYY-MM-DD');
                endDate = state.datePicker.startDate.format('YYYY-MM-DD');
                break;
            case 'custom':
                startDate = state.dateRangePicker.startDate.format('YYYY-MM-DD');
                endDate = state.dateRangePicker.endDate.format('YYYY-MM-DD');
                break;
            default:
                startDate = state.datePicker.startDate.format('YYYY-MM-DD');
                endDate = state.datePicker.startDate.format('YYYY-MM-DD');
                console.warn("An invalid period filter was found. Assuming day period for sensor load.");
        }

        return API.VISUALIZATIONS.COST.SIMULATION.CUSTOM.SENSOR(
            state.filterSensor.subgroupId,
            state.filterSensor.id,
            state.filterSensor.channel,
            state.compare[index].startDate && state.compare[index].startDate.startDate
                ? state.compare[index].startDate.startDate.format('YYYY-MM-DD')
                : startDate,
            state.compare[index].endDate && state.compare[index].endDate.endDate
                ? state.compare[index].endDate.endDate.format('YYYY-MM-DD')
                : endDate,
            state.compare[index].filterTariff.id,
            dataGranularity,
        ).then(this.addLoadedData);
    }

    getMinimumFromData = (data) => {
        let min = null;
        if (data && data.length){
            const dataWithoutNegativeValues = data.filter(d => d.defined);
            min = dataWithoutNegativeValues.reduce((min, d) => {
                return (!d.cost || d.cost < min.cost) ? d : min;
            }, dataWithoutNegativeValues[0]);
        }
        return min;
    }

    getGroupSubgroupsOptions = (group) => {
        if (group.subGroups && group.subGroups.length > 0){
            return group.subGroups.map(subgroup => {
                return {
                    groupId: group.id,
                    name: subgroup.name,
                    id: subgroup.id
                }
            });
        }else{
            return [];
        }
    }

    getSubgroupSensorsOptions = (subgroup) => {
        // non main subgroups sensors are stored in a "artificial" sub-subgroup
        if (subgroup.subGroups && subgroup.subGroups[0]
            && subgroup.subGroups[0].sensors
            && subgroup.subGroups[0].sensors.length > 0){

            return subgroup.subGroups[0].sensors.map(sensor => {
                return {...getSensorStructured(sensor), subgroupId: subgroup.subGroups[0].id} });
        }else{
            return [];
        }
    }


    // URL Functions
    goToURL = (url) => this.props.history.push(url);
    getFullURL = (ids) => `${this.props.location.pathname}${this.getIdsToURL(ids)}${this.getSearchURL()}`;
    getIdsJSONFromURL = (search) => {
        if (!search)
            search = this.props.location.search;
        const searchParams = new URLSearchParams(search);

        return searchParams.get('ids')
            ? JSON.parse(adjustJSONToValidJSON(searchParams.get('ids')))
            : [];
    }
    getIdsParsedToURL = (ids) => JSON.stringify(ids).replace(/"/g, '');
    getIdsToURL = (ids) => `?ids=${this.getIdsParsedToURL(ids)}`;

    getSearchURL = () => {
        let url = this.getDateURL();
        url = this.getPeriodFilterURL(false, url);
        if (!this.state.isLinear)
            url += '&step';
        return url;
    }

    getPeriodFilterURL = (fromSelect, url, period=this.state.periodFilter, date) => {
        if (fromSelect) {
            url = this.props.location.pathname;
            const ids = this.getIdsJSONFromURL().map(({ id }) => ({ id }));
            url += `?ids=${this.getIdsParsedToURL(ids)}`;
            url += this.getDateURL(period, date);
        }

        url.indexOf('period') >= 0 ? url = url.replace(/day|week|month|custom/, period) : url += `&period=${period}`;

        if (fromSelect && !this.state.isLinear)
            url += '&step';

        if (this.state.dataGranularity)
            url += `&dataGranularity=${this.state.dataGranularity}`

        return url;
    }

    getDateURL = (period=this.state.periodFilter, date) => {
        date = date ? date : period === 'custom' ? this.state.dateRangePicker : this.state.datePicker;

        if (period === 'custom'){
            let url = "";
            url += date.startDate ?
                `&startDate=${date.startDate.format('YYYY-MM-DD')}` : '';
            url += date.endDate ?
                `&endDate=${date.endDate.format('YYYY-MM-DD')}` : '';
            return url;
        }else{
            return date.startDate ?
                `&date=${date.startDate.format('YYYY-MM-DD')}` : '';
        }
    }

    // Close (the Collapse element) all compares, except the index, if you pass one
    closeCompares = (index, toClose) => {
        let ids = this.getIdsJSONFromURL(window.location.search);
        return ids;
    }

    addCompare = () => {
        if (this.state.tariffs && this.state.tariffs.length) {
            let ids = this.closeCompares();
            ids.push({ id: this.state.tariffs[0].id });
            this.goToURL(this.getFullURL(ids));
        }
    }

    updateCompare = (index, compare) => this.setState({
        compare: this.state.compare.map((obj, i) => i === index ? compare : obj)
    })

    getGroupSelectedById = (id) => {
        const group = id ? this.state.groups.find((group) => group.id === id) : this.state.groups[0];
        if (id && !group) {
            this.props.history.push(`/analysis/cost/group/${this.state.groups[0].id}`);
            return;
        }

        return {
            filterGroup: group,
            filterSubgroup: null,
            filterSubgroupOptions: this.getGroupSubgroupsOptions(group),
            filterSensor: null,
            filterSensorOptions: [],
        };
    }

    getSubgroupSelectedById = (id, group) => {
        const subgroup = group.subGroups.find((subgroup) => subgroup.id === id);
        if (id && !subgroup) {
            this.props.history.push(`/analysis/cost/group/${group.id}/subgroup/${group.subGroups[0].id}`);
            return;
        }

        return {
            filterSubgroup: subgroup,
            filterSensor: null,
            filterSensorOptions: this.getSubgroupSensorsOptions(subgroup),
        };
    }

    getSensorSelectedById = (id, channel, state) => {
        let sensor = state.filterSensorOptions.find(sensor => sensor.id === id && sensor.channel === channel);
        if (id && !sensor) {
            this.props.history.push(`/analysis/cost/group/${state.filterGroup.id}/subgroup/${state.filterSubgroup.id}`);
            return;
        }

        return {
            filterSensor: sensor,
        };
    }

    removeKeyColorMap = (index) => {
        delete this.keyColorMap[index];

        this.keyColorMap = Object.values(this.keyColorMap).reduce((prev, cur, i) => ({
            ...prev,
            [i]: cur
        }), {})
    }

    removeCompare = (index) => {
        let ids = this.getIdsJSONFromURL(window.location.search);
        ids.splice(index, 1);

        this.removeKeyColorMap(index);
        this.goToURL(this.getFullURL(ids));
    }

    getBreadcrumbsTrail = () => {
        const filterGroup = this.state.filterGroup;
        const filterSubgroup = this.state.filterSubgroup;
        const filterSensor = this.state.filterSensor;

        let trail = (filterGroup && filterGroup.name) ? '► ' + filterGroup.name : null;
        if (filterSubgroup && filterSubgroup.name) trail += ' / ' + filterSubgroup.name;
        if (filterSensor &&  filterSensor.label) trail += ' / ' + filterSensor.label;

        return trail;
    }

    getBreadcrumbsBySelected = (selected) => {
        const {filterGroup, filterSubgroup, filterSensor} = selected;

        let trail = (filterGroup && filterGroup.name) ? filterGroup.name : '';
        if (filterSubgroup && filterSubgroup.name) trail += ' / ' + filterSubgroup.name;
        if (filterSensor &&  filterSensor.label) trail += ' / ' + filterSensor.label;

        return trail;
    }

    // Ensure we have just positive values for the chart
    ensurePositiveValues = (data) => data.map((d) => {
        if (d.consumption < 0) {
            d.consumption = 0;
            d.defined = false;
        } else d.defined = true;
        return d;
    })


    changePeriodFilter = (period) => {
        let url = this.getPeriodFilterURL(true, '', period ? period.value : 'day');
        this.props.history.push(url);
    }

    goToPreviousPeriod = () => {
        let {datePicker} = this.state;
        datePicker.startDate.subtract(1, this.state.periodFilter);
        this.goToURL(this.getFullURL(this.getIdsJSONFromURL().map(({ id }) => ({ id }))));
    }

    goToNextPeriod = () => {
        let {datePicker} = this.state;
        datePicker.startDate.add(1, this.state.periodFilter);
        this.goToURL(this.getFullURL(this.getIdsJSONFromURL().map(({ id }) => ({ id }))));
    }

    goToCurrentDay = () => {
        const url = this.getPeriodFilterURL(true, '', this.state.periodFilter, {startDate: moment()});
        this.goToURL(url);
    }

    toggleLinear = () => {
        let searchParams = new URLSearchParams(window.location.search);
        let url = this.getFullURL(this.getIdsJSONFromURL());
        url = searchParams.has('step') ? url.replace('&step', '') : `${url}&step`;
        this.willLoadData = false;
        this.goToURL(url);
    }

    handleLoadError = (state, message, error) => {
        this.setState({
            ...state,
            loadError: true,
        });

        console.error(this.loadErrorMessage + " (" + message + ")");
        if (error)
            console.error(error);
    }

    // Date period select functions
    updateDateRangePicker = (dateRangePicker, removeTooltip) => {
        this.setState({dateRangePicker});
        if (removeTooltip)
            d3.selectAll('.nvtooltip').remove();
    }

    updateDatePicker = (datePicker, removeTooltip) => {
        this.setState({datePicker});
        if (removeTooltip)
            d3.selectAll('.nvtooltip').remove();
    }
    // /Date period select functions

    getGranularity = (getValue, state) => {
        if (!state)
            state = this.state;

        const { periodFilter, dateRangePicker: { startDate, endDate }} = state;
        const diffDays = endDate.diff(startDate, 'days')

        const propToReturn = (getValue) ? 'value' : 'label';

        if (periodFilter === 'day' || (periodFilter === 'custom' && diffDays <= 1)) {
            return this.granularitiesMap.get('ONE_HOUR')[propToReturn];
        }

        return this.granularitiesMap.get('ONE_DAY')[propToReturn];
    }

    changeGranularity = granularity => {
        const { dataGranularity } = this.state;
        let searchParams = new URLSearchParams(window.location.search);
        let search = window.location.search;

        if (granularity && granularity.value){
            if (!searchParams.has('dataGranularity')) {
                search = search + `&dataGranularity=${granularity.value}`;
            } else {
                search = search.replace(`&dataGranularity=${dataGranularity}`, `&dataGranularity=${granularity.value}`);
            }
        }

        this.props.history.push(window.location.pathname + search);
    }

    isButtonNowDisabled = () => moment().isSame(this.state.datePicker.startDate, this.state.periodFilter)

    openCalendar = () => {
        let {datePicker, dateRangePicker} = this.state;
        this.state.periodFilter === 'custom' ? dateRangePicker.open = true : datePicker.open = true;
        this.setState({datePicker: datePicker, dateRangePicker: dateRangePicker});
    }

    getRoundedTimestamp = (timestamp) =>{
        const { periodFilter, dateRangePicker: { startDate, endDate }, dataGranularity } = this.state;
        const diffDays = endDate.endOf('day').diff(startDate.startOf('day'), 'days', true);
        let roundedTimestamp = null;

        if (periodFilter === 'day') {
            const found = this.costGranularities.find(granularity => granularity.value === dataGranularity);
            if (found.value === 'ONE_HOUR'){
                roundedTimestamp = moment(timestamp).startOf('hour');
            }else{
                roundedTimestamp = moment(timestamp).minutes(found.minutes).seconds(0).milliseconds(0);
            }
        }else if (periodFilter === 'custom' && diffDays <= 2) {
            roundedTimestamp = moment(timestamp).minutes(5).seconds(0).milliseconds(0);
        } else if (periodFilter === 'week' || (periodFilter === 'custom' && diffDays <= 31)) {
            roundedTimestamp = moment(timestamp).startOf('hour');
        }else{
            roundedTimestamp = moment(timestamp).startOf('day');
        }
        return roundedTimestamp;
    }

    getEventNames(events){
        return events.reduce((acc, event) => acc.concat(event.values.map(v => v.name)), []);
    }

    updateReloadData = boll => this.willLoadData = boll

    render () {
        const { compare, periodFilter, loadingData } = this.state
        const { classes, location } = this.props

        const isButtonNowDisabled = moment().isSame(this.state.datePicker.startDate, this.state.periodFilter);

        return (
            <div id="cost-simulation">
                <div id="line-chart">
                    <div id="top-bar">
                        <div className={`top top-period-filter-${periodFilter}`}>
                            <div>
                                {this.periodOptions.map((period, key) => (
                                    <Button
                                        className={`btn-period-filter${periodFilter === period.value ? ' active' : ''}`}
                                        type="button"
                                        disabled={
                                            loadingData ||
                                            periodFilter === period.value ||
                                            (!this.state.loadingGroups && this.state.groups.length === 0)
                                        }
                                        key={key}
                                        onClick={() => this.changePeriodFilter(period)}
                                    >
                                        {period.label}
                                    </Button>
                                ))}
                            </div>

                            <div>
                                {periodFilter !== 'custom' ?
                                    <IconButton className="btn-previous btn-previous-date"
                                        disabled={loadingData}
                                        onClick={this.goToPreviousPeriod}>
                                        <ExpandMoreIcon />
                                    </IconButton> : null}

                                <DatePicker
                                    clearIds
                                    chartType={'line-chart'}
                                    datePicker={this.state.datePicker}
                                    dateRangePicker={this.state.dateRangePicker}
                                    updateDatePicker={this.updateDatePicker}
                                    updateDateRangePicker={this.updateDateRangePicker}
                                    periodFilter={periodFilter}
                                    dataGranularity={this.state.dataGranularity}
                                    isLinear={this.state.isLinear}
                                    loadingGroups={this.state.loadingGroups}
                                    history={this.props.history}
                                    disabled={!this.state.loadingGroups && this.state.groups.length === 0}
                                    showLabel={false} />

                                {periodFilter !== 'custom' ?
                                    <IconButton className="btn-next btn-next-date"
                                        disabled={
                                            loadingData ||
                                            this.state.datePicker.startDate.isSameOrAfter(
                                                moment().startOf(periodFilter))
                                        }
                                        onClick={this.goToNextPeriod}>
                                        <ExpandMoreIcon />
                                    </IconButton> : null}

                                {periodFilter !== 'custom' ?
                                    <Button className={`btn-period${isButtonNowDisabled ? ' active' : ''}`}
                                        disabled={
                                            loadingData ||
                                            isButtonNowDisabled ||
                                            (!this.state.loadingGroups && this.state.groups.length === 0)
                                        }
                                        onClick={() => this.goToCurrentDay()}>
                                        {i18n.t('NOW')}
                                    </Button> : null}
                            </div>

                            <div className="granularity">
                                <div>
                                    <Text text={this.getGranularity()} />
                                </div>
                            </div>
                        </div>
                        {!this.state.loadingGroups && this.state.groups.length > 0 && (
                            <div className="bottom">
                                <TariffCompare
                                    updateReloadData={this.updateReloadData}
                                    location={this.props.location}
                                    history={this.props.history}
                                    periodFilter={this.state.periodFilter}
                                    addCompare={this.addCompare}
                                    updateCompare={this.updateCompare}
                                    isLinear={this.state.isLinear}
                                    dateRangePicker={this.state.dateRangePicker}
                                    datePicker={this.state.datePicker}
                                    dataGranularity={this.state.dataGranularity}
                                    filterGroup={this.state.filterGroup}
                                    filterSubgroup={this.state.filterSubgroup}
                                    filterSensor={this.state.filterSensor}
                                    filterSensorOptions={this.state.filterSensorOptions}
                                    filterSubgroupOptions={this.state.filterSubgroupOptions}
                                    compare={compare.map((comp, index) => ({
                                        ...comp,
                                        color: this.getColor(index)
                                    }))}
                                    filterGroupOptions={this.state.filterGroupOptions}
                                    removeCompare={this.removeCompare}
                                    tariffs={this.state.tariffs}
                                    loading={loadingData}
                                    simulateCosts={this.simulateCosts}
                                    hadChangeOnFilters={this.state.loadedFiltersURL !== location.pathname + location.search}
                                    noTariffSetupError={!this.state.loadingTariffs && (!this.state.tariffs || !this.state.tariffs.length)}
                                />
                            </div>
                        )}
                    </div>
                    <div id="graph">
                        <div className="switch">
                            <p>{i18n.t('Graph interpolation')}</p>
                            <span className={`label ${this.state.isLinear ? 'active' : ''}`}>
                                {i18n.t('Linear')}
                            </span>
                            <Switch
                                checked={!this.state.isLinear}
                                onChange={this.toggleLinear}
                                value="linear"
                                classes={{
                                    switchBase: classes.switchBase,
                                    checked: classes.checked,
                                    bar: classes.bar,
                                }}
                            />
                            <span className={`label ${!this.state.isLinear ? 'active' : ''}`}>
                                {i18n.t('Step')}
                            </span>
                        </div>
                        <CostSimulationLineChart
                            rawData={this.state.rawData}
                            periodFilter={this.state.periodFilter}
                            datePicker={this.state.datePicker}
                            dateRangePicker={this.state.dateRangePicker}
                            shouldAddWeekendhighlight={this.state.dateRangePicker.open === false}
                            diffOfSelectedDays={this.state.diffOfSelectedDays}
                            dataGranularity={this.state.dataGranularity}
                            loadingData={this.state.loadingData}
                            loadingGroups={this.state.loadingGroups}
                            getGroupName={this.getGroupName}
                            getTariffName={this.getTariffName}
                            getSensorPhaseName={this.getSensorPhaseName}
                            max={this.state.max}
                            isLinear={this.state.isLinear}
                            loadedData={this.state.loadedData}
                            totalData={this.state.compare.length}
                            noGroupSetupError={this.state.noGroupSetupError}
                            noTariffSetupError={!this.state.loadingTariffs && (!this.state.tariffs || !this.state.tariffs.length)}
                            loadError={this.state.loadError}
                            simulationIndex={this.state.simulationIndex}
                        />
                    </div>

                    <CostSimulationResumeTable
                        compare={this.state.compare}
                        costFormat={this.costFormat}
                        currency={this.state.currency}
                        dateRangePicker={this.state.dateRangePicker}
                        getColor={this.getColor}
                        getGranularity={this.getGranularity}
                        periodFilter={periodFilter}
                        tariffsMap={this.state.tariffsMap}
                    />
                </div>

                <SystemSnackbar
                    open={!this.state.loadingGroups && !this.state.loadingData && !!this.state.tariffs.length && !!this.state.groups.length && this.state.loadedFiltersURL !== location.pathname + location.search}
                    message={i18n.t('Chart may be outdated. Please press "Simulate Costs" after setting the filters.')}
                    variant='info2'
                    noAutoHide />

                <SystemSnackbar
                    open={!this.state.loadingTariffs && (!this.state.tariffs || !this.state.tariffs.length)}
                    message={i18n.t('No tariff setup. Please setup a tariff in "Configurations > Tariff > Create Tariff".')}
                    variant='warning' />

                <SystemSnackbar
                    open={!this.state.loadingGroups && this.state.groups.length === 0}
                    message={i18n.t('No group setup. Please setup a group in "Configurations > Groups > Create Group".')}
                    variant='warning' />
            </div>
        )
    }
}

CostSimulation.propTypes = {
    intl: intlShape.isRequired,
};

export {colors, kwhFormat};
export default withStyles(styles)(injectIntl(CostSimulation));
