import React from 'react';
import PropTypes from 'prop-types';


class MonthPickerGrid extends React.Component {
    render () {
        return (
            this.props.months && this.props.months.length ? (
                <div className="months">
                    <p className="title">{this.props.months[0].date.format('YYYY')}</p>

                    {this.props.months.map((month) => (
                        <button className={
                            this.props.startDate && this.props.startDate.valueOf() === month.date.valueOf() ?
                                'active' : ''}
                            disabled={month.disabled}
                            key={month.date.format('MM')}
                            onClick={() => this.props.handleMonthClick(month)}>
                            {month.date.format(this.props.format ? this.props.format : 'MMM')}
                        </button>
                    ))}
                </div>
            ) : null
        );
    }
}

MonthPickerGrid.propTypes = {
    format: PropTypes.string,
    handleMonthClick: PropTypes.func.isRequired,
    months: PropTypes.array.isRequired,
    startDate: PropTypes.object.isRequired,
};

export default MonthPickerGrid;
