import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { withStyles } from 'material-ui/styles';
import moment from 'moment';

import { Title } from '../../elements/title';
import { API } from '../../components/api';
import {Loading} from '../../elements/loading';
import Table, {TableBody, TableCell, TableHead, TableRow} from 'material-ui/Table';

// Translation
import i18n from '../../i18n';

const styles = theme => ({
    alertsContent: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '25px'
    },
    card: {
        flex: 1,
        marginRight: '16px',
        '&:last-child': {
            marginRight: '0px'
        }
    },
    contentRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0',
        '&:last-child': {
            padding: '10px'
        }
    },
    alertName: {
        flex: 1,
        marginLeft: '10px'
    },
    alertValue: {
        fontSize: '45px',
        margin: '0px',
    },
    table: {
        backgroundColor: '#FFF',
        boxShadow: '0px 2px 3px 2px #CCC',
        '& thead tr th': {
            color: '#757575',
            fontSize: '14px',
        }
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    tableHeadFirstRow: {
    },
    tableHeaderButtonWrapper: {
        height: '24px',
        width: '30px',
        display: 'inline-flex',
        alignItems: 'center',
        flex: '0 0 auto',
        verticalAlign: 'middle',
        justifyContent: 'center',
    },
    readRow:{
        backgroundColor: '#F5F5F5',
    },
    unreadCell:{
        fontWeight: 'bold',
    },
    tbodyRow: {
        '& td': {
            color: '#757575',
            fontSize: '13px',
        },
        '& td:last-child button': {
            opacity: '0',
            transition: 'opacity 0.6s'
        },
        '&:hover td:last-child button': {
            opacity: '1'
        }
    }
});

class AlertSection extends PureComponent {
    state = {
        loading: true,
        alertEvents: []
    }

    componentDidMount() {
        this.getLastEvents();
    }

    getLastEvents = async () => {
        try {
            const { data } = await API.HOME.ALERTS.EVENTS();

            this.setState({
                loading: false,
                alertEvents: data
            });
        } catch (error) {
            this.setState({ loading: false, })
        }
    }

    getBreadcrumbsTrailFromHierarchy = (alert, systemAlert) => {
        const errorMessage = i18n.t("Unknown group/sensor");
        let error = false;

        let breadcrumbsText = '';
        if (alert && alert.breadCrumbs) {
            if (alert.breadCrumbs.parentGroupName) {
                breadcrumbsText = alert.breadCrumbs.parentGroupName;
            }

            if (alert.breadCrumbs.groupName) {
                if (alert.breadCrumbs.parentGroupName) {
                    breadcrumbsText += '/'
                }
                breadcrumbsText += alert.breadCrumbs.groupName;
            }
            if (alert.breadCrumbs.sensorId && alert.breadCrumbs.channel) {
                breadcrumbsText = breadcrumbsText ? breadcrumbsText + '/' : '';
                breadcrumbsText += alert.breadCrumbs.sensorId + '-' + alert.breadCrumbs.channel;
                if (alert.breadCrumbs.sensorChannelName) {
                    breadcrumbsText += ' - ' + alert.breadCrumbs.sensorChannelName;
                }
            }
            if (breadcrumbsText === '') {
                error = true;
            }
        } else if (systemAlert && systemAlert.breadCrumbs) {
            if (systemAlert.breadCrumbs.parentGroupName) {
                breadcrumbsText = systemAlert.breadCrumbs.parentGroupName;
            }

            if (systemAlert.breadCrumbs.groupName) {
                if (systemAlert.breadCrumbs.parentGroupName) {
                    breadcrumbsText += '/'
                }
                breadcrumbsText += systemAlert.breadCrumbs.groupName;
            }

            if (systemAlert.breadCrumbs.sensorId && systemAlert.breadCrumbs.channel) {
                breadcrumbsText = breadcrumbsText ? breadcrumbsText + '/' : '';
                breadcrumbsText += systemAlert.breadCrumbs.sensorId + '-' + systemAlert.breadCrumbs.channel;
                if (systemAlert.breadCrumbs.sensorChannelName) {
                    breadcrumbsText += ' - ' + systemAlert.breadCrumbs.sensorChannelName;
                }
            }
        } else if (systemAlert) {
            breadcrumbsText = systemAlert.sensorId + '-' + systemAlert.channel;
        } else {
            error = true;
        }

        if (error) {
            breadcrumbsText = errorMessage;
            console.warn("A wrong breadcrumb format was received and no breadcrumb text could be created for Alerts Events list");
        }

        return breadcrumbsText;
    }

    render() {
        const { alertEvents } = this.state;
        const { classes } = this.props;

        return (
            <section id="page-home-events">
                <Title element="h4" text={i18n.t("Recent Events")} />
                <div className={classes.alertsContent}>
                    <Table className={classes.table} aria-labelledby="tableTitle">
                        <TableHead>
                            <TableRow className={classes.tableHeadFirstRow}>
                                <TableCell colSpan={4}>
                                    {i18n.t('Group / Subgroup / Sensor')}
                                </TableCell>
                                <TableCell colSpan={4}>
                                    {i18n.t('Triggered')}
                                </TableCell>
                                <TableCell colSpan={4}>
                                    {i18n.t('Alert')}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.loading ? (
                                <TableRow>
                                    <TableCell padding="checkbox" colSpan="3">
                                        <div style={{minWidth: 48, width: 48}}> <Loading /></div>
                                    </TableCell>
                                </TableRow>
                            ) : null}
                            {!this.state.loading && alertEvents.length === 0 ? (
                                <TableRow>
                                    <TableCell className="no-data" colSpan={3}>
                                        {i18n.t('No alert events yet.')}
                                    </TableCell>
                                </TableRow>
                            ) : null}
                            {!this.state.loading && alertEvents.length ? alertEvents.map(alertEvent => (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={alertEvent.id}
                                    className={classNames(classes.tbodyRow, alertEvent.read ? classes.readRow : null)}
                                >
                                    <TableCell className="group_column" colSpan={4}>
                                        {this.getBreadcrumbsTrailFromHierarchy(alertEvent.alert, alertEvent.systemAlert)}
                                    </TableCell>
                                    <TableCell className="triggered_column" colSpan={4}>
                                        {moment(alertEvent.timestamp).format('lll')}
                                    </TableCell>
                                    <TableCell className="alert_column" colSpan={4}>
                                        {i18n.t(JSON.parse(alertEvent.message).key, JSON.parse(alertEvent.message).values)}
                                    </TableCell>
                                </TableRow>
                            )) : null}
                        </TableBody>
                    </Table>
                </div>
            </section>
        )
    }
}

export default withStyles(styles)(AlertSection);
