import React, { PureComponent } from 'react';
import GoogleMapReact from 'google-map-react';
import moment from 'moment-timezone';
import { NavLink } from 'react-router-dom';
import CloseIcon from 'material-ui-icons/Close';
import { API } from '../../../components/api';

import SystemSnackbar from '../../../elements/material-ui/SystemSnackbar';

import './geolocation.css';

// Translation
import i18n from '../../../i18n';

const getMapBounds = (map, maps, places) => {
    const bounds = new maps.LatLngBounds();

    places.forEach(place => {
        bounds.extend(new maps.LatLng(
            place.lat,
            place.lng,
        ));
    });

    return bounds;
};

const getDisplayValue = value => parseFloat(value).toFixed(3);

class Geolocation extends PureComponent {
    state = {
        loadingSensors: true,
        selectedSensor: null,
        toSelectSensor: null,
        googleMapApi: {},
        showSnackBar: false,
        sensors: [],
        snackbar: {
            message: '',
            type: 'warning'
        },
        map: {
            zoom: 11,
            center: {
                lat: 0,
                lng: 0
            }
        },
    }

    componentDidMount() {
        API.VISUALIZATIONS.MAP().then(({ data }) => {
            if (data && data.groups &&  data.groups.length) {

                const liveConsumptions = data.groups.map(({ totalConsumption }) => totalConsumption);
                const sensorsCount = data.groups.map(({ numberOfSensors }) => numberOfSensors);

                return this.setState({
                    maxConsumption: Math.max(...liveConsumptions),
                    minConsumption: liveConsumptions.length === 1 ? 0 : Math.min(...liveConsumptions),
                    maxSensors: Math.max(...sensorsCount),
                    minSensors: sensorsCount.length === 1 ? 0 : Math.min(...sensorsCount),
                    loadingSensors: false,
                    sensors: data.groups
                });
            }

            this.setState({ loadingSensors: false });
        }).catch(error => {
            console.error(error)
            this.setState({ loadingSensors: false });
            this.openSnackBar(i18n.t('An error occurred while loading the groups.'), 'error');
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            (!prevState.googleMapApi.maps && this.state.googleMapApi.maps && this.state.sensors.length && !this.state.loadingSensors) ||
            (prevState.loadingSensors && !this.state.loadingSensors && this.state.googleMapApi.maps)
        ) {
            const { sensors, googleMapApi: { map, maps }} = this.state;
            const filtered = sensors.map(map => ({
                ...map,
                lat: map.lat === null ? 0 : map.lat,
                lng: map.lng === null ? 0 : map.lng
            }));

            if (filtered.length === 1) {
                this.setState({
                    map: {
                        zoom: 15,
                        center: {
                            lat: filtered[0].lat,
                            lng: filtered[0].lng
                        }
                    }
                });
            } else if (filtered.length) {
                const bounds = getMapBounds(map, maps, filtered);
                map.fitBounds(bounds);
            }
        }

        if (!prevState.toSelectSensor && this.state.toSelectSensor) {
            setTimeout(() => {
                this.setState({
                    selectedSensor: this.state.toSelectSensor,
                    toSelectSensor: null
                });
            }, 600);
        }
    }

    apiIsloaded = googleMapApi => this.setState({
        googleMapApi
    })

    getMarkerColor = liveConsumption => {
        const { maxConsumption, minConsumption } = this.state;
        const percentage = (liveConsumption - minConsumption) / (maxConsumption - minConsumption) * 100;

        if (percentage === 100)
            return 'extra-high'

        if (percentage > 80)
            return 'high'

        if (percentage > 65)
            return 'mid-high'

        if (percentage > 50)
            return 'normal'

        if (percentage > 30)
            return 'mid-low'

        if (percentage > 15)
            return 'low'

        return 'extra-low'
    }

    getMarkerSize = numberOfSensors => {
        const { maxSensors, minSensors } = this.state;
        const percentage = (numberOfSensors - minSensors) / (maxSensors - minSensors) * 100;

        if (percentage === 100)
            return 'extra-big'

        if (percentage > 80)
            return 'big'

        if (percentage > 65)
            return 'mid-big'

        if (percentage > 50)
            return 'normal'

        if (percentage > 30)
            return 'mid-small'

        if (percentage > 15)
            return 'small'

        return 'extra-small'
    }

    openSnackBar = (message, type) => this.setState({
        showSnackBar: true,
        snackbar: {
            message,
            type
        }
    })

    closeSnackBar = () => this.setState({
        showSnackBar: false
    })

    toggleSensor = id => {
        const { selectedSensor } = this.state
        if (selectedSensor === id || !id) {
            const { sensors, googleMapApi: { map, maps }} = this.state;
            const filtered = sensors.map(map => ({
                ...map,
                lat: map.lat === null ? 0 : map.lat,
                lng: map.lng === null ? 0 : map.lng,
            }))

            this.setState({
                map: {
                    zoom: null,
                    center: {
                        lat: null,
                        lng: null
                    }
                },
                selectedSensor: null
            });

            if (filtered.length === 1) {
                this.setState({
                    map: {
                        zoom: 15,
                        center: {
                            lat: filtered[0].lat,
                            lng: filtered[0].lng
                        }
                    }
                });
            } else if (filtered.length) {
                const bounds = getMapBounds(map, maps, sensors);
                return map.fitBounds(bounds);
            }

            return;
        }

        const sensor = this.state.sensors.find(ele => ele.id === id);
        this.setState({
            map: {
                ...this.state.map,
                center: {
                    lat: sensor.lat,
                    lng: sensor.lng
                },
                zoom: this.state.map.zoom === 16 ? 15 : 16
            },
            selectedSensor: selectedSensor ? null : id,
            toSelectSensor: selectedSensor ? id : null
        });
    }

    render () {
        const {
            loadingSensors,
            map,
            maxConsumption,
            minConsumption,
            sensors,
            selectedSensor,
            showSnackBar,
            snackbar,
        } = this.state;

        const openedSensor = sensors.find(sensor => selectedSensor === sensor.id);

        return (
            <div id="geolocation">
                <GoogleMapReact
                    {...map}
                    yesIWantToUseGoogleMapApiInternals
                    bootstrapURLKeys={{ key: 'AIzaSyCWMjiecps2GRLyTO0aqnTqgxVI36YVRkI' }}
                    onGoogleApiLoaded={this.apiIsloaded}
                >
                    {!loadingSensors && sensors.length && sensors.map(sensor => (
                        <div
                            key={sensor.id}
                            lat={sensor.lat}
                            lng={sensor.lng}
                            onClick={() => this.toggleSensor(sensor.id)}
                            className={`
                                marker
                                 ${this.getMarkerColor(sensor.totalConsumption)}
                                 ${this.getMarkerSize(sensor.numberOfSensors)}
                                 ${sensor.id === selectedSensor ? 'active' : ''}
                            `}
                        >
                            <div className="color">
                                <div className="marker-tooltip">
                                    <p className="tooltip-title">
                                        {sensor.name}
                                    </p>
                                    <p className="tooltip-date">
                                        {moment(`${sensor.liveTimestampTz}`, 'YYYY-MM-DDHH:mm:ss').format('LLL')}
                                    </p>
                                    {false && (
                                        <p className="data">
                                            <span>{i18n.t('Sensors Count:')}</span>
                                            <span>{sensor.numberOfSensors}</span>
                                        </p>
                                    )}
                                    {false && (
                                        <p className="data">
                                            <span>{i18n.t('Total Consumption:')}</span>
                                            <span>
                                                {`${getDisplayValue(sensor.totalConsumption)} ${i18n.t('kWh')}`}
                                            </span>
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </GoogleMapReact>
                <div id="sensor-info" className={selectedSensor ? 'open' : ''}>
                    {selectedSensor && openedSensor && (
                        <div className="sensor-content">
                            <div className="header">
                                <div className="title">
                                    <h3>{openedSensor.name}</h3>
                                    <button
                                        className="close-button"
                                        onClick={() => this.toggleSensor()}
                                    >
                                        <CloseIcon />
                                    </button>
                                </div>
                                <p>
                                    {moment(`${openedSensor.liveTimestampTz}`, 'YYYY-MM-DDHH:mm:ss').format('LLL')}
                                </p>
                            </div>
                            <div className="sensor-data">
                                {false && (
                                    <p>
                                        <span>{i18n.t('Sensors Count:')}</span>
                                        <span>{openedSensor.numberOfSensors}</span>
                                    </p>
                                )}
                                {false && (
                                    <p>
                                        <span>{i18n.t('Total Consumption:')}</span>
                                        <span>
                                            {`${openedSensor.liveConsumption ? getDisplayValue(openedSensor.liveConsumption) : ' -'} ${i18n.t('kWh')}`}
                                        </span>
                                    </p>
                                )}
                                <div className="daily-consumption">
                                    <p className="title">
                                        {i18n.t('Total Consumption')}
                                    </p>
                                    <p>
                                        <span>{i18n.t('Today:')}</span>
                                        <span>
                                            {`${openedSensor.totalConsumption ? getDisplayValue(openedSensor.totalConsumption) : ' -'} ${i18n.t('kWh')}`}
                                        </span>
                                    </p>
                                </div>
                                <div className="daily-consumption">
                                    <p className="title">
                                        <span>
                                            {i18n.t('Granularity')}
                                        </span>
                                        <span>
                                            {i18n.t('5 Min')}
                                        </span>
                                    </p>
                                    <p>
                                        <span>{i18n.t('Max:')}</span>
                                        <span>
                                            {openedSensor.maxHourConsumption && openedSensor.maxHourConsumption.consumption !== null
                                                ? getDisplayValue(openedSensor.maxHourConsumption.consumption)
                                                : ' - '
                                            } {i18n.t('kWh')}
                                        </span>
                                    </p>
                                    {openedSensor.maxHourConsumption && openedSensor.maxHourConsumption.hour && (
                                        <p>
                                            <small>
                                                {moment(openedSensor.maxHourConsumption.hour, 'HH:mm:ss').format('LTS')}
                                            </small>
                                        </p>
                                    )}
                                    <p>
                                        <span>{i18n.t('Min:')}</span>
                                        <span>
                                            {openedSensor.minHourConsumption && openedSensor.minHourConsumption.consumption !== null
                                                ? getDisplayValue(openedSensor.minHourConsumption.consumption)
                                                : ' - '
                                            } {i18n.t('kWh')}
                                        </span>
                                    </p>
                                    {openedSensor.minHourConsumption && openedSensor.minHourConsumption.hour && (
                                        <p>
                                            <small>
                                                {moment(openedSensor.minHourConsumption.hour, 'HH:mm:ss').format('LTS')}
                                            </small>
                                        </p>
                                    )}
                                    <p>
                                        <span>{i18n.t('Avg:')}</span>
                                        <span>
                                            {`${openedSensor.avgConsumption ? getDisplayValue(openedSensor.avgConsumption) : ' -'} ${i18n.t('kWh')}`}
                                        </span>
                                    </p>
                                </div>
                            </div>
                            <div className="group-link">
                                <NavLink to={`/analysis/consumption?ids=[{gid:${openedSensor.id}}]&date=${moment().format('YYYY-MM-DD')}&period=day`}>
                                    {i18n.t('GO TO ANALYSIS')}
                                </NavLink>
                            </div>
                        </div>
                    )}
                </div>
                <div id="resume">
                    {loadingSensors && (
                        <div className="loading">
                            {i18n.t('Loading data...')}
                        </div>
                    )}
                    {!loadingSensors && false && (
                        <div className="top">
                            <div className="info">
                                <span>{i18n.t('Sensors Count:')}</span>
                                <span>
                                    {sensors.reduce((prev, cur) => prev + cur.numberOfSensors, 0)}
                                </span>
                            </div>
                            <div className="info">
                                <span>{i18n.t('Total Consumption:')}</span>
                                <span>
                                    {getDisplayValue(sensors.reduce((prev, cur) => prev + cur.liveConsumption, 0))} {i18n.t('kWh')}
                                </span>
                            </div>
                        </div>
                    )}
                    {!loadingSensors && (
                        <div className="consume-info">
                            <div className="colors-info" />
                            <div className="values-info">
                                <span>{`${parseFloat(minConsumption).toFixed(2)} ${i18n.t('kWh')}`}</span>
                                <span>{`${parseFloat(maxConsumption).toFixed(2)} ${i18n.t('kWh')}`}</span>
                            </div>
                        </div>
                    )}
                </div>
                <SystemSnackbar
                    open={showSnackBar}
                    message={snackbar.message}
                    onClose={this.closeSnackBar}
                    variant={snackbar.type}
                />
            </div>
        )
    }
}

export default Geolocation;
