export const customFormats = {
  'en-gb': {
      '[LM]':'D MMM',
      '[llll-a]': 'ddd, D MMMM, YYYY',
      '[llll-b]': 'dddd, D MMMM, YYYY',
      '[LMMMMYYYY]': 'MMMM YYYY',
      '[LWYYYY]': '[W]w - YYYY',
      '[LDDMMHHmm]': 'D MMM HH:mm',
  },
  'ru': {
      '[LM]':'D MMM',
      '[llll-a]': 'ddd, D MMMM YYYY г.',
      '[llll-b]': 'dddd, D MMMM YYYY г.',
      '[LMMMMYYYY]': 'MMMM YYYY г.',
      '[LWYYYY]': '[н]w YYYY г.',
      '[LDDMMHHmm]': 'DD.MM HH:mm',
  },
  'pt-br': {
      '[LM]':'D MMM',
      '[llll-a]': 'ddd, D [de] MMMM, YYYY',
      '[llll-b]': 'dddd, D [de] MMMM [de] YYYY',
      '[LMMMMYYYY]': 'MMMM, YYYY',
      '[LWYYYY]': '[Semana] w, YYYY',
      '[LDDMMHHmm]': 'DD/MM HH:mm',
  }
}
