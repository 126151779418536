import i18n from '../i18n'

const getSensorStructured = (sensor) => {
    let id = sensor.id ? sensor.id : sensor.sensorId ? sensor.sensorId : '';
    return {
        channel: sensor.channel,
        id: id,
        key: `${id}-${sensor.channel}`,
        label: `${id}-${sensor.channel}${sensor.name ? ' - ' + sensor.name : ''}`,
        name: sensor.name ? sensor.name : '',
    }
}

const getMultiphaseSensorStructured = (sensor) => {
    const id = sensor.id ? sensor.id : sensor.sensorId ? sensor.sensorId : '';
    const numberOfChannels = sensor.channels ? sensor.channels.length : 0;
    return {
        channel: null,
        id: id,
        key: id + '',
        label: id + (numberOfChannels > 1 ? (' ' + i18n.t('(all phases)')) : ''),
        name: id + '',
    }
}

const adjustJSONToValidJSON = (data) => data.replace(/(['"])?([a-z0-9A-Z_]+)(['"])?:/g, '"$2": ');

const defaultRoutePath = '/home';

const isEstimatedData = (data) => {
    return (data && data.aggregationType && (data.aggregationType === 'ESTIMATED' || data.aggregationType === 'HYBRID'));
}

export {
    adjustJSONToValidJSON,
    getSensorStructured,
    getMultiphaseSensorStructured,
    defaultRoutePath,
    isEstimatedData,
}
