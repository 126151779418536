import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import {Text} from '../../elements/text';
import {SensorPhase} from './sensor-phase';

import i18n from '../../i18n';

import './add-sensors-phases.css';
import 'react-select/dist/react-select.css';

const initialState = {
    mode: '',
    loading: false,
    feedbackMessage: '',
    noSensorsAvailable: false,
    errorMessage: '',
    loadErrorMessage: '',
    fieldsWithError: [],
};

const baseSensorPhase = {
    seq: 0,
    id: 0,
    name: '',
    key: '',
    keyError: false,
    last: false
}

const modes = {totalLoad: 'totalLoad', subLoad: 'subLoad'};

class SensorsPhasesAdd extends PureComponent {
    constructor (props) {
        super(props);

        this.totalLoadMessages = {
            title: i18n.t('Total Load Sensor'),
            hint: i18n.t('Total load sensor'),
        }

        this.subLoadMessages = {
            title: i18n.t('Sub Load Sensors'),
            hint: i18n.t('Sub load sensors'),
        }

        const sensorsPhases = this.props.sensorsPhases;
        if (sensorsPhases && sensorsPhases.length > 0){
            initialState.seq = sensorsPhases[sensorsPhases.length-1].seq + 1;
        }

        this.state = initialState;
        this.messages = (props.mode === modes.totalLoad) ? this.totalLoadMessages : this.subLoadMessages;
    }

    componentDidMount(){
        this.createNewRowIfNeeded();
    }

    componentDidUpdate() {
        this.createNewRowIfNeeded();
    }

    changeSensorPhase = (selectedSensorPhase, seq) => {
        const i = this.props.sensorsPhases.findIndex((sensorPhase) => sensorPhase.seq === seq);
        const sensorPhase = Object.assign({}, this.props.sensorsPhases[i], selectedSensorPhase, {seq });
        const sensorsPhases = [].concat(this.props.sensorsPhases);
        sensorsPhases[i] = sensorPhase;

        this.props.changeSensorsPhases(sensorsPhases);
    }

    changeSensorTag = (tag, seq) => {
        const i = this.props.sensorsPhases.findIndex(sensorPhase => sensorPhase.seq === seq);
        const found = !!tag && tag.value === tag.label && this.props.tags.find(ele => ele.label.toLowerCase() === tag.label.toLowerCase())

        const sensorPhase = Object.assign({}, this.props.sensorsPhases[i], { tags: !!tag
            ? !!found
                ? [found.value]
                : [tag.value]
            : null
        });

        if (tag && !found && tag.value === tag.label) {
            this.props.addTag(tag)
        }

        const sensorsPhases = [].concat(this.props.sensorsPhases);
        sensorsPhases[i] = sensorPhase;

        this.props.changeSensorsPhases(sensorsPhases);
    }

    changeSensorTariff = (tariff, seq) => {
        const i = this.props.sensorsPhases.findIndex(sensorPhase => sensorPhase.seq === seq);

        const sensorPhase = Object.assign({}, this.props.sensorsPhases[i], { tariffId: !!tariff && tariff.value
            ? tariff.value
            : null
        });

        const sensorsPhases = [].concat(this.props.sensorsPhases);
        sensorsPhases[i] = sensorPhase;

        this.props.changeSensorsPhases(sensorsPhases);
    }

    shouldCreateNewRow = () => {
        const { sensorsPhases } = this.props;
        if (!sensorsPhases || (sensorsPhases && sensorsPhases.length === 0)){
            return true;
        }

        if (sensorsPhases && sensorsPhases.length > 0){
            const lastSensorPhase = sensorsPhases[sensorsPhases.length - 1];
            if (lastSensorPhase.key !== ''){
                return true;
            }
        }

        return false;
    }

    addSensorPhase = () => {
        let sensorsPhases = [].concat(this.props.sensorsPhases);
        let lastSeq = 0;
        if (sensorsPhases && sensorsPhases.length > 0){
            lastSeq = Math.max.apply(Math, sensorsPhases.map((o) => o.seq));
            lastSeq++;
        }

        const sensorPhase = Object.assign({}, baseSensorPhase, { seq: lastSeq });
        sensorsPhases.push(sensorPhase);
        this.props.changeSensorsPhases(this.checkLast(sensorsPhases));
    }

    removeSensorPhase = (seq) => {
        let sensorsPhases = [].concat(this.props.sensorsPhases);
        sensorsPhases.splice(sensorsPhases.findIndex((sensorPhase) => sensorPhase.seq === seq), 1);
        this.props.changeSensorsPhases(this.checkLast(sensorsPhases));
    }

    createNewRowIfNeeded = () => {
        if (this.shouldCreateNewRow())
            this.addSensorPhase();
    }

    checkLast = (sensorsPhases) => {
        if (sensorsPhases && sensorsPhases.length > 0){
            const lastSeq = Math.max.apply(Math,sensorsPhases.map((o) => o.seq));

            sensorsPhases = sensorsPhases.map(sensorPhase => ({
                ...sensorPhase,
                last: sensorPhase.seq === lastSeq
            }));
        }
        return sensorsPhases;
    }

    changeLabel = (e, seq) => {
        const i = this.props.sensorsPhases.findIndex((sensorPhase) => sensorPhase.seq === seq);
        const sensorPhase = Object.assign({}, this.props.sensorsPhases[i], { name: e.target.value });
        const sensorsPhases = [].concat(this.props.sensorsPhases);
        sensorsPhases[i] = sensorPhase;
        this.props.changeSensorsPhases(sensorsPhases);
    }

    render () {
        return (
            <div className={(this.props.mode === 'totalLoad') ? 'sensor-phase-container total-load' : 'sensor-phase-container'}>
                <div>
                    <h6>{this.messages.title} <small> {i18n.t('(If any)')}</small></h6>
                </div>
                <div>
                    <div className="sensor-phase-selection">
                        <Text text={this.messages.hint} />
                    </div>
                    {this.props.mode !== 'totalLoad' && (
                        <div className="sensor-phase-label">
                            <p dangerouslySetInnerHTML={{__html: i18n.t('Label <small>(Optional)</small>')}} />
                        </div>
                    )}

                    {this.props.mode !== 'totalLoad' && (
                        <div className="select-tag-sensor">
                            <p dangerouslySetInnerHTML={{__html: i18n.t('Tag <small>(Optional)</small>', {interpolation: { escapeValue: false }})}} />
                        </div>
                    )}
                    {this.props.mode !== 'totalLoad' && (
                        <div className="select-tag-sensor">
                            <p dangerouslySetInnerHTML={{__html: i18n.t('Tariff <small>(Optional)</small>', {interpolation: { escapeValue: true }})}} />
                        </div>
                    )}
                </div>
                {this.props.sensorsPhases && this.props.sensorsPhases.map(sensorPhase => (
                    <SensorPhase
                        key={sensorPhase.seq}
                        seq={sensorPhase.seq}
                        availableSensors={this.props.availableSensors}
                        changeLabel={this.changeLabel}
                        changeSensorPhase={this.changeSensorPhase}
                        changeSensorTag={this.changeSensorTag}
                        changeSensorTariff={this.changeSensorTariff}
                        createNewRowIfNeeded={this.createNewRowIfNeeded}
                        isLast={sensorPhase.last}
                        keyError={sensorPhase.keyError}
                        loadingSensors={this.props.loadingSensors}
                        mode={this.props.mode}
                        name={sensorPhase.name}
                        removeSensorPhase={this.removeSensorPhase}
                        sensorPhase={sensorPhase}
                        tariffs={this.props.tariffs}
                        tags={this.props.tags && this.props.tags.filter(tag =>
                            tag.value !== this.props.groupTag &&
                            tag.value !== this.props.subgroupTag
                        )}
                    />
                ))}
            </div>
        );
    }
}

SensorsPhasesAdd.propTypes = {
    mode: PropTypes.oneOf(Object.keys(modes)).isRequired
};

export {SensorsPhasesAdd};
