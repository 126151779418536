import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import moment from 'moment';
import IconButton from 'material-ui/IconButton';
import ExpandMoreIcon from 'material-ui-icons/ExpandMore';
import Typography from 'material-ui/Typography';
import Collapse from 'material-ui/transitions/Collapse';
import classNames from 'classnames';
import { CardContent } from 'material-ui/Card';
import { injectIntl, intlShape } from 'react-intl';

// Translation
import i18n from '../../i18n';

import { granularities, granularitiesPlural, alertOperations, granularitiesContextPT_BR } from './constants';
import {Loading} from '../../elements/loading';
import { API } from '../../components/api';


const styles = theme => ({
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
        marginLeft: 'auto',
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    expandMoreButtonRoot: {
        width: '26px',
        height: '26px',
    },
    detailsRoot:{
        display: 'flex',
        alignItems: 'center',
        paddingTop: '4px',
        paddingRight: '0px',
        paddingBottom: '0px',
        paddingLeft: '0px',
        '&:last-child' : {
            paddingTop: '6px',
            paddingBottom: '0px',
        }
    },
    alertContent: {
        padding: '10px 15px',
    },
    typographyTitle: {
        fontSize: '1rem',
        fontWeight: 600,
    },
});

class AlertCardText extends PureComponent {
    constructor (props){
        super(props);

        this.state = {
            expanded: false,
            loading: false,
            currencyCode: null,
        }

        this.costFormat = (value) => {
            return this.state.currencyCode && value >= 0 ? `${this.props.intl.formatNumber(value, {style: 'currency', currency: this.state.currencyCode})}` : ' - '
        }
    }


    handleExpandClick = () => {
        setTimeout(this.props.masonryLayout, 250);
        this.setState({ expanded: !this.state.expanded });
        if (this.props.data.category === 'ABSOLUTE_COST' && !this.state.currencyCode) {
            this.getAlertCurrency();
        }
    }

    getAlertCurrency = () => {
        this.setState({ loading: true })

        const {groupId, sensorId, channel} = this.props.data;

        if (sensorId && channel){
            API.TARIFFS.CURRENCY.SENSOR(sensorId, channel).then(({data}) => {
                this.setState({
                    currencyCode: groupId && data && data.currency ? data.currency : null,
                    loading: false,
                });
            }).catch(error => {
                console.error("Error while fetching currency of a sensor. The default currency symbol will be used.");
                this.setState({
                    currencyCode: null,
                    loading: false,
                })
            });
        } else if (!sensorId && !channel && groupId){
            API.TARIFFS.CURRENCY.GROUP(groupId).then(({data}) => {
                this.setState({
                    currencyCode: groupId && data && data.currency ? data.currency : null,
                    loading: false,
                });
            }).catch(error => {
                console.error("Error while fetching currency of a group. The default currency symbol will be used.");
                this.setState({
                    currencyCode: null,
                    loading: false,
                })
            });
        }else{
            this.setState({
                currencyCode: null,
                loading: false,
            });
        }
    }

    getAlertText = alert => {
        switch (alert.category) {
            case 'ABSOLUTE_COST':
                return (
                    <div className="alert-text">
                        {i18n.t('I want to receive notifications if the {{alert_period}} accumulated cost reaches {{alert_value}} under the tariff set on configuration.', {
                            context: moment.locale() === 'pt-br' ? granularitiesContextPT_BR()[alert.alertGranularity] : {},
                            alert_period: granularities()[alert.alertGranularity],
                            alert_value: this.costFormat(alert.alertValue)
                        })}
                    </div>
                );
            case 'ABSOLUTE_CONSUMPTION':
                return (
                    <div className="alert-text">
                        {i18n.t('I want to receive notifications if the {{alert_period}} accumulated consumption reaches {{alert_value}} $t(kWh).', {
                            context: moment.locale() === 'pt-br' ? granularitiesContextPT_BR()[alert.alertGranularity] : {},
                            alert_period: granularities()[alert.alertGranularity],
                            alert_value: alert.alertValue
                        })}
                    </div>
                );
            case 'RELATIVE_CONSUMPTION':
                if (alert.alertType === 'PERCENTAGE') {
                    return (
                        <div className="alert-text">
                            {i18n.t('I want to receive notifications if the {{granularity_select}} consumption is {{alert_value}}$t(%) {{alert_operation}} the average of the last {{alert_period_quantity}}.', {
                                context: moment.locale() === 'pt-br' ? granularitiesContextPT_BR()[alert.alertPeriodGranularity] : {},
                                granularity_select: granularities()[alert.alertGranularity],
                                alert_value: alert.alertValue,
                                alert_operation: alertOperations()[alert.alertOperation],
                                alert_period_quantity: `${alert.alertPeriodQuantity} ${granularitiesPlural(alert.alertPeriodQuantity)[alert.alertPeriodGranularity]}`
                            })}
                        </div>
                    );
                }
                return (
                    <div className="alert-text">
                        {i18n.t('I want to receive notifications if the {{granularity_select}} consumption is {{alert_value}} $t(kWh) {{alert_operation}} the average of the last {{alert_period_quantity}}.', {
                            context: moment.locale() === 'pt-br' ? granularitiesContextPT_BR()[alert.alertPeriodGranularity] : {},
                            granularity_select: granularities()[alert.alertGranularity],
                            alert_value: alert.alertValue,
                            alert_operation: alertOperations()[alert.alertOperation],
                            alert_period_quantity: `${alert.alertPeriodQuantity} ${granularitiesPlural(alert.alertPeriodQuantity)[alert.alertPeriodGranularity]}`
                        })}
                    </div>
                );
            case 'ENTITY_STATUS':
                return (
                    <div className="alert-text">
                        {i18n.t('I want to receive notifications if the sensor goes {{entity_status}} {{alert_period}}.', {
                            entity_status: alert.alertOperation === 'ABOVE' ? i18n.t('on') : i18n.t('off'),
                            alert_period: alert.executionEndTime && alert.executionStartTime
                                ? i18n.t('at any moment between {{alert_init_period}} and {{alert_end_period}}', {
                                    alert_init_period: moment(alert.executionStartTime, 'HH:mm:ss').format('LT'),
                                    alert_end_period: moment(alert.executionEndTime, 'HH:mm:ss').format('LT')
                                }) : i18n.t('at any moment')
                        })}
                    </div>
                );
            case 'ABSOLUTE_ACTIVE_POWER':
                return (
                    <div className="alert-text">
                        {i18n.t('I want to receive notifications if the active power goes above {{alert_value}} $t(kW) in the last {{alert_period}}.', {
                            alert_value: alert.alertValue,
                            alert_period: `${alert.alertQuantity} ${granularitiesPlural(alert.alertQuantity)[alert.alertGranularity]}`
                        })}
                    </div>
                );
            default:
                if (alert.alertType === 'PERCENTAGE') {
                    return (
                        <div className="alert-text">
                            {i18n.t('I want to receive notifications if the {{granularity_select}} consumption is {{alert_value}}$t(%) {{alert_operation}} the average of the last {{alert_period_quantity}}.', {
                                context: moment.locale() === 'pt-br' ? granularitiesContextPT_BR()[alert.alertPeriodGranularity] : {},
                                granularity_select: granularities()[alert.alertGranularity],
                                alert_value: alert.alertValue,
                                alert_operation: alertOperations()[alert.alertOperation],
                                alert_period_quantity: `${alert.alertPeriodQuantity} ${granularitiesPlural(alert.alertPeriodQuantity)[alert.alertPeriodGranularity]}`
                            })}
                        </div>
                    );
                }
                return (
                    <div className="alert-text">
                        {i18n.t('I want to receive notifications if the {{granularity_select}} consumption is {{alert_value}} $t(kWh) {{alert_operation}} the average of the last {{alert_period_quantity}}.', {
                            context: moment.locale() === 'pt-br' ? granularitiesContextPT_BR()[alert.alertPeriodGranularity] : {},
                            granularity_select: granularities()[alert.alertGranularity],
                            alert_value: alert.alertValue,
                            alert_operation: alertOperations()[alert.alertOperation],
                            alert_period_quantity: `${alert.alertPeriodQuantity} ${granularitiesPlural(alert.alertPeriodQuantity)[alert.alertPeriodGranularity]}`
                        })}
                    </div>
                );
        }
    }

    render() {
        const { classes, data } = this.props;

        return (
            <div>
                <CardContent classes={{root: classes.detailsRoot}}>
                    <Typography variant="title" color="textSecondary" classes={{title: classes.typographyTitle}}>{i18n.t('Details')}</Typography>
                    <IconButton
                        className={classNames(classes.expand, {
                            [classes.expandOpen]: this.state.expanded,
                        })}
                        classes={{root: classes.expandMoreButtonRoot}}
                        onClick={this.handleExpandClick}
                        aria-expanded={this.state.expanded}
                        aria-label={i18n.t("Show more")}>
                        <ExpandMoreIcon />
                    </IconButton>
                </CardContent>
                <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                    {this.state.expanded && this.state.loading && <Loading />}
                    {this.state.expanded && !this.state.loading && this.getAlertText(data)}
                </Collapse>
            </div>
        );
    }
}

AlertCardText.propTypes = {
    classes: PropTypes.object.isRequired,
    intl: intlShape.isRequired,
};

export default withStyles(styles)(injectIntl(AlertCardText));
