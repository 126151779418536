import React from 'react';

import { Title } from '../../elements/title';
import ABSOLUTE_CONSUMPTION from './absolute.svg';
import ABSOLUTE_COST from './cost.svg';
import RELATIVE from './relative.svg';
import POWER from './power-peak-alert.svg';
import ENTITY_STATUS from './sequipament.svg';

// Translation
import i18n from '../../i18n';

import './alert-category.css';

const AlertCategory = ({ mode, onSelectCategory }) => (
    <section id="alert-category">
        <Title
            element="h4"
            text={i18n.t("Choose Alert Type")}
        />
        <div className="alert-types">
            <button id="btn-absolute-consumption" onClick={() => onSelectCategory('ABSOLUTE_CONSUMPTION')}>
                <img src={ABSOLUTE_CONSUMPTION} alt={i18n.t("Absolute consumption")} />
                <p className="btn-text">
                    {i18n.t('Absolute consumption')}
                </p>
            </button>
            <button id="btn-absolute-cost" onClick={() => onSelectCategory('ABSOLUTE_COST')}>
                <img src={ABSOLUTE_COST} alt={i18n.t("Cost")} />
                <p className="btn-text">
                    {i18n.t('Cost')}
                </p>
            </button>
            <button id="btn-entity-status" onClick={() => onSelectCategory('ENTITY_STATUS')}>
                <img src={ENTITY_STATUS} alt={i18n.t("Equipment status")} />
                <p className="btn-text">
                    {i18n.t('Equipment status')}
                </p>
            </button>
            <button id="btn-relative-consumption" onClick={() => onSelectCategory('RELATIVE_CONSUMPTION')}>
                <img src={RELATIVE} alt={i18n.t("Relative consumption")} />
                <p className="btn-text">
                    {i18n.t('Relative consumption')}
                </p>
            </button>
            <button id="btn-power-peak" onClick={() => onSelectCategory('ABSOLUTE_ACTIVE_POWER')}>
                <img src={POWER} alt={i18n.t("Power")} />
                <p className="btn-text">
                    {i18n.t('Power')}
                </p>
            </button>
        </div>
    </section>
);

export default AlertCategory;
