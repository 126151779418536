import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';

import Select from 'react-select';
import {DayPickerRangeController} from 'react-dates';
import KeyboardArrowLeftIcon from 'material-ui-icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from 'material-ui-icons/KeyboardArrowRight';
import Button from 'material-ui/Button';
import moment from 'moment-timezone';

import i18n from '../../i18n';

import { API } from '../../components/api';
import { Title } from '../../elements/title';
import { Button as VButton } from '../../elements/button';
import SystemSnackbar from '../../elements/material-ui/SystemSnackbar'
import { getSensorStructured } from '../../components/utils'

import 'react-select/dist/react-select.css';
import 'react-dates/lib/css/_datepicker.css';
import './report-generate.css';

class ReportGenerate extends PureComponent {
    constructor (props) {
        super(props);
        let today = moment();

        this.state = {
            report: {
                type: 'DEFAULT',
                group: null,
                subgroup: null,
                channel: null,
                sensorId: null,
                sensorKey: null,
                dateRangePicker: {
                    open: false,
                    endDate: today,
                    startDate: today.clone().subtract(6, 'days'),
                    data: {
                        endDate: today,
                        focusedInput: 'endDate',
                        startDate: today.clone().subtract(6, 'days'),
                    },
                },
            },
            loadingGroups: true,
            groups: [],
            fieldsWithError: {},
            showSnackBar: false,
            formDisabled: false,
            snackbar: {
                type: 'warning',
                message: ''
            },
        }

        this.maximumDate = today.clone().add(1, 'day').endOf('day');
        window.openModalSnackBar = this.openSnackBar;
    }

    componentWillMount = () => {
        API.GROUPS.GET().then(({ data }) => {
            if (data && data.length) {
                return this.setState({
                    loadingGroups: false,
                    groups: data.map(group => ({
                        ...group,
                        label: group.name,
                        value: group.id
                    }))
                })
            }

            this.setState({
                loadingGroups: false
            })
        }).catch(error => this.handleLoadError(
            { loadingGroups: false },
            'Could not load groups.',
            error
        ));
    }

    loadErrorMessage = i18n.t("An error occurred while retrieving data.");

    getSaveData = ({subgroup, group, dateRangePicker, ...report }, groups) => {
        const foundGroup = groups.find(({ id }) => id === group);
        const foundSubgroup = foundGroup.subGroups.find(({ id }) => id === subgroup);
        const subSubGroup = subgroup && foundSubgroup.subGroups[0];

        const startDate = dateRangePicker.startDate.format('YYYY-MM-DD');
        const endDate = dateRangePicker.endDate.format('YYYY-MM-DD');

        return {
            ...report,
            startDate,
            endDate,
            groupId: report.sensorId ? subSubGroup.id : subgroup ? subgroup : group,
        }
    }

    handleLoadError = (state, message, error) => {
        state.loadErrorMessage = this.loadErrorMessage;
        this.setState(state);

        console.error(this.loadErrorMessage + " (" + message + ")");
        if (error)
            console.error(error);
    }

    back = () => this.setState({
        errorMessage: ''
    })

    changeGroup = group => this.setState({
        report: {
            ...this.state.report,
            group: group ? group.value : null,
            subgroup: null,
            channel: null,
            sensorId: null,
            sensorKey: null
        }
    })

    changeSubgroup = subgroup => this.setState({
        report: {
            ...this.state.report,
            subgroup: subgroup ? subgroup.id : null,
            sensorId: null,
            sensorKey: null,
            channel: null
        }
    })

    changeSensor = sensor => this.setState({
        report: {
            ...this.state.report,
            sensorId: sensor ? sensor.id : null,
            sensorKey: sensor ? sensor.key : null,
            channel: sensor ? sensor.channel : null
        }
    })

    changePeriod = period => period && period.value && this.setState({
        report: {
            ...this.state.report,
            period: period.value
        }
    })

    isValid = () => {
        const fieldsWithError = {};
        const { report } = this.state

        if (!report.group) {
            fieldsWithError.group = i18n.t('Is required');
        }

        this.setState({
            fieldsWithError: fieldsWithError,
            loading: false
        });

        return !Object.keys(fieldsWithError).length;
    }

    generateReport = () => {
        const { report, groups } = this.state

        if (this.isValid()){
            localStorage.setItem('reportData', JSON.stringify(this.getSaveData(report, groups)));
            window.open(`/reports/preview`, '_blank', 'toolbar=no');
        }
    }

    openSnackBar = (message, type) => this.setState({
        showSnackBar: true,
        snackbar: {
            message,
            type
        }
    })

    closeSnackBar = () => this.setState({
        showSnackBar: false
    })

    setDateRangePickerNodeRef = (ref) => {
        this.dateRangePickerNode = ref;
    }

    inputRangeDateFormatted = () => {
        if (!this.state.report.dateRangePicker.startDate || !this.state.report.dateRangePicker.endDate){
            return '';
        }else{
            return this.state.report.dateRangePicker.startDate.format('ll') + ' → ' + this.state.report.dateRangePicker.endDate.format('ll');
        }
    }

    handleDateRangeInputFocus = () => {
        let dateRangePicker = Object.assign({}, this.state.report.dateRangePicker);
        dateRangePicker.data.focusedInput = 'endDate';
        dateRangePicker.open = true;
        this.updateDateRangePicker(dateRangePicker);
    }

    updateDateRangePicker = (dateRangePicker) => {
        this.setState({
            report: {
                ...this.state.report,
                dateRangePicker: dateRangePicker
            }
        });
    }

    handleDatesRangeChange = ({startDate, endDate}) => {
        let dateRangePicker = Object.assign({}, this.state.report.dateRangePicker);
        dateRangePicker.data.startDate = startDate;
        let hasValidEndDate = false;

        if (!endDate)
            dateRangePicker.data.focusedInput = 'endDate';
        if (endDate && endDate.isAfter(startDate)){
            dateRangePicker.open = false;
            hasValidEndDate = true;
            // date range is valid, updates last date range selected
            dateRangePicker.startDate = startDate;
            dateRangePicker.endDate = endDate;
        }
        if (!endDate || hasValidEndDate)
            dateRangePicker.data.endDate = endDate;

        this.updateDateRangePicker(dateRangePicker);
    }

    handleDateRangePickerReset = () => {
        let dateRangePicker = Object.assign({}, this.state.report.dateRangePicker);
        dateRangePicker.data.startDate = null;
        dateRangePicker.data.endDate = null;
        dateRangePicker.data.focusedInput = 'startDate';
        this.updateDateRangePicker(dateRangePicker);
    }

    handleDateRangePickerCancel = () => {
        let dateRangePicker = Object.assign({}, this.state.report.dateRangePicker);
        dateRangePicker.open = false;
        dateRangePicker.data.startDate = dateRangePicker.startDate;
        dateRangePicker.data.endDate = dateRangePicker.endDate;
        this.updateDateRangePicker(dateRangePicker, true);
    }

    handleDateRangePickerOutsideClick = (e) => {
        const isDescendantOfRoot = this.dateRangePickerNode && this.dateRangePickerNode.contains(e.target);
        if (!isDescendantOfRoot) {
            e.stopPropagation();
            this.handleDateRangePickerCancel();
        }
    }

    //TODO handle no group case

    render () {
        const {
            fieldsWithError,
            groups,
            loading,
            loadingGroups,
            report,
            showSnackBar,
            snackbar,
        } = this.state

        const {dateRangePicker} = report;

        const foundGroup = groups.find(group => group.id === report.group)
        const subgroups = foundGroup ? foundGroup.subGroups : []

        const foundSubgroup = foundGroup && foundGroup.subGroups.find(group => group.id === report.subgroup)
        const sensors = foundSubgroup ? foundSubgroup.subGroups.reduce((prev, curr) => [
            ...prev,
            ...curr.sensors ? curr.sensors : [],
        ], []) : []

        return (
            <section id="report-generate">
                <Title
                    element="h4"
                    text={i18n.t('Generate Instant Report')}
                />

                <form action="#">
                    <div ref={element => this.formDiv = element} className="form-content">
                        <div className="group-section form-sections">
                            <div className="filters">
                                <Title
                                    element="h6"
                                    text={i18n.t("Select a group, a subgroup or a sensor")}
                                />

                                <div className="select-control">
                                    <Select
                                        name="group"
                                        value={report.group}
                                        onChange={this.changeGroup}
                                        disabled={loadingGroups || !groups.length}
                                        isLoading={loadingGroups}
                                        className={fieldsWithError.group ? 'error' : null}
                                        valueKey="value"
                                        labelKey="label"
                                        options={groups}
                                        placeholder={i18n.t("Group")}
                                        required
                                        noResultsText={i18n.t('No results found')}
                                    />
                                    {fieldsWithError.group ?
                                        <p className="select-error">
                                            {i18n.t('Is required')}
                                        </p>
                                    : null}
                                </div>

                                <Select
                                    name="subgroup"
                                    value={report.subgroup}
                                    onChange={this.changeSubgroup}
                                    disabled={!report.group || !subgroups.length}
                                    isLoading={loadingGroups}
                                    valueKey="id"
                                    labelKey="name"
                                    options={subgroups}
                                    placeholder={i18n.t("Subgroup - Optional")}
                                    noResultsText={i18n.t('No results found')}
                                />

                                <Select
                                    name="sensorKey"
                                    value={report.sensorKey}
                                    onChange={this.changeSensor}
                                    disabled={!report.subgroup || !sensors.length}
                                    isLoading={loadingGroups}
                                    valueKey="key"
                                    labelKey="label"
                                    options={sensors.map(getSensorStructured)}
                                    placeholder={i18n.t("Sensor - Optional")}
                                    noResultsText={i18n.t('No results found')}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="period-section form-sections">
                        <Title
                            element="h6"
                            text={i18n.t("Select a period")}
                        />
                        <div>
                            <div className="date-picker-wrapper" ref={this.setDateRangePickerNodeRef}>
                                <div className={
                                    `date-picker-input-wrapper ${this.state.loadingGroups || this.state.formDisabled ? "is-disabled" : ''}`}>
                                    <input disabled={this.state.loadingGroups || this.state.formDisabled}
                                        name="date-picker-input"
                                        onFocus={this.handleDateRangeInputFocus}
                                        placeholder={i18n.t('Select date range')}
                                        readOnly
                                        type="text"
                                        value={this.inputRangeDateFormatted()} />
                                    <span className="Select-arrow-zone" onClick={this.handleDateRangeInputFocus}>
                                        <span className="Select-arrow" />
                                    </span>
                                    {this.state.loadingGroups ?
                                        <span className="Select-loading-zone">
                                            <span className="Select-loading" />
                                        </span> : null}
                                </div>
                                {dateRangePicker.open ?
                                    <div className="date-picker-container date-range-picker">
                                        <DayPickerRangeController
                                            endDate={dateRangePicker.data.endDate}
                                            startDate={dateRangePicker.data.startDate}
                                            focusedInput={dateRangePicker.data.focusedInput}
                                            hideKeyboardShortcutsPanel={true}
                                            isOutsideRange={(day) => day.startOf('day').isAfter(this.maximumDate)
                                            }
                                            navPrev={<KeyboardArrowLeftIcon />}
                                            navNext={<KeyboardArrowRightIcon />}
                                            onDatesChange={this.handleDatesRangeChange}
                                            onFocusChange={() => {}}
                                            onOutsideClick={this.handleDateRangePickerOutsideClick}
                                            numberOfMonths={2}
                                            minimumNights={0}
                                        />
                                        <div className='btn-actions'>
                                            <Button onClick={this.handleDateRangePickerReset}>
                                                {i18n.t('Reset Date Range')}
                                            </Button>
                                        </div>
                                    </div>
                                :null}
                            </div>
                        </div>
                    </div>
                    <div className="buttons-content">
                        <VButton
                            action="preview"
                            disabled={loading}
                            label={i18n.t("Generate Report")}
                            click={this.generateReport}
                        />
                    </div>
                </form>
                <SystemSnackbar
                    open={showSnackBar}
                    message={snackbar.message}
                    onClose={this.closeSnackBar}
                    variant={snackbar.type}
                />
            </section>
        );
    }
}

ReportGenerate.protoTypes = {
    handleCloseModal: PropTypes.func.isRequired,
    handleOpenModal: PropTypes.func.isRequired,
}

export default ReportGenerate;
