import React from 'react';

import './title.css';


class Title extends React.Component {
    render () {
        return typeof this.props.element === 'string' ?
            <this.props.element>{this.props.text}</this.props.element> : null;
    }
}

export {Title};
