import React, { PureComponent } from 'react';
import { withStyles } from 'material-ui/styles';
import {format} from 'd3-format';
import moment from 'moment';

import { Title } from '../../elements/title';
import GroupsPieChart from './groups-pie-chart';
import ConsumptionSensorBarChart from './sensors-bar-chart';
import TotalCard from './total-card';
import {API} from '../../components/api';

import i18n from '../../i18n';

const styles = theme => ({
    consumptionsContent: {
        display: 'flex',
        marginBottom: '25px'
    },
    card: {
        flex: 1,
        marginRight: '16px',
        '&:last-child': {
            marginRight: '0px'
        }
    },
    consumptionTotals: {
        display: 'flex'
    }
});

const kwhFormat = (d) => {
    if (d === 0){
        return format("d")(d);
    }else if (d < 1){
        return format(".2f")(d);
    }else{
        return format("d")(d);
    }
}

const kwhDecimalFormat = (d) => {
    if (d < 1){
        return format(".2r")(d);
    }else{
        return format(".2f")(d);
    }
}

const percentageFormat = (d) => {
    if (Math.abs(d) === 0){
        return format(".0%")(d);
    }else if (Math.abs(d) < 0.01){
        return format(".2%")(d);

    }else if (Math.abs(d) < 0.1){
        return format(".1%")(d);

    }else{
        return format(".0%")(d);
    }
}

class ConsumptionSection extends PureComponent {
    state = {
        groups: {
            activePeriod: 'day',
            dayConsumption: {
                loading: true,
                currentTotal: undefined,
                diff: undefined,
                data: undefined,
            },
            weekConsumption: {
                loading: true,
                currentTotal: undefined,
                diff: undefined,
                data: undefined,
            },
            monthConsumption: {
                loading: true,
                currentTotal: undefined,
                diff: undefined,
                data: undefined,
            },
            lastDayConsumption: {
                loading: true,
                currentTotal: undefined,
                diff: undefined,
                data: undefined,
            },
            lastWeekConsumption: {
                loading: true,
                currentTotal: undefined,
                diff: undefined,
                data: undefined,
            },
            lastMonthConsumption: {
                loading: true,
                currentTotal: undefined,
                diff: undefined,
                data: undefined,
            },
        },
        sensors: {
            activePeriod: 'day',
            dayConsumption: {
                loading: true,
                data: [],
            },
            weekConsumption: {
                loading: true,
                data: [],
            },
            monthConsumption: {
                loading: true,
                data: [],
            },
        }
    }

    componentDidMount() {
        this.getGroupsConsumptionDayData();
        this.getGroupsConsumptionDayData(moment().subtract(1, 'day').format('YYYY-MM-DD'));
        this.getGroupsConsumptionWeekData();
        this.getGroupsConsumptionWeekData(moment().subtract(1, 'week').format('YYYY-MM-DD'));
        this.getGroupsConsumptionMonthData();
        this.getGroupsConsumptionMonthData(moment().subtract(1, 'month').format('YYYY-MM-DD'));
        this.getSensorsConsumptionDayData();
        this.getSensorsConsumptionWeekData();
        this.getSensorsConsumptionMonthData();
    }

    changeGroupsPeriod = period => this.setState({
        groups: {
            ...this.state.groups,
            activePeriod: period
        }
    })

    changeSensorsPeriod = period => this.setState({
        sensors: {
            ...this.state.sensors,
            activePeriod: period
        }
    })

    getGroupsConsumptionDayData = async (date) => {
        const dayProp = date ? 'lastDayConsumption' : 'dayConsumption';

        try {
            const { data } = await API.HOME.CONSUMPTION.GROUPS.DAY(date);
            this.setState({
                groups: {
                    ...this.state.groups,
                    [dayProp]: {
                        loading: false,
                        currentTotal: data.currentTotalConsumption,
                        diff: data.diffConsumption,
                        data: data.data,
                    }
                }
            });
        } catch (error) {
            this.setState({
                groups: {
                    ...this.state.groups,
                    [dayProp]: {
                        loading: false,
                        currentTotal: null,
                        diff: null,
                        data: null,
                    }
                }
            });
        }
    }

    getGroupsConsumptionWeekData = async (date) => {
        const weekProp = date ? 'lastWeekConsumption' : 'weekConsumption';

        try {
            const { data } = await API.HOME.CONSUMPTION.GROUPS.WEEK(date);
            this.setState({
                groups: {
                    ...this.state.groups,
                    [weekProp]: {
                        loading: false,
                        currentTotal: data.currentTotalConsumption,
                        diff: data.diffConsumption,
                        data: data.data,
                    }
                }
            });
        } catch (error) {
            this.setState({
                groups: {
                    ...this.state.groups,
                    [weekProp]: {
                        loading: false,
                        currentTotal: null,
                        diff: null,
                        data: null,
                    }
                }
            })
        }
    }

    getGroupsConsumptionMonthData = async (date) => {
        const monthProp = date ? 'lastMonthConsumption' : 'monthConsumption';

        try {
            const { data } = await API.HOME.CONSUMPTION.GROUPS.MONTH(date);
            this.setState({
                groups: {
                    ...this.state.groups,
                    [monthProp]: {
                        loading: false,
                        currentTotal: data.currentTotalConsumption,
                        diff: data.diffConsumption,
                        data: data.data,
                    }
                }
            });
        } catch (error) {
            this.setState({
                groups: {
                    ...this.state.groups,
                    [monthProp]: {
                        loading: false,
                        currentTotal: null,
                        diff: null,
                        data: null,
                    }
                }
            })
        }
    }

    getSensorsConsumptionDayData = async () => {
        try {
            const { data } = await API.HOME.CONSUMPTION.SENSORS.DAY();

            this.setState({
                sensors: {
                    ...this.state.sensors,
                    dayConsumption: {
                        loading: false,
                        data: data.data,
                    }
                }
            });
        } catch (error) {
            this.setState({
                sensors: {
                    ...this.state.sensors,
                    dayConsumption: {
                        loading: false,
                        data: null,
                    }
                }
            });
        }
    }

    getSensorsConsumptionWeekData = async () => {
        try {
            const { data } = await API.HOME.CONSUMPTION.SENSORS.WEEK();

            this.setState({
                sensors: {
                    ...this.state.sensors,
                    weekConsumption: {
                        loading: false,
                        data: data.data,
                    }
                }
            });
        } catch (error) {
            this.setState({
                sensors: {
                    ...this.state.sensors,
                    weekConsumption: {
                        loading: false,
                        data: null,
                    }
                }
            });
        }
    }

    getSensorsConsumptionMonthData = async () => {
        try {
            const { data } = await API.HOME.CONSUMPTION.SENSORS.MONTH();

            this.setState({
                sensors: {
                    ...this.state.sensors,
                    monthConsumption: {
                        loading: false,
                        data: data.data,
                    }
                }
            });
        } catch (error) {
            this.setState({
                sensors: {
                    ...this.state.sensors,
                    monthConsumption: {
                        loading: false,
                        data: null,
                    }
                }
            });
        }
    }

    render() {
        const { classes } = this.props;
        const { groups, sensors } = this.state;

        return (
            <section id="page-home-consumption">
                <Title element="h4" text={i18n.t("Consumption")} />
                <div className={classes.consumptionsContent}>
                    <GroupsPieChart
                        type="consumption"
                        selectedPeriod={groups.activePeriod}
                        changePeriod={this.changeGroupsPeriod}
                        valueFormat={kwhDecimalFormat}
                        data={groups[`${groups.activePeriod}Consumption`].data}
                        loading={groups[`${groups.activePeriod}Consumption`].loading}
                    />
                    <div>
                        <div className={classes.consumptionTotals}>
                            <TotalCard
                                title={i18n.t('Total aggregated consumption')}
                                period="DAY"
                                type="consumption"
                                loading={groups.dayConsumption.loading}
                                diff={groups.dayConsumption.diff}
                                value={groups.dayConsumption.currentTotal}
                                lastPeriod={groups.lastDayConsumption}
                                valueFormat={kwhFormat}
                                percentageFormat={percentageFormat}
                            />
                            <TotalCard
                                title={i18n.t('Total aggregated consumption')}
                                period="WEEK"
                                type="consumption"
                                loading={groups.weekConsumption.loading}
                                diff={groups.weekConsumption.diff}
                                value={groups.weekConsumption.currentTotal}
                                lastPeriod={groups.lastWeekConsumption}
                                valueFormat={kwhFormat}
                                percentageFormat={percentageFormat}
                            />
                            <TotalCard
                                title={i18n.t('Total aggregated consumption')}
                                period="MONTH"
                                type="consumption"
                                loading={groups.monthConsumption.loading}
                                diff={groups.monthConsumption.diff}
                                value={groups.monthConsumption.currentTotal}
                                lastPeriod={groups.lastMonthConsumption}
                                valueFormat={kwhFormat}
                                percentageFormat={percentageFormat}
                            />
                        </div>
                        <ConsumptionSensorBarChart
                            data={sensors[`${sensors.activePeriod}Consumption`].data}
                            type="consumption"
                            valueFormat={kwhFormat}
                            changePeriod={this.changeSensorsPeriod}
                            selectedPeriod={sensors.activePeriod}
                            loading={sensors[`${sensors.activePeriod}Consumption`].loading}
                        />
                    </div>
                </div>
            </section>
        )
    }
}

export default withStyles(styles)(ConsumptionSection);
