import React from 'react';
import axios from 'axios';
import d3 from 'd3';
import NVD3Chart from 'react-nvd3';
import moment from 'moment-timezone';
import { injectIntl, intlShape } from 'react-intl'
import { withStyles } from 'material-ui/styles';
// import classNames from 'classnames';

// Translation
import i18n from '../../../i18n';

import {API} from '../../../components/api';
import {adjustJSONToValidJSON, getSensorStructured, isEstimatedData} from '../../../components/utils';

import Button from 'material-ui/Button';
import Table, {TableBody, TableCell, TableHead, TableRow} from 'material-ui/Table';
import Switch from 'material-ui/Switch';
import {Text} from '../../../elements/text';
import {nest} from 'd3-collection'
import {utcDays, utcMonths} from 'd3-time'
import {format} from 'd3-format'
import {sum,mean,max} from 'd3-array'
import {scaleTime, scaleLinear} from 'd3-scale'
import DatePicker from '../filter-date-picker'
import ChartLoading from '../chart-loading'
import SystemSnackbar from '../../../elements/material-ui/SystemSnackbar'
import GroupCompare from '../../../elements/material-ui/GroupCompare'

import IconButton from 'material-ui/IconButton';
import ExpandMoreIcon from 'material-ui-icons/ArrowDropDown';

import 'nvd3/build/nv.d3.min.css'
import './dashboard.css'
import 'react-select/dist/react-select.css';

const styles = {
    switchBase: {
        height: '34px',
        '& + $bar': {
            backgroundColor: 'green',
        },
        '&$checked': {
            '& + $bar': {
                backgroundColor: 'green',
            },
        },
    },
    switchBaseOnOff: {
        height: '34px',
        '& + $bar': {
            backgroundColor: '#b5b5b5',
        },
        '&$checked': {
            '& + $bar': {
                backgroundColor: 'green',
            },
        },
    },
    disabled: {
        '&$switchBase': {
            '& + $bar': {
                backgroundColor: 'gray',
            },
            '&$checked': {
                '& + $bar': {
                    backgroundColor: 'gray',
                },
            },
        },
    },
    bar: {},
    checked: {},
};

const yTickFormat = (d) => {
    if (Number.isInteger(d)){
        return format("d")(d);
    }else if(d < 1){
        return format(".2r")(d);
    }else{
        return format(".2f")(d);
    }
}

const kwhFormat = (d) => {
    if (d < 1){
        return format(".2r")(d);
    }else{
        return format(".2f")(d);
    }
}

let baseURLDefault;


// category20 color scheme (from D3v3) rearranged for darker first, then lighter
// (instead of dark blue, light blue, dark green, light green...)
const colors = ["#1f77b4","#ff7f0e","#2ca02c","#d62728","#9467bd","#8c564b","#e377c2","#7f7f7f","#bcbd22","#17becf","#aec7e8","#ffbb78","#98df8a","#ff9896","#c5b0d5","#c49c94","#f7b6d2","#c7c7c7","#dbdb8d","#9edae5"];
const noDataColor = "#000000";

const daysToMonthGranularity = 92;

class ConsumptionLineChart extends React.PureComponent {
    constructor (props) {
        super(props);
        let today = moment();

        this.state = {
            compare: [{
                filterGroupOptions: [],
                filterSubgroupOptions: [],
                filterSensorOptions: [],
            }],
            tagsMap: {},
            dataGranularity: 'FIVE_MINUTES',
            loadingGroups: true,
            loadingTags: true,
            loadingData: false,
            periodFilter: 'day',
            filterGroup: null,
            filterSubgroup: null,
            filterSensor: null,
            breadcrumbsTrail: '',
            rolledup: true,
            noDataChartMsg: this.loadingChartMessage,
            groups: [],
            xTicks: [],
            xDomain: [],
            data:[],
            tags:[],
            events: [],
            isLinear: false,
            requestsToDraw: 0,
            total: 0,
            totals: [],
            totalCost: 0,
            totalCosts: [],
            currency: "",
            min: {
                consumption: null,
                cost: 0,
                hour: '00:00:00',
            },
            mins: [],
            max: {
                consumption: null,
                cost: 0,
                hour: '00:00:00',
            },
            maxes: [],
            average: 0,
            averages: [],
            datePicker: {
                open: false,
                endDate: today,
                startDate: today,
                data: {
                    endDate: today,
                    focusedInput: 'startDate',
                    startDate: today,
                },
            },
            dateRangePicker: {
                open: false,
                endDate: today,
                startDate: today.clone().subtract(2, 'days'),
                data: {
                    endDate: today,
                    focusedInput: 'endDate',
                    startDate: today.clone().subtract(2, 'days'),
                },
            },
            screenWidth: 0,
            screenHeight: 0,
            hadScreenResize: false,
            diffOfSelectedDays: 0,
            loadingEvents: true,
            showAlertEvents: true,
        };

        baseURLDefault = props.location.pathname.includes('cumulative')
            ? '/analysis/consumption/cumulative'
            : '/analysis/consumption';

        this.isCumulative = baseURLDefault.includes('cumulative');
        this.maximumDate = today.clone().add(1, 'day').endOf('day');
        this.willLoadData = true;
        this.costFormat = (currency, value) => {
            return currency && value > 0 ? `${this.props.intl.formatNumber(value, {style: 'currency', currency: currency})}` : ' - '
        }
        this.compareIndexOpen = undefined;
    }

    componentWillMount = async () => {
        try {
            const { data } = await API.LABELS.GET()

            if (data) {
                this.setState({
                    tagsMap: data.reduce((prev, cur) => ({ ...prev, [cur.id]: cur.name }), {}),
                    tags: data,
                    loadingTags: false,
                });
            }else{
                this.handleLoadError({loadingTags: false}, i18n.t("Could not load the tags"))
            }
        } catch (error) {
            this.handleLoadError({loadingTags: false}, i18n.t("Could not load the tags"), error)
        }

        API.GROUPS.GET().then((response) => {
            if (response.data && response.data.length > 0){
                this.groupsNamesMap = this.createGroupsNamesMap(response.data);
                this.sensorsPhasesNamesMap = this.createSensorsPhasesNamesMap(response.data);
                this.setState({
                    groups: response.data,
                    loadingGroups: false,
                    filterGroupOptions: response.data.map(group => { return {name: group.name, id: group.id} }),
                }, this.checkGeneralChangesOnComponent);
            }else if (response.data && response.data.length === 0){
                this.setState({
                    loadingGroups: false,
                    noDataChartMsg: this.noGroupChartMessage,
                });
            }else{
                this.handleLoadError({loadingGroups: false}, i18n.t("Could not load groups data"))
            }
        }).catch((error) => {
            this.handleLoadError({loadingGroups: false}, i18n.t("Could not load groups data"), error)
        });

        this.updateDimensions();
    }

    componentDidMount () {
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillReceiveProps (nextProps) {
        this.checkGeneralChangesOnComponent(nextProps);
    }

    componentWillUpdate(nextProps, nextState){
        // screen was resized
        if (nextState.screenWidth !== this.state.screenWidth || nextState.screenHeight !== this.state.screenHeight){
            // remove existing weekend highlight to force redraw
            d3.selectAll('.nv-chart svg .weekend-highlight').remove();
            d3.selectAll('.nv-chart svg .after-work-highlight').remove();
            this.removeEstimatesLines();
            // this.removeAlertEvents();
        }
    }

    componentDidUpdate () {
        if (this.state.breadcrumbsTrail !== this.getBreadcrumbsTrail()){
            this.setState({
                breadcrumbsTrail: this.getBreadcrumbsTrail()
            });
        }

        if (!this.state.decomposedData && this.state.rolledup === false ){
            this.setState({
                rolledup: true
            });
        }

        // fixes tooltip mark that stayed on the chart when changing data
        d3.selectAll('.nvtooltip').remove();

        if (this.state.periodFilter === 'day'){
            this.addAfterWorkHighlight();
        }

        if (this.state.periodFilter === 'week' || this.state.periodFilter === 'month' || (this.state.periodFilter === 'custom' && this.state.diffOfSelectedDays < daysToMonthGranularity)){
            // when in 'custom' period, it avoids executing when selecting start date of the range:
            if (this.state.dateRangePicker.open === false)
                this.addWeekEndsHighlight();

            if (!this.state.loadingData)
                this.removeEstimatesLines();
        }

        if (!this.state.loadingData &&
            this.state.requestsToDraw > 0 && this.state.requestsToDraw === this.state.data.length){
            // when in 'custom' period, it avoids executing when selecting start date of the range:
            if (this.state.dateRangePicker.open === false)
                this.resetEstimatesLines(false);
            if (this.state.periodFilter !== 'year' && !(this.state.periodFilter === 'custom' && this.state.diffOfSelectedDays >= daysToMonthGranularity))
                this.resetAlertEventsLines();
        }

        if (!this.state.showAlertEvents)
            this.removeAlertEvents();

        if (this.state.hadScreenResize){
            this.removeEstimatesLines();
            this.removeAlertEvents();

            //to force state update and then force the chart redraw of x axis, background lines, etc
            setTimeout(() => {
              this.setState({hadScreenResize: false});
          }, 1250); // after 1 second to execute it after chart transitions (animation lasts 250 ms)
        }
    }

    componentWillUnmount () {
        window.removeEventListener("resize", this.updateDimensions);
        document.body.removeEventListener('click', this.onOutsideCompareClick);
        clearTimeout(this.onOutsideCompareClickTimer);
    }

    loadErrorMessage = () => i18n.t("An error occurred while retrieving data");
    loadingChartMessage = i18n.t("Loading data");
    noDataChartMessage = i18n.t("There is no data available for the selected criteria");
    noGroupChartMessage = i18n.t("There is no data available as no group has been set up");
    dayGranularities = [
        { label: i18n.t('5 Min'), value: 'FIVE_MINUTES', minutes: 5 },
        { label: i18n.t('30 Min'), value: 'THIRTY_MINUTES', minutes: 30 },
        { label: i18n.t('1 Hour'), value: 'ONE_HOUR', minutes: 60 },
    ];

    periodsText = {
        day: i18n.t('24 Hours'),
        week: i18n.t('1 Week'),
        month: i18n.t('1 Month'),
        custom: i18n.t('Custom'),
    }

    periodOptions = [
        {label: i18n.t('Day'), value: 'day'},
        {label: i18n.t('Week'), value: 'week'},
        {label: i18n.t('Month'), value: 'month'},
        {label: i18n.t('Year'), value: 'year'},
        {label: i18n.t('Custom'), value: 'custom'},
    ];

    // Based on https://stackoverflow.com/a/34475071
    updateDimensions = () => {
        let w = window,
        d = document,
        documentElement = d.documentElement,
        body = d.getElementsByTagName('body')[0],
        width = w.innerWidth || documentElement.clientWidth || body.clientWidth,
        height = w.innerHeight|| documentElement.clientHeight|| body.clientHeight;

        this.setState({
            screenWidth: width,
            screenHeight: height,
            hadScreenResize: true,
        });
    }

    xTickFormat = (d) => {
        let format = '';
        switch (this.state.periodFilter){
            case 'year':
                format = 'MMM';
                break;
            case 'month':
                format = '[LM]';
                break;
            case 'week':
                format = 'ddd DD';
                break;
            case 'day':
                format = 'LT';
                break;
            case 'custom':
                if (this.state.diffOfSelectedDays < 5){
                    format = '[LDDMMHHmm]';
                }else if (this.state.diffOfSelectedDays >= daysToMonthGranularity){
                    format = 'MMM';
                }else{
                    format = '[LM]';
                }
                break;
            default:
                // day format
                format = 'LT';
                console.warn("An invalid x tick format was found. Assuming day x tick format.");
        }
        return moment(d).format(format);
    }

    tooltipHeaderFormatter = (strDate) => {
        if (this.state.periodFilter === 'day') {
            if (this.state.dataGranularity === 'ONE_HOUR')
                return `${moment(strDate).format('LT')} - ${moment(strDate).add(1, 'hour').format('LT')}`

            if (this.state.dataGranularity === 'THIRTY_MINUTES')
                return `${moment(strDate).format('LT')} - ${moment(strDate).add(30, 'minute').format('LT')}`

            if (this.state.dataGranularity === 'FIVE_MINUTES')
                return `${moment(strDate).format('LT')} - ${moment(strDate).add(5, 'minute').format('LT')}`
        }

        if (this.state.periodFilter === 'week') {
            return `${moment(strDate).format('LLLL')} - ${moment(strDate).add(1, 'hour').format('LT')}`
        }


        let format = '';
        switch (this.state.periodFilter) {
            case 'year':
                format = '[LMMMMYYYY]';
                break;
            case 'month':
                format = 'LL';
                break;
            case 'custom':
                if (this.state.diffOfSelectedDays < 30){
                    format = 'LLLL';
                }else if (this.state.diffOfSelectedDays >= daysToMonthGranularity){
                    format = '[LMMMMYYYY]';
                }else{
                    format = 'LL';
                }
                break;
            default:
                format = 'LT';// day format
                console.warn("An invalid period filter was found for tooltip header. Assuming day format.");
        }
        return moment(strDate).format(format);
    }

    awaitChartData = (state, promises, eventsPromises) => {
        if (eventsPromises && eventsPromises.length){
            axios.all(eventsPromises).then((responses, i) => {
                state.events = [];
                responses.forEach((response, i) => {
                    if (response.data && response.data.length){
                        //creates events key with same pattern of this.state.data for sensor and group
                        //(related to legend interaction and tooltip implementations)
                        const events = response.data.map(event => {
                            let key;
                            if (event.sensorId && event.channel){
                                const sensorData = getSensorStructured({
                                    name: this.getSensorPhaseName(event.sensorId, event.channel),
                                    id: event.sensorId,
                                    channel: event.channel,
                                });
                                key = sensorData.label;
                            }else{
                                key = event.groupId+'';
                            }

                            return {
                                ...event,
                                timestamp: moment(event.eventStartPeriod).toDate().valueOf(),
                                key: key,
                            }
                        });
                        Object.assign(state, {
                            events: (state.events) ? state.events.concat(events) : [].concat(events),
                            loadingEvents: false,
                        });
                    }
                });
                this.setState(state);
            }).catch((error) => {
                this.handleLoadError({loadingEvents: false}, i18n.t("Could not load alert events data"), error)
            });
        }

        axios.all(promises).then((responses) => {
            state.data = [];
            state.totals = [];
            state.totalCosts = [];
            state.mins = [];
            state.maxes = [];
            state.averages = [];
            state.requestsToDraw = responses.length;
            responses.forEach((response, index) => {
                if (response.data) {
                    response.data.data = response.data.data ?
                        this.isCumulative
                            ? this.sumPositiveValues(response.data.data)
                            : this.ensurePositiveValues(response.data.data)
                        : [];

                    let data = [];
                    if (response.data.groupId){
                        data = this.createGroupData(response.data);
                    }else if(response.data.sensorId){
                        data = this.createSensorData(response.data);
                    }else if(response.data.labelId){
                        data = this.createTagData(response.data);
                    }

                    // check if single point and add class to show circle on point
                    if (data.length && data[0].values && data[0].values.length === 1)
                        data[0] = {...data[0], classed: 'nv-single-point-line'};

                    if (!data.length)
                        state.requestsToDraw = state.requestsToDraw - 1;

                    let averages = state.averages ? state.averages.concat(response.data.avgConsumption) : [].concat(response.data.avgConsumption);
                    averages = averages.filter(s => s);
                    let totals = state.totals ? state.totals.concat(response.data.totalConsumption) : [].concat(response.data.totalConsumption);
                    totals = totals.filter(s => s);
                    let totalCosts = state.totalCosts ? state.totalCosts.concat(response.data.totalCost) : [].concat(response.data.totalCost);
                    totalCosts = totalCosts.filter(s => s);
                    const lastCurrency = (response.data.tariffs && response.data.tariffs.length > 0) ? response.data.tariffs[0].currency : null;
                    const max = response.data.maxDayAndHourConsumption ||
                                response.data.maxHourConsumption ||
                                response.data.maxDayConsumption ||
                                response.data.maxMonthConsumption ||
                                {
                                    consumption: null,
                                    cost: 0,
                                    hour: '00:00:00',
                                };
                    let min = response.data.minDayAndHourConsumption ||
                                response.data.minHourConsumption ||
                                response.data.minDayConsumption ||
                                response.data.minMonthConsumption ||
                                {
                                    consumption: null,
                                    cost: 0,
                                    hour: '00:00:00',
                                };

                    if (min && min.consumption < 0){
                        if (data && data[0] && data[0].values && data[0].values.length){
                            const dataWithoutNegativeValues = data[0].values.filter(d => d.defined);
                            min = dataWithoutNegativeValues.reduce((min, d) => {
                                return (!d.consumption || d.consumption < min.consumption) ? d : min;
                            }, dataWithoutNegativeValues[0]);
                        }else{
                            min.consumption = 0;
                        }
                    }

                    let mins;
                    // if the data has no min consumption, doesn't include it in mins array
                    // (it avoids finding 0 consumption for data that has no consumption data)
                    if (min && min.consumption === null){
                        mins = state.mins ? [].concat(...state.mins) : [];
                    }else{
                        mins = state.mins ? state.mins.concat(min) : [].concat(min);
                    }
                    const maxes = state.maxes ? state.maxes.concat(max) : [].concat(max);

                    Object.assign(state, {
                        loadingData: false,
                        data: (state.data) ? state.data.concat(data) : [].concat(data),
                        breadcrumbsTrail: this.getBreadcrumbsTrail(),
                        total: sum(totals),
                        totals: totals,
                        totalCost: sum(totalCosts),
                        totalCosts: totalCosts,
                        currency: lastCurrency,
                        average: averages && averages.length > 0 ? mean(averages) : 0,
                        averages: averages,
                        noDataChartMsg: this.noDataChartMessage,
                        min: mins.reduce((min, cur) => (min.consumption < cur.consumption) ? min : cur, 0),
                        mins: mins,
                        max: maxes.reduce((max, cur) => (max.consumption > cur.consumption) ? max : cur, 0),
                        maxes: maxes,
                        xDomain: this.getXDomain(),
                        xTicks: this.getXTicks(),
                    });
                    Object.assign(state.compare[index], {
                        average: response.data.avgConsumption,
                        max: max,
                        min: min,
                        total: this.isCumulative
                            ? response.data.data && response.data.data[response.data.data.length - 1]
                                ? response.data.data[response.data.data.length - 1].consumption
                                : 0
                            : response.data.totalConsumption,
                        totalCost: response.data.totalCost,
                    });
                    this.setState(state);
                }else{
                    this.handleLoadError({loadingData: false}, i18n.t("Could not load chart data"))
                }
            });
        }).catch((error) => {
            this.handleLoadError({loadingData: false}, i18n.t("Could not load chart data"), error)
        });
    }

    getCompareAndLoadChartData = (state) => {
        const ids = this.getIdsJSONFromURL(window.location.search);
        let promises = [], alertEventsPromises = [];

        ids.forEach((obj, index) => {
            const compare = (this.willLoadData || !this.state.compare[index])
                ? {}
                : this.state.compare[index];
            let groupData, subgroupData, sensorData, tagData;

            compare.open = obj.open;
            if (obj.open) {
                this.compareIndexOpen = index;
                document.body.addEventListener('click', this.onOutsideCompareClick);
            }

            if (obj.gid) {
                groupData = this.getGroupSelectedById(obj.gid, 0);
                if (groupData) Object.assign(compare, groupData);
            }
            if (obj.sgid) {
                subgroupData = this.getSubgroupSelectedById(obj.sgid, compare.filterGroup);
                if (subgroupData) Object.assign(compare, subgroupData);
            }
            if (obj.sid) {
                sensorData = this.getSensorSelectedById(obj.sid, obj.sph, compare);
                if (sensorData) Object.assign(compare, sensorData);
            }

            if (obj.tag) {
                tagData = this.getTagSelectedById(obj.tag, 0);
                if (tagData) Object.assign(compare, tagData);
            }

            state.compare.push(compare);
            if (this.willLoadData){
                promises.push(sensorData
                    ? this.loadSensorChart(state, index)
                    : tagData
                        ? this.loadTagChart(state, index)
                        : this.loadGroupChart(state, index)
                );
                alertEventsPromises.push(sensorData
                    ? this.loadSensorAlertEvents(state, index)
                    : subgroupData || groupData
                        ? this.loadGroupAlertEvents(state, index)
                        : null
                );
            }
        });

        this.awaitChartData(state, promises, alertEventsPromises.filter(d => d));
        return state;
    }

    getGroupSelectedById = (id) => {
        const group = id ? this.state.groups.find((group) => group.id === id) : this.state.groups[0];
        if (id && !group) {
            this.goToURL(this.getFullURL([{gid: this.state.groups[0].id}]));
            return;
        }

        return {
            filterGroup: group,
            filterSubgroup: null,
            filterSubgroupOptions: this.getGroupSubgroupsOptions(group),
            filterSensor: null,
            filterSensorOptions: [],
            noDataChartMsg: this.loadingChartMessage,
        };
    }

    getSubgroupSelectedById = (id, group) => {
        const subgroup = group.subGroups.find((subgroup) => subgroup.id === id);
        if (id && !subgroup) {
            this.goToURL(this.getFullURL([{gid: group.id, sgid: group.subGroups[0].id}]));
            return;
        }

        return {
            filterSubgroup: subgroup,
            filterSensor: null,
            filterSensorOptions: this.getSubgroupSensorsOptions(subgroup),
            noDataChartMsg: this.loadingChartMessage,
        };
    }

    getSensorSelectedById = (id, channel, state) => {
        let sensor = state.filterSensorOptions.find((sensor) => sensor.id === id && sensor.channel === channel);
        if (id && !sensor) {
            sensor = state.filterSubgroup.subGroups[0].sensors[0];
            this.goToURL(this.getFullURL([{
                gid: state.filterGroup.id,
                sgid: state.filterSubgroup.id,
                sid: sensor.id,
                sph: sensor.channel,
            }]));
            return;
        }

        return {
            filterSensor: sensor,
            noDataChartMsg: this.loadingChartMessage,
        };
    }

    getTagSelectedById = id => {
        const tag = id ? this.state.tags.find(tag => tag.id === id) : this.state.tags[0];
        if (id && !tag) {
            this.goToURL(this.getFullURL([{ tag: this.state.tags[0].id}]));
            return;
        }

        return {
            filterTag: tag,
            noDataChartMsg: this.loadingChartMessage,
        };
    }

    checkGeneralChangesOnComponent = (nextProps) => {
        const props = nextProps || this.props;
        const searchParams = new URLSearchParams(props.location.search);

        if (!props.location.search || searchParams.get('ids') === 'null') {
            if (this.state.groups && this.state.groups.length)
                this.props.history.push(`${baseURLDefault}?ids=[{gid:${this.state.groups[0].id}}]&step`);
            return;
        }

        let state = {
            compare: [],
            datePicker: this.state.datePicker,
            dateRangePicker: this.state.dateRangePicker
        }, startDate, endDate;


        state.rolledup = true;
        // state.rolledup = !(
        //     (searchParams.get('rolledup') === 'false') || props.location.pathname.indexOf('rolledup=false') >= 0);
        state.periodFilter = searchParams.get('period') ? searchParams.get('period') : 'day';
        if (searchParams.get('date')) {
            startDate = moment(searchParams.get('date'));

            if (state.periodFilter === 'week') {
                startDate = startDate.startOf('week');
                endDate = startDate.clone().endOf('week');
                endDate = endDate.isAfter(this.maximumDate) ? this.maximumDate : endDate;
            } else endDate = startDate;

            state.datePicker.open = false;
            state.datePicker.startDate = startDate;
            state.datePicker.endDate = endDate;
            state.datePicker.data.startDate = startDate;
            state.datePicker.data.endDate = endDate;
        }

        if (searchParams.get('startDate') && searchParams.get('endDate')) {
            startDate = moment(searchParams.get('startDate'));
            endDate = moment(searchParams.get('endDate'));

            state.dateRangePicker.open = false;
            state.dateRangePicker.startDate = startDate;
            state.dateRangePicker.endDate = endDate;
            state.dateRangePicker.data.startDate = startDate;
            state.dateRangePicker.data.endDate = endDate;
            state.diffOfSelectedDays = (startDate && endDate) ? endDate.diff(startDate, 'days') : 0;
        }

        state.isLinear = !searchParams.has('step');
        state.showAlertEvents = searchParams.has('alerts');
        state.dataGranularity = searchParams.has('dataGranularity') ? searchParams.get('dataGranularity') : 'FIVE_MINUTES';

        state = this.getCompareAndLoadChartData(state);
        this.setState(state);

        // Always enforce to load data again
        this.willLoadData = true;
    }

    createGroupsNamesMap = (groups) => {
        const idNamesPairsArray = groups.map((group) => {
            const groupPair = [group.id, group.name];
            const subgroupPair = group.subGroups.map((subgroup) => {
                return [subgroup.id, subgroup.name];
            })
            return subgroupPair.concat([groupPair]);
        }).reduce( (acc,x)=> acc.concat(x));

        return new Map(idNamesPairsArray);
    }

    // Create a Map that lets get the sensor name from sensor-phase
    createSensorsPhasesNamesMap = (groups) => {
        const sensorPhasesMap = new Map();
        groups.forEach((group) => {
            group.sensors.forEach((sensorPhase) => sensorPhasesMap.set(sensorPhase.id + "-" + sensorPhase.channel, sensorPhase.name));
            group.subGroups.forEach((subgroup) => {
                if (subgroup.sensors && subgroup.sensors.length > 0)
                    subgroup.sensors.forEach((sensorPhase) => sensorPhasesMap.set(sensorPhase.id + "-" + sensorPhase.channel, sensorPhase.name));

                if (subgroup.subGroups && subgroup.subGroups.length > 0){
                    subgroup.subGroups.forEach(innerSubgroup => {
                        if (innerSubgroup.sensors && innerSubgroup.sensors.length > 0)
                            innerSubgroup.sensors.forEach((sensorPhase) => sensorPhasesMap.set(sensorPhase.id + "-" + sensorPhase.channel, sensorPhase.name))
                    });
                }
            })
        });

        return sensorPhasesMap;
    }

    getGroupName = (groupId) => {
        return this.groupsNamesMap.get(+groupId);
    }

    getTagName = tagId => this.state.tagsMap[tagId] ? `# ${this.state.tagsMap[tagId]}` : null

    getSensorPhaseName = (sensor, phase) => {
        return this.sensorsPhasesNamesMap.get(sensor + '-' + phase);
    }

    getXDomain = () => {
        // this forces the x axis to go from 12AM to 12PM, when period is day (sunday to saturday, when week. Each month day, when month)
        // even if the data is partial for the period
        // NVD3 forceX function only understands it if we use getTime() to get the numeric value for the day object
        let domain = [], date;

        switch (this.state.periodFilter) {
            case 'year':
                const yearStart = this.state.datePicker.startDate.clone().startOf('year');
                const yearEnd = this.state.datePicker.startDate.clone().endOf('year');
                domain = [yearStart.valueOf(), yearEnd.valueOf()];
                break;
            case 'month':
                const monthStart = this.state.datePicker.startDate.clone().startOf('month');
                const monthEnd = this.state.datePicker.startDate.clone().endOf('month');
                domain = [monthStart.valueOf(), monthEnd.valueOf()];
                break;
            case 'week':
                date = this.state.datePicker.startDate.clone().startOf('week');
                domain = [date.valueOf(), date.add(7, 'days').valueOf()];
                break;
            case 'day':
                date = this.state.datePicker.startDate.clone().startOf('day');
                domain = [date.valueOf(), date.add('1', 'days').valueOf()];
                break;
            case 'custom':
                const periodToConsider = (this.state.diffOfSelectedDays >= daysToMonthGranularity) ? 'month' : 'day';
                const periodStart = this.state.dateRangePicker.startDate.clone().startOf(periodToConsider);
                const periodEnd = this.state.dateRangePicker.endDate.clone().endOf(periodToConsider);
                domain = [periodStart.valueOf(), periodEnd.valueOf()];
                break;
            default:
                // day domain
                date = this.state.datePicker.startDate.clone().startOf('day');
                domain = [date.valueOf(), date.add('1', 'days').valueOf()];
                console.warn("An invalid x domain format was found. Assuming day x domain.");
        }
        return domain;
    }

    getXTicks = () => {
        let xTicks = [];
        switch (this.state.periodFilter) {
            case 'year':
                const yearStart = this.state.datePicker.startDate.clone().startOf('year');
                const yearEnd = this.state.datePicker.startDate.clone().endOf('year');
                xTicks = utcMonths(
                    yearStart,
                    yearEnd,
                ).map((value) => moment(value).valueOf());
                break;
            case 'month':
                const monthStart = this.state.datePicker.startDate.clone().startOf('month');
                const monthEnd = this.state.datePicker.startDate.clone().endOf('month');
                xTicks = utcDays(
                    monthStart,
                    monthEnd,
                ).map((value) => moment(value).valueOf());
                break;
            case 'week':
                let momentDate = this.state.datePicker.startDate.clone();
                xTicks = utcDays(
                    momentDate.startOf('week'),
                    momentDate.clone().add(7, 'days')
                ).map((value) => moment(value).valueOf());
                break;
            case 'day':
            case 'custom':
                xTicks = [];
                break;
            default:
                xTicks = [];
                console.warn("An invalid period filter was found for x ticks. Returning no ticks.");
        }
        return  xTicks;
    }

    getYDomain = () =>{
        let maximum = this.state.max;

        if (this.isCumulative){
            const data = this.state.data;

            if (data && data.length){
                const peakConsumptionValues = data.map(d => d.values[d.values.length - 1].consumption);
                maximum = { consumption: max(peakConsumptionValues) };
            }
        }

        if (maximum && maximum.consumption < 0.1){
            return [0, 0.1];
        }else{
            return maximum && maximum.consumption ? [0, maximum.consumption] : [0];
        }
    }

    // Gets the index of selected element (group/subgroup/sensor-phase) on chart's data.
    // It will return -1 when the element selected has no data to be drawn
    getSelectedIndexOnChart = (compare) => {
        if (compare){
            let compareKey = "";
            if (compare.filterSensor && compare.filterSensor.name){
                compareKey = compare.filterSensor.label;
            }else if (compare.filterSubgroup && compare.filterSubgroup.name){
                compareKey = compare.filterSubgroup.id;
            }else if (compare.filterGroup && compare.filterGroup.name){
                compareKey = compare.filterGroup.id;
            }else if (compare.filterTag && compare.filterTag.name){
                compareKey = compare.filterTag.id;
            }
            const dataIndex = this.state.data.findIndex((datum) => datum.key === compareKey+"" );
            return dataIndex;
        }else{
            return null;
        }
    }

    getColor = (compareIndex) => {
        const compare = this.state.compare[compareIndex];

        if (compare){
            const dataIndex = this.getSelectedIndexOnChart(compare);
            return (dataIndex >= 0) ? colors[dataIndex % colors.length] : noDataColor;
        }else{
            return noDataColor;
        }
    }

    loadGroupChart = (state, index) => {
        d3.selectAll('.nv-chart svg .weekend-highlight').remove();
        d3.selectAll('.nv-chart svg .after-work-highlight').remove();
        this.removeAlertEvents();

        this.setState({ loadingData: true, noDataChartMsg: this.loadingChartMessage, xTicks: [] });
        const compare = state.compare[index],
              groupId = compare.filterSubgroup ? compare.filterSubgroup.id : compare.filterGroup.id;

        let date = state.datePicker.startDate.format('YYYY-MM-DD');
        let startDate = null, endDate;
        let apiFn;
        let dataGranularity = null;
        switch (state.periodFilter) {
            case 'year':
                apiFn = API.VISUALIZATIONS.CONSUMPTION.LINE.YEAR.GROUP;
                date = state.datePicker.startDate.format('YYYY');
                break;
            case 'month':
                apiFn = API.VISUALIZATIONS.CONSUMPTION.LINE.MONTH.GROUP;
                date = state.datePicker.startDate.format('YYYYMM');
                break;
            case 'week':
                apiFn = API.VISUALIZATIONS.CONSUMPTION.LINE.WEEK.GROUP;
                break;
            case 'day':
                apiFn = API.VISUALIZATIONS.CONSUMPTION.LINE.DAY.GROUP;
                dataGranularity = state.dataGranularity;
                break;
            case 'custom':
                apiFn = API.VISUALIZATIONS.CONSUMPTION.LINE.CUSTOM.GROUP;
                startDate = state.dateRangePicker.startDate.format('YYYY-MM-DD');
                endDate = state.dateRangePicker.endDate.format('YYYY-MM-DD');
                break;
            default:
                apiFn = API.VISUALIZATIONS.CONSUMPTION.LINE.DAY.GROUP;
                console.warn("An invalid period filter was found. Assuming day period for group load.");
        }

        if (startDate === null){
            return apiFn(groupId, date, dataGranularity);
        }else{
            return apiFn(groupId, startDate, endDate, dataGranularity);
        }
    }

    loadSensorChart = (state, index) => {
        d3.selectAll('.nvtooltip').remove();
        d3.selectAll('.nv-chart svg .weekend-highlight').remove();
        d3.selectAll('.nv-chart svg .after-work-highlight').remove();
        this.removeAlertEvents();

        this.setState({ loadingData: true, noDataChartMsg: this.loadingChartMessage, xTicks: [] });

        let date = state.datePicker.startDate.format('YYYY-MM-DD');
        let startDate = null, endDate;
        let apiFn = null;
        let dataGranularity = null;
        switch (state.periodFilter) {
            case 'year':
                apiFn = API.VISUALIZATIONS.CONSUMPTION.LINE.YEAR.SENSOR;
                date = state.datePicker.startDate.format('YYYY');
                break;
            case 'month':
                apiFn = API.VISUALIZATIONS.CONSUMPTION.LINE.MONTH.SENSOR;
                date = state.datePicker.startDate.format('YYYYMM');
                break;
            case 'week':
                apiFn = API.VISUALIZATIONS.CONSUMPTION.LINE.WEEK.SENSOR;
                break;
            case 'day':
                apiFn = API.VISUALIZATIONS.CONSUMPTION.LINE.DAY.SENSOR;
                dataGranularity = state.dataGranularity
                break;
            case 'custom':
                apiFn = API.VISUALIZATIONS.CONSUMPTION.LINE.CUSTOM.SENSOR;
                startDate = state.dateRangePicker.startDate.format('YYYY-MM-DD');
                endDate = state.dateRangePicker.endDate.format('YYYY-MM-DD');
                break;
            default:
                apiFn = API.VISUALIZATIONS.CONSUMPTION.LINE.DAY.SENSOR;
                console.warn("An invalid period filter was found. Assuming day period for sensor load.");
        }

        if (startDate === null){
            return apiFn(
                state.compare[index].filterSensor.subgroupId,
                state.compare[index].filterSensor.id,
                state.compare[index].filterSensor.channel,
                date,
                dataGranularity
            );
        }else{
            return apiFn(
                state.compare[index].filterSensor.subgroupId,
                state.compare[index].filterSensor.id,
                state.compare[index].filterSensor.channel,
                startDate,
                endDate,
                dataGranularity
            );
        }
    }

    loadTagChart = (state, index) => {
        d3.selectAll('.nv-chart svg .weekend-highlight').remove();
        d3.selectAll('.nv-chart svg .after-work-highlight').remove();
        this.removeAlertEvents();

        this.setState({ loadingData: true, noDataChartMsg: this.loadingChartMessage, xTicks: [] });
        const compare = state.compare[index],
              tagId = compare.filterTag.id;

        let date = state.datePicker.startDate.format('YYYY-MM-DD');
        let startDate = null, endDate;
        let apiFn;
        let dataGranularity = null;
        switch (state.periodFilter) {
            case 'year':
                apiFn = API.VISUALIZATIONS.CONSUMPTION.LINE.YEAR.LABEL;
                date = state.datePicker.startDate.format('YYYY');
                break;
            case 'month':
                apiFn = API.VISUALIZATIONS.CONSUMPTION.LINE.MONTH.LABEL;
                date = state.datePicker.startDate.format('YYYYMM');
                break;
            case 'week':
                apiFn = API.VISUALIZATIONS.CONSUMPTION.LINE.WEEK.LABEL;
                break;
            case 'day':
                apiFn = API.VISUALIZATIONS.CONSUMPTION.LINE.DAY.LABEL;
                dataGranularity = state.dataGranularity;
                break;
            case 'custom':
                apiFn = API.VISUALIZATIONS.CONSUMPTION.LINE.CUSTOM.LABEL;
                startDate = state.dateRangePicker.startDate.format('YYYY-MM-DD');
                endDate = state.dateRangePicker.endDate.format('YYYY-MM-DD');
                break;
            default:
                apiFn = API.VISUALIZATIONS.CONSUMPTION.LINE.DAY.LABEL;
                console.warn("An invalid period filter was found. Assuming day period for tag load.");
        }

        if (startDate === null){
            return apiFn(tagId, date, dataGranularity);
        }else{
            return apiFn(tagId, startDate, endDate, dataGranularity);
        }
    }

    getAlertEventsPeriod = (state) => {
        let startDate, endDate;
        switch (state.periodFilter) {
            case 'year':
                startDate = state.datePicker.startDate.clone().startOf('year').format('YYYY-MM-DD');
                endDate = state.datePicker.startDate.clone().endOf('year').format('YYYY-MM-DD');
                break;
            case 'month':
                startDate = state.datePicker.startDate.clone().startOf('month').format('YYYY-MM-DD');
                endDate = state.datePicker.startDate.clone().endOf('month').format('YYYY-MM-DD');
                break;
            case 'week':
                startDate = state.datePicker.startDate.clone().startOf('week').format('YYYY-MM-DD');
                endDate = state.datePicker.startDate.clone().endOf('week').format('YYYY-MM-DD');
                break;
            case 'day':
                startDate = state.datePicker.startDate.clone().startOf('day').format('YYYY-MM-DD');
                endDate = state.datePicker.startDate.clone().endOf('day').format('YYYY-MM-DD');
                break;
            case 'custom':
                startDate = state.dateRangePicker.startDate.clone().startOf('day').format('YYYY-MM-DD');
                endDate = state.dateRangePicker.endDate.clone().endOf('day').format('YYYY-MM-DD');
                break;
            default:
                startDate = state.datePicker.startDate.clone().startOf('day').format('YYYY-MM-DD');
                endDate = state.datePicker.startDate.clone().endOf('day').format('YYYY-MM-DD');
                console.warn("An invalid period filter was found. Assuming day period for alert events.");
        }

        return {startDate, endDate};
    }

    loadGroupAlertEvents = (state, index) => {
        this.setState({ loadingEvents: true });
        const compare = state.compare[index],
              groupId = compare.filterSubgroup ? compare.filterSubgroup.id : compare.filterGroup.id;

        const period = this.getAlertEventsPeriod(state);

        return API.ALERTS.EVENTS.CONSUMPTION.GROUP(groupId, period.startDate, period.endDate);
    }

    loadSensorAlertEvents = (state, index) => {
        this.setState({ loadingEvents: true });
        const compare = state.compare[index];
        const sensorId = compare.filterSensor.id;
        const channel = compare.filterSensor.channel;

        const period = this.getAlertEventsPeriod(state);

        return API.ALERTS.EVENTS.CONSUMPTION.SENSOR(sensorId, channel, period.startDate, period.endDate);
    }

    getGroupSubgroupsOptions = (group) => {
        if (group.subGroups && group.subGroups.length > 0){
            return group.subGroups.map(subgroup => {
                return {
                    groupId: group.id,
                    name: subgroup.name,
                    id: subgroup.id
                }
            });
        }else{
            return [];
        }
    }

    getSubgroupSensorsOptions = (subgroup) => {
        // non main subgroups sensors are stored in a "artificial" sub-subgroup
        if (subgroup.subGroups && subgroup.subGroups[0]
            && subgroup.subGroups[0].sensors
            && subgroup.subGroups[0].sensors.length > 0){

            return subgroup.subGroups[0].sensors.map(sensor => {
                return {...getSensorStructured(sensor), subgroupId: subgroup.subGroups[0].id} });
        }else{
            return [];
        }
    }


    // URL Functions
    goToURL = (url) => this.props.history.push(url);
    getFullURL = (ids) => `${baseURLDefault}${this.getIdsToURL(ids)}${this.getSearchURL()}`;
    getIdsJSONFromURL = (search) => {
        if (!search)
            search = this.props.location.search;
        const searchParams = new URLSearchParams(search);

        if (searchParams.has('groupId')) {
            const groupId = parseFloat(searchParams.get('groupId'))

            const foundGroup = this.state.groups.find(group =>
                group.id === groupId || (
                !!group.subGroups.some(subgroup => searchParams.has('sensorId')
                    ? subgroup.subGroups.some(({ id }) => id === groupId)
                    : subgroup.id === groupId
                )
            ))

            const foundSubgroup = foundGroup && foundGroup.subGroups.find(subgroup => searchParams.has('sensorId')
                ? subgroup.subGroups.some(({ id }) => id === groupId)
                : subgroup.id === groupId
            )

            return [{
                gid: foundGroup && foundGroup.id,
                sgid: foundSubgroup && foundSubgroup.id,
                sid: searchParams.has('sensorId') ? parseFloat(searchParams.get('sensorId')) : null,
                sph: searchParams.has('channel') ? parseFloat(searchParams.get('channel')) : null
            }]
        }

        return searchParams.get('ids') ? JSON.parse(adjustJSONToValidJSON(searchParams.get('ids'))) : [];
    }
    getIdsParsedToURL = (ids) => JSON.stringify(ids).replace(/"/g, '');
    getIdsToURL = (ids) => `?ids=${this.getIdsParsedToURL(ids)}`;

    getSearchURL = () => {
        let url = this.getDateURL();
        url = this.getPeriodFilterURL(false, url);
        if (!this.state.isLinear)
            url += '&step';
        if (this.state.showAlertEvents)
            url += '&alerts';
        return url;
    }

    getPeriodFilterURL = (fromSelect, url, period=this.state.periodFilter, date) => {
        if (fromSelect){
            const searchParams = new URLSearchParams(this.props.location.search);
            url = this.props.location.pathname + `?ids=${searchParams.get('ids')}`;
            url += this.getDateURL(period, date);
        }

        url.indexOf('period') >= 0 ? url = url.replace(/day|week|month|custom/, period) : url += `&period=${period}`;

        if (fromSelect && !this.state.isLinear)
            url += '&step';

        if (fromSelect && this.state.showAlertEvents)
            url += '&alerts';

        if (this.state.dataGranularity)
            url += `&dataGranularity=${this.state.dataGranularity}`

        return url;
    }

    getDateURL = (period=this.state.periodFilter, date) => {
        date = date ? date : period === 'custom' ? this.state.dateRangePicker : this.state.datePicker;

        if (period === 'custom'){
            let url = "";
            url += date.startDate ?
                `&startDate=${date.startDate.format('YYYY-MM-DD')}` : '';
            url += date.endDate ?
                `&endDate=${date.endDate.format('YYYY-MM-DD')}` : '';
            return url;
        }else{
            return date.startDate ?
                `&date=${date.startDate.format('YYYY-MM-DD')}` : '';
        }
    }

    onOutsideCompareClick = (e) => {
        this.onOutsideCompareClickTimer = setTimeout(() => {
            const groupsEle = document.getElementById('groups')
            if (!groupsEle) {
                return
            }

            const rootElem = groupsEle.children[this.compareIndexOpen];
            const isDescendantOfRoot = rootElem.contains(e.target);
            if (!isDescendantOfRoot) {
                let ids = this.getIdsJSONFromURL(window.location.search);
                this.willLoadData = false;
                document.body.removeEventListener('click', this.onOutsideCompareClick);
                ids = ids.map((id) => {delete(id.open); return id;});
                this.goToURL(this.getFullURL(ids));
            }
        }, 50);
    }

    // Close (the Collapse element) all compares, except the index, if you pass one
    closeCompares = (index, toClose) => {
        let ids = this.getIdsJSONFromURL(window.location.search);
        return ids.map((id, i) => {
            if (i === index && !id.open && !toClose) {
                document.body.addEventListener('click', this.onOutsideCompareClick);
                this.compareIndexOpen = index;
                id.open = true;
            } else delete(id.open);
            return id;
        });
    }

    toggleCompareCollapse = (index, toClose) => {
        clearTimeout(this.onOutsideCompareClickTimer);

        this.willLoadData = false;
        this.compareIndexOpen = undefined;
        document.body.removeEventListener('click', this.onOutsideCompareClick);

        let ids = this.closeCompares(index, toClose);
        this.goToURL(this.getFullURL(ids));
    }

    addCompare = () => {
        clearTimeout(this.onOutsideCompareClickTimer);

        if (this.state.groups && this.state.groups.length) {
            let ids = this.closeCompares();
            ids.push({ gid: this.state.groups[0].id });
            this.goToURL(this.getFullURL(ids));
        }
    }

    removeCompare = (index) => {
        clearTimeout(this.onOutsideCompareClickTimer);
        let ids = this.getIdsJSONFromURL(window.location.search);
        ids.splice(index, 1);
        this.goToURL(this.getFullURL(ids));
    }

    getBreadcrumbsTrail = () => {
        const filterGroup = this.state.filterGroup;
        const filterSubgroup = this.state.filterSubgroup;
        const filterSensor = this.state.filterSensor;

        let trail = (filterGroup && filterGroup.name) ? '► ' + filterGroup.name : null;
        if (filterSubgroup && filterSubgroup.name) trail += ' / ' + filterSubgroup.name;
        if (filterSensor &&  filterSensor.label) trail += ' / ' + filterSensor.label;

        return trail;
    }

    getBreadcrumbsBySelected = (selected) => {
        const {filterGroup, filterSubgroup, filterSensor, filterTag} = selected;

        let trail = (filterGroup && filterGroup.name) ? filterGroup.name : '';
        if (filterTag && filterTag.name) trail += `# ${filterTag.name}`;
        if (filterSubgroup && filterSubgroup.name) trail += ' / ' + filterSubgroup.name;
        if (filterSensor &&  filterSensor.label) trail += ' / ' + filterSensor.label;

        return trail;
    }

    createDatetime = (data) => data.map((d) => ({
        ...d,
        time: this.state.periodFilter === 'year' ? moment(d.month, 'YYYYMM') : moment(this.state.periodFilter !== 'month' ? d.dayTz + "T" + d.hour : d.dayTz + "T00:00:00").toDate(),
    }))

    createSensorPhase = (data) => data.map((d) => {
        d.name = this.getSensorPhaseName(d.sensorId, d.channel);
        return {
            ...d,
            ...getSensorStructured(d),
        }
    })

    /* Creates chart data for groups and subgroups */
    createGroupData = (groupData) => {
        let groupEntries = [];

        // Creates group data for the chart to draw
        if (groupData.data && groupData.data.length >= 1){
            const groupPre = this.createDatetime(groupData.data).sort((a, b) => moment(a.time).diff(moment(b.time)));
            let group = groupPre.map((d) => {
                return {
                    ...d,
                    id: groupData.groupId+'',
                }
            });
            groupEntries = nest().key(function(d) { return d.id; }).entries(group);
        }

        return groupEntries;
    }

    /* Creates chart data for tags */
    createTagData = (tagData) => {
        let tagEntries = [];

        // Creates tag data for the chart to draw
        if (tagData.data && tagData.data.length >= 1){
            const tagPre = this.createDatetime(tagData.data).sort((a, b) => moment(a.time).diff(moment(b.time)));
            let tag = tagPre.map((d) => {
                return {
                    ...d,
                    id: tagData.labelId+'',
                }
            });
            tagEntries = nest().key(function(d) { return d.id; }).entries(tag);
        }

        return tagEntries;
    }

    /* Creates chart data for sensors-phases */
    createSensorData = (sensorData) => {
        if (sensorData.data && sensorData.data.length) {
            let sensorsDataPre = this.createDatetime(sensorData.data).sort((a, b) => moment(a.time).diff(moment(b.time)));
            sensorsDataPre = this.createSensorPhase(sensorsDataPre); // creates sensor-phase, e.g.: 3031-1, 3031-2, 3031-3
            const entries = nest().key(function(d) { return d.label; }).entries(sensorsDataPre);
            return entries;
        }
        return [];
    }

    sumPositiveValues = (data) => data.reduce((prev, curr, index) => ([
        ...prev,
        {
            ...curr,
            defined: true,
            consumption: prev[index - 1] && prev[index - 1].consumption !== undefined
                ? prev[index - 1].consumption + (curr.consumption > 0 ? curr.consumption : 0)
                : curr.consumption > 0
                    ? curr.consumption
                    : 0
        }
    ]), [])

    // Ensure we have just positive values for the chart
    ensurePositiveValues = (data) => data.map((d) => {
        if (d.consumption < 0) {
            d.consumption = 0;
            d.defined = false;
        } else d.defined = true;
        return d;
    })


    changePeriodFilter = (period) => {
        let url = this.getPeriodFilterURL(true, '', period ? period.value : 'day');
        this.props.history.push(url);
    }

    goToPreviousPeriod = () => {
        let {datePicker} = this.state;
        datePicker.startDate.subtract(1, this.state.periodFilter);
        this.goToURL(this.getFullURL(this.getIdsJSONFromURL()));
    }

    goToNextPeriod = () => {
        let {datePicker} = this.state;
        datePicker.startDate.add(1, this.state.periodFilter);
        this.goToURL(this.getFullURL(this.getIdsJSONFromURL()));
    }

    goToCurrentDay = () => {
        const url = this.getPeriodFilterURL(true, '', this.state.periodFilter, {startDate: moment()});
        this.goToURL(url);
    }

    toggleLinear = () => {
        let searchParams = new URLSearchParams(window.location.search);
        let url = this.getFullURL(this.getIdsJSONFromURL());
        url = searchParams.has('step') ? url.replace('&step', '') : `${url}&step`;
        this.willLoadData = false;
        this.goToURL(url);
    }

    toggleShowAlertEvents = () => {
        let searchParams = new URLSearchParams(window.location.search);
        let url = this.getFullURL(this.getIdsJSONFromURL());
        url = searchParams.has('alerts') ? url.replace('&alerts', '') : `${url}&alerts`;
        this.willLoadData = false;
        this.goToURL(url);
    }

    handleLoadError = (state, message, error) => {
        state.loadErrorMessage = this.loadErrorMessage;
        this.setState(state);

        console.error(this.loadErrorMessage + " (" + message + ")");
        if (error)
            console.error(error);
    }


    // Date period select functions
    updateDateRangePicker = (dateRangePicker, removeTooltip) => {
        this.setState({dateRangePicker});
        if (removeTooltip)
            d3.selectAll('.nvtooltip').remove();
    }

    updateDatePicker = (datePicker, removeTooltip) => {
        this.setState({datePicker});
        if (removeTooltip)
            d3.selectAll('.nvtooltip').remove();
    }
    // /Date period select functions

    resetLines = (withDelay) => {
        this.resetEstimatesLines(withDelay);
        this.resetAlertEventsLines(withDelay);
    }

    resetEstimatesLines = (withDelay) => {
        if (withDelay){
            // 1 ms timeout needed to get correctly the current data disabled state on addEstimatesLines
            setTimeout(() => {
                this.removeEstimatesLines();
                this.addEstimatesLines();
            }, 1);
        }else{
            this.removeEstimatesLines();
            this.addEstimatesLines();
        }
    }

    resetAlertEventsLines = (withDelay) => {
        if (withDelay){
            // 1 ms timeout needed to get correctly the current data disabled state on addAlertEvents
            setTimeout(() => {
                this.removeAlertEvents();
                this.addAlertEvents();
            }, 1);
        }else{
            this.removeAlertEvents();
            this.addAlertEvents();
        }
    }

    removeEstimatesLines = () => {
        d3.selectAll('.nv-chart svg .estimate-line').remove();
    }

    addEstimatesLines = () => {
        const renderedInnerChartG = d3.select(".nv-chart svg g.nv-background");
        if (renderedInnerChartG && renderedInnerChartG.node()){
            const existingLines = d3.select(".nv-chart svg g g .estimate-line")[0][0];

            // no estimate lines were added yet
            if (existingLines === null){
                const chartDimensions = renderedInnerChartG.node().getBBox();
                const xScale = scaleTime().domain(this.getXDomain()).range([chartDimensions.x, chartDimensions.width]);
                const yScale = scaleLinear().domain(this.getYDomain()).range([chartDimensions.height, 0]);

                const line = d3.svg.line()
                    .interpolate(this.state.isLinear ? 'linear' : 'step-before')
                    .x(function(d) { return xScale(d.time); })
                    .y(function(d) { return yScale(d.consumption); });

                //get estimated ranges separately, then it's possible to get n paths for the same group/sensor
                const estimatedData = [];
                let estimatedIndex = 0;
                this.state.data.forEach( (d) => {
                    if (!d.disabled){
                        for (let i = 0; i < d.values.length; i++){
                            //estimated data range start, initialize
                            if  (isEstimatedData(d.values[i]) && ((d.values[i-1] && !isEstimatedData(d.values[i-1])) || !d.values[i-1]))
                                estimatedData[estimatedIndex] = [];

                            if  (isEstimatedData(d.values[i]))
                                estimatedData[estimatedIndex].push(d.values[i]);

                            //estimated data range end, increments index for next range
                            if  (isEstimatedData(d.values[i]) && d.values[i+1] && !isEstimatedData(d.values[i+1]))
                                estimatedIndex++;
                        }
                    }

                    //increments index for next group/sensor
                    if (estimatedData.length)
                        estimatedIndex = estimatedData.length;
                });

                const g = d3.select(".nv-chart svg .nv-groups");

                g.selectAll('path.estimate-line')
                    .data(estimatedData)
                    .enter()
                    .append('path')
                    .attr("stroke", "#CFCFCF")
                    .attr("fill-opacity", 0)
                    .attr("stroke-width", 2.5)
                    .classed('estimate-line', true)
                    .attr('d', line);
            }
        }
    }

    addWeekEndsHighlight = () => {
        const renderedInnerChartG = d3.select(".nv-chart svg g.nv-background");

        // the chart was already created'
        if (renderedInnerChartG && renderedInnerChartG.node()){
            const existingHighlights = d3.select(".nv-chart svg g g .weekend-highlight")[0][0];

            // no highlight rects were added yet
            if (existingHighlights === null){
                let weekEndData = [];
                const chartDimensions = renderedInnerChartG.node().getBBox();
                const xScale = scaleTime().domain(this.getXDomain()).range([0, chartDimensions.width]);

                if (this.state.periodFilter === 'week'){
                    let sunday = {};
                    let left = xScale(moment(this.state.datePicker.startDate.clone().startOf('week').valueOf()).toDate());
                    let right = xScale(moment(this.state.datePicker.startDate.clone().startOf('week').add(1, 'days').valueOf()).toDate());
                    let width = right - left;
                    sunday.xStart = left;
                    sunday.width = width;

                    let saturday = {};
                    let left2 = xScale(moment(this.state.datePicker.startDate.clone().endOf('week').subtract(1, 'days').valueOf()).toDate());
                    let right2 = xScale(moment(this.state.datePicker.startDate.clone().endOf('week').valueOf()).toDate());
                    let width2 = right2 - left2;
                    saturday.xStart = left2;
                    saturday.width = width2;

                    weekEndData.push(sunday);
                    weekEndData.push(saturday);
                }

                if (this.state.periodFilter === 'month'){
                    const lastMonthDay = this.state.datePicker.startDate.clone().endOf('month');
                    let currentDay = this.state.datePicker.startDate.clone().startOf('month');

                    const weekEndDays = [];
                    while (currentDay.isSameOrBefore(lastMonthDay, 'day')){
                        // if (currentDay.day() === 6)
                        if (currentDay.day() === 0 || currentDay.day() === 6)
                            weekEndDays.push(currentDay.clone());
                        currentDay.add(1, 'days');
                    }

                    weekEndDays.forEach(weekEndDay => {
                        let weekEndDayData = {};
                        //if the first day of month, doesn't apply the 12 hours left margin
                        const start = (weekEndDay.format('DD') === "01") ? weekEndDay.clone().startOf('day') : weekEndDay.clone().startOf('day');
                        let left = xScale(moment(start.valueOf()).toDate());
                        let right = xScale(moment(weekEndDay.clone().startOf('day').add(1, 'days').valueOf()).toDate());
                        let width = right - left;
                        weekEndDayData.xStart = left;
                        weekEndDayData.width = width;
                        weekEndData.push(weekEndDayData);
                    });
                }

                if (this.state.periodFilter === 'custom'){
                    const firstPeriodDay = this.state.dateRangePicker.startDate.clone().startOf('day');
                    const lastPeriodDay = this.state.dateRangePicker.endDate.clone().endOf('day');
                    let currentDay = this.state.dateRangePicker.startDate.clone().startOf('day');

                    const weekEndDays = [];
                    while (currentDay.isSameOrAfter(firstPeriodDay, 'day') && currentDay.isSameOrBefore(lastPeriodDay, 'day')){
                        if (currentDay.day() === 0 || currentDay.day() === 6)
                            weekEndDays.push(currentDay.clone());
                        currentDay.add(1, 'days');
                    }

                    weekEndDays.forEach((weekEndDay, i) => {
                        let weekEndDayData = {};
                        let left, right;

                        //for periods greater than 31 days, uses month highlight logic (one day granularity)
                        if (this.state.diffOfSelectedDays > 30){//diff of 30 === 31 days selected
                            //if the first day of period is weekend day, doesn't apply the 12 hours left margin
                            const start = (i === 0 && firstPeriodDay.isSame(weekEndDay)) ? weekEndDay.clone().startOf('day') : weekEndDay.clone().startOf('day');
                            left = xScale(moment(start.valueOf()).toDate());
                            right = xScale(moment(weekEndDay.clone().startOf('day').add(1, 'day').valueOf()).toDate());
                        } else {
                            left = xScale(moment(weekEndDay.clone().startOf('day').valueOf()).toDate());
                            right = xScale(moment(weekEndDay.clone().endOf('day').valueOf()).toDate());
                        }

                        let width = right - left;
                        weekEndDayData.xStart = left;
                        weekEndDayData.width = width;
                        weekEndData.push(weekEndDayData);
                    });
                }

                if (weekEndData.length > 0){
                    const group = d3.select(".nv-chart svg g g");

                    group.selectAll("rect.weekend-highlight")
                        .data(weekEndData)
                        .enter()
                        .append("rect")
                        .classed("weekend-highlight", true)
                        .attr("x", (d) => d.xStart)
                        .attr("y", 0)
                        .attr("width", (d) => d.width )
                        .attr("height", chartDimensions.height )
                        .attr("fill", "#d8d8d8")
                        .attr("opacity", 0.2);
                }
            }
        }
    }

    addAfterWorkHighlight = () => {
        const renderedInnerChartG = d3.select(".nv-chart svg g.nv-background");

        // the chart was already created'
        if (renderedInnerChartG && renderedInnerChartG.node()){
            const existingHighlights = d3.select(".nv-chart svg g g .after-work-highlight")[0][0];

            // no highlight rects were added yet
            if (existingHighlights === null){
                let afterWork = [];
                const chartDimensions = renderedInnerChartG.node().getBBox();
                const xScale = scaleTime().domain(this.getXDomain()).range([0, chartDimensions.width]);
                let currentDay = this.state.datePicker.startDate.clone();

                if (this.state.periodFilter === 'day' && currentDay.day() !== 0 && currentDay.day() !== 6){
                    let morning = {};
                    let left = xScale(moment(this.state.datePicker.startDate.clone().startOf('day').valueOf()).toDate());
                    let right = xScale(moment(this.state.datePicker.startDate.clone().startOf('day').add(8, 'hours').valueOf()).toDate());
                    let width = right - left;
                    morning.xStart = left;
                    morning.width = width;

                    let evening = {};
                    let left2 = xScale(moment(this.state.datePicker.startDate.clone().endOf('day').subtract(6, 'hours').valueOf()).toDate());
                    let right2 = xScale(moment(this.state.datePicker.startDate.clone().endOf('day').valueOf()).toDate());
                    let width2 = right2 - left2;
                    evening.xStart = left2;
                    evening.width = width2;

                    afterWork.push(morning);
                    afterWork.push(evening);
                }

                if (afterWork.length > 0){
                    const group = d3.select(".nv-chart svg g g");

                    group.selectAll("rect.after-work-highlight")
                        .data(afterWork)
                        .enter()
                        .append("rect")
                        .classed("after-work-highlight", true)
                        .attr("x", (d) => d.xStart)
                        .attr("y", 0)
                        .attr("width", (d) => d.width )
                        .attr("height", chartDimensions.height )
                        .attr("fill", "#d8d8d8")
                        .attr("opacity", 0.2);
                }
            }
        }
    }

    removeAlertEvents = () => {
        d3.selectAll('.nv-chart svg .alert-event-line').remove();
    }

    addAlertEvents = () => {
        if (this.state.showAlertEvents){
            const renderedInnerChartG = d3.select(".nv-chart svg g.nv-background");

            // the chart was already created'
            if (renderedInnerChartG && renderedInnerChartG.node()){
                const existingEvents = d3.select(".nv-chart svg g g .alert-event-line")[0][0];

                // no alert event lines were added yet
                if (existingEvents === null){
                    const chartDimensions = renderedInnerChartG.node().getBBox();
                    const xScale = scaleTime().domain(this.getXDomain()).range([0, chartDimensions.width]);
                    const {data, events} = this.state;

                    let filteredEvents = [];
                    if (data && data.length && events && events.length ){
                        filteredEvents = events.filter(event => {
                            const found = data.find(datum => datum.key === event.key);
                            return (found) ? !found.disabled : false;
                        });
                        filteredEvents = filteredEvents.map(event => ({ ...event, timestamp: this.getRoundedTimestamp(event.timestamp) }));
                    }

                    if (filteredEvents.length > 0){
                        const group = d3.select(".nv-chart svg g g");
                        group.selectAll("path.alert-event-line")
                            .data(filteredEvents)
                            .enter()
                            .append("line")
                            .classed("alert-event-line", true)
                            .attr("x1", (d) => xScale(d.timestamp))
                            .attr("y1", 0)
                            .attr("x2", (d) => xScale(d.timestamp))
                            .attr("y2", chartDimensions.height)
                            .style("stroke-width", 2)
                            .style("stroke", "red")
                            .style("stroke-dasharray", ("10, 4"));
                    }
                }
            }
        }
    }

    getGranularity = () => {
        const { periodFilter, dateRangePicker: { startDate, endDate }, dataGranularity } = this.state
        const diffDays = endDate.endOf('day').diff(startDate.startOf('day'), 'days', true)

        if (periodFilter === 'day') {
            const found = this.dayGranularities.find(granularity => granularity.value === dataGranularity)
            return found ? found.label : i18n.t('5 Min')
        }

        if (periodFilter === 'custom' && diffDays <= 2) {
            return i18n.t('5 Min')
        }

        if (periodFilter === 'week' || (periodFilter === 'custom' && diffDays <= 31)) {
            return i18n.t('1 Hour')
        }

        if (periodFilter === 'year' || (periodFilter === 'custom' && diffDays >= daysToMonthGranularity)) {
            return i18n.t('1 Month')
        }

        return i18n.t('1 Day')
    }

    changeGranularity = granularity => {
        const { dataGranularity } = this.state;
        let searchParams = new URLSearchParams(window.location.search);
        let search = window.location.search;

        if (granularity && granularity.value){
            if (!searchParams.has('dataGranularity')) {
                search = search + `&dataGranularity=${granularity.value}`;
            } else {
                search = search.replace(`&dataGranularity=${dataGranularity}`, `&dataGranularity=${granularity.value}`);
            }
        }

        this.props.history.push(baseURLDefault + search);
    }

    detailsDateFormatter = day => {
        const { dateRangePicker: { startDate, endDate }} = this.state;
        const diffDays = endDate.endOf('day').diff(startDate.startOf('day'), 'days', true);

        if (diffDays <= 31) {
            const rangeStart = moment(day).format('LT');
            let rangeEnd;

            if (diffDays <= 2) {
                rangeEnd = moment(day).add(5, 'minute').format('LT');
            }else{
                rangeEnd = moment(day).add(1, 'hour').format('LT');
            }

            return moment(day).format('LL') + ' ' + rangeStart + ' - ' + rangeEnd;
        }

        return moment(day).format('LL');
    }

    getMinMaxTimeRange = (obj, variable) => {
        if (variable !== 'min' && variable !== 'max'){
            console.warn('Invalid variable informed. No min/max time range will be displayed.');
            return '-';
        }

        if (obj[variable] && obj[variable].hour){
            const granularity = this.dayGranularities.find(granularity => granularity.value === this.state.dataGranularity)
            const rangeStart = moment(obj[variable].hour, 'HH:mm').format('LT');
            const rangeEnd = moment(obj[variable].hour, 'HH:mm').add(granularity.minutes, 'minute').format('LT');
            return rangeStart + ' - ' + rangeEnd;
        }else if (obj[variable] && obj[variable].dayAndHour){
            return this.detailsDateFormatter(obj[variable].dayAndHour);
        }else if (obj[variable] && obj[variable].day){
            return moment(obj[variable].day, 'YYYY-MM-DD').format('LL');
        }else if (obj[variable] && obj[variable].month){
            return moment(obj[variable].month, 'YYYYMM').format('[LMMMMYYYY]');
        }else{
            return '-'
        }
    }

    isButtonNowDisabled = () => moment().isSame(this.state.datePicker.startDate, this.state.periodFilter)

    openCalendar = () => {
        let {datePicker, dateRangePicker} = this.state;
        this.state.periodFilter === 'custom' ? dateRangePicker.open = true : datePicker.open = true;
        this.setState({datePicker: datePicker, dateRangePicker: dateRangePicker});
    }

    getRoundedTimestamp = (timestamp) =>{
        const { periodFilter, dateRangePicker: { startDate, endDate }, dataGranularity } = this.state;
        const diffDays = endDate.endOf('day').diff(startDate.startOf('day'), 'days', true);
        let roundedTimestamp = null;

        if (periodFilter === 'day') {
            const found = this.dayGranularities.find(granularity => granularity.value === dataGranularity);
            if (found.value === 'ONE_HOUR'){
                roundedTimestamp = moment(timestamp).startOf('hour');
            }else{
                roundedTimestamp = moment(timestamp).minutes(found.minutes).seconds(0).milliseconds(0);
            }
        }else if (periodFilter === 'custom' && diffDays <= 2) {
            roundedTimestamp = moment(timestamp).minutes(5).seconds(0).milliseconds(0);
        } else if (periodFilter === 'week' || (periodFilter === 'custom' && diffDays <= 31)) {
            roundedTimestamp = moment(timestamp).startOf('hour');
        }else{
            roundedTimestamp = moment(timestamp).startOf('day');
        }
        return roundedTimestamp;
    }

    getEventNames(events){
        return events.reduce((acc, event) => acc.concat(event.values.map(v => v.name)), []);
    }

    createTooltip = data => {
        if (!data) {
            return ''
        }

        const {events, showAlertEvents} = this.state;
        let foundEvents;
        if (showAlertEvents){
            foundEvents = events && events.length && events.filter(({ timestamp }) => {
                if (!timestamp)
                    return false;

                return data.series.some(serie => this.getRoundedTimestamp(timestamp).isSame(moment(serie.data.time)));
            });
            foundEvents = nest().key(function(d) { return d.key; }).entries(foundEvents);
        }

        return `
            <div id="custom-tooltip">
                ${showAlertEvents && !!foundEvents && foundEvents.length ? `
                    <div class="alert-title">
                        ${this.getEventNames(foundEvents).map((event, i) => `<div key=${i}>${i18n.t('Check alert')}: ${event}</div>`).join('')}
                    </div>
                ` : ''}
                <div class="tooltip-header">
                    ${this.tooltipHeaderFormatter(data.value)}
                </div>
                <div class="tooltip-content">
                    ${data.series && data.series.reduce((prev, serie, index) => prev += `
                        <div class="tooltip-serie">
                            <div class="serie-info ${showAlertEvents && foundEvents && foundEvents.length ? 'with-alert' : ''}">
                                ${showAlertEvents && foundEvents && foundEvents.length && foundEvents.some(event => event.key === serie.key) ? `
                                    <div class="serie-alert"></div>
                                ` : ''}
                                <div class="serie-color" style="background-color: ${serie.color}"></div>
                                <div class="name">
                                    ${this.getGroupName(serie.key) || this.getTagName(serie.key) || serie.key}
                                </div>
                            </div>
                            <div class="serie-value">
                                ${serie.data && serie.data.aggregationType === 'ESTIMATED'
                                    ? `${i18n.t('(estimated)')} ${kwhFormat(serie.value)}`
                                    : serie.data && serie.data.aggregationType === 'HYBRID'
                                        ? `${i18n.t('(partially estimated)')} ${kwhFormat(serie.value)}`
                                        : kwhFormat(serie.value)
                                }
                            </div>
                        </div>
                    `, '')}
                </div>
            </div>
        `
    }

    render () {
        const { compare, periodFilter, loadingData } = this.state
        const { classes } = this.props

        let xAxis = {
            tickFormat: this.xTickFormat,
            showMaxMin: false,
        };

        if (periodFilter === 'month' || periodFilter === 'day'){
            xAxis.rotateLabels = -50;
            xAxis.tickPadding = 0;
        }

        if (periodFilter === 'day')
            xAxis.ticks = 24;

        if (periodFilter === 'week' || periodFilter === 'month' || periodFilter === 'year')
            xAxis.tickValues = this.state.xTicks;

        if (periodFilter === 'custom'){
            if (this.state.diffOfSelectedDays < daysToMonthGranularity){
                xAxis.ticks = 8;
            }else{
                const periodStart = this.state.dateRangePicker.startDate.clone().startOf('month');
                const periodEnd = this.state.dateRangePicker.endDate.clone().endOf('month');
                const months = utcMonths(
                    periodStart,
                    periodEnd,
                );
                xAxis.ticks = months && months.length ? months.length : 4;
            }
        }

        const isButtonNowDisabled = moment().isSame(this.state.datePicker.startDate, this.state.periodFilter);

        return (
            <div id="dashboard">
                <div id="line-chart">
                    <div id="top-bar">
                        <div className={`top top-period-filter-${periodFilter}`}>
                            <div>
                                {this.periodOptions.map((period, key) => (
                                    <Button className={
                                        `btn-period-filter${periodFilter === period.value ? ' active' : ''}`}
                                        disabled={
                                            loadingData ||
                                            periodFilter === period.value ||
                                            (!this.state.loadingGroups && this.state.groups.length === 0)
                                        }
                                        key={key}
                                        onClick={() => this.changePeriodFilter(period)}>
                                        {period.label}
                                    </Button>
                                ))}
                            </div>

                            <div>
                                {periodFilter !== 'custom' ?
                                    <IconButton className="btn-previous btn-previous-date"
                                        disabled={loadingData}
                                        onClick={this.goToPreviousPeriod}>
                                        <ExpandMoreIcon />
                                    </IconButton> : null}

                                <DatePicker
                                    chartType={'line-chart'}
                                    datePicker={this.state.datePicker}
                                    dateRangePicker={this.state.dateRangePicker}
                                    updateDatePicker={this.updateDatePicker}
                                    updateDateRangePicker={this.updateDateRangePicker}
                                    periodFilter={periodFilter}
                                    dataGranularity={this.state.dataGranularity}
                                    isLinear={this.state.isLinear}
                                    showAlertEvents={this.state.showAlertEvents}
                                    loadingGroups={this.state.loadingGroups}
                                    history={this.props.history}
                                    disabled={!this.state.loadingGroups && this.state.groups.length === 0}
                                    showLabel={false} />

                                {periodFilter !== 'custom' ?
                                    <IconButton className="btn-next btn-next-date"
                                        disabled={
                                            loadingData ||
                                            this.state.datePicker.startDate.isSameOrAfter(
                                                moment().startOf(periodFilter))
                                        }
                                        onClick={this.goToNextPeriod}>
                                        <ExpandMoreIcon />
                                    </IconButton> : null}

                                {periodFilter !== 'custom' && periodFilter !== 'year' ?
                                    <Button className={`btn-period${isButtonNowDisabled ? ' active' : ''}`}
                                        disabled={
                                            loadingData ||
                                            isButtonNowDisabled ||
                                            (!this.state.loadingGroups && this.state.groups.length === 0)
                                        }
                                        onClick={() => this.goToCurrentDay()}>
                                        {i18n.t('NOW')}
                                    </Button> : null}
                            </div>

                            <div className="granularity">
                                {periodFilter === 'day' && (
                                    <div className="granularity-group">
                                        {this.dayGranularities.map(granularity => (
                                            <Button
                                                className={
                                                    `btn-period-filter ${this.state.dataGranularity === granularity.value ? 'active' : ''}`}
                                                disabled={
                                                    loadingData ||
                                                    (!this.state.loadingGroups && this.state.groups.length === 0)
                                                }
                                                key={granularity.value}
                                                onClick={() => this.changeGranularity(granularity)}
                                            >
                                                {granularity.label}
                                            </Button>
                                        ))}
                                    </div>
                                )}
                                {periodFilter !== 'day' && (
                                    <div>
                                        <Text text={this.getGranularity()} />
                                    </div>
                                )}
                            </div>
                        </div>

                        {!this.state.loadingGroups && this.state.groups.length > 0 && (
                            <div className="bottom">
                                <GroupCompare
                                    baseURLDefault={baseURLDefault}
                                    history={this.props.history}
                                    periodFilter={this.state.periodFilter}
                                    addCompare={this.addCompare}
                                    isLinear={this.state.isLinear}
                                    showAlertEvents={this.state.showAlertEvents}
                                    dateRangePicker={this.state.dateRangePicker}
                                    datePicker={this.state.datePicker}
                                    compare={compare.map((comp, index) => ({
                                        ...comp,
                                        color: this.getColor(index)
                                    }))}
                                    filterGroupOptions={this.state.filterGroupOptions}
                                    removeCompare={this.removeCompare}
                                    toggleCompareCollapse={this.toggleCompareCollapse}
                                    tags={this.state.tags}
                                />
                            </div>
                        )}
                    </div>
                    {(!this.state.loadingGroups &&
                    !this.state.loadingData &&
                    !this.state.data.length) ? (
                        <div className="nv-chart no-chart-msg">
                            <p className="no-data">{this.noDataChartMessage}</p>
                        </div>
                    ) : (
                        <div id="graph">
                            <ChartLoading loading={this.state.loadingData} />
                            <div className="switch interpolation">
                                <p>{i18n.t('Graph interpolation')}</p>
                                <span className={`label ${this.state.isLinear ? 'active' : ''}`}>
                                    {i18n.t('Linear')}
                                </span>
                                <Switch
                                    checked={!this.state.isLinear}
                                    onChange={this.toggleLinear}
                                    value="linear"
                                    classes={{
                                        switchBase: classes.switchBase,
                                        checked: classes.checked,
                                        bar: classes.bar,
                                    }}
                                />
                                <span className={`label ${!this.state.isLinear ? 'active' : ''}`}>
                                    {i18n.t('Step')}
                                </span>
                            </div>
                            {!this.isCumulative && this.state.periodFilter !== 'year' && !(periodFilter === 'custom' && this.state.diffOfSelectedDays >= daysToMonthGranularity) && (
                                <div className={`switch show-alerts ${this.state.loadingEvents || !this.state.events.length ? 'disabled' : ''}`}>
                                    <p>{i18n.t('Show alerts')}</p>
                                    <span className={`label ${!this.state.showAlertEvents ? 'active' : ''}`}>
                                        {i18n.t('Off')}
                                    </span>
                                    <Switch
                                        checked={(this.state.loadingEvents || !this.state.events.length) ? false : this.state.showAlertEvents}
                                        onChange={this.toggleShowAlertEvents}
                                        disabled={this.state.loadingEvents || !this.state.events.length}
                                        value="showAlerts"
                                        classes={{
                                            switchBase: classes.switchBaseOnOff,
                                            checked: classes.checked,
                                            bar: classes.bar,
                                            disabled: classes.disabled,
                                        }}
                                    />
                                    <span className={`label ${this.state.showAlertEvents ? 'active' : ''}`}>
                                        {i18n.t('On')}
                                    </span>
                                </div>
                            )}
                            <NVD3Chart id="line-chart"
                                type="lineChart"
                                datum={this.state.data}
                                x="time"
                                y="consumption"
                                options={{
                                    color: colors,
                                    interpolate: this.state.isLinear ? 'linear' : 'step-before',
                                    forceX: this.state.xDomain,
                                    forceY: this.getYDomain(),
                                    interactiveLayer: {
                                        tooltip: {
                                            contentGenerator: this.createTooltip
                                        },
                                    },
                                    noData: this.state.noDataChartMsg,
                                    useInteractiveGuideline: true,
                                    xAxis: xAxis,
                                    xScale: scaleTime(),
                                    defined: (d) => d.defined,
                                    yAxis: { axisLabel: i18n.t('kWh'), tickFormat: yTickFormat},
                                    legend: {keyFormatter: (key) => this.getGroupName(key) || this.getTagName(key) || key}, //if no group name, it's a sensor, use the key itself
                                }}
                                legend={{
                                    legendClick: (chartState) => this.resetLines(true),
                                    legendDblclick: (chartState) => this.resetLines(true),
                                }}
                            />
                        </div>
                        )
                    }

                    <div className="tables">
                        <Table className="chart-table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{i18n.t('Group / Subgroup / Sensor')}</TableCell>
                                    <TableCell>{i18n.t('Total Cost')}</TableCell>
                                    <TableCell>{i18n.t('Total Consumption')}</TableCell>
                                    <TableCell>{this.periodsText[periodFilter]}</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {this.state.compare.map((obj, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <span className="compare-colored-circle"
                                                style={{background: this.getColor(index)}} />
                                            {this.getBreadcrumbsBySelected(obj)}
                                        </TableCell>
                                        <TableCell>
                                            {this.costFormat(this.state.currency, obj.totalCost)}
                                        </TableCell>
                                        <TableCell>
                                            {obj.total && obj.total >= 0 ? `${kwhFormat(obj.total)} ${i18n.t('kWh')}` : '-'}
                                        </TableCell>
                                        <TableCell />
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        {!this.isCumulative && (
                            <Table className="details-table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{i18n.t('Max')}</TableCell>
                                        <TableCell>{i18n.t('Min')}</TableCell>
                                        <TableCell>{i18n.t('Avg')}</TableCell>
                                        <TableCell>{this.getGranularity()}</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {this.state.compare.map((obj, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <div>
                                                    {obj.max && obj.max.consumption !== null && obj.max.consumption >= 0
                                                        ? `${kwhFormat(obj.max.consumption)} ${i18n.t('kWh')}`
                                                        : '-'
                                                    }
                                                    <small>{this.getMinMaxTimeRange(obj, 'max')}</small>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div>
                                                    {obj.min && obj.min.consumption !== null && obj.min.consumption >= 0
                                                        ? `${kwhFormat(obj.min.consumption)} ${i18n.t('kWh')}`
                                                        : '-'
                                                    }
                                                    <small>{this.getMinMaxTimeRange(obj, 'min')}</small>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                {obj.average && obj.average >= 0 ? `${kwhFormat(obj.average)} ${i18n.t('kWh')}` : '-'}
                                            </TableCell>
                                            <TableCell />
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        )}
                    </div>
                </div>
                <SystemSnackbar
                    open={!this.state.loadingGroups && this.state.groups.length === 0}
                    message={i18n.t('No group setup')}
                    variant='warning' />
            </div>
        )
    }
}

ConsumptionLineChart.propTypes = {
    intl: intlShape.isRequired,
};

export default withStyles(styles)(injectIntl(ConsumptionLineChart));
