import React from 'react';
import d3 from 'd3';
import NVD3Chart from 'react-nvd3';
import moment from 'moment-timezone';
import { injectIntl, intlShape } from 'react-intl'

import {API} from '../../../components/api';
import {getSensorStructured} from '../../../components/utils';
import Select from 'react-select';

// Translation
import i18n from '../../../i18n';

import Button from 'material-ui/Button';
import {nest} from 'd3-collection'
import {utcDays} from 'd3-time'
import {format} from 'd3-format'
import {scaleTime} from 'd3-scale'
import DatePicker from '../filter-date-picker'
import ChartLoading from '../chart-loading'
import SystemSnackbar from '../../../elements/material-ui/SystemSnackbar';
import Table, {TableBody, TableCell, TableHead, TableRow} from 'material-ui/Table';

import IconButton from 'material-ui/IconButton';
import ExpandMoreIcon from 'material-ui-icons/ArrowDropDown';

import 'nvd3/build/nv.d3.min.css'
import './power-chart.css'
import 'react-select/dist/react-select.css';

const kwhFormat = (d) => {
    if (d < 1){
        return format(".2r")(d);
    }else{
        return format(".2f")(d);
    }
}

const colors = ["#2ca02c", "#d62728"];

class PowerChart extends React.Component {
    constructor (props) {
        super(props);
        let today = moment();

        this.state = {
            loadingSensors: true,
            loadingData: false,
            periodFilter: 'day',
            noDataChartMsg: this.loadingChartMessage,
            dataLegends: {},
            xTicks: [],
            xDomain: [],
            data:[],
            activePower: {
                max: {
                    value: null,
                    time: null
                },
                min: {
                    value: null,
                    time: null
                },
                average: null
            },
            reativePower: {
                max: {
                    value: null,
                    time: null
                },
                min: {
                    value: null,
                    time: null
                },
                average: null
            },
            chartMaxConsumption: 0,
            datePicker: {
                open: false,
                startDate: today,
                data: {
                    endDate: today,
                    focusedInput: 'startDate',
                    startDate: today,
                },
            },
            screenWidth: 0,
            screenHeight: 0,
            hadScreenResize: false,
            tooltip: true,
            sensors: [],
        };

        this.maximumDate = today.clone().add(1, 'day').endOf('day');
    }

    componentWillMount = () => {
        API.SENSORS.GET().then(({data}) => {
            if (data && data.length > 0){
                this.setState({
                    sensors: this.getSensorsList(data),
                    loadingSensors: false,
                }, this.checkGeneralChangesOnComponent);
            }else if (data && data.length === 0){
                this.setState({
                    loadingSensors: false,
                    noDataChartMsg: this.noSensorChartMessage,
                });
            }else{
                this.handleLoadError({loadingSensors: false}, i18n.t("Could not load sensors data"))
            }
        }).catch((error) => {
            this.handleLoadError({loadingSensors: false}, i18n.t("Could not load sensors data"), error)
        });

        this.updateDimensions();
    }

    componentDidMount () {
        //TODO review this implementation. Not sure that this.state.tooltip is useful
        let elem = document.querySelector('#chart');
        if (elem)
            elem.addEventListener('click', this.clearTooltip);

        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillReceiveProps (nextProps) {
        this.checkGeneralChangesOnComponent(nextProps);
    }

    componentWillUpdate(nextProps, nextState){
        // screen was resized
        if (nextState.screenWidth !== this.state.screenWidth || nextState.screenHeight !== this.state.screenHeight){
            // remove existing weekend highlight to force redraw
            d3.selectAll('.nv-chart svg .weekend-highlight').remove();
        }
    }

    componentDidUpdate () {
        // fixes tooltip mark that stayed on the chart when changing data
        d3.selectAll('.nvtooltip').remove();

        if (this.state.periodFilter === 'week') {
            setTimeout(this.addWeekEndsHighlight, 1250);
        }

        // verification to force state update and then force the chart redraw of x axis, background lines, etc
        if (this.state.hadScreenResize){
            setTimeout(() => {
              this.setState({hadScreenResize: false});
            }, 1250); // after 1 second to execute it after chart transitions (animation lasts 250 ms)
        }
    }

    componentWillUnmount () {
        window.removeEventListener("resize", this.updateDimensions);
    }

    loadErrorMessage = i18n.t("An error occurred while retrieving data");
    loadingChartMessage = i18n.t("Loading data");
    noDataChartMessage = i18n.t("There is no data available for the selected criteria");
    noSensorChartMessage = i18n.t("There is no data available as no sensor has been set up");

    periodOptions = [
        {label: i18n.t('Day'), value: 'day'},
        {label: i18n.t('Week'), value: 'week'}
    ];

    getSensorsList = (sensors) => {
        let sensorsList = [];

        if (sensors && sensors.length){
            sensorsList = sensors.reduce((list, sensor) => {
                if (sensor.channels && sensor.channels.length){
                    const sensorPhases = sensor.channels.map(sensor => ({
                        ...sensor,
                        ...getSensorStructured(sensor),
                        label: this.getBreadcrumbsTrailFromHierarchy(sensor)
                    }));

                    list = [...list, ...sensorPhases];
                }
                return list;
            }, []);
        }

        return sensorsList;
    }

    getBreadcrumbsTrailFromHierarchy = (sensor) => {
        const errorMessage = i18n.t("Unknown group/sensor");
        let error = false;

        let breadcrumbsText = '';
        if (sensor) {
            if (sensor.parentGroupName)
                breadcrumbsText = sensor.parentGroupName;
            if (sensor.groupName)
                if (sensor.parentGroupName)
                    breadcrumbsText += '/'
                breadcrumbsText += sensor.groupName ? sensor.groupName : '';
            if (sensor.sensorId && sensor.channel){
                breadcrumbsText += (breadcrumbsText ? '/' : '') + sensor.sensorId + '-' + sensor.channel;
                if (sensor.sensorChannelName)
                    breadcrumbsText += ' - ' + sensor.sensorChannelName;
            }
            if (breadcrumbsText === '')
                error = true;
        } else {
            error = true;
        }

        if (error){
            breadcrumbsText = errorMessage;
            console.warn("A wrong breadcrumb format was received and no breadcrumb text could be created for Alerts Events list");
        }

        return breadcrumbsText;
    }

    // Based on https://stackoverflow.com/a/34475071
    updateDimensions = () => {
        let w = window,
        d = document,
        documentElement = d.documentElement,
        body = d.getElementsByTagName('body')[0],
        width = w.innerWidth || documentElement.clientWidth || body.clientWidth,
        height = w.innerHeight|| documentElement.clientHeight|| body.clientHeight;

        this.setState({
            screenWidth: width,
            screenHeight: height,
            hadScreenResize: true,
        });
    }

    xTickFormat = (d) => {
        const time = moment(d);
        const hourOfDay = time.format('HH');
        let format = 'hh A';

        if (hourOfDay === "00")
            format = 'MMM DD';

        return time.format(format);
    }

    tooltipHeaderFormatter = (strDate) => {
        return moment(strDate).format('dddd, MMMM Do YYYY');
    }

    getSensorSelectedById = (id, channel, state) => {
        let sensor = state.sensors.find(sensor => sensor.id === id && sensor.channel === channel);

        if (id && !sensor) {
            this.props.history.push(`/analysis/power/sensor/${state.sensors[0].id}/${state.sensors[0].channel}`);
            return;
        }

        return {
            filterSensor: sensor,
            noDataChartMsg: this.loadingChartMessage,
        };
    }

    checkGeneralChangesOnComponent = (nextProps) => {
        const props = nextProps || this.props;
        if (!props.match.params.sensorId && this.state.sensors && this.state.sensors.length) {
            this.props.history.push(`${this.props.history.location.pathname}/sensor/${this.state.sensors[0].id}/${this.state.sensors[0].channel}`);
            return;
        }

        let sensorData, startDate, endDate, URLToSearchParams;
        let state = {
            datePicker: this.state.datePicker,
            sensors: this.state.sensors
        };

        URLToSearchParams = props.location.search ?
            props.location.search : props.location.pathname.indexOf('?') >= 0 ?
                props.location.pathname.slice(props.location.pathname.indexOf('?')) :
                props.location.pathname;
        const searchParams = new URLSearchParams(URLToSearchParams);

        if (props.match.params.sensorId && props.match.params.channel) {
            sensorData = this.getSensorSelectedById(parseInt(props.match.params.sensorId, 0), parseInt(props.match.params.channel, 0), state);
            if (!sensorData) return;
            Object.assign(state, sensorData);
        }

        state.periodFilter = searchParams.get('period') ?
            searchParams.get('period') : props.location.pathname.indexOf('period=week') >= 0 ? 'week' : 'day';
        if (searchParams.get('date')) {
            startDate = moment(searchParams.get('date'));

            if (state.periodFilter === 'week') {
                startDate = startDate.startOf('week');
                endDate = startDate.clone().endOf('week');
                endDate = endDate.isAfter(this.maximumDate) ? this.maximumDate : endDate;
            } else endDate = startDate;

            state.datePicker.open = false;
            state.datePicker.startDate = startDate;
            state.datePicker.endDate = endDate;
            state.datePicker.data.startDate = startDate;
            state.datePicker.data.endDate = endDate;
        }

        this.setState(state);
        this.loadSensorChart(state);
    }

    getXDomain = () => {
        // this forces the x axis to go from 12AM to 12PM, when period is day (sunday to saturday, when week)
        // even if the data is partial for the period
        // NVD3 forceX function only understands it if we use valueOf() to get the numeric value for the date object
        let domain = [], date;

        switch (this.state.periodFilter) {
            case 'week':
                date = this.state.datePicker.startDate.clone().startOf('week');
                domain = [date.valueOf(), date.add(7, 'days').valueOf()];
                break;
            case 'day':
                date = this.state.datePicker.startDate.clone().startOf('day');
                domain = [date.valueOf(), date.add('1', 'days').valueOf()];
                break;
            default:
                // day domain
                date = this.state.datePicker.startDate.clone().startOf('day');
                domain = [date.valueOf(), date.add('1', 'days').valueOf()];
                console.warn("An invalid x domain format was found. Assuming day x domain.");
        }
        return domain;
    }

    getXTicks = () => {
        let xTicks = [];
        switch (this.state.periodFilter) {
            case 'week':
                let momentDate = this.state.datePicker.startDate.clone();
                xTicks = utcDays(
                    momentDate.startOf('week'),
                    momentDate.clone().add(7, 'days')
                ).map((value) => moment(value).valueOf());
                break;
            case 'day':
                xTicks = [];
                break;
            default:
                xTicks = [];
        }
        return  xTicks;
    }

    loadSensorChart = (state) => {
        d3.selectAll('.nvtooltip').style('opacity', '0');

        this.setState({ loadingData: true, noDataChartMsg: this.loadingChartMessage });

        const sensorId = state.filterSensor.id;
        const channelId = state.filterSensor.channel;
        const startDate = state.datePicker.startDate.format('YYYY-MM-DD');
        const endDate = state.datePicker.data.endDate.format('YYYY-MM-DD');
        const params = [sensorId, channelId, startDate, endDate];

        API.VISUALIZATIONS.POWER.LINE.SENSOR(...params).then((response) => {
            if (response.data) {
                d3.selectAll('.nvtooltip').style('opacity', '0');
                this.setState({
                    loadingData: false,
                    data: this.createSensorData(response.data),
                    noDataChartMsg: this.noDataChartMessage,
                    activePower: {
                        average: response.data.avgActivePower,
                        max: {
                            value: response.data.maxActivePower ? response.data.maxActivePower.activePower : null,
                            time: response.data.maxActivePower ? response.data.maxActivePower.dayAndHour : null
                        },
                        min: {
                            value: response.data.minActivePower ? response.data.minActivePower.activePower : null,
                            time: response.data.minActivePower ? response.data.minActivePower.dayAndHour : null
                        },
                    },
                    reativePower: {
                        average: response.data.avgReactivePower,
                        max: {
                            value: response.data.maxReactivePower ? response.data.maxReactivePower.reativePower : null,
                            time: response.data.maxReactivePower ? response.data.maxReactivePower.dayAndHour : null
                        },
                        min: {
                            value: response.data.minReactivePower ? response.data.minReactivePower.reativePower: null,
                            time: response.data.minReactivePower ? response.data.minReactivePower.dayAndHour: null
                        }
                    },
                    xDomain: this.getXDomain(),
                    xTicks: this.getXTicks(),
                });
            }else{
                this.handleLoadError({loadingData: false}, i18n.t("Could not load chart data"))
            }
        }).catch((error) => {
            this.handleLoadError({loadingData: false}, i18n.t("Could not load chart data"), error)
        });
    }

    getSearchURL = () => {
        return this.getPeriodFilterURL(false, this.getDateURL());
    }

    getPeriodFilterURL = (fromSelect, url, period=this.state.periodFilter) => {
        if (fromSelect){
            url = this.props.location.pathname + this.getDateURL(period) + `&period=${period}`;
        }
        if (url.indexOf('?') < 0) url += `?period=${period}`;
        else {
            url.indexOf('period') >= 0 ? url = url.replace(/day|week|month|custom/, period) : url += `&period=${period}`;
        }

        return url;
    }

    getDateURL = () => (this.state.datePicker.startDate
        ? `?date=${this.state.datePicker.startDate.format('YYYY-MM-DD')}`
        : ''
    )

    changeSensor = (selectedSensor) => {
        const url = `/analysis/power`;
        if (!selectedSensor) {
            return this.props.history.push(url + this.getSearchURL())
        }

        this.props.history.push(url + `/sensor/${selectedSensor.id}/${selectedSensor.channel}/${this.getSearchURL()}`);
    }

    createSensorPhase = (data) => data.map((d) => {
        d.name = this.state.filterSensor.label;
        return {
            ...d,
            ...getSensorStructured(d),
        }
    })

    createSensorData = (sensorData) => {
        if (sensorData.data && sensorData.data.length) {
            let sensorsDataPre = sensorData.data.sort((a, b) => moment(a.timestampTz).diff(moment(b.timestampTz)));
            sensorsDataPre = this.createSensorPhase(sensorsDataPre); // creates sensor-phase, e.g.: 3031-1, 3031-2, 3031-3
            const activePower = sensorsDataPre.map(d => {
                return {
                    ...d,
                    powerType: i18n.t("Active Power"),
                    power: d.activePower,
                    time: moment(d.timestampTz).toDate(),
                }
            });
            const reactivePower = sensorsDataPre.map(d => {
                return {
                    ...d,
                    powerType: i18n.t("Reactive Power"),
                    power: d.reactivePower,
                    time: moment(d.timestampTz).toDate(),
                }
            });
            const entries = nest().key(function(d) { return d.powerType; }).entries(activePower.concat(reactivePower));
            return entries;
        }
        return [];
    }


    changePeriodFilter = (period) => {
        let url = this.getPeriodFilterURL(true, '', period ? period.value : 'day');
        this.props.history.push(url);
    }

    handleLoadError = (state, message, error) => {
        state.loadErrorMessage = this.loadErrorMessage;
        this.setState(state);

        console.error(this.loadErrorMessage + " (" + message + ")");
        if (error)
            console.error(error);
    }

    updateDatePicker = (datePicker, removeTooltip) => {
        this.setState({datePicker});
        if (removeTooltip)
            d3.selectAll('.nvtooltip').remove();
    }

    isButtonNowDisabled = () => moment().isSame(this.state.datePicker.startDate, this.state.periodFilter)

    goToPreviousPeriod = () => {
        let {datePicker} = this.state;
        datePicker.startDate.subtract(1, this.state.periodFilter);
        this.props.history.push(this.getSearchURL());
    }

    goToNextPeriod = () => {
        let { datePicker } = this.state;
        datePicker.startDate.add(1, this.state.periodFilter);
        this.props.history.push(this.getSearchURL());
    }

    goToCurrentDay = () => {
        let { datePicker } = this.state;
        datePicker.startDate = moment();
        this.props.history.push(this.getSearchURL());
    }

    addWeekEndsHighlight = () => {
        const renderedInnerChartG = d3.select(".nv-chart svg g.nv-background");

        // the chart was already created
        if (renderedInnerChartG && renderedInnerChartG.node()){
            const existingHighlights = d3.select(".nv-chart svg g g .weekend-highlight")[0][0];

            // no highlight rects were added yet
            if (existingHighlights === null){
                let weekEndData = [];
                const chartDimensions = renderedInnerChartG.node().getBBox();
                const xScale = scaleTime().domain(this.getXDomain()).range([0, chartDimensions.width]);

                if (this.state.periodFilter === 'week'){
                    let sunday = {};
                    let left = xScale(moment(this.state.datePicker.startDate.clone().startOf('week').valueOf()).toDate());
                    let right = xScale(moment(this.state.datePicker.startDate.clone().startOf('week').add(1, 'days').valueOf()).toDate());
                    let width = right - left;
                    sunday.xStart = left;
                    sunday.width = width;

                    let saturday = {};
                    let left2 = xScale(moment(this.state.datePicker.startDate.clone().endOf('week').subtract(1, 'days').valueOf()).toDate());
                    let right2 = xScale(moment(this.state.datePicker.startDate.clone().endOf('week').valueOf()).toDate());
                    let width2 = right2 - left2;
                    saturday.xStart = left2;
                    saturday.width = width2;

                    weekEndData.push(sunday);
                    weekEndData.push(saturday);
                }

                if (weekEndData.length > 0){
                    const group = d3.select(".nv-chart svg g g");

                    group.selectAll("rect.weekend-highlight")
                        .data(weekEndData)
                        .enter()
                        .append("rect")
                        .classed("weekend-highlight", true)
                        .attr("x", (d) => d.xStart)
                        .attr("y", 0)
                        .attr("width", (d) => d.width )
                        .attr("height", chartDimensions.height )
                        .attr("fill", "#d8d8d8")
                        .attr("opacity", 0.2);
                }
            }
        }
    }

    clearTooltip = () => {
        this.setState({tooltip: false})
        setTimeout(() => { this.setState({tooltip: true})}, 500)
    }

    render () {
        const { periodFilter, loadingData } = this.state

        const isButtonNowDisabled = this.isButtonNowDisabled();

        let xAxis = {
            tickFormat: this.xTickFormat,
            showMaxMin: false,
        };

        if (this.state.periodFilter === 'day'){
            xAxis.rotateLabels = -50;
            xAxis.tickPadding = 0;
            xAxis.ticks = 24;
        }

        if (this.state.periodFilter === 'week')
            xAxis.tickValues = this.state.xTicks;

        return (
            <div id="dashboard" className="power-chart">
                <div id="power-chart">
                    <div id="top-bar">
                        <div className={`top top-period-filter-${periodFilter}`}>
                            <div>
                                {this.periodOptions.map((period, key) => (
                                    <Button className={
                                        `btn-period-filter${periodFilter === period.value ? ' active' : ''}`}
                                        disabled={
                                            loadingData ||
                                            periodFilter === period.value
                                        }
                                        key={key}
                                        onClick={() => this.changePeriodFilter(period)}>
                                        {period.label}
                                    </Button>
                                ))}
                            </div>

                            <div>
                                <IconButton
                                    className="btn-previous btn-previous-date"
                                    disabled={loadingData}
                                    onClick={this.goToPreviousPeriod}
                                >
                                    <ExpandMoreIcon />
                                </IconButton>

                                <DatePicker
                                    chartType="streamgraph"
                                    datePicker={this.state.datePicker}
                                    dateRangePicker={{}}
                                    updateDatePicker={this.updateDatePicker}
                                    updateDateRangePicker={() => {}}
                                    periodFilter={periodFilter}
                                    isLinear={this.state.isLinear}
                                    showParent={this.state.showParent}
                                    loadingGroups={this.state.loadingSensors}
                                    history={this.props.history}
                                    disabled={!this.state.loadingSensors}
                                    showLabel={false}
                                    dataGranularity={this.state.dataGranularity} />

                                <IconButton className="btn-next btn-next-date"
                                    disabled={
                                        loadingData ||
                                        this.state.datePicker.startDate.isSameOrAfter(
                                            moment().startOf(periodFilter))
                                    }
                                    onClick={this.goToNextPeriod}>
                                    <ExpandMoreIcon />
                                </IconButton>

                                <Button className={`btn-period${isButtonNowDisabled ? ' active' : ''}`}
                                    disabled={
                                        loadingData ||
                                        isButtonNowDisabled
                                    }
                                    onClick={() => this.goToCurrentDay()}>
                                    {i18n.t('NOW')}
                                </Button>
                            </div>
                            <div />
                        </div>
                        {!this.state.loadingSensors && (
                            <div id="group-filters">
                                <div id="compare-card" className="bottom">
                                    <Select
                                        name="sensorPhaseFilter"
                                        value={this.state.filterSensor}
                                        onChange={sensor => this.changeSensor(sensor)}
                                        disabled={!this.state.sensors || !this.state.sensors.length}
                                        valueKey="key"
                                        labelKey="label"
                                        options={this.state.sensors}
                                        placeholder={i18n.t('Select any sensor-phase (type to search)')}
                                        noResultsText={i18n.t('No results found')}
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                    <div id="chart">
                        {(!this.state.loadingSensors &&
                        !this.state.loadingData &&
                        (!this.state.data.length || !this.state.sensors.length)) ? (
                            <div className="nv-chart no-chart-msg">
                                <p className="no-data">{this.state.noDataChartMsg}</p>
                            </div>
                        ) : (
                            <div id="graph" >
                                <ChartLoading loading={this.state.loadingData} />
                                <div className="tooltip-killer" />

                                <NVD3Chart
                                    type="lineChart"
                                    datum={this.state.data}
                                    x="time"
                                    y="power"
                                    options={{
                                        color: colors,
                                        duration: 0,
                                        interpolate: 'step-before',
                                        forceX: this.state.xDomain,
                                        // forceY: this.state.chartMaxConsumption ?
                                        //     [0, this.state.chartMaxConsumption] : [0],
                                        interactiveLayer: {
                                            tooltip: {
                                        //         keyFormatter: (name, index) => !this.state.filterTag ? name : name === 'TOTAL' ? 'Total' : this.state.breadcrumbsTrails[index],
                                                // headerFormatter: this.tooltipHeaderFormatter,
                                                headerFormatter: (strDate) => moment(strDate).format('dddd, MMMM Do YYYY, hh:mm A'),
                                        //         enabled: this.state.tooltip,
                                        //         valueFormatter: (value, i, d) => {
                                        //             let valueLabel = kwhFormat(value);
                                        //             if (d && d.point && d.point.aggregationType){
                                        //                 if (d.point.aggregationType === 'ESTIMATED')
                                        //                     valueLabel = '(estimated) ' + valueLabel;
                                        //                 if (d.point.aggregationType === 'HYBRID')
                                        //                     valueLabel = '(partially estimated) ' + valueLabel;
                                        //             }
                                        //             return valueLabel;
                                        //         }
                                            }
                                        },
                                        // legend: { keyFormatter:
                                        //     key => this.state.dataLegends[key] || key
                                        // },
                                        useInteractiveGuideline: true,
                                        noData: this.state.noDataChartMsg,
                                        xAxis: xAxis,
                                        xScale: scaleTime(),
                                        yAxis: { axisLabel: 'kW | kVar' },
                                        showControls: false
                                    }} />
                            </div>
                        )}
                    </div>
                    <div className="tables">
                        <Table className="details-table">
                            <TableHead>
                                <TableRow>
                                    <TableCell />
                                    <TableCell>{i18n.t('Max')}</TableCell>
                                    <TableCell>{i18n.t('Min')}</TableCell>
                                    <TableCell>{i18n.t('Avg')}</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                <TableRow>
                                    <TableCell><strong>{i18n.t('Active Power')}</strong></TableCell>
                                    <TableCell>
                                        <div>
                                            {this.state.activePower.max.value !== null
                                                ? `${kwhFormat(this.state.activePower.max.value)} kW`
                                                : '-'
                                            }
                                            <small>
                                                {this.state.activePower.max.time !== null
                                                    ? this.state.periodFilter === 'day'
                                                        ? moment(this.state.activePower.max.time).format('hh:mm:ss A')
                                                        : moment(this.state.activePower.max.time).format('dddd, MMMM Do - hh:ss A')
                                                    : '-'
                                                }
                                            </small>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div>
                                            {this.state.activePower.min.value !== null
                                                ? `${kwhFormat(this.state.activePower.min.value)} kW`
                                                : '-'
                                            }
                                            <small>
                                                {this.state.activePower.min.time !== null
                                                    ? this.state.periodFilter === 'day'
                                                        ? moment(this.state.activePower.min.time).format('hh:mm:ss A')
                                                        : moment(this.state.activePower.min.time).format('dddd, MMMM Do - hh:ss A')
                                                    : '-'
                                                }
                                            </small>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        {this.state.activePower.average !== null
                                            ? `${kwhFormat(this.state.activePower.average)} kW`
                                            : '-'
                                        }
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>{i18n.t('Reactive Power')}</strong></TableCell>
                                    <TableCell>
                                        <div>
                                            {this.state.reativePower.max.value !== null
                                                ? `${kwhFormat(this.state.reativePower.max.value)} kVar`
                                                : '-'
                                            }
                                            <small>
                                                {this.state.reativePower.max.time !== null
                                                    ? this.state.periodFilter === 'day'
                                                        ? moment(this.state.reativePower.max.time).format('hh:mm:ss A')
                                                        : moment(this.state.reativePower.max.time).format('dddd, MMMM Do - hh:ss A')
                                                    : '-'
                                                }
                                            </small>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div>
                                            {this.state.reativePower.min.value !== null
                                                ? `${kwhFormat(this.state.reativePower.min.value)} kVar`
                                                : '-'
                                            }
                                            <small>
                                                {this.state.reativePower.min.time !== null
                                                    ? this.state.periodFilter === 'day'
                                                        ? moment(this.state.reativePower.min.time).format('hh:mm:ss A')
                                                        : moment(this.state.reativePower.min.time).format('dddd, MMMM Do - hh:ss A')
                                                    : '-'
                                                }
                                            </small>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        {this.state.reativePower.average !== null
                                            ? `${kwhFormat(this.state.reativePower.average)} kVar`
                                            : '-'
                                        }
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </div>
                <SystemSnackbar
                    open={!this.state.loadingSensors && this.state.sensors.length === 0}
                    message={i18n.t('No sensors setup')}
                    variant='warning' />
            </div>
        )
    }
}

PowerChart.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(PowerChart);
