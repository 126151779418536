// Translation
import i18n from '../../i18n';

const granularities = () => ({
    MINUTE: i18n.t('minute'),
    HOUR: i18n.t('hour'),
    DAY: i18n.t('day'),
    WEEK: i18n.t('week'),
    MONTH: i18n.t('month'),
})

const granularitiesContextPT_BR = () => ({
    MINUTE: 'male',
    HOUR: 'female',
    DAY: 'male',
    WEEK: 'female',
    MONTH: 'male',
    MINUTES: 'male',
    HOURS: 'female',
    DAYS: 'male',
    WEEKS: 'female',
    MONTHS: 'male',
})

const granularitiesPlural = (quantity = 1) => ({
    MINUTE: quantity > 1 ? i18n.t('minutes') : i18n.t('minute'),
    HOUR: quantity > 1 ? i18n.t('hours') : i18n.t('hour'),
    DAY: quantity > 1 ? i18n.t('days') : i18n.t('day'),
    WEEK: quantity > 1 ? i18n.t('weeks') : i18n.t('week'),
    MONTH: quantity > 1 ? i18n.t('months') : i18n.t('month'),
})

const granularitiesCapitalized = () => ({
    MINUTE: i18n.t('Minute'),
    HOUR: i18n.t('Hour'),
    DAY: i18n.t('Day'),
    WEEK: i18n.t('Week'),
    MONTH: i18n.t('Month'),
})

const alertOperations = () => ({
    ABOVE: i18n.t('above'),
    BELOW: i18n.t('below'),
    ABOVE_OR_BELOW: i18n.t('either above or below'),
})

export { granularities, granularitiesPlural, granularitiesCapitalized, alertOperations, granularitiesContextPT_BR }
