import React from 'react';
import CircularProgress from 'material-ui/Progress/CircularProgress';

import './chart-loading.css'

const ChartLoading = ({ loading, loaded, total, hidePercentage, position = 'top' }) => (
    <div
        id="chart-loading-wrapper"
        className={`${loading ? 'loading' : ''} ${position === 'top' ? '' : 'center'}`}
    >
        {!!total && !hidePercentage && (
            <p className="loading-text">
                {parseFloat((loaded / total) * 100).toFixed(0)}%
            </p>
        )}
        <CircularProgress
            size={56}
            value={(total && ((loaded / total) * 100) > 5) ? (loaded / total) * 100 : 5}
            variant={total ? 'static' : 'indeterminate'}
            style={{ color: '#6ab42d' }}
        />
    </div>
)

export default ChartLoading;
