const benchmarkLineGroupMonthData = {
    "groupId": "Sample Group",
    "totalConsumption": 105.85612,
    "totalCost": 59.9581475959428,
    "avgConsumption": 3.528537,
    "avgCost": 1.998605,
    "avgWeekendsConsumption": 4.06443,
    "avgWeekendsCost": 2.272859,
    "avgWorkingDaysConsumption": 3.333667,
    "avgWorkingDaysCost": 1.898876,
    "minDayConsumption": {
        "consumption": 1.427996,
        "day": "2019-04-18"
    },
    "maxDayConsumption": {
        "consumption": 7.506558,
        "day": "2019-04-04"
    },
    "minDayCost": {
        "cost": 0.9275836408,
        "day": "2019-04-18"
    },
    "maxDayCost": {
        "cost": 4.02834440636,
        "day": "2019-04-04"
    },
    "diffCons": -22.6758,
    "numberOfSensors": 1,
    "tariffs": [
        {
            "id": 149,
            "name": "Brazil Tariff",
            "rate": 0.51,
            "standingCharge": 0.2,
            "initDate": "2018-09-10T18:57:37.961495Z",
            "endDate": null,
            "compositions": [],
            "currency": "BRL"
        }
    ],
    "data": [
        {
            "groupId": 194,
            "month": 201904,
            "dayTz": "2019-04-01",
            "consumption": 3.42919,
            "cost": 1.9488866268,
            "aggregationType": "REAL",
            "tariffCompositions": [
                {
                    "id": null,
                    "rate": 0.51,
                    "tariffId": 149,
                    "dayOfWeek": null,
                    "startTime": null,
                    "endTime": null,
                    "standingCharge": 0.2,
                    "limitType": null,
                    "limitValue": null,
                    "createdAt": null
                }
            ]
        },
        {
            "groupId": 194,
            "month": 201904,
            "dayTz": "2019-04-02",
            "consumption": 4.372785,
            "cost": 2.4301201532,
            "aggregationType": "REAL",
            "tariffCompositions": [
                {
                    "id": null,
                    "rate": 0.51,
                    "tariffId": 149,
                    "dayOfWeek": null,
                    "startTime": null,
                    "endTime": null,
                    "standingCharge": 0.2,
                    "limitType": null,
                    "limitValue": null,
                    "createdAt": null
                }
            ]
        },
        {
            "groupId": 194,
            "month": 201904,
            "dayTz": "2019-04-03",
            "consumption": 4.287335,
            "cost": 2.3865408412,
            "aggregationType": "REAL",
            "tariffCompositions": [
                {
                    "id": null,
                    "rate": 0.51,
                    "tariffId": 149,
                    "dayOfWeek": null,
                    "startTime": null,
                    "endTime": null,
                    "standingCharge": 0.2,
                    "limitType": null,
                    "limitValue": null,
                    "createdAt": null
                }
            ]
        },
        {
            "groupId": 194,
            "month": 201904,
            "dayTz": "2019-04-04",
            "consumption": 7.506558,
            "cost": 4.02834440636,
            "aggregationType": "REAL",
            "tariffCompositions": [
                {
                    "id": null,
                    "rate": 0.51,
                    "tariffId": 149,
                    "dayOfWeek": null,
                    "startTime": null,
                    "endTime": null,
                    "standingCharge": 0.2,
                    "limitType": null,
                    "limitValue": null,
                    "createdAt": null
                }
            ]
        },
        {
            "groupId": 194,
            "month": 201904,
            "dayTz": "2019-04-05",
            "consumption": 3.956787,
            "cost": 2.21796135572,
            "aggregationType": "REAL",
            "tariffCompositions": [
                {
                    "id": null,
                    "rate": 0.51,
                    "tariffId": 149,
                    "dayOfWeek": null,
                    "startTime": null,
                    "endTime": null,
                    "standingCharge": 0.2,
                    "limitType": null,
                    "limitValue": null,
                    "createdAt": null
                }
            ]
        },
        {
            "groupId": 194,
            "month": 201904,
            "dayTz": "2019-04-06",
            "consumption": 6.125097,
            "cost": 3.3237996693,
            "aggregationType": "REAL",
            "tariffCompositions": [
                {
                    "id": null,
                    "rate": 0.51,
                    "tariffId": 149,
                    "dayOfWeek": null,
                    "startTime": null,
                    "endTime": null,
                    "standingCharge": 0.2,
                    "limitType": null,
                    "limitValue": null,
                    "createdAt": null
                }
            ]
        },
        {
            "groupId": 194,
            "month": 201904,
            "dayTz": "2019-04-07",
            "consumption": 4.757401,
            "cost": 2.6262745985,
            "aggregationType": "REAL",
            "tariffCompositions": [
                {
                    "id": null,
                    "rate": 0.51,
                    "tariffId": 149,
                    "dayOfWeek": null,
                    "startTime": null,
                    "endTime": null,
                    "standingCharge": 0.2,
                    "limitType": null,
                    "limitValue": null,
                    "createdAt": null
                }
            ]
        },
        {
            "groupId": 194,
            "month": 201904,
            "dayTz": "2019-04-08",
            "consumption": 2.706924,
            "cost": 1.5805313215,
            "aggregationType": "REAL",
            "tariffCompositions": [
                {
                    "id": null,
                    "rate": 0.51,
                    "tariffId": 149,
                    "dayOfWeek": null,
                    "startTime": null,
                    "endTime": null,
                    "standingCharge": 0.2,
                    "limitType": null,
                    "limitValue": null,
                    "createdAt": null
                }
            ]
        },
        {
            "groupId": 194,
            "month": 201904,
            "dayTz": "2019-04-09",
            "consumption": 3.181789,
            "cost": 1.82271212334,
            "aggregationType": "REAL",
            "tariffCompositions": [
                {
                    "id": null,
                    "rate": 0.51,
                    "tariffId": 149,
                    "dayOfWeek": null,
                    "startTime": null,
                    "endTime": null,
                    "standingCharge": 0.2,
                    "limitType": null,
                    "limitValue": null,
                    "createdAt": null
                }
            ]
        },
        {
            "groupId": 194,
            "month": 201904,
            "dayTz": "2019-04-10",
            "consumption": 2.369726,
            "cost": 1.4085602342,
            "aggregationType": "REAL",
            "tariffCompositions": [
                {
                    "id": null,
                    "rate": 0.51,
                    "tariffId": 149,
                    "dayOfWeek": null,
                    "startTime": null,
                    "endTime": null,
                    "standingCharge": 0.2,
                    "limitType": null,
                    "limitValue": null,
                    "createdAt": null
                }
            ]
        },
        {
            "groupId": 194,
            "month": 201904,
            "dayTz": "2019-04-11",
            "consumption": 2.985283,
            "cost": 1.7224940811,
            "aggregationType": "REAL",
            "tariffCompositions": [
                {
                    "id": null,
                    "rate": 0.51,
                    "tariffId": 149,
                    "dayOfWeek": null,
                    "startTime": null,
                    "endTime": null,
                    "standingCharge": 0.2,
                    "limitType": null,
                    "limitValue": null,
                    "createdAt": null
                }
            ]
        },
        {
            "groupId": 194,
            "month": 201904,
            "dayTz": "2019-04-12",
            "consumption": 3.605829,
            "cost": 2.03897252972,
            "aggregationType": "REAL",
            "tariffCompositions": [
                {
                    "id": null,
                    "rate": 0.51,
                    "tariffId": 149,
                    "dayOfWeek": null,
                    "startTime": null,
                    "endTime": null,
                    "standingCharge": 0.2,
                    "limitType": null,
                    "limitValue": null,
                    "createdAt": null
                }
            ]
        },
        {
            "groupId": 194,
            "month": 201904,
            "dayTz": "2019-04-13",
            "consumption": 5.089627,
            "cost": 2.7957094922,
            "aggregationType": "REAL",
            "tariffCompositions": [
                {
                    "id": null,
                    "rate": 0.51,
                    "tariffId": 149,
                    "dayOfWeek": null,
                    "startTime": null,
                    "endTime": null,
                    "standingCharge": 0.2,
                    "limitType": null,
                    "limitValue": null,
                    "createdAt": null
                }
            ]
        },
        {
            "groupId": 194,
            "month": 201904,
            "dayTz": "2019-04-14",
            "consumption": 3.64983,
            "cost": 2.061413394203,
            "aggregationType": "REAL",
            "tariffCompositions": [
                {
                    "id": null,
                    "rate": 0.51,
                    "tariffId": 149,
                    "dayOfWeek": null,
                    "startTime": null,
                    "endTime": null,
                    "standingCharge": 0.2,
                    "limitType": null,
                    "limitValue": null,
                    "createdAt": null
                }
            ]
        },
        {
            "groupId": 194,
            "month": 201904,
            "dayTz": "2019-04-15",
            "consumption": 3.612738,
            "cost": 2.04249610188,
            "aggregationType": "REAL",
            "tariffCompositions": [
                {
                    "id": null,
                    "rate": 0.51,
                    "tariffId": 149,
                    "dayOfWeek": null,
                    "startTime": null,
                    "endTime": null,
                    "standingCharge": 0.2,
                    "limitType": null,
                    "limitValue": null,
                    "createdAt": null
                }
            ]
        },
        {
            "groupId": 194,
            "month": 201904,
            "dayTz": "2019-04-16",
            "consumption": 4.231886,
            "cost": 2.3582620041,
            "aggregationType": "REAL",
            "tariffCompositions": [
                {
                    "id": null,
                    "rate": 0.51,
                    "tariffId": 149,
                    "dayOfWeek": null,
                    "startTime": null,
                    "endTime": null,
                    "standingCharge": 0.2,
                    "limitType": null,
                    "limitValue": null,
                    "createdAt": null
                }
            ]
        },
        {
            "groupId": 194,
            "month": 201904,
            "dayTz": "2019-04-17",
            "consumption": 2.610798,
            "cost": 1.5315070728,
            "aggregationType": "REAL",
            "tariffCompositions": [
                {
                    "id": null,
                    "rate": 0.51,
                    "tariffId": 149,
                    "dayOfWeek": null,
                    "startTime": null,
                    "endTime": null,
                    "standingCharge": 0.2,
                    "limitType": null,
                    "limitValue": null,
                    "createdAt": null
                }
            ]
        },
        {
            "groupId": 194,
            "month": 201904,
            "dayTz": "2019-04-18",
            "consumption": 1.427996,
            "cost": 0.9275836408,
            "aggregationType": "HYBRID",
            "tariffCompositions": [
                {
                    "id": null,
                    "rate": 0.51,
                    "tariffId": 149,
                    "dayOfWeek": null,
                    "startTime": null,
                    "endTime": null,
                    "standingCharge": 0.2,
                    "limitType": null,
                    "limitValue": null,
                    "createdAt": null
                }
            ]
        },
        {
            "groupId": 194,
            "month": 201904,
            "dayTz": "2019-04-19",
            "consumption": 1.448861,
            "cost": 0.9389192832,
            "aggregationType": "ESTIMATED",
            "tariffCompositions": [
                {
                    "id": null,
                    "rate": 0.51,
                    "tariffId": 149,
                    "dayOfWeek": null,
                    "startTime": null,
                    "endTime": null,
                    "standingCharge": 0.2,
                    "limitType": null,
                    "limitValue": null,
                    "createdAt": null
                }
            ]
        },
        {
            "groupId": 194,
            "month": 201904,
            "dayTz": "2019-04-20",
            "consumption": 1.448861,
            "cost": 0.9389192832,
            "aggregationType": "ESTIMATED",
            "tariffCompositions": [
                {
                    "id": null,
                    "rate": 0.51,
                    "tariffId": 149,
                    "dayOfWeek": null,
                    "startTime": null,
                    "endTime": null,
                    "standingCharge": 0.2,
                    "limitType": null,
                    "limitValue": null,
                    "createdAt": null
                }
            ]
        },
        {
            "groupId": 194,
            "month": 201904,
            "dayTz": "2019-04-21",
            "consumption": 1.448861,
            "cost": 0.9389192832,
            "aggregationType": "ESTIMATED",
            "tariffCompositions": [
                {
                    "id": null,
                    "rate": 0.51,
                    "tariffId": 149,
                    "dayOfWeek": null,
                    "startTime": null,
                    "endTime": null,
                    "standingCharge": 0.2,
                    "limitType": null,
                    "limitValue": null,
                    "createdAt": null
                }
            ]
        },
        {
            "groupId": 194,
            "month": 201904,
            "dayTz": "2019-04-22",
            "consumption": 3.183408,
            "cost": 1.8235379231,
            "aggregationType": "HYBRID",
            "tariffCompositions": [
                {
                    "id": null,
                    "rate": 0.51,
                    "tariffId": 149,
                    "dayOfWeek": null,
                    "startTime": null,
                    "endTime": null,
                    "standingCharge": 0.2,
                    "limitType": null,
                    "limitValue": null,
                    "createdAt": null
                }
            ]
        },
        {
            "groupId": 194,
            "month": 201904,
            "dayTz": "2019-04-23",
            "consumption": 3.752138,
            "cost": 2.1135902008,
            "aggregationType": "REAL",
            "tariffCompositions": [
                {
                    "id": null,
                    "rate": 0.51,
                    "tariffId": 149,
                    "dayOfWeek": null,
                    "startTime": null,
                    "endTime": null,
                    "standingCharge": 0.2,
                    "limitType": null,
                    "limitValue": null,
                    "createdAt": null
                }
            ]
        },
        {
            "groupId": 194,
            "month": 201904,
            "dayTz": "2019-04-24",
            "consumption": 2.721263,
            "cost": 1.5878442222,
            "aggregationType": "HYBRID",
            "tariffCompositions": [
                {
                    "id": null,
                    "rate": 0.51,
                    "tariffId": 149,
                    "dayOfWeek": null,
                    "startTime": null,
                    "endTime": null,
                    "standingCharge": 0.2,
                    "limitType": null,
                    "limitValue": null,
                    "createdAt": null
                }
            ]
        },
        {
            "groupId": 194,
            "month": 201904,
            "dayTz": "2019-04-25",
            "consumption": 3.848374,
            "cost": 2.16267039783,
            "aggregationType": "REAL",
            "tariffCompositions": [
                {
                    "id": null,
                    "rate": 0.51,
                    "tariffId": 149,
                    "dayOfWeek": null,
                    "startTime": null,
                    "endTime": null,
                    "standingCharge": 0.2,
                    "limitType": null,
                    "limitValue": null,
                    "createdAt": null
                }
            ]
        },
        {
            "groupId": 194,
            "month": 201904,
            "dayTz": "2019-04-26",
            "consumption": 3.32091,
            "cost": 1.89366416722,
            "aggregationType": "REAL",
            "tariffCompositions": [
                {
                    "id": null,
                    "rate": 0.51,
                    "tariffId": 149,
                    "dayOfWeek": null,
                    "startTime": null,
                    "endTime": null,
                    "standingCharge": 0.2,
                    "limitType": null,
                    "limitValue": null,
                    "createdAt": null
                }
            ]
        },
        {
            "groupId": 194,
            "month": 201904,
            "dayTz": "2019-04-27",
            "consumption": 5.342053,
            "cost": 2.92444712166,
            "aggregationType": "REAL",
            "tariffCompositions": [
                {
                    "id": null,
                    "rate": 0.51,
                    "tariffId": 149,
                    "dayOfWeek": null,
                    "startTime": null,
                    "endTime": null,
                    "standingCharge": 0.2,
                    "limitType": null,
                    "limitValue": null,
                    "createdAt": null
                }
            ]
        },
        {
            "groupId": 194,
            "month": 201904,
            "dayTz": "2019-04-28",
            "consumption": 4.653712,
            "cost": 2.5733929262,
            "aggregationType": "REAL",
            "tariffCompositions": [
                {
                    "id": null,
                    "rate": 0.51,
                    "tariffId": 149,
                    "dayOfWeek": null,
                    "startTime": null,
                    "endTime": null,
                    "standingCharge": 0.2,
                    "limitType": null,
                    "limitValue": null,
                    "createdAt": null
                }
            ]
        },
        {
            "groupId": 194,
            "month": 201904,
            "dayTz": "2019-04-29",
            "consumption": 2.85378,
            "cost": 1.6554279211,
            "aggregationType": "REAL",
            "tariffCompositions": [
                {
                    "id": null,
                    "rate": 0.51,
                    "tariffId": 149,
                    "dayOfWeek": null,
                    "startTime": null,
                    "endTime": null,
                    "standingCharge": 0.2,
                    "limitType": null,
                    "limitValue": null,
                    "createdAt": null
                }
            ]
        },
        {
            "groupId": 194,
            "month": 201904,
            "dayTz": "2019-04-30",
            "consumption": 1.92632,
            "cost": 1.1546452193098,
            "aggregationType": "REAL",
            "tariffCompositions": [
                {
                    "id": null,
                    "rate": 0.51,
                    "tariffId": 149,
                    "dayOfWeek": null,
                    "startTime": null,
                    "endTime": null,
                    "standingCharge": 0.2,
                    "limitType": null,
                    "limitValue": null,
                    "createdAt": null
                }
            ]
        }
    ]
};

export {benchmarkLineGroupMonthData}