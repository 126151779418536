import React, { PureComponent } from 'react';
import PropTypes from 'prop-types'
import { withStyles } from 'material-ui/styles';
import { injectIntl, intlShape } from 'react-intl';
import Typography from 'material-ui/Typography';
import AddIcon from 'material-ui-icons/AddCircleOutline';
import CloseIcon from 'material-ui-icons/Close';
import FormControlLabel from 'material-ui/Form/FormControlLabel'
import FormControl from 'material-ui/Form/FormControl'
import FormGroup from 'material-ui/Form/FormGroup'
import Radio from 'material-ui/Radio/Radio'
import RadioGroup from 'material-ui/Radio/RadioGroup'
import Checkbox from 'material-ui/Checkbox'
import Modal from 'material-ui/Modal';
import moment from 'moment';
import getSymbolFromCurrency from 'currency-symbol-map';
import reactStrReplace from 'react-string-replace';

// Translation
import i18n from '../../i18n';

import { API } from '../../components/api';
import { Title } from '../../elements/title';
import { Button } from '../../elements/button';
import { Input as VInput } from '../../elements/forms/input';
import { CustomSelect } from '../../elements/forms/custom-react-select';
import SystemSnackbar from '../../elements/material-ui/SystemSnackbar';
import {InputTimeSelect} from '../../elements/forms/input-time-select';
import { getSensorStructured, getMultiphaseSensorStructured } from '../../components/utils'
import AlertAddRecipient from './alert-add-recipient';
import {startHoursOptions, endHoursOptions} from '../../elements/forms/input-time-select';

import 'react-select/dist/react-select.css';
import './alert-add.css';

const modes = { add: 'add', edit: 'edit' };

const alertCategoriesVars = {
    ENTITY_STATUS: 'entityStatusAlert',
    RELATIVE_CONSUMPTION: 'alert',
    ABSOLUTE_CONSUMPTION: 'absoluteConsumptionAlert',
    ABSOLUTE_COST: 'absoluteCostAlert',
    ABSOLUTE_ACTIVE_POWER: 'absolutePowerAlert',
}

const initialState = {
    addingEmail: false,
    fieldsWithError: {},
    groupId: null,
    loading: false,
    loadingGroups: true,
    mode: modes.add,
    groups: [],
    sensors: [],
    recipient: '',
    showSnackBar: false,
    snackbar: {
        type: 'warning',
        message: ''
    },
    alert: {
        name: '',
        recipients: [],
        alertType: 'PERCENTAGE',
        alertValue: '',
        notificationType: 'ALL',
    },
    absoluteConsumptionAlert: {
        name: '',
        recipients: [],
        period: '',
        value: '',
        notificationType: 'ALL',
    },
    entityStatusAlert: {
        name: '',
        recipients: [],
        option: '',
        notificationType: 'EMAIL',
    },
    absoluteCostAlert: {
        name: '',
        recipients: [],
        period: '',
        value: '',
        notificationType: 'ALL',
    },
    absolutePowerAlert: {
        name: '',
        recipients: [],
        period: '',
        value: '',
        notificationType: 'EMAIL',
    },
    groupCurrencyCode: null,
    subgroupCurrencyCode: null,
    sensorCurrencyCode: null,
    loadingCurrency: false,
};

const RelativeConsumptionSection = ({loading, classes, fieldsWithError, alert, handleAlertTypeChange, changeGranularity, changeOperation, change, changePeriodQtd}) => {

    const operations = [{
        value: 'ABOVE',
        label: i18n.t('Above')
    }, {
        value: 'BELOW',
        label: i18n.t('Below')
    }, {
        value: 'ABOVE_OR_BELOW',
        label: i18n.t('Above or below')
    }];

    const formPhrase = i18n.t('I want to receive notifications if {{granularity_select}} of consumption is {{alert_value}} {{alert_type}} {{alert_operation}} than the average of the last {{alert_period_quantity}}.', {
            alert_type: alert.alertType ? alert.alertType === 'PERCENTAGE' ? '$t(%)' : '$t(kWh)' : '',
            granularity_select: 'granularity_select',
            alert_value: 'alert_value',
            alert_operation: 'alert_operation',
            alert_period_quantity: 'alert_period_quantity'
    })

    return (
        <div>
            <div className="form-sections">
                <FormControl component="fieldset" className={classes.formControl} required >
                    <RadioGroup
                        aria-label="alertType"
                        name="alertType"
                        className={classes.radiosContainer}
                        value={alert.alertType}
                        onChange={handleAlertTypeChange}
                    >
                        <FormControlLabel value="PERCENTAGE" control={<Radio />} label={i18n.t("% Consumption")} />
                        <FormControlLabel value="ABSOLUTE" control={<Radio />} label={i18n.t("kWh Consumption")} />
                    </RadioGroup>
                </FormControl>
            </div>
            <div className="trigger-section">
                <div className="text">
                    {reactStrReplace(formPhrase, /(granularity_select|alert_value|alert_operation|alert_period_quantity)/g, match => {
                        switch(match) {
                            case 'granularity_select':
                                return (
                                    <CustomSelect
                                        clearable={false}
                                        name="granularity"
                                        key="granularity"
                                        value={alert.granularity}
                                        onChange={changeGranularity}
                                        className={fieldsWithError.granularity ? 'error' : null}
                                        hasError={fieldsWithError.granularity}
                                        withinText={true}
                                        valueKey="value"
                                        labelKey="label"
                                        options={granularities()}
                                    />
                                )
                            case 'alert_value':
                                return (
                                    <VInput
                                        change={change}
                                        disabled={loading}
                                        hasError={fieldsWithError.alertValue}
                                        value={alert.alertValue}
                                        name="alertValue"
                                        key="alertValue"
                                        type="number"
                                        min="0"
                                        required
                                    />
                                )
                            case 'alert_operation':
                                return (
                                    <CustomSelect
                                        clearable={false}
                                        name="alertOperation"
                                        key="alertOperation"
                                        value={alert.alertOperation}
                                        onChange={changeOperation}
                                        className={fieldsWithError.alertOperation ? 'error' : null}
                                        hasError={fieldsWithError.alertOperation}
                                        withinText={true}
                                        valueKey="value"
                                        labelKey="label"
                                        options={operations}
                                    />
                                )
                            case 'alert_period_quantity':
                                return (
                                    <CustomSelect
                                        clearable={false}
                                        name="alertPeriodQuantity"
                                        key="alertPeriodQuantity"
                                        value={alert.alertPeriodQuantity}
                                        onChange={changePeriodQtd}
                                        className={fieldsWithError.alertPeriodQuantity ? 'error' : null}
                                        hasError={fieldsWithError.alertPeriodQuantity}
                                        withinText={true}
                                        valueKey="value"
                                        labelKey="label"
                                        options={alertPeriods()}
                                    />
                                )
                            default:
                                return '';
                        }

                    })}
                </div>
            </div>
        </div>
    );
}

const AbsoluteConsumptionSection = ({loading, absoluteConsumptionAlert, fieldsWithError, changeAbsoluteConsumptionValue, changeAbsoluteConsumptionPeriod, absoluteConsumptionPeriods}) => {
    const formPhrase = i18n.t('I would like to receive a notification if the accumulated consumption during the period {{alert_period_quantity}} reaches {{alert_value}} $t(kWh).', {
            alert_value: 'alert_value',
            alert_period_quantity: 'alert_period_quantity'
    })

    return (
        <div className="trigger-section">
            <div className="text">
                {reactStrReplace(formPhrase, /(alert_period_quantity|alert_value)/g, match => {
                    switch(match) {
                        case 'alert_period_quantity':
                            return (
                                <CustomSelect
                                    clearable={false}
                                    name="absoluteConsumptionPeriod"
                                    key="absoluteConsumptionPeriod"
                                    value={absoluteConsumptionAlert.period}
                                    onChange={changeAbsoluteConsumptionPeriod}
                                    className={fieldsWithError.absoluteConsumptionPeriod ? 'error absolute-consumption-period' : 'absolute-consumption-period'}
                                    hasError={fieldsWithError.absoluteConsumptionPeriod}
                                    withinText={true}
                                    valueKey="value"
                                    labelKey="label"
                                    options={absoluteConsumptionPeriods}
                                />
                            )
                        case 'alert_value':
                            return (
                                <VInput
                                    change={changeAbsoluteConsumptionValue}
                                    disabled={loading}
                                    hasError={fieldsWithError.absoluteConsumptionValue}
                                    value={absoluteConsumptionAlert.value}
                                    name="absoluteConsumptionValue"
                                    key="absoluteConsumptionValue"
                                    type="number"
                                    step="0.01"
                                    min="0"
                                    required
                                />
                            )
                        default:
                            return '';
                    }
                })}
            </div>
        </div>
    )
}

const EntityStatusSection = ({value, fieldsWithError, changeEntityStatusOption, entityStatusOptions, executionStartTime, executionEndTime, changeStartTime, changeEndTime }) => {
    const formPhrase = i18n.t('I would like to receive a notification if the consumption measured goes {{entity_status}} at any moment between {{alert_init_period}} and {{alert_end_period}}. The checking frequency is hourly.', {
        entity_status: 'entity_status',
        alert_init_period: 'alert_init_period',
        alert_end_period: 'alert_end_period'
    })

    return (
        <div className="trigger-section">
            <div className="text">
                {reactStrReplace(formPhrase, /(entity_status|alert_init_period|alert_end_period)/g, match => {
                    switch(match) {
                        case 'entity_status':
                            return (
                                <CustomSelect
                                    clearable={false}
                                    name="entityStatusConsumption"
                                    key="entityStatusConsumption"
                                    value={value}
                                    onChange={changeEntityStatusOption}
                                    className={fieldsWithError.entityStatusOption ? 'error' : null}
                                    hasError={fieldsWithError.entityStatusOption}
                                    withinText={true}
                                    valueKey="value"
                                    labelKey="label"
                                    options={entityStatusOptions}
                                />
                            )
                        case 'alert_init_period':
                            return (
                                <div className="custom-react-select" key="executionStartTime">
                                    <InputTimeSelect
                                        name="executionStartTime"
                                        type="startTime"
                                        hasError={fieldsWithError.executionStartTime >= 0}
                                        time={executionStartTime}
                                        change={changeStartTime}
                                    />
                                </div>
                            )
                        case 'alert_end_period':
                            return (
                                <div className="custom-react-select" key="executionEndTime">
                                    <InputTimeSelect
                                        name="executionEndTime"
                                        type="endTime"
                                        className={fieldsWithError.executionEndTime ? 'error' : null}
                                        hasError={fieldsWithError.executionEndTime >= 0}
                                        time={executionEndTime}
                                        change={changeEndTime}
                                        minHour={executionStartTime ? executionStartTime.value : null}
                                    />
                                </div>
                            )
                        default:
                            return ''
                    }
                })}
            </div>
        </div>
    )
}

const AbsoluteCostSection = ({loading, absoluteCostAlert, fieldsWithError, changeAbsoluteCostValue, changeAbsoluteCostPeriod, absoluteCostPeriods, currencyCode}) => {
    const formPhrase = i18n.t('I would like to receive a notification if the sensor accumulated cost reaches {{currency_code}}{{alert_value}} in the period {{alert_period}}', {
        currency_code: currencyCode ? getSymbolFromCurrency(currencyCode) : '¤',
        alert_value: 'alert_value',
        alert_period: 'alert_period'
    });

    return (
        <div className="trigger-section">
            <div className="text">
                {reactStrReplace(formPhrase, /(alert_value|alert_period)/g, match => {
                    switch(match) {
                        case 'alert_value':
                            return (
                                <VInput
                                    change={changeAbsoluteCostValue}
                                    disabled={loading}
                                    hasError={fieldsWithError.absoluteCostValue}
                                    value={absoluteCostAlert.value}
                                    name="absoluteCostValue"
                                    key="absoluteCostValue"
                                    type="number"
                                    step="0.01"
                                    min="0"
                                    required
                                />
                            )
                        case 'alert_period':
                            return (
                                <CustomSelect
                                    clearable={false}
                                    name="absoluteCost"
                                    key="absoluteCost"
                                    value={absoluteCostAlert.period}
                                    onChange={changeAbsoluteCostPeriod}
                                    className={fieldsWithError.absoluteCostPeriod ? 'error absolute-cost-period' : 'absolute-cost-period'}
                                    hasError={fieldsWithError.absoluteCostPeriod}
                                    withinText={true}
                                    valueKey="value"
                                    labelKey="label"
                                    options={absoluteCostPeriods}
                                />
                            )
                        default:
                            return ''
                    }
                })}
            </div>
        </div>
    )
}

const PowerPeakSection = ({loading, absolutePowerAlert, fieldsWithError, changePowerPeakValue, changePowerPeakPeriod, powerPeakPeriods, changePowerPeakTrigger}) => {
    const formPhrase = i18n.t('I would like to receive a notification if the active power goes above {{alert_value}} kW in the last {{alert_period}}.', {
        alert_value: 'alert_value',
        alert_period: 'alert_period'
    });

    return (
        <div className="trigger-section">
            <div className="text">
                {reactStrReplace(formPhrase, /(alert_value|alert_period)/g, match => {
                    switch(match) {
                        case 'alert_value':
                            return (
                                <VInput
                                    change={changePowerPeakValue}
                                    disabled={loading}
                                    hasError={fieldsWithError.powerPeakValue}
                                    value={absolutePowerAlert.value}
                                    name="powerPeakValue"
                                    key="powerPeakValue"
                                    type="number"
                                    step="0.01"
                                    min="0"
                                    required
                                />
                            )
                        case 'alert_period':
                            return (
                                <CustomSelect
                                    clearable={false}
                                    name="powerPeakPeriod"
                                    key="powerPeakPeriod"
                                    value={absolutePowerAlert.period}
                                    onChange={changePowerPeakPeriod}
                                    className={fieldsWithError.powerPeakPeriod ? 'error power-peak-period' : 'power-peak-period'}
                                    hasError={fieldsWithError.powerPeakPeriod}
                                    withinText={true}
                                    valueKey="value"
                                    labelKey="label"
                                    options={powerPeakPeriods}
                                />
                            )
                        default:
                            return ''
                    }
                })}
            </div>
        </div>
    )
}

const granularities = () => [{
    value: 1,
    label: i18n.t('1 Hour'),
    alertQuantity: 1,
    alertGranularity: 'HOUR'
}, {
    value: 2,
    label: i18n.t('1 Day'),
    alertQuantity: 1,
    alertGranularity: 'DAY'
}, {
    value: 3,
    label: i18n.t('1 Week'),
    alertQuantity: 1,
    alertGranularity: 'WEEK'
}, {
    value: 4,
    label: i18n.t('1 Month'),
    alertQuantity: 1,
    alertGranularity: 'MONTH'
}]

const alertPeriods = () => [{
    value: 1,
    label: i18n.t('60 minutes'),
    alertPeriodQuantity: 60,
    alertPeriodGranularity: 'MINUTE',
    availableFor: [1],
}, {
    value: 2,
    label: i18n.t('24 hours'),
    alertPeriodQuantity: 24,
    alertPeriodGranularity: 'HOUR',
    availableFor: [1, 2],
}, {
    value: 3,
    label: i18n.t('7 days'),
    alertPeriodQuantity: 7,
    alertPeriodGranularity: 'DAY',
    availableFor: [1, 2, 3],
}, {
    value: 4,
    label: i18n.t('30 days'),
    alertPeriodQuantity: 30,
    alertPeriodGranularity: 'DAY',
    availableFor: [1, 2, 3, 4],
}]


const entityStatusOptions = () => [{
    value: i18n.t('off'),
    label: i18n.t('off'),
    operation: 'BELOW',
},
{
    value: i18n.t('on'),
    label: i18n.t('on'),
    operation: 'ABOVE',
}];

const absoluteConsumptionPeriods = () => [{
    value: 'HOUR',
    label: i18n.t('hour'),
    alertQuantity: 1,
    alertGranularity: 'HOUR',
},
{
    value: 'DAY',
    label: i18n.t('calendar day'),
    alertQuantity: 1,
    alertGranularity: 'DAY',
},
{
    value: 'WEEK',
    label: i18n.t('calendar week'),
    alertQuantity: 1,
    alertGranularity: 'WEEK',
},
{
    value: 'MONTH',
    label: i18n.t('calendar month'),
    alertQuantity: 1,
    alertGranularity: 'MONTH',
}];

const absoluteCostPeriods = () => [{
    value: 'HOUR',
    label: i18n.t('hour'),
    alertQuantity: 1,
    alertGranularity: 'HOUR',
},
{
    value: 'DAY',
    label: i18n.t('calendar day'),
    alertQuantity: 1,
    alertGranularity: 'DAY',
},
{
    value: 'WEEK',
    label: i18n.t('calendar week'),
    alertQuantity: 1,
    alertGranularity: 'WEEK',
},
{
    value: 'MONTH',
    label: i18n.t('calendar month'),
    alertQuantity: 1,
    alertGranularity: 'MONTH',
}];

const powerPeakPeriods = () => [{
    value: 'HOUR',
    label: i18n.t('hour'),
    alertQuantity: 1,
    alertGranularity: 'HOUR'
},
{
    value: 'DAY',
    label: i18n.t('calendar day'),
    alertQuantity: 1,
    alertGranularity: 'DAY'
}];

const closeButtonStyle = {
    cursor: 'pointer',
}

const modalStyle = {
    backgroundColor: '#FFF',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    maxHeight: '90%',
    overflowY: 'auto',
}

const closeModalButtonStyle = {
    cursor: 'pointer',
    padding: '10px',
    position: 'absolute',
    right: 0,
    zIndex: 1,
}

const styles = theme => ({
    paper: {
        '&:focus': {
            outline: 'none'
        }
    },
    typographyNoRecipients: {
        fontSize: '13px'
    },
    radiosContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    radioContent: {
        paddingTop: 0,
    },
    radio: {
        height: 33,
        width: 33,
    }
})

const getSaveData = ({ granularity, alertPeriodQuantity, groupId, subgroup, ...alert }, groups, category) => {
    const foundGranul = granularities().find(data => data.value === granularity)
    const foundPeriod = alertPeriods().find(data => data.value === alertPeriodQuantity)

    const foundGroup = groups.find(({ id }) => id === groupId)
    const foundSubgroup = foundGroup.subGroups.find(({ id }) => id === subgroup)
    const subSubGroup = subgroup && foundSubgroup.subGroups[0]

    return {
        ...alert,
        category,
        groupId: alert.sensorId ? subSubGroup.id : subgroup ? subgroup : groupId,
        ...foundGranul ? {
            alertQuantity: foundGranul.alertQuantity,
            alertGranularity: foundGranul.alertGranularity
        } : {},
        ...foundPeriod ? {
            alertPeriodQuantity: foundPeriod.alertPeriodQuantity,
            alertPeriodGranularity: foundPeriod.alertPeriodGranularity,
            triggerQuantity: foundPeriod.alertPeriodQuantity,
            triggerGranularity: foundPeriod.alertPeriodGranularity
        } : {}
    }
}

const getEntityStatusSaveData = ({ option, notificationType, groupId, subgroup, executionStartTime, executionEndTime, ...alert }, groups, category) => {
    const foundGroup = groups.find(({ id }) => id === groupId);
    const foundSubgroup = foundGroup.subGroups.find(({ id }) => id === subgroup);
    const subSubGroup = subgroup && foundSubgroup.subGroups[0];

    const foundOption = entityStatusOptions().find(data => data.value === option);
    const notification = notificationType || 'NONE';
    const startTime = executionStartTime && executionStartTime.value ? moment(executionStartTime.value, 'HH:mm:ss').format('HH:mm') : null;
    const endTime = executionEndTime && executionEndTime.value ? moment(executionEndTime.value, 'HH:mm:ss').format('HH:mm') : null;

    return {
        ...alert,
        category,
        executionStartTime: startTime,
        executionEndTime: endTime,
        groupId: alert.sensorId ? subSubGroup.id : subgroup ? subgroup : groupId,
        alertOperation: foundOption ? foundOption.operation : null,
        notificationType: notification,
    }
}

const getAbsoluteCostSaveData = ({ period, value, groupId, subgroup, ...alert }, groups, category) => {
    const foundGroup = groups.find(({ id }) => id === groupId);
    const foundSubgroup = foundGroup.subGroups.find(({ id }) => id === subgroup);
    const subSubGroup = subgroup && foundSubgroup.subGroups[0];

    const foundOption = absoluteCostPeriods().find(data => data.value === period);

    return {
        ...alert,
        category,
        alertValue: value,
        ...foundOption ?  {
            alertQuantity: foundOption.alertQuantity,
            alertGranularity: foundOption.alertGranularity,
            triggerQuantity: foundOption.alertQuantity,
            triggerGranularity: foundOption.alertGranularity,
        } : {},
        groupId: alert.sensorId ? subSubGroup.id : subgroup ? subgroup : groupId,
        alertOperation: 'ABOVE',
    }
}

const getAbsoluteCosumptionSaveData = ({ period, value, groupId, subgroup, ...alert }, groups, category) => {
    const foundGroup = groups.find(({ id }) => id === groupId);
    const foundSubgroup = foundGroup.subGroups.find(({ id }) => id === subgroup);
    const subSubGroup = subgroup && foundSubgroup.subGroups[0];

    const foundOption = absoluteConsumptionPeriods().find(data => data.value === period);

    return {
        ...alert,
        category,
        alertValue: value,
        ...foundOption ?  {
            alertQuantity: foundOption.alertQuantity,
            alertGranularity: foundOption.alertGranularity,
            triggerQuantity: foundOption.alertQuantity,
            triggerGranularity: foundOption.alertGranularity,
        } : {},
        groupId: alert.sensorId ? subSubGroup.id : subgroup ? subgroup : groupId,
        alertOperation: 'ABOVE',
    }
}

const getAbsolutePowerSaveData = ({ period, value, notificationType, ...alert }, groups, category) => {

    const foundOption = absoluteConsumptionPeriods().find(data => data.value === period);

    const notification = notificationType || 'NONE';

    return {
        ...alert,
        category,
        alertValue: value,
        ...foundOption ?  {
            alertQuantity: foundOption.alertQuantity,
            alertGranularity: foundOption.alertGranularity,
            triggerQuantity: foundOption.alertQuantity,
            triggerGranularity: foundOption.alertGranularity,
        } : {},
        alertOperation: 'ABOVE',
        notificationType: notification
    }
}

class AlertAdd extends PureComponent {
    constructor (props) {
        super(props);

        const alert = props.editAlert;

        this.state = {
            ...initialState,
            mode: alert && alert.id && alert.id > 0 ? modes.edit : modes.add,
        };
    }

    componentWillMount = () => {
        if (this.props.category === 'ABSOLUTE_ACTIVE_POWER') {
            return API.SENSORS.GET().then(({data}) => {
                if (data && data.length > 0){
                    if (this.props.editAlert && this.props.editAlert.id) {
                        this.setEditableAlert(data)
                    }

                    this.setState({
                        sensors: this.getSensorsList(data),
                        loadingSensors: false,
                        loadingGroups: false,
                    }, this.checkGeneralChangesOnComponent);
                }else if (data && data.length === 0){
                    this.setState({
                        loadingSensors: false,
                        loadingGroups: false,
                        noDataChartMsg: this.noSensorChartMessage,
                    });
                }else{
                    this.handleLoadError({loadingSensors: false, loadingGroups: false}, i18n.t("Could not load sensors data"))
                }
            }).catch((error) => {
                this.handleLoadError({loadingSensors: false, loadingGroups: false}, i18n.t("Could not load sensors data"), error)
            });
        }


        API.GROUPS.GET().then(({ data }) => {
            if (data && data.length) {
                if (this.props.editAlert && this.props.editAlert.id) {
                    this.setEditableAlert(data)
                }

                return this.setState({
                    loadingGroups: false,
                    groups: data.map(group => ({
                        ...group,
                        label: group.name,
                        value: group.id
                    }))
                })
            }

            this.setState({
                loadingGroups: false
            })
        }).catch(error => this.handleLoadError(
            { loadingGroups: false },
            i18n.t('Could not load tariffs.'),
            error
        ));
    }

    loadErrorMessage = i18n.t("An error occurred while retrieving data.");

    WINDOW_TITLE = {
        RELATIVE_CONSUMPTION_ADD: i18n.t('New Relative Consumption Alert'),
        RELATIVE_CONSUMPTION_EDIT: i18n.t('Edit Relative Consumption Alert'),
        ABSOLUTE_CONSUMPTION_ADD: i18n.t('New Absolute Consumption Alert'),
        ABSOLUTE_CONSUMPTION_EDIT: i18n.t('Edit Absolute Consumption Alert'),
        ENTITY_STATUS_ADD: i18n.t('New Equipment Status Alert'),
        ENTITY_STATUS_EDIT: i18n.t('Edit Equipment Status Alert'),
        ABSOLUTE_COST_ADD: i18n.t('New Cost Alert'),
        ABSOLUTE_COST_EDIT: i18n.t('Edit Cost Alert'),
        ABSOLUTE_ACTIVE_POWER_ADD: i18n.t('New Power Alert'),
        ABSOLUTE_ACTIVE_POWER_EDIT: i18n.t('Edit Power Alert'),
    }

    getSensorsList = (sensors) => {
        let sensorsList = [];

        if (sensors && sensors.length){
            // if the sensor has only one channel (phase), won't be displayed as multiphase,
            // only as sensor-phase, on sensorPhasesList below
            sensorsList = sensors.filter(sensor => sensor.channels.length > 1).map(sensor => ({
                ...sensor.channels[0],
                ...getMultiphaseSensorStructured(sensor),
            }));

            const sensorPhasesList = sensors.reduce((list, sensor) => {
                if (sensor.channels && sensor.channels.length){
                    const sensorPhases = sensor.channels.map(sensor => ({
                        ...sensor,
                        ...getSensorStructured(sensor),
                        label: this.getBreadcrumbsTrailFromHierarchy(sensor)
                    }));

                    list = [...list, ...sensorPhases];
                }
                return list;
            }, []);

            sensorsList = [...sensorsList, ...sensorPhasesList];
        }

        return sensorsList;
    }

    getBreadcrumbsTrailFromHierarchy = (sensor) => {
        const errorMessage = i18n.t("Unknown group/sensor");
        let error = false;

        let breadcrumbsText = '';
        if (sensor) {
            if (sensor.parentGroupName)
                breadcrumbsText = sensor.parentGroupName;
            if (sensor.groupName)
                if (sensor.parentGroupName)
                    breadcrumbsText += '/'
                breadcrumbsText += sensor.groupName ? sensor.groupName : '';
            if (sensor.sensorId && sensor.channel){
                breadcrumbsText += (breadcrumbsText ? '/' : '') + sensor.sensorId + '-' + sensor.channel;
                if (sensor.sensorChannelName)
                    breadcrumbsText += ' - ' + sensor.sensorChannelName;
            }
            if (breadcrumbsText === '')
                error = true;
        } else {
            error = true;
        }

        if (error) {
            breadcrumbsText = errorMessage;
            console.warn("A wrong breadcrumb format was received and no breadcrumb text could be created for Alerts Events list");
        }

        return breadcrumbsText;
    }

    setEditableAlert = async groups => {
        const { editAlert } = this.props
        if (!editAlert || !editAlert.id) {
            return
        }

        const foundGranul = granularities().find(data =>
            data.alertQuantity === editAlert.alertQuantity &&
            data.alertGranularity === editAlert.alertGranularity
        )
        const foundPeriod = alertPeriods().find(period =>
            period.alertPeriodQuantity === editAlert.alertPeriodQuantity &&
            period.alertPeriodGranularity === editAlert.alertPeriodGranularity
        )

        let foundGroup = this.props.category !== 'ABSOLUTE_ACTIVE_POWER' && groups.find(group => (
            !!editAlert.breadCrumbs.parentGroupName &&
            !!group.subGroups.some(subgroup => editAlert.sensorId
                ? subgroup.subGroups.some(({ id }) => id === editAlert.groupId)
                : subgroup.id === editAlert.groupId
            )
        ) || group.id === editAlert.groupId)

        const foundSubgroup = foundGroup && !!editAlert.breadCrumbs.parentGroupName &&
            foundGroup.subGroups.find(subgroup => editAlert.sensorId
                ? subgroup.subGroups.some(({ id }) => id === editAlert.groupId)
                : subgroup.id === editAlert.groupId
            )

        const alert = alertCategoriesVars[this.props.category];

        if (this.props.category === "ENTITY_STATUS"){
            editAlert.option = editAlert.alertOperation === 'ABOVE' ? i18n.t('on') : i18n.t('off');
            editAlert.notificationType =  editAlert.notificationType === "NONE" ? '' : editAlert.notificationType;
            editAlert.executionStartTime = editAlert.executionStartTime
                ? startHoursOptions({}).find(({ value }) =>
                    value === moment(editAlert.executionStartTime, 'HH:mm:ss').format('HH:mm')
                ) : null;
            editAlert.executionEndTime = editAlert.executionEndTime
                ? endHoursOptions({}).find(({ value }) =>
                    value === moment(editAlert.executionEndTime, 'HH:mm:ss').format('HH:mm')
                ) : null;
        }

        if (this.props.category === "ABSOLUTE_COST"){
            editAlert.period = absoluteCostPeriods().find(data =>
                data.alertQuantity === editAlert.alertQuantity &&
                data.alertGranularity === editAlert.alertGranularity
            )
            editAlert.value = editAlert.alertValue;

            if (editAlert && editAlert.sensorId && editAlert.channel){
                await this.getCurrencyCodeByGroupId(foundGroup.id);
                await this.getCurrencyCodeBySubgroupId(foundSubgroup.id);
                await this.getCurrencyCodeBySensorPhase(editAlert.sensorId, editAlert.channel);
            }else if (foundSubgroup && foundSubgroup.id){
                await this.getCurrencyCodeByGroupId(foundGroup.id);
                await this.getCurrencyCodeBySubgroupId(foundSubgroup.id);
            }else if (foundGroup && foundGroup.id){
                await this.getCurrencyCodeByGroupId(foundGroup.id);
            }
        }

        if (this.props.category === "ABSOLUTE_CONSUMPTION"){
            editAlert.period = absoluteConsumptionPeriods().find(data =>
                data.alertQuantity === editAlert.alertQuantity &&
                data.alertGranularity === editAlert.alertGranularity
            )
            editAlert.value = editAlert.alertValue;
        }

        if (this.props.category === "ABSOLUTE_ACTIVE_POWER") {
            foundGroup = { id: editAlert.groupId };

            editAlert.period = powerPeakPeriods().find(data =>
                data.alertQuantity === editAlert.alertQuantity &&
                data.alertGranularity === editAlert.alertGranularity
            )
            editAlert.value = editAlert.alertValue;
        }

        this.setState({
            [alert]: {
                ...this.state[alert],
                ...editAlert,
                recipients: editAlert.recipients && editAlert.recipients.length ? editAlert.recipients : [],
                granularity: foundGranul ? foundGranul.value : null,
                alertPeriodQuantity: (foundPeriod && this.props.category === 'RELATIVE_CONSUMPTION')
                    ? foundPeriod.value
                    : editAlert.alertPeriodQuantity && this.props.category !== 'RELATIVE_CONSUMPTION'
                        ? editAlert.alertPeriodQuantity
                        : null,
                groupId: foundGroup.id,
                subgroup: foundSubgroup.id,
                sensorId: editAlert.sensorId,
                sensorKey: editAlert.sensorId && editAlert.channel
                    ? `${editAlert.sensorId}-${editAlert.channel}`
                    : editAlert.sensorId ? editAlert.sensorId : null
            }
        })
    }

    handleLoadError = (state, message, error) => {
        state.loadErrorMessage = this.loadErrorMessage;
        this.setState(state);

        console.error(this.loadErrorMessage + " (" + message + ")");
        if (error)
            console.error(error);
    }

    reset = () => this.setState(initialState)

    back = () => this.setState({
        errorMessage: ''
    })

    toggleAddEmail = () => this.setState({
        recipient: '',
        fieldsWithError: {},
        addingEmail: !this.state.addingEmail
    })

    changeRecipient = ({ target }) => this.setState({
        recipient: target.value
    })

    change = ({ target }) => {
        const stateVarToUpdate = alertCategoriesVars[this.props.category];

        this.setState({
            [stateVarToUpdate]: {
                ...this.state[stateVarToUpdate],
                [target.name]: target.value,
            }
        });
    }

    changeGroup = group => {
        const stateVarToUpdate = alertCategoriesVars[this.props.category];

        this.setState({
            [stateVarToUpdate]: {
                ...this.state[stateVarToUpdate],
                groupId: group ? group.value : null,
                subgroup: null,
                channel: null,
                sensorId: null,
                sensorKey: null
            }
        });

        if (this.props.category === "ABSOLUTE_COST"){
            this.getCurrencyCodeByGroupId(group && group.value);
        }
    }

    changeSubgroup = subgroup => {
        const stateVarToUpdate = alertCategoriesVars[this.props.category];

        this.setState({
            [stateVarToUpdate]: {
                ...this.state[stateVarToUpdate],
                subgroup: subgroup ? subgroup.id : null,
                sensorId: null,
                sensorKey: null,
                channel: null
            }
        });

        if (this.props.category === "ABSOLUTE_COST"){
            this.getCurrencyCodeBySubgroupId(subgroup && subgroup.id);
        }
    }

    changeSensor = sensor => {
        const stateVarToUpdate = alertCategoriesVars[this.props.category];

        this.setState({
            [stateVarToUpdate]: {
                ...this.state[stateVarToUpdate],
                sensorId: sensor ? sensor.id : null,
                sensorKey: sensor ? sensor.key : null,
                channel: sensor ? sensor.channel : null
            }
        });

        if (this.props.category === "ABSOLUTE_COST"){
            this.getCurrencyCodeBySensorPhase(sensor && sensor.id, sensor && sensor.channel);
        }
    }

    changePowerSensor = sensor => {
        const stateVarToUpdate = alertCategoriesVars[this.props.category];

        this.setState({
            [stateVarToUpdate]: {
                ...this.state[stateVarToUpdate],
                groupId: sensor && sensor.groupId ? sensor.groupId : null,
                sensorId: sensor ? sensor.id : null,
                sensorKey: sensor ? sensor.key : null,
                channel: sensor ? sensor.channel : null
            }
        });
    }

    getCurrencyCodeByGroupId = (groupId) => {
        this.setState({
            groupCurrencyCode: null,
            subgroupCurrencyCode: null,
            sensorCurrencyCode: null,
        });

        if (groupId){
            this.setState({
                loadingCurrency: true,
            });

            API.TARIFFS.CURRENCY.GROUP(groupId).then(({data}) => {
                this.setState({
                    groupCurrencyCode: groupId && data && data.currency ? data.currency : null,
                    subgroupCurrencyCode: null,
                    sensorCurrencyCode: null,
                    loadingCurrency: false,
                });
            }).catch(error => {
                console.error("Error while fetching currency of a group. The default currency symbol will be used.");
                this.setState({
                    loadingCurrency: false,
                });
            });
        }
    }

    getCurrencyCodeBySubgroupId = (subgroupId) => {
        this.setState({
            subgroupCurrencyCode: null,
            sensorCurrencyCode: null,
        });

        if (subgroupId){
            this.setState({
                loadingCurrency: true,
            });

            API.TARIFFS.CURRENCY.GROUP(subgroupId).then(({data}) => {
                this.setState({
                    subgroupCurrencyCode: subgroupId && data && data.currency ? data.currency : null,
                    sensorCurrencyCode: null,
                    loadingCurrency: false,
                });
            }).catch(error => {
                console.error("Error while fetching currency of a group. The default currency symbol will be used.");
                this.setState({
                    loadingCurrency: false,
                });
            });
        }
    }

    getCurrencyCodeBySensorPhase = (sensorId, sensorChannel) => {
        this.setState({
            sensorCurrencyCode: null,
        });

        if (sensorId && sensorChannel){
            this.setState({
                loadingCurrency: true,
            });

            API.TARIFFS.CURRENCY.SENSOR(sensorId, sensorChannel).then(({data}) => {
                this.setState({
                    sensorCurrencyCode: sensorId && sensorChannel && data && data.currency ? data.currency : null,
                    loadingCurrency: false,
                });
            }).catch(error => {
                console.error("Error while fetching currency of a sensor. The default currency symbol will be used.");
                this.setState({
                    loadingCurrency: false,
                });
            });
        }
    }

    checkAddButtonDisabled = () => {
        if (this.props.category !== "ABSOLUTE_COST")
            return false;

        return this.checkNoCurrencies();
    }

    checkNoCurrencies = () => {
        const {groupCurrencyCode, subgroupCurrencyCode, sensorCurrencyCode} = this.state;

        return (!groupCurrencyCode && !subgroupCurrencyCode && !sensorCurrencyCode) ? true : false;
    }

    checkEntitiesLackOfTariff = () => {
        const {groupId, subgroup, sensorId} = this.state.absoluteCostAlert;
        return (this.props.category === "ABSOLUTE_COST" && (sensorId || subgroup || groupId) && !this.state.loadingCurrency && this.checkNoCurrencies()) ? true : false;
    }

    handleAlertTypeChange = (event, value) => this.setState({
        alert: {
            ...this.state.alert,
            alertType: value
        }
    })

    handleNotificationTypeChange = (event, value) => {
        if (this.props.category === "RELATIVE_CONSUMPTION"){
            this.setState({
                alert: {
                    ...this.state.alert,
                    notificationType: value,
                }
            });
        }
        if (this.props.category === "ABSOLUTE_CONSUMPTION"){
            this.setState({
                absoluteConsumptionAlert: {
                    ...this.state.absoluteConsumptionAlert,
                    notificationType: value,
                }
            });
        }
        if (this.props.category === "ENTITY_STATUS"){
            this.setState({
                entityStatusAlert: {
                    ...this.state.entityStatusAlert,
                    notificationType: (value) ? 'EMAIL' : '',
                }
            });
        }
        if (this.props.category === "ABSOLUTE_COST"){
            this.setState({
                absoluteCostAlert: {
                    ...this.state.absoluteCostAlert,
                    notificationType: value,
                }
            });
        }
        if (this.props.category === "ABSOLUTE_ACTIVE_POWER"){
            this.setState({
                absolutePowerAlert: {
                    ...this.state.absolutePowerAlert,
                    notificationType: (value) ? 'EMAIL' : '',
                }
            });
        }
    }

    changeGranularity = granularity => granularity && granularity.value && this.setState({
        alert: {
            ...this.state.alert,
            alertPeriodQuantity: null,
            granularity: granularity.value
        }
    })

    changeOperation = alertOperation => alertOperation && alertOperation.value && this.setState({
        alert: {
            ...this.state.alert,
            alertOperation: alertOperation.value
        }
    })

    changePeriodQtd = alertPeriodQuantity => alertPeriodQuantity && alertPeriodQuantity.value && this.setState({
        alert: {
            ...this.state.alert,
            alertPeriodQuantity: alertPeriodQuantity.value

        }
    })

    changeEntityStatusOption = (option) => {
        if (option && option.value){
            this.setState({
                entityStatusAlert: {
                    ...this.state.entityStatusAlert,
                    option: option.value,
                }
            });
        }
    }

    changeAbsoluteConsumptionValue = ({target}) => this.setState({
        absoluteConsumptionAlert: {
            ...this.state.absoluteConsumptionAlert,
            value: target.value,
        }
    })

    changeAbsoluteConsumptionPeriod = (option) => {
        if (option && option.value){
            this.setState({
                absoluteConsumptionAlert: {
                    ...this.state.absoluteConsumptionAlert,
                    period: option.value,
                }
            });
        }
    }

    changeAbsoluteCostValue = ({target}) => this.setState({
        absoluteCostAlert: {
            ...this.state.absoluteCostAlert,
            value: target.value,
        }
    })

    changeAbsoluteCostPeriod = (option) => {
        if (option && option.value){
            this.setState({
                absoluteCostAlert: {
                    ...this.state.absoluteCostAlert,
                    period: option.value,
                }
            });
        }
    }

    changePowerPeakValue = ({target}) => this.setState({
        absolutePowerAlert: {
            ...this.state.absolutePowerAlert,
            value: target.value,
        }
    })

    changePowerPeakPeriod = (option) => {
        if (option && option.value){
            this.setState({
                absolutePowerAlert: {
                    ...this.state.absolutePowerAlert,
                    period: option.value,
                }
            });
        }
    }

    changeStartTime = option => this.setState({
        entityStatusAlert: {
            ...this.state.entityStatusAlert,
            executionStartTime: option,
            executionEndTime: null
        }
    })

    changeEndTime = option => this.setState({
        entityStatusAlert: {
            ...this.state.entityStatusAlert,
            executionEndTime: option
        }
    })

    removeRecipient = index => {
        const stateVarToUpdate = alertCategoriesVars[this.props.category];

        this.setState({
            [stateVarToUpdate]: {
                ...this.state[stateVarToUpdate],
                recipients: this.state[stateVarToUpdate].recipients.filter((email, i) => i !== index)
            }
        });
    }

    addRecipient = recipient => {
        const stateVarToUpdate = alertCategoriesVars[this.props.category];

        this.setState({
            addingEmail: false,
            [stateVarToUpdate]: {
                ...this.state[stateVarToUpdate],
                recipients: [
                    ...this.state[stateVarToUpdate].recipients,
                    recipient
                ]
            }
        });
    }

    scrollIntoView = (elem) => {
        elem.scrollIntoView ?
            elem.scrollIntoView({behavior: 'smooth'}) :
            document.getElementById('add-group').parentElement.scrollTop = 0;
    }

    isValid = () => {
        const fieldsWithError = {};

        const stateVarToCheck = alertCategoriesVars[this.props.category];
        const alert = this.state[stateVarToCheck];

        if (!alert.name) {
            fieldsWithError.name = i18n.t('Is required');
        } else if (alert.name.length > 50) {
            fieldsWithError.name = i18n.t('Name is too long');
        }

        if (!alert.groupId && this.props.category !== 'ABSOLUTE_ACTIVE_POWER') {
            fieldsWithError.groupId = i18n.t('Is required');
        }

        if (!alert.sensorKey && this.props.category === 'ABSOLUTE_ACTIVE_POWER') {
            fieldsWithError.sensorKey = i18n.t('Is required');
        }

        if (this.props.category === "RELATIVE_CONSUMPTION"){
            if (!alert.granularity) {
                fieldsWithError.granularity = i18n.t('Is required');
            }

            if (alert.alertValue === null || typeof alert.alertValue === 'undefined' || alert.alertValue === '') {
                fieldsWithError.alertValue = i18n.t('Is required');
            }

            if (!alert.alertOperation) {
                fieldsWithError.alertOperation = i18n.t('Is required');
            }

            if (!alert.alertPeriodQuantity) {
                fieldsWithError.alertPeriodQuantity = i18n.t('Is required');
            }
        }

        if (this.props.category === "ABSOLUTE_CONSUMPTION"){
            if (!alert.period) {
                fieldsWithError.absoluteConsumptionPeriod = i18n.t('Is required');
            }

            if (!alert.value) {
                fieldsWithError.absoluteConsumptionValue = i18n.t('Is required');
            }
        }

        if (this.props.category === "ENTITY_STATUS"){
            if (!alert.option){
                fieldsWithError.entityStatusOption = i18n.t('Is required');
            }

            if (alert.executionStartTime && !alert.executionEndTime) {
                fieldsWithError.executionEndTime = i18n.t('Is required');
            }
        }

        if (this.props.category === "ABSOLUTE_COST"){
            if (!alert.period) {
                fieldsWithError.absoluteCostPeriod = i18n.t('Is required');
            }

            if (!alert.value) {
                fieldsWithError.absoluteCostValue = i18n.t('Is required');
            }
        }

        if (this.props.category === "ABSOLUTE_ACTIVE_POWER"){
            if (!alert.period) {
                fieldsWithError.powerPeakPeriod = i18n.t('Is required');
            }

            if (!alert.value) {
                fieldsWithError.powerPeakValue = i18n.t('Is required');
            }
        }

        this.setState({
            fieldsWithError: fieldsWithError,
            loading: false
        });

        return !Object.keys(fieldsWithError).length;
    }

    send = () => {
        const { alert, groups, entityStatusAlert, absoluteCostAlert, absoluteConsumptionAlert, absolutePowerAlert } = this.state
        let action = "ADD";

        let saveData = {};

        if (this.props.category === "RELATIVE_CONSUMPTION"){
            saveData = getSaveData(alert, groups, this.props.category);
            action = alert.id ? 'EDIT' : 'ADD';
        }

        if (this.props.category === "ENTITY_STATUS"){
            saveData = getEntityStatusSaveData(entityStatusAlert, groups, this.props.category);
            action = entityStatusAlert.id ? 'EDIT' : 'ADD';
        }

        if (this.props.category === "ABSOLUTE_COST"){
            saveData = getAbsoluteCostSaveData(absoluteCostAlert, groups, this.props.category);
            action = absoluteCostAlert.id ? 'EDIT' : 'ADD';
        }

        if (this.props.category === "ABSOLUTE_CONSUMPTION"){
            saveData = getAbsoluteCosumptionSaveData(absoluteConsumptionAlert, groups, this.props.category);
            action = absoluteConsumptionAlert.id ? 'EDIT' : 'ADD';
        }

        if (this.props.category === "ABSOLUTE_ACTIVE_POWER"){
            saveData = getAbsolutePowerSaveData(absolutePowerAlert, groups, this.props.category);
            action = absolutePowerAlert.id ? 'EDIT' : 'ADD';
        }

        API.ALERTS[action](saveData).then(response => {
            this.openSnackBar(action === 'EDIT'
                ? i18n.t('Alert saved successfully')
                : i18n.t('Alert created successfully')
            , 'success');

            this.props.handleCloseModal(true);
            this.setState(initialState);
        }).catch(error => {
            this.setState({ loading: false });
            this.openSnackBar(i18n.t('An error occurred while creating the alert.'), 'error');
        })
    }

    submit = (e) => {
        e.preventDefault();
        e.target.querySelector('button[type="submit"]').blur();

        this.setState({
            loading: true,
            feedbackMessage: '',
            errorMessage: '',
            loadErrorMessage: ''
        });

        if (this.isValid()) {
            this.send()
        }
    }

    openSnackBar = (message, type) => this.setState({
        showSnackBar: true,
        snackbar: {
            message,
            type
        }
    })

    closeSnackBar = () => this.setState({
        showSnackBar: false
    })

    getWindowTitle = () => {
        let windowTitle = '';
        switch (this.props.category) {
            case 'RELATIVE_CONSUMPTION':
                windowTitle = !!this.props.editAlert ? this.WINDOW_TITLE.RELATIVE_CONSUMPTION_EDIT : this.WINDOW_TITLE.RELATIVE_CONSUMPTION_ADD;
                break;
            case 'ABSOLUTE_CONSUMPTION':
                windowTitle = !!this.props.editAlert ? this.WINDOW_TITLE.ABSOLUTE_CONSUMPTION_EDIT : this.WINDOW_TITLE.ABSOLUTE_CONSUMPTION_ADD;
                break;
            case 'ENTITY_STATUS':
                windowTitle = !!this.props.editAlert ? this.WINDOW_TITLE.ENTITY_STATUS_EDIT : this.WINDOW_TITLE.ENTITY_STATUS_ADD;
                break;
            case 'ABSOLUTE_COST':
                windowTitle = !!this.props.editAlert ? this.WINDOW_TITLE.ABSOLUTE_COST_EDIT : this.WINDOW_TITLE.ABSOLUTE_COST_ADD;
                break;
            case 'ABSOLUTE_ACTIVE_POWER':
                windowTitle = !!this.props.editAlert ? this.WINDOW_TITLE.ABSOLUTE_ACTIVE_POWER_EDIT : this.WINDOW_TITLE.ABSOLUTE_ACTIVE_POWER_ADD;
                break;
            default:
                windowTitle = !!this.props.editAlert ? i18n.t('Edit Alert') : i18n.t('New Alert')
        }
        return windowTitle;
    }

    render () {
        const { classes, category } = this.props
        const {
            fieldsWithError,
            groups,
            loading,
            loadingGroups,
            mode,
            alert,
            showSnackBar,
            snackbar,
            submitting,
            entityStatusAlert,
            absoluteConsumptionAlert,
            absoluteCostAlert,
            absolutePowerAlert,
            groupCurrencyCode,
            subgroupCurrencyCode,
            sensorCurrencyCode,
        } = this.state

        const categoryState = this.state[alertCategoriesVars[category]];
        const foundGroup = groups.find(group => group.id === categoryState.groupId)
        const subgroups = foundGroup ? foundGroup.subGroups : []

        const foundSubgroup = foundGroup && foundGroup.subGroups.find(group => group.id === categoryState.subgroup)
        const sensors = foundSubgroup ? foundSubgroup.subGroups.reduce((prev, curr) => [
            ...prev,
            ...curr.sensors ? curr.sensors : [],
        ], []) : []

        return (
            <section id="add-alert">
                <Title
                    element="h4"
                    text={this.getWindowTitle()}
                />

                <form action="#" onSubmit={this.submit}>
                    <div ref={element => this.formDiv = element} className="form-content">
                        <div className="form-sections">
                            <VInput change={this.change}
                                disabled={loading}
                                hasError={fieldsWithError.name}
                                value={categoryState.name}
                                name="name"
                                placeholder={i18n.t("Alert Name")}
                                type="text"
                                required
                            />
                            {this.props.category !== 'ABSOLUTE_ACTIVE_POWER' && (
                                <div className="filters">
                                    <CustomSelect
                                        name="groupId"
                                        value={categoryState.groupId}
                                        onChange={this.changeGroup}
                                        disabled={loadingGroups || !groups.length}
                                        isLoading={loadingGroups}
                                        className={fieldsWithError.groupId ? 'error' : null}
                                        hasError={fieldsWithError.groupId}
                                        noExtremitiesMargins={true}
                                        valueKey="value"
                                        labelKey="label"
                                        options={groups}
                                        placeholder={i18n.t("Group")}
                                    />

                                    <CustomSelect
                                        name="subgroup"
                                        value={categoryState.subgroup}
                                        onChange={this.changeSubgroup}
                                        disabled={!categoryState.groupId || !subgroups.length}
                                        isLoading={loadingGroups}
                                        noExtremitiesMargins={true}
                                        valueKey="id"
                                        labelKey="name"
                                        options={subgroups}
                                        placeholder={i18n.t("Subgroup - Optional")}
                                    />

                                    <CustomSelect
                                        name="sensorKey"
                                        value={categoryState.sensorKey}
                                        onChange={this.changeSensor}
                                        disabled={!categoryState.subgroup || !sensors.length}
                                        isLoading={loadingGroups}
                                        noExtremitiesMargins={true}
                                        valueKey="key"
                                        labelKey="label"
                                        options={sensors.map(getSensorStructured)}
                                        placeholder={i18n.t("Sensor - Optional")}
                                    />
                                </div>
                            )}
                            {this.props.category === 'ABSOLUTE_ACTIVE_POWER' && (
                                <div className="filters">
                                    <CustomSelect
                                        name="sensorKey"
                                        value={categoryState.sensorKey}
                                        onChange={this.changePowerSensor}
                                        disabled={!this.state.sensors.length}
                                        isLoading={loadingGroups}
                                        noExtremitiesMargins={true}
                                        className={fieldsWithError.sensorKey ? 'error' : null}
                                        hasError={fieldsWithError.sensorKey}
                                        valueKey="key"
                                        labelKey="label"
                                        options={this.state.sensors}
                                        placeholder={i18n.t("Select any sensor-phase (type to search)")}
                                    />
                                </div>
                            )}
                        </div>

                        {(this.props.category === 'ENTITY_STATUS') ?
                            <EntityStatusSection
                                value={entityStatusAlert.option}
                                changeEntityStatusOption={this.changeEntityStatusOption}
                                fieldsWithError={fieldsWithError}
                                entityStatusOptions={entityStatusOptions()}
                                executionStartTime={entityStatusAlert.executionStartTime}
                                executionEndTime={entityStatusAlert.executionEndTime}
                                changeStartTime={this.changeStartTime}
                                changeEndTime={this.changeEndTime}
                            />
                        : null}

                        {(this.props.category === 'RELATIVE_CONSUMPTION') ?
                            <RelativeConsumptionSection
                                loading={loading}
                                classes={classes}
                                fieldsWithError={fieldsWithError}
                                alert={alert}
                                handleAlertTypeChange={this.handleAlertTypeChange}
                                change={this.change}
                                changeGranularity={this.changeGranularity}
                                changeOperation={this.changeOperation}
                                changePeriodQtd={this.changePeriodQtd}
                            />
                        : null}

                        {(this.props.category === 'ABSOLUTE_CONSUMPTION') ?
                            <AbsoluteConsumptionSection
                                loading={loading}
                                // classes={classes}
                                absoluteConsumptionAlert={absoluteConsumptionAlert}
                                absoluteConsumptionPeriods={absoluteConsumptionPeriods()}
                                fieldsWithError={fieldsWithError}
                                changeAbsoluteConsumptionPeriod={this.changeAbsoluteConsumptionPeriod}
                                changeAbsoluteConsumptionValue={this.changeAbsoluteConsumptionValue}
                            />
                        : null}

                        {(this.props.category === 'ABSOLUTE_COST') ?
                            <AbsoluteCostSection
                                loading={loading}
                                // classes={classes}
                                absoluteCostAlert={absoluteCostAlert}
                                absoluteCostPeriods={absoluteCostPeriods()}
                                fieldsWithError={fieldsWithError}
                                changeAbsoluteCostPeriod={this.changeAbsoluteCostPeriod}
                                changeAbsoluteCostValue={this.changeAbsoluteCostValue}
                                currencyCode={sensorCurrencyCode || subgroupCurrencyCode || groupCurrencyCode}
                            />
                        : null}

                        {(this.props.category === 'ABSOLUTE_ACTIVE_POWER') ?
                            <PowerPeakSection
                                loading={loading}
                                // classes={classes}
                                absolutePowerAlert={absolutePowerAlert}
                                powerPeakPeriods={powerPeakPeriods()}
                                fieldsWithError={fieldsWithError}
                                changePowerPeakValue={this.changePowerPeakValue}
                                changePowerPeakPeriod={this.changePowerPeakPeriod}
                            />
                        : null}

                        <div className="notification-content">
                            <div className="form-sections">
                                <Title
                                    element="h6"
                                    text={i18n.t("Notifications:")}
                                />
                                <FormControl component="fieldset" className={classes.formControl} required >
                                    {(this.props.category === 'RELATIVE_CONSUMPTION' || this.props.category === 'ABSOLUTE_CONSUMPTION' || this.props.category === 'ABSOLUTE_COST') ?
                                        <RadioGroup
                                            aria-label="notificationType"
                                            name="notificationType"
                                            value={categoryState.notificationType}
                                            onChange={this.handleNotificationTypeChange}
                                        >
                                            <FormControlLabel className={classes.radioContent} value="EMAIL" control={<Radio className={classes.radio} />} label={i18n.t("By email")} />
                                            <FormControlLabel className={classes.radioContent} value="GRAPH" control={<Radio className={classes.radio} />} label={i18n.t("On the graph")} />
                                            <FormControlLabel className={classes.radioContent} value="ALL" control={<Radio className={classes.radio} />} label={i18n.t("By email and on the graph")} />
                                        </RadioGroup>
                                    : null}

                                    {(this.props.category === 'ENTITY_STATUS' || this.props.category === 'ABSOLUTE_ACTIVE_POWER' ) ?
                                        <FormGroup>
                                            <FormControlLabel className={classes.radioContent} value="EMAIL"
                                                control={
                                                    <Checkbox
                                                        className={classes.radio}
                                                        onChange={this.handleNotificationTypeChange}
                                                        checked={categoryState.notificationType.includes('EMAIL')}
                                                    />
                                                }
                                                label={i18n.t("By email")}
                                            />
                                        </FormGroup>
                                    : null}
                                </FormControl>
                            </div>
                            <div className="form-sections">
                                <div className="recipient-header">
                                    <Title
                                        element="h6"
                                        text={i18n.t("Recipients")}
                                    />
                                    <div onClick={this.toggleAddEmail}>
                                        <AddIcon />
                                        <Typography variant="body2" classes={{body2: classes.createAlertButtonText}}>
                                            {i18n.t('ADD E-MAIL')}
                                        </Typography>
                                    </div>
                                </div>

                                {!categoryState.recipients.length && (
                                    <Typography
                                        variant="title"
                                        color="textSecondary"
                                        classes={{title: classes.typographyNoRecipients}}
                                    >
                                        {i18n.t('There are no recipients added')}
                                    </Typography>
                                )}

                                {!!categoryState.recipients.length && (
                                    <div className="recipients-content">
                                        {categoryState.recipients.map((email, index) => (
                                            <div key={email} className="recipient">
                                                {email}
                                                <CloseIcon
                                                    onClick={() => this.removeRecipient(index)}
                                                    style={closeButtonStyle}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                    <div className="buttons-content">
                        <Button
                            action="preview"
                            label={i18n.t("CANCEL")}
                            click={this.props.handleCloseModal}
                        />

                        <Button
                            type="submit"
                            action="submit"
                            disabled={submitting}
                            nonLoadingDisabled={this.checkAddButtonDisabled()}
                            label={(mode === modes.edit) ? i18n.t('Save Alert') : i18n.t('Add Alert')}
                        />
                    </div>
                </form>
                <SystemSnackbar
                    open={showSnackBar}
                    message={snackbar.message}
                    onClose={this.closeSnackBar}
                    variant={snackbar.type}
                />
                <SystemSnackbar
                    open={this.checkEntitiesLackOfTariff()}
                    message={i18n.t('The group or sensor selected has no tariff set. Please set a tariff for it on Configuration > Group to create cost alerts.')}
                    variant='warning' />
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.addingEmail}
                    onClose={this.toggleAddEmail}
                    disableBackdropClick={true}>

                    <div style={modalStyle} className={classes.paper}>
                        <CloseIcon onClick={this.toggleAddEmail} style={closeModalButtonStyle} />
                        <AlertAddRecipient
                            recipients={categoryState.recipients}
                            cancel={this.toggleAddEmail}
                            addRecipient={this.addRecipient}
                        />
                    </div>
                </Modal>
            </section>
        );
    }
}

AlertAdd.propTypes = {
    intl: intlShape.isRequired,
    category: PropTypes.oneOf(['RELATIVE_CONSUMPTION', 'ABSOLUTE_CONSUMPTION', 'ABSOLUTE_COST', 'ENTITY_STATUS', 'ABSOLUTE_ACTIVE_POWER']).isRequired,
};

export default withStyles(styles)(injectIntl(AlertAdd));
