import React from 'react'
import {DayPickerRangeController} from 'react-dates';
import moment from 'moment-timezone';
import Select from 'react-select';
import {utcYears} from 'd3-time'

import Button from 'material-ui/Button';
import {Text} from '../../elements/text';
import MonthPicker from '../../elements/MonthPicker/MonthPicker';
import KeyboardArrowLeftIcon from 'material-ui-icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from 'material-ui-icons/KeyboardArrowRight';
import { adjustJSONToValidJSON } from '../../components/utils';

import PropTypes from 'prop-types';

import i18n from '../../i18n';

import 'react-dates/lib/css/_datepicker.css';
import './filter-date-picker.css';

const years = utcYears(moment('2018-01-01'), moment().add(1, 'year')).map(date => ({ value: moment(date).format('YYYY'), label: moment(date).format('YYYY') }));

class DatePicker extends React.Component {

    setDatePickerNodeRef = (ref) => {
        this.datePickerNode = ref;
    }

    setDateRangePickerNodeRef = (ref) => {
        this.dateRangePickerNode = ref;
    }

    datePickerPeriodButton = () => this.props.periodFilter === 'day' ? i18n.t('Today') : `${i18n.t('This {{period}}', {period: i18n.t(this.props.periodFilter)})}`;

    inputDateFormatted = () => {
        if (!this.props.datePicker.startDate)
            return '';

        switch (this.props.periodFilter){
            case 'month':
                return this.props.datePicker.startDate.format('[LMMMMYYYY]');
            case 'week':
                return this.props.datePicker.endDate.format('[LWYYYY]');
            default:
                return this.props.datePicker.startDate.format('[llll-a]');
        }
    }

    inputRangeDateFormatted = () => {
            if (!this.props.dateRangePicker.startDate || !this.props.dateRangePicker.endDate){
                return '';
            }else{
                return this.props.dateRangePicker.startDate.format('ll') + ' → ' + this.props.dateRangePicker.endDate.format('ll');
            }
        }

    handleDateInputFocus = () => {
        let datePicker = Object.assign({}, this.props.datePicker);
        datePicker.open = true;
        this.props.updateDatePicker(datePicker);
    }

    handleDateRangeInputFocus = () => {
        let dateRangePicker = Object.assign({}, this.props.dateRangePicker);
        dateRangePicker.data.focusedInput = 'endDate';
        dateRangePicker.open = true;
        this.props.updateDateRangePicker(dateRangePicker);
    }

    handleDatePickerOutsideClick = (e) => {
        const isDescendantOfRoot = this.datePickerNode && this.datePickerNode.contains(e.target);
        if (!isDescendantOfRoot) {
            e.stopPropagation();
            this.handleDatePickerCancel();
        }
    }

    handleDateRangePickerOutsideClick = (e) => {
        const isDescendantOfRoot = this.dateRangePickerNode && this.dateRangePickerNode.contains(e.target);
        if (!isDescendantOfRoot) {
            e.stopPropagation();
            this.handleDateRangePickerCancel();
        }
    }

    handleDatesChange = ({startDate, endDate}) => {
        let datePicker = Object.assign({}, this.props.datePicker), end;

        datePicker.open = false;
        if (this.props.periodFilter === 'week') {
            end = startDate.clone().endOf('week');
            datePicker.data.startDate = startDate.clone().startOf('week');
            datePicker.data.endDate = end.isAfter(this.maximumDate) ? this.maximumDate : end;
        } else {
            datePicker.data.startDate = startDate;
            datePicker.data.endDate = startDate;
        }
        datePicker.startDate = datePicker.data.startDate;
        datePicker.endDate = datePicker.data.startDate;
        this.props.updateDatePicker(datePicker);
        this.datePickerChangeURL(datePicker.startDate);
    }

    handleDatesRangeChange = ({startDate, endDate}) => {
        let dateRangePicker = Object.assign({}, this.props.dateRangePicker);
        dateRangePicker.data.startDate = startDate;
        let hasValidEndDate = false;

        if (!endDate)
            dateRangePicker.data.focusedInput = 'endDate';
        if (endDate && endDate.isAfter(startDate)){
            dateRangePicker.open = false;
            hasValidEndDate = true;
            // date range is valid, updates last date range selected
            dateRangePicker.startDate = startDate;
            dateRangePicker.endDate = endDate;
        }
        if (!endDate || hasValidEndDate)
            dateRangePicker.data.endDate = endDate;

        this.props.updateDateRangePicker(dateRangePicker);

        if (hasValidEndDate)
            this.dateRangePickerChangeURL(dateRangePicker.startDate, dateRangePicker.endDate);
    }

    handleYearChange = (year) => {
        const datePicker = {
            ...this.props.datePicker,
            startDate: moment(new Date(year.value)),
            endDate: moment(new Date(year.value)),
        }

        datePicker.data.startDate = datePicker.startDate;
        datePicker.data.endDate = datePicker.startDate;

        this.props.updateDatePicker(datePicker);
        this.datePickerChangeURL(datePicker.startDate);
    }

    handleDatePickerStartDateOffset = (day) => this.props.periodFilter === 'week' ? day.startOf('week') : day;

    handleDatePickerEndDateOffset = (day) => this.props.periodFilter === 'week' ? day.endOf('week') : day;

    handleDatePickerGoToToday = () => {
        const today = moment();
        let datePicker = Object.assign({}, this.props.datePicker);
        datePicker.open = false;
        datePicker.startDate = today;
        datePicker.endDate = today;
        datePicker.data.startDate = today;
        datePicker.data.endDate = today;
        this.props.updateDatePicker(datePicker);
        this.datePickerChangeURL(datePicker.startDate);
    }

    handleDatePickerCancel = () => {
        let datePicker = Object.assign({}, this.props.datePicker);
        datePicker.open = false;
        datePicker.data.startDate = datePicker.startDate;
        datePicker.data.endDate = datePicker.endDate;
        if (this.props.chartType === 'line-chart' || this.props.chartType === 'streamgraph'){
            this.props.updateDatePicker(datePicker, true);
        }else{
            this.props.updateDatePicker(datePicker);
        }
    }

    handleDateRangePickerCancel = () => {
        let dateRangePicker = Object.assign({}, this.props.dateRangePicker);
        dateRangePicker.open = false;
        dateRangePicker.data.startDate = dateRangePicker.startDate;
        dateRangePicker.data.endDate = dateRangePicker.endDate;
        this.props.updateDateRangePicker(dateRangePicker, true);
    }

    handleDateRangePickerReset = () => {
        let dateRangePicker = Object.assign({}, this.props.dateRangePicker);
        dateRangePicker.data.startDate = null;
        dateRangePicker.data.endDate = null;
        dateRangePicker.data.focusedInput = 'startDate';
        this.props.updateDateRangePicker(dateRangePicker);
    }

    getClearIds = searchParams => {
        const ids = JSON.parse(adjustJSONToValidJSON(searchParams.get('ids'))).map(({ id }) => ({ id }))

        return JSON.stringify(ids).replace(/"/g, '');
    }

    datePickerChangeURL = (date) => {
        let url;
        switch (this.props.chartType) {
            case 'line-chart':
                const searchParams = new URLSearchParams(window.location.search);
                url = window.location.pathname
                const ids = this.props.clearIds ? this.getClearIds(searchParams) : searchParams.get('ids');

                url += `?ids=${ids}`;
                url += `&date=${date.format('YYYY-MM-DD')}`;
                url += `&period=${this.props.periodFilter}`;
                if (this.props.isLinear === false)
                    url += '&step';
                if (this.props.level)
                    url += `&level=${this.props.level}`
                if (this.props.old_date)
                    url += `&old_date=${this.props.old_date}`
                if (this.props.dataGranularity)
                    url += `&dataGranularity=${this.props.dataGranularity}`
                if (this.props.showAlertEvents)
                    url += '&alerts';
                this.props.history.push(url);
                break;
            case 'streamgraph':
                url = window.location.pathname + `?date=${date.format('YYYY-MM-DD')}`;
                url += `&period=${this.props.periodFilter}`;
                if (this.props.isLinear === false)
                    url += '&step';
                if (!this.props.showParent)
                    url += '&hideParent';
                if (this.props.periodFilter === 'day'){
                    url += '&dataGranularity=';
                    url += (this.props.dataGranularity) ? this.props.dataGranularity : 'FIVE_MINUTES';
                }
                this.props.history.push(url);
                break;
            case 'bar-chart':
                this.props.history.push(
                    `${window.location.pathname}?date=${date.format('YYYY-MM-DD')}&period=${this.props.periodFilter}`);
                break;
            default:
                console.error("No chart type found for date picker URL creation.");
        }
    }

    dateRangePickerChangeURL = (startDate, endDate) => {
        let url;
        switch (this.props.chartType) {
            case 'line-chart':
                const searchParams = new URLSearchParams(window.location.search);
                url = window.location.pathname
                const ids = this.props.clearIds ? this.getClearIds(searchParams) : searchParams.get('ids');

                url += `?ids=${ids}`;
                url += `&startDate=${startDate.format('YYYY-MM-DD')}`;
                url += `&endDate=${endDate.format('YYYY-MM-DD')}`;
                url += `&period=custom`;
                if (this.props.isLinear === false)
                    url += '&step';
                if (this.props.level)
                    url += `&level=${this.props.level}`
                if (this.props.old_date)
                    url += `&old_date=${this.props.old_date}`
                if (this.props.dataGranularity)
                    url += `&dataGranularity=${this.props.dataGranularity}`
                if (this.props.showAlertEvents)
                    url += '&alerts';
                this.props.history.push(url);
                break;
            case 'streamgraph':
                url = window.location.pathname;
                url += `?startDate=${startDate.format('YYYY-MM-DD')}`;
                url += `&endDate=${endDate.format('YYYY-MM-DD')}`;
                url += `&period=custom`;
                if (this.props.isLinear === false)
                    url += '&step';
                if (!this.props.showParent)
                    url += '&hideParent';
                this.props.history.push(url);
                break;
            case 'bar-chart':
                url = window.location.pathname;
                url += `?startDate=${startDate.format('YYYY-MM-DD')}`;
                url += `&endDate=${endDate.format('YYYY-MM-DD')}`;
                url += `&period=custom`;
                this.props.history.push(url);
                break;
            default:
                console.error("No chart type found for date picker URL creation.");
        }
    }

    render () {
        return (
            <div>
                {this.props.periodFilter !== 'custom' && this.props.periodFilter !== 'year' ?
                    <div>
                        {this.props.showLabel !== false ? <Text className="date-filter-label" text="Date" /> : null}
                        <div className="date-picker-wrapper" ref={this.setDatePickerNodeRef}>
                            <div className={
                                `date-picker-input-wrapper ${this.props.loadingGroups || this.props.disabled ? "is-disabled" : ''}`}>
                                <input disabled={this.props.loadingGroups || this.props.disabled}
                                    name="date-picker-input"
                                    onFocus={this.handleDateInputFocus}
                                    placeholder={i18n.t('Select date')}
                                    readOnly
                                    type="text"
                                    value={this.inputDateFormatted()} />
                                <span className="Select-arrow-zone" onClick={this.handleDateInputFocus}>
                                    <span className="Select-arrow" />
                                </span>
                                {this.props.loadingGroups ?
                                    <span className="Select-loading-zone">
                                        <span className="Select-loading" />
                                    </span> : null}
                            </div>
                            {this.props.datePicker.open ?
                                <div className="date-picker-container">
                                    {this.props.periodFilter === 'month' ?
                                        <MonthPicker startDate={this.props.datePicker.data.startDate}
                                            onDatesChange={this.handleDatesChange}
                                            onOutsideClick={this.handleDatePickerOutsideClick} /> :
                                        <DayPickerRangeController
                                            enableOutsideDays={this.props.periodFilter !== 'day'}
                                            endDate={this.props.datePicker.data.endDate}
                                            startDate={this.props.datePicker.data.startDate}
                                            endDateOffset={this.handleDatePickerEndDateOffset}
                                            startDateOffset={this.handleDatePickerStartDateOffset}
                                            focusedInput={this.props.datePicker.data.focusedInput}
                                            hideKeyboardShortcutsPanel={true}
                                            isOutsideRange={this.props.isOutsideRange}
                                            navPrev={<KeyboardArrowLeftIcon />}
                                            navNext={<KeyboardArrowRightIcon />}
                                            onDatesChange={this.handleDatesChange}
                                            onFocusChange={() => {}}
                                            onOutsideClick={this.handleDatePickerOutsideClick} />}
                                    {!this.props.hideTodayButton && (
                                        <div className={`btn-actions ${this.props.periodFilter}`}>
                                            <Button onClick={this.handleDatePickerGoToToday}>
                                                {this.datePickerPeriodButton()}
                                            </Button>
                                        </div>
                                    )}
                                </div> : null}
                        </div>
                    </div> :
                    this.props.periodFilter !== 'year' ?
                        <div>
                            {this.props.showLabel !== false ? <Text className="date-filter-label" text={i18n.t('Date Range')} /> : null}
                            <div className="date-picker-wrapper" ref={this.setDateRangePickerNodeRef}>
                                <div className={
                                    `date-picker-input-wrapper ${this.props.loadingGroups || this.props.disabled ? "is-disabled" : ''}`}>
                                    <input disabled={this.props.loadingGroups || this.props.disabled}
                                        name="date-picker-input"
                                        onFocus={this.handleDateRangeInputFocus}
                                        placeholder={i18n.t('Select date range')}
                                        readOnly
                                        type="text"
                                        value={this.inputRangeDateFormatted()} />
                                    <span className="Select-arrow-zone" onClick={this.handleDateRangeInputFocus}>
                                        <span className="Select-arrow" />
                                    </span>
                                    {this.props.loadingGroups ?
                                        <span className="Select-loading-zone">
                                            <span className="Select-loading" />
                                        </span> : null}
                                </div>
                                {this.props.dateRangePicker.open ?
                                    <div className="date-picker-container date-range-picker">
                                        <DayPickerRangeController
                                            endDate={this.props.dateRangePicker.data.endDate}
                                            startDate={this.props.dateRangePicker.data.startDate}
                                            focusedInput={this.props.dateRangePicker.data.focusedInput}
                                            hideKeyboardShortcutsPanel={true}
                                            isOutsideRange={(day) => this.props.isOutsideRange && typeof this.props.isOutsideRange === 'function'
                                                ? this.props.isOutsideRange(day)
                                                : day.startOf('day').isAfter(this.maximumDate)
                                            }
                                            navPrev={<KeyboardArrowLeftIcon />}
                                            navNext={<KeyboardArrowRightIcon />}
                                            onDatesChange={this.handleDatesRangeChange}
                                            onFocusChange={() => {}}
                                            onOutsideClick={this.handleDateRangePickerOutsideClick}
                                            numberOfMonths={2}
                                            minimumNights={0} />
                                        <div className='btn-actions'>
                                            <Button onClick={this.handleDateRangePickerReset}>
                                                {i18n.t('Reset Date Range')}
                                            </Button>
                                        </div>
                                    </div> : null}
                            </div>
                        </div>
                    :
                        <Select
                            removeSelected
                            closeOnSelect
                            // disabled={loading}
                            onChange={this.handleYearChange}
                            valueKey="label"
                            options={years}
                            placeholder={i18n.t('Select the year')}
                            // simpleValue
                            value={years.filter(year => year.value === this.props.datePicker.startDate.format('YYYY'))[0]}
                        />
                    }
            </div>)
        }
}

DatePicker.propTypes = {
    clearIds: PropTypes.bool,
    chartType: PropTypes.oneOf(['line-chart', 'bar-chart', 'streamgraph']).isRequired,
    datePicker: PropTypes.object.isRequired,
    dateRangePicker: PropTypes.object.isRequired,
    updateDatePicker: PropTypes.func.isRequired,
    updateDateRangePicker: PropTypes.func.isRequired,
    periodFilter: PropTypes.string.isRequired,
    isLinear: PropTypes.bool,
    loadingGroups: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    showLabel: PropTypes.bool,
    dataGranularity: PropTypes.string,
}

export default DatePicker;
