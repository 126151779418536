import React from 'react';
import reactStrReplace from 'react-string-replace';

import AlertSection from './alert-section';
import ConsumptionSection from './consumption-section';
import CostSection from './cost-section';
import EventsSection from './events-section';
import i18n from '../../i18n';

import './overview.css';

const HomeOverview = () => {
    const lastUrl = localStorage.getItem('lastUrlFromLogin');
    localStorage.setItem('lastUrlFromLogin', null);

    return (
        <section id="page-home-browse">
            <div className="home-msg-content">
                {lastUrl && !lastUrl.includes('home') && lastUrl !== 'null' && (
                    <p className="home-last-url-msg">
                        {reactStrReplace(i18n.t('Click {{here_link}} to go to the last visited page', { here_link: 'here_link' }), /(here_link)/g, () => (
                            <a href={lastUrl} key="here-link">{i18n.t('here')}</a>
                        ))}
                    </p>
                )}
            </div>
            <AlertSection />
            <ConsumptionSection />
            <CostSection />
            <EventsSection />
        </section>
    )
};

export default HomeOverview;
