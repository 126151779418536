import React from 'react';
import d3 from 'd3';
import NVD3Chart from 'react-nvd3';
import moment from 'moment-timezone';
import { injectIntl, intlShape } from 'react-intl'

import {nest} from 'd3-collection';
import {utcDays} from 'd3-time';
import {format} from 'd3-format';
import {scaleTime, scaleLinear} from 'd3-scale';
import {sum} from 'd3-array';
import Button from 'material-ui/Button';

import ChartLoading from '../chart-loading';
import {Text} from '../../../elements/text';
import BenchmarkCompare from './benchmark-compare';
import BenchmarkResumeTable from './benchmark-resume-table';
import DatePicker from '../filter-date-picker';
import SystemSnackbar from '../../../elements/material-ui/SystemSnackbar'

import i18n from '../../../i18n';

import 'nvd3/build/nv.d3.min.css';
import './benchmark-line-chart-mock.css';

import {benchmarkLineGroupMonthData} from '../../../components/__mocks__/visualizations-benchmark-line-group-194-month-get';

const yTickFormat = (d) => format(".2f")(d);

class BenchmarkLineChartMock extends React.PureComponent {
    constructor (props) {
        super(props);

        this.periodOptions = [
            {label: i18n.t('Day'), value: 'day'},
            {label: i18n.t('Week'), value: 'week'},
            {label: i18n.t('Month'), value: 'month'},
            {label: i18n.t('Custom'), value: 'custom'},
        ];

        this.loadingChartMessage = "Loading data...";
        this.noDataChartMessage = "There is no consumption data available for the selected criteria. Cost simulation can't be calculated in this case.";
        this.noGroupChartMessage = "There is no data available as no group has been set up.";

        this.colors = ["#9467bd"];

        this.filterGroupOptions = [{name: i18n.t("Sample Group"), id: 1}];
        this.benchmarkOptions = [{name: i18n.t("Fixed Benchmark"), id: 1}, {name: i18n.t("Relative Benchmark"), id: 2}];

        this.state = {
            noDataChartMsg: this.loadingChartMessage,
            xTicks: [],
            xDomain: [],
            currency: "",
            screenWidth: 0,
            screenHeight: 0,
            loadingData: true,
            filterGroup: this.filterGroupOptions[0],
            benchmarkCompare: {
                filterBenchmark: this.benchmarkOptions[0],
                open: false,
            },
            datePicker: {
                startDate: moment('2019-04-01'),
                endDate: moment('2019-04-30'),
            },
            periodFilter: "month",
            absoluteBenchmark: {total: 0, savings: 0, losses: 0},
            relativeBenchmark: {total: 0, savings: 0, losses: 0},
        };

        this.costFormat = (currency, value) => {
            return currency && value > 0 ? `${this.props.intl.formatNumber(value, {style: 'currency', currency: currency})}` : ' - '
        }
    }

    componentWillMount = async () => {
        this.updateDimensions();
    }

    componentDidMount () {
        window.addEventListener("resize", this.updateDimensions);

        this.setState({
            data: this.createGroupData(benchmarkLineGroupMonthData),
            xDomain: this.getXDomain(),
            xTicks: this.getXTicks(),
            max: {consumption: benchmarkLineGroupMonthData.maxDayConsumption.consumption},
            loadingData: false,
        }, this.calculateResume);
    }

    componentWillUpdate(nextProps, nextState){
        // screen was resized
        if (nextState.screenWidth !== this.state.screenWidth || nextState.screenHeight !== this.state.screenHeight){
            // remove existing highlights to force redraw
            d3.selectAll('.nv-chart svg .weekend-highlight').remove();
            d3.selectAll('.nv-chart svg .after-work-highlight').remove();
            this.removeBenchmarkElements();
        }
    }

    componentDidUpdate () {
        // fixes tooltip mark that stayed on the chart when changing data
        d3.selectAll('.nvtooltip').remove();

        if (this.state.periodFilter === 'day'){
            this.addAfterWorkHighlight();
        }

        if (this.state.periodFilter === 'week' || this.state.periodFilter === 'month' || this.state.periodFilter === 'custom'){
            // when in 'custom' period, it avoids executing when selecting start date of the range:
            if (this.props.shouldAddWeekendhighlight)
                this.addWeekEndsHighlight();
        }

        if (this.state.data && this.state.data.length)
            setTimeout(() => this.addBenchmarkElements(), 1);
    }

    componentWillUnmount () {
        d3.selectAll('.nvtooltip').remove();
        window.removeEventListener("resize", this.updateDimensions);
    }

    // Based on https://stackoverflow.com/a/34475071
    updateDimensions = () => {
        let w = window,
        d = document,
        documentElement = d.documentElement,
        body = d.getElementsByTagName('body')[0],
        width = w.innerWidth || documentElement.clientWidth || body.clientWidth,
        height = w.innerHeight|| documentElement.clientHeight|| body.clientHeight;

        this.setState({
            screenWidth: width,
            screenHeight: height,
        });
    }

    xTickFormat = (d) => {
        let format = '';
        switch (this.state.periodFilter){
            case 'month':
                format = '[LM]';
                break;
            case 'week':
                format = 'ddd DD';
                break;
            case 'day':
                format = 'hh A';
                break;
            case 'custom':
                if (this.props.diffOfSelectedDays <= 1){
                    format = 'MMM DD, hh A';
                }else{
                    format = 'MMM DD';
                }
                break;
            default:
                // day format
                format = 'hh A';
                console.warn("An invalid x tick format was found. Assuming day x tick format.");
        }
        return moment(d).format(format);
    }

    getYDomain = () =>{
        if (this.state.max && this.state.max.consumption < 0.1){
           return [0, 0.1];
        }else{
            return this.state.max && this.state.max.consumption ? [0, this.state.max.consumption] : [0];
        }
    }

    tooltipHeaderFormatter = (strDate) => {
        let format = '';
        switch (this.state.periodFilter) {
            case 'month':
                format = 'dddd, MMMM Do YYYY';
                break;
            case 'week':
                format = 'dddd, MMMM Do YYYY';
                break;
            case 'day':
                format = 'hh:mm A';
                break;
            case 'custom':
                if (this.props.diffOfSelectedDays <= 1)
                    format = 'dddd, MMMM Do YYYY, hh:mm A';
                if (this.props.diffOfSelectedDays > 1)
                    format = 'dddd, MMMM Do YYYY';
                break;
            default:
                format = 'hh:mm A';// day format
                console.warn("An invalid period filter was found. Assuming day format.");
        }
        return moment(strDate).format(format);
    }

    getXDomain = () => {
        // this forces the x axis to go from 12AM to 12PM, when period is day, sunday to saturday, when week, each month day, when month,
        // even if the data is partial for the period
        // NVD3 forceX function only understands it if we get the numeric value of the date object
        let domain = [], date;

        switch (this.state.periodFilter) {
            case 'month':
                const monthStart = this.state.datePicker.startDate.clone().startOf('month');
                const monthEnd = this.state.datePicker.startDate.clone().endOf('month');
                domain = [monthStart.valueOf(), monthEnd.valueOf()];
                break;
            case 'week':
                date = this.state.datePicker.startDate.clone().startOf('week');
                domain = [date.valueOf(), date.add(7, 'days').valueOf()];
                break;
            case 'day':
                date = this.state.datePicker.startDate.clone().startOf('day');
                domain = [date.valueOf(), date.add('1', 'days').valueOf()];
                break;
            case 'custom':
                const periodStart = this.props.dateRangePicker.startDate.clone().startOf('day');
                const periodEnd = this.props.dateRangePicker.endDate.clone().endOf('day');
                domain = [periodStart.valueOf(), periodEnd.valueOf()];
                break;
            default:
                // day domain
                date = this.state.datePicker.startDate.clone().startOf('day');
                domain = [date.valueOf(), date.add('1', 'days').valueOf()];
                console.warn("An invalid x domain format was found. Assuming day x domain.");
        }
        return domain;
    }

    getXTicks = () => {
        let xTicks = [];
        switch (this.state.periodFilter) {
            case 'month':
                const monthStart = this.state.datePicker.startDate.clone().startOf('month');
                const monthEnd = this.state.datePicker.startDate.clone().endOf('month');
                xTicks = utcDays(
                    monthStart,
                    monthEnd,
                ).map((value) => moment(value).valueOf());
                break;
            case 'week':
                let momentDate = this.state.datePicker.startDate.clone();
                xTicks = utcDays(
                    momentDate.startOf('week'),
                    momentDate.clone().add(7, 'days')
                ).map((value) => moment(value).valueOf());
                break;
            case 'day':
                xTicks = [];
                break;
            case 'custom':
                xTicks = [];
                if (this.props.diffOfSelectedDays > 1){
                    const periodStart = this.props.dateRangePicker.startDate.clone().startOf('day');
                    const periodEnd = this.props.dateRangePicker.endDate.clone().endOf('day');
                    xTicks = utcDays(
                        periodStart,
                        periodEnd
                    ).map((value) => moment(value).valueOf());
                }
                break;
            default:
                xTicks = [];
                console.warn("An invalid period filter was found for x ticks. Returning no ticks.");
        }
        return  xTicks;
    }

    createDatetime = (data) => data.map((d) => ({
        ...d,
        time: moment(this.state.periodFilter !== 'month' ? d.dayTz + "T" + d.hour : d.dayTz + "T00:00:00").toDate(),
    }));

    /* Creates chart data for groups and subgroups */
    createGroupData = (groupData) => {
        let groupEntries = [];

        // Creates group data for the chart to draw
        if (groupData.data && groupData.data.length > 1){
            const groupPre = this.createDatetime(groupData.data).sort((a, b) => moment(a.time).diff(moment(b.time)));
            let group = groupPre.map((d) => {
                return {
                    ...d,
                    // Localising this groupId here because in data mock the groupId is the group name 'Sample Group'.
                    // On non mock components this should not happen, as the id is numeric (the idea is to show a mock suited for any customer).
                    id: i18n.t(groupData.groupId),
                }
            });
            groupEntries = nest().key(function(d) { return d.id; }).entries(group);
        }

        return groupEntries;
    }


    addWeekEndsHighlight = () => {
        const renderedInnerChartG = d3.select(".nv-chart svg g.nv-background");

        // the chart was already created'
        if (renderedInnerChartG && renderedInnerChartG.node()){
            const existingHighlights = d3.select(".nv-chart svg g g .weekend-highlight")[0][0];

            // no highlight rects were added yet
            if (existingHighlights === null){
                let weekEndData = [];
                const chartDimensions = renderedInnerChartG.node().getBBox();
                const xScale = scaleTime().domain(this.getXDomain()).range([0, chartDimensions.width]);

                if (this.state.periodFilter === 'week'){
                    let sunday = {};
                    let left = xScale(moment(this.state.datePicker.startDate.clone().startOf('week').valueOf()).toDate());
                    let right = xScale(moment(this.state.datePicker.startDate.clone().startOf('week').add(1, 'days').valueOf()).toDate());
                    let width = right - left;
                    sunday.xStart = left;
                    sunday.width = width;

                    let saturday = {};
                    let left2 = xScale(moment(this.state.datePicker.startDate.clone().endOf('week').subtract(1, 'days').valueOf()).toDate());
                    let right2 = xScale(moment(this.state.datePicker.startDate.clone().endOf('week').valueOf()).toDate());
                    let width2 = right2 - left2;
                    saturday.xStart = left2;
                    saturday.width = width2;

                    weekEndData.push(sunday);
                    weekEndData.push(saturday);
                }

                if (this.state.periodFilter === 'month'){
                    const lastMonthDay = this.state.datePicker.startDate.clone().endOf('month');
                    let currentDay = this.state.datePicker.startDate.clone().startOf('month');

                    const weekEndDays = [];
                    while (currentDay.isSameOrBefore(lastMonthDay, 'day')){
                        // if (currentDay.day() === 6)
                        if (currentDay.day() === 0 || currentDay.day() === 6)
                            weekEndDays.push(currentDay.clone());
                        currentDay.add(1, 'days');
                    }

                    weekEndDays.forEach(weekEndDay => {
                        let weekEndDayData = {};
                        //if the first day of month, doesn't apply the 12 hours left margin
                        const start = (weekEndDay.format('DD') === "01") ? weekEndDay.clone().startOf('day') : weekEndDay.clone().startOf('day');
                        let left = xScale(moment(start.valueOf()).toDate());
                        let right = xScale(moment(weekEndDay.clone().startOf('day').add(1, 'days').valueOf()).toDate());
                        let width = right - left;
                        weekEndDayData.xStart = left;
                        weekEndDayData.width = width;
                        weekEndData.push(weekEndDayData);
                    });
                }

                if (this.state.periodFilter === 'custom'){
                    const firstPeriodDay = this.props.dateRangePicker.startDate.clone().startOf('day');
                    const lastPeriodDay = this.props.dateRangePicker.endDate.clone().endOf('day');
                    let currentDay = this.props.dateRangePicker.startDate.clone().startOf('day');

                    const weekEndDays = [];
                    while (currentDay.isSameOrAfter(firstPeriodDay, 'day') && currentDay.isSameOrBefore(lastPeriodDay, 'day')){
                        if (currentDay.day() === 0 || currentDay.day() === 6)
                            weekEndDays.push(currentDay.clone());
                        currentDay.add(1, 'days');
                    }

                    weekEndDays.forEach((weekEndDay, i) => {
                        let weekEndDayData = {};
                        let left, right;

                        //for periods greater than 31 days, uses month highlight logic (one day granularity)
                        if (this.props.diffOfSelectedDays > 30){//diff of 30 === 31 days selected
                            //if the first day of period is weekend day, doesn't apply the 12 hours left margin
                            const start = (i === 0 && firstPeriodDay.isSame(weekEndDay)) ? weekEndDay.clone().startOf('day') : weekEndDay.clone().startOf('day');
                            left = xScale(moment(start.valueOf()).toDate());
                            right = xScale(moment(weekEndDay.clone().startOf('day').add(1, 'day').valueOf()).toDate());
                        } else {
                            left = xScale(moment(weekEndDay.clone().startOf('day').valueOf()).toDate());
                            right = xScale(moment(weekEndDay.clone().endOf('day').valueOf()).toDate());
                        }

                        let width = right - left;
                        weekEndDayData.xStart = left;
                        weekEndDayData.width = width;
                        weekEndData.push(weekEndDayData);
                    });
                }

                if (weekEndData.length > 0){
                    const group = d3.select(".nv-chart svg g g");

                    group.selectAll("rect.weekend-highlight")
                        .data(weekEndData)
                        .enter()
                        .append("rect")
                        .classed("weekend-highlight", true)
                        .attr("x", (d) => d.xStart)
                        .attr("y", 0)
                        .attr("width", (d) => d.width )
                        .attr("height", chartDimensions.height )
                        .attr("fill", "#d8d8d8")
                        .attr("opacity", 0.2);
                }
            }
        }
    }

    addAfterWorkHighlight = () => {
        const renderedInnerChartG = d3.select(".nv-chart svg g.nv-background");

        // the chart was already created'
        if (renderedInnerChartG && renderedInnerChartG.node()){
            const existingHighlights = d3.select(".nv-chart svg g g .after-work-highlight")[0][0];

            // no highlight rects were added yet
            if (existingHighlights === null){
                let afterWork = [];
                const chartDimensions = renderedInnerChartG.node().getBBox();
                const xScale = scaleTime().domain(this.getXDomain()).range([0, chartDimensions.width]);
                let currentDay = this.state.datePicker.startDate.clone();

                if (this.state.periodFilter === 'day' && currentDay.day() !== 0 && currentDay.day() !== 6){
                    let morning = {};
                    let left = xScale(moment(this.state.datePicker.startDate.clone().startOf('day').valueOf()).toDate());
                    let right = xScale(moment(this.state.datePicker.startDate.clone().startOf('day').add(8, 'hours').valueOf()).toDate());
                    let width = right - left;
                    morning.xStart = left;
                    morning.width = width;

                    let evening = {};
                    let left2 = xScale(moment(this.state.datePicker.startDate.clone().endOf('day').subtract(6, 'hours').valueOf()).toDate());
                    let right2 = xScale(moment(this.state.datePicker.startDate.clone().endOf('day').valueOf()).toDate());
                    let width2 = right2 - left2;
                    evening.xStart = left2;
                    evening.width = width2;

                    afterWork.push(morning);
                    afterWork.push(evening);
                }

                if (afterWork.length > 0){
                    const group = d3.select(".nv-chart svg g g");

                    group.selectAll("rect.after-work-highlight")
                        .data(afterWork)
                        .enter()
                        .append("rect")
                        .classed("after-work-highlight", true)
                        .attr("x", (d) => d.xStart)
                        .attr("y", 0)
                        .attr("width", (d) => d.width )
                        .attr("height", chartDimensions.height )
                        .attr("fill", "#d8d8d8")
                        .attr("opacity", 0.2);
                }
            }
        }
    }

    relativeMockFn = (i, d, data) => {
        return (i < data.length/2) ? d.consumption * 0.3 : d.consumption + d.consumption * 0.2;
    }


    createMockData = (filterBenchmarkName) => {
        const fixedConsumptionValue = 4;

        const data = this.state.data[0].values;

        const benchmarkMockData = [].concat(data).map((d, i) => {
            return {
                ...d,
                benchmark: filterBenchmarkName === i18n.t("Fixed Benchmark") ? fixedConsumptionValue : this.relativeMockFn(i, d, data)
            };
        });

        return benchmarkMockData || [];
    }

    calculateResume = () => {
        const relativeData = this.createMockData('Relative Benchmark');
        const absoluteData = this.createMockData('Fixed Benchmark');

        this.setState({
            relativeBenchmark: {
                total: sum(relativeData, (d) => d.consumption),
                savings: sum(relativeData.filter(d => d.consumption < d.benchmark), (d) => d.benchmark - d.consumption),
                losses: sum(relativeData.filter(d => d.consumption >= d.benchmark), (d) => d.consumption - d.benchmark),
                // savings: sum(relativeData.filter(d => d.consumption < d.benchmark), (d) => d.consumption),
                // losses: sum(relativeData.filter(d => d.consumption >= d.benchmark), (d) => d.consumption),
            },
            absoluteBenchmark: {
                total: sum(absoluteData, (d) => d.consumption),
                savings: sum(absoluteData.filter(d => d.consumption < d.benchmark), (d) => d.benchmark - d.consumption),
                losses: sum(absoluteData.filter(d => d.consumption >= d.benchmark), (d) => d.consumption - d.benchmark),
                // savings: sum(absoluteData.filter(d => d.consumption < d.benchmark), (d) => d.consumption),
                // losses: sum(absoluteData.filter(d => d.consumption >= d.benchmark), (d) => d.consumption),
            }
        });
    }

    addBenchmarkLegend = () => {
        const mainG = d3.select("div.nv-chart svg g.nv-legendWrap.nvd3-svg > g");

        if (mainG.selectAll('.nv-benchmark-legend')[0].length === 0){
            const marginX = 14;
            let widthSum;

            const extraLegendG = mainG.append('g');
            const originalLegendTransform = mainG.select('g:nth-child(1)').attr('transform');
            extraLegendG.attr('transform', originalLegendTransform).classed('nv-benchmark-legend', true);

            const belowG = extraLegendG.append('g');
            belowG.classed('nv-series', true);
            belowG.append('circle')
                .classed('nv-legend-symbol', true)
                .attr('r', 5)
                .style('stroke-width', 2)
                .style('fill', '#d1dbc8')
                .style('fill-opacity', 1)
                .style('stroke', '#d1dbc8');
            belowG.append('text')
                .attr('text-anchor', 'start')
                .attr('dy', '.32em')
                .attr('dx', '8')
                .attr('fill', '#000000')
                .classed('nv-legend-text', true)
                .text(i18n.t('Below benchmark'));

            const belowGWidth = belowG.node().getBBox().width;
            widthSum = belowGWidth + marginX;
            belowG.attr('transform', 'translate(-' + widthSum + ',5)');

            const aboveG = extraLegendG.append('g');
            aboveG.classed('nv-series', true);
            aboveG.append('circle')
                .classed('nv-legend-symbol', true)
                .attr('r', 5)
                .style('stroke-width', 2)
                .style('fill', '#f0d0d0')
                .style('fill-opacity', 1)
                .style('stroke', '#f0d0d0');
            aboveG.append('text')
                .attr('text-anchor', 'start')
                .attr('dy', '.32em')
                .attr('dx', '8')
                .attr('fill', '#000000')
                .classed('nv-legend-text', true)
                .text(i18n.t('Above benchmark'));

            const aboveGWidth = aboveG.node().getBBox().width;
            widthSum = widthSum + aboveGWidth + marginX;
            aboveG.attr('transform', 'translate(-' + widthSum + ',5)');

            const benchmarkG = extraLegendG.append('g');
            benchmarkG.classed('nv-series', true);
            benchmarkG.append('path')
                .attr('d', 'M -10,0 H 12')
                .style('stroke-width', 3)
                .style('stroke-dasharray', 4)
                .style('stroke-opacity', 1)
                .style('fill', 'none')
                .style('fill-opacity', 1)
                .style('stroke', '#000000');
            benchmarkG.append('text')
                .attr('text-anchor', 'start')
                .attr('dy', '.32em')
                .attr('dx', '14')
                .attr('fill', '#000000')
                .classed('nv-legend-text', true)
                .text(i18n.t('Benchmark'));

            const benchmarkGWidth = benchmarkG.node().getBBox().width;
            widthSum = widthSum + benchmarkGWidth + (marginX-4); //margin adjustment to better fit benchmark element on legend
            benchmarkG.attr('transform', 'translate(-' + widthSum + ',5)');
        }
    }

    addBenchmarkElements(){
        if (!this.state.data || !this.state.data.length)
            return;

        this.addBenchmarkLegend();

        const benchmarkMockData = this.createMockData(this.state.benchmarkCompare.filterBenchmark.name);

        let benchmarkLine;

        const renderedInnerChartG = d3.select(".nv-chart svg g.nv-background");

        if (renderedInnerChartG && renderedInnerChartG.node()){
            const existingLines = d3.select(".nv-chart svg g g .nv-benchmark-line")[0][0];

            // no line was added yet
            if (existingLines === null){
                const chartDimensions = renderedInnerChartG.node().getBBox();
                const xScale = scaleTime().domain(this.getXDomain()).range([chartDimensions.x, chartDimensions.width]);
                const yScale = scaleLinear().domain(this.getYDomain()).range([chartDimensions.height, 0]);

                benchmarkLine = d3.svg.line()
                    .x(function(d) { return xScale(d.time); })
                    .y(function(d) { return yScale(d.benchmark); });
            }

            const existingAreas = d3.select(".nv-chart svg .nv-benchmark-area")[0][0];

            // no areas were added yet
            if (existingAreas === null && benchmarkLine){

                const areaBelowBenchmark = d3.svg.area().x(benchmarkLine.x()).y0(benchmarkLine.y()).y1(renderedInnerChartG.node().getBBox().height);
                const areaAboveBenchmark = d3.svg.area().x(benchmarkLine.x()).y0(benchmarkLine.y()).y1(0);
                var defs = d3.select(".nv-chart svg").append('defs');

                defs.append('clipPath')
                    .attr('id', 'clip-above')
                    .append('path')
                    .datum(benchmarkMockData)
                    .attr('d', areaAboveBenchmark);

                defs.append('clipPath')
                    .attr('id', 'clip-below')
                    .append('path')
                    .datum(benchmarkMockData)
                    .attr('d', areaBelowBenchmark);

                const area = this.getAreaGenerator(renderedInnerChartG.node().getBBox(), true);
                const g = d3.select(".nv-chart svg .nv-groups");

                g.selectAll(".nv-above-benchmark-area")
                    .data([benchmarkMockData])
                    .enter().append("path")
                    .attr("class", function(d, i){ return "nv-benchmark-area nv-above-benchmark-area nv-above-benchmark-area-" + i })
                    .attr("d", function(d) { return area(d); })
                    .attr('clip-path', 'url(#clip-above)')
                    .style("fill-opacity", 0.7)
                    .style("fill", "#F6D5D5");

                g.selectAll(".nv-below-benchmark-area")
                    .data([benchmarkMockData])
                    .enter().append("path")
                    .attr("class", function(d, i){ return "nv-benchmark-area nv-below-benchmark-area nv-below-benchmark-area-" + i })
                    .attr("d", function(d) { return area(d); })
                    .attr('clip-path', 'url(#clip-below)')
                    .style("fill-opacity", 0.7)
                    .style("fill", "#DDE9D3");

                g.selectAll('path.nv-benchmark-line')
                    .data([benchmarkMockData])
                    .enter()
                    .append('path')
                    .attr("stroke", "#000000")
                    .attr("fill-opacity", 0)
                    .attr("stroke-width", 2)
                    .attr("stroke-dasharray", 4)
                    .classed('nv-benchmark-line', true)
                    .attr('d', benchmarkLine);
            }
        }
    }

    getAreaGenerator = (chartDimensions, isEstimation) => {
        const xScale = scaleTime().domain(this.getXDomain()).range([chartDimensions.x, chartDimensions.width]);
        const yScale = scaleLinear()
            .domain(this.getYDomain())
            .range([chartDimensions.height, 0]);

        var area = d3.svg.area()
            .x(function(d,i)  { return xScale(d.time) })
            .y0(function(d) {
                return yScale(d.consumption)
            })
            .y1(function(d) {
                return yScale(d.benchmark)
            });


        return area;
    }

    removeBenchmarkElements = () => {
        d3.selectAll('.nv-chart svg .nv-benchmark-area').remove();
        d3.selectAll('.nv-chart svg .nv-benchmark-line').remove();
        d3.selectAll('.nv-chart svg defs clipPath').remove();
        d3.selectAll('.nv-chart svg .nv-benchmark-legend').remove();
    }

    resetBenchmarkElements = (withDelay) => {
        if (withDelay){
            // 1 ms timeout needed to get correctly the current data disabled state on addBenchmarkElements
            setTimeout(() => {
                this.removeBenchmarkElements();
                this.addBenchmarkElements();
            }, 1);
        }else{
            this.removeBenchmarkElements();
            this.addBenchmarkElements();
        }
    }

    // createTooltip = data => {
    //     if (!data) {
    //         return ''
    //     }

    //     return `
    //         <div id="custom-tooltip">
    //             <div class="tooltip-header">
    //                 ${this.tooltipHeaderFormatter(data.value)}
    //             </div>
    //             <div class="tooltip-content">
    //                 ${data.series && data.series.reduce((prev, serie, index) => prev += `
    //                     <div class="tooltip-serie">
    //                         <div class="serie-info">
    //                             <div class="serie-color" style="background-color: ${serie.color}"></div>
    //                             <div class="name">
    //                                 ${serie.data.tariff.name}
    //                             </div>
    //                         </div>
    //                         <div class="serie-value">
    //                             ${serie.data.aggregationType === 'ESTIMATED'
    //                                 ? `(estimated) ${this.costFormat(serie.data.tariff.currency, serie.value)}`
    //                                 : serie.data.aggregationType === 'HYBRID'
    //                                     ? `(partially estimated) ${this.costFormat(serie.data.tariff.currency, serie.value)}`
    //                                     : `${this.costFormat(serie.data.tariff.currency, serie.value)}`
    //                             }
    //                         </div>
    //                     </div>
    //                 `, '')}
    //             </div>
    //         </div>
    //     `
    // }

    toggleCompareCollapse = (index, toClose) => {
        this.props.updateReloadData(false)
        let ids = this.closeCompares(index, toClose);
        this.goToURL(this.getFullURL(ids));
    }

    toggleBenchmarkCollapse = () =>{
        const benchmarkCompare = this.state.benchmarkCompare;
        this.setState({
            benchmarkCompare: {
                ...benchmarkCompare,
                open: !benchmarkCompare.open,
            }
        })
    }

    changeBenchmark = (benchmark) => {
        const benchmarkCompare = this.state.benchmarkCompare;
        this.setState({
            benchmarkCompare: {
                ...benchmarkCompare,
                filterBenchmark: benchmark,
                open: false,
            }
        }, this.resetBenchmarkElements);
    }

    render () {
        let xAxis = {
            tickFormat: this.xTickFormat,
            showMaxMin: false,
        };

        const periodFilter = this.state.periodFilter;

        if (periodFilter === 'month' || periodFilter === 'day'){
            xAxis.rotateLabels = -50;
            xAxis.tickPadding = 0;
        }

        if (periodFilter === 'day')
            xAxis.ticks = 24;

        if (periodFilter === 'week' || periodFilter === 'month')
            xAxis.tickValues = this.state.xTicks;

        if (periodFilter === 'custom'){
            if (this.props.diffOfSelectedDays > 1 && this.props.diffOfSelectedDays < 20){
                xAxis.tickValues = this.state.xTicks;
            }else{
                xAxis.ticks = 8;
            }
        }

        let yAxis = { axisLabel: i18n.t('kWh'), tickFormat: yTickFormat };

        return (
            <div id="benchmark">
                <div id="line-chart">
                    <div id="top-bar">
                        <div className={`top top-period-filter-${periodFilter}`}>
                            <div>
                                {this.periodOptions.map((period, key) => (
                                    <Button
                                        className={`btn-period-filter${periodFilter === period.value ? ' active' : ''}`}
                                        type="button"
                                        disabled={true}
                                        key={key}
                                        onClick={() => {}}
                                    >
                                        {period.label}
                                    </Button>
                                ))}
                            </div>

                            <div>
                                <DatePicker
                                    clearIds
                                    chartType={'line-chart'}
                                    datePicker={this.state.datePicker}
                                    dateRangePicker={{}}
                                    updateDatePicker={()=>{}}
                                    updateDateRangePicker={()=>{}}
                                    periodFilter={periodFilter}
                                    dataGranularity={''}
                                    isLinear={true}
                                    loadingGroups={false}
                                    history={{}}
                                    disabled={true}
                                    showLabel={false} />
                            </div>


                            <div className="granularity">
                                <div>
                                    <Text text={i18n.t('1 Day')} />
                                </div>
                            </div>

                        </div>

                        {!this.state.loadingData && (
                            <div className="bottom">
                                <BenchmarkCompare
                                    updateReloadData={this.updateReloadData}
                                    location={this.props.location}
                                    history={this.props.history}
                                    periodFilter={this.state.periodFilter}
                                    // addCompare={this.addCompare}
                                    updateCompare={this.updateCompare}
                                    isLinear={this.state.isLinear}
                                    dateRangePicker={this.state.dateRangePicker}
                                    datePicker={this.state.datePicker}
                                    // dataGranularity={this.state.dataGranularity}
                                    filterGroup={this.state.filterGroup}
                                    // filterSubgroup={this.state.filterSubgroup}
                                    // filterSensor={this.state.filterSensor}
                                    filterSensorOptions={[]}
                                    filterSubgroupOptions={[]}
                                    compare={[]}
                                    filterGroupOptions={this.filterGroupOptions}
                                    // removeCompare={this.removeCompare}
                                    benchmarkOptions={this.benchmarkOptions}
                                    benchmarkCompare={this.state.benchmarkCompare}
                                    loading={this.state.loadingData}
                                    toggleBenchmarkCollapse={this.toggleBenchmarkCollapse}
                                    changeBenchmark={this.changeBenchmark}
                                    // simulateCosts={this.simulateCosts}
                                    // hadChangeOnFilters={this.state.loadedFiltersURL !== location.pathname + location.search}
                                />
                            </div>
                        )}
                    </div>

                    <div id="benchmark-line-chart-wrapper">
                        {!this.state.loadingData && !this.state.data.length ? (
                            <div className="nv-chart no-chart-msg">
                                <p className="no-data"> {(this.props.noGroupSetupError) ? this.noGroupChartMessage : this.noDataChartMessage} </p>
                            </div>
                        ) : (
                            <div id="graph">
                                <ChartLoading
                                    loading={this.state.loadingData}
                                />
                                <NVD3Chart id="benchmark-line-chart"
                                    type="lineChart"
                                    datum={this.state.data}
                                    x="time"
                                    y="consumption"
                                    options={{
                                        color: this.colors,
                                        interpolate: 'linear',
                                        forceX: this.state.xDomain,
                                        forceY: this.getYDomain(),
                                        // interactiveLayer: {
                                        //     tooltip: {
                                        //         contentGenerator: this.createTooltip
                                        //     },
                                        // },
                                        noData: this.state.noDataChartMsg,
                                        useInteractiveGuideline: true,
                                        xAxis: xAxis,
                                        xScale: scaleTime(),
                                        yAxis: yAxis,
                                    }}
                                />
                            </div>
                        )}
                    </div>
                    <BenchmarkResumeTable
                        absoluteBenchmark={this.state.absoluteBenchmark}
                        relativeBenchmark={this.state.relativeBenchmark}
                    />
                </div>
                <SystemSnackbar
                    open={true}
                    message={i18n.t('View Only - This feature is currently under development')}
                    variant='info2'
                    noAutoHide
                />
            </div>

        )
    }
}

BenchmarkLineChartMock.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(BenchmarkLineChartMock);
