import React from 'react';
import Select from 'react-select';
import { Collapse } from 'material-ui';

import Card, { CardHeader, CardContent } from 'material-ui/Card';
import IconButton from 'material-ui/IconButton';
import ExpandMoreIcon from 'material-ui-icons/ArrowDropDown';
import DeleteIcon from 'material-ui-icons/Delete';

import { Text } from '../../../elements/text';

import i18n from '../../../i18n';

const BenchmarkCompareCard = ({
    changeBenchmark,
    checkDateAvailability,
    compare,
    dataGranularity,
    history,
    index,
    isLinear,
    obj,
    periodFilter,
    removeCompare,
    benchmarkOptions,
    toggleCompareCollapse,
    updateDatePicker,
}) => (
    <Card className={`compare-card ${obj.open ? ' active' : ''}`} style={{ borderColor: "#FFFFFFFF" }}>
        <div className="compare-card-wrapper">
            <CardHeader
                action={
                    <div>
                        {compare.length > 1 && (
                            <IconButton
                                onClick={() => removeCompare(index)}
                                style={{height: 24, width: 24}}
                            >
                                <DeleteIcon />
                            </IconButton>
                        )}
                        <IconButton
                            onClick={() => toggleCompareCollapse(index)}
                            style={{height: 24, width: 24}}
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    </div>
                }
                className="compare-card-title"
                title={obj.filterBenchmark ? obj.filterBenchmark.name : ''}
            />
            <Collapse
                in={obj.open}
                timeout={300}
                className="compare-card-collapse"
            >
                <CardContent style={{padding: 0, marginBottom: '-13px'}}>
                    <Text text={i18n.t("Benchmark")} />
                    <Select
                        clearable={false}
                        disabled={false}
                        labelKey="name"
                        name="filterBenchmark"
                        onChange={benchmark => changeBenchmark(benchmark)}
                        options={benchmarkOptions}
                        placeholder={i18n.t("Select any benchmark")}
                        value={obj.filterBenchmark}
                        valueKey="id"
                        noResultsText={i18n.t('No results found')}
                    />
                </CardContent>
            </Collapse>
        </div>
    </Card>
)

export default BenchmarkCompareCard
