import React, { PureComponent } from 'react';
import Masonry from 'react-masonry-component';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import Button from 'material-ui/Button';
import CloseIcon from 'material-ui-icons/Close';
import Typography from 'material-ui/Typography';
import AddIcon from 'material-ui-icons/AddCircleOutline';
import Modal from 'material-ui/Modal';
import Dialog, {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from 'material-ui/Dialog';

import { API } from '../../components/api';
import i18n from '../../i18n';

import ReportCard from './report-card';
import ReportAdd from './report-add';
import ReportGenerate from './report-generate';
import { Loading } from '../../elements/loading';
import SystemSnackbar from '../../elements/material-ui/SystemSnackbar';

import PDF_ICON from './pdf-box.svg';

const masonryOptions = {
    gutter: 20,
    horizontalOrder: true,
};

const styles = theme => ({
    typographyTitle: {
        fontSize: '1rem',
        fontWeight: 600
    },
    paper: {
        position: 'absolute',
        width: 600,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: 0,
        '&:focus': {
            outline: 'none'
        }
    },
    snackbarClose: {
        width: theme.spacing.unit * 4,
        height: theme.spacing.unit * 4,
    },
    generateReportButton: {
        borderRadius: '3px',
        display: 'flex',
        alignItems: 'center',
        background: '#FFF',
        color: '#6ab42d',
        position: 'fixed',
        right: '200px',
        top: '20px',
        zIndex: 2000,
        padding: '4px 10px',
        cursor: 'pointer',
    },
    createReportButton: {
        borderRadius: '3px',
        display: 'flex',
        alignItems: 'center',
        background: '#FFF',
        color: '#6ab42d',
        position: 'fixed',
        right: '30px',
        top: '20px',
        zIndex: 2000,
        padding: '4px 10px',
        cursor: 'pointer',
    },
    reportButtonText: {
        color: '#6ab42d',
        paddingLeft: '4px',
        fontWeight: 'bold',
    },
    deleteConfirm: {
        color: '#6ab42d',
    },
    deleteCancel: {
        color: '#c62828',
    },
});

const modalStyle = {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '90%',
    overflowY: 'auto',
}

const generateModalStyle = {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: '90%',
    overflowY: 'visible',
}

const closeButtonStyle = {
    cursor: 'pointer',
    padding: '10px',
    position: 'absolute',
    right: 0,
    zIndex: 1,
}

class ReportsOverview extends PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            reports: [],
            openReportModal: false,
            reportModalDialogOpen: false,
            openGenerateModal: false,
            showSnackBar: false,
            snackbar: {
                type: 'warning',
                message: ''
            },
        }

        window.openSnackBar = this.openSnackBar;
    }

    componentWillMount() {
        this.getReports()
    }

    handleOpenModal = () => this.setState({
        openReportModal: true
    })

    handleOpenGenerateModal = () => this.setState({
        openGenerateModal: true
    })

    openEditReport = report => this.setState({
        openReportModal: true,
        editReport: report,
    })

    handleCloseModal = closeAndUpdateReports => {
        if (closeAndUpdateReports === true){
            this.getReports();
            this.setState({ openReportModal: false, editReport: null });
        } else {
            this.setState({ reportModalDialogOpen: true });
        }
    }

    handleCloseGenerateModal = () => this.setState({
        openGenerateModal: false
    })

    handleGroupModalDialogOpen = () => {
        this.setState({reportModalDialogOpen: true});
    }

    handleReportModalDialogClose = flag => (flag === true
        ? this.setState({
            openReportModal: false,
            reportModalDialogOpen: false,
            editReport: null
        }) : this.setState({
            reportModalDialogOpen: false,
            editReport: null
        })
    )

    getReports = () => {
        API.REPORTS.GET().then(({ data }) => {
            this.setState({
                reports: data ? data : [],
                loading: false
            })
        }).catch((error) => {
            this.setState({ loading: false })
            console.error(error)
        })
    }

    handleReportDelete = (report) => {
        API.REPORTS.DELETE(report.id).then((response) => {
            this.openSnackBar(i18n.t('Report successfully removed.'), 'success');
            this.getReports();
        }).catch((error) => {
            console.error(error);
            this.openSnackBar(i18n.t('An error occurred while trying to remove this report.'), 'error');
        });
    }

    openSnackBar = (message, type) => this.setState({
        showSnackBar: true,
        snackbar: {
            message,
            type
        }
    })

    closeSnackBar = () => this.setState({
        showSnackBar: false
    })

    setMasonryRef = (ref) => this.masonry = ref
    masonryLayout = () => this.masonry.performLayout()

    render () {
        const { classes } = this.props;
        const { loading, reports, showSnackBar, snackbar, editReport } = this.state;

        return (
            <div id="reports">
                <div onClick={this.handleOpenGenerateModal} className={`${classes.generateReportButton} generate-report`} >
                    <img src={PDF_ICON} alt="" />
                    <Typography variant="body2" classes={{body2: classes.reportButtonText}}>
                        {i18n.t('GENERATE REPORT')}
                    </Typography>
                </div>
                {localStorage.getItem('authority') === 'ADMIN' && (
                    <div onClick={this.handleOpenModal} className={`${classes.createReportButton} create-report`} >
                        <AddIcon />
                        <Typography variant="body2" classes={{body2: classes.reportButtonText}}>
                            {i18n.t('CREATE REPORT')}
                        </Typography>
                    </div>
                )}
                {loading ?
                    <Loading /> :
                    <Masonry
                        disableImagesLoaded={false}
                        elementType={'div'}
                        className="reports-grid"
                        options={masonryOptions}
                        updateOnEachImageLoad={false}
                        ref={this.setMasonryRef}
                    >
                        {reports && reports.length > 0 ? reports.map((report, i) => (
                            <ReportCard
                                handleReportDelete={this.handleReportDelete}
                                key={report.id}
                                data={report}
                                seq={i}
                                openEditReport={this.openEditReport}
                                masonryLayout={this.masonryLayout}
                            />
                        )) : (
                            <Typography variant="title" color="textSecondary" classes={{title: `${classes.typographyTitle} no-reports-title`}}>
                                {i18n.t('No reports available')}
                            </Typography>
                        )}
                    </Masonry>
                }
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.openReportModal}
                    onClose={this.handleCloseModal}
                    disableBackdropClick={true}>

                    <div style={modalStyle} className={classes.paper}>
                        <CloseIcon className="close-modal" onClick={this.handleCloseModal} style={closeButtonStyle} />

                        <ReportAdd
                            handleCloseModal={this.handleCloseModal}
                            handleOpenModal={this.handleOpenModal}
                            editReport={editReport} />

                        <Dialog aria-labelledby="dialog-text"
                            fullWidth={true}
                            maxWidth="xs"
                            open={this.state.reportModalDialogOpen}
                            onClose={this.handleReportModalDialogClose}>
                            <DialogTitle>
                                {i18n.t('Are you sure?')}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="dialog-text">
                                    {i18n.t('If you close all unsaved information will be lost.')}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button classes={{label: classes.deleteConfirm}}
                                    onClick={() => this.handleReportModalDialogClose(true)}>
                                    {i18n.t('Yes')}
                                </Button>
                                <Button classes={{label: classes.deleteCancel}} onClick={this.handleReportModalDialogClose}>
                                    {i18n.t('Cancel')}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </Modal>

                <Modal
                    aria-labelledby="Generate Instant Report"
                    aria-describedby="Modal window with form for report PDF generation"
                    open={this.state.openGenerateModal}
                    onClose={this.handleCloseGenerateModal}
                    disableBackdropClick={true}>

                    <div style={generateModalStyle} className={classes.paper}>
                        <CloseIcon onClick={this.handleCloseGenerateModal} style={closeButtonStyle} />

                        <ReportGenerate
                            handleCloseModal={this.handleCloseGenerateModal}
                            handleOpenModal={this.handleOpenGenerateModal} />
                    </div>
                </Modal>

                <SystemSnackbar
                    open={showSnackBar}
                    message={snackbar.message}
                    onClose={this.closeSnackBar}
                    variant={snackbar.type} />
            </div>
        )
    }

}

ReportsOverview.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ReportsOverview);
