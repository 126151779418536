import React, { PureComponent } from 'react';
import Masonry from 'react-masonry-component';
import Cookies from 'universal-cookie';
import { withStyles } from 'material-ui/styles';
import Card, { CardContent, CardHeader } from 'material-ui/Card';
import IconButton from 'material-ui/IconButton';
import { Button as VButton } from '../../../elements/button';
import AllocateSensorIcon from 'material-ui-icons/Memory';
import EditIcon from 'material-ui-icons/Edit';
import UsersIcon from 'material-ui-icons/AccountBox';
import AddIcon from 'material-ui-icons/AddCircleOutline';
import classNames from 'classnames';
import Button from 'material-ui/Button';
import Modal from 'material-ui/Modal';
import CloseIcon from 'material-ui-icons/Close';
import Dialog, {
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from 'material-ui/Dialog';
import Typography from 'material-ui/Typography';

import i18n from '../../../i18n';
import { API } from '../../../components/api';
import {Loading} from '../../../elements/loading';
import { Text } from '../../../elements/text';
import SystemSnackbar from '../../../elements/material-ui/SystemSnackbar';
import TenantAddEdit from './tenant-add-edit';
import SensorManagement from './sensor-management';

import UsersOverview from '../../user/overview';

import './overview.css';

const cookies = new Cookies();

const masonryOptions = {
    gutter: 20,
    horizontalOrder: true,
};

const styles = theme => ({
    card: {
        marginBottom: '20px',
        width: '220px',
    },
    headerRoot: {
        padding: '4px 9px 5px',
    },
    headerAvatar: {
        marginRight: '8px',
    },
    headerAction: {
        marginRight: '-2px',
        marginTop: '3px',
    },
    headerTitle: {
        color: '#fff',
        fontSize: '1.1rem',
        lineHeight: '1.4',
        paddingTop: '5px',
        wordBreak: 'break-word',
    },
    imageContent: {
        display: 'flex',
        justifyContent: 'center',
        padding: '16px'
    },
    image: {
        maxWidth: '80px',
        maxheight: '80px',
    },
    cardButton: {
        height: '30px',
        width: '30px',
    },
    cardButtonIcon: {
        color: '#fff',
        fontSize: '20px',
    },
    avatar: {
        background: 'none',
        height: '60px',
        width: '54px',
    },
    avatarIcon: {
        height: '48px',
        width: '48px',
    },
    contentRoot: {
        padding: '0',
        backgroundColor: '#FFF',
        '&:last-child': {
            paddingBottom: '10px',
        },
    },
    tenantContent: {
        padding: '10px 15px',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        width: '550px',
        position: 'absolute',
        marginBottom: theme.spacing.unit * 3,
        boxShadow: theme.shadows[5],
        padding: 0,
        '&:focus': {
            outline: 'none'
        }
    },
    createTenantButton: {
        borderRadius: '3px',
        display: 'flex',
        alignItems: 'center',
        background: '#FFF',
        color: '#6ab42d',
        position: 'fixed',
        right: '120px',
        top: '16px',
        zIndex: 2000,
        padding: '4px 10px',
        cursor: 'pointer',
    },
    createTenantButtonText: {
        color: '#6ab42d',
        paddingLeft: '4px',
        fontWeight: 'bold',
    },
    deleteConfirm: {
        color: '#6ab42d',
    },
    deleteCancel: {
        color: '#c62828',
    },
});

const modalStyle = {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
}

const usersModalStyle = {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    overflowY: 'auto',
    maxHeight: '96%',
    width: 'auto',
}

const closeButtonStyle = {
    cursor: 'pointer',
    padding: '10px',
    position: 'absolute',
    right: 0,
    zIndex: 11,
}

class HomeOverview extends PureComponent {
    state = {
        lastUrl: null,
        loading: true,
        tenants: [],
        openTenantModal: false,
        openTenantModalDialog: false,
        openUsersModal: false,
        editTenant: null,
        openSensorModal: false,
        openSensorModalDialog: false,
        sensorTenant: null,
        showSnackBar: false,
        snackbar: {
            type: 'warning',
            message: ''
        },
    }

    componentDidMount() {
        this.getTenants();
    }

    getTenants = async () => {
        try {
            const { data } = await API.ROOT.TENANTS.GET();

            this.setState({
                loading: false,
                tenants: data,
            })
        } catch (error) {
            console.error('getTenants', error)

            this.setState({
                loading: false
            });
        }
    }

    handleLoginTenant = async user => {
        // return console.log(user)
        if (this.state.loading) {
            return;
        }

        this.setState({ loading: true })
        const email = localStorage.getItem('userEmail');

        try {
            const { data } = await API.ROOT.TENANTS.LOGIN({ tenantId: user.id, email});

            localStorage.setItem('rootTenantId', user.parentId);
            localStorage.setItem('authority', data.user.authority);
            localStorage.setItem('isRoot', false);
            cookies.set('tenantName', data.tenant.name, { path: '/' });
            localStorage.setItem('tenantColors', JSON.stringify(data.tenant.colors));
            localStorage.setItem('tenantLogo', data.tenant.logo);
            localStorage.setItem('tenantShowPoweredByVoltaware', data.tenant.showPoweredByVoltaware);
            window.location = '/home';
        } catch (error) {
            console.error(error);
            this.openSnackBar(i18n.t('There was an error trying to signin as user'), 'error')
        }
        this.setState({ loading: false })
    }

    handleEditTenantClick = (tenant) => {
        this.handleTenantModalOpen();
        this.setState({
            editTenant: tenant,
        });
    }

    handleEditSensorClick = (tenant) => {
        this.handleSensorModalOpen();
        this.setState({
            sensorTenant: tenant,
        });
    }

    handleTenantModalOpen = () => {
        this.setState({
            openTenantModal: true,
        });
    }

    handleSensorModalOpen = () => {
        this.setState({
            openSensorModal: true,
        });
    }

    handleTenantModalClose = (closeAndUpdateTenants) => {
        if (closeAndUpdateTenants === true){
            this.getTenants();
            this.setState({ openTenantModal: false, editTenant: null });
        } else {
            this.setState({ openTenantModalDialog: true });
        }
    }

    handleTenantModalDialogClose = flag => (flag === true
        ? this.setState({
            openTenantModalDialog: false,
            editTenant: null,
            openTenantModal: false
        }) : this.setState({
            openTenantModalDialog: false,
        })
    )

    handleEditUsersClick = (tenant) => {
        this.handleUsersModalOpen();
        this.setState({
            editTenant: tenant,
        });
    }

    handleUsersModalOpen = () => {
        this.setState({
            openUsersModal: true,
        });
    }

    handleUsersModalClose = (closeAndUpdateTenants) => {
        this.setState({ openUsersModal: false, editTenant: null });
    }

    handleSensorModalClose = (closeAndUpdateSensors) => {
        if (closeAndUpdateSensors === true){
            this.getTenants();
            this.setState({ openSensorModal: false, sensorTenant: null });
        } else {
            this.setState({ openSensorModalDialog: true });
        }
    }

    handleSensorModalDialogClose = flag => (flag === true
        ? this.setState({
            openSensorModalDialog: false,
            sensorTenant: null,
            openSensorModal: false
        }) : this.setState({
            openSensorModalDialog: false,
        })
    )

    openSnackBar = (message, type) => {
        this.setState({
            showSnackBar: true,
            snackbar: {
                message,
                type
            }
        });
    }

    closeSnackBar = () => {
        this.setState({
            showSnackBar: false
        });
    }

    render() {
        const { classes } = this.props;
        const {
            editTenant,
            loading,
            sensorTenant,
            showSnackBar,
            snackbar,
            tenants,
        } = this.state;

        return (
            <section id="page-root-home">
                <div onClick={this.handleTenantModalOpen} className={classes.createTenantButton} id="create-tenant-btn">
                    <AddIcon />
                    <Typography variant="body2" classes={{body2: classes.createTenantButtonText}}>
                        {i18n.t('CREATE CLIENT')}
                    </Typography>
                </div>
                {loading && (
                    <div className="loading-wrapper">
                        <Loading />
                    </div>
                )}
                {!loading && !tenants.length &&(
                    <div className="no-data">
                        {i18n.t('No clients available')}
                    </div>
                )}
                {!loading && !!tenants.length && (
                    <Masonry
                        disableImagesLoaded={false}
                        className="tenants-wrapper"
                        elementType={'div'}
                        options={masonryOptions}
                        updateOnEachImageLoad={false}
                        ref={this.setMasonryRef}
                    >
                        {tenants.map(tenant => (
                            <Card className={`tenant-card ${classes.card}`} key={tenant.id} style={{ backgroundColor: tenant.configuration.secondaryColor ? tenant.configuration.secondaryColor : '#569124' }}>
                                <CardHeader
                                    title={tenant.name}
                                    classes={{
                                        action: classes.headerAction,
                                        avatar: classes.headerAvatar,
                                        root: classes.headerRoot,
                                        title: classes.headerTitle,
                                    }}
                                    action={
                                        <div>
                                            <IconButton aria-label="Manage Users"
                                                color="inherit"
                                                key="users"
                                                className={classNames(classes.cardButton, "btn-users")}
                                                onClick={() => this.handleEditUsersClick(tenant)}>
                                                <UsersIcon className={classes.cardButtonIcon} />
                                            </IconButton>
                                            <IconButton aria-label="Allocate Sensor"
                                                color="inherit"
                                                key="allocate"
                                                className={classNames(classes.cardButton, "btn-allocate")}
                                                onClick={() => this.handleEditSensorClick(tenant)}>
                                                <AllocateSensorIcon className={classes.cardButtonIcon} />
                                            </IconButton>
                                            <IconButton aria-label="Edit"
                                                color="inherit"
                                                key="edit"
                                                className={classNames(classes.cardButton, "btn-edit-tenant")}
                                                onClick={() => this.handleEditTenantClick(tenant)}>
                                                <EditIcon className={classes.cardButtonIcon} />
                                            </IconButton>
                                        </div>
                                    }
                                />
                                <CardContent classes={{root: classes.contentRoot}}>
                                    {tenant.configuration.logo && (
                                        <div className={classes.imageContent} style={{ backgroundColor: tenant.configuration.primaryColor ? tenant.configuration.primaryColor : '#FFF' }}>
                                            <img
                                                src={`data:image/png;base64,${tenant.configuration.logo}`}
                                                alt="logo"
                                                className={classes.image}
                                            />
                                        </div>
                                    )}
                                    <div className={classes.tenantContent}>
                                        <div className={classes.txtWrapper}>
                                            <Text inline text={i18n.t("Primary color:")} />
                                            <Text
                                                inline
                                                bold
                                                color={tenant.configuration.primaryColor}
                                                text={tenant.configuration.primaryColor ? tenant.configuration.primaryColor : i18n.t('Default')}
                                            />
                                        </div>
                                        <div className={classes.txtWrapper}>
                                            <Text inline text={i18n.t("Secondary color:")} />
                                            <Text
                                                inline
                                                bold
                                                color={tenant.configuration.secondaryColor}
                                                text={tenant.configuration.secondaryColor ? tenant.configuration.secondaryColor : i18n.t('Default')}
                                            />
                                        </div>
                                        <div className={classes.txtWrapper}>
                                            <VButton
                                                action="preview"
                                                label={i18n.t("Login as client")}
                                                disabled={this.state.loading}
                                                click={() => this.handleLoginTenant(tenant)}
                                            />
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        ))}
                    </Masonry>
                )}
                <Modal
                    aria-labelledby="Client Creation or Edition"
                    aria-describedby="Modal window for client creation or edition"
                    open={this.state.openTenantModal}
                    onClose={this.handleTenantModalClose}
                    disableBackdropClick={true}>

                    <div style={modalStyle} className={classes.paper}>
                        <CloseIcon onClick={this.handleTenantModalClose} style={closeButtonStyle} />


                        <Dialog aria-labelledby="dialog-text"
                            fullWidth={true}
                            maxWidth="xs"
                            open={this.state.openTenantModalDialog}
                            onClose={this.handleModalDialogClose}>
                            <DialogTitle>
                                {i18n.t('Are you sure?')}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="dialog-text">
                                    {i18n.t('If you close all unsaved information will be lost.')}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button classes={{label: classes.deleteConfirm}}
                                    onClick={() => this.handleTenantModalDialogClose(true)}>
                                    {i18n.t('Yes')}
                                </Button>
                                <Button classes={{label: classes.deleteCancel}} onClick={this.handleTenantModalDialogClose}>
                                    {i18n.t('Cancel')}
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <TenantAddEdit
                            editTenant={editTenant}
                            handleCloseModal={this.handleTenantModalClose}
                            handleOpenModal={this.handleTenantModalOpen}
                            openParentSnackBar={this.openSnackBar}
                        />
                    </div>
                </Modal>

                <Modal
                    aria-labelledby="Client Creation or Edition"
                    aria-describedby="Modal window for client creation or edition"
                    open={this.state.openUsersModal}
                    onClose={this.handleUsersModalClose}
                    disableBackdropClick={true}>
                    <div style={usersModalStyle} className={classes.paper}>
                        <CloseIcon onClick={this.handleUsersModalClose} style={closeButtonStyle} />

                        <UsersOverview
                            tenantUsersMode={true}
                            tenant={editTenant}
                        />
                    </div>
                </Modal>

                <Modal
                    aria-labelledby="Sensor Management"
                    aria-describedby="Modal window for client sensors management"
                    open={this.state.openSensorModal}
                    onClose={this.handleSensorModalClose}
                    disableBackdropClick={true}>

                    <div style={modalStyle} className={classes.paper}>
                        <CloseIcon onClick={this.handleSensorModalClose} style={closeButtonStyle} />


                        <Dialog aria-labelledby="dialog-text"
                            fullWidth={true}
                            maxWidth="xs"
                            open={this.state.openSensorModalDialog}
                            onClose={this.handleModalDialogClose}>
                            <DialogTitle>
                                {i18n.t('Are you sure?')}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="dialog-text">
                                    {i18n.t('If you close all unsaved information will be lost.')}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button classes={{label: classes.deleteConfirm}}
                                    onClick={() => this.handleSensorModalDialogClose(true)}>
                                    {i18n.t('Yes')}
                                </Button>
                                <Button classes={{label: classes.deleteCancel}} onClick={this.handleSensorModalDialogClose}>
                                    {i18n.t('Cancel')}
                                </Button>
                            </DialogActions>
                        </Dialog>
                        {this.state.openSensorModal && (
                            <SensorManagement
                                tenant={sensorTenant}
                                handleCloseModal={this.handleSensorModalClose}
                                handleOpenModal={this.handleSensorModalOpen}
                                openParentSnackBar={this.openSnackBar}
                            />
                        )}
                    </div>
                </Modal>
                <SystemSnackbar
                    open={showSnackBar}
                    message={snackbar.message}
                    onClose={this.closeSnackBar}
                    variant={snackbar.type}
                />
            </section>
        )
    }
};

export default withStyles(styles)(HomeOverview);
